import { Fragment } from 'react';

import clsx from 'clsx';

/**
 * Styles
 */
const stepBarStyle = 'h-2 my-2 mx-[-2rem] z-[8] content-[_] grow';

/**
 * Types
 */
export type StepperProps = {
  steps: string[];
  activeStep: number;
  onStepClick?: (step: number) => void;
  testId?: string;
};

/**
 * Component
 */
function Stepper(props: StepperProps) {
  /**
   * Props
   */
  const { activeStep, onStepClick, steps, testId } = props;

  /**
   * Render
   */
  return (
    <div className="w-full flex justify-between" data-testid={testId}>
      {/* Steps are each generated */}
      {steps.map((step, index) => (
        <Fragment key={index}>
          {index > 0 && (
            <div
              className={clsx(stepBarStyle, {
                'bg-primary-2-100': activeStep >= index,
                'bg-secondary-2-20': activeStep < index
              })}
            />
          )}
          <button
            className={clsx(
              'p-0 bg-transparent border-none flex flex-col items-center w-16',
              {
                completed: activeStep >= index,
                active: activeStep === index,
                'cursor-default': !onStepClick
              }
            )}
            onClick={() => onStepClick?.(index)}
            data-testid={`${testId}-button-${index}`}
          >
            <div
              className={clsx('w-6 h-6 rounded-full z-[9]', {
                'bg-primary-2-100': activeStep >= index,
                'bg-secondary-2-20': activeStep < index,
                'shadow-activeStepper shadow-primary-2-100/[.3]':
                  activeStep === index
              })}
            />
            <p className="stepper__step-text">{step}</p>
          </button>
        </Fragment>
      ))}
    </div>
  );
}

export default Stepper;
