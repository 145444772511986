import { Fragment } from 'react';

import { CheckCircle, Error, Info, Warning } from '@mui/icons-material';
import {
  Alert as MuiAlert,
  AlertColor,
  AlertProps as MuiAlertProps
} from '@mui/material';
import clsx from 'clsx';

import { Modify } from '@reece/global-types';
import { styles } from 'components/Alert/styles';

/**
 * Config
 */
const iconMap = {
  default: <Fragment />,
  error: <Error />,
  info: <Info />,
  success: <CheckCircle />,
  warning: <Warning />
};

/**
 * Types
 */
type NewProps = {
  severity?: AlertSeverity;
  customStyle?: boolean;
};
export type AlertSeverity = AlertColor | 'default';
export type AlertProps = Modify<MuiAlertProps, NewProps>;

/**
 * Component
 */
function Alert(props: AlertProps) {
  /**
   * Props
   */
  const { className, customStyle, severity = 'default', ...rest } = props;

  /**
   * Styles
   */
  const styling = styles[severity];

  /**
   * Render
   */
  return (
    <MuiAlert
      className={clsx(
        className,
        '[&_.MuiAlert-icon]:flex [&_.MuiAlert-icon]:items-center',
        { [styling]: !customStyle }
      )}
      icon={iconMap[severity]}
      {...rest}
    />
  );
}

export default Alert;
