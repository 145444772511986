import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Button, Image, Link } from 'components';
import useScreenSize from 'hooks/useScreenSize';
import bgDesktop from 'images/bigDog/footerBgDesktop.jpg';
import bgMobile from 'images/bigDog/footerBgMobile.jpg';
import imgDesktop from 'images/bigDog/footerImageDesktop.jpg';
import imgMobile from 'images/bigDog/footerImageMobile.jpg';
import { links } from 'utils/links';

/**
 * Component
 */
function BigDogFooter() {
  /**
   * Custom Hooks
   */
  const { isSmallScreen } = useScreenSize();
  const { t } = useTranslation();

  /**
   * Render
   */
  return (
    <section
      className="h-[845px] mt-[109.6px] md:h-[709px] md:mt-[50.4px]"
      data-testid="bigdog-section-footer"
    >
      <Divider flexItem className="w-full !h-4 !bg-etc-big-dog-orange" />
      <div
        className="min-h-[329px] box-border text-common-white font-gilroy md:h-[295px]"
        style={{
          backgroundImage: `url(${isSmallScreen ? bgMobile : bgDesktop})`
        }}
        data-testid="bigdog-footer-box"
      >
        <h1 className="pt-[78.4px] text-[70px] leading-[1.2] text-center font-extrabold md:pt-[44px] md:text-[32px]">
          {t('bigDogMarketing.range.title')}
        </h1>
        <h3 className="mt-6 px-[220px] text-[32px] leading-[1.2] text-center md:px-[33.6px] md:text-xl">
          {t('bigDogMarketing.range.introParagraph')}
        </h3>
        <div className="mt-[25.6px] flex justify-center md:mt-10">
          <Link to={links.bigDog.seeFullRange}>
            <Button className="!bg-etc-big-dog-orange">
              <span className="text-common-white text-base leading-[1.2] font-bold font-metropolis text-center uppercase">
                {t('bigDogMarketing.range.buttonText')}
              </span>
            </Button>
          </Link>
        </div>
      </div>
      <Image
        className="w-full h-[500px] object-cover object-top md:h-[400px]"
        src={isSmallScreen ? imgMobile : imgDesktop}
        data-testid="bigdog-footer-image"
      />
    </section>
  );
}

export default BigDogFooter;
