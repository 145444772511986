import { useCallback } from 'react';

import { Container, Hidden, Link, MenuItem } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useCompanyList from 'common/Header/lib/useCompanyList';
import { Button, DropdownButton } from 'components';
import { useDomainInfo } from 'hooks/useDomainInfo';
import { NewHelpIcon, NewLocationIcon } from 'icons';
import { generateCompanyUrl } from 'utils/brandList';
import { Configuration } from 'utils/configuration';
import { useHeaderContext } from 'common/Header/HeaderProvider';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';
import ResourcesButton from 'common/Header/sub/ResourcesButton';

/**
 * Component
 */
function CompanyListHeader() {
  /**
   * Custom Hooks
   */
  const { port } = useDomainInfo();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { isWaterworks } = useDomainInfo();

  /**
   * Context
   */
  const { maintenance } = useHeaderContext();
  const { isMincron } = useSelectedAccountsContext();

  /**
   * Memo
   */
  // 🔵 Memo - Filter company list
  const filteredCompanyList = useCompanyList();

  /**
   * Callbacks
   */
  // 🟤 Cb - company list dropdown content
  const companyListDropdownContent = useCallback(
    () => (
      <div className="py-4">
        {filteredCompanyList.map((company, i) => (
          <Link
            key={i}
            data-testid={`${company.sub}-link`}
            underline="none"
            href={generateCompanyUrl(
              company.sub,
              port,
              Configuration.environment
            )}
          >
            <MenuItem>{company.link}</MenuItem>
          </Link>
        ))}
      </div>
    ),
    [filteredCompanyList, port]
  );

  /**
   * Effect
   */

  /**
   * Render
   */
  if (
    pathname.includes('/register') ||
    pathname.includes('/barcode') ||
    pathname.includes('/careers') ||
    pathname.includes('/banner/')
  ) {
    return null;
  }
  return (
    <Hidden mdDown>
      <div
        className="bg-common-white border-b border-primary-3-10 print:hidden"
        data-testid="header-companylist-wrapper"
      >
        <Container maxWidth="lg" className="!px-4">
          <div className="py-2 pl-4 flex justify-between items-center">
            <div className="flex justify-between items-center pl-2">
              {/* Reece Companies */}
              <DropdownButton
                className="!px-1"
                data-testid="company-list"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                content={companyListDropdownContent}
              >
                {t('common.reeceCompanies')}
              </DropdownButton>

              {/* Brands */}
              {!isWaterworks && !isMincron && (
                <Link
                  className="block m-0 px-1 py-1 !text-primary-3-100"
                  href="/brands"
                  data-testid="nav-brands"
                >
                  {t('common.brands')}
                </Link>
              )}
            </div>

            {/* used to control flex */}
            {!maintenance && (
              <div className="flex" data-testid="header-companylist-misc">
                <Button
                  color="gray"
                  className="!py-2 !px-1 mr-2 !text-xs !leading-normal font-normal"
                  iconStart={
                    <NewLocationIcon className="!w-[18px] !h-[18px] !text-gray-500" />
                  }
                  kind="text"
                  onClick={() => push('/location-search')}
                  data-testid="location-search-button"
                >
                  {t('common.storeFinder')}
                </Button>
                {/* Resources */}
                <ResourcesButton item="resources">
                  {t('common.resources')}
                </ResourcesButton>
                <Button
                  color="gray"
                  className="!py-2 !px-1 !text-xs !leading-normal font-normal"
                  iconStart={
                    <NewHelpIcon className="!w-[18px] !h-[18px] !text-secondary-2-100" />
                  }
                  kind="text"
                  onClick={() => push('/support')}
                  data-testid="support-button"
                >
                  {t('support.help')}
                </Button>
              </div>
            )}
          </div>
        </Container>
      </div>
    </Hidden>
  );
}

export default CompanyListHeader;
