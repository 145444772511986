import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { OnInputChange, OnInputKeyboard } from '@reece/global-types';
import { IconButton, TextInput } from 'components';
import { SearchIcon } from 'icons';
import { useListsPageContext } from 'pages/Lists/provider/ListsPageProvider';

/**
 * Component
 */
function ListsSearch() {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();
  const {
    callSearchList,
    emptyListSearch,
    listsRefetch,
    setSearchAllListsValue
  } = useListsPageContext();

  /**
   * State
   */
  const [value, setValue] = useState('');
  // eslint-disable-next-line no-useless-escape
  const adjustedValue = value.trim().replace(/[^a-zA-Z0-9\s\-.()#\/]/g, '');
  const invalidValue = !adjustedValue.length;

  /**
   * Callbacks
   */
  // 🟤 Cb - handle search term change
  const handleOnChange = (e: OnInputChange) => {
    const { value: rawText } = e.currentTarget;
    setValue(rawText);
    !rawText &&
      (() => {
        listsRefetch();
        setSearchAllListsValue('');
      })();
  };
  // 🟤 Cb - perform search
  const search = () => {
    if (invalidValue) {
      return;
    }
    callSearchList(encodeURIComponent(adjustedValue));
    setSearchAllListsValue(adjustedValue);
  };
  // 🟤 Cb - call search list query on enter
  const handleKeyDown = (event: OnInputKeyboard) =>
    event.key === 'Enter' && search();
  // 🟤 Cb - clear search term
  const handleClearSearch = () => {
    setSearchAllListsValue('');
    setValue('');
    listsRefetch();
  };

  /**
   * Render
   */
  return (
    <div className="px-4 mb-2 md:px-2">
      <TextInput
        label={t('lists.searchLists')}
        placeholder={t('lists.searchListsByProduct')}
        value={value}
        onChange={handleOnChange}
        onKeyDown={handleKeyDown}
        iconStart={
          <IconButton onClick={search} disabled={invalidValue}>
            <SearchIcon className="cursor-pointer" />
          </IconButton>
        }
        onClear={handleClearSearch}
        data-testid="lists-search"
      />
      {emptyListSearch && (
        <p
          data-testid="list-search-no-results"
          className="flex justify-center mt-5 text-primary-3-100 text-base"
        >
          {t('common.noResultsFound')}
        </p>
      )}
    </div>
  );
}

export default ListsSearch;
