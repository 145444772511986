import { useContext, useMemo } from 'react';

import { useCartContext } from 'providers/CartProvider';
import { CheckoutContext } from 'Checkout/CheckoutProvider';
import { Maybe } from 'generated/graphql';
import { format } from 'utils/currency';

export type UseOrderSummaryProps = {
  page: string;
  showTax?: boolean;
};
export type OrderSummaryData = {
  subTotal?: string;
  shippingAmount?: Maybe<string>;
  handling?: Maybe<string>;
  tax: Maybe<string>;
  orderTotal?: string;
};

export function useOrderSummary({ page, showTax }: UseOrderSummaryProps) {
  /**
   * Context
   */
  const { orderData, orderPreviewData } = useContext(CheckoutContext);
  const {
    checkingOutWithQuote,
    contract,
    quoteData,
    itemCount: productCount,
    subTotal = '—'
  } = useCartContext();

  /**
   * Memo
   */
  const summaryData = useMemo<OrderSummaryData>(() => {
    /**
     * Variables
     */
    const isCartPage = page === 'cart';
    const isCheckoutPage = page === 'checkout';
    const formatRequired = !!contract || page === 'approval';

    /**
     * Logics
     */
    if (isCartPage || (isCheckoutPage && contract && !showTax)) {
      const output = {
        subTotal: (Boolean(productCount) && subTotal) || '—',
        shippingAmount: null,
        handling: null,
        tax: null,
        orderTotal: (Boolean(productCount) && subTotal) || '—'
      };

      return output;
    }
    if (checkingOutWithQuote) {
      return {
        subTotal: format(quoteData?.subTotal ?? 0),
        tax: format(quoteData?.tax ?? 0),
        orderTotal: format(quoteData?.orderTotal ?? 0)
      };
    }
    // Regular
    return {
      subTotal: showTax
        ? isCheckoutPage
          ? formatRequired
            ? format(parseFloat(orderPreviewData.subTotal || '0'))
            : orderPreviewData.subTotal
          : format(orderData.subTotal ?? 0)
        : subTotal,
      shippingAmount: isCheckoutPage
        ? formatRequired
          ? format(parseFloat(orderPreviewData.shippingAmount || '0'))
          : orderPreviewData.shippingAmount
        : format(orderData.shippingAmount ?? 0),
      handling: isCheckoutPage
        ? formatRequired
          ? format(parseFloat(orderPreviewData.handling || '0'))
          : orderPreviewData.handling
        : format(orderData.handling ?? 0),
      tax: isCheckoutPage
        ? formatRequired
          ? format(parseFloat(orderPreviewData.tax || '0'))
          : orderPreviewData.tax
        : format(orderData.tax ?? 0),
      orderTotal: showTax
        ? isCheckoutPage
          ? formatRequired
            ? format(parseFloat(orderPreviewData.orderTotal || '0'))
            : orderPreviewData.orderTotal
          : format(orderData.orderTotal ?? 0)
        : subTotal
    };
  }, [
    checkingOutWithQuote,
    contract,
    page,
    quoteData,
    orderData,
    orderPreviewData,
    showTax,
    subTotal,
    productCount
  ]);

  return summaryData;
}
