import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { logoStyle } from 'common/Header/lib/styles';
import NewCartButton from 'common/OldHeader/sub/NewCartButton';
import { AppBar } from 'components';
import logoSrc from 'images/logo.svg';

function CartHeader() {
  return (
    <div
      className="bg-transparent bottom-0 z-10 print:hidden"
      data-testid="checkout-header-wrapper"
    >
      <div className={clsx('bg-transparent bottom-0 z-10 flex flex-row')}>
        <AppBar
          color="inherit"
          position="static"
          elevation={0}
          borderBottom={false}
        >
          <div className="flex items-center px-12 md:px-6 py-4 justify-between md:[&_img]:!h-12 h-24">
            <Link to="/" className="flex">
              <img
                alt="Reece"
                data-testid="reecestandard-logo"
                src={logoSrc}
                className={logoStyle}
              />
            </Link>
            <NewCartButton />
          </div>
        </AppBar>
      </div>
    </div>
  );
}

export default CartHeader;
