import { APIOptions } from 'API/hooks/useApiBase';
import { UseAPILazyProps } from 'API/hooks/useApiLazy';
import { useApiLazyAuth } from 'API/hooks/useApiLazyAuth';
import { makeApiOnMountAuth } from 'API/lib/makeApi';
import {
  GetProductInventoryAtBranchesResponse,
  GetProductInventoryResponse,
  GetProductsPricingResponse,
  ProductDetails
} from 'API/types/products.types';
import { generateQueryParam } from 'utils/generateQueryParam';

/**
 * Config
 */
const BASE_URL = 'products';

/**
 * Hooks
 */
// 🟦 GET products/{productId} - Get product details by product id
export const useApiGetProductDetails = (productId: string) =>
  makeApiOnMountAuth<ProductDetails>({
    url: `${BASE_URL}/${productId}`,
    kind: 'get',
    header: {},
    optionalAuth: true
  });

// 🟦 GET /products/pricing - Get product stock and pricing with multiple product ids
export function useApiGetProductsPricing(
  options: APIOptions<GetProductsPricingResponse> = {}
) {
  const props: UseAPILazyProps<GetProductsPricingResponse> = {
    url: '',
    kind: 'get',
    options,
    header: {}
  };
  const api = useApiLazyAuth<GetProductsPricingResponse>(props);
  const call = async (ids: string[], searchID?: string) => {
    const params = { productIds: ids.join(','), searchID };
    const url = generateQueryParam(`${BASE_URL}/pricing`, params);
    return await api.call({ ...props, url });
  };
  return { ...api, call };
}

// 🟦 GET /products/getProductInventoryInBranches - Get product inventory at branches
export const useApiGetProductInventoryAtBranches = (productId: string) =>
  makeApiOnMountAuth<GetProductInventoryAtBranchesResponse>({
    url: generateQueryParam(`${BASE_URL}/getProductInventoryInBranches`, {
      productId
    }),
    kind: 'get',
    header: {},
    optionalAuth: true
  });

// 🟦 GET /products/list/{listId} - Get product pricing with list id
export function useApiGetListProductsPricing(
  options: APIOptions<GetProductsPricingResponse> = {}
) {
  const props: UseAPILazyProps<GetProductsPricingResponse> = {
    url: '',
    kind: 'get',
    options,
    header: {}
  };
  const api = useApiLazyAuth<GetProductsPricingResponse>(props);
  const call = async (listId: string) =>
    await api.call({ ...props, url: `${BASE_URL}/list/${listId}` });
  return { ...api, call };
}

// 🟦 GET /products/{productId}/inventory - Get product availability on hand
export function useApiGetProductInventory(
  options: APIOptions<GetProductInventoryResponse> = {}
) {
  const props: UseAPILazyProps<GetProductInventoryResponse> = {
    url: '',
    kind: 'get',
    options,
    header: {}
  };
  const api = useApiLazyAuth<GetProductInventoryResponse>(props);
  const call = (productId: string) =>
    api.call({
      ...props,
      url: `${BASE_URL}/${productId}/inventory`
    });
  return { ...api, call };
}
