/**
 * Index
 */
export const toolbarStyle = [
  'py-4 border-b border-primary-3-10',
  '[&_.MuiIconButton-root]:p-0',
  '[&_.MuiIconButton-root_svg]:w-[18px]',
  '[&_.MuiIconButton-root_svg]:h-[18_px]',
  'md:min-h-auto md:border-0',
  'transition-[padding] ease-in-out duration-200'
];
export const toolbarStyleSearchOpen = 'md:py-3 md:!border-b';
export const containerStyle =
  '!pl-[41px] !pr-[26px] flex items-center visible transition-[visibility] duration-300 md:!px-4';
export const containerStyleSearchOpen = 'md:invisible md:transition-none';
export const logoStyle = 'h-12 py-2 md:h-[18px] md:py-0';

/**
 * Search Input
 */
export const searchTextFieldStyle = [
  '!m-0 !p-0',
  '[&_.MuiInputBase-root]:!pr-0',
  '[&_.MuiInputBase-root]:!rounded-sm',
  '[&_.MuiInputBase-root]:!border',
  '[&_.MuiInputBase-root]:!border-solid',
  '[&_.MuiOutlinedInput-root]:!items-stretch',
  '[&_.MuiInputBase-input]:!py-[14.4px]',
  '[&_.MuiInputBase-input]:!pl-2',
  '[&_.MuiInputBase-input]:!pr-0',
  '[&_.MuiInputBase-input_svg]:!w-6',
  '[&_.MuiInputBase-input_svg]:!h-6',
  '[&_fieldset]:border-0'
];
