import { DragIndicator } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

import clsx from 'clsx';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { List } from 'API/types/lists.types';
import { useListsPageContext } from 'pages/Lists/provider/ListsPageProvider';
import ListMoreActions from 'pages/Lists/sub/ListMoreActions';
import { MAX_LIST_ITEMS } from 'providers/libs/ListsProvider';
import ListFullWarning from 'common/ListsDrawer/sub/ListFullWarning';

/**
 * Types
 */
export type ListsSelectorItemProps = {
  list: List;
  index: number;
  selected?: boolean;
  isDragged: boolean;
  isDragDisabled?: boolean;
  draggable?: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  testId?: string;
};

/**
 * Component
 */
function ListsSelectorItem(props: ListsSelectorItemProps) {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();

  /**
   * Context
   */
  const { selectedList, applySelectedList, setMobileDrawerOpen } =
    useListsPageContext();
  const selected = props.selected || props.list.id === selectedList?.list.id;

  const listFull = (props.list.listLineItemsSize ?? 0) >= MAX_LIST_ITEMS;
  /**
   * Render
   */
  return (
    <div className="mx-4 md:mx-0">
      <div
        data-testid={`${props.testId}-${props.index}`}
        className={clsx(
          'bg-common-white w-full pr-4 py-3 flex border-b border-secondary-3-100 rounded-none shadow-none transition-all md:px-0',
          {
            '[&:has(.select-area:hover)]:bg-primary-2-20': !props.isDragged,
            '!bg-primary-2-10': selected && !props.isDragged,
            '!border-transparent !rounded-lg !shadow-surround-lg':
              props.isDragged
          }
        )}
      >
        <div
          className={clsx('px-2 flex justify-center items-center')}
          {...props.dragHandleProps}
          data-testid={`${props.testId}-drag-handle-${props.index}`}
        >
          <DragIndicator
            className={clsx('text-secondary-3-100', {
              'opacity-0': props.isDragDisabled
            })}
          />
        </div>
        <div
          className="select-area w-[calc(100%-76px)] flex-1 cursor-pointer select-none"
          onClick={() => {
            !selected && applySelectedList(props.list, props.index);
            setMobileDrawerOpen(false);
          }}
        >
          <div className="flex text-primary-1-100 text-base font-medium mb-2 gap-2">
            <span
              className="min-w-0 text-primary-3-100 truncate"
              data-testid={`${props.testId}-name-${props.index}`}
            >
              {props.list.name}
            </span>
            <span
              className="text-primary-3-100 md:text-secondary-2-100 md:font-normal"
              data-testid={`${props.testId}-size-${props.index}`}
            >
              ({props.list.listLineItemsSize})
            </span>
            {listFull && (
              <Tooltip
                PopperProps={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -10] // Adjust this value to move the tooltip closer or further from the cursor
                      }
                    }
                  ]
                }}
                title={<p className="pt-[-10px]">{t('lists.listFull')}</p>}
              >
                <div>
                  <ListFullWarning showText={false} />
                </div>
              </Tooltip>
            )}
          </div>
          <p
            className={clsx('text-caption truncate', {
              'text-secondary-2-100': props.list.description,
              'text-secondary-3-100': !props.list.description
            })}
            data-testid={`${props.testId}-description-${props.index}`}
          >
            {props.list.description || t('lists.noDescription')}
          </p>
        </div>
        <ListMoreActions index={props.index} list={props.list} />
      </div>
    </div>
  );
}
export default ListsSelectorItem;
