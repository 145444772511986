import { useMemo } from 'react';

import { DataMap } from '@reece/global-types';
import { useTranslation } from 'react-i18next';

import { ErpSystemEnum } from 'generated/graphql';
import { useDomainInfo } from 'hooks/useDomainInfo';
import { useAuthContext } from 'providers/AuthProvider';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';

/**
 * Types
 */
export type FooterItem = {
  id: string;
  name: string;
  to: string;
  condition: boolean;
  externalUrl?: boolean;
};

/**
 * Hook
 */
function useFooterData() {
  /**
   * Custom hooks
   */
  const { isWaterworks } = useDomainInfo();
  const { t } = useTranslation();

  /**
   * Context
   */
  const {
    selectedAccounts: { erpSystemName }
  } = useSelectedAccountsContext();
  const { features } = useAuthContext();

  /**
   * Memo
   */
  const isWPDEnabled = useMemo(
    () =>
      Boolean(
        features?.find((f) => f.name === 'WORLD_PLUMBING_DAY')?.isEnabled
      ),
    [features]
  );

  /**
   * Consts
   */
  const isMincron = erpSystemName === ErpSystemEnum.Mincron;
  const isWWOrMincron = isWaterworks || isMincron;

  /**
   * Memo
   */
  const data = useMemo<DataMap<FooterItem[]>>(
    () => ({
      [t('footer.company')]: [
        {
          id: 'aboutreece',
          name: t('footer.aboutUs'),
          to: '/about',
          condition: true,
          externalUrl: false
        },
        {
          id: 'careers',
          name: t('footer.careers'),
          to: 'https://careers.reece.com/us',
          condition: true,
          externalUrl: true
        },
        {
          id: 'events',
          name: t('footer.events'),
          to: '/events',
          condition: isWPDEnabled,
          externalUrl: false
        },
        {
          id: 'partners',
          name: t('common.partners'),
          to: '/partners',
          condition: true,
          externalUrl: false
        },
        {
          id: 'reecegroup',
          name: t('footer.reeceGroup'),
          to: 'https://group.reece.com/us',
          condition: true,
          externalUrl: true
        },
        {
          id: 'linkedin',
          name: t('footer.reeceLinkedIn'),
          to: 'https://www.linkedin.com/company/reeceusa/',
          condition: true,
          externalUrl: true
        }
        // 🔸 Commented out until we implement this later, this will be used as reference just in case
        // {
        //   id: 'sitemap',
        //   name: t('footer.siteMap'),
        //   to: '',
        //   condition: true
        // }
      ],
      [t('footer.support')]: [
        {
          id: 'callus',
          name: t('footer.callUs'),
          to: 'tel:877-273-3236',
          condition: true,
          externalUrl: false
        },
        {
          id: 'emailus',
          name: t('footer.emailUs'),
          to: '/support',
          condition: true,
          externalUrl: false
        },
        {
          id: 'storefinder',
          name: t('footer.storeFinder'),
          to: '/location-search',
          condition: true,
          externalUrl: false
        },
        //{
        //  id: 'willcallpickup',
        //  name: t('footer.willCallPickup'),
        //  to: '/will-call-pickup',
        //  condition: true,
        //  externalUrl: false
        //},
        //{
        //  id: 'rapiddelivery',
        //  name: t('footer.rapidDelivery'),
        //  to: '/rapid-delivery',
        //  condition: true,
        //  externalUrl: false
        //},
        {
          id: 'jobform',
          name: t('footer.jobForm'),
          to: isWWOrMincron ? '/jobform' : '/job-form',
          condition: true,
          externalUrl: false
        }
      ],
      [t('footer.legal')]: [
        {
          id: 'termsaccess',
          name: t('footer.termsAccess'),
          to: '/terms-of-access',
          condition: true,
          externalUrl: false
        },
        {
          id: 'termssale',
          name: t('footer.termsSale'),
          to: '/terms-of-sale',
          condition: true,
          externalUrl: false
        },
        {
          id: 'privacy',
          name: t('footer.privacy'),
          to: '/privacy-policy',
          condition: true,
          externalUrl: false
        },
        {
          id: 'donotsellinfo',
          name: t('footer.doNotSellMyInfo'),
          to: '/do-not-sell-my-info',
          condition: true,
          externalUrl: false
        }
      ]
    }),
    [isWPDEnabled, isWWOrMincron, t]
  );

  /**
   * Output
   */
  return data;
}

export default useFooterData;
