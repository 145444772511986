import { useCallback } from 'react';

import clsx from 'clsx';

import EnergyStar from 'images/energy_star.png';
import HazardousMaterial from 'images/hazardous_material.png';
import LeadFree from 'images/lead_free.png';
import MecuryFree from 'images/mercury_free.png';
import WaterSense from 'images/ws-logo-seal.png';
import { useProductPageContext } from 'pages/Product/ProductPageProvider';

/**
 * Config
 */
export const LOW_LEAD_COMPLIANT = 'Low lead compliant';
export const MERCURY_FREE = 'Mercury free';
export const WATER_SENSE_COMPLIANT = 'WaterSense compliant';
export const ENERGY_STAR = 'Energy Star';
export const HAZARDOUS_MATERIAL = 'Hazardous material';

/**
 * Component
 */
function EnvironmentalOptions() {
  /**
   * Context
   */
  const { product } = useProductPageContext();

  /**
   * Callbacks
   */
  // 🟤 Cb - util to check if it has specific environmental options
  const hasOption = useCallback(
    (input: string) => Boolean(product?.environmentalOptions?.includes(input)),
    [product?.environmentalOptions]
  );

  /**
   * Render
   */
  return (
    <div
      className={clsx(
        'pt-6 flex flex-nowrap justify-start md:pt-0 md:justify-center md:border-t-0',
        {
          'border-secondary-3-100 border-t':
            hasOption(WATER_SENSE_COMPLIANT) ||
            hasOption(ENERGY_STAR) ||
            hasOption(LOW_LEAD_COMPLIANT) ||
            hasOption(HAZARDOUS_MATERIAL) ||
            hasOption(MERCURY_FREE)
        }
      )}
      data-testid="pdp-environmental-options"
    >
      {hasOption(WATER_SENSE_COMPLIANT) && (
        <div className="basis-1/6" data-testid="pdp-env-watersense">
          <img
            className="block mx-auto w-1/2"
            alt="WaterSense"
            src={WaterSense}
          />
        </div>
      )}
      {hasOption(ENERGY_STAR) && (
        <div className="basis-1/6" data-testid="pdp-env-energystar">
          <img
            className="block mx-auto w-1/2"
            alt="EnergyStar"
            src={EnergyStar}
          />
        </div>
      )}
      {hasOption(LOW_LEAD_COMPLIANT) && (
        <div className="basis-1/6" data-testid="pdp-env-leadfree">
          <img className="block mx-auto w-1/2" alt="LeadFree" src={LeadFree} />
        </div>
      )}
      {hasOption(HAZARDOUS_MATERIAL) && (
        <div className="basis-1/6" data-testid="pdp-env-hazmat">
          <img
            className="block mx-auto w-1/2"
            alt="HazardousMaterial"
            src={HazardousMaterial}
          />
        </div>
      )}
      {hasOption(MERCURY_FREE) && (
        <div className="basis-1/6" data-testid="pdp-env-mercuryfree">
          <img
            className="block mx-auto w-1/2"
            alt="MecuryFree"
            src={MecuryFree}
          />
        </div>
      )}
    </div>
  );
}

export default EnvironmentalOptions;
