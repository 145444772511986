import { Link } from 'components';
import Button from 'components/Button';
import Card from 'components/Card';
import { useToastContext } from 'providers/ToastProvider';

/**
 * Config
 */
const display = {
  title: 'Toast'
};

/**
 * Component
 */
function ToastPage() {
  // state
  const { toast } = useToastContext();
  // render
  return (
    <Card className="my-8 p-6" data-testid="component-page_modal">
      <h5 className="text-2xl font-bold text-secondary-2-100">
        {display.title}
      </h5>
      <hr className="my-4" />
      <div className="my-4 flex justify-center gap-2">
        <Button
          onClick={() =>
            toast({
              kind: 'info',
              message: (
                <span>
                  <b>Info:</b> TEST
                </span>
              )
            })
          }
          color="lightBlue"
          kind="text"
          data-testid="open0"
        >
          Info toast
        </Button>
        <Button
          onClick={() =>
            toast({
              kind: 'success',
              message: (
                <div>
                  <b>SUCCESS</b>
                  <hr className="border-success-40" />
                  <p>This is a success</p>
                </div>
              ),
              hasClose: true
            })
          }
          color="lightBlue"
          kind="text"
          data-testid="open1"
        >
          Success toast
        </Button>
      </div>
      <div className="my-4 flex justify-center gap-2">
        <Button
          onClick={() =>
            toast({
              kind: 'warn',
              message: 'This is a warning!',
              button: { display: 'edit', action: () => console.log('EDIT') },
              timer: 0
            })
          }
          color="lightBlue"
          kind="text"
          data-testid="open0"
        >
          Warning toast
        </Button>
        <Button
          onClick={() =>
            toast({
              kind: 'error',
              message: (
                <span>
                  <b>Error: </b>Test error toast. <Link to="/">Click here</Link>{' '}
                  to go home
                </span>
              )
            })
          }
          color="lightBlue"
          kind="text"
          data-testid="open1"
        >
          Error toast
        </Button>
      </div>
    </Card>
  );
}

export default ToastPage;
