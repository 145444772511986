import { useTranslation } from 'react-i18next';

import useScreenSize from 'hooks/useScreenSize';
import heroDesktop from 'images/bigDog/heroDesktop.jpg';
import heroMobile from 'images/bigDog/heroMobile.jpg';

/**
 * Component
 */
function BigDogHeader() {
  /**
   * Custom Hooks
   */
  const { isSmallScreen } = useScreenSize();
  const { t } = useTranslation();

  /**
   * Render
   */
  return (
    <section data-testid="bigdog-section-header">
      <div className="max-w-[1440px] flex">
        <div
          className="w-full h-[334px] bg-center bg-no-repeat bg-[contain,_cover] md:bg-[center_right_15%] md:bg-cover md:h-[275px]"
          style={{
            backgroundImage: `url(${isSmallScreen ? heroMobile : heroDesktop})`
          }}
          data-testid="bigdog-banner"
        />
      </div>
      <h1 className="mx-48 mt-[72px] text-common-black text-4xl leading-[1.2] font-extrabold font-metropolis text-center md:mx-[13.6px] md:mt-16 md:text-2xl">
        {t('bigDogMarketing.introParagraph')}
      </h1>
      <h2 className="mx-[220px] text-etc-big-dog-orange text-4xl leading-[1.2] font-extrabold font-metropolis text-center md:mx-[13.6px] md:text-2xl">
        {t('bigDogMarketing.paragraphOne')}
      </h2>
    </section>
  );
}

export default BigDogHeader;
