import { Breadcrumbs, Container, Hidden } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useAccountPageContext } from 'pages/Account/AccountPageProvider';
import EditAccount from 'pages/Account/sub/EditAccount';
import ViewAccount from 'pages/Account/sub/ViewAccount';
import ViewCompanyInfo from 'pages/Account/sub/ViewCompanyInfo';
import { Button, Loader, Link } from 'components';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { ChevronLeftIcon, ChevronRightIcon } from 'icons';
import { embedding } from 'utils/embedPage';

/**
 * Component
 */
function AccountPage() {
  // Check if we are embedding the current page.
  const embeddingPage = embedding();

  /**
   * Custom Hooks
   */
  const history = useHistory();
  const { t } = useTranslation();
  useDocumentTitle(t('common.accountManagement'));

  /**
   * Context
   */
  const { accountQuery, isEditing, loading, userInfo, userQuery } =
    useAccountPageContext();

  /**
   * Callbacks
   */
  // 🟤 Cb - Navigate home
  const navigateHome = () => history.push('/');

  /**
   * Render
   */
  if (loading || !userQuery?.user || !accountQuery?.account?.[0]) {
    return <Loader testId="accountpage-loader" />;
  }
  return (
    <div className="mb-10" data-testid="accountpage-wrapper">
      <Container maxWidth="lg" className="md:!px-0">
        <div className="grid grid-cols-2 gap-8">
          {/* Header - Conditionally render if embeddingPage is false; otherwise, we are embedding a screen for Max Mobile, so we do not want to display this element */}
          {!embeddingPage && (
            <div className="col-span-2">
              <Hidden mdUp>
                <Button
                  className="mt-2"
                  iconStart={<ChevronLeftIcon />}
                  kind="text"
                  onClick={navigateHome}
                  data-testid="accountpage-home-button"
                >
                  {t('common.backToHome')}
                </Button>
              </Hidden>
              <Hidden mdDown>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  separator={<ChevronRightIcon />}
                >
                  <Link to="/" className="!text-primary-1-100">
                    {t('common.home')}
                  </Link>
                  <span className="text-inherit">
                    {t('common.accountInfo')}
                  </span>
                </Breadcrumbs>
              </Hidden>
            </div>
          )}

          {/* Title */}
          <div className="col-span-2">
            <div className="mb-4 md:pl-6">
              <h4 className="text-2xl font-medium text-primary-3-100">{`${userInfo.firstName} ${userInfo.lastName}`}</h4>
            </div>
          </div>
          {/* Account Info */}
          <div className="col-span-1 md:col-span-2">
            {isEditing ? <EditAccount /> : <ViewAccount />}
          </div>
          {/* Company info */}
          <div className="col-span-1 md:col-span-2">
            <ViewCompanyInfo />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default AccountPage;
