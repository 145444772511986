export type CompanyDomain = {
  link: string;
  list: string;
  sub: string;
  engine: string;
  sf: string;
  divisionEnum?: string;
};

export const waterworksCompany: CompanyDomain = {
  link: 'Fortiline',
  list: 'Fortiline Waterworks',
  sub: 'fortiline',
  engine: 'waterworks',
  sf: 'Fortiline'
};

export const expressionsHomeGallery: CompanyDomain = {
  link: 'Expressions Home Gallery',
  list: 'Expressions Home Gallery',
  sub: 'expressionshomegallery',
  engine: 'bath_kitchen',
  sf: 'Expressions Home Gallery'
};

export const schumacherAndSeilerCompany: CompanyDomain = {
  link: 'Schumacher & Seiler',
  list: 'Schumacher & Seiler',
  sub: 'schumacherseiler',
  engine: 'plumbing_hvac',
  sf: 'Schumacher & Seiler'
};

export const mainCompanyList: CompanyDomain[] = [
  {
    link: 'Morrison Supply Company',
    list: 'Morrison Supply',
    sub: 'morrisonsupply',
    engine: 'plumbing_hvac',
    sf: 'Morrison Supply'
  },
  {
    link: 'DeVore & Johnson',
    list: 'DeVore & Johnson',
    sub: 'devoreandjohnson',
    engine: 'plumbing_hvac',
    sf: 'DeVore And Johnson'
  },
  {
    link: 'Murray Supply',
    list: 'Murray Supply Company',
    sub: 'murraysupply',
    engine: 'plumbing_hvac',
    sf: 'Murray Supply'
  },
  {
    link: 'Morsco HVAC',
    list: 'Morsco HVAC Supply',
    sub: 'morscohvacsupply',
    engine: 'plumbing_hvac',
    sf: 'Morsco Hvac'
  },
  {
    link: 'Farnsworth Wholesale',
    list: 'Farnsworth Wholesale',
    sub: 'fwcaz',
    engine: 'plumbing_hvac',
    sf: 'Farnsworth'
  },
  expressionsHomeGallery,
  waterworksCompany,
  {
    link: 'L&B Pipe',
    list: 'L&B Pipe',
    sub: 'landbpipe',
    engine: 'plumbing_hvac',
    sf: 'L&B Pipe'
  },
  {
    link: 'Irvine Pipe Supply',
    list: 'Irvine Pipe & Supply',
    sub: 'irvinepipe',
    engine: 'plumbing_hvac',
    sf: 'Irvine Pipe Supply'
  },
  schumacherAndSeilerCompany
];

export const defaultCompany: CompanyDomain = {
  link: 'Reece',
  list: 'Reece',
  sub: 'reece',
  engine: 'plumbing_hvac',
  sf: ''
};

export function isCommonSubdomain(subdomain: string) {
  return (
    subdomain === 'localhost' || subdomain === 'reece' || subdomain === 'app'
  );
}
