import { TableRendererProps } from 'common/TablePageLayout/TableRenderer';
import { CircularProgress, Link } from 'components';

/**
 * Component
 */
function TableRendererBodyLoading<TData extends object>(
  props: TableRendererProps<TData>
) {
  /**
   * Props
   */
  const {
    isWaterworks,
    loading,
    noResultsContactBranch,
    noResultsContactMessage,
    noResultsMessage
  } = props;

  /**
   * Render
   */
  return (
    <tbody data-testid="table-loading-wrapper">
      <tr>
        <td colSpan={6}>
          <div className="px-6 py-16 flex justify-center text-center">
            {loading ? (
              <CircularProgress
                size={50}
                color="secondary"
                testId="tablerender-loading"
              />
            ) : isWaterworks ? (
              <div data-testid="tablerender-loading-noresult-waterworks">
                <h4 className="text-secondary-3-100 text-2xl font-medium">
                  {noResultsMessage}
                </h4>
                <h5 className="text-secondary-3-100 text-xl font-medium">
                  {noResultsContactMessage}{' '}
                  <Link to="/support">{noResultsContactBranch}</Link>
                </h5>
              </div>
            ) : (
              <h4 className="text-secondary-2-100 text-2xl font-bold">
                {noResultsMessage}
              </h4>
            )}
          </div>
        </td>
      </tr>
    </tbody>
  );
}

export default TableRendererBodyLoading;
