import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Image } from 'components';
import { Product } from 'generated/graphql';
import { makeProductSlug } from 'Cart/util';

/**
 * Types
 */
export type SearchTopResultProps = {
  product?: Product;
  loading?: boolean;
  onClick?: (id: string, productSlug: string) => void;
};

/**
 * Component
 */
function SearchTopResult(props: SearchTopResultProps) {
  /**
   * Props
   */
  const { loading, onClick, product } = props;
  /**
   * Custom hooks
   */
  const { t } = useTranslation();

  /**
   * Callback
   */
  const handleProductClick = () => {
    if (onClick && product) {
      const productSlug = makeProductSlug(
        product.manufacturerName,
        product.categories?.at(-1)
      );
      onClick(product.id, productSlug);
    }
  };

  /**
   * Render
   */
  if (loading) {
    return (
      <div
        className="flex px-2 py-2 hover:cursor-pointer hover:bg-primary-3-10"
        data-testid="header-searchtopresults-loading"
      >
        <div className="pr-4 h-20 flex">
          <Skeleton variant="rectangular" height={64} width={64} />
        </div>
        <div className="flex-grow flex flex-col">
          <Skeleton width="40%" />
          <Skeleton />
          <Skeleton width="60%" />
        </div>
      </div>
    );
  }
  return (
    <div
      className="flex px-2 py-2 hover:cursor-pointer hover:bg-primary-3-10"
      onClick={handleProductClick}
      data-testid="header-searchtopresults-wrapper"
    >
      {/* Image Container */}
      <div className="pr-4 flex">
        <div className="w-16 h-16">
          <Image
            alt={product?.name ?? t('common.productPicture')}
            src={product?.imageUrls?.thumb ?? t('common.productPicture')}
          />
        </div>
      </div>
      {/* Details Container */}
      <div className="flex-grow flex flex-col">
        <span
          className="block text-caption pb-1"
          data-testid={`product-mfrName-${product?.id}`}
        >
          {product?.manufacturerName}
        </span>
        <span
          className="block text-base leading-[1.2rem] font-semibold"
          data-testid={`product-name-${product?.id}`}
        >
          {product?.name}
        </span>
        <span
          className="block text-caption"
          data-testid={`product-mfrNumber-${product?.id}`}
        >
          {`${t('contract.mfrNum')} ${product?.manufacturerNumber ?? '-'}`}
        </span>
      </div>
    </div>
  );
}

export default SearchTopResult;
