import { Maybe } from 'yup/lib/types';

/**
 * Config
 */
export const NOT_IN_ECLIPSE = 'NotInEclipse';
export const NOT_AVAILABLE_STATS = [
  'Delete',
  'Purge',
  'NonPDW',
  'Discontinued',
  'Comment',
  'NotInElastic',
  NOT_IN_ECLIPSE
];

/**
 * getProductAvailability
 * @param productStatus - product status
 * @param override - will return as not available if true
 * @returns availability in boolean
 */
function getProductAvailability(
  productStatus: Maybe<string>,
  override: boolean
) {
  const status = override ? NOT_IN_ECLIPSE : productStatus;
  return !NOT_AVAILABLE_STATS.includes(status ?? '');
}
export default getProductAvailability;
