import { useState, useEffect, useCallback } from 'react';

import { Builder, builder, useIsPreviewing } from '@builder.io/react';
import { BuilderContent } from '@builder.io/sdk';

import BuilderButton from 'pages/BuilderIO/components/Button';

/**
 * Hook
 */
function useBuilderIOGetAllAPI(model: string, query: object, skip?: boolean) {
  /**
   * States
   */
  // 🔸 Due to BuilderIO's library has poor Typescript support and there isn't anyway for us to fix it, we have to use `any`
  const isPreviewingInBuilder = useIsPreviewing();
  const [contents, setContents] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  /**
   * Callback
   */
  const fetch = useCallback(
    () =>
      new Promise<BuilderContent['data'][]>((resolve) => {
        builder
          .getAll(model, { query })
          // Apply content
          .then((dataList) => {
            setLoading(false);
            const parsedData = dataList.map(({ data }) => ({ ...data }));
            setContents(parsedData);
            resolve(parsedData);
          })
          // Error
          .catch((error) => {
            setError(true);
            console.error('Error fetching content:', error);
            resolve([]);
          });
      }),
    [model, query]
  );

  /**
   * Effects
   */
  // 🟡 Effect - register Builder components
  useEffect(() => {
    // Register the CustomButton component with Builder.io
    Builder.registerComponent(BuilderButton, { name: 'Button' });
  }, []);
  // 🟡 Effect - API call
  useEffect(() => {
    // Fetch content from Builder CMS
    !skip && fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip]);

  /**
   * Output
   */
  return { contents, error, isPreviewingInBuilder, loading, refetch: fetch };
}

export default useBuilderIOGetAllAPI;
