import { forwardRef } from 'react';

import { IMaskInput } from 'react-imask';

import { Modify } from '@reece/global-types';

/**
 * Types
 */
export type IMaskInputProps = Parameters<typeof IMaskInput>[0];
type NewProps = {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name?: string;
};
export type BaseInputMaskProps = Modify<IMaskInputProps, NewProps>;

/**
 * Component
 */
const BaseMaskedInput = forwardRef<HTMLInputElement, BaseInputMaskProps>(
  (props, ref) => {
    /**
     * Props
     */
    const { name, onChange, ...rest } = props;

    /**
     * Render
     */
    return (
      <IMaskInput
        // Due to leaky abstration of IMaskInput, type ignore has to be implemented
        // @ts-ignore
        inputRef={ref}
        onAccept={
          // istanbul ignore next
          (v: unknown) =>
            onChange({ target: { name: name ?? '', value: v as string } })
        }
        overwrite
        {...rest}
      />
    );
  }
);

export default BaseMaskedInput;
