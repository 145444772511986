import { ReactNode } from 'react';

import { VariableSizeList } from 'react-window';

import VirtualizedListItem from 'common/VirtualizedList/sub/VirtualizedListItem';
import { useVirtualizedListHeight } from 'common/VirtualizedList/lib/useVirtualizedListHeight';

/**
 * Types
 */
export type VirtualizedListProps<TData> = {
  defaultItemSize: number;
  maxHeight: number;
  dataArray: TData[];
  renderItem: (data: TData, itemIndex: number) => ReactNode;
  width?: number | string;
};

/**
 * Component
 */
function VirtualizedList<TData>(props: VirtualizedListProps<TData>) {
  /**
   * Props
   */
  const { dataArray, defaultItemSize, maxHeight, renderItem, width } = props;

  /**
   * Custom hooks
   */
  const { getItemHeight, handleSetHeight, listHeight, listRef } =
    useVirtualizedListHeight({ defaultItemSize, maxHeight });

  /**
   * Render
   */
  return (
    <VariableSizeList
      itemData={props.dataArray}
      height={listHeight}
      width={width ?? '100%'}
      ref={listRef}
      itemSize={getItemHeight}
      estimatedItemSize={defaultItemSize}
      itemCount={dataArray.length}
    >
      {({ style, data, index }) => (
        <VirtualizedListItem
          wrapperStyle={style}
          setHeight={handleSetHeight(index)}
        >
          {renderItem(data[index], index)}
        </VirtualizedListItem>
      )}
    </VariableSizeList>
  );
}

export default VirtualizedList;
