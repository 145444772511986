import {
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemText
} from '@mui/material';

import Accordion from 'components/Accordion';
import AccordionWrapper from 'components/AccordionWrapper';
import Card from 'components/Card';
import { ArrowDropDownIcon } from 'icons';

/**
 * Config
 */
const data = [
  { label: 'Property 1', value: 'value 1' },
  { label: 'Property 2', value: 'value 2' },
  { label: 'Property 3', value: 'value 3' }
];
const display = {
  title: 'Accordion',
  default: 'Default Type',
  product: 'Product Type',
  filter: 'Filter Type',
  wrapper: 'Accordion Wrapper Component'
};

/**
 * Component
 */
function AccordionPage() {
  return (
    <Card className="my-8 p-6">
      <h5 className="text-2xl font-bold text-secondary-2-100">
        {display.title}
      </h5>
      <hr className="my-4" />
      <div className="my-4">
        <Accordion type="default">
          <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
            {display.default}
          </AccordionSummary>
          <AccordionDetails>
            <AccordionDetailsContent />
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="my-4">
        <Accordion type="product">
          <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
            {display.product}
          </AccordionSummary>
          <AccordionDetails>
            <List>
              <AccordionDetailsContent />
            </List>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="my-4">
        <Accordion type="filter">
          <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
            {display.filter}
          </AccordionSummary>
          <AccordionDetails>
            <AccordionDetailsContent />
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="my-4">
        <AccordionWrapper label={display.wrapper} variant="warning">
          <AccordionDetailsContent />
        </AccordionWrapper>
      </div>
    </Card>
  );
}

export default AccordionPage;

/**
 * Sub-Component
 */
function AccordionDetailsContent() {
  return (
    <List>
      {data.map((item) => (
        <ListItem disableGutters key={`accordion-filter-${item.label}`}>
          <ListItemText>
            <span className="text-sm text-primary-1-100 font-bold mr-4">
              {item.label}
            </span>
            <span className="text-sm text-primary-3-100">{item.value}</span>
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
}
