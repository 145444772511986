export const baseOutlineStyle =
  '[&_.MuiBadge-badge]:shadow-[inset_0_0_0_1px_rgba(0,0,0,0)] ';
export const themedStyles = {
  fill: {
    primary:
      '[&_.MuiBadge-badge]:text-common-white [&_.MuiBadge-badge]:bg-primary-1-100',
    secondary:
      '[&_.MuiBadge-badge]:text-common-white [&_.MuiBadge-badge]:bg-primary-2-100',
    gray: '[&_.MuiBadge-badge]:text-common-white [&_.MuiBadge-badge]:bg-primary-3-100',
    green:
      '[&_.MuiBadge-badge]:text-common-white [&_.MuiBadge-badge]:bg-success-100',
    yellow:
      '[&_.MuiBadge-badge]:text-secondary-2-100 [&_.MuiBadge-badge]:bg-secondary-1-100',
    red: '[&_.MuiBadge-badge]:text-common-white [&_.MuiBadge-badge]:bg-error-100'
  },
  strokeFill: {
    primary:
      baseOutlineStyle +
      '[&_.MuiBadge-badge]:text-primary-1-100 [&_.MuiBadge-badge]:bg-primary-1-10 [&_.MuiBadge-badge]:shadow-primary-1-100',
    secondary:
      baseOutlineStyle +
      '[&_.MuiBadge-badge]:text-primary-2-100 [&_.MuiBadge-badge]:bg-primary-2-10 [&_.MuiBadge-badge]:shadow-primary-2-100',
    gray:
      baseOutlineStyle +
      '[&_.MuiBadge-badge]:text-primary-3-100 [&_.MuiBadge-badge]:bg-primary-3-10 [&_.MuiBadge-badge]:shadow-primary-3-100',
    green:
      baseOutlineStyle +
      '[&_.MuiBadge-badge]:text-success-100 [&_.MuiBadge-badge]:bg-success-10 [&_.MuiBadge-badge]:shadow-success-100',
    yellow:
      baseOutlineStyle +
      '[&_.MuiBadge-badge]:text-secondary-2-100 [&_.MuiBadge-badge]:bg-secondary-1-20 [&_.MuiBadge-badge]:shadow-secondary-1-100',
    red:
      baseOutlineStyle +
      '[&_.MuiBadge-badge]:text-error-100 [&_.MuiBadge-badge]:bg-error-10 [&_.MuiBadge-badge]:shadow-error-100'
  },
  stroke: {
    primary:
      baseOutlineStyle +
      '[&_.MuiBadge-badge]:text-primary-1-100 [&_.MuiBadge-badge]:bg-common-white [&_.MuiBadge-badge]:shadow-primary-1-100',
    secondary:
      baseOutlineStyle +
      '[&_.MuiBadge-badge]:text-primary-2-100 [&_.MuiBadge-badge]:bg-common-white [&_.MuiBadge-badge]:shadow-primary-2-100',
    gray:
      baseOutlineStyle +
      '[&_.MuiBadge-badge]:text-primary-3-100 [&_.MuiBadge-badge]:bg-common-white [&_.MuiBadge-badge]:shadow-primary-3-100',
    green:
      baseOutlineStyle +
      '[&_.MuiBadge-badge]:text-success-100 [&_.MuiBadge-badge]:bg-common-white [&_.MuiBadge-badge]:shadow-success-100',
    yellow:
      baseOutlineStyle +
      '[&_.MuiBadge-badge]:text-secondary-2-100 [&_.MuiBadge-badge]:bg-common-white [&_.MuiBadge-badge]:shadow-secondary-1-100',
    red:
      baseOutlineStyle +
      '[&_.MuiBadge-badge]:text-error-100 [&_.MuiBadge-badge]:bg-common-white [&_.MuiBadge-badge]:shadow-error-100'
  }
};
