const baseStyle = [
  'm-0 before:hidden',
  '[&.Mui-expanded]:!m-0',
  '[&_.MuiButtonBase-root]:!min-h-[48px]'
];

export const defaultStyle = [
  ...baseStyle,
  '[&_.MuiAccordionSummary-content]:!my-3',
  '[&_.MuiAccordionSummary-content.Mui-expanded]:!my-3',
  '[&_.MuiAccordionDetails-root]:pt-0'
];

export const productStyle = [
  ...baseStyle,
  'font-medium',
  '[&_.MuiButtonBase-root]:px-0',
  '[&_.MuiButtonBase-root]:border-b',
  '[&_.MuiButtonBase-root]:border-solid',
  '[&_.MuiButtonBase-root]:border-secondary-2-100',
  '[&_.MuiButtonBase-root]:items-end',
  '[&_.MuiButtonBase-root.Mui-expanded]:border-primary-1-100',
  '[&_.MuiAccordionSummary-content]:!my-0',
  '[&_.MuiAccordionSummary-content.Mui-expanded]:!my-0',
  '[&_.MuiAccordionSummary-content.Mui-expanded]:text-secondary-2-100',
  '[&_.MuiAccordionDetails-root]:px-0'
];

export const filterStyle = [
  ...baseStyle,
  'font-medium border-b border-solid border-secondary-3-100',
  '[&.Mui-expanded]:bg-secondary-4-30',
  '[&_.MuiButtonBase-root]:!px-10',
  '[&_.MuiButtonBase-root]:!py-0',
  '[&_.MuiAccordionSummary-content]:!my-0',
  '[&_.MuiAccordionSummary-content]:text-primary-1-100'
];
