import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { OnInputChange, OnInputKeyboard } from '@reece/global-types';

import { IconButton, TextInput } from 'components';
import { SearchIcon } from 'icons';
import { useListsPageContext } from 'pages/Lists/provider/ListsPageProvider';

/**
 * Types
 */
type ListsSearchItemProps = {
  deselectAll: () => void;
};

/**
 * Component
 */
function ListsSearchItem(props: ListsSearchItemProps) {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();

  /**
   * Context
   */
  const { listProducts, setSearchListValue } = useListsPageContext();
  const itemCount = listProducts.length;

  /**
   * State
   */
  const [value, setValue] = useState('');
  const invalidValue = !value.length;

  /**
   * Callbacks
   */
  // 🟤 Cb - handle search term change
  const handleOnChange = (e: OnInputChange) => {
    const { value: val } = e.currentTarget;
    setValue(val);
  };

  // 🟤 Cb - search
  const search = () => {
    setSearchListValue(value);
    props.deselectAll();
  };
  // 🟤 Cb - call search list query on enter
  const handleKeyDown = (event: OnInputKeyboard) => {
    event.key === 'Enter' && search();
  };

  // 🟤 Cb - clear search term
  const handleClearSearch = () => {
    setSearchListValue('');
    setValue('');
    props.deselectAll();
  };

  return (
    <div className="basis-7/12 md:flex-1">
      <TextInput
        label={t('lists.searchThisList')}
        iconStart={
          <IconButton onClick={search} disabled={invalidValue}>
            <SearchIcon className="cursor-pointer" />
          </IconButton>
        }
        iconStartClassName=" text-primary-1-100"
        placeholder={t('lists.searchThisListPlaceholder')}
        value={value}
        onChange={handleOnChange}
        onKeyDown={handleKeyDown}
        onClear={handleClearSearch}
        disabled={!itemCount}
        testId="list-search-items-input"
      />
    </div>
  );
}

export default ListsSearchItem;
