import React from 'react';
import { match } from 'path-to-regexp';
import { Portal } from '@headlessui/react';
import { Login, Logout, Search, Support } from '@mui/icons-material';
import Cart from 'Cart';
import CategoryPage from 'Categories';
import Checkout from 'Checkout';
import Company from 'Company';
import Contract from 'Contract';
import Contracts from 'Contracts';
import CreditCardCallback from 'CreditCard/CreditCardCallback';
import CreditForms from 'CreditForms';
import CustomerApproval from 'CustomerApproval';
import ForgotPassword from 'ForgotPassword';
import Invite from 'Invite';
import Invoice from 'Invoice';
import Invoices from 'Invoices';
import JobForm from 'JobForm';
import Legal from 'Legal';
import DoNotSellMyInfo from 'Legal/DoNotSellMyInfo';
import Lists from 'pages/Lists';
import NewOrders from 'pages/Orders';
import Order from 'pages/Orders/OrderDetails';
import PaymentInformation from 'PaymentInformation';
import PreviouslyPurchasedProducts from 'PreviouslyPurchasedProducts';
import PurchaseApprovals from 'PurchaseApprovals';
import ReviewPurchase from 'PurchaseApprovals/ReviewPurchase';
import Quote from 'Quote';
import Quotes from 'Quotes';
import RegisterOld from 'Register-old';
import VerifyEmail from 'Register-old/VerifyEmail';
import User from 'User';
import UserManagement from 'UserManagement';
import WorksForYou from 'WorksForYou';
import Account from 'pages/Account';
import Appointments from 'pages/Appointments';
import ModifyAppointment from 'pages/Appointments/ModifyAppointment';
import BranchManagement from 'pages/BranchManagement';
import BigDogMarketing from 'pages/BrandPages/BigDog';
import PoshMarketing from 'pages/BrandPages/Posh';
import BrandPage from 'pages/Brands';
import Embedded from 'pages/Embedded';
import FeatureToggle from 'pages/FeatureToggle';
import ListUpload from 'pages/ListUpload';
import LocationSearch from 'pages/LocationSearch';
import MigrationSetupMessage from 'pages/MigrationSetupMessage';
import PostLogin from 'pages/PostLogin';
import Product from 'pages/Product';
import { useTranslation } from 'react-i18next';

type SupportedRoute = {
  path: string;
  component: React.ComponentType;
};

// Define the routes supported for embedding
export const supportedRoutes: SupportedRoute[] = [
  { path: '/orders', component: NewOrders },
  { path: '/account', component: Account },
  { path: '/appointments', component: Appointments },
  { path: '/migration-setup-message', component: MigrationSetupMessage },
  { path: '/branch-management', component: BranchManagement },
  { path: '/brands', component: BrandPage },
  { path: '/brands/bigdog', component: BigDogMarketing },
  { path: '/brands/posh', component: PoshMarketing },
  { path: '/cart', component: Cart },
  { path: '/checkout', component: Checkout },
  { path: '/company', component: Company },
  { path: '/contract', component: Contract },
  { path: '/contracts', component: Contracts },
  { path: '/credit-card-callback', component: CreditCardCallback },
  { path: '/credit-forms', component: CreditForms },
  { path: '/customer-approval', component: CustomerApproval },
  { path: '/feature-toggle', component: FeatureToggle },
  { path: '/forgot-password', component: ForgotPassword },
  { path: '/category', component: CategoryPage },
  { path: '/invite', component: Invite },
  { path: '/invoice', component: Invoice },
  { path: '/invoices', component: Invoices },
  { path: '/legal', component: Legal },
  { path: '/do-not-sell-my-info', component: DoNotSellMyInfo },
  { path: '/lists', component: Lists },
  { path: '/list-upload', component: ListUpload },
  { path: '/location-search', component: LocationSearch },
  { path: '/login', component: Login },
  { path: '/logout', component: Logout },
  { path: '/modify-appointment', component: ModifyAppointment },
  { path: '/orders', component: NewOrders },
  { path: '/order/:id', component: Order },
  { path: '/payment-information', component: PaymentInformation },
  { path: '/portal', component: Portal },
  {
    path: '/previously-purchased-products',
    component: PreviouslyPurchasedProducts
  },
  { path: '/product', component: Product },
  { path: '/purchase-approvals', component: PurchaseApprovals },
  { path: '/review-purchase', component: ReviewPurchase },
  { path: '/quote', component: Quote },
  { path: '/quotes', component: Quotes },
  { path: '/register-old', component: RegisterOld },
  { path: '/verify-email', component: VerifyEmail },
  { path: '/search', component: Search },
  { path: '/post-login', component: PostLogin },
  { path: '/support', component: Support },
  { path: '/user', component: User },
  { path: '/user-management', component: UserManagement },
  { path: '/works-for-you', component: WorksForYou },
  { path: '/job-form', component: JobForm },
  { path: '/embedded', component: Embedded }
];

export const getSupportedRoute = (route: string) =>
  supportedRoutes.find((r) => {
    const matchRoute = match(r.path, { decode: decodeURIComponent });
    return matchRoute(route); // Attempt to match the provided route
  });

// Function to check and use the route if it is supported
export const useSupportedRoute = (route: string) => {
  const { t } = useTranslation();
  const routeFound = getSupportedRoute(route);
  if (!routeFound) {
    return null;
  }
  return routeFound.path ? (
    <routeFound.component />
  ) : (
    <div>
      <h1>{t('embed.routeNotSupported')}</h1>
    </div>
  );
};
