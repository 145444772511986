import { Grid, Skeleton, Typography } from '@dialexa/reece-component-library';

type Props = {
  skeletonWidth: number;
  data: string;
  isSmallScreen: boolean;
  loading: boolean | undefined;
  dataId: string;
};

export default function InvoiceItemDataSection(props: Props) {
  return (
    <Grid item md xs={3}>
      <Typography
        variant="subtitle2"
        align="center"
        display="flex"
        justifyContent="center"
        data-testid={props.dataId}
        pt={props.isSmallScreen ? 2 : 2.75}
      >
        {props.loading ? <Skeleton width={props.skeletonWidth} /> : props.data}
      </Typography>
    </Grid>
  );
}
