import { useEffect } from 'react';

import { Redirect } from 'react-router-dom';

import { Loader } from 'components';
import { useAuthContext } from 'providers/AuthProvider';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';

/**
 * Component
 */
function Logout() {
  /**
   * Context
   */
  const { authState, handleLogout, isLoggingOut } = useAuthContext();
  const { setAccountsModalOpen } = useSelectedAccountsContext();

  /**
   * Effect
   */
  useEffect(() => {
    handleLogout?.();
    setAccountsModalOpen(false);
  }, [handleLogout, setAccountsModalOpen]);

  /**
   * Render
   */
  if (authState?.isAuthenticated || isLoggingOut) {
    return (
      <div className="h-full flex justify-center" data-testid="logout">
        <Loader size="flex" />
      </div>
    );
  }
  return <Redirect to="/" />;
}

export default Logout;
