import { useMemo } from 'react';

import useFooterData from 'common/Footer/lib/useFooterData';
import FooterLink from 'common/Footer/sub/FooterLink';

/**
 * Component
 */
function FooterLinks() {
  /**
   * Custom Hooks
   */
  const footerData = useFooterData();

  /**
   * Memo
   */
  const renderFooterData = useMemo(
    () =>
      Object.entries(footerData).map(([key, list]) => {
        const filteredList = list.filter(({ condition }) => condition);
        return (
          <div
            key={`footer-links-${key}`}
            className="col-span-2 md:col-span-3 md:mx-2 sm:col-span-6"
          >
            <h5 className="text-primary-1-100 text-xl leading-8 font-medium">
              {key}
            </h5>
            {filteredList.map((item, index) => (
              <div className="my-4" key={`footer-link-${key}-${index}`}>
                <FooterLink item={item} />
              </div>
            ))}
          </div>
        );
      }),
    [footerData]
  );

  /**
   * Render
   */
  return <div className="grid grid-cols-6 gap-2">{renderFooterData}</div>;
}

export default FooterLinks;
