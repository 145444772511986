import { Fragment, useMemo } from 'react';

import { Collapse, List, ListItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { WrapperProps } from '@reece/global-types';
import useNavDataMemo, { NavData } from 'common/OldHeader/lib/useNavDataMemo';
import { ArrowDropDownIcon, ArrowDropUpIcon } from 'icons';

/**
 * Types
 */
export type MobileDrawerCollapseListProps = WrapperProps & {
  item: keyof NavData;
  open: boolean;
  setOpen: (v: boolean) => void;
  urlHandler: (path: string, auth?: boolean) => () => void;
};

/**
 * Component
 */
function MobileDrawerCollapseList(props: MobileDrawerCollapseListProps) {
  /**
   * Props
   */
  const { children, item, open, setOpen, urlHandler } = props;

  /**
   * Custom hooks
   */
  const { t } = useTranslation();

  /**
   * Memo
   */
  // 🔵 Memo - Nav data
  const list = useNavDataMemo()[item];
  // 🔵 Memo - Rendered list item
  const renderedList = useMemo(
    () =>
      list.map(({ comingSoon, condition, id, name, to }, index) => (
        <Fragment key={`mobile-nav-${item}-${index}`}>
          {condition && (
            <ListItem onClick={urlHandler(to)} data-testid={`mobile-nav-${id}`}>
              {name}
              {comingSoon && ` - ${t('common.comingSoon')}`}
            </ListItem>
          )}
        </Fragment>
      )),
    [item, list, t, urlHandler]
  );

  /**
   * Render
   */
  return (
    <>
      <ListItem
        className="!px-8 !py-4"
        data-testid={`${item}-dropdown-button`}
        onClick={() => setOpen(!open)}
      >
        {children}
        {open ? (
          <ArrowDropUpIcon className="text-primary-1-100" />
        ) : (
          <ArrowDropDownIcon className="text-primary-1-100" />
        )}
      </ListItem>
      <Collapse in={open}>
        <List className="!ml-9">{renderedList}</List>
      </Collapse>
    </>
  );
}

export default MobileDrawerCollapseList;
