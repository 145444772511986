import { useEmbeddedContext } from 'providers/EmbeddedProvider';
import { useSupportedRoute } from 'pages/Embedded/supportedRoutes';

function Embedded() {
  const { embeddedSession } = useEmbeddedContext();

  // Generate the route elements
  return <>{useSupportedRoute(embeddedSession.path)}</>;
}

export default Embedded;
