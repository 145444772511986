import { useEffect, useState } from 'react';

import { useMediaQuery, useTheme } from '@mui/material';

/**
 * Hook
 */
function useScreenSize() {
  /**
   * Custom hooks
   */
  const theme = useTheme();
  // misc
  const isPrint = useMediaQuery('print');

  /**
   * States
   */
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  /**
   * Effect
   */
  useEffect(() => {
    // istanbul ignore next
    const handleResize = () => setWindowDimensions(getWindowDimensions());
    window.addEventListener('resize', handleResize);
    // istanbul ignore next
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  /**
   * Output
   */
  return {
    windowDimensions,
    isMobile: windowDimensions.width < theme.breakpoints.values.sm,
    isPrint,
    isSmallScreen: windowDimensions.width < theme.breakpoints.values.md,
    isTablet: windowDimensions.width < theme.breakpoints.values.lg
  };
}

/**
 * Lib
 */
export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
}

export default useScreenSize;
