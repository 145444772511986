// istanbul ignore file
import { useMemo, useState } from 'react';

import { Skeleton } from '@mui/material';
import { isNil } from 'lodash-es';
import Dotdotdot from 'react-dotdotdot';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Maybe } from 'yup/lib/types';

import { ProductPricing } from 'API/types/products.types';
import { SearchProduct } from 'API/types/search.types';
import { makeProductSlug } from 'Cart/util';
import { AdvancedToolTip, FeedbackButton, Image, Link } from 'components';
import AddToListbutton from 'common/AddToListButton';
import AvailabilityChip from 'common/AvailabilityChip';
import CustomerPartNumber from 'common/CustomerPartNumber';
import QtyInput from 'common/QtyInput';
import { useLocalStorage } from 'hooks/useLocalStorage';
import useScreenSize from 'hooks/useScreenSize';
import { WarningIcon } from 'icons';
import useSearchQueryParams from 'pages/Search/util/useSearchQueryParams';
import { useAuthContext } from 'providers/AuthProvider';
import { useBranchContext } from 'providers/BranchProvider';
import { useCartContext } from 'providers/CartProvider';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';
import { trackSearchPageOneConversionAction } from 'utils/analytics';
import { format } from 'utils/currency';

/**
 * Types
 */
type Props = {
  product: SearchProduct;
  pricing: ProductPricing[];
  pricingLoading: boolean;
  index: number;
  onClick: () => void;
};

/**
 * Component
 */
function SearchResult(props: Props) {
  /**
   * Custom Hooks
   */
  const { isSmallScreen } = useScreenSize();
  const { t } = useTranslation();
  const [listAddedIds] = useLocalStorage<Maybe<string[]>>(
    'itemsAddedList',
    null
  );

  /**
   * State
   */
  const [quantity, setQuantity] = useState(props.product.minIncrementQty || 1);
  const [availableInList, setAvailableInList] = useState<string[]>([]);

  /**
   * Context
   */
  const { authState, hasFeature, user } = useAuthContext();
  const { addItemToCart, cart, disableAddToCart, cartLoading } =
    useCartContext();
  const { shippingBranch } = useBranchContext();
  const { selectedAccounts } = useSelectedAccountsContext();
  const [params] = useSearchQueryParams();

  /**
   * Memos
   */
  // 🔵 Memo - product pricing
  const productPricing = useMemo(
    () => props.pricing.find((p) => p.productId === props.product.partNumber),
    [props.pricing, props.product.partNumber]
  );
  // 🔵 Memo - exists id in list
  const existId = useMemo(
    () => listAddedIds?.includes?.(props.product.partNumber ?? ''),
    [listAddedIds, props.product.partNumber]
  );

  /**
   * Consts
   */
  const { criteria = '', page = 1 } = params;
  const billToAccountId = selectedAccounts.billToErpAccount?.erpAccountId ?? '';
  const userEmail = user?.email ?? '';
  const productSlug = makeProductSlug(
    props.product?.manufacturerName,
    props.product?.categories?.at(-1)
  );
  const showsPricesEnabled = hasFeature?.('SHOWS_PRICES');

  /**
   * Callbacks
   */
  // 🟤 Cb - add to cart
  const handleAddToCart = async () => {
    await addItemToCart([
      {
        productId: props.product.id,
        qty: quantity,
        minIncrementQty: props.product.minIncrementQty
      }
    ]);
    if ((page === '1' || page === 1) && !isNil(criteria)) {
      trackSearchPageOneConversionAction({
        billTo: billToAccountId,
        searchTerm: criteria,
        conversionType: 'Add to Cart',
        userEmail: userEmail
      });
    }
  };

  /**
   * Render
   */
  // 🟢 Mobile
  if (isSmallScreen) {
    return (
      <div
        className="flex flex-col gap-4"
        data-testid={`search-result-${props.index}`}
      >
        {authState?.isAuthenticated && (
          <div className="w-full flex justify-end items-start">
            <AddToListbutton
              availableInList={availableInList}
              updatedAddedToLists={setAvailableInList}
              partNumber={props.product.partNumber ?? ''}
              quantity={quantity}
              isAddedToList={existId}
              index={props.index}
            />
          </div>
        )}
        <div className="flex justify-center items-center">
          <Link
            to={`/product/${productSlug}${props.product.id}`}
            className="flex justify-center items-center w-1/3 h-5/6"
          >
            <Image
              alt={props.product.name ?? t('product.productName')}
              src={props.product.imageUrls?.thumb ?? ''}
              onClick={props.onClick}
              className="object-contain"
              data-testid={`product-image-${props.index}`}
            />
          </Link>
        </div>
        <div className="w-full">
          <p
            className="text-caption uppercase mb-2"
            data-testid={`product-manufacturer-name-${props.index}`}
          >
            {props.product.manufacturerName}
          </p>
          <div className="mb-2" data-testid={`product-name-${props.index}`}>
            <Dotdotdot clamp={3}>
              <Link to={`/product/${productSlug}${props.product.id}`}>
                <span
                  onClick={props.onClick}
                  className="text-primary-1-100 text-base font-medium break-words"
                >
                  {props.product.name}
                </span>
              </Link>
            </Dotdotdot>
          </div>
          {props.product.manufacturerNumber && (
            <div className="mb-4">
              <p
                className="text-caption"
                data-testid={`product-manufacturer-number-${props.index}`}
              >
                {t('product.mfr')} {props.product.manufacturerNumber}
              </p>
              <CustomerPartNumber
                partNumber={props.product.customerPartNumber}
                stylingForPage="PLP"
              />
            </div>
          )}
          {!authState?.isAuthenticated ? (
            <div className="search-results__cmp-container">
              {showsPricesEnabled && (
                <span
                  className="search-results__cmp-text-content"
                  data-testid={`search-results-cmp-text-content-${props.index}`}
                >
                  {props.product.cmp}
                </span>
              )}
              <p
                className="search-results__cmp-signin-info-text"
                data-testid={`search-results-cmp-signin-info-text-${props.index}`}
              >
                <Link to="/login" component={RouterLink}>
                  <span
                    className="search-results__cmp-signin-link-text"
                    data-testid={`search-results-cmp-signin-link-text-${props.index}`}
                  >
                    {t('search.signIn')}
                  </span>
                </Link>
                {t('search.signInInfo')}
              </p>
            </div>
          ) : (
            <>
              <div className="mb-4">
                <p
                  className="text-primary-1-100 text-xl font-medium my-4"
                  data-testid={`product-price-${props.index}`}
                >
                  {props.pricingLoading ? (
                    <Skeleton
                      variant="rectangular"
                      data-testid={`search-result-pricing-skeleton-${props.index}`}
                    />
                  ) : (
                    Boolean(productPricing?.sellPrice) && (
                      <>
                        {/* {isCompetitiveMarketPrice && (
                          <p
                            className="text-secondary-2-100 text-sm font-bold line-through"
                            data-testid={`product-cmp-${props.index}`}
                          >
                            {format(productPricing?.cmp ?? 0)}{' '}
                          </p>
                        )} */}
                        <span
                          className="text-2xl font-bold lowercase"
                          data-testid={`product-sell-price-${props.index}`}
                        >
                          {format(productPricing!.sellPrice)}
                        </span>
                        <span
                          className="text-base font-bold lowercase"
                          data-testid={`product-each-${props.index}`}
                        >
                          {productPricing!.orderUom ?? t('product.each')}
                        </span>
                        {/* {isCompetitiveMarketPrice && (
                          <p
                            className="text-success-100 text-caption"
                            data-testid={`product-savings-${props.index}`}
                          >
                            {t('common.youSave')}{' '}
                            <b
                              data-testid={`product-saved-price-${props.index}`}
                            >
                              {format(saved.price)}{' '}
                            </b>
                            {`(${saved.percentage}%)`}
                          </p>
                        )} */}
                      </>
                    )
                  )}
                </p>
                <AvailabilityChip
                  branch={shippingBranch}
                  loading={props.pricingLoading}
                  stock={
                    shippingBranch?.isPricingOnly
                      ? productPricing?.totalAvailableQty
                      : productPricing?.branchAvailableQty
                  }
                />
              </div>
              <div className="flex items-center gap-2">
                <QtyInput
                  disabled={
                    props.pricingLoading || !productPricing?.sellPrice || !cart
                  }
                  value={quantity}
                  onUpdate={setQuantity}
                  increment={props.product.minIncrementQty || 1}
                  max={999999}
                  index={props.index}
                  size="sm"
                  fullWidth
                  noDebounce
                  testId={`quantity-input-${props.index}`}
                />
                <FeedbackButton
                  disabled={
                    props.pricingLoading ||
                    !productPricing?.sellPrice ||
                    !cart ||
                    cartLoading
                  }
                  testId={`add-to-cart-button-${props.index}`}
                  fullWidth
                  onClick={handleAddToCart}
                  size="md"
                  value={t('common.addToCart')}
                  valueDone={t('common.addedToCart')}
                  className="h-[37px]"
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
  // 🟢 Desktop
  return (
    <div
      className="mb-4 pb-4 w-full flex flex-col"
      data-testid={`search-result-${props.index}`}
    >
      <div className="p-4 flex-1 flex flex-col">
        {authState?.isAuthenticated && (
          <div className="pb-3">
            <AddToListbutton
              availableInList={availableInList}
              updatedAddedToLists={setAvailableInList}
              partNumber={props.product.partNumber ?? ''}
              quantity={quantity}
              isAddedToList={existId}
              index={props.index}
            />
          </div>
        )}
        <Link
          to={`/product/${productSlug}${props.product.id}`}
          className="flex justify-center items-center pb-6 h-[120px]"
        >
          <Image
            alt={props.product.name ?? '--'}
            src={props.product.imageUrls?.thumb ?? ''}
            onClick={props.onClick}
            data-testid={`product-image-${props.index}`}
          />
        </Link>
        <p
          className="text-caption pb-2 text-secondary-2-100 uppercase"
          data-testid={`product-manufacturer-name-${props.index}`}
        >
          {props.product?.manufacturerName ?? '--'}
        </p>
        <div className="pb-2">
          <Dotdotdot clamp={3}>
            <Link
              to={`/product/${productSlug}${props.product.id}`}
              testId={`product-name-${props.index}`}
            >
              <span
                onClick={props.onClick}
                className="text-primary-1-100 text-base font-medium break-word"
              >
                {props.product.name}
              </span>
            </Link>
          </Dotdotdot>
        </div>
        {props.product.manufacturerNumber && (
          <p
            className="text-caption"
            data-testid={`product-manufacturer-number-${props.index}`}
          >
            {t('product.mfr')} {props.product.manufacturerNumber}
          </p>
        )}
        <CustomerPartNumber
          partNumber={props.product.customerPartNumber}
          stylingForPage="PLP"
        />
      </div>
      {!authState?.isAuthenticated ? (
        <div className="flex flex-col px-4 md:pl-0">
          {/* {showsPricesEnabled && (
            <span
              className="text-primary-1-100 text-2xl font-bold"
              data-testid={`search-results-cmp-text-content-${props.index}`}
            >
              {format(props.product.cmp ?? 0)}
            </span>
          )} */}
          <p
            className="text-primary-1-100 text-caption"
            data-testid={`search-results-cmp-signin-info-text-${props.index}`}
          >
            <Link
              to="/login"
              testId={`search-results-cmp-siginin-link-text-${props.index}`}
            >
              {t('search.signIn')}
            </Link>
            {t('search.signInInfo')}
          </p>
        </div>
      ) : (
        <>
          <div className="px-4 mb-4">
            <p
              className="text-primary-1-100 text-xl font-medium mb-4"
              data-testid={`product-price-${props.index}`}
            >
              {props.pricingLoading ? (
                <Skeleton
                  variant="rectangular"
                  data-testid={`search-result-pricing-skeleton-${props.index}`}
                />
              ) : (
                Boolean(productPricing?.sellPrice) && (
                  <>
                    {/* {isCompetitiveMarketPrice && (
                      <p
                        className="text-sm text-secondary-2-100 font-bold line-through"
                        data-testid={`product-cmp-${props.index}`}
                      >
                        {format(productPricing?.cmp ?? 0)}{' '}
                      </p>
                    )} */}
                    <span
                      className="text-2xl font-bold lowercase"
                      data-testid={`product-sell-price-${props.index}`}
                    >
                      {format(productPricing!.sellPrice)}
                    </span>
                    <span
                      className="text-base lowercase"
                      data-testid={`product-each-${props.index}`}
                    >
                      {productPricing!.orderUom ?? t('product.each')}
                    </span>
                    {/* {isCompetitiveMarketPrice && (
                      <p
                        className="text-success-100 text-caption"
                        data-testid={`product-savings-${props.index}`}
                      >
                        {t('common.youSave')}{' '}
                        <b data-testid={`product-saved-price-${props.index}`}>
                          {format(saved.price)}{' '}
                        </b>
                        {`(${saved.percentage}%)`}
                      </p>
                    )} */}
                  </>
                )
              )}
            </p>
            <AvailabilityChip
              branch={shippingBranch}
              loading={props.pricingLoading}
              stock={
                shippingBranch?.isPricingOnly
                  ? productPricing?.totalAvailableQty
                  : productPricing?.branchAvailableQty
              }
              index={props.index}
            />
          </div>
          <div className="py-0 px-1 flex flex-wrap items-center gap-2">
            <QtyInput
              disabled={
                props.pricingLoading || !productPricing?.sellPrice || !cart
              }
              value={quantity}
              onUpdate={setQuantity}
              increment={props.product?.minIncrementQty || 1}
              max={9999}
              size="xs"
              index={props.index}
              noDebounce
              testId={`quantity-input-${props.index}`}
            />
            <AdvancedToolTip
              title="Warning"
              text={t('cart.maxLimitToolTip')}
              icon={<WarningIcon className="w-4 h-4" />}
              placement="bottom"
              activated={disableAddToCart}
            >
              <FeedbackButton
                disabled={
                  props.pricingLoading ||
                  !productPricing?.sellPrice ||
                  !cart ||
                  cartLoading
                }
                testId={`add-to-cart-button-${props.index}`}
                fullWidth
                onClick={handleAddToCart}
                size="sm"
                value={t('common.addToCart')}
                valueDone={t('common.addedToCart')}
              />
            </AdvancedToolTip>
          </div>
        </>
      )}
    </div>
  );
}
export default SearchResult;
