import { Fade } from '@mui/material';
import clsx from 'clsx';

/**
 * Types
 */
export type BackdropProps = {
  className?: string;
  on: boolean;
  onClick: () => void;
};

/**
 * Component
 */
function Backdrop(props: BackdropProps) {
  /**
   * Props
   */
  const { className, on, onClick } = props;
  /**
   * Render
   */
  return (
    <Fade in={on}>
      <div
        data-testid="branch-sidebar-backdrop"
        onClick={onClick}
        className={clsx(
          className,
          'absolute z-20 inset-x-0 w-full h-full bg-common-black/50'
        )}
      />
    </Fade>
  );
}

export default Backdrop;
