import { Grid, Typography } from '@dialexa/reece-component-library';

type Props = {
  xs: number;
  children: any;
};

export default function ProductListHeaderItem(props: Props) {
  return (
    <Grid item xs={props.xs}>
      <Typography fontWeight={500} component="div">
        {props.children}
      </Typography>
    </Grid>
  );
}
