import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import clsx from 'clsx';
import { kebabCase } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Button } from 'components';
import useScreenSize from 'hooks/useScreenSize';
import { ChevronLeftIcon, ChevronRightIcon } from 'icons';
import { SEARCH_CATEGORY_PATH } from 'pages/Search';
import useSearchQueryParams from 'pages/Search/util/useSearchQueryParams';
import { makeSlug } from 'utils/makeSlug';

/**
 * Component
 */
function SearchBreadcrumbs() {
  /**
   * Custom Hooks
   */
  const history = useHistory();
  const { isSmallScreen } = useScreenSize();
  const { t } = useTranslation();
  const [params, setParams] = useSearchQueryParams();
  const { categories = [], criteria: searchTerm } = params;

  /**
   * Callbacks
   */
  // 🟤 Cb - desktop breadcrumb
  const goToDepth = (i: number) => {
    const newCategories = categories.slice(0, i);
    history.location.pathname = SEARCH_CATEGORY_PATH + makeSlug(newCategories);
    setParams({ ...params, categories: newCategories });
  };
  // 🟤 Cb - mobile breadcrumb
  const goBackOne = () => {
    if (categories.length) {
      goToDepth(categories.length - 1);
    } else {
      history.push('/');
    }
  };

  /**
   * Render
   */
  if (isSmallScreen) {
    return (
      <Button
        iconStart={<ChevronLeftIcon fontSize="small" />}
        kind="text"
        onClick={goBackOne}
        className="!p-0 right-2"
        data-testid="home-button-mobile"
      >
        {categories.length ? categories?.slice(-1)?.[0] : t('common.home')}
      </Button>
    );
  }
  if (!categories.length) {
    return null;
  }
  return (
    <MuiBreadcrumbs
      aria-label="breadcrumb"
      separator={<ChevronRightIcon />}
      className="!px-0 !py-2"
      data-testid="desktop-breadcrumbs"
    >
      {[t('common.home'), ...categories].map((c, i) => {
        const isCurrent = i === categories.length && !searchTerm;
        return (
          <Button
            key={c || i}
            onClick={() => goToDepth(i)}
            kind="text"
            disabled={isCurrent}
            className={clsx('!px-2 !py-0 !min-w-0 font-normal', {
              '!pl-0': !i,
              '!text-primary-3-100': isCurrent
            })}
            data-testid={kebabCase(`${c || i}-breadcrumb-button`)}
          >
            {c}
          </Button>
        );
      })}
      {Boolean(searchTerm) && (
        <Button
          kind="text"
          color="gray"
          className="!px-2 !py-0 font-normal !cursor-default"
          data-testid={kebabCase(`${searchTerm}-breadcrumb-button`)}
        >
          {searchTerm}
        </Button>
      )}
    </MuiBreadcrumbs>
  );
}
export default SearchBreadcrumbs;
