import { Divider } from '@mui/material';
import clsx from 'clsx';
import { kebabCase } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { Card } from 'components';

/**
 * Config
 */
const noBg = 'linear-gradient(transparent 0%, transparent 100%)';
const backgroundStyling = (thumb: string) => ({
  backgroundImage: `${noBg}, url(${thumb})`,
  backgroundRepeat: 'no-repeat'
});

/**
 * Types
 */
export type BrandCardProps = {
  thumbnail: string;
  title: string;
  description: string;
  url: string;
  isContained?: boolean;
  isCentered?: boolean;
  shouldOpenInNewTab?: boolean;
};

/**
 * Component
 */
function BrandCard(props: BrandCardProps) {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();

  /**
   * Render
   */
  return (
    <div
      className="flex justify-center"
      data-testid={kebabCase(`${props.title}-brandcard`)}
    >
      <Card className="!w-[374px] h-[486px]">
        <div className="h-full flex flex-col justify-between">
          <div className="flex-1">
            <a
              href={props.url}
              target={props.shouldOpenInNewTab ? '_blank' : ''}
              rel="noreferrer noopener"
            >
              <div
                className={clsx('w-full h-[180px]', {
                  'bg-center': props.isCentered,
                  'bg-cover': !props.isContained,
                  'bg-contain': props.isContained
                })}
                style={backgroundStyling(props.thumbnail)}
                data-testid={kebabCase(`${props.title}-thumbnail`)}
              />
            </a>
            <div className="px-5 py-5 max-h-[240px]">
              <a
                className="text-primary-1-100 text-xl"
                data-testid={kebabCase(`${props.title}-title`)}
                href={props.url}
                target={props.shouldOpenInNewTab ? '_blank' : ''}
                rel="noreferrer noopener"
              >
                {props.title}
              </a>
              <p
                className="pt-[5px] text-base line-clamp-[7]"
                data-testid={kebabCase(`${props.title}-description`)}
              >
                {props.description}
              </p>
            </div>
          </div>
          <Divider />
          <div className="p-4">
            <a
              className="text-primary-2-100 text-base"
              href={props.url}
              target={props.shouldOpenInNewTab ? '_blank' : ''}
              data-testid={kebabCase(`${props.title}-learn-more-link`)}
              rel="noreferrer noopener"
            >
              {t('common.learnMore')}
            </a>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default BrandCard;
