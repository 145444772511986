import { CreditCard } from 'generated/graphql';
import { Maybe } from 'yup/lib/types';
import { ImageURLs } from 'API/types/common.types';

/**
 * Request
 */
export type AddItemsToCartRequest = {
  items: AddToCartItem[];
};
export type UpdateWillCallBranchRequest = string;

export type UpdateCartItemQtyRequest = {
  lineItemId: string;
  qty: number;
  minIncrementQty: number;
};
/**
 * Resposnse
 */
export type GetCartResponse = CartProps;

export type GetCartPricingAvailabilityResponse = CartPricingAvailabilityProps;

export type CartChangeResponse = string;

export type UpdateCartItemQtyResponse = {
  cartBadgeCount: number;
  product: ProductProps;
  subtotal: string;
};

export type AddItemsToCartResponse = {
  cartBadgeCount: number;
  itemAddedSuccess: boolean;
};

export type DeleteItemResponse = {
  id: string;
  subtotal: string;
  cartBadgeCount: number;
  allProductAvailable: boolean;
  products: DeleteItemResponseProductProps[];
};

export type RemoveAllCartItemsResponse = CartProps;

/**
 * Data
 */
export enum PreferredTimeEnum {
  Afternoon = 'AFTERNOON',
  Asap = 'ASAP',
  Midday = 'MIDDAY',
  Morning = 'MORNING'
}

export type WillCallProps = {
  id?: string;
  branchId?: string;
  preferredDate?: string;
  preferredTime?: PreferredTimeEnum;
  pickupInstructions?: string;
};

export type CartAddress = {
  id: string;
  companyName: string;
  street1: string;
  street2?: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  custom: boolean;
  shipToId: string;
};

export type DeliveryProps = {
  id: string;
  address?: CartAddress;
  shipTo?: string;
  preferredDate?: string;
  preferredTime?: PreferredTimeEnum;
  deliveryInstructions?: string;
  shouldShipFullOrder?: boolean;
  phoneNumber?: string;
  isRapid?: boolean;
  isScheduled?: boolean;
  branchId?: Maybe<string>;
};

export enum DeliveryMethodEnum {
  Delivery = 'DELIVERY',
  Willcall = 'WILLCALL'
}

export enum PaymentMethodTypeEnum {
  Billtoaccount = 'BILLTOACCOUNT',
  Creditcard = 'CREDITCARD',
  Payinstore = 'PAYINSTORE'
}

export type CartProps = Partial<{
  id: string;
  deliveryMethod: DeliveryMethodEnum;
  willCall: WillCallProps;
  delivery: DeliveryProps;
  cartBadgeCount: number;
  products: ProductProps[];
  removedProducts: ProductProps[];
  allProductAvailable: boolean;
  cartSubtotal: string;
  creditCard: Maybe<CreditCard>;
  erpSystemName: string;
  shippingBranchId?: string;
  paymentMethodType?: Maybe<PaymentMethodTypeEnum> | undefined;
  poNumber?: Maybe<string> | undefined;
}>;

export type CartProduct = {
  id: string;
  partNumber: string;
  name: string;
  manufacturerName: string;
  manufacturerNumber: string;
  imageUrls: ImageURLs;
  categories: string[];
  minIncrementQty: number;
};

export type ProductProps = {
  id: string;
  erpPartNumber: string;
  customerPartNumber: string;
  quantity: number;
  uom: string;
  product: CartProduct;
  listIds: string[];
} & ProductPricingAvailability;

export type CartProductPricing = {
  productId: string;
  catalogId: string;
  branchAvailableQty: number;
  totalAvailableQty: number;
  sellPrice: string;
  orderUom: string;
  cmp: string;
  listIds: string[];
};

export type ProductPricingAvailability = {
  qty?: number;
  productPricingDTO?: Partial<CartProductPricing>;
  pricePerUnit?: number;
  orderUom?: string;
  lineItemId?: string;
  lineItemSubtotal?: string;
  productAvailable?: boolean;
};

export type CartPricingAvailabilityProps = {
  products: ProductPricingAvailability[];
  allProductAvailable: boolean;
  cartSubtotal: string;
};

export type AddToCartItem = {
  productId: string;
  minIncrementQty: number;
  qty: number;
};

type DeleteItemResponseProductProps = {
  id: string;
  erpPartNumber: string;
  customerPartNumber: string;
  quantity: number;
  pricePerUnit: string;
  uom: string;
  qtyAvailable: number;
  product: {
    id: string;
    partNumber: string;
    name: string;
    manufacturerName: string;
    manufacturerNumber: string;
    cmp: string;
    imageUrls: ImageURLs;
    minIncrementQty: number;
  };
  listIds: string[];
  lineItemSubtotal: string;
  productAvailable: boolean;
};
