import { Divider, Hidden } from '@mui/material';
import { useTranslation } from 'react-i18next';

import useDocumentTitle from 'hooks/useDocumentTitle';
import ContactFormComponent from 'pages/Support/ContactForm';
import SupportInfo from 'pages/Support/SupportInfo';

/**
 * Component
 */
function Support() {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();
  useDocumentTitle(t('common.support'));

  /**
   * Render
   */
  return (
    <div
      className="max-w-7xl self-center flex flex-col justify-center items-center py-14 md:mt-6 md:pb-10"
      data-testid="support-page-root"
    >
      <h1 className="text-primary-1-100 text-3xl font-medium md:text-2xl">
        {t('support.header')}
      </h1>
      <p className="pt-6 pb-2 text-caption md:text-center">
        {t('support.helpText')}
      </p>
      <div className="w-full pt-6 flex flex-row justify-center md:flex-col">
        <div className="w-1/3 md:w-full">
          <SupportInfo />
        </div>
        <Hidden mdUp>
          <Divider />
        </Hidden>
        <div className="w-2/3 md:w-full">
          <ContactFormComponent />
        </div>
      </div>
    </div>
  );
}

export default Support;
