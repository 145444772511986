import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps
} from '@mui/material';
import clsx from 'clsx';

import { Modify } from '@reece/global-types';
import { borderBottomStyle, dropShadowStyle } from 'components/AppBar/styles';

/**
 * Type
 */
type NewProps = {
  dropShadow?: boolean;
  borderBottom?: boolean;
};
export type AppBarProps = Modify<MuiAppBarProps, NewProps>;

/**
 * Component
 */
function AppBar(props: AppBarProps) {
  /**
   * Props
   */
  const {
    children,
    className,
    borderBottom = true,
    dropShadow = false,
    ...other
  } = props;

  /**
   * Styles
   */
  const styling = {
    [borderBottomStyle]: borderBottom,
    [dropShadowStyle]: dropShadow
  };

  /**
   * Render
   */
  return (
    <MuiAppBar className={clsx(className, styling)} {...other}>
      {children}
    </MuiAppBar>
  );
}

export default AppBar;
