export const baseStyle = 'py-0 min w-fit h-6 flex items-center font-medium';
export const svgStyle = '[&_svg]:w-5 [&_svg]:h-5 [&_svg]:mr-1';
export const containedStyle = 'px-3 rounded-full';
export const baseColorStyles = {
  primary: 'text-primary-1-100',
  green: 'text-success-100',
  default: 'text-inherit'
};
export const containedColorStyles = {
  primary: 'bg-primary-2-5',
  green: 'bg-success-10',
  default: 'bg-transparent'
};
