import { useState } from 'react';

import { AxiosResponse, AxiosError } from 'axios';

import { apiCall, APICoreProps } from 'API/lib/core';
import { ApiError } from 'API/types/common.types';
import { Maybe } from 'generated/graphql';

/**
 * Types
 */
// Common
export type APIOnCompleted<Response> = (res: AxiosResponse<Response>) => void;
export type APIOnError = (e: unknown) => void;
export type APIOptions<Response> = {
  onCompleted?: APIOnCompleted<Response>;
  onError?: APIOnError;
};

// Base
export type APIBaseCall<Response, Request = object> = (
  myProps?: APICoreProps<Request>
) => Promise<Maybe<AxiosResponse<Maybe<Response>>>>;
export type UseAPIBaseProps<
  Response,
  Request = object
> = APICoreProps<Request> & {
  options: APIOptions<Response>;
};

/**
 * Hook
 */
export function useApiBase<Response, Request = object>(
  props: UseAPIBaseProps<Response, Request>
) {
  /**
   * props
   */
  const { body, header, kind, options, url } = props;

  /**
   * State
   */
  const [loading, setLoading] = useState(false);
  const [called, setCalled] = useState(false);

  /**
   * Callback
   */
  // 🟤 Cb - MAIN async call
  const call: APIBaseCall<Maybe<Response>, Request> = (
    myProps?: APICoreProps<Request>
  ) => {
    // Init
    setLoading(true);
    const props: APICoreProps<Request> = { url, kind, header, body };
    const selectedHeader = { ...header, ...myProps?.header };
    const selectedProps = { ...(myProps ?? props), header: selectedHeader };
    // Call
    const asyncCall = new Promise<Maybe<AxiosResponse<Maybe<Response>>>>(
      (resolve, reject) => {
        apiCall<Response, Request>(selectedProps)
          .then((res) => {
            resolve(res);
            options.onCompleted?.(res);
          })
          .catch((e: AxiosError<ApiError>) => {
            const data = e.response?.data;
            reject(data);
            console.error({ apiError: data });
            options.onError?.(data);
          })
          .finally(() => {
            setCalled(true);
            setLoading(false);
          });
      }
    );
    return asyncCall;
  };

  /**
   * Output
   */
  return { called, loading, call };
}
