import { useTranslation } from 'react-i18next';

import { Button, Modal } from 'components';
import { CustomAddressInput } from 'Checkout/util/types';

type AddressConfirmationModalProps = {
  open: boolean;
  address: CustomAddressInput;
  onConfirm: (customAddress: CustomAddressInput) => void;
  onCancel: () => void;
};

function AddressConfirmModal(props: AddressConfirmationModalProps) {
  const { open, address, onConfirm, onCancel } = props;

  const { t } = useTranslation();

  return (
    <Modal
      width="sm"
      overridePageScroll
      onClose={onCancel}
      noBlur
      open={open}
      testId="validate-address-dialog"
      headerContent={
        <h5 className="text-primary-3-100 text-xl font-medium">
          {t('validation.doubleCheck')}
        </h5>
      }
    >
      <p>{t('validation.noValidation')}</p>
      <div className="p-4">
        <b>{t('validation.youEntered')}</b>
        <p>{address.companyName}</p>
        <p>
          {`${address.street1}`}
          <br />
          {`${address.city}, ${address.state} ${address.zip}`}
        </p>
      </div>

      <div className="flex flex-1 justify-end">
        <Button
          type="button"
          color="lightBlue"
          size="lg"
          kind="text"
          className="!w-fit self-end ml-auto !underline"
          onClick={onCancel}
          data-testid="validate-address-dialog-cancel"
        >
          {t('validation.makeChanges')}
        </Button>
        <Button
          type="button"
          color="primary"
          size="lg"
          className="!w-fit self-end"
          onClick={() => onConfirm(address)}
          data-testid="validate-address-dialog-confirm-address"
        >
          {t('validation.continueWithEntered')}
        </Button>
      </div>
    </Modal>
  );
}

export default AddressConfirmModal;
