import { alpha } from '@mui/material/styles';

import palette from 'providers/libs/Theme/palette';
import theme from 'providers/libs/Theme/baseTheme';

const muiListItem = {
  root: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    '&:hover': {
      backgroundColor: palette.secondary04.main
    },
    '&.Mui-selected': {
      backgroundColor: `${alpha(palette.primary02.main, 0.1)}`,
      color: palette.primary02.main,
      '&:hover': {
        backgroundColor: `${alpha(palette.primary02.main, 0.1)}`,
        color: palette.primary02.main
      },
      '& [class*="MuiListItemText-"]': {
        color: palette.primary02.main
      },
      '& svg path': {
        fill: palette.primary02.main
      }
    },
    '&.Mui-focusVisible': {
      backgroundColor: `${alpha(palette.primary02.main, 0.1)}`
    },
    'nav & [class*="MuiListItemText-"]': {
      fontWeight: 500
    },
    'nav &.Mui-selected': {
      borderRight: `6px solid ${palette.primary02.main}`
    },
    'nav & .MuiListItemIcon-root': {
      color: palette.primary.main
    }
  },
  button: {
    '&:hover': {
      backgroundColor: palette.secondary04.main
    }
  },
  gutters: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  }
};

export default muiListItem;
