import { useContext, useMemo } from 'react';

import { ApolloError } from '@apollo/client';
import { Divider, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Button, CircularProgress } from 'components';
import {
  useBillToAccountsQuery,
  useShipToAccountsQuery
} from 'generated/graphql';
import useScreenSize from 'hooks/useScreenSize';
import { WarningIcon } from 'icons';
import { AuthContext } from 'providers/AuthProvider';
import { useCartContext } from 'providers/CartProvider';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';
import { truncateText } from 'utils/strings';

/**
 * Types
 */
type Props = {
  onChange?: () => void;
};

/**
 * Component
 */
function SwitchAccounts(props: Props) {
  /**
   * Custom hooks
   */
  const { push } = useHistory();
  const { isSmallScreen } = useScreenSize();
  const { t } = useTranslation();

  /**
   * Context
   */
  const { authState, profile } = useContext(AuthContext);
  const { contract } = useCartContext();
  const { selectedAccounts, setAccountsModalOpen } =
    useSelectedAccountsContext();

  /**
   * Data
   */
  // 🟣 Query - bill-to accounts
  const { data: billTosData, loading: billTosLoading } = useBillToAccountsQuery(
    {
      skip: !profile?.userId,
      variables: { userId: profile?.userId ?? '' },
      onError: handleGqlError
    }
  );
  // 🟣 Query - ship-to accounts
  const { data: shipTosData, loading: shipTosLoading } = useShipToAccountsQuery(
    {
      skip: !profile?.userId || !selectedAccounts?.billTo?.id,
      variables: { accountId: selectedAccounts?.billTo?.id ?? '' },
      onError: handleGqlError
    }
  );

  /**
   * Memo
   */
  // 🔵 Memo - Loading
  const loading = useMemo(
    () => billTosLoading || shipTosLoading,
    [billTosLoading, shipTosLoading]
  );
  // 🔵 Memo - Available mapped Bill-to Data
  const availableBillTos = useMemo(() => {
    const output = billTosData?.billToAccounts?.map((account) => ({
      ...account,
      shipTos:
        selectedAccounts?.billTo?.id === account?.id
          ? shipTosData?.shipToAccounts
          : []
    }));
    return output ?? [];
  }, [
    billTosData?.billToAccounts,
    selectedAccounts?.billTo?.id,
    shipTosData?.shipToAccounts
  ]);
  // 🔵 Memo - Available ship-to data
  const availableShipTos = useMemo(() => {
    const selectedBillTo = availableBillTos.find(
      (item) => item?.id === selectedAccounts?.billTo?.id
    );
    return selectedBillTo?.shipTos ?? [];
  }, [availableBillTos, selectedAccounts?.billTo?.id]);
  // 🔵 Memo - Ship-to name
  const shipToName = useMemo(() => {
    const contractJobName = contract?.data?.jobName;
    const contractAddress =
      contract?.data?.accountInformation?.shipToAddress?.address1;
    return contractJobName && contractAddress
      ? `${contractJobName} ${contractAddress}`
      : selectedAccounts.shipTo?.name ?? '';
  }, [
    contract?.data?.accountInformation?.shipToAddress?.address1,
    contract?.data?.jobName,
    selectedAccounts.shipTo?.name
  ]);
  // 🔵 Const - Show select account button
  const showButton =
    (availableBillTos.length > 1 || availableShipTos.length > 1) && !contract;

  /**
   * Callbacks
   */
  // 🟤 Cb - go to select accounts
  const onSwitchPressed = () => {
    setAccountsModalOpen(true);
    props.onChange?.();
  };

  /**
   * Render
   */
  // Hidden
  if (!authState?.isAuthenticated || !selectedAccounts.billTo) {
    return null;
  }
  // Mobile
  if (isSmallScreen) {
    return (
      <>
        <div
          className="pt-4 w-full flex"
          data-testid="header-switchaccount-wrapper-mobile"
        >
          <div className="flex-1 min-w-0">
            <p className="pb-2 text-primary-1-100 text-caption font-medium">
              {t('common.account')}:
            </p>
            <p
              className="pb-2 text-sm font-normal truncate"
              data-testid="mobile-menu-bill-to"
            >
              {selectedAccounts.billTo?.name ?? ''}
            </p>
            <p className="pb-2 text-primary-1-100 text-caption font-medium">
              {t('common.job')}:
            </p>
            <p
              className="pb-6 text-sm font-normal truncate"
              data-testid="mobile-menu-ship-to"
            >
              {shipToName}
            </p>
          </div>
          {(loading || showButton) && (
            <div className="pl-7">
              {loading ? (
                <CircularProgress
                  size={20}
                  color="secondary"
                  testId="switch-account-loading-mobile"
                />
              ) : (
                showButton && (
                  <Button
                    className="!pr-3, !text-caption"
                    color="lightBlue"
                    data-testid="change-button"
                    kind="text"
                    onClick={onSwitchPressed}
                  >
                    {t('common.change')}
                  </Button>
                )
              )}
            </div>
          )}
        </div>
        <Divider />
      </>
    );
  }
  // Desktop
  return (
    <div
      className="flex items-center"
      data-testid="header-switchaccount-wrapper-desktop"
    >
      {/* Credit hold warning */}
      {Boolean(
        selectedAccounts.billToErpAccount?.creditHold ||
          selectedAccounts.shipToErpAccount?.creditHold
      ) && (
        <Tooltip
          title={t('common.accountHold') as string}
          PopperProps={{ className: 'max-w-none' }}
        >
          <WarningIcon
            className="mr-2 text-secondary-1-100"
            height={24}
            width={24}
            data-testid="header-accounthold-warningicon"
          />
        </Tooltip>
      )}
      {/* Account display text */}
      {Boolean(shipToName) && (
        <Tooltip
          placement="top"
          PopperProps={{ className: 'max-w-none' }}
          title={
            <>
              <p className="text-sm" data-testid="header-tooltip-bill-to">
                {selectedAccounts.billTo?.erpAccountId} -{' '}
                {truncateText(selectedAccounts.billTo?.name ?? '', 45)} -{' '}
                {truncateText(selectedAccounts.billTo?.address ?? '', 29)}
              </p>
              {selectedAccounts.billTo?.name !== shipToName && (
                <p className="text-sm" data-testid="header-tooltip-ship-to">
                  {selectedAccounts.shipTo?.erpAccountId} -{' '}
                  {truncateText(shipToName, 45)} -{' '}
                  {truncateText(selectedAccounts.shipTo?.address ?? '', 29)}
                </p>
              )}
            </>
          }
        >
          <p className="text-sm" data-testid="header-ship-to">
            <span className="font-bold">
              {t('common.job')}
              {': '}
            </span>
            {selectedAccounts.shipTo?.erpAccountId} -{' '}
            {truncateText(shipToName, 30)}
          </p>
        </Tooltip>
      )}
      {loading ? (
        <div className="px-6">
          <CircularProgress
            size={25}
            color="secondary"
            testId="switch-account-loading-desktop"
          />
        </div>
      ) : (
        showButton && (
          <Button
            onClick={() => setAccountsModalOpen(true)}
            size="sm"
            color="lightBlue"
            kind="text"
            className="!px-3 !font-normal"
            data-testid="switch-account-button"
          >
            {t('common.switchAccount')}
          </Button>
        )
      )}
    </div>
  );

  /**
   * Util
   */
  // 🔴 Error - gql error handling
  function handleGqlError(error: ApolloError) {
    push('/error', { error });
  }
}

export default SwitchAccounts;
