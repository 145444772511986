import { ReactNode, useEffect, useMemo, useRef } from 'react';

import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';

import { Modal, TextInput, Textarea } from 'components';
import useScreenSize from 'hooks/useScreenSize';
import useListActionsForm, {
  ListNaming
} from 'pages/Lists/provider/hooks/useListActionsForm';
import { ListAction } from 'pages/Lists/provider/ListsPageProvider';

/**
 * Props
 */
export type ListFormDialogProps = {
  children: ReactNode;
  initialValues: ListNaming;
  actionType?: ListAction;
  open: boolean;
  title: string;
  loading?: boolean;
  setFormikInstance: (formik?: FormikProps<ListNaming>) => void;
  onSubmitCallback: (values: ListNaming) => void;
  onClose: () => void;
};

/**
 *  Config
 */
const MIN_TEXTAREA_HEIGHT = 191;

/**
 * Component
 */
function ListFormDialog(props: ListFormDialogProps) {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();
  const { isSmallScreen } = useScreenSize();
  const { formik } = useListActionsForm(props);

  /**
   * Effects
   */
  // 🟡 Effect - Send instance of formik to parent component
  useEffect(() => {
    props.setFormikInstance(formik);
    return () => props.setFormikInstance(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Refs
   */
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const textareaParent = textareaRef.current?.parentElement;
  const textareaLabel = textareaParent?.parentElement?.children[0];
  const textareaMessage = textareaParent?.children[1];
  const margin =
    textareaLabel && textareaMessage
      ? textareaLabel?.clientHeight + textareaMessage?.clientHeight
      : 0;

  /**
   * Memo
   */
  // 🔵 Memo - Update the height of the textarea as the user types
  const textareaHeight: number = useMemo(
    () => {
      if (!textareaRef.current || !textareaParent || !margin) {
        return MIN_TEXTAREA_HEIGHT;
      }

      const newHeight = textareaRef.current.scrollHeight - margin;
      if (newHeight > MIN_TEXTAREA_HEIGHT) {
        return newHeight < textareaParent.clientHeight - margin
          ? newHeight
          : textareaParent.clientHeight - margin;
      }
      return MIN_TEXTAREA_HEIGHT;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.values.description, textareaParent?.clientHeight, margin]
  );

  /**
   * Render
   */
  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      width={isSmallScreen ? 'default' : 'sm'}
      testId={`list-form-dialog-${props.actionType}`}
      headerContent={
        <h5 className="text-primary-3-100 text-xl font-medium">
          {props.title}
        </h5>
      }
    >
      <form
        data-testid={`list-form-dialog-${props.actionType}-form`}
        className="flex flex-col gap-4 md:h-full"
        onSubmit={(e) => e.preventDefault()}
        onReset={formik.handleReset}
        noValidate
      >
        {/* <>-------------------------<> LIST NAME <>-------------------------<> */}
        <div className="flex py-4 flex-col w-full max-w-full md:py-0">
          <TextInput
            name="name"
            label={t('lists.enterListName')}
            testId={`list-form-dialog-${props.actionType}-input`}
            required
            placeholder={t('lists.listNamePlaceholder')}
            maxLength={30}
            value={formik.values.name}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.name)}
            disabled={props.loading}
            onBlur={formik.handleBlur}
            message={formik.errors.name ?? ''}
          />
        </div>
        {/* <>-------------------------<> LIST DESCRIPTION <>-------------------------<> */}
        <div className="flex flex-col w-full max-w-full md:h-full overflow-y-hidden">
          <Textarea
            name="description"
            label={t('lists.enterListDesc')}
            testId={`list-form-dialog-${props.actionType}-textarea`}
            inputClassName="whitespace-break-spaces md:h-auto overflow-scroll"
            inputStyles={isSmallScreen ? { height: textareaHeight } : undefined}
            className="md:h-full"
            rows={4}
            ref={textareaRef}
            placeholder={t('lists.listDescPlaceholder')}
            maxLength={200}
            value={formik.values.description}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.description)}
            disabled={props.loading}
            onBlur={formik.handleBlur}
            message={formik.errors.description ?? ''}
          />
        </div>
        {/* <>-------------------------<> SUBMIT BUTTONS <>-------------------------<> */}
        <div className="flex gap-x-8 gap-y-4 justify-center items-center transition-none">
          {props.children}
        </div>
      </form>
    </Modal>
  );
}

export default ListFormDialog;
