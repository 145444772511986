import { useCallback, useEffect, useMemo, useState } from 'react';

import { Cached, CheckCircle } from '@mui/icons-material';
import { InputLabel, FormControlLabel, Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { GetLastSelectedAccountsRequest } from 'API/types/user.types';

import { Button, Loader } from 'components';
import { EcommAccount, Maybe } from 'generated/graphql';
import useFormatOptions from 'common/SelectAccounts/lib/useFormatOptions';
import useSelectAccountsFormData from 'common/SelectAccounts/lib/useSelectAccountsFormData';
import Autocomplete from 'common/SelectAccounts/sub/Autocomplete';
import { useAuthContext } from 'providers/AuthProvider';
import { useCartContext } from 'providers/CartProvider';
import { useSnackbar } from 'providers/SnackbarProvider';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';

/**
 * Configs
 */
export const FIRST_BILLTO_ID = '287169';
const defaultLocalStorage = { billToId: '', shipToId: '' };

/**
 * Types
 */
type SelectAccountsFormProps = { onContinue: () => void; isLogin: boolean };
export type LocalStorageAccountIds = typeof defaultLocalStorage;

/**
 * Component
 */
function SelectAccountsForm(props: SelectAccountsFormProps) {
  /**
   * Custom Hooks
   */
  const history = useHistory();
  const { t } = useTranslation();
  const { pushAlert } = useSnackbar();
  const formatOptions = useFormatOptions();

  /**
   * Context
   */
  const { ecommUser, profile } = useAuthContext();
  const { cart, refreshCart } = useCartContext();
  const {
    waterworksLogin,
    selectedAccounts,
    defaultAccount,
    setWaterworksLogin,
    updateAccounts
  } = useSelectedAccountsContext();
  const { isEmployee } = profile ?? {};

  /**
   * State
   */
  const [storeSelected, setStoreSelected] = useState(false);
  const [storeSelectedCheck, setStoreSelectedCheck] = useState(false);
  const [loadedBillTo, setLoadedBillTo] = useState(false);
  const [loadedShipTo, setLoadedShipTo] = useState(false);
  const [refreshedBillToId, setRefreshedBillToId] = useState('');
  const [shipTosRefreshed, setShipTosRefreshed] = useState(false);
  const [isDefaultAccount, setIsDefaultAccount] = useState(false);

  /**
   * Data
   */
  const {
    accountsData,
    accountsLoading,
    getShipToDataQuery,
    getRefreshShipToList,
    getRefreshShipToListLoading,
    selectedBillTo,
    selectedBillToShipTos,
    selectedShipTo,
    setIsBillToChange,
    setSelectedBillTo,
    setSelectedBillToShipTos,
    setSelectedShipTo,
    updateLastSelectedAccounts
  } = useSelectAccountsFormData();

  /**
   * Memos
   */
  // 🔵 Memo - Bill-to options
  const billToOptions = useMemo(() => {
    const data = [...(accountsData ?? [])];
    const sortedData = data.sort(
      (a, b) =>
        parseInt(a.erpAccountId ?? '0') - parseInt(b.erpAccountId ?? '0')
    );
    return formatOptions(sortedData);
  }, [formatOptions, accountsData]);
  // 🔵 Memo - Ship-to options
  const shipToOptions = useMemo(
    () => formatOptions(selectedBillToShipTos ?? []),
    [formatOptions, selectedBillToShipTos]
  );
  // 🔵 Memo - Previous Bill-to
  const previousBillTo = useMemo(
    () => accountsData?.find(({ id }) => id === selectedAccounts.billTo?.id),
    [accountsData, selectedAccounts.billTo?.id]
  );

  // 🔵 Memo - First Bill-to
  const firstBillTo = useMemo(
    () => accountsData?.find((item) => item.id === billToOptions[0]?.value),
    [accountsData, billToOptions]
  );
  // 🔵 Memo - Previous Ship-to
  const previousShipTo = useMemo(
    () =>
      selectedBillToShipTos?.find(
        ({ id }) => id === selectedAccounts.shipTo?.id
      ),
    [selectedBillToShipTos, selectedAccounts.shipTo?.id]
  );

  // 🔵 Memo - First Ship-to
  const firstShipTo = useMemo(
    () =>
      selectedBillToShipTos?.find(({ id }) => id === shipToOptions[0]?.value),
    [selectedBillToShipTos, shipToOptions]
  );

  /**
   * Callbacks
   */
  // 🟤 Cb - Get Ship-to data
  const updateShipToData = useCallback(
    (billToId?: Maybe<string>) => {
      if (!billToId) {
        return;
      }
      getShipToDataQuery({ variables: { accountId: billToId } });
    },
    [getShipToDataQuery]
  );
  // 🟤 Cb - Bill-to change
  const handleBillToChange = useCallback(
    (id?: string) => {
      const selected = accountsData?.find((item) => item.id === id);
      setShipTosRefreshed(false);
      setSelectedBillTo(selected);
      setIsBillToChange(true);
      updateShipToData(selected?.id);
      setLoadedShipTo(false);
    },
    [
      accountsData,
      setLoadedShipTo,
      setIsBillToChange,
      setSelectedBillTo,
      updateShipToData
    ]
  );
  // 🟤 Cb - Ship-to change
  const handleShipToChange = useCallback(
    (id?: string) => {
      const selected = selectedBillToShipTos?.find((item) => item.id === id);
      setSelectedShipTo(selected);
    },
    [selectedBillToShipTos, setSelectedShipTo]
  );

  const handleDefaultAccountChange = (value: boolean) => {
    setStoreSelected(value);
    setStoreSelectedCheck(value);
  };
  // 🟤 Cb - Refresh ship-to list
  const handleRefreshShipToList = async () => {
    const billToAccountId = selectedBillTo?.id ?? '';
    setShipTosRefreshed(true);
    setRefreshedBillToId(billToAccountId);
    const res = await getRefreshShipToList({ variables: { billToAccountId } });
    const accounts = res.data?.refreshShipToAccount;
    if (accounts?.length && refreshedBillToId === selectedBillTo?.id) {
      setSelectedBillToShipTos(accounts as EcommAccount[]);
    }
  };
  // 🟤 Cb - Submit
  const handleSubmit = async () => {
    const body: GetLastSelectedAccountsRequest = {
      lastSelectedAccountId: selectedBillTo?.id ?? '',
      lastSelectedShipTo: selectedShipTo?.id ?? '',
      setDefault: storeSelected,
      clearDefault: isDefaultAccount && !storeSelected
    };
    try {
      await updateLastSelectedAccounts(body);
    } catch (e) {
      pushAlert(t('common.messageFail'), { variant: 'error' });
    }

    try {
      cart && refreshCart();
      updateAccounts(selectedBillTo, selectedShipTo);
      props.onContinue();
      if (waterworksLogin) {
        setWaterworksLogin?.(false);
        history.push('/invoices');
      }
    } catch (e) {
      pushAlert(t('common.messageFail'), { variant: 'error' });
    }
  };

  /**
   * Effects
   */
  // 🟡 Effect - Set the default billTo
  useEffect(() => {
    if (!loadedBillTo && ecommUser?.id && (previousBillTo || firstBillTo)) {
      const billTo = previousBillTo ?? firstBillTo;
      setSelectedBillTo(billTo);
      setIsBillToChange(false);
      updateShipToData(billTo?.id);
      setLoadedBillTo(true);
    }
  }, [
    accountsData,
    billToOptions,
    ecommUser,
    firstBillTo,
    loadedBillTo,
    previousBillTo,
    setIsBillToChange,
    setSelectedBillTo,
    updateShipToData
  ]);
  // 🟡 Effect - Set the default shipTo
  useEffect(() => {
    if (
      !loadedShipTo &&
      !accountsLoading &&
      loadedBillTo &&
      (previousShipTo || firstShipTo)
    ) {
      setSelectedShipTo(previousShipTo || firstShipTo);
      setLoadedShipTo(true);
    }
  }, [
    accountsData,
    accountsLoading,
    firstShipTo,
    loadedBillTo,
    loadedShipTo,
    previousShipTo,
    selectedBillToShipTos,
    setSelectedShipTo,
    shipToOptions
  ]);
  // Effect - Set the defaultAccount
  useEffect(() => {
    if (defaultAccount && selectedBillTo) {
      setIsDefaultAccount(
        defaultAccount.billToAccount.id === selectedBillTo.id
      );
      handleDefaultAccountChange(
        defaultAccount.billToAccount.id === selectedBillTo.id
      );
    }
  }, [defaultAccount, selectedBillTo, selectedShipTo]);

  /**
   * Render
   */
  return (
    <div className="relative mx-[88px] mt-2 mb-14 md:mx-4 md:my-8">
      {accountsLoading && <Loader backdrop testId="selectaccounts-loading" />}
      <h5 className="mb-12 text-xl text-center font-medium">
        {t('selectAccounts.selectToContinue')}
      </h5>
      {/* Bill To */}
      <div data-testid="wrapper-billto">
        <InputLabel htmlFor="bill-to">{t('selectAccounts.billTo')}</InputLabel>
        <Autocomplete
          testId="autocomplete-billto"
          options={billToOptions}
          onChange={handleBillToChange}
          disabled={billToOptions.length <= 1}
          value={selectedBillTo?.id ?? ''}
          isEmployee={isEmployee}
        />
        {isEmployee && (
          <p className="mt-2 text-[.8rem]">{t('account.searchAccount')}</p>
        )}
      </div>
      {/* Ship To */}
      <div className="pt-12" data-testid="wrapper-shipto">
        <InputLabel htmlFor="ship-to">{t('selectAccounts.shipTo')}</InputLabel>
        <Autocomplete
          testId="autocomplete-shipto"
          options={shipToOptions}
          disabled={!shipToOptions.length}
          onChange={handleShipToChange}
          value={selectedShipTo?.id ?? ''}
          isEmployee={isEmployee}
          isShipTo
        />
        <p className="pt-2 text-[.8rem]">
          {isEmployee ? t('account.searchJob') : t('account.searchJobCustomer')}
        </p>
      </div>
      {isEmployee && (
        <div
          className="flex items-center pt-12 text-secondary-2-100 text-base"
          data-testid="wrapper-employee"
        >
          {getRefreshShipToListLoading ? (
            <>
              <Cached
                className="pr-2 text-primary-1-100"
                data-testid="select-accounts-form-refresh-loading"
              />
              {t('common.updatingJobList')}
            </>
          ) : shipTosRefreshed ? (
            <>
              <CheckCircle
                className="pr-2 text-success-100"
                data-testid="select-accounts-form-refresh-done"
              />
              {t('common.jobListUpToDate')}
            </>
          ) : (
            <>
              <b>{t('common.doNotSeeJobList')}</b>
              <Button
                data-testid="select-accounts-form-refresh-shipto"
                kind="text"
                color="primary"
                onClick={handleRefreshShipToList}
                className="inline-flex mx-2 !p-0"
              >
                {t('common.clickHere')}
              </Button>
              {t('common.toUpdateJobList')}
            </>
          )}
        </div>
      )}
      {/* Make Default */}
      <div className="pt-6">
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              data-testid="select-accounts-form-make-default"
            />
          }
          onChange={(_, value) => handleDefaultAccountChange(value)}
          label={`${t('account.defaultAccount')}`}
          checked={storeSelectedCheck}
          value={storeSelected}
        />
      </div>

      <div className="w-1/2 flex justify-center mx-auto pt-8">
        <Button
          fullWidth
          onClick={handleSubmit}
          disabled={
            !selectedBillTo ||
            !selectedShipTo ||
            accountsLoading ||
            getRefreshShipToListLoading
          }
          data-testid="select-accounts-form-continue"
        >
          {t('selectAccounts.continue')}
        </Button>
      </div>
    </div>
  );
}

export default SelectAccountsForm;
