import { useEffect, useRef, useState } from 'react';

import { Grow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useResizeObserver from 'use-resize-observer/polyfilled';

import { Button } from 'components';
import useScreenSize from 'hooks/useScreenSize';
import { LargeArrowUpIcon } from 'icons';

/**
 * Component
 */
function BackToTop() {
  /**
   * Custom Hooks
   */
  const { isSmallScreen } = useScreenSize();
  const { t } = useTranslation();

  /**
   * State
   */
  const [shouldShow, setShouldShow] = useState(false);

  /**
   * Ref
   */
  const contentRef = useRef<HTMLElement>(document.getElementById('content'));
  const { height } = useResizeObserver<HTMLElement>({ ref: contentRef });

  /**
   * Effect
   */
  useEffect(() => setShouldShow((height ?? 0) > window.outerHeight), [height]);

  /**
   * Callbacks
   */
  const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  /**
   * Render
   */
  if (!isSmallScreen) {
    return null;
  }
  return (
    <Grow in={shouldShow} style={{ transformOrigin: 'center bottom' }}>
      <div className="bg-common-white border border-secondary-3-80">
        <Button
          kind="text"
          fullWidth
          iconStart={<LargeArrowUpIcon />}
          data-testid="back-to-top-button"
          onClick={scrollToTop}
          className="py-4"
        >
          {t('backToTop.text')}
        </Button>
      </div>
    </Grow>
  );
}

export default BackToTop;
