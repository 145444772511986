import { useTranslation } from 'react-i18next';

import { TableRendererProps } from 'common/TablePageLayout/TableRenderer';
import { Pagination } from 'components';
import { handleGoToPage } from 'utils/tableUtils';

/**
 * Component
 */
function TableRendererFooter<TData extends object>(
  props: TableRendererProps<TData>
) {
  /**
   * Props
   */
  const {
    resultsCount,
    resultsCountText,
    customPreviousPage,
    customNextPage,
    customPageCount,
    customTotalItems,
    customPageNumber,
    customGoToPage,
    tableInstance: { gotoPage, nextPage, pageCount, previousPage, state }
  } = props;

  /**
   * Custom hooks
   */
  const { t } = useTranslation();

  /**
   * Render
   */
  return (
    <div
      className="px-6 py-4 flex flex-row justify-between md:flex-col-reverse md:justify-center"
      data-testid="table-footer-wrapper"
    >
      <div className="flex content-center">
        <span
          className="mx-2 my-1 text-center"
          data-testid={`total-${resultsCountText?.toLowerCase()}-count`}
        >
          {Boolean(customTotalItems || resultsCount) &&
            `${customTotalItems ?? resultsCount} ${resultsCountText}`}
        </span>
      </div>
      {Boolean(pageCount && resultsCount) && (
        <div className="flex-1 flex justify-end md:justify-center">
          <Pagination
            current={customPageNumber ?? state.pageIndex + 1}
            count={customPageCount ?? pageCount}
            ofText={t('common.of')}
            onChange={customGoToPage ?? handleGoToPage(gotoPage)}
            onPrev={customPreviousPage ?? previousPage}
            onNext={customNextPage ?? nextPage}
            testId="table-footer-pagination"
          />
        </div>
      )}
    </div>
  );
}

export default TableRendererFooter;
