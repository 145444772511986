import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { useGetHomeBranchQuery } from 'generated/graphql';
import { ContactPhoneIcon, ContactLocationIcon } from 'icons';
import { useAuthContext } from 'providers/AuthProvider';
import { useBranchContext } from 'providers/BranchProvider';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';

/**
 * Component
 */
function SupportInfo() {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();

  /**
   * Context
   */
  const { homeBranch } = useBranchContext();
  const { authState } = useAuthContext();
  const { selectedAccounts } = useSelectedAccountsContext();

  /**
   * Data
   */
  const { data: homeBranchQuery } = useGetHomeBranchQuery({
    variables: { shipToAccountId: selectedAccounts?.shipTo?.id ?? '' }
  });

  /**
   * Memo
   */
  const { addressStreet, addressCity, hours } = useMemo(() => {
    const address1 = homeBranchQuery?.homeBranch?.address1 ?? '';
    const address2 = homeBranchQuery?.homeBranch?.address2 ?? '';
    const city = homeBranchQuery?.homeBranch?.city ?? '';
    const state = homeBranchQuery?.homeBranch?.state ?? '';
    const zip = homeBranchQuery?.homeBranch?.zip ?? '';
    const addressStreet = `${address1} ${address2}`;
    const addressCity = `${city}, ${state} ${zip}`;
    const hours = homeBranch?.businessHours?.split(';');
    return { addressStreet, addressCity, hours };
  }, [homeBranch?.businessHours, homeBranchQuery?.homeBranch]);

  /**
   * Render
   */
  return (
    <div
      className="flex flex-col items-end my-10 md:px-4"
      data-testid="support-info"
    >
      <div className="w-full pb-10 flex justify-center">
        <ContactPhoneIcon />
        <div className="basis-3/4 pl-4">
          <h6 className="text-primary-1-100 text-lg font-medium">
            {t('support.eCommerceTitle')}
          </h6>
          <div className="pt-2 flex flex-col">
            <div className="text-base">
              <span className="font-medium">{t('support.email')}</span>
              <a
                href={`mailto:${t('support.eCommerceEmail')}`}
                className="text-primary-1-100"
              >
                {t('support.eCommerceEmail')}
              </a>
            </div>
            <div className="text-base">
              <span className="font-medium">{t('support.phone')}</span>
              {t('support.eCommerceNumber')}
            </div>
            <div className="text-base">
              <span className="font-medium">{t('support.hours')}</span>
              {t('support.eCommerceHours')}
            </div>
          </div>
        </div>
      </div>
      {authState?.isAuthenticated && (
        <div className="flex justify-center w-full">
          <ContactLocationIcon />
          <div className="basis-3/4 pl-4">
            <h6 className="text-primary-1-100 text-lg font-medium">
              {homeBranchQuery?.homeBranch?.name ?? ''}
            </h6>
            <div className="pt-2 flex flex-col">
              <div className="mb-2 text-base">
                <p>{addressStreet}</p>
                <p>{addressCity}</p>
              </div>
              <div className="text-base">
                <span className="font-medium">{t('support.phone')} </span>
                {homeBranchQuery?.homeBranch?.phone}
              </div>
              <div className="flex gap-x-2 text-base">
                <span className="font-medium">{t('support.hours')}</span>
                <div>
                  {hours?.map((hour, i) => (
                    <p key={`support-home-hour${i}`}>{hour}</p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SupportInfo;
