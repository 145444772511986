import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Button, CircularProgress, Link } from 'components';
import { useResendLegacyInviteEmailMutation } from 'generated/graphql';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { MaxIcon, WelcomeToMaxIcon } from 'icons';
import { useSnackbar } from 'providers/SnackbarProvider';

/**
 * Types
 */
export type LocationState = {
  email: string;
};

/**
 * Component
 */
function MigrationSetupMessage() {
  /**
   * Custom Hooks
   */
  const { state } = useLocation<LocationState>();
  const { pushAlert } = useSnackbar();
  const { t } = useTranslation();
  useDocumentTitle(t('common.welcomeToMaX'));

  /**
   * Data
   */
  const [sendInviteEmail, { loading }] = useResendLegacyInviteEmailMutation();

  /**
   * Callbacks
   */
  // 🟤 Cb - Resend email
  const handleResendEmail = () => {
    sendInviteEmail({ variables: { legacyUserEmail: state?.email } })
      .then(() =>
        pushAlert(t('register.emailSentNotification'), { variant: 'success' })
      )
      .catch(() =>
        pushAlert(t('register.emailSentErrorNotification'), {
          variant: 'error'
        })
      );
  };

  /**
   * Render
   */
  return (
    <div
      className="flex flex-col items-center my-16 md:my-12 mx-12"
      data-testid="wrapper"
    >
      <div className="flex items-center">
        <h3 className="text-primary-1-100 text-3xl font-bold">
          {t('account.welcomeTo')}
        </h3>
        <div className="pl-2 pt-[2px]">
          <MaxIcon height={25} width={75} />
        </div>
      </div>
      <div className="p-8 md:p-4">
        <WelcomeToMaxIcon />
      </div>
      <span className="text-center pt-4 text-base max-w-[30vw] md:max-w-[640px]">
        {`${t('account.maxWelcomeLine1')} ${t('account.maxWelcomeLine2')}`}
      </span>
      {Boolean(state) && (
        <>
          <span className="pt-12 text-base">{t('register.noEmail')}</span>
          <Button
            disabled={loading}
            kind="text"
            color="lightBlue"
            onClick={handleResendEmail}
            className="!p-0"
            data-testid="resend-email-button"
          >
            {loading ? (
              <CircularProgress size={15} testId="resend-loading" />
            ) : (
              t('register.resendEmail')
            )}
          </Button>
          <div className="flex flex-col justify-center pt-16 pb-10 max-w-[450px]">
            <h4 className="pb-2 text-primary-1-100 text-xl text-center font-medium">
              {t('common.havingTrouble')}
            </h4>
            <p className="text-base text-center ">
              {t('common.hereToHelp')}
              <Link to="/location-search">{t('home.supportUrl1')}</Link>
              {t('home.supportSub2')}
              <Link to="/support">{t('home.supportUrl2')}</Link>
            </p>
          </div>
        </>
      )}
    </div>
  );
}

export default MigrationSetupMessage;
