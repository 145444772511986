import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

import useDocumentTitle from 'hooks/useDocumentTitle';
import BrandCard from 'pages/Brands/BrandCard';
import useBrandList from 'pages/Brands/lib/useBrandList';

/**
 * Component
 */
function BrandPage() {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();
  const list = useBrandList();
  useDocumentTitle(t('common.brandsTitle'));

  /**
   * Render
   */
  return (
    <Container maxWidth="lg" className="mb-[100px]">
      <h3
        className="my-[60px] text-primary-1-100 text-3xl text-center font-bold md:my-6"
        data-testid="brands-title"
      >
        {t('brands.title')}
      </h3>
      <div className="ml-1 grid grid-cols-3 gap-12 md:ml-0">
        {list.map((brand, key) => (
          <div
            className="pt-0 pb-10 col-span-1 md:pb-8 md:col-span-3"
            key={`brand-${key}`}
          >
            <BrandCard {...brand} />
          </div>
        ))}
      </div>
    </Container>
  );
}

export default BrandPage;
