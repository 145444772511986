import en from './en/translation.json';
import test from './test/translation.json';

export const resources = {
  en: {
    translation: en
  },
  test: {
    translation: test
  }
};
