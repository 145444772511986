import theme from 'providers/libs/Theme/baseTheme';

const muiChip = {
  root: {
    height: 'auto',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
    letterSpacing: '0.005em',
    fontWeight: 500,
    padding: theme.spacing(0, 1.25),
    '& .MuiChip-avatar': {
      color: 'inherit',
      width: theme.spacing(2),
      height: theme.spacing(2),
      marginLeft: 0,
      marginRight: 3.5
    }
  },
  deleteIcon: {
    margin: '0 -6px 0 5px'
  },
  label: {
    paddingLeft: 0,
    paddingRight: 0
  }
};

export default muiChip;
