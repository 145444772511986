import { useEffect } from 'react';
import { loadLightningComponent } from 'pages/Appointments/lib/loadLightningComponent';

/**
 * Component
 */
function ModifyAppointment() {
  /**
   * Effects
   */
  // 🟡 effect - loads salesforce lightning component for cancel and updating an appointment
  useEffect(() => {
    loadLightningComponent(
      'lexcontainercancel',
      'Inbound_Cancel_Appointment_custom'
    );
    loadLightningComponent(
      'lexcontainermodify',
      'Inbound_Modify_Appointment_Custom1'
    );
  }, []);
  /**
   * Render
   */
  return (
    <>
      <div className="bg-common-white" data-testid="manage-appointment">
        <div className="py-4 mx-40 md:mx-0">
          <div className="uppercase" data-testid="cancel-appointment-title">
            <h2>Cancel Appointment</h2>
          </div>
          <div id="lexcontainercancel"></div>
        </div>
        <div className="py-4 mx-40 md:mx-0">
          {/* Manage Appointments */}
          <div className="uppercase" data-testid="modify-appointment-title">
            <h2>Modify Appointment</h2>
          </div>
          <div id="lexcontainermodify"></div>
        </div>
      </div>
    </>
  );
}

export default ModifyAppointment;
