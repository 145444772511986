import { useState } from 'react';

import Card from 'components/Card';
import MaskedInput, { MaskedInputProps } from 'components/MaskedInput';

/**
 * Config
 */
const display = {
  title: 'Masked Inputs',
  zip: 'Zip Code Here',
  phone: 'Phone Here',
  message: 'Test message'
};

/**
 * Component
 */
function MaskedInputPage() {
  /**
   * States
   */
  const [phone, setPhone] = useState('');
  const [zip, setZip] = useState('');

  /**
   * Callback
   */
  const setState = (dispatch: (d: string) => void) => {
    const cb: MaskedInputProps['onChange'] = (e) => dispatch(e.target.value);
    return cb;
  };

  /**
   * Render
   */
  return (
    <Card className="my-8 p-6">
      <h5 className="text-2xl font-bold text-secondary-2-100">
        {display.title}
      </h5>
      <hr className="my-4" />
      <div className="my-4 flex justify-center">
        <MaskedInput
          value={phone}
          mask="phone"
          label="Normal"
          required
          placeholder={display.phone}
          message={display.message}
          testId="masked-phone-input"
          onChange={setState(setPhone)}
        />
        <MaskedInput
          value={phone}
          mask="phone"
          label="Disabled"
          disabled
          placeholder={display.phone}
          message={display.message}
          onChange={setState(setPhone)}
        />
        <MaskedInput
          value={phone}
          mask="phone"
          label="Error"
          error
          placeholder={display.phone}
          message={display.message}
          onChange={setState(setPhone)}
        />
      </div>
      <hr className="my-4" />
      <div className="my-4 flex justify-center">
        <MaskedInput
          value={zip}
          mask="zipcode"
          label="Normal"
          required
          placeholder={display.zip}
          message={display.message}
          testId="masked-zipcode-input"
          onChange={setState(setZip)}
        />
        <MaskedInput
          value={zip}
          mask="zipcode"
          label="Disabled"
          disabled
          placeholder={display.zip}
          message={display.message}
          onChange={setState(setZip)}
        />
        <MaskedInput
          value={zip}
          mask="zipcode"
          label="Error"
          error
          placeholder={display.zip}
          message={display.message}
          onChange={setState(setZip)}
        />
      </div>
    </Card>
  );
}

export default MaskedInputPage;
