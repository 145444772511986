export const MenuStyles = {
  maxHeight: 240,
  '& .MuiMenu-paper': {
    border: 1,
    borderColor: 'lightGray.main',
    boxShadow: 'none',
    mt: 1
  },
  '& .MuiMenu-list': {
    py: 2,
    '& .MuiMenuItem-root': {
      py: 1,
      px: 2
    }
  }
};
