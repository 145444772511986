import palette from 'providers/libs/Theme/palette';
import theme from 'providers/libs/Theme/baseTheme';

const muiBreadcrumbs = {
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(4)
  },
  li: {
    fontSize: theme.typography.pxToRem(12.8),
    lineHeight: theme.typography.pxToRem(16.2),
    letterSpacing: 0,
    color: palette.secondary02.main
  },
  separator: {
    color: palette.secondary03.main,
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    '& svg': {
      height: 12,
      width: 12
    }
  }
};

export default muiBreadcrumbs;
