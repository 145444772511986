import { Dispatch } from 'react';

// 🔸 Splitting off this async funcion to prevent unusual unit test behaviors and handle test coverage
/**
 * readjustSidebarHeight
 *
 * @description readjust the header height after it mounts
 * @param isSmallScreen boolean
 * @param availableHeight number
 * @param setSidebarHeight (value: number) => void
 */
export async function readjustSidebarHeight(
  isSmallScreen: boolean,
  availableHeight: number,
  setSidebarHeight: Dispatch<number>
) {
  // await for sidebar to mount
  await new Promise((res) => setTimeout(res, 0));
  const header = document.getElementById('sidebar-header');
  const containerH = isSmallScreen ? window.innerHeight : availableHeight;
  const headerH = header?.getBoundingClientRect().height ?? 0;
  setSidebarHeight(containerH - headerH);
}
