import {
  CssBaseline,
  Theme,
  ThemeProvider as MuiThemeProvider,
  createTheme
} from '@mui/material';

import { WrapperProps } from '@reece/global-types';
import defaultProps from 'providers/libs/Theme/props';
import styleOverrides from 'providers/libs/Theme/overrides';
import palette from 'providers/libs/Theme/palette';
import typography from 'providers/libs/Theme/typography';

/**
 * Config
 */
const breakpoints = { values: { xs: 0, sm: 768, md: 960, lg: 1440, xl: 1920 } };

/**
 * Types
 */
declare global {
  interface Window {
    theme: Theme;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    primary02: Palette['primary'];
    primary03: Palette['primary'];
    secondary02: Palette['primary'];
    secondary03: Palette['primary'];
    secondary04: Palette['primary'];
    secondary05: Palette['primary'];
    secondary06: Palette['primary'];
    secondary07: Palette['primary'];
    darkGray: Palette['primary'];
    darkOrange: Palette['primary'];
    darkYellow: Palette['primary'];
    lightBlue: Palette['primary'];
    lightGray: Palette['primary'];
    lighterBlue: Palette['primary'];
    lighterGray: Palette['primary'];
    lightestGray: Palette['primary'];
    mediumGray: Palette['primary'];
    orangeRed: Palette['primary'];
    purple: Palette['primary'];
    warmGray: Palette['primary'];
    poshRed: Palette['primary'];
    bigDogBrand: Palette['primary'];
    waterWorksWarning: Palette['primary'];
  }
  interface PaletteOptions {
    primary02?: PaletteOptions['primary'];
    primary03?: PaletteOptions['primary'];
    secondary02?: PaletteOptions['primary'];
    secondary03?: PaletteOptions['primary'];
    secondary04?: PaletteOptions['primary'];
    secondary05?: PaletteOptions['primary'];
    secondary06?: PaletteOptions['primary'];
    secondary07?: PaletteOptions['primary'];
    darkGray?: PaletteOptions['primary'];
    darkOrange?: PaletteOptions['primary'];
    darkYellow?: PaletteOptions['primary'];
    lightBlue?: PaletteOptions['primary'];
    lightGray?: PaletteOptions['primary'];
    lighterBlue?: PaletteOptions['primary'];
    lighterGray?: PaletteOptions['primary'];
    lightestGray?: PaletteOptions['primary'];
    mediumGray?: PaletteOptions['primary'];
    orangeRed?: PaletteOptions['primary'];
    purple?: PaletteOptions['primary'];
    warmGray?: PaletteOptions['primary'];
    poshRed?: PaletteOptions['primary'];
    bigDogBrand: Palette['primary'];
    waterWorksWarning?: Palette['primary'];
  }
}

/**
 * Provider
 */
function ThemeProvider({ children }: WrapperProps) {
  /**
   * THEME
   */
  const reeceTheme = createTheme({
    breakpoints,
    components: {
      MuiAccordion: {
        defaultProps: defaultProps.MuiAccordion
      },
      MuiAvatar: {
        defaultProps: {},
        styleOverrides: styleOverrides.MuiAvatar
      },
      MuiBadge: {
        defaultProps: defaultProps.MuiBadge,
        styleOverrides: styleOverrides.MuiBadge
      },
      MuiBreadcrumbs: {
        styleOverrides: styleOverrides.MuiBreadcrumbs
      },
      MuiButton: {
        defaultProps: defaultProps.MuiButton,
        styleOverrides: styleOverrides.MuiButton
      },
      MuiCard: {
        defaultProps: defaultProps.MuiCard,
        styleOverrides: styleOverrides.MuiCard
      },
      MuiCardActions: {
        styleOverrides: styleOverrides.MuiCardActions
      },
      MuiCardContent: {
        styleOverrides: styleOverrides.MuiCardContent
      },
      MuiCardHeader: {
        styleOverrides: styleOverrides.MuiCardHeader
      },
      MuiCheckbox: {
        styleOverrides: styleOverrides.MuiCheckbox
      },
      MuiChip: {
        styleOverrides: styleOverrides.MuiChip
      },
      MuiCssBaseline: {
        styleOverrides: styleOverrides.MuiCssBaseline
      },
      MuiDialog: {
        styleOverrides: styleOverrides.MuiDialog
      },
      MuiDivider: {
        styleOverrides: styleOverrides.MuiDivider
      },
      MuiDrawer: {
        styleOverrides: styleOverrides.MuiDrawer
      },
      MuiFormHelperText: {
        styleOverrides: styleOverrides.MuiFormHelperText
      },
      MuiInput: {
        defaultProps: defaultProps.MuiInput,
        styleOverrides: styleOverrides.MuiInput
      },
      MuiInputBase: {
        styleOverrides: styleOverrides.MuiInputBase
      },
      MuiInputLabel: {
        styleOverrides: styleOverrides.MuiInputLabel
      },
      MuiLink: {
        defaultProps: defaultProps.MuiLink
      },
      MuiList: {
        defaultProps: defaultProps.MuiList,
        styleOverrides: styleOverrides.MuiList
      },
      MuiListItem: {
        styleOverrides: styleOverrides.MuiListItem
      },
      MuiListItemAvatar: {
        styleOverrides: styleOverrides.MuiListItemAvatar
      },
      MuiListItemText: {
        defaultProps: defaultProps.MuiListItemText,
        styleOverrides: styleOverrides.MuiListItemText
      },
      MuiMenu: {
        styleOverrides: styleOverrides.MuiMenu
      },
      MuiOutlinedInput: {
        styleOverrides: styleOverrides.MuiOutlinedInput
      },
      MuiPaper: {
        styleOverrides: styleOverrides.MuiPaper
      },
      MuiRadio: {
        styleOverrides: styleOverrides.MuiRadio
      },
      MuiStepConnector: {
        styleOverrides: styleOverrides.MuiStepConnector
      },
      MuiStepLabel: {
        defaultProps: defaultProps.MuiStepLabel,
        styleOverrides: styleOverrides.MuiStepLabel
      },
      MuiStepper: {
        defaultProps: defaultProps.MuiStepper
      },
      MuiTab: {
        defaultProps: defaultProps.MuiTab,
        styleOverrides: styleOverrides.MuiTab
      },
      MuiTableCell: {
        styleOverrides: styleOverrides.MuiTableCell
      },
      MuiTableContainer: {
        styleOverrides: styleOverrides.MuiTableContainer
      },
      MuiTableRow: {
        styleOverrides: styleOverrides.MuiTableRow
      },
      MuiTabs: {
        styleOverrides: styleOverrides.MuiTabs
      },
      MuiTextField: {
        defaultProps: defaultProps.MuiTextField,
        styleOverrides: styleOverrides.MuiTextField
      },
      MuiTypography: {
        styleOverrides: styleOverrides.MuiTypography
      }
    },
    palette,
    typography
  });

  window.theme = reeceTheme;

  /**
   * Render
   */
  return (
    <MuiThemeProvider theme={reeceTheme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
}

export default ThemeProvider;
