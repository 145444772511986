import { DayContent, DayContentProps } from 'react-day-picker';

/**
 * Component
 */
function DayPickerDay(props: DayContentProps) {
  return (
    <>
      <DayContent {...props} />
      <div className="backdrop">{props.date.getDate()}</div>
    </>
  );
}

export default DayPickerDay;
