import { useMemo } from 'react';

import { Container } from '@mui/material';
import { chunk } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import BarcodeBox from 'react-jsbarcode';
import { useParams } from 'react-router-dom';

import { Image, Loader } from 'components';
import { useGetListQuery } from 'generated/graphql';
import 'pages/Barcode/print.css';
import { useAuthContext } from 'providers/AuthProvider';
import { useBranchContext } from 'providers/BranchProvider';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';
import { encryptData } from 'utils/encrypt';

/**
 * Component
 */
function Barcode() {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  /**
   * Context
   */
  const { profile } = useAuthContext();
  const { shippingBranch } = useBranchContext();
  const { selectedAccounts } = useSelectedAccountsContext();

  // 🔵 Memo - Encrypted ShipTo and BillTo
  // 🔶 Initialized here so it can be used on graphql query
  const encryptedBillTo = useMemo(
    () => encryptData(selectedAccounts.billToErpAccount?.erpAccountId ?? ''),
    [selectedAccounts.billToErpAccount?.erpAccountId]
  );

  /**
   * Data
   */
  // 🟣 Query - List
  const { data, loading: listLoading } = useGetListQuery({
    fetchPolicy: 'no-cache',
    skip:
      !shippingBranch?.branchId ||
      !selectedAccounts.billToErpAccount?.erpAccountId ||
      !selectedAccounts.shipTo?.id ||
      !profile?.userId ||
      !id,
    variables: {
      listId: id,
      userId: profile?.userId ?? '',
      shipToAccountId: selectedAccounts.shipTo?.id ?? '',
      branchId: shippingBranch?.branchId ?? '',
      erpAccountId: encryptedBillTo
    }
  });

  /**
   * Memo
   */
  // 🔵 Memo - Chunk breakdown
  const listChunk = useMemo(
    () => chunk(data?.list.listLineItems, 12),
    [data?.list.listLineItems]
  );

  /**
   * Render
   */
  return (
    <Container
      maxWidth="md"
      disableGutters
      className="bg-common-white"
      data-testid="barcode-container"
    >
      {listLoading && <Loader testId="barcode-loader" />}
      {listChunk.map((chunk, index) => (
        <div className="flex flex-wrap p-4 h-[11in]" key={`chunk-${index}`}>
          {chunk.map((item) => (
            <div
              className="w-1/4 h-1/3 px-3 py-1"
              key={item.id}
              data-testid="barcode-item"
            >
              <div className="h-full flex flex-col border border-secondary-2-100 px-4 pt-4 overflow-hidden">
                <div className="border border-secondary-3-100 flex items-center justify-center h-16 rounded overflow-hidden">
                  <Image
                    src={item.imageUrls?.thumb ?? ''}
                    className="max-h-16"
                  />
                </div>
                <div className="flex-1 text-sm pt-2">
                  <p className="text-xs text-center">
                    {t('product.productName')}:
                  </p>
                  <p className="text-sm font-bold text-center line-clamp-2">
                    {item.name ?? t('common.na')}
                  </p>
                  <hr className="border-common-black my-2" />
                  <div className="text-caption">
                    <p className="text-center">
                      {`${t('product.mfr')}: `}
                      <b>{item.manufacturerNumber ?? t('common.na')}</b>
                    </p>
                    <p className="text-center">
                      {`${t('product.id')}: `}
                      <b>{item.erpPartNumber ?? t('common.na')}</b>
                    </p>
                  </div>
                </div>
                <div className="flex justify-center">
                  <BarcodeBox
                    options={{
                      width: 1,
                      height: 48,
                      fontSize: 16,
                      background: 'transparent',
                      text: item.erpPartNumber ?? ''
                    }}
                    value={item.erpPartNumber ? `.${item.erpPartNumber}` : ''}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </Container>
  );
}

export default Barcode;
