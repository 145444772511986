/**
 * Consts & Config
 */
export const displayCount = ['25', '50', '100'] as const;

export const OrdersColumns = {
  orderDate: 'Order date',
  orderNumber: 'Order number',
  poNumber: 'PO #',
  fulfillment: 'Fulfillment',
  orderStatus: 'Order status',
  fulfilledDate: 'Fulfilled date',
  orderTotal: 'Order total',
  invoiceStatus: 'Invoices'
} as const;

export const OrdersColumnNames = [
  {
    id: 'orderDate',
    label: 'Order date'
  },
  {
    id: 'orderNumber',
    label: 'Order number'
  },
  {
    id: 'poNumber',
    label: 'PO #'
  },
  {
    id: 'fulfillment',
    label: 'Fulfillment'
  },
  {
    id: 'orderStatus',
    label: 'Order status'
  },
  {
    id: 'fulfilledDate',
    label: 'Fulfilled date'
  },
  {
    id: 'orderTotal',
    label: 'Order total'
  },
  {
    id: 'invoiceStatus',
    label: 'Invoices'
  }
];

export const ordersStatusEclipse = [
  'All',
  'Order placed',
  'In-transit',
  'Delivered',
  'Ready for pick up',
  'Picked up',
  'Cancelled'
] as const;

export const ordersStatusMincron = [
  {
    id: 'All',
    label: 'All'
  },
  {
    id: 'OPEN',
    label: 'Open'
  },
  {
    id: 'SHIPPED',
    label: 'Shipped'
  },
  {
    id: 'INVOICED',
    label: 'Invoiced'
  },
  {
    id: 'PENDING',
    label: 'Pending'
  },
  {
    id: 'RESERVED',
    label: 'Reserved'
  }
];

export const ordersSort = [
  {
    id: '!orderDate',
    label: 'Newest'
  },
  {
    id: 'orderDate',
    label: 'Oldest'
  },
  {
    id: '!orderTotal',
    label: 'Largest total'
  },
  {
    id: 'orderTotal',
    label: 'Smallest total'
  }
];
