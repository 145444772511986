import { useMemo, useState } from 'react';

import { Collapse } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Alert, IconButton } from 'components';
import { WarningIcon, CloseIcon } from 'icons';
import { useAuthContext } from 'providers/AuthProvider';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';

/**
 * Component
 */
function HoldAlert() {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();

  /**
   * Context
   */
  const { authState } = useAuthContext();
  const { selectedAccounts } = useSelectedAccountsContext();
  const auth = authState?.isAuthenticated;
  const { billToErpAccount: billTo = {}, shipToErpAccount: shipTo = {} } =
    selectedAccounts;

  /**
   * State
   */
  const [open, setOpen] = useState(true);

  /**
   * Memo
   */
  const showBanner = useMemo(() => {
    const creditHold = billTo.creditHold || shipTo.creditHold;
    return creditHold && auth;
  }, [billTo.creditHold, shipTo.creditHold, auth]);

  /**
   * Render
   */
  if (!showBanner) {
    return null;
  }
  return (
    <div
      className="relative left-0 inset-x-0 top-0 z-[2] md:inset-x-auto"
      data-testid="holdalert-wrapper"
    >
      <div className="justify-center sticky top-0 inset-x-0 md:relative md:top-auto md:inset-x-auto">
        <Collapse in={open}>
          <Alert
            customStyle
            severity="warning"
            className="!bg-secondary-1-10 !text-secondary-2-100 text-sm border !border-secondary-1-100 [&_.MuiAlert-icon]:!text-secondary-1-100"
            icon={<WarningIcon />}
            data-testid="hold-alert"
            action={
              <IconButton
                color="inherit"
                size="small"
                data-testid="close-hold-alert"
                onClick={() => setOpen(false)}
              >
                <CloseIcon />
              </IconButton>
            }
          >
            {t('common.accountHold')}
          </Alert>
        </Collapse>
      </div>
    </div>
  );
}

export default HoldAlert;
