export const reeceDomains = [
  'schumacherseiler.com',
  'morrisonsupply.com',
  'morscohvacsupply.com',
  'expresspipe.com',
  'devoreandjohnson.com',
  'wholesalespecialties.com',
  'expressionshomegallery.com',
  'fortiline.com',
  'morsco.com',
  'reece.com'
];
