import palette from 'providers/libs/Theme/palette';

const muiStepConnector = {
  alternativeLabel: {
    left: '-50%',
    right: '50%',
    '&.Mui-active': {
      '& .MuiStepConnector-line': {
        borderColor: palette.primary02.main
      }
    },
    '&.Mui-completed': {
      '& .MuiStepConnector-line': {
        borderColor: palette.primary02.main
      }
    }
  },
  line: {
    borderColor: palette.common.white,
    borderTopWidth: 8,
    marginTop: -4,
    zIndex: 1,
    '.MuiStepper-backgroundGray .MuiStepConnector-root:not(.Mui-active):not(.Mui-completed) &':
      {
        borderColor: palette.secondary04.main
      }
  },
  lineHorizontal: {
    borderTopWidth: 8,
    marginTop: -4
  },
  root: {}
};

export default muiStepConnector;
