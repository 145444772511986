import { HTMLAttributes, useEffect, useMemo, useState } from 'react';

import { Modal, Slide } from '@mui/material';
import clsx from 'clsx';
import useResizeObserver from 'use-resize-observer/polyfilled';

import { Modify } from '@reece/global-types';
import { useSidebarContentRef } from 'common/Sidebar/lib/useSidebarContentRef';
import Backdrop from 'common/Sidebar/sub/Backdrop';
import SidebarError from 'common/Sidebar/sub/SidebarError';
import { ConditionalWrapper, IconButton } from 'components';
import useScreenSize from 'hooks/useScreenSize';
import { CloseIcon } from 'icons';
import { readjustSidebarHeight } from 'common/Sidebar/lib/readjustSidebarHeight';

/**
 * Config
 */
export const DEFAULT_WIDTH = 380;
export const HEADER_HEIGHT = 56;

/**
 * Types
 */
type NewProps = {
  close: () => void;
  error?: boolean;
  on: boolean;
  title?: string;
  isContentLoading?: boolean;
  widthOverride?: number;
  containerClassname?: string;
  backdropClassname?: string;
};
export type SidebarProps = Modify<HTMLAttributes<HTMLElement>, NewProps>;

/**
 * Component
 */
function Sidebar(props: SidebarProps) {
  /**
   * Custom Hooks
   */
  const { isSmallScreen } = useScreenSize();

  /**
   * State
   */
  const [sidebarHeight, setSidebarHeight] = useState(0);

  /**
   * Refs
   */
  const contentRef = useSidebarContentRef();
  const { height: contentHeight = 0 } = useResizeObserver<HTMLDivElement>({
    ref: contentRef
  });

  /**
   * Memo
   */
  // 🔵 Memo - available height
  const availableHeight = useMemo(
    () =>
      Math.min(
        contentHeight,
        window.innerHeight -
          (contentRef.current?.getBoundingClientRect().y ?? 0)
      ),
    [contentHeight, contentRef]
  );

  /**
   * Effect
   */
  // 🟡 Effect - Adjust sidebar height
  useEffect(() => {
    if (!props.on || props.isContentLoading) {
      const mobileHeight = window.innerHeight - HEADER_HEIGHT;
      const desktopHeight = contentHeight - HEADER_HEIGHT;
      setSidebarHeight(isSmallScreen ? mobileHeight : desktopHeight);
      return;
    }
    readjustSidebarHeight(isSmallScreen, availableHeight, setSidebarHeight);
  }, [
    availableHeight,
    contentHeight,
    props.isContentLoading,
    props.on,
    isSmallScreen
  ]);
  // 🟡 Effect - Disable scrolling
  useEffect(() => {
    const root = document.getElementById('root');
    if (root) {
      root.style.overflow = props.on ? 'hidden' : 'visible';
    }
    return () => {
      if (root) {
        root.style.overflow = 'visible';
      }
    };
  }, [props.on]);

  /**
   * Render
   */
  return (
    <>
      <Backdrop
        on={props.on}
        onClick={props.close}
        className={clsx(props.backdropClassname, 'backdrop-filter-none')}
      />
      {/* Used for referencing height */}
      <div className="fixed w-full h-full z-[-1]" ref={contentRef} />
      <ConditionalWrapper
        condition={isSmallScreen}
        wrapper={(children) => (
          <Modal open={props.on} disablePortal>
            <>{children}</>
          </Modal>
        )}
      >
        <Slide
          direction="left"
          in={props.on}
          mountOnEnter
          unmountOnExit
          children={
            <div
              className={clsx(
                props.containerClassname,
                'absolute top-0 right-0 z-[25] overflow-hidden bg-common-white shadow-sidebar md:h-screen'
              )}
              style={{
                // Have to use style on these due to the dynamic nature that Tailwind can't handle
                width: isSmallScreen
                  ? '100vw'
                  : props.widthOverride ?? DEFAULT_WIDTH,
                maxHeight: isSmallScreen ? undefined : availableHeight || '100%'
              }}
              data-testid="sidebar-container"
            >
              <div className="flex flex-col" data-testid="branch-list">
                <div
                  id="sidebar-header"
                  className="py-2 pl-6 pr-4 flex justify-between items-center shadow"
                >
                  {Boolean(props.title) && (
                    <h5
                      className="text-primary-1-100 text-xl font-medium"
                      data-testid="sidebar-title"
                    >
                      {props.title}
                    </h5>
                  )}
                  <IconButton
                    onClick={props.close}
                    size="large"
                    className="!p-2"
                    data-testid="slider-close"
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
                {props.error ? (
                  <SidebarError />
                ) : (
                  <div
                    className="flex flex-col"
                    style={{
                      // Have to use style on these due to the dynamic nature that Tailwind can't handle
                      [isSmallScreen || props.isContentLoading
                        ? 'height'
                        : 'maxHeight']: sidebarHeight
                    }}
                    data-testid="sidebar-subcontainer"
                  >
                    {props.children}
                  </div>
                )}
              </div>
            </div>
          }
        />
      </ConditionalWrapper>
    </>
  );
}

export default Sidebar;
