import { ComponentPropsWithoutRef, forwardRef } from 'react';

import clsx from 'clsx';

/**
 * Styles
 */
const textareaStyle = [
  'px-4 py-[11px] w-full w-full rounded',
  'text-secondary-2-100 bg-common-white text-base',
  'transition-all',
  'border border-solid border-secondary-3-100',
  'outline-none focus:shadow-input',
  '[&::placeholder]:text-secondary-3-100',
  '[&:hover&:not(:disabled)]:border-primary-2-100',
  '[&:focus&:not(:disabled)]:border-primary-2-100',
  '[&:focus&:not(:disabled)]:shadow-primary-2-50',
  'disabled:bg-primary-3-10',
  'read-only:bg-secondary-4-60'
];
const textareaError = 'border-support-1-100';

/**
 * Types
 */
export type TextareaBaseBaseProps = ComponentPropsWithoutRef<'textarea'> & {
  error?: boolean;
  testId?: string;
};

/**
 * Component
 */
const TextareaBase = forwardRef<HTMLTextAreaElement, TextareaBaseBaseProps>(
  (props, ref) => {
    /**
     * Props
     */
    const { error, className, testId, ...rest } = props;

    /**
     * Render
     */
    return (
      <textarea
        className={clsx(textareaStyle, className, { [textareaError]: error })}
        ref={ref}
        data-testid={testId}
        {...rest}
      />
    );
  }
);

export default TextareaBase;
