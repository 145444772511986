import clsx from 'clsx';
import Dotdotdot from 'react-dotdotdot';

import { Image, Link } from 'components';
import { FSItem } from 'pages/Search/sub/FeaturedSearch';

/**
 * Types
 */
type Props = {
  item: FSItem;
};

/**
 * Constants
 */
const NOT_ALLOWED_CHAR = /[^a-zA-Z0-9\\ \\-]/;
const SEARCH_URL = '/search?criteria=';

/**
 * Component
 */
function FeaturedSearchItem(props: Props) {
  /**
   * Props
   */
  const { image, name, search, url } = props.item;
  const criteria = (search ?? name).replace(NOT_ALLOWED_CHAR, '');
  const searchQueryUrl = url ?? SEARCH_URL + criteria;
  const splitName = name.split('</br>');
  const shouldHaveTwoLines = splitName.length > 1;

  /**
   * Render
   */
  return (
    <div
      className="py-2 basis-1/5 max-w-[20%] md:basis-full md:max-w-full"
      data-testid={`featured-search-item${name}`}
    >
      <Link
        href={searchQueryUrl}
        to={searchQueryUrl}
        className="h-[100px] flex justify-center"
      >
        <Image
          alt={name}
          src={image}
          data-testid={`featured-search-item-image${name}`}
          className="object-contain max-w-full max-h-full w-auto h-auto"
        />
      </Link>
      <div className={clsx('text-center', { 'pt-3': !shouldHaveTwoLines })}>
        <Link
          href={searchQueryUrl}
          to={searchQueryUrl}
          data-testid={`featured-search-url${name}`}
        >
          <Dotdotdot
            clamp={shouldHaveTwoLines ? 2 : 1}
            className="text-primary-3-100"
          >
            {shouldHaveTwoLines ? (
              <p data-testid={`featured-search-item-name${name}`}>
                {splitName[0]}
                <br data-testid={`featured-search-item-name${name}_break`} />
                {splitName[1]}
              </p>
            ) : (
              <p data-testid={`featured-search-item-name${name}`}>{name}</p>
            )}
          </Dotdotdot>
        </Link>
      </div>
    </div>
  );
}
export default FeaturedSearchItem;
