import theme from 'providers/libs/Theme/baseTheme';

const muiCardActions = {
  root: {
    padding: theme.spacing(1.5, 2),
    '& input[type=number]': {
      width: theme.spacing(6)
    },
    '& > .MuiButton-contained:first-of-type': {
      marginLeft: theme.spacing(1)
    }
  }
};

export default muiCardActions;
