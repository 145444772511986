import { useState } from 'react';

import { MenuItem } from '@mui/material';

import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Maybe } from 'yup/lib/types';

import { CreateListRequest } from 'API/types/lists.types';
import { Button, DropdownButton } from 'components';
import { AddIcon, FileUploadIcon, BlankList } from 'icons';
import useScreenSize from 'hooks/useScreenSize';
import {
  ListAction,
  useListsPageContext
} from 'pages/Lists/provider/ListsPageProvider';
import { ListNaming } from 'pages/Lists/provider/hooks/useListActionsForm';
import ListFormDialog from 'pages/Lists/sub/ListFormDialog';
import { useToastContext } from 'providers/ToastProvider';

/**
 * Component
 */
function ListsCreateListButton() {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();
  const { push } = useHistory();
  const { isSmallScreen } = useScreenSize();

  /**
   * Contexts
   */
  const { applySelectedList, callCreateList, createListLoading, setLists } =
    useListsPageContext();
  const { toast } = useToastContext();

  /**
   * State
   */
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [formikInstance, setFormikInstance] =
    useState<Maybe<FormikProps<ListNaming>>>(null);

  /**
   * Callbacks
   */
  // 🟤 Cb - Make create list call
  const createList = async (values: ListNaming) => {
    // Remove whitespace
    const name = values.name.trim();
    const description = values.description.trim();
    const createParams: CreateListRequest = {
      name,
      description,
      products: []
    };
    callCreateList(createParams)
      .then((data) => {
        if (data?.lists?.length) {
          toast({
            message: t('lists.actionSuccess', {
              name,
              change: ListAction.create
            }),
            kind: 'success'
          });
          setLists(data.lists);
          applySelectedList(data.lists[0], 0);
          formikInstance?.resetForm();
        }
      })
      .catch(() => {
        toast({
          message: t('lists.actionFail', {
            name,
            change: ListAction.create
          }),
          kind: 'error'
        });
      });
  };
  // 🟤 Cb - Close create dialog
  const handleCloseCreateDialog = () => {
    setOpenFormDialog(false);
    formikInstance?.resetForm();
  };

  /**
   * Render
   */
  return (
    <>
      <DropdownButton
        testId="create-list-button"
        className="!bg-primary-2-90 !text-common-white !text-base !font-medium !min-w-0 whitespace-nowrap md:w-full"
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        hideLastIcon
        iconStart={<AddIcon />}
        content={(_, setOpen) => (
          <>
            <MenuItem
              onClick={() => {
                setOpenFormDialog(true);
                setOpen(false);
              }}
            >
              <Button
                data-testid="list-create-blank-button"
                iconStart={<BlankList />}
                color="gray"
                kind="text"
                size="sm"
              >
                {t('lists.startBlank')}
              </Button>
            </MenuItem>
            <MenuItem
              onClick={() => {
                push('/lists/upload/');
                setOpen(false);
              }}
            >
              <Button
                data-testid="list-create-blank-button"
                iconStart={<FileUploadIcon />}
                color="gray"
                kind="text"
                size="sm"
              >
                {t('lists.uploadToList')}
              </Button>
            </MenuItem>
          </>
        )}
      >
        {t('lists.createOrUploadList')}
      </DropdownButton>
      {/* Create Modal */}
      <ListFormDialog
        initialValues={{ name: '', description: '' }}
        actionType={ListAction.create}
        setFormikInstance={setFormikInstance}
        open={openFormDialog}
        onClose={handleCloseCreateDialog}
        loading={createListLoading}
        onSubmitCallback={createList}
        title={t(
          isSmallScreen ? 'lists.createNewList' : 'lists.createOrUploadList'
        )}
      >
        <Button
          type="submit"
          key="list-dialog-form-create-blank-button"
          data-testid="list-dialog-form-create-blank-button"
          fullWidth
          disabled={createListLoading}
          onClick={formikInstance?.submitForm}
        >
          {t('lists.createNewList')}
        </Button>
      </ListFormDialog>
    </>
  );
}
export default ListsCreateListButton;
