import { useMemo } from 'react';

import clsx from 'clsx';
import { Container, Toolbar } from '@mui/material';
import { useLocation } from 'react-router-dom';

import useHeaderItems, {
  HeaderItem
} from 'common/OldHeader/hooks/useHeaderItems';
import { Link } from 'components';
import { MaxIcon } from 'icons';
/**
 * Component
 */
function NewListsSubheader() {
  /**
   * Custom hooks
   */
  const { pathname } = useLocation();
  const navItems = useHeaderItems();

  /**
   * Memo
   */
  // 🔵 Memo - rendered nav items with selected state
  const processedNavItems = useMemo(
    () =>
      navItems.reduce<HeaderItem[]>((prev, item) => {
        const selected = item.validPaths.some((path) =>
          pathname.includes(path)
        );
        item.visible && prev.push({ ...item, selected });
        return prev;
      }, []),
    [pathname, navItems]
  );

  /**
   * Render
   */
  return (
    <Toolbar
      disableGutters
      variant="dense"
      className="bg-primary-2-5 shadow-lg h-11"
      sx={{ minHeight: 44 }}
    >
      <Container
        maxWidth="lg"
        data-testid="header-desktopsubheader-container"
        sx={{ height: 44 }}
      >
        <div className="flex items-center h-full">
          {/* MAX */}
          <div className="pl-6 !pr-4 !min-w-12 !bg-transparent">
            <MaxIcon className="!w-[47px] !h-[16px]" />
          </div>
          <div className="flex items-center gap-x-4 h-full [&_button]:py-0">
            {/* Nav items */}
            {processedNavItems.map((item, i) => (
              <Link
                className={clsx(
                  'border-b-[3px] border-transparent text-sm h-full text-nowrap font-medium flex px-4 items-center !text-primary-1-100',
                  {
                    '!border-current !font-bold': item.selected
                  }
                )}
                to={item.path}
                key={`navitem-${i}`}
              >
                {item.name}
              </Link>
            ))}
          </div>
        </div>
      </Container>
    </Toolbar>
  );
}

export default NewListsSubheader;
