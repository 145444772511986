import { useState } from 'react';

import {
  useApiPutLastSelectedAccounts,
  useApiSetSelectedBranch
} from 'API/users.api';
import {
  EcommAccount,
  Maybe,
  ShipToAccountsQuery,
  useBillToAccountsQuery,
  useGetRefreshShipToAccountLazyQuery,
  useShipToAccountsLazyQuery
} from 'generated/graphql';
import { useAuthContext } from 'providers/AuthProvider';

/**
 * Hook
 */
function useSelectAccountsFormData() {
  /**
   * Context
   */
  const { profile } = useAuthContext();

  /**
   * State
   */
  const [selectedBillTo, setSelectedBillTo] = useState<EcommAccount>();
  const [selectedShipTo, setSelectedShipTo] = useState<EcommAccount>();
  const [selectedBillToShipTos, setSelectedBillToShipTos] =
    useState<EcommAccount[]>();
  const [isBillToChange, setIsBillToChange] = useState<boolean>(true);

  /**
   * Data
   */
  // 🟣 Query - Bill-to accouts
  const { data: billTosData, loading: billTosLoading } = useBillToAccountsQuery(
    {
      fetchPolicy: 'no-cache',
      skip: !profile?.userId,
      variables: { userId: profile?.userId ?? '' }
    }
  );
  // 🟣 Lazy Query - Ship-to accouts
  const [getShipToDataQuery, { loading: shipTosLoading }] =
    useShipToAccountsLazyQuery({
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      onCompleted: onShipToDataQueryCompleted
    });
  // 🟣 Lazy Query - Refresh Ship-to accouts
  const [getRefreshShipToList, { loading: getRefreshShipToListLoading }] =
    useGetRefreshShipToAccountLazyQuery({ fetchPolicy: 'network-only' });
  // 🟣 BFF PUT - Set Branch
  const { call: setSelectedBranch } = useApiSetSelectedBranch();
  // 🟣 BFF PUT - Update selected billTo and ShipTo
  const { call: updateLastSelectedAccounts } = useApiPutLastSelectedAccounts();

  // Misc const
  const accountsData: Maybe<EcommAccount[]> | undefined =
    billTosData?.billToAccounts;
  const accountsLoading = billTosLoading || shipTosLoading;

  /**
   * Output
   */
  return {
    accountsData,
    accountsLoading,
    getShipToDataQuery,
    getRefreshShipToList,
    getRefreshShipToListLoading,
    onShipToDataQueryCompleted,
    selectedBillTo,
    selectedBillToShipTos,
    selectedShipTo,
    setIsBillToChange,
    setSelectedBillTo,
    setSelectedBillToShipTos,
    setSelectedBranch,
    setSelectedShipTo,
    updateLastSelectedAccounts
  };

  /**
   * Special
   */
  // 🔸 Splitting this logics off because it's impossible to get test coverage by making selectedBillTo NOT undefined
  // due to the nature of React states preventing it to be updated
  function onShipToDataQueryCompleted(
    data?: ShipToAccountsQuery,
    test?: EcommAccount
  ) {
    const billTo = selectedBillTo || test;
    const useBillTo = billTo ? [billTo] : [];
    const shipTos: EcommAccount[] = data?.shipToAccounts?.length
      ? data.shipToAccounts
      : useBillTo;
    setSelectedBillToShipTos(shipTos);
    isBillToChange && setSelectedShipTo(shipTos[0]);
  }
}

export default useSelectAccountsFormData;
