// Wrapper
export const wrapperFullWidth = 'w-full [&_input]:flex-1';

// Button
export const buttonWrapperStyle =
  'bg-common-white shadow-secondary-3-100 transition hover:bg-secondary-4-50 [@media_(hover:_none)]:!bg-transparent';
export const buttonLeftBorder =
  'shadow-[inset_1px_1px_0_0_rgba(0,0,0,0),inset_1px_-1px_0_0_rgba(0,0,0,0)]';
export const buttonRightBorder =
  'shadow-[inset_-1px_1px_0_0_rgba(0,0,0,0),inset_-1px_-1px_0_0_rgba(0,0,0,0)]';
export const buttonSizeStyles = {
  xs: 'w-[25px] [&_svg]:w-4 [&_svg]:h-4',
  sm: 'w-[30px] [&_svg]:w-4 [&_svg]:h-4',
  md: 'w-14 [&_svg]:w-6 [&_svg]:h-6',
  lg: 'w-[60px] [&_svg]:w-6 [&_svg]:h-6'
};
export const buttonDisabled = 'bg-secondary-4-100';
export const buttonStyle = [
  'w-full h-full p-0',
  'cursor-pointer border-none',
  'bg-transparent text-primary-1-100',
  'disabled:cursor-default',
  'disabled:text-secondary-3-100'
];

// Input
export const inputStyle =
  'text-primary-1-100 text-center z-10 !rounded-none !p-0';
export const inputSizeStyles = {
  xs: 'h-[30px] !w-8 !text-xs2 leading-4',
  sm: 'h-[37px] !w-[40px] !text-xs leading-4',
  md: 'h-[55px] !w-[85px] !text-sm leading-6',
  lg: 'h-[60px] !w-[140px] !text-xl leading-6'
};
