import { Container } from '@mui/material';

import BigDogFeaturedItems from 'pages/BrandPages/BigDog/sections/BigDogFeaturedItems';
import BigDogFooter from 'pages/BrandPages/BigDog/sections/BigDogFooter';
import BigDogHeader from 'pages/BrandPages/BigDog/sections/BigDogHeader';
import BigDogMission from 'pages/BrandPages/BigDog/sections/BigDogMission';

/**
 * Component
 */
function BigDogMarketing() {
  /**
   * Render
   */
  return (
    <Container
      disableGutters
      className="!max-w-[1440px] bg-common-white"
      data-testid="bigdog-root"
    >
      <BigDogHeader />
      <BigDogMission />
      <BigDogFeaturedItems />
      <BigDogFooter />
    </Container>
  );
}

export default BigDogMarketing;
