import { yupResolver } from '@hookform/resolvers/yup';
import { useAccountPageContext } from 'pages/Account/AccountPageProvider';
import { Maybe } from 'generated/graphql';
import useUsernameParts from 'hooks/useUsernameParts';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

/**
 * Config
 */
const defaultValues = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: ''
};
const passwordRegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;

/**
 * Types
 */
export type PasswordForm = typeof defaultValues;

/**
 * Hook
 */
function usePasswordChangeForm() {
  /**
   * Context
   */
  const { userInfo } = useAccountPageContext();

  /**
   * Custom hooks
   */
  const { t } = useTranslation();
  const usernamePartsToMatch = useUsernameParts(userInfo.email);

  /**
   * Callbacks
   */
  // 🟤 Cb - Validate Old Password
  const isValidOldPassword = (oldPassword?: Maybe<string>) =>
    passwordRegExp.test(oldPassword || '');
  // 🟤 Cb - Validate New Password
  const isValidNewPassword = (newPassword?: Maybe<string>) => {
    const toCheck = newPassword || '';
    // Okta password should not contains username
    const oktaPasswordResult = !usernamePartsToMatch.some((part) =>
      toCheck.toLowerCase().includes(part)
    );
    return passwordRegExp.test(toCheck) && oktaPasswordResult;
  };

  /**
   * Form
   */
  return useForm({
    defaultValues,
    resolver: yupResolver(
      Yup.object({
        oldPassword: Yup.string()
          .required(t('validation.passwordRequired'))
          .test('isValid', t('validation.passwordInvalid'), isValidOldPassword),
        newPassword: Yup.string()
          .required(t('validation.passwordRequired'))
          .test('isValid', t('validation.passwordInvalid'), isValidNewPassword),
        confirmPassword: Yup.string()
          .required(t('validation.confirmPasswordRequired'))
          .oneOf([Yup.ref('newPassword'), ''], t('validation.passwordNoMatch'))
      })
    ),
    mode: 'onBlur',
    reValidateMode: 'onSubmit'
  });
}

export default usePasswordChangeForm;
