// istanbul ignore file
import { Skeleton } from '@mui/material';

/**
 * Types
 */
type Props = {
  testid: string;
};

/**
 * Component
 */
function SearchResultSkeleton(props: Props) {
  /**
   * Render
   */
  return (
    <div className="p-4 md:p-0 md:flex md:gap-6" data-testid={props.testid}>
      <Skeleton
        variant="rectangular"
        className="w-full h-[120px] mb-6 md:basis-1/3 md:h-[170px] md:mb-0"
      />
      <p className="text-caption">
        <Skeleton variant="text" />
      </p>
      <p>
        <Skeleton variant="text" className="h-14" />
      </p>
      <p className="text-caption">
        <Skeleton variant="text" />
      </p>
      <div className="flex items-center mt-10">
        <Skeleton
          variant="rectangular"
          className="w-[45px] h-[30px] md:w-[60px] md:h-[41px] md:flex-shrink-0"
        />
        <Skeleton
          variant="rectangular"
          className="w-full ml-2 h-[30px] md:ml-4 md:h-[41px]"
        />
      </div>
    </div>
  );
}
export default SearchResultSkeleton;
