import Card from 'components/Card';
import Link from 'components/Link';

/**
 * Config
 */
const display = {
  title: 'Link',
  stadard: 'Standard URL',
  newWindow: 'New Window'
};

/**
 * Component
 */
function LinkPage() {
  return (
    <Card className="my-8 p-6">
      <h5 className="text-2xl font-bold text-secondary-2-100">
        {display.title}
      </h5>
      <hr className="my-4" />
      <div className="my-4 flex justify-cente">
        <Link to="#">{display.stadard}</Link>
      </div>
      <div className="my-4 flex justify-cente">
        <Link to="https://google.com" target="_blank">
          {display.newWindow}
        </Link>
      </div>
    </Card>
  );
}

export default LinkPage;
