import palette from 'providers/libs/Theme/palette';
import theme from 'providers/libs/Theme/baseTheme';

const muiButton = {
  root: {
    borderRadius: '2px',
    padding: theme.spacing(1, 3)
  },
  text: {
    padding: theme.spacing(1, 3)
  },
  textPrimary: {
    '&:hover': {
      backgroundColor: 'transparent',
      color: palette.primary.light
    }
  },
  outlined: {
    padding: theme.spacing(1, 3)
  },
  outlinedPrimary: {
    border: '0 none',
    boxShadow: `inset 0 0 0 1px ${palette.primary.main}`,
    '&:hover': {
      backgroundColor: 'transparent',
      color: palette.primary.light,
      border: '0 none',
      boxShadow: `inset 0 0 0 1px ${palette.primary.light}`
    },
    '&.Mui-disabled': {
      border: '0 none',
      boxShadow: `inset 0 0 0 1px ${palette.lightGray.main}`
    }
  },
  containedPrimary: {
    backgroundColor: palette.primary.main,
    '&:hover': {
      backgroundColor: palette.primary.light
    }
  },
  sizeSmall: {
    padding: theme.spacing(0.5, 2),
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
    letterSpacing: '0.005em'
  },
  sizeLarge: {
    padding: theme.spacing(2, 4),
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    letterSpacing: '0.005em'
  },
  iconSizeSmall: {
    '& > *:first-child': {
      height: theme.spacing(2.25),
      width: theme.spacing(2.25)
    }
  },
  iconSizeMedium: {
    '& > *:first-child': {
      height: theme.spacing(2.5),
      width: theme.spacing(2.5)
    }
  },
  startIcon: {
    marginLeft: 0,
    marginRight: theme.spacing(1.5)
  },
  endIcon: {
    marginLeft: theme.spacing(1.5),
    marginRight: 0
  },
  disabled: {}
};

export default muiButton;
