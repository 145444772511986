import { ReactNode, useMemo, useRef, useState } from 'react';

import {
  PaperProps,
  Popover,
  PopoverOrigin,
  SxProps,
  Theme
} from '@mui/material';
import clsx from 'clsx';
import { uniqueId } from 'lodash-es';

import Button from 'components/Button';
import { ArrowDropDownIcon } from 'icons';

/**
 * Styles
 */
export const buttonStyle = '!rounded text-sm font-normal leading-[21px]';
export const buttonIconEndStyle = 'transition-transform ease-in-out';
export const buttonOpenStyle = '!bg-primary-2-10';
export const buttonIconEndOpenStyle = 'scale-y-[-1]';
export const paperStyle =
  '!mt-1 border border-solid border-secondary-4-100 !rounded !shadow-lg';

/**
 * Types
 */
export type DropdownButtonProps = {
  anchorOrigin?: PopoverOrigin;
  sx?: SxProps<Theme>;
  children?: ReactNode;
  className?: string;
  content: (open: boolean, setOpen: (open: boolean) => void) => ReactNode;
  id?: string;
  label?: string;
  PaperProps?: PaperProps;
  iconStart?: JSX.Element;
  testId?: string;
  transformOrigin?: PopoverOrigin;
  hideLastIcon?: boolean;
  disabled?: boolean;
};

/**
 * Component
 */
function DropdownButton(props: DropdownButtonProps) {
  /**
   * Props
   */
  const {
    anchorOrigin,
    children,
    className,
    content,
    disabled,
    iconStart,
    id,
    label,
    PaperProps,
    testId,
    transformOrigin,
    hideLastIcon
  } = props;

  /**
   * State
   */
  const [open, setOpen] = useState(false);

  /**
   * Memo
   */
  const myId = useMemo(() => id || uniqueId('popover'), [id]);

  /**
   * Refs
   */
  const buttonEl = useRef(null);

  /**
   * Callbacks
   */
  const toggleOpen = () => setOpen(!open);

  /**
   * Render
   */
  return (
    <>
      <Button
        aria-controls={open ? myId : undefined}
        aria-haspopup="true"
        iconEnd={
          !hideLastIcon ? (
            <ArrowDropDownIcon data-testid={`${props.testId}-dropdown-icon`} />
          ) : undefined
        }
        iconStart={iconStart}
        onClick={toggleOpen}
        disabled={disabled}
        ref={buttonEl}
        color={open ? 'lightBlue' : 'gray'}
        className={clsx({ [buttonOpenStyle]: open }, buttonStyle, className)}
        iconEndClassName={clsx(
          { [buttonIconEndOpenStyle]: open },
          buttonIconEndStyle
        )}
        kind="text"
        data-testid={testId}
      >
        {label || children}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={buttonEl.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
          ...anchorOrigin
        }}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
          ...transformOrigin
        }}
        onClose={toggleOpen}
        PaperProps={{
          elevation: 0,
          className: paperStyle,
          ...PaperProps
        }}
      >
        {content(open, setOpen)}
      </Popover>
    </>
  );
}

export default DropdownButton;
