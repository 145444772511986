import { UseAPILazyProps, useApiLazy } from 'API/hooks/useApiLazy';
import { APICoreProps } from 'API/lib/core';
import { useAuthContext } from 'providers/AuthProvider';
import { embedding } from 'utils/embedPage';

/**
 * Types
 */
export type UseAPILazyAuthProps<Response, Request = object> = Omit<
  UseAPILazyProps<Response, Request>,
  'body'
> & {
  optionalAuth?: boolean;
};

/**
 * Hook
 */
export function useApiLazyAuth<Response, Request = object>(
  props: UseAPILazyAuthProps<Response, Request>
) {
  /**
   * Context
   */
  const { oktaAuth } = useAuthContext();

  /**
   * API
   */
  const { call, ...rest } = useApiLazy<Response, Request>(props);

  /**
   * Callback
   */
  const asyncCall = async (myProps: APICoreProps<Request>) => {
    const auth = await oktaAuth?.isAuthenticated();
    const mySkip = !(auth || embedding()) && !props.optionalAuth;
    if (mySkip) {
      return;
    }

    const token = oktaAuth?.getAccessToken();

    // Construct the header conditionally
    const headers =
      props.optionalAuth && !token
        ? myProps.header
        : { ...myProps.header, Authorization: `Bearer ${token}` };

    return await call({
      ...myProps,
      header: headers
    });
  };

  /**
   * Output
   */
  return { ...rest, call: asyncCall };
}
