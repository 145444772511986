import CloseIcon from '@mui/icons-material/Close';
import { Chip as MuiChip, ChipProps as MuiChipProps } from '@mui/material';
import clsx from 'clsx';

import { Modify } from '@reece/global-types';
import { baseStyles, themedStyles } from 'components/Chip/styles';

/**
 * Types
 */
type NewProps = {
  color?: ChipColor;
  kind?: ChipKind;
};
export type ChipColor =
  | 'primary'
  | 'secondary'
  | 'gray'
  | 'green'
  | 'yellow'
  | 'red';
export type ChipKind = 'fill' | 'strokeFill' | 'stroke';
export type ChipProps = Modify<MuiChipProps, NewProps>;

/**
 * Component
 */
function Chip(props: ChipProps) {
  /**
   * props
   */
  const {
    color = 'secondary',
    className,
    kind = 'fill',
    deleteIcon = <CloseIcon />,
    sx,
    ...other
  } = props;

  /**
   * Styles
   */
  const styling = themedStyles[kind][color];

  /**
   * Render
   */
  return (
    <MuiChip
      className={clsx(className, baseStyles, styling)}
      deleteIcon={deleteIcon}
      {...other}
    />
  );
}

export default Chip;
