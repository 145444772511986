import { ApolloError } from '@apollo/client';

export const parseError = (e: ApolloError): string[] => {
  try {
    const msgs = JSON.parse(e.message);
    return Array.isArray(msgs)
      ? msgs.filter((val) => typeof val === 'string')
      : [];
  } catch (e) {
    return [];
  }
};

export const catchError = (e: unknown) => console.error(e);
