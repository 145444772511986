import { ReactNode } from 'react';

/**
 * Type
 */
type ConditionalWrapperProps = {
  children: ReactNode;
  condition: boolean;
  wrapper: (children: ReactNode) => JSX.Element;
};

/**
 * Component
 */
function ConditionalWrapper(props: ConditionalWrapperProps) {
  if (props.condition) {
    return props.wrapper(props.children);
  }
  return <>{props.children}</>;
}

export default ConditionalWrapper;
