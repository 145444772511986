import { Fragment, useCallback, useState } from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Button, Image, Link } from 'components';
import blackButton from 'images/posh/faucets/BlackButton.png';
import chromeButton from 'images/posh/faucets/ChromeButton.png';
import goldButton from 'images/posh/faucets/GoldButton.png';
import nickelButton from 'images/posh/faucets/NickelButton.png';
import { productList } from 'pages/BrandPages/Posh/lib/productList';
import { buttonStyle } from 'pages/BrandPages/Posh/lib/styles';

/**
 * Component
 */
function PoshProductList() {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();
  const [imagesIndex, setImagesIndex] = useState([0, 0, 0, 0, 0, 0]);

  /**
   * Callbacks
   */
  const handleFinishChange = useCallback(
    (productIndex: number, finishIndex: number) => {
      const newIndex = imagesIndex.map((item, i) =>
        i === productIndex ? finishIndex : item
      );
      setImagesIndex(newIndex);
    },
    [imagesIndex]
  );

  /**
   * Render
   */
  return (
    <section
      className="flex flex-col bg-etc-warm-gray"
      data-testid="posh-section-products"
    >
      {productList.map((item, index) => (
        <Fragment key={index}>
          <div
            className={clsx(
              'px-24 py-16 flex justify-center md:px-0 md:py-0 md:flex-col',
              {
                'flex-row': Boolean(index % 2),
                'flex-row-reverse': !(index % 2)
              }
            )}
          >
            {/* Image & finish */}
            <div className="w-1/2 px-12 py-0 flex flex-col justify-center md:w-full md:p-7">
              {/* Image */}
              <div className="w-[480px] h-[560px] flex justify-center items-center md:w-full md:h-[360px]">
                <Image
                  src={item.imageFinishes[imagesIndex[index]]}
                  alt={item.title}
                  data-testid={`posh-product-image-${index}`}
                />
              </div>
              {/* Finish Title */}
              <p className="mb-6 text-base font-gilroy md:mx-4">
                {t('poshMarketing.availableFinishes')}
              </p>
              {/* Finishes */}
              <div className="grid grid-cols-4 gap-2">
                {/* Finish - Chrome */}
                <div
                  className="px-6 flex flex-col items-center cursor-pointer"
                  data-testid={`chrome-finish-button-${index}`}
                  onClick={() => handleFinishChange(index, 0)}
                >
                  <Image
                    alt={t('poshMarketing.chromeButton')}
                    src={chromeButton}
                    className={clsx(
                      'max-w-[48px] max-h-[48px] min-w-[24px] min-h-[24px] md:w-[70%] hover:scale-[1.1] border-2 transition-all',
                      {
                        'border-transparent': imagesIndex[index] !== 0,
                        'rounded-full border-etc-posh-red':
                          imagesIndex[index] === 0
                      }
                    )}
                  />
                  <span className="text-base text-center font-gilroy">
                    {t('poshMarketing.chromeButton')}
                  </span>
                </div>
                {/* Finish - Nickel */}
                <div
                  className="px-6 flex flex-col items-center cursor-pointer"
                  data-testid={`nickel-finish-button-${index}`}
                  onClick={() => handleFinishChange(index, 1)}
                >
                  <Image
                    alt={t('poshMarketing.nickelButton')}
                    src={nickelButton}
                    className={clsx(
                      'max-w-[48px] max-h-[48px] min-w-[24px] min-h-[24px] md:w-[70%] hover:scale-[1.1] border-2 transition-all',
                      {
                        'border-transparent': imagesIndex[index] !== 1,
                        'rounded-full border-etc-posh-red':
                          imagesIndex[index] === 1
                      }
                    )}
                  />
                  <span className="text-base text-center font-gilroy">
                    {t('poshMarketing.nickelButton')}
                  </span>
                </div>
                {/* Finish - Gold */}
                <div
                  className="px-6 flex flex-col items-center cursor-pointer"
                  data-testid={`gold-finish-button-${index}`}
                  onClick={() => handleFinishChange(index, 2)}
                >
                  <Image
                    alt={t('poshMarketing.goldButton')}
                    src={goldButton}
                    className={clsx(
                      'max-w-[48px] max-h-[48px] min-w-[24px] min-h-[24px] md:w-[70%] hover:scale-[1.1] border-2 transition-all',
                      {
                        'border-transparent': imagesIndex[index] !== 2,
                        'rounded-full border-etc-posh-red':
                          imagesIndex[index] === 2
                      }
                    )}
                  />
                  <span className="text-base text-center font-gilroy">
                    {t('poshMarketing.goldButton')}
                  </span>
                </div>
                {/* Finish - Black */}
                <div
                  className="px-6 flex flex-col items-center cursor-pointer"
                  data-testid={`black-finish-button-${index}`}
                  onClick={() => handleFinishChange(index, 3)}
                >
                  <Image
                    alt={t('poshMarketing.blackButton')}
                    src={blackButton}
                    className={clsx(
                      'max-w-[48px] max-h-[48px] min-w-[24px] min-h-[24px] md:w-[70%] hover:scale-[1.1] border-2 transition-all',
                      {
                        'border-transparent': imagesIndex[index] !== 3,
                        'rounded-full border-etc-posh-red':
                          imagesIndex[index] === 3
                      }
                    )}
                  />
                  <span className="text-base text-center font-gilroy">
                    {t('poshMarketing.blackButton')}
                  </span>
                </div>
              </div>
            </div>
            {/* Description */}
            <div className="w-1/2 px-12 py-24 flex flex-col items-center justify-center md:w-full md:px-9 md:py-7">
              {/* Title */}
              <h3 className="w-full text-primary-3-100 text-[40px] leading-[1.2] font-bold md:text-[30px]">
                {item.title}
              </h3>
              {/* Features title */}
              <span className="w-full mt-8 text-primary-3-100 text-base md:mt-4">
                {t('poshMarketing.features')}:
              </span>
              {/* Features list */}
              <ul style={{ marginBlockStart: 0, paddingInlineStart: 20 }}>
                {item.features.map((feature, featureIndex) => (
                  <li
                    key={`${index}${featureIndex}`}
                    className="text-primary-3-100 text-base font-medium leading-[1.5]"
                  >
                    {feature}
                  </li>
                ))}
              </ul>
              {/* Shop Now button */}
              <div className="mt-8 flex md:justify-center md:mt-4">
                <Link to={item.url}>
                  <Button kind="outline" className={buttonStyle}>
                    {t('aboutUs.shopNow')}
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          {/* Divider */}
          {index < productList.length - 1 && (
            <hr className="block w-auto h-[1px] mx-32 my-4 border-none bg-secondary-2-100 md:mx-20 md:my-[10px]" />
          )}
        </Fragment>
      ))}
    </section>
  );
}

export default PoshProductList;
