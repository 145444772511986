import { kebabCase } from 'lodash-es';

/**
 * Types
 */
type BuilderButtonProps = {
  children: any;
  href: string;
  size: string;
  variant: string;
  iconRight: string;
};

/**
 * Component
 */
function BuilderButton(props: BuilderButtonProps) {
  /**
   * Props
   */
  const { children, href, variant } = props;

  /**
   * Render
   */
  return (
    <a
      href={href}
      style={{ backgroundColor: variant }}
      className="inline-flex items-center justify-center rounded-sm border font-medium transition-colors disabled:cursor-not-allowed px-8 py-4 text-base bg-white text-darkBlue-100 border-white hover:text-buttonHover disabled:border-mediumGray-100"
      data-testid={`builderio-button-${kebabCase(href)}`}
    >
      <span>{children.Default} &gt;</span>
    </a>
  );
}

export default BuilderButton;
