import AccountPageProvider from 'pages/Account/AccountPageProvider';
import AccountPage from 'pages/Account/AccountPage';

/**
 * Component
 */
function Account() {
  // render
  return (
    <AccountPageProvider>
      <AccountPage />
    </AccountPageProvider>
  );
}

export default Account;
