import React from 'react';

import {
  Box,
  List,
  ListItem,
  Typography
} from '@dialexa/reece-component-library';
import { useTranslation } from 'react-i18next';

/**
 * Return list item list
 */
const listItems = [
  'Stock items – Returns permitted as long as item is in original packaging and is not damaged or obsolete.',
  'Custom Items – Returns not permitted.',
  'Non-stock items (non-custom) – Permitted with restocking fee applied as long as manufacturer is willing to accept.'
];

function TermsOfSale() {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();

  return (
    <>
      <Box>
        <Typography variant="h4">{t('legal.termsOfSale')}</Typography>
      </Box>
      <Box mt={4} mx={1}>
        <Typography variant="body1">
          <b>Terms of Sale</b> All quotations and sales by MORSCO, Inc. and/or
          any present or future parent, subsidiary, affiliate or business unit
          of MORSCO, Inc. (collectively, “Seller”) to the Applicant,
          Guarantor(s) and/or any parent, subsidiary, affiliate or business unit
          of the Applicant (collectively, “Buyer”) are expressly conditioned
          upon Buyer's acceptance of these Terms and Conditions of Sale (the
          “Terms”). These Terms, along with any terms and conditions identified
          in Seller's quotation, invoice or delivery ticket for specific goods,
          which are incorporated by reference herein, reflect the complete
          agreement of Buyer and Seller with regard to the sale of goods by
          Seller to Buyer, and all prior proposals, agreements and
          understandings, oral or written, are superseded by these Terms.
          Modification or alteration of these Terms must be in writing and
          signed by an authorized representative of Buyer and Seller. Buyer's
          acceptance or receipt of the goods shall be deemed acceptance by Buyer
          of these Terms. Seller objects to additional or different terms and
          conditions in any Buyer purchase order, documentation or
          correspondence and those additional or different terms are of no force
          or effect.
        </Typography>
      </Box>
      <Box mt={4} mx={1}>
        <Typography variant="body1">
          <b>Delivery of Goods</b> The goods shall be delivered to Buyer in the
          manner specified in the purchase order. Seller shall use reasonable
          efforts to deliver the goods to Buyer at the location date and time
          specified mutually agreed upon by Buyer and Seller and specified in
          writing. Partial deliveries of goods are permitted at Seller's
          discretion. Seller does not guarantee planned delivery dates, but will
          use reasonable efforts to meet them. Delivery of materials to the
          construction site constitutes delivery to Buyer regardless of whether
          Buyer or its agent is on site at the time of delivery. Seller will not
          be liable for failure or delay in delivery for any reason, and Buyer
          hereby waives any claims for damages arising therefrom.
        </Typography>
      </Box>
      <Box mt={4} mx={1}>
        <Typography variant="body1">
          <b>Title and Risk of Loss</b> Seller's responsibility for the goods
          sold under these Terms ceases upon its issuance of Bill of Lading or
          upon delivery of goods to Buyer or a common carrier or upon
          installation, whichever occurs first. Seller shall not be liable to
          Buyer for goods that are damaged or lost while in the possession of a
          common carrier, and Buyer is solely responsible for recovering any and
          all damage directly from the common carrier. Title to the goods passes
          to Buyer upon Seller's receipt of full payment from Buyer for all
          amounts owed for the goods. Buyer hereby grants Seller a security
          interest in the goods sold by Seller to Buyer under these Terms and
          any proceeds thereof as security for Buyer's obligation to Seller to
          pay the purchase price. This security interest shall commence upon the
          first delivery of goods to Buyer or upon installation, whichever
          occurs first, and shall terminate upon Buyer's full payment of all
          amounts due Seller. Buyer authorizes Seller to file financing
          statements or other documents in Seller's sole discretion to perfect
          this security interest along with other notices, and will assist
          Seller in taking any other action that Seller deems necessary in its
          sole discretion to perfect and protect Seller's security interest.
        </Typography>
      </Box>
      <Box mt={4} mx={1}>
        <Typography variant="body1">
          <b>Prices</b> Quoted prices are subject to change without notice, and
          all quotations expire and become invalid if Buyer does not accept them
          within 30 days from the date of issue. Price extensions when granted
          by Seller are for Buyer's convenience only, and they, as well as any
          mathematical, stenographic or clerical errors, are not binding on
          Seller. Any claims by Buyer related to billing errors or adjustments
          shall be made in writing to Seller within ten (10) days from the
          invoice date or they are waived by Buyer. Any changes by Buyer in
          quantities, destination, schedule or installation may result in a
          price adjustment by Seller. Prices shown do not include any sales,
          excise, or other governmental tax or charge payable by Seller to
          Federal, State, or local authority. Any taxes now or later imposed
          upon sales will be added to the purchase price. Buyer will reimburse
          Seller for any such tax or provide Seller with an acceptable tax
          exemption certificate. Prices quoted with respect to a particular
          purchase of goods or installation of the goods, and any applicable
          transportation charges, shall apply only to that particular order of
          goods and/or installation and not subsequent orders and purchases.
        </Typography>
      </Box>
      <Box mt={4} mx={1}>
        <Typography variant="body1">
          <b>Payment</b> Buyer shall pay Seller in full for all goods within
          thirty (30) days of the date Buyer takes receipt of the goods unless
          stated otherwise on the invoice. Payment shall be in the form of cash,
          check or wire transfer. Retainage shall not apply, and Buyer shall not
          hold back any retainage from Seller, even if retainage is part of any
          contract between Buyer and any other party. Payment is not contingent
          on Buyer's ability to collect or obtain funds from any other party.
          Buyer consents to Seller's presentment of and request for payment of
          any check or other payment order issued to Seller by Buyer by any
          commercially reasonable electronic means in accordance with applicable
          provision of the Uniform Commercial Code, and Buyer further authorizes
          any bank or other financial institution on which any such order is
          drawn or through which such order is payable to make payment pursuant
          to such order directly to Seller or for credit to Seller's account by
          electronic funds transfer. If Buyer fails to make any payment to
          Seller when due, Buyer's entire account(s) with Seller shall become
          immediately due and payable and Seller may suspend further performance
          under any order with Buyer. Seller may repossess and remove any
          materials sold to Buyer where payment is outstanding, without notice
          or demand. Notwithstanding anything stated therein to the contrary,
          any lien waiver or release executed by Seller related to the goods
          sold under these Terms shall only be effective to the extent payment
          is received, paid by the drawee bank, and not avoidable as a
          bankruptcy preference. Seller may apply Buyer's payment against any
          open charges within Seller's sole discretion. Seller may exercise
          setoff or recoupment to apply to or satisfy Buyer's outstanding debt.
          Buyer shall have no right of setoff hereunder, the same being
          expressly waived hereby. Buyer agrees to pay Seller on past due
          accounts a monthly interest charge equal to the maximum interest
          charge permitted to be charged by the law governing the account
          between Buyer and Seller. Buyer and Seller further agree that, where
          required by law to specify such rate, a rate of one and one-half
          percent (1.5%) per month shall apply. The interest shall continue to
          accrue after Seller receives a judgment against Buyer at the highest
          rate allowable by law then in effect. At no time will interest be
          charged at a rate higher than the maximum rate allowable by law in the
          State where suit is brought. Buyer agrees to pay Seller all costs and
          expenses of collection, suit, arbitration or other legal action,
          including all actual attorneys and paralegal fees, incurred pre-suit,
          through trial, on appeal, or in any administrative proceedings,
          mediations or arbitrations brought as a result of the commercial
          relationship between them. In any jurisdiction where a stated rate is
          required, reasonable attorney's fees shall be 15% of the outstanding
          balance.
        </Typography>
      </Box>
      <Box mt={4} mx={1}>
        <Typography variant="body1">
          <b>Trust</b> Buyer agrees that all funds owed to or received by Buyer
          from any source, resulting from the labor or material supplied by
          Seller shall be held in trust for the benefit of Seller. Buyer agrees
          to promptly pay to Seller all such funds. Upon request, Buyer shall
          irrevocably assign to Seller its account receivable from anyone to the
          extent that such is resulting from the labor or materials supplied by
          Seller.
        </Typography>
      </Box>
      <Box mt={4} mx={1}>
        <Typography variant="body1">
          <b>Credit Terms</b> Issuance of any credit by Seller to Buyer shall be
          subject to the approval of Seller's credit department in its sole
          discretion. If Seller determines in its sole discretion that Buyer's
          financial responsibility is unsatisfactory, or becomes impaired, or if
          Buyer fails to make any payment for any goods purchased from seller
          when due, then Seller may postpone or decline to make any shipments
          except upon receipt of satisfactory security or payment of cash in
          advance of shipment, or Seller may cancel any or all contracts with
          Buyer or any portion thereof. If Buyer fails to comply with these
          Terms, or Buyer's credit becomes unsatisfactory in Seller's sole
          discretion, Seller reserves the right to terminate or restrict
          Seller's credit on any order upon notice to Buyer. Buyer certifies
          that it is solvent and that it will immediately advise Seller if it
          becomes insolvent. Buyer agrees to send Seller written notice of any
          changes in the form of ownership of Buyer's business within five days
          of such changes.
        </Typography>
      </Box>
      <Box mt={4} mx={1}>
        <Typography variant="body1">
          <b>Unavoidable Forces</b> Seller shall not be liable for delay, damage
          or default in delivery resulting from any cause beyond Seller's
          reasonable control ("Unavoidable Forces"), including but not limited
          to, governmental action, terrorism, strikes, or other labor troubles,
          fire, damage of destruction of goods, manufacturer's shortages, damage
          of goods by shipping agents or carriers, inability to obtain
          materials, fuels, or supplies, weather events, floods or other
          so-called acts of God, or any other event interfering with the
          production, supply, transportation, or consumption of the goods being
          sold, or with the supply or consumption of any raw materials or labor
          used in connection with the goods. Upon occurrence of an Unavoidable
          Force, the time for Seller's performance shall be extended reasonably,
          the purchase price shall be adjusted for any increased costs to Seller
          resulting therefrom, and Buyer shall not be entitled to any other
          remedy.
        </Typography>
      </Box>
      <Box mt={4} mx={1}>
        <Typography variant="body1">
          <b>Seller's Interpretation of Buyer's Plans</b> Seller assumes no
          responsibility whatsoever for Seller's interpretation of plans or
          specifications provided by Buyer. Any interpretation of plans or
          specifications provided by Buyer to Seller for the purpose of
          purchases or bids are for the Buyer's convenience only, and Seller
          shall have no liability for quantities or materials arising from those
          interpretations. Buyer shall rely solely on final approval of the
          plans or specifications by architects, engineers, or other third
          parties, and not on Seller's interpretation.
        </Typography>
      </Box>
      <Box mt={4} mx={1}>
        <Typography variant="body1">
          <b>Disclaimer of Warranties</b> For any goods that are not
          manufactured by Seller, Buyer shall be entitled to any manufacturer's
          warranty that the manufacturer provides to Seller for said goods, but
          only to the extent and in the same manner as the manufacturer's
          warranty extends to Seller.{' '}
          <b>
            SELLER DISCLAIMS ANY AND ALL OTHER WARRANTIES WITH REGARD TO THE
            GOODS OR WORKMANSHIP, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
            TO ANY WARRANTY OF MERCHANTABILITY, FITNESS FOR ANY PARTICULAR
            PURPOSE, WORKMANSHIP, DESIGN, HABITABILITY OR NON-INFRINGEMENT OF
            ANY THIRD PARTY'S PROPRIETARY RIGHTS OR ANY WARANTIES ARISING BY
            OPERATION OF LAW, STATUTE OR OTHERWISE, OR IMPLIED BY INDUSTRY OR
            TRADE CUSTOM, COURSE OF DEALINGS BETWEEN THE PARTIES OR OTHERWISE.
            BUYER'S SOLE AND EXCLUSIVE WARRANTY FOR ANY GOODS OR WORKMANSHIP
            SOLD UNDER THESE TERMS IS ANY WARRANTY PROVIDED BY THE MANUFACTURER
            OF THE GOODS. SELLER'S LIABILITY AND BUYER'S SOLE AND EXCLUSIVE
            REMEDY FOR ANY CAUSE OF ACTION, INCLUDING, WITHOUT LIMITATION,
            CLAIMS IN WARRANTY, CONTRACT, NEGLIGENCE, INDEMNITY, STRICT
            LIABILITY OR OTHERWISE, ARISING OUT OF OR CONNECTED WITH THESE TERMS
            IS EXPRESSLY LIMITED TO REPAIR OR REPLACEMENT OF NON-CONFORMING
            MATERIALS OR, AT SELLER'S SOLE OPTION, PAYMENT NOT TO EXCEED THE
            PURCHASE PRICE OF THE MATERIALS OR WORKMANSHIP FOR WHICH DAMAGES ARE
            CLAIMED. SELLER SHALL NOT BE LIABLE TO BUYER OR ANY OTHER PERSON OR
            ENTITY FOR ANY INDIRECT, LIQUIDATED, PUNITIVE, SPECIAL, INCIDENTAL
            OR CONSEQUENTIAL DAMAGES, WHETHER ARISING OUT OF BREACH OF WARRANTY,
            BREACH OF CONTRACT, NEGLIGENCE, INDEMNITY, STRICT LIABILITY OR
            OTHERWISE. SELLER'S LIABILITY, IF ANY, SHALL BE LIMITED TO THE NET
            SALES PRICE ACTUALLY RECEIVED BY SELLER FOR THE GOODS OF
            WORKMANSHIP. ALL CLAIMS BY BUYER AGAINST SELLER MUST BE BROUGHT
            WITHIN ONE YEAR OF DELIVERY OF THE GOODS TO BUYER OR INSTALLATION OF
            THE GOODS, WHICHEVER OCCURS FIRST, OR THEY SHALL BE DEEMED WAIVED.
          </b>
        </Typography>
      </Box>
      <Box mt={4} mx={1}>
        <Typography variant="body1">
          <b>Orders</b> Buyer specifically authorizes any of its employees and
          agents to purchase materials under these Terms, pick up materials, and
          sign for, deliver and receive ordered materials and invoices and
          delivery tickets for said materials and agrees to be bound by these
          Terms.
        </Typography>
      </Box>
      <Box mt={4} mx={1}>
        <Typography variant="body1">
          <p>
            <b>Returns</b> Seller shall give no credit for goods returned by
            Buyer without Seller's prior written authorization. All returns are
            subject to a restocking charge. Any returns for credit must be
            clean, unused/uninstalled and undamaged with original packaging and
            all original parts. In conjunction with the previous statement,
            returns windows are as follows:
          </p>
          <p>
            <List sx={{ listStyleType: 'disc', pl: 4 }}>
              {listItems.map((item) => {
                return (
                  <ListItem
                    sx={{
                      display: 'list-item',
                      pl: 2,
                      '&:hover': { backgroundColor: 'transparent' }
                    }}
                  >
                    {item}
                  </ListItem>
                );
              })}
            </List>
          </p>
          <p>
            No claims for shortage of goods or damage to goods shall be allowed
            unless Buyer, within five (5) days after receipt of shipment, gives
            Seller written notice of the claim for shortage or damage with a
            full description of the alleged shortage or damage.{' '}
          </p>
        </Typography>
      </Box>
      <Box mt={4} mx={1}>
        <Typography variant="body1">
          <b>Cancellations</b> Buyer may only cancel orders within 24 hours of
          placement. If the period has passed, goods must be evaluated using the
          returns policy.
        </Typography>
      </Box>
      <Box mt={4} mx={1}>
        <Typography variant="body1">
          <b>Concealed Damage</b> Builder or Homeowner has five (5) business
          days to notify Seller of the damage. Photos from customer are required
          for sales team members to compare with photos taken at delivery or
          installation. Determination of whether service or replacement is
          required will be dependent on the location of the damage. If
          replacement is deemed necessary, RGA from the vendor must have prior
          approval before approving the return with the customer.
        </Typography>
      </Box>
      <Box mt={4} mx={1}>
        <Typography variant="body1">
          <b>Casing or structural damage will have to be replaced.</b> This
          should be caught immediately during installation or five (5) days
          after product has been delivered. Product to be returned to Seller and
          vendor RGA is required to send item(s) back to the manufacturer for
          credit. Customer has five (5) days to notify of structural damage.{' '}
        </Typography>
      </Box>
      <Box mt={4} mx={1}>
        <Typography variant="body1">
          <b>Tract Builders</b> Builders have ten (10) business days to notify
          of cosmetic damage, non-functioning (DOA) goods or structural damage
          after installation. Scheduling service must be done first. Sales team
          members will review installation or delivery pictures as well as the
          pictures provided from the builder to assess proper action. If damage
          was done after installation (trade damage), a PO is required for
          replacement from builder.
        </Typography>
      </Box>
      <Box mt={4} mx={1}>
        <Typography variant="body1">
          <b>Indemnification</b> Buyer agrees to indemnify, defend and hold
          harmless Seller and its officers, directors, employees and agents from
          any claim, demand, loss, costs (including attorneys' fees) or damage
          incurred or sustained by Seller arising from or related to Buyer's use
          or resale of the goods sold by Seller to Buyer, any negligent,
          intentional or tortious act or omission of Buyer or Buyer's failure to
          comply with these Terms. This indemnification shall not apply to
          losses, damages or liabilities expressly assumed by Seller under these
          Terms or to those damages for property damage or personal injury
          arising from the Seller's sole negligence.
        </Typography>
      </Box>
      <Box mt={4} mx={1}>
        <Typography variant="body1">
          <b>Governing Law and Venue</b> These Terms shall be construed and
          governed by the laws of the State in which the principal place of
          business of the MORSCO subsidiary that sold the goods in dispute is
          located, except that the law applicable to any mechanic's or
          materialman's lien asserted by Seller shall be construed and governed
          by the law of the State where the goods or services were delivered,
          consumed, or used. Any action or claim arising out of or relating to
          the manufacture, purchase or sale of the goods shall be brought only
          in a state court located in the State in which the principal place of
          business of the MORSCO subsidiary that sold the goods in dispute is
          located, or at Seller's election, in the State to which the goods were
          delivered, consumed, or used, and Buyer hereby waives any right to
          object to such venue or to assert the inconvenience of such venue.
          Buyer consents to jurisdiction in the state courts sitting in the
          State in which the principal place of business of the MORSCO
          subsidiary that sold the goods in dispute is located, or at Seller's
          election, the State to which the goods were delivered, consumed, or
          used.
        </Typography>
      </Box>
      <Box mt={4} mx={1}>
        <Typography variant="body1">
          <b>Miscellaneous</b> Buyer agrees that Seller retains all rights to
          claim a mechanic's and/or materialman's lien as allowed by the law of
          the State where the goods were delivered, consumed, or used. Buyer may
          not assign or transfer or delegate its rights or obligation (by law or
          otherwise) without Seller's prior written consent. A party's waiver of
          right under one provision of these Terms shall not constitute a waiver
          of any other provision or a subsequent waiver of the same provision.
        </Typography>
      </Box>
      <Box mt={4} mx={1}>
        <Typography variant="body1">
          <b>ABOVE PAYMENT TERMS DO NOT APPLY TO CASH SALES</b>
        </Typography>
      </Box>
      <Box mt={2} mx={1}>
        <Typography variant="body1">Last updated January 18, 2024.</Typography>
      </Box>
    </>
  );
}
export default TermsOfSale;
