import { ButtonColor } from 'components/Button';

export const baseStyle = [
  'flex items-center gap-2',
  'text-lg font-medium',
  'transition-all'
];
export const checkboxEffect =
  '[&_.glow]:shadow-none [&:hover_.glow]:shadow-input [&:active_.border-box]:opacity-20 [&:active_.border-box]:-inset-0.5';
export const colors: { [key in ButtonColor]: string } = {
  primary: 'text-primary-1-100',
  secondary: 'text-primary-2-100',
  gray: 'text-primary-3-100',
  lightBlue: 'text-primary-2-100',
  yellow: 'text-secondary-1-100',
  green: 'text-success-100',
  red: 'text-support-1-100'
};
