import { Children, ReactElement, cloneElement, useMemo } from 'react';

import {
  Accordion as MuiAccordion,
  AccordionProps as MuiAccordionProps
} from '@mui/material';
import clsx from 'clsx';

import { Modify } from '@reece/global-types';
import {
  defaultStyle,
  filterStyle,
  productStyle
} from 'components/Accordion/styles';

/**
 * Types
 */
type NewProps = {
  children: ReactElement[];
  type?: 'product' | 'filter' | 'default';
};
export type AccordionProps = Modify<MuiAccordionProps, NewProps>;

/**
 * Component
 */
function Accordion(props: AccordionProps) {
  /**
   * props
   */
  const { children, type = 'default', className, ...rest } = props;

  /**
   * Style
   */
  const style = useMemo(() => {
    switch (type) {
      case 'product':
        return productStyle;
      case 'filter':
        return filterStyle;
      default:
        return defaultStyle;
    }
  }, [type]);

  /**
   * Render
   */
  return (
    <MuiAccordion {...rest} className={clsx(className, style)}>
      {Children.map(children, (child) => cloneElement(child, { type }))}
    </MuiAccordion>
  );
}

export default Accordion;
