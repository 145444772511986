import { useTranslation } from 'react-i18next';

import QtyInput from 'common/QtyInput';
import { AdvancedToolTip, Button, FeedbackButton, Link } from 'components';
import useScreenSize from 'hooks/useScreenSize';
import { SignInIcon, WarningIcon } from 'icons';
import { useProductPageContext } from 'pages/Product/ProductPageProvider';
import { useAuthContext } from 'providers/AuthProvider';
import { useCartContext } from 'providers/CartProvider';

/**
 * Component
 */
function ProductCartButton() {
  /**
   * Custom Hooks
   */
  const { isSmallScreen } = useScreenSize();
  const { t } = useTranslation();

  /**
   * Context
   */
  const { authState } = useAuthContext();
  const { addItemToCart, cart, disableAddToCart } = useCartContext();
  const { product, quantity, setQuantity } = useProductPageContext();

  /**
   * Callbacks
   */
  // 🟤 Cb - add to cart
  const handleAddToCart = () =>
    // these will never be falsey when they're called
    addItemToCart([
      {
        productId: product!.productMSC,
        minIncrementQty: product!.minIncrementQty || 1,
        qty: quantity
      }
    ]);

  /**
   * Render
   */
  // 🟩 logged out
  if (!authState?.isAuthenticated) {
    return (
      <div className="pb-10" data-testid="pdp-cartbutton-login">
        <Link to="/login">
          <Button
            className="!px-1.5 !py-2.5"
            size="lg"
            fullWidth
            iconStart={<SignInIcon />}
            data-testid="log-in-for-price-button"
          >
            {t('product.signInForPrice')}
          </Button>
        </Link>
      </div>
    );
  }
  // 🟩 logged in
  return (
    <div
      className="flex gap-4 w-[528px] md:w-auto"
      data-testid="pdp-cartbutton"
    >
      <div className="w-64 md:w-[175px]">
        <QtyInput
          fullWidth
          disabled={!cart || !product?.sellPrice}
          value={quantity <= 1 ? product?.minIncrementQty || 1 : quantity}
          onUpdate={setQuantity}
          increment={product?.minIncrementQty || 1}
          size={!isSmallScreen ? 'lg' : 'md'}
          max={999999}
          noDebounce
          testId="quantity-input"
        />
      </div>
      <div className="flex-1 md:mb-[120px]">
        <AdvancedToolTip
          title={disableAddToCart ? t('common.warning') : ''}
          text={t('cart.maxLimitToolTip')}
          icon={<WarningIcon />}
          placement="bottom"
          activated={disableAddToCart}
        >
          <FeedbackButton
            disabled={disableAddToCart || !product?.sellPrice || !cart}
            testId={`${product?.productId}-add-to-cart-button`}
            fullWidth
            size="lg"
            onClick={handleAddToCart}
            value={t('common.addToCart')}
            valueDone={t('common.addedToCart')}
            className="!py-[18px] md:!py-4"
          />
        </AdvancedToolTip>
      </div>
    </div>
  );
}
export default ProductCartButton;
