import { Container, Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Button, IconButton } from 'components';
import { DeleteIcon } from 'icons';

/**
 * Types
 */
export type MinimumQuantityDialogProps = {
  minQty: number;
  adjustedQty: number;
  open: boolean;
  productName: string;
  onClose: () => void;
  onConfirm: () => void;
};

/**
 * Component
 */
function MinimumQuantityDialog(props: MinimumQuantityDialogProps) {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();

  /**
   * Render
   */
  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="sm" fullWidth>
      <div className="flex-auto px-8 pt-7 pb-9 overflow-y-auto bg-primary-1-100">
        <h5 className="text-common-white text-xl font-medium">
          {t('minimumQuantity.minimumSellQuantity')}
        </h5>
        <IconButton
          onClick={props.onClose}
          size="large"
          data-testid="qty-dialog-close-button"
          className="!absolute right-2 top-2"
        >
          <DeleteIcon className="text-common-white align-middle" />
        </IconButton>
      </div>
      <div className="px-8">
        <Container>
          <div className="pl-10 pr-[120px] pt-12 pb-6 flex flex-col w-full max-w-full md:px-6 md:pt-8 md:pb-6">
            <p className="text-primary-3-100 text-base">
              {t('minimumQuantity.product')}
              <span className="text-primary-1-100 font-extrabold">
                {props.productName}
              </span>
              {t('minimumQuantity.minQty', { minQty: props.minQty })}
            </p>
            <h5 className="block pt-4 md:pt-8 text-primary-1-100 text-xl font-medium">
              {t('minimumQuantity.orderQuantity')}
              {': '}
              {props.adjustedQty}
            </h5>
          </div>
          <div className="flex items-end justify-end pb-12 pr-4 md:items-center md:justify-center">
            <Button
              onClick={props.onConfirm}
              data-testid="qty-dialog-confirm-button"
            >
              {t('common.ok')}
            </Button>
          </div>
        </Container>
      </div>
    </Dialog>
  );
}

export default MinimumQuantityDialog;
