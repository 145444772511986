import { alpha } from '@mui/material/styles';

import palette from 'providers/libs/Theme/palette';

const muiDialog = {
  container: {
    backgroundBlendMode: 'multiply',
    backdropFilter: 'blur(2px)'
  },
  paper: {
    boxShadow: `0 3px 10px 0 ${alpha(palette.common.black, 0.3)}`
  }
};

export default muiDialog;
