import theme from 'providers/libs/Theme/baseTheme';

const muiCardContent = {
  root: {
    padding: theme.spacing(2, 3),
    '&:last-child': {
      paddingBottom: theme.spacing(2)
    }
  }
};

export default muiCardContent;
