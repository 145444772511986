import { useTranslation } from 'react-i18next';

import { Image } from 'components';
import mission1 from 'images/bigDog/mission1.jpg';
import mission2 from 'images/bigDog/mission2.jpg';
import mission3 from 'images/bigDog/mission3.jpg';

/**
 * Component
 */
function BigDogMission() {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();

  /**
   * Render
   */
  return (
    <section
      className="mt-[72px] flex justify-center gap-x-10 gap-y-[12.8px] text-common-white md:mt-16 md:flex-col md:items-center md:gap-x-0"
      data-testid="bigdog-section-mission"
    >
      {/* Smnarter designs */}
      <div className="w-[30%] h-[149px] flex md:w-full md:justify-center">
        <div className="bg-etc-big-dog-orange w-[152px] h-[149px]">
          <Image src={mission1} />
        </div>
        <div className="w-[273px] h-[149px] text-common-black bg-common-background md:w-[197px]">
          <h4 className="h-6 ml-4 mt-5 text-xl leading-[1.2] font-bold font-metropolis md:h-[22px] md:text-lg">
            {t('bigDogMarketing.mission.designTitle')}
          </h4>
          <p className="w-[213px] h-16 ml-4 mt-[9px] text-sm leading-[1.2] font-gilroy md:w-[168px] md:h-14 md:mt-[14px] md:text-xs">
            {t('bigDogMarketing.mission.designDescription')}
          </p>
        </div>
      </div>
      {/* Heavy-duty quality */}
      <div className="w-[30%] h-[149px] flex md:w-full md:justify-center">
        <div className="bg-etc-big-dog-orange w-[152px] h-[149px]">
          <Image src={mission2} />
        </div>
        <div className="w-[273px] h-[149px] text-common-black bg-common-background md:w-[197px]">
          <h4 className="h-6 ml-4 mt-5 text-xl leading-[1.2] font-bold font-metropolis md:h-[22px] md:text-lg">
            {t('bigDogMarketing.mission.qualityTitle')}
          </h4>
          <p className="w-[213px] h-16 ml-4 mt-[9px] text-sm leading-[1.2] font-gilroy md:w-[168px] md:h-14 md:mt-[14px] md:text-xs">
            {t('bigDogMarketing.mission.qualityDescription')}
          </p>
        </div>
      </div>
      {/* Always on-hand */}
      <div className="w-[30%] h-[149px] flex md:w-full md:justify-center">
        <div className="bg-etc-big-dog-orange w-[152px] h-[149px]">
          <Image src={mission3} />
        </div>
        <div className="w-[273px] h-[149px] text-common-black bg-common-background md:w-[197px]">
          <h4 className="h-6 ml-4 mt-5 text-xl leading-[1.2] font-bold font-metropolis md:h-[22px] md:text-lg">
            {t('bigDogMarketing.mission.availabilityTitle')}
          </h4>
          <p className="w-[213px] h-16 ml-4 mt-[9px] text-sm leading-[1.2] font-gilroy md:w-[168px] md:h-14 md:mt-[14px] md:text-xs">
            {t('bigDogMarketing.mission.availabilityDescription')}
          </p>
        </div>
      </div>
    </section>
  );
}

export default BigDogMission;
