import { startOfDay, startOfMonth } from 'date-fns';
import { DayPickerProps } from 'react-day-picker';

/**
 * Types
 */
export type FromToDate = {
  fromDate?: Date;
  toDate?: Date;
};
export type ParseFromToProps = Pick<
  DayPickerProps,
  'fromYear' | 'toYear' | 'fromDate' | 'toDate' | 'fromMonth' | 'toMonth'
>;

/**
 * parseFromToProps
 */
export function parseFromToProps(props: ParseFromToProps): FromToDate {
  // Props
  const { fromYear, toYear, fromMonth, toMonth } = props;
  let { fromDate, toDate } = props;
  // From: 1st day of the month
  if (fromMonth) {
    fromDate = startOfMonth(fromMonth);
  }
  // From: 1st day of the year
  else if (fromYear) {
    fromDate = new Date(fromYear, 0, 1);
  }
  // To: 1st day of the month
  if (toMonth) {
    toDate = startOfMonth(toMonth);
  }
  // To: last day of the year
  else if (toYear) {
    toDate = new Date(toYear, 11, 31);
  }
  return {
    fromDate: fromDate ? startOfDay(fromDate) : undefined,
    toDate: toDate ? startOfDay(toDate) : undefined
  };
}
