import { Container } from '@mui/material';
import clsx from 'clsx';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { useApiGetListsTemplate } from 'API/lists.api';
import { Card, Image } from 'components';
import useDocumentTitle from 'hooks/useDocumentTitle';
import exampleImg from 'images/list-upload-example.png';
import baseI18nComponents from 'locales/baseComponents';
import Header from 'pages/Header';
import ListUploadDropzone from 'pages/ListUpload/sub/UploadDropzone';
import ListUploadDropzoneNew from 'pages/ListUpload/sub/UploadDropzoneNew';
import { downloadFile } from 'utils/downloadFile';
import repeatMap from 'utils/repeatMap';

/**
 * Config
 */
const STEPS = 8;

/**
 * Compoonent
 */
function ListUpload() {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();
  const { push } = useHistory();
  const { id } = useParams<{ id?: string }>();
  useDocumentTitle(t('common.uploadList'));

  /**
   * API
   */
  // 🟣 GET - List template
  const { call: getTemplateCall, loading: getTemplateLoading } =
    useApiGetListsTemplate();

  /**
   * Callbacks
   */
  // 🟤 Cb - back to lists
  const backToLists = () => push(id ? `/lists?id=${id}` : '/lists');
  // 🟤 Cb - download list template
  const downloadListTemplate = async () => {
    const res = await getTemplateCall();
    if (!res?.data) {
      return;
    }
    const file = new Blob([res.data]);
    downloadFile(file, 'list_template.csv', 'text/csv');
  };

  /**
   * Render
   */
  return (
    <div data-testid="lists-upload-root">
      <Header pageTitle={t('common.myLists')} />
      <Card className="!rounded-none">
        <Container>
          <div className="px-8 py-6 my-6 flex gap-8 md:my-0 md:gap-4 md:flex-col-reverse md:p-4">
            <div className="basis-1/3 w-1/3 pt-2 pb-2 md:basis-full md:w-full md:pt-0 md:gap-4">
              {id ? (
                <ListUploadDropzone id={id} back={backToLists} />
              ) : (
                <ListUploadDropzoneNew back={backToLists} />
              )}
            </div>
            <div className="basis-2/3 pt-4 pb-2 flex flex-col text-primary-3-100">
              <h5 className="text-base font-medium">
                {t('lists.uploadPageTitle')}
              </h5>
              <ol className="ml-6 my-6 list-decimal text-base">
                {repeatMap(STEPS, (i) => (
                  <li key={`list-upload-step-${i}`}>
                    <Trans
                      i18nKey={`lists.uploadPageStep${i}`}
                      components={{
                        ...baseI18nComponents,
                        a: (
                          <span
                            onClick={downloadListTemplate}
                            className={clsx('underline', {
                              'text-primary-2-100 cursor-pointer':
                                !getTemplateLoading,
                              'text-secondary-3-100 cursor-wait':
                                getTemplateLoading
                            })}
                            data-testid="download-template-link"
                          />
                        )
                      }}
                    />
                  </li>
                ))}
              </ol>
              <p className="text-base mb-6">
                <Trans
                  i18nKey="lists.uploadPageNote"
                  components={baseI18nComponents}
                />
              </p>
              <p className="text-base mb-2 font-bold">
                {t('lists.uploadPageExample')}
              </p>
              <Image className="w-full mb-8 object-contain" src={exampleImg} />
            </div>
          </div>
        </Container>
      </Card>
    </div>
  );
}

export default ListUpload;
