import { forwardRef } from 'react';

import { Switch } from '@headlessui/react';
import clsx from 'clsx';

import { ButtonColor } from 'components/Button';
import { CheckboxUnselected, CheckboxSelected } from 'icons';
import {
  baseStyle,
  checkboxEffect,
  colors
} from 'components/CheckboxInput/styles';

/**
 * Types
 */
export type CheckboxInputProps = {
  label?: string;
  labelClassName?: string;
  defaultChecked?: boolean;
  checked?: boolean;
  color?: ButtonColor;
  onChange?: (checked: boolean) => void;
  name?: string;
  id?: string;
  disabled?: boolean;
  className?: string;
  'data-testid'?: string;
};

/**
 * Component
 */
const CheckboxInput = forwardRef<HTMLButtonElement, CheckboxInputProps>(
  (props, ref) => {
    /**
     * Props
     */
    const {
      label,
      labelClassName,
      defaultChecked,
      checked,
      color,
      onChange,
      name,
      id,
      disabled,
      className
    } = props;

    /**
     * Render
     */
    return (
      <Switch
        className={clsx(baseStyle, className, colors[color ?? 'primary'], {
          '!text-secondary-3-100': disabled,
          [checkboxEffect]: !disabled
        })}
        defaultChecked={defaultChecked}
        checked={checked}
        onChange={onChange}
        name={name}
        id={id ?? name}
        ref={ref}
        disabled={disabled}
        data-testid={props['data-testid']}
      >
        {(state) => (
          <>
            <div className="relative">
              <div className="glow absolute inset-1 transition-all !shadow-current" />
              <div className="border-box absolute inset-0 transition-all bg-current opacity-0 rounded" />
              {state.checked ? (
                <CheckboxSelected className="w-6 h-6" />
              ) : (
                <CheckboxUnselected className="w-6 h-6" />
              )}
            </div>
            {label && (
              <span className={clsx('truncate', labelClassName)}>{label}</span>
            )}
          </>
        )}
      </Switch>
    );
  }
);
export default CheckboxInput;
