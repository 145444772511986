import slugify from 'react-slugify';
import { Maybe } from 'yup/lib/types';

export function makeSlug(params?: Maybe<string>[]) {
  if (!params?.length) {
    return '/';
  }
  const res = params.map((c) => slugify(c) + '/').join('');
  return '/' + res;
}
