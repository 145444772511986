import {
  ChangeEvent,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  MenuItem,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
  useScreenSize
} from '@dialexa/reece-component-library';
import { Trans, useTranslation } from 'react-i18next';

import {
  CustomAddressInput,
  DeliveryMethodOptionEnum
} from 'Checkout/util/types';
import { preferredTimeValues } from 'Checkout/util';
import { useCheckoutContext } from 'Checkout/CheckoutProvider';
import CustomAddress from 'Checkout/CustomAddress';
import Heading from 'Checkout/Heading';
import Location from 'Checkout/Location';
import Shipments from 'Checkout/Shipments';
import { deliveryOptionsStyles } from 'Checkout/util/styles';
import { DayPicker, Loader } from 'components';
import {
  Address,
  Branch,
  Delivery,
  PreferredTimeEnum,
  WillCall
} from 'generated/graphql';
import { useDomainInfo } from 'hooks/useDomainInfo';
import { CalendarIcon, InfoOutlineIcon, RapidDeliveryIcon } from 'icons';
import baseI18nComponents from 'locales/baseComponents';
import { getTypedKeys } from 'utils/getTypedKeys';
import { useCartContext } from 'providers/CartProvider';
import { BranchContext } from 'providers/BranchProvider';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';
import { radio } from 'utils/inputTestId';
import { RapidDeliveryRequest } from 'API/types/checkout.types';
import {
  useApiUpdateDeliveryAddress,
  useApiUpdateIsRapid
} from 'API/checkout.api';
import { omit } from 'lodash-es';

/**
 * Component
 */
export default function Info() {
  /**
   * Custom Hooks
   */
  const { isSmallScreen } = useScreenSize();
  const { t } = useTranslation();
  const { isWaterworks } = useDomainInfo();
  const instructionsMax = isWaterworks ? 240 : 500;

  /**
   * Refs
   */
  const instructionsRef = useRef<HTMLDivElement>(null);

  /**
   * Context
   */
  const { cart, contract, contractBranch } = useCartContext();
  const {
    cartLoaded,
    deliveryMethodOption,
    getCheckoutLoading,
    deliveryData,
    setDeliveryData,
    setDeliveryMethodOption,
    setWillCallData,
    willCallData,
    isDelivery,
    rapidDelivery,
    rapidDeliveryCheck,
    rapidDeliveryCheckLoading
  } = useCheckoutContext();
  const { shippingBranch } = useContext(BranchContext);
  const {
    selectedAccounts: { shipToErpAccount }
  } = useSelectedAccountsContext();

  const deliveryObject =
    deliveryMethodOption === DeliveryMethodOptionEnum.Standard
      ? (deliveryData as Delivery | undefined)
      : undefined;
  const delivery = deliveryData ? (deliveryData as Delivery) : undefined;
  const defaultAddress =
    isDelivery && deliveryObject?.address
      ? (deliveryObject.address as CustomAddressInput)
      : undefined;

  const {
    isAllItemsAvailable,
    isBranchAllowed,
    isInMiles,
    isRapidAllowed,
    isWithinHours
  } = rapidDelivery;

  /**
   * State
   */
  const [showCustomAddress, setShowCustomAddress] = useState(false);
  const [address, setAddress] = useState<CustomAddressInput | undefined>(
    defaultAddress
  );
  const [preferredDate, setPreferredDate] = useState<Date | undefined>(
    deliveryObject?.preferredDate
  );
  const [preferredTime, setPreferredTime] = useState<PreferredTimeEnum | ''>(
    deliveryObject?.preferredTime ?? ''
  );
  const [instructions, setInstructions] = useState('');

  /**
   * API
   */
  // 🟣 API - call Update Delivery Type
  const { call: callUpdateDeliveryType, loading: updateDeliveryTypeLoading } =
    useApiUpdateIsRapid();
  // 🟣 API - call Update Delivery Address
  const { call: callUpdateDeliveryAddress } = useApiUpdateDeliveryAddress();

  /**
   * Memo
   */
  const allProductsNotAvailable = useMemo(
    () => Boolean(cart?.products?.some((p) => !p.productAvailable)),
    [cart?.products]
  );
  /**
   * Effect
   */
  useEffect(updateAddress, [
    address,
    delivery,
    isDelivery,
    setAddress,
    shippingBranch
  ]);
  useEffect(updatePreferredDate, [delivery?.preferredDate, setPreferredDate]);
  useEffect(updatePreferredTime, [delivery?.preferredTime, setPreferredTime]);
  useEffect(updateInstructions, [delivery, setInstructions]);
  useEffect(() => {
    instructionsRef.current?.focus();
    instructionsRef.current?.blur();
  }, [deliveryMethodOption]);

  /**
   * Callbacks
   */
  const handleAddressChange = async (address?: CustomAddressInput) => {
    if (!address) {
      const shipToErpAccountAddress = {
        country: 'USA',
        custom: false,
        companyName: shipToErpAccount?.companyName,
        street1: shipToErpAccount?.street1 ?? '',
        street2: shipToErpAccount?.street2,
        city: shipToErpAccount?.city ?? '',
        state: shipToErpAccount?.state ?? '',
        zip: shipToErpAccount?.zip ?? '',
        phoneNumber: shipToErpAccount?.phoneNumber
      };
      handleUpdateDelivery(shipToErpAccountAddress);
      return;
    }
    handleUpdateDelivery(address);
  };

  const handleUpdateDelivery = async (
    normalizedAddress: CustomAddressInput
  ) => {
    setAddress(normalizedAddress);
    const { phoneNumber, ...addressInput } = normalizedAddress;
    const deliveryAddress = omit(normalizedAddress, ['key']);

    await callUpdateDeliveryAddress(cart?.id!, deliveryAddress);

    const req: RapidDeliveryRequest = {
      deliveryAddress: {
        address1: addressInput.street1,
        address2: addressInput.street2 ?? '',
        city: addressInput.city,
        state: addressInput.state,
        zip: addressInput.zip,
        branchId: shippingBranch?.branchId ?? ''
      }
    };
    await rapidDeliveryCheck(cart?.id!, req);
    setDeliveryData({ ...deliveryData, address: deliveryAddress as Address });
    setShowCustomAddress(false);
  };

  /**
   * Constants
   */

  const isFortlineUrl = window.location.href.includes('fortiline');

  /**
   * Render
   */
  return (
    <>
      {(rapidDeliveryCheckLoading ||
        getCheckoutLoading ||
        updateDeliveryTypeLoading) && (
        <div
          className="fixed z-10 top-0 left-0 h-[100%] w-[100vw] bg-common-black/40 backdrop-blur-xl"
          data-testid="checkout-rapid-delivery-loading"
        >
          <Loader backdrop />
        </div>
      )}
      <Heading
        title={t(
          isDelivery ? 'cart.deliveryInformation' : 'cart.willCallInformation'
        )}
      />
      <Grid container mb={6}>
        <Grid item xs={12} md={3}>
          <Typography
            variant="body1"
            color="textSecondary"
            pb={isSmallScreen ? 3 : 0}
          >
            {t(
              isDelivery ? 'invoice.deliveryAddress' : 'cart.willCallLocation'
            )}
            {isDelivery && (
              <Typography variant="body1" component="span" color="error">
                {'*'}
              </Typography>
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} md={showCustomAddress ? 7 : 6}>
          {isDelivery && showCustomAddress ? (
            <CustomAddress
              onConfirm={handleAddressChange}
              onCancel={() => setShowCustomAddress(false)}
            />
          ) : (
            <>
              <Location
                location={
                  contract
                    ? isDelivery
                      ? contract?.data
                      : (contractBranch as Branch)
                    : address
                }
                includePhone={!!contract}
                data-testid="will-call-location-step-1"
              />
              {isDelivery && !contract && (
                <Box mt={1.5}>
                  <Button
                    variant="inline"
                    color="primaryLight"
                    onClick={() => setShowCustomAddress(true)}
                    data-testid="change-address-button"
                    sx={{ minWidth: 0 }}
                  >
                    {address?.custom
                      ? t('common.edit')
                      : t('cart.changeAddress')}
                  </Button>
                  {!!address?.custom && (
                    <>
                      <Typography variant="body1" component="span" mx={1}>
                        {'|'}
                      </Typography>
                      <Button
                        variant="inline"
                        color="primaryLight"
                        data-testid="restore-address"
                        onClick={() => handleAddressChange()}
                      >
                        {t('common.restoreDefault')}
                      </Button>
                    </>
                  )}
                </Box>
              )}
            </>
          )}
        </Grid>
        {!showCustomAddress && (
          <Hidden mdDown>
            <Grid item md={3} />
          </Hidden>
        )}
      </Grid>
      <Grid container mb={0}>
        <Grid item xs={12} md={3}>
          <Typography
            variant="body1"
            color="textSecondary"
            pb={isSmallScreen ? 3 : 0}
          >
            {isDelivery ? t('cart.preferences') : t('cart.preferencesWillCall')}
            {isDelivery && (
              <Typography variant="body1" component="span" color="error">
                {'*'}
              </Typography>
            )}
          </Typography>
        </Grid>

        <Grid item xs={12} md={9}>
          <FormControl
            component="fieldset"
            sx={deliveryOptionsStyles.formControlSx}
          >
            <RadioGroup
              aria-label="delivery options"
              name="deliveryOptions"
              value={deliveryMethodOption}
              onChange={handleDeliveryOptionChange}
            >
              {isDelivery && (
                <FormControlLabel
                  value={DeliveryMethodOptionEnum.Standard}
                  control={radio('standard-delivery-radio-button')}
                  label={`${t('cart.standardDelivery')}`}
                  sx={deliveryOptionsStyles.formLabelOneSx}
                />
              )}
              <Grid
                container
                ml={isSmallScreen || !isDelivery ? 0 : 4}
                mb={4}
                mt={-1}
              >
                <Grid
                  container
                  spacing={isSmallScreen ? 1 : 2}
                  flexDirection={isDelivery || isSmallScreen ? 'row' : 'column'}
                >
                  <Grid item xs={11} md={isDelivery ? 5.5 : 11}>
                    <Box mb={3}>
                      {!updateDeliveryTypeLoading && cartLoaded && (
                        <DayPicker
                          onDayClick={handlePreferredDateChange}
                          input={(baseProps) => (
                            <TextField
                              id="preferred-date"
                              name="preferredDate"
                              label={
                                <Typography color="textSecondary">
                                  {t(
                                    isDelivery
                                      ? 'cart.preferredDateDelivery'
                                      : 'cart.preferredDateWillCall'
                                  )}
                                </Typography>
                              }
                              fullWidth
                              inputProps={{
                                'data-testid': 'preferred-date-input'
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      color="primary"
                                      size="small"
                                      data-testid="preferred-date-button"
                                      onClick={(e) =>
                                        baseProps.onFocus?.(e as any)
                                      }
                                    >
                                      <CalendarIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                ...baseProps
                              }}
                            />
                          )}
                          inputOptions={{
                            defaultSelected: isDelivery
                              ? deliveryData.preferredDate
                                ? new Date(deliveryData.preferredDate)
                                : preferredDate
                              : willCallData.preferredDate
                              ? new Date(willCallData.preferredDate)
                              : new Date(),
                            fromDate: new Date()
                          }}
                          disabled={[{ dayOfWeek: [0, 6] }]}
                        />
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={11} md={isDelivery ? 5.5 : 11}>
                    <Box mb={3}>
                      <Typography mb={0.9} color="textSecondary">
                        {t(
                          isDelivery
                            ? 'cart.preferredTimeDelivery'
                            : 'cart.preferredTimeWillCall'
                        )}
                      </Typography>
                      <Select
                        id="preferred-time"
                        name="preferredTime"
                        value={preferredTime}
                        onChange={handlePreferredTimeChange}
                        renderValue={preferredTimeValues}
                        inputProps={{ 'data-testid': 'preferred-time-input' }}
                        fullWidth
                      >
                        {getTypedKeys(PreferredTimeEnum).map(
                          (key) =>
                            key !== 'Asap' && (
                              <MenuItem
                                key={key}
                                value={PreferredTimeEnum[key]}
                              >
                                {key}
                              </MenuItem>
                            )
                        )}
                      </Select>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={11}>
                  {!contract && (
                    <Typography variant="h6" component="p" gutterBottom>
                      {t(
                        isDelivery
                          ? `cart.cutoffTimeDelivery`
                          : `cart.cutoffTimeWillCall`
                      )}
                    </Typography>
                  )}
                  {!contract && (
                    <Typography variant="body1" color="textSecondary">
                      {t(
                        isDelivery
                          ? `cart.cutoffTimeMessageDelivery`
                          : `cart.cutoffTimeMessageWillCall`
                      )}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              {isDelivery && isBranchAllowed && isAllItemsAvailable && (
                <Grid>
                  <FormControlLabel
                    value={DeliveryMethodOptionEnum.Rapid}
                    control={radio('rapid-delivery-radio-button')}
                    label={
                      <Trans
                        i18nKey="cart.rapidDelivery"
                        components={baseI18nComponents}
                      />
                    }
                    sx={deliveryOptionsStyles.formLabelMultipleSx}
                    disabled={!isRapidAllowed}
                  />
                  <Grid container ml={isSmallScreen ? 0 : 4} mb={8} mt={1}>
                    {isRapidAllowed && (
                      <Box
                        display="flex"
                        alignItems="center"
                        bgcolor="#FFF6E6"
                        borderRadius="4px"
                        my={1}
                        py={0.5}
                      >
                        <Box component={RapidDeliveryIcon} mr={1} ml={1} />
                        <Box flex={1} display="flex" flexDirection="column">
                          <Typography fontWeight={500} color="inherit">
                            {t('cart.rapidDeliveryMessage')}
                          </Typography>
                        </Box>
                        <Tooltip
                          title={
                            <Box px={1} py={0.5}>
                              <Typography
                                fontWeight={700}
                                fontSize={12.8}
                                mb={2}
                                color="inherit"
                              >
                                {t('cart.rapidDeliveryWarningTipHeader')}
                              </Typography>
                              <Typography
                                fontWeight={400}
                                fontSize={12.8}
                                color="inherit"
                              >
                                {t('cart.rapidDeliveryWarningTipMessage')}{' '}
                              </Typography>
                            </Box>
                          }
                          PopperProps={{
                            className: 'max-w-none'
                          }}
                          sx={{
                            '& .MuiTooltip-popper': {
                              backgroundColor: 'common.white'
                            }
                          }}
                        >
                          <Box
                            component={InfoOutlineIcon}
                            ml={1}
                            mr={1}
                            color="primary02.main"
                          />
                        </Tooltip>
                      </Box>
                    )}
                    <Grid item xs={12}>
                      <Box>
                        {!isInMiles && isWithinHours && (
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            pb={isSmallScreen ? 3 : 0}
                          >
                            <Typography
                              variant="body1"
                              component="span"
                              color="inherit"
                            >
                              {'* '}
                            </Typography>
                            {`${t('common.notAvailable')} ${t(
                              'cart.rapidDeliveryOverDistance'
                            )}`}
                          </Typography>
                        )}
                        {isInMiles && !isWithinHours && (
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            pb={isSmallScreen ? 3 : 0}
                          >
                            <Typography
                              variant="body1"
                              component="span"
                              color="inherit"
                            >
                              {'* '}
                            </Typography>
                            {`${t('common.notAvailable')} ${t(
                              'cart.rapidDeliveryNoTime'
                            )}`}
                          </Typography>
                        )}
                        {!isInMiles && !isWithinHours && (
                          <Typography>
                            <Typography
                              variant="body1"
                              component="span"
                              color="inherit"
                            >
                              {'* '}
                            </Typography>
                            {`${t('common.notAvailable')}`}
                            <List sx={{ listStyleType: 'disc', pl: 6, py: 0 }}>
                              <ListItem
                                sx={{
                                  display: 'list-item',
                                  pl: 0.5,
                                  pb: 0,
                                  pt: 0,
                                  '&:hover': { backgroundColor: 'transparent' }
                                }}
                              >
                                {t('cart.rapidDeliveryOverDistance')}
                              </ListItem>
                              <ListItem
                                sx={{
                                  display: 'list-item',
                                  pl: 0.5,
                                  pb: 0,
                                  pt: 0,
                                  '&:hover': { backgroundColor: 'transparent' }
                                }}
                              >
                                {t('cart.rapidDeliveryNoTime')}
                              </ListItem>
                            </List>
                          </Typography>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      {cartLoaded &&
        isDelivery &&
        allProductsNotAvailable &&
        !isFortlineUrl && (
          <Shipments
            shouldShipFullOrder={(delivery as Delivery)?.shouldShipFullOrder}
          />
        )}
      <Grid container mt={-2}>
        <Grid item xs={12} md={3}>
          <Typography
            variant="body1"
            color="textSecondary"
            pr={isSmallScreen ? 0 : 5}
            pb={isSmallScreen ? 1 : 0}
          >
            {t(
              isDelivery
                ? `cart.deliveryInstructions`
                : `cart.willCallInstructions`
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="instructions"
            name="instructions"
            placeholder={t('cart.haveSpecialInstructions')}
            value={instructions}
            onChange={handleInstructionsChange}
            multiline
            rows={5}
            inputProps={{
              maxLength: instructionsMax,
              'data-testid': 'instructions-input'
            }}
            fullWidth
            helperText={
              <Box component="span" display="flex" justifyContent="flex-end">
                {`${instructions.length} / ${instructionsMax}`}
              </Box>
            }
            sx={{ mt: 0 }}
            inputRef={instructionsRef}
          />
        </Grid>
        <Hidden mdDown>
          <Grid item md={3} />
        </Hidden>
      </Grid>
    </>
  );

  /**
   * Effect Definitions
   */
  function updateAddress() {
    if (isDelivery && delivery && !address) {
      setAddress(delivery.address as CustomAddressInput);
    } else if (shippingBranch && !address) {
      const address: CustomAddressInput = {
        city: shippingBranch.city ?? '',
        companyName: shippingBranch.name,
        country: '',
        custom: false,
        phoneNumber: shippingBranch.phone,
        state: shippingBranch.state ?? '',
        street1: shippingBranch.address1 ?? '',
        street2: shippingBranch.address2,
        zip: shippingBranch.zip ?? ''
      };
      setAddress(address);
    }
  }
  function updatePreferredTime() {
    if (delivery?.preferredTime) {
      setPreferredTime(delivery.preferredTime);
    }
  }
  function updatePreferredDate() {
    if (delivery?.preferredDate) {
      setPreferredDate(delivery.preferredDate);
    }
  }
  function updateInstructions() {
    const instructions = delivery
      ? (delivery.deliveryInstructions
          ? delivery.deliveryInstructions
          : (delivery as WillCall).pickupInstructions) ?? ''
      : '';
    setInstructions(instructions);
  }

  /**
   * Callback Definitions
   */
  function handlePreferredDateChange(preferredDate?: Date) {
    setPreferredDate(preferredDate);
    if (deliveryMethodOption === DeliveryMethodOptionEnum.Rapid) {
      setDeliveryMethodOption(DeliveryMethodOptionEnum.Standard);
      callUpdateDeliveryType(cart?.id!, DeliveryMethodOptionEnum.Standard);
    }
    if (isDelivery) {
      setDeliveryData({ ...deliveryData, preferredDate });
    } else {
      setWillCallData({ ...willCallData, preferredDate });
    }
  }

  function handlePreferredTimeChange(event: SelectChangeEvent<unknown>) {
    const preferredTime = (event.target as HTMLInputElement)
      .value as PreferredTimeEnum;
    setPreferredTime(preferredTime);
    if (deliveryMethodOption === DeliveryMethodOptionEnum.Rapid) {
      setDeliveryMethodOption(DeliveryMethodOptionEnum.Standard);
      callUpdateDeliveryType(cart?.id!, DeliveryMethodOptionEnum.Standard);
    }
    if (isDelivery) {
      setDeliveryData({ ...deliveryData, preferredTime });
    } else {
      setWillCallData({ ...willCallData, preferredTime });
    }
  }

  function handleInstructionsChange(event: ChangeEvent<HTMLInputElement>) {
    const deliveryInstructions = (event.target as HTMLInputElement).value;
    setInstructions(deliveryInstructions);
    if (isDelivery) {
      setDeliveryData({ ...deliveryData, deliveryInstructions });
    } else {
      const pickupInstructions = deliveryInstructions;
      setWillCallData({ ...willCallData, pickupInstructions });
    }
  }

  function handleDeliveryOptionChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const selectedDeliveryOption =
      DeliveryMethodOptionEnum[
        event.target.value as keyof typeof DeliveryMethodOptionEnum
      ];
    setDeliveryMethodOption(selectedDeliveryOption);
    if (selectedDeliveryOption === DeliveryMethodOptionEnum.Rapid) {
      setPreferredDate(undefined);
      handlePreferredDateChange();
      setPreferredTime('');
    }
    callUpdateDeliveryType(cart?.id!, selectedDeliveryOption);
  }
}
