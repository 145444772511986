import CompanyListHeader from 'common/Header/sub/CompanyList';
import OldCompanyListHeader from 'common/OldHeader/sub/OldCompanyList';
import { useAuthContext } from 'providers/AuthProvider';
/**
 * Component
 */
function CompanyListHeaderSelector() {
  /**
   * Context
   */
  const { activeFeatures } = useAuthContext();

  // General Header
  if (activeFeatures?.includes('NEW_HEADER')) {
    return <CompanyListHeader />;
  }
  return <OldCompanyListHeader />;
}

export default CompanyListHeaderSelector;
