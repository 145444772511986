import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';

import { noop } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { WrapperProps } from '@reece/global-types';
import {
  useApiGetProductDetails,
  useApiGetProductsPricing
} from 'API/products.api';
import { ProductDetails, ProductPricing } from 'API/types/products.types';
import { makeProductSlug } from 'Cart/util';
import { Maybe } from 'generated/graphql';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { useAuthContext } from 'providers/AuthProvider';
import { useBranchContext } from 'providers/BranchProvider';
import { useListsContext } from 'providers/ListsProvider';

/**
 * Types
 */
export type ProductPageContextType = {
  availableInList: string[];
  product?: Maybe<ProductDetails>;
  loading: boolean;
  pricingLoading: boolean;
  quantity: number;
  pricing?: ProductPricing;
  refetchProductDetails: () => void;
  setAvailableInList: (v: string[]) => void;
  setQuantity: (v: number) => void;
};
export type ProductPageParam = {
  id: string;
  slugBrand: string;
  slugCategory: string;
};

/**
 * Config
 */
export const PAGE_SIZE = 12;

/**
 * Context
 */
export const defaultProductPageContext: ProductPageContextType = {
  availableInList: [],
  loading: false,
  pricingLoading: false,
  quantity: 0,
  setAvailableInList: noop,
  setQuantity: noop,
  refetchProductDetails: noop
};
export const ProductPageContext = createContext(defaultProductPageContext);
export const useProductPageContext = () => useContext(ProductPageContext);

/**
 * Provider
 */
function ProductPageProvider({ children }: WrapperProps) {
  /**
   * Custom Hooks
   */
  const history = useHistory();
  const { pathname } = useLocation();
  const param = useParams<ProductPageParam>();
  const { t } = useTranslation();

  /**
   * Contexts
   */
  const { oktaAuth } = useAuthContext();
  const { shippingBranch } = useBranchContext();
  const { callGetAssociatedLists } = useListsContext();

  /**
   * State
   */
  const [quantity, setQuantity] = useState(0);
  const [availableInList, setAvailableInList] = useState<string[]>([]);

  /**
   * Data
   */
  // 🟣 GET product
  const {
    data: product,
    loading,
    refetch: refetchProductDetails
  } = useApiGetProductDetails(param.id)({
    onCompleted: ({ data }) =>
      callGetAssociatedLists({ products: [data.productId] })
  });
  // 🟣 GET - products pricing
  const {
    call: pricingCall,
    data: pricingData,
    loading: pricingLoading
  } = useApiGetProductsPricing();
  const pricing = pricingData?.products?.[0];

  /**
   * Page Title
   */
  useDocumentTitle(
    t('dynamicPageTitles.product', {
      mfrName: product?.manufacturerName ?? '',
      productName: product?.name ?? '',
      mfrNumber: product?.manufacturerNumber ?? ''
    })
  );

  /**
   * Callbacks
   */
  // 🟤 Cb - Call Pricing
  const handleCallPricing = useCallback(
    async () => {
      const auth = await oktaAuth?.isAuthenticated();
      auth && param.id && pricingCall([param.id]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [oktaAuth]
  );

  /**
   * Effects
   */
  // 🟡 effect - refetch product detail every time shipping branch changes
  useEffect(() => {
    if (pathname.includes('/product/')) {
      refetchProductDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  // 🟡 effect - Call product pricing
  useEffect(() => {
    handleCallPricing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingBranch]);
  // 🟡 effect - adjust qty
  useEffect(() => {
    !loading && product && setQuantity(product?.minIncrementQty || 1);
  }, [loading, product, setQuantity]);
  // 🟡 effect - adjust page slug url
  useEffect(() => {
    if (
      !loading &&
      !pricingLoading &&
      product &&
      param.slugBrand &&
      param.slugCategory
    ) {
      const { productId, manufacturerName, categories } = product;
      const productSlug = makeProductSlug(manufacturerName, categories?.at(-1));
      history.replace(`/product/${productSlug}${productId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, product, param.slugBrand, param.slugCategory]);

  /**
   * Render
   */
  return (
    <ProductPageContext.Provider
      value={{
        availableInList,
        product,
        loading,
        quantity,
        pricing,
        pricingLoading,
        setAvailableInList,
        setQuantity,
        refetchProductDetails
      }}
    >
      {children}
    </ProductPageContext.Provider>
  );
}
export default ProductPageProvider;
