import { Fragment } from 'react';

import { MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { WrapperProps } from '@reece/global-types';
import useNavDataMemo, { NavData } from 'common/OldHeader/lib/useNavDataMemo';
import { DropdownButton, Link } from 'components';

/**
 * Props
 */
export type DesktopSubheaderMenuProps = WrapperProps & {
  item: keyof NavData;
  hideLastIcon?: boolean;
  disabled?: boolean;
  className?: string;
};

/**
 * Compoment
 */
function DesktopSubheaderMenu(props: DesktopSubheaderMenuProps) {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();

  /**
   * Memo
   */
  const list = useNavDataMemo()[props.item];

  /**
   * Render
   */
  return (
    <div data-testid="header-subheader-menu-wrapper">
      <DropdownButton
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        testId={`header-subheader-menu-button-${props.item}`}
        hideLastIcon={props.hideLastIcon}
        disabled={props.disabled}
        className={props.className}
        content={(_, setOpen) => (
          <div className="py-4">
            {list.map((item, index) => (
              <Fragment key={`desktop-nav-${props.item}-${index}`}>
                {item.condition && (
                  <Link
                    onClick={() => setOpen(false)}
                    to={item.to}
                    testId={`desktop-nav-${item.id}`}
                    className="!text-primary-1-100"
                  >
                    <MenuItem>
                      {item.name}
                      {item.comingSoon && ` - ${t('common.comingSoon')}`}
                    </MenuItem>
                  </Link>
                )}
              </Fragment>
            ))}
          </div>
        )}
      >
        {props.children}
      </DropdownButton>
    </div>
  );
}

export default DesktopSubheaderMenu;
