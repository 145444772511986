import { startCase } from 'lodash-es';
import { t } from 'i18next';

import {
  Branch,
  OrderPreviewResponse,
  OrderResponse,
  PaymentMethodTypeEnum,
  PreferredTimeEnum
} from 'generated/graphql';
import { AddressDifferences, CustomAddressInput, PaymentData } from './types';
import { CartContractType } from 'providers/CartProvider';
import { RapidDeliveryResponse } from 'API/types/checkout.types';

/**
 * Default Values
 */
export const defaultBranchData: Branch = {
  branchId: '',
  latitude: 0,
  longitude: 0,
  isPlumbing: false,
  isWaterworks: false,
  isHvac: false,
  isBandK: false,
  isActive: false,
  isAvailableInStoreFinder: false,
  isPricingOnly: false,
  isShoppable: false,
  id: ''
};

export const defaultOrderData: OrderResponse = { lineItems: [] };
export const defaultOrderPreviewData: OrderPreviewResponse = {
  subTotal: '',
  tax: '',
  orderTotal: ''
};
export const defaultPaymentData: PaymentData = {
  paymentMethodType: PaymentMethodTypeEnum.Billtoaccount
};

export const defaultRapidDelivery: RapidDeliveryResponse = {
  isRapidAllowed: false,
  isInMiles: false,
  isBranchAllowed: false,
  isWithinHours: false,
  isAllItemsAvailable: false
};

export const initialAddressValues: CustomAddressInput = {
  companyName: '',
  street1: '',
  street2: '',
  city: '',
  state: '',
  zip: '',
  phoneNumber: '',
  country: 'USA',
  custom: true
};

/**
 * Utils
 */
export function preferredTimeValues(value: unknown) {
  const enumValue = value as PreferredTimeEnum;
  return enumValue === PreferredTimeEnum.Asap
    ? PreferredTimeEnum.Asap
    : startCase(enumValue.toLowerCase());
}

export function contractInfo({
  contract,
  orderedContract
}: {
  contract?: CartContractType;
  orderedContract?: CartContractType;
}) {
  const na = t('common.na');
  const output = {
    contractNumber: na,
    contractDescription: na,
    job: na
  };
  if (contract) {
    const { data } = contract;

    output.contractNumber = data?.contractNumber ?? na;
    output.contractDescription = data?.contractDescription ?? na;

    const jobName = data?.jobName;
    const jobNumber = data?.customerInfo?.jobNumber;
    if (jobName && jobNumber) {
      output.job = `${jobName} - ${jobNumber}`;
    } else if (jobName || jobNumber) {
      output.job = jobName || jobNumber!;
    }
  } else if (orderedContract) {
    const { data } = orderedContract;

    output.contractNumber = data?.contractNumber ?? na;
    output.contractDescription = data?.contractDescription ?? na;

    const jobName = data?.jobName;
    const jobNumber = data?.customerInfo?.jobNumber;
    if (jobName && jobNumber) {
      output.job = `${jobName} - ${jobNumber}`;
    } else if (jobName || jobNumber) {
      output.job = jobName || jobNumber!;
    }
  }
  return output;
}

// Function - convert to Title Case
function titleCase(str: string) {
  return str
    .toString()
    .toLowerCase()
    .replace(/\b\w/g, (wordHead) => wordHead.toUpperCase());
}

// Function -  highlight differences in the address string
function highlightDifferences(unval: string, val: string) {
  const oldWords = unval.split(/\s+/);
  const newWords = val.split(/\s+/);

  const maxLength = Math.max(oldWords.length, newWords.length);
  const result = [];

  for (let i = 0; i < maxLength; i++) {
    const oldWord = oldWords[i] ?? '';
    const newWord = newWords[i] ?? '';

    if (oldWord !== newWord) {
      if (newWord) {
        result.push(`<span>[${newWord}]</span>`);
        continue;
      }
      oldWord && result.push(`${oldWord}`);
    } else {
      result.push(newWord);
    }
  }

  return result.join(' ');
}

// Function - get differences between the two addresses
export const handleAddressDiffs = (
  unvalidated: CustomAddressInput,
  validated: CustomAddressInput
) => {
  let differences: AddressDifferences = { custom: true };
  let diffKeys: string[] = [];

  Object.keys(validated).forEach((key) => {
    if (key === 'custom') {
      return;
    }

    const val = validated[key]!.toString().toUpperCase();
    const unval = unvalidated[key]!.toString().toUpperCase();

    if (val !== unval) {
      diffKeys.push(key);

      if (key === 'street1') {
        differences[key] = highlightDifferences(
          titleCase(unvalidated[key]),
          titleCase(validated[key])
        );
      } else {
        differences[key] = `<span>[${titleCase(val)}]</span>`;
      }
    } else {
      if (key === 'zip' || key === 'state' || key === 'phoneNumber') {
        differences[key] = val;
      } else {
        differences[key] = titleCase(val);
      }
    }
  });

  if (!diffKeys.length) {
    return {};
  }
  return differences;
};
