import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

import useDocumentTitle from 'hooks/useDocumentTitle';
import PoshHeader from 'pages/BrandPages/Posh/sub/PoshHeader';
import PoshMission from 'pages/BrandPages/Posh/sub/PoshMission';
import PoshProductList from 'pages/BrandPages/Posh/sub/PoshProductList';
import PoshFooter from 'pages/BrandPages/Posh/sub/PoshFooter';

/**
 * Component
 */
function PoshMarketing() {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();
  useDocumentTitle(t('poshMarketing.posh'));

  /**
   * Render
   */
  return (
    <Container
      disableGutters
      className="!max-w-[1440px] bg-common-white font-tt-commons"
      data-testid="posh-root"
    >
      <PoshHeader />
      <PoshMission />
      <PoshProductList />
      <PoshFooter />
    </Container>
  );
}

export default PoshMarketing;
