import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Maybe, Category } from 'generated/graphql';
import { Link } from '@dialexa/reece-component-library';
import { makeSlug } from 'utils/makeSlug';

export type Props = {
  filters: Maybe<Category>[];
  category: string;
};

export default function CategoriesMenu(props: Props) {
  const entries = props.filters
    ? Object.entries(props.filters[0]!.children!)
    : null;

  return !entries ? (
    <div data-testid="categories-menu-empty"></div>
  ) : (
    <div data-testid="categories-menu">
      {entries!.map((item, index) => {
        return (
          <div key={'cat-' + index} data-test-id="categories-item-menu">
            <Box component="section" sx={{ p: 1.5, px: 0 }}>
              <Typography>
                <b>{item[1]?.name}</b>
              </Typography>
            </Box>

            <span></span>
            {item[1]?.children!.map((sub, indexSub) => {
              return (
                <Box
                  component="section"
                  sx={{ p: 0.6, px: 2 }}
                  key={index + '-' + indexSub}
                >
                  <Link
                    variant="body2"
                    data-testid={`category2-${index}`}
                    component={RouterLink}
                    sx={{ pb: 0, fontWeight: 200 }}
                    to={{
                      pathname:
                        '/search/category' +
                        makeSlug([props.category, item[1]?.name, sub.name]),
                      search: `&categories=${[
                        props.category === 'water heaters'
                          ? 'Water Heaters'
                          : props.category,
                        item[1]?.name,
                        sub.name
                      ]
                        .map((c) => encodeURIComponent(c ?? '').trim())
                        .join('&categories=')}`
                    }}
                  >
                    <Typography>{sub.name}</Typography>
                  </Link>
                </Box>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}
