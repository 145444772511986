// https://github.com/gpbl/react-day-picker/tree/master/packages/react-day-picker
// Version 8
import { FocusEvent, ReactNode, useRef, useState } from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { ClickAwayListener, InputBaseProps, Popper, Grow } from '@mui/material';
import clsx from 'clsx';
import {
  DayPicker as BaseDayPicker,
  DayPickerProps as BaseDayPickerProps
} from 'react-day-picker';

import DayPickerDay from 'components/DayPicker/DayPickerDay';
import useDateInput, {
  ControlledInputOptions
} from 'components/DayPicker/lib/useDateInput';
import { popperStyling, wrapperStyling } from 'components/DayPicker/style';
import { Modify } from '@dialexa/reece-component-library';

/**
 * Config
 */
const baseDayPickerComponents = {
  DayContent: DayPickerDay,
  IconLeft: () => <ChevronLeftIcon />,
  IconRight: () => <ChevronRightIcon />
};

/**
 * Types
 */
type NewProps = {
  id?: string;
  input?: (props: InputBaseProps) => ReactNode;
  inputOptions?: ControlledInputOptions;
  showOutsideDays?: boolean;
  testId?: string;
};
export type DayPickerProps = Modify<BaseDayPickerProps, NewProps>;

/**
 * Component
 */
function DayPicker(props: DayPickerProps) {
  /**
   * Props
   */
  const {
    components,
    disabled,
    id,
    inputOptions,
    onDayClick,
    showOutsideDays,
    testId
  } = props;

  /**
   * Custom hooks
   */
  const input = useDateInput({
    defaultSelected: new Date(),
    fromYear: new Date().getFullYear(),
    toYear: new Date().getFullYear() + 1,
    onSelect: onDayClick as (day: Date) => void,
    ...inputOptions
  });

  /**
   * Refs
   */
  const inputEl = useRef<HTMLInputElement | null>(null);

  /**
   * State
   */
  const [open, setOpen] = useState(false);

  /**
   * Callbacks
   */
  const onFocus = (event: FocusEvent<HTMLInputElement, Element>) => {
    input.inputProps.onFocus?.(event);
    setOpen(true);
  };

  /**
   * Render
   */
  return props.input ? (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        {props.input({ ...input.inputProps, ref: inputEl, onFocus })}
        <Popper
          id={open ? id : undefined}
          open={open}
          anchorEl={inputEl.current}
          placement="bottom"
          transition
          modifiers={[{ name: 'flip', enabled: false }]}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <div className={popperStyling}>
                <div
                  className={clsx(wrapperStyling)}
                  data-testid={`${testId}-input-wrapper`}
                >
                  <BaseDayPicker
                    {...input.dayPickerProps}
                    components={{ ...baseDayPickerComponents, ...components }}
                    disabled={disabled}
                    showOutsideDays={showOutsideDays ?? true}
                  />
                </div>
              </div>
            </Grow>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  ) : (
    <div className={clsx(wrapperStyling)} data-testid={`${testId}-wrapper`}>
      <BaseDayPicker
        {...props}
        showOutsideDays={showOutsideDays ?? true}
        components={{ ...baseDayPickerComponents, ...components }}
      />
    </div>
  );
}

export default DayPicker;
