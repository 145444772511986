import { useMemo } from 'react';

import { Divider, Table, TableBody, TableContainer } from '@mui/material';
import clsx from 'clsx';
import { TableInstance } from 'react-table';

import { tableStyles } from 'common/TablePageLayout/lib/styles';
import TableRendererBodyLoading from 'common/TablePageLayout/sub/TableRendererBodyLoading';
import TableRendererBodyRow from 'common/TablePageLayout/sub/TableRendererBodyRow';
import TableRendererFooter from 'common/TablePageLayout/sub/TableRendererFooter';
import TableRendererHead from 'common/TablePageLayout/sub/TableRendererHead';

/**
 * Types
 */
export type TableRendererProps<TData extends object = {}> = {
  cellClassName?: string;
  customGoToPage?: (pageIndex: number) => void;
  customNextPage?: () => void;
  customPageNumber?: number;
  customPageCount?: number;
  customPreviousPage?: () => void;
  customTotalItems?: number;
  hasGroups?: boolean;
  headerClassName?: string;
  isContracts?: boolean;
  isWaterworks?: boolean;
  loading?: boolean;
  noResultsContactBranch?: string;
  noResultsContactMessage?: string;
  noResultsMessage?: string;
  onRowClick?: (row: TData) => void;
  primaryKey: string;
  resultsCount?: number;
  resultsCountText?: string;
  tableInstance: TableInstance<TData>;
  testId: string;
};

/**
 * Component
 */
// This expects a table instance from react table to be passed to it. Requires pagination to be included.
function TableTemplate<TData extends object = {}>(
  props: TableRendererProps<TData>
) {
  /**
   * Memo
   */
  const tableBodyRender = useMemo(
    () =>
      props.tableInstance.page.map((row, rowIndex) => {
        props.tableInstance.prepareRow(row);
        return (
          <TableRendererBodyRow
            {...props}
            row={row}
            rowIndex={rowIndex}
            key={rowIndex}
          />
        );
      }),
    [props]
  );

  /**
   * Render
   */
  return (
    <>
      <TableContainer className="!p-0">
        <Table
          {...props.tableInstance.getTableProps()}
          data-testid={props.testId}
          className={clsx(tableStyles)}
        >
          <TableRendererHead {...props} />
          {props.loading || !props.resultsCount ? (
            <TableRendererBodyLoading {...props} />
          ) : (
            <TableBody {...props.tableInstance.getTableBodyProps()}>
              {tableBodyRender}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Divider />
      <TableRendererFooter {...props} />
    </>
  );
}

export default TableTemplate;
