import { MouseEvent, ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';

import clsx from 'clsx';

import { WrapperProps } from '@reece/global-types';
import { Card, IconButton } from 'components';
import Fade from 'components/Fade';
import { CloseIcon } from 'icons';

/**
 * ℹ️ TEST INFO - For unit tests, please mock Fade instead.
 * When mocking, use 'components/Fade' instead of 'components'.
 * See unit test of this component as example.
 */

/**
 * Styles
 */
export const modalWidthStyles = {
  default: 'w-auto',
  xs: 'max-w-[360px]',
  sm: 'max-w-[560px]',
  md: 'max-w-[768px]',
  lg: 'max-w-[960px]',
  xl: 'max-w-[1280px]',
  full: 'w-full'
};

/**
 * Types
 */
export type ModalWidthOptions = keyof typeof modalWidthStyles;
export type ModalProps = WrapperProps & {
  fullScreen?: boolean;
  width?: ModalWidthOptions;
  noClose?: boolean;
  headerContent?: ReactNode;
  onClose?: () => void;
  open: boolean;
  testId?: string;
  stayMounted?: boolean;
  zOverride?: number;
  disableClose?: boolean;
  overridePageScroll?: boolean;
  noBlur?: boolean;
  noRounded?: boolean;
};

/**
 * Component
 */
function Modal(props: ModalProps) {
  /**
   * Callbacks
   */
  const close = (selfOnly: boolean) => (e: MouseEvent) => {
    if (
      props.noClose ||
      props.disableClose ||
      (e.currentTarget !== e.target && selfOnly)
    ) {
      return;
    }
    props.onClose?.();
  };

  /**
   * Effects
   */
  // 🟡 Effect - disable/enable scroll to the page depends on if the modal is open
  useEffect(() => {
    if (!props.overridePageScroll) {
      if (props.open && document.body.style.overflow !== 'hidden') {
        document.body.style.overflow = 'hidden';
      }
      if (!props.open && document.body.style.overflow === 'hidden') {
        document.body.style.overflow = '';
      }
    }
  }, [props.open, props.overridePageScroll]);

  /**
   * Render
   */
  return createPortal(
    <Fade
      in={props.open}
      className={clsx({ 'z-[999]': !props.zOverride })}
      stayMounted={props.stayMounted}
    >
      <div
        className={clsx(
          'fixed top-0 left-0 w-full h-full flex items-center justify-center',
          { 'bg-common-black/40 backdrop-blur-sm': !props.noBlur }
        )}
        onClick={close(true)}
        style={{ zIndex: props.zOverride }}
        data-testid={props.testId}
      >
        {props.open}
        <div
          className={clsx(
            'md:px-2',
            modalWidthStyles[props.width ?? 'default'],
            {
              'flex-1': props.width,
              '!w-full h-full': props.fullScreen
            }
          )}
        >
          <Card
            className={clsx('p-4 w-auto flex flex-col gap-y-2', {
              '!rounded-none':
                props.width === 'full' || props.fullScreen || props.noRounded,
              'h-full': props.fullScreen
            })}
          >
            <div className="flex flex-nowrap items-center">
              {Boolean(props.headerContent) && (
                <div
                  className="flex-1 py-1 px-2 md:px-0"
                  data-testid={`${props.testId}-header`}
                >
                  {props.headerContent}
                </div>
              )}
              {!props.noClose && (
                <IconButton
                  onClick={close(false)}
                  disabled={!props.open || props.disableClose}
                  data-testid={`${props.testId}-close`}
                >
                  <CloseIcon data-testid={`${props.testId}-delete-close`} />
                </IconButton>
              )}
            </div>
            <div className="px-2 md:h-full md:px-0 md:overflow-y-hidden">
              {props.children}
            </div>
          </Card>
        </div>
      </div>
    </Fade>,
    document.body
  );
}
export default Modal;
