import { Component, isValidElement } from 'react';

import { Redirect } from 'react-router-dom';

import { WrapperProps } from '@reece/global-types';

// 🚫 - Due to the nature of React `getDerivedStateFromError`, this cannot be a functional component
/**
 * Types
 */
type State = {
  hasError: boolean;
  error?: Error;
};

/**
 * Config
 */
const defaultState: State = { hasError: false };

/**
 * Component
 */
class ErrorBoundary extends Component<WrapperProps, State> {
  /**
   * Constructor
   */
  constructor(props: WrapperProps) {
    super(props);
    this.state = defaultState;
  }

  /**
   * Error State
   */
  static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error };
  }

  /**
   * Render
   */
  render() {
    if (this.state.hasError) {
      const to = { pathname: '/error', state: { error: this.state.error } };
      return <Redirect to={to} />;
    }
    if (isValidElement(this.props.children)) {
      return this.props.children;
    }
    return <Redirect to="/" />;
  }
}

export default ErrorBoundary;
