import { useMemo, useState } from 'react';

import { CardHeader, Hidden } from '@mui/material';
import clsx from 'clsx';
import { isEqual } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { useAccountPageContext } from 'pages/Account/AccountPageProvider';
import useEditAccountForm, {
  EditAccountForm
} from 'pages/Account/lib/useEditAccountForm';
import ConfirmEmailChangeDialog from 'pages/Account/sub/ConfirmEmailChangeDialog';
import {
  Button,
  Card,
  CircularProgress,
  FormMaskedInput,
  FormSelectInput,
  FormTextInput
} from 'components';
import {
  useGetPhoneTypesQuery,
  useUpdateUserMutation
} from 'generated/graphql';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';
import { useSnackbar } from 'providers/SnackbarProvider';
import ConfirmCancelDialog from 'User/ConfirmCancelDialog';
import { OnButtonClick } from '@reece/global-types';

function EditAccount() {
  /**
   * Custom hooks
   */
  const { pushAlert } = useSnackbar();
  const { t } = useTranslation();

  /**
   * Context
   */
  const { selectedAccounts } = useSelectedAccountsContext();
  const { handleFinishEditing, userInfo } = useAccountPageContext();

  /**
   * State
   */
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [confirmEmailDialogOpen, setConfirmEmailDialogOpen] = useState(false);
  const [submitForm, setSubmitForm] = useState(false);

  /**
   * Data
   */
  const { data: phoneTypesQuery } = useGetPhoneTypesQuery();
  const [updateUser, { loading: updateUserLoading }] = useUpdateUserMutation();

  /**
   * Memo
   */
  const accountId = useMemo(
    () => selectedAccounts.billTo?.id,
    [selectedAccounts]
  );
  const phoneTypes = useMemo(
    () =>
      phoneTypesQuery?.phoneTypes?.map((pt) => ({
        label: t(`common.${pt.toLowerCase()}`),
        value: pt
      })) ?? [],
    [phoneTypesQuery?.phoneTypes, t]
  );

  /**
   * Form
   */
  const { control, getValues, handleSubmit, defaultValues } =
    useEditAccountForm();

  /**
   * Callbacks
   */
  // 🟤 Cb - Cancel
  const handleCancel = (e: OnButtonClick) => {
    e.preventDefault();
    const values = getValues();
    if (!isEqual(defaultValues, values)) {
      setCancelDialogOpen(true);
      return;
    }
    handleFinishEditing();
  };
  // 🟤 Cb - Close Cancel Dialog
  const handleCloseCancelDialog = (shouldSave: boolean) => {
    if (shouldSave) {
      setCancelDialogOpen(false);
      setSubmitForm(true);
      handleSubmit(onSubmit);
      return;
    }
    handleFinishEditing();
  };
  // 🟤 Cb - Close Confirm Email Dialog
  const handleCloseConfirmEmailDialog = (shouldSave: boolean) => {
    if (shouldSave) {
      setConfirmEmailDialogOpen(false);
      setSubmitForm(true);
      handleSubmit(onSubmit);
      return;
    }
    handleFinishEditing();
  };
  // 🟤 Cb - Submit
  const onSubmit = (values: EditAccountForm) => {
    if (values.email !== userInfo.email && !submitForm) {
      setConfirmEmailDialogOpen(true);
      return;
    }
    const updateUserInput = {
      accountId: accountId,
      userId: userInfo.id,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      email: values.email,
      phoneTypeId: values.phoneTypeId
    };
    updateUser({ variables: { updateUserInput } })
      .then(() => {
        pushAlert(t('user.accountInformationUpdated'), { variant: 'success' });
        handleFinishEditing({ ...userInfo, ...values });
      })
      .catch((error) => {
        console.error(error);
        pushAlert(t('user.informationFailed'), { variant: 'error' });
      });
  };

  /**
   * Render
   */
  return (
    <div data-testid="edit-account-wrapper">
      <ConfirmCancelDialog
        open={cancelDialogOpen}
        onClose={handleCloseCancelDialog}
      />
      <ConfirmEmailChangeDialog
        open={confirmEmailDialogOpen}
        onClose={handleCloseConfirmEmailDialog}
        email={getValues('email')}
      />
      <Hidden mdUp>
        <div className="pl-4">
          <h4 className="font-medium text-xl">
            {t('common.accountInformation')}
          </h4>
        </div>
      </Hidden>
      <Hidden mdDown>
        <Card className="p-2">
          <CardHeader
            title={t('common.accountInformation')}
            titleTypographyProps={{ className: '!text-2xl font-medium' }}
          />
        </Card>
      </Hidden>
      <form
        className="mt-6 pl-6 pr-12 md:pl-4 md:pr-4"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <div className="grid grid-cols-2 gap-4">
          <p className="col-span-2 text-sm md:mb-2">{t('user.nameChange')}</p>
          <div className="col-span-2">
            <FormTextInput
              type="text"
              name="firstName"
              label={t('common.firstName')}
              control={control}
              testId="first-name-input"
              required
              className="w-full"
            />
          </div>
          <div className="col-span-2">
            <FormTextInput
              type="text"
              name="lastName"
              label={t('common.lastName')}
              control={control}
              testId="last-name-input"
              required
              className="w-full"
            />
          </div>
          <div
            className={clsx('md:col-span-2', {
              'col-span-2': !phoneTypes.length
            })}
          >
            <FormMaskedInput
              name="phoneNumber"
              label={t('common.phoneNumber')}
              control={control}
              testId="phone-input"
              required
              mask="phone"
            />
          </div>
          <div
            className={clsx('md:col-span-2', { hidden: !phoneTypes.length })}
          >
            <FormSelectInput
              name="phoneTypeId"
              label={t('common.phoneType')}
              control={control}
              testId="phone-type-input"
              required
              listKey="phone-type-input"
              placeholder={t('common.select')}
              options={phoneTypes}
            />
          </div>
          <div className="col-span-2">
            <FormTextInput
              type="text"
              name="email"
              label={t('common.emailAddress')}
              control={control}
              testId="email-input"
              required
              className="w-full"
            />
          </div>
          <div className="col-span-2 pt-8">
            <Button
              disabled={updateUserLoading}
              type="submit"
              color="lightBlue"
              data-testid="editaccount-submit-button"
            >
              {updateUserLoading ? (
                <CircularProgress size={20} testId="editaccount-loading" />
              ) : (
                t('common.save')
              )}
            </Button>
            <Button
              kind="text"
              onClick={handleCancel}
              data-testid="editaccount-cancel-button"
            >
              {t('common.cancel')}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditAccount;
