import { Dispatch } from 'react';

import { useHistory } from 'react-router-dom';

import { Button } from 'components';
import { SignOutIcon } from 'icons';

/**
 * Types
 */
export type UserItemButtonProps = {
  isSignOut?: boolean;
  label: string;
  path: string;
  setOpen: Dispatch<boolean>;
};

/**
 * Component
 */
function UserItemButton(props: UserItemButtonProps) {
  /**
   * Custom Hooks
   */
  const { push } = useHistory();

  /**
   * Callbacks
   */
  const onClick = () => {
    props.setOpen(false);
    push(props.path);
  };

  /**
   * Render
   */
  return (
    <Button
      kind="text"
      iconStart={
        props.isSignOut ? (
          <SignOutIcon data-testid="header-useritembutton-signouticon" />
        ) : undefined
      }
      color={props.isSignOut ? 'lightBlue' : 'gray'}
      fullWidth
      className="!px-0 !justify-start !font-normal [&_>_svg]:w-6 [&_>_svg]:h-6"
      onClick={onClick}
      data-testid="header-useritembutton"
    >
      {props.label}
    </Button>
  );
}

export default UserItemButton;
