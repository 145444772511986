import { useState } from 'react';

import Button from 'components/Button';
import Card from 'components/Card';
import Stepper from 'components/Stepper';

/**
 * Config
 */
const display = {
  title: 'Stepper'
};
// set dates
const steps = ['start', 'mid', 'end'];

/**
 * Component
 */
function StepperPage() {
  // state
  const [step, setStep] = useState(0);
  // callback
  const adjustStep = (next: boolean) => () => {
    const nextIndex = Math.min(step + 1, steps.length - 1);
    const prevIndex = Math.max(step - 1, 0);
    setStep(next ? nextIndex : prevIndex);
  };
  // render
  return (
    <Card className="my-8 p-6" data-testid="component-page_stepper">
      <h5 className="text-2xl font-bold text-secondary-2-100">
        {display.title}
      </h5>
      <hr className="my-4" />
      <div className="my-4 flex justify-center">
        <Stepper steps={steps} activeStep={step} testId="stepper" />
      </div>
      <hr className="my-4" />
      <div className="my-4 flex justify-center gap-2">
        <Button
          onClick={adjustStep(false)}
          disabled={step <= 0}
          color="lightBlue"
          kind="text"
          data-testid="prev"
        >
          Previous
        </Button>
        <Button
          onClick={adjustStep(true)}
          disabled={step >= steps.length - 1}
          color="lightBlue"
          kind="text"
          data-testid="next"
        >
          Next
        </Button>
      </div>
    </Card>
  );
}

export default StepperPage;
