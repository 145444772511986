import {
  FieldValues,
  UseControllerProps,
  useController
} from 'react-hook-form';
import MaskedInput, { MaskedInputProps } from 'components/MaskedInput';
import { Modify } from '@reece/global-types';

/**
 * Types
 */
type NewProps = {
  label?: string;
  testId?: string;
  disabled?: boolean;
  required?: boolean;
  message?: string;
  placeholder?: string;
  className?: string;
  mask: MaskedInputProps['mask'];
  pattern?: string;
};
export type FormMaskedInputProps<TFields extends FieldValues> = Modify<
  UseControllerProps<TFields>,
  NewProps
>;

/**
 * Component
 */
function FormMaskedInput<T extends FieldValues>(
  props: FormMaskedInputProps<T>
) {
  /**
   * Props
   */
  const {
    label,
    testId,
    disabled,
    required,
    message,
    placeholder,
    className,
    mask,
    pattern,
    ...rest
  } = props;

  /**
   * Custom Hooks
   */
  const {
    field,
    fieldState: { invalid, error }
  } = useController(rest);

  /**
   * Render
   */
  return (
    <MaskedInput
      label={label}
      testId={testId}
      disabled={disabled}
      required={required}
      placeholder={placeholder}
      className={className}
      mask={mask}
      pattern={pattern}
      message={invalid ? error?.message : message}
      error={Boolean(error?.message)}
      {...field}
    />
  );
}

export default FormMaskedInput;
