import BuilderIO from 'pages/BuilderIO/Core';

/**
 * Component
 */
function BuilderIODivisions() {
  /**
   * Render
   */
  return (
    <div data-testid="builderio-page-root">
      <BuilderIO model="divisions-pages" pathname={window.location.pathname} />
    </div>
  );
}
export default BuilderIODivisions;
