import { useMemo, useState } from 'react';

import { Collapse, Link, List, ListItem } from '@mui/material';
import { kebabCase } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import useCompanyList from 'common/Header/lib/useCompanyList';
import { useDomainInfo } from 'hooks/useDomainInfo';
import { ArrowDropDownIcon, ArrowDropUpIcon } from 'icons';
import { generateCompanyUrl } from 'utils/brandList';
import { Configuration } from 'utils/configuration';

/**
 * Component
 */
function MobileDrawerCollapseListCompany() {
  /**
   * Hooks
   */
  const { port } = useDomainInfo();
  const { t } = useTranslation();

  /**
   * Context
   */

  /**
   * States
   */
  const [open, setOpen] = useState(false);

  /**
   * Memo
   */
  // 🔵 Memo - Filter company list
  const companyList = useCompanyList();
  // 🔵 Memo - Rendered list item
  const renderedCompanyList = useMemo(
    () =>
      companyList.map(({ link, sub }, i) => (
        <ListItem key={i} data-testid={`${kebabCase(link)}-button`}>
          <Link
            key={`mobile-nav-company-${i}`}
            href={generateCompanyUrl(sub, port, Configuration.environment)}
            underline="none"
            color="secondary02.main"
          >
            {link}
          </Link>
        </ListItem>
      )),
    [companyList, port]
  );

  /**
   * Render
   */
  return (
    <>
      <ListItem
        className="!px-8 !py-4"
        data-testid="reece-companies-button"
        onClick={() => setOpen(!open)}
      >
        {t('common.reeceCompanies')}
        {open ? (
          <ArrowDropUpIcon
            className="text-primary-1-100"
            data-testid="reece-companies-button-arrow-up"
          />
        ) : (
          <ArrowDropDownIcon
            className="text-primary-1-100"
            data-testid="reece-companies-button-arrow-down"
          />
        )}
      </ListItem>
      <Collapse in={open}>
        <List className="!ml-9">{renderedCompanyList}</List>
      </Collapse>
    </>
  );
}

export default MobileDrawerCollapseListCompany;
