import { OnInputKeyboard } from '@reece/global-types';

enum InputKeys {
  BACKSPACE = 'Backspace',
  DELETE = 'Delete',
  ENTER = 'Enter'
}

export const handleDateRangeInputKeyDown = (
  event: OnInputKeyboard,
  onSubmit: (e: OnInputKeyboard) => void
) => {
  const { key, target } = event;
  const input = target as HTMLInputElement;
  const keyIsNumber = !isNaN(Number(key));

  const { BACKSPACE, DELETE, ENTER } = InputKeys;
  const isDeleting = [BACKSPACE.toString(), DELETE.toString()].includes(key);
  const isSubmitting = key === ENTER.toString();

  // Prevent default behavior for specific keys
  if (isDeleting || isSubmitting) {
    event.preventDefault();
  }

  const selectionStart = input.selectionStart || 0;
  const selectionEnd = input.selectionEnd || 0;
  const isSingleCharacter = selectionStart === selectionEnd;

  let { value } = input;

  if (isDeleting) {
    // Handle deletion within the mask
    value = value.slice(0, selectionStart - 1) + value.slice(selectionEnd);
    input.value = value;

    if (isSingleCharacter) {
      // Deleting a single character
      input.setSelectionRange(selectionStart - 1, selectionStart - 1);
    } else {
      // Deleting a range of characters
      input.setSelectionRange(selectionStart, selectionStart);
    }
  } else if (keyIsNumber) {
    // Handle numeric input
    event.preventDefault();
    value = value.slice(0, selectionStart) + key + value.slice(selectionEnd);
    input.value = value;
    input.setSelectionRange(selectionStart + 1, selectionStart + 1);
  } else if (isSubmitting) {
    onSubmit(event);
  }
};
