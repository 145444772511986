import { SyntheticEvent } from 'react';

import clsx from 'clsx';

import { Modify } from '@reece/global-types';
import notfound from 'images/notfound.png';

/**
 * Styling
 */
const baseImgStyle = 'w-auto h-auto max-w-full max-h-full';

/**
 * Types
 */
type NewProps = {
  alt?: string;
  src?: string;
  className?: string;
};
export type ImageProps = Modify<
  React.DOMAttributes<HTMLImageElement>,
  NewProps
>;

/**
 * Component
 */
function Image(props: ImageProps) {
  /**
   * Props
   */
  const {
    alt = '',
    className,
    src = '',
    onError: userOnError,
    ...remainingProps
  } = props;

  /**
   * Callbacks
   */
  // 🟤 Cb - Error
  const onError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.onerror = null;
    e.currentTarget.src = notfound;
    userOnError?.(e);
  };

  /**
   * Render
   */
  return (
    <img
      {...remainingProps}
      className={clsx(baseImgStyle, className)}
      alt={alt}
      onError={onError}
      src={src}
    />
  );
}

export default Image;
