import { useMemo } from 'react';

import { Hidden } from '@mui/material';

import Breadcrumbs, { BreadcrumbConfig } from 'common/Breadcrumbs';
import { useProductPageContext } from 'pages/Product/ProductPageProvider';

/**
 * Component
 */
function ProductBreadcrumb() {
  /**
   * Context
   */
  const { product } = useProductPageContext();

  /**
   * Memos
   */
  const breadcrumbConfig = useMemo<BreadcrumbConfig[]>(
    () =>
      product?.categories?.map((text, index) => {
        const search = `?categories=${product
          .categories!.slice(0, index + 1)
          .map((c) => encodeURIComponent(c).trim())
          .join('&categories=')}`;
        const to = { pathname: '/search', search, state: '', hash: '' };
        return { text, to };
      }) ?? [],
    [product]
  );

  /**
   * Render
   */
  return (
    <Hidden mdDown>
      <Breadcrumbs config={breadcrumbConfig} pageTitle={product?.name ?? ''} />
    </Hidden>
  );
}
export default ProductBreadcrumb;
