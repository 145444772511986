import { APIOptions } from 'API/hooks/useApiBase';
import { useApiLazy, UseAPILazyProps } from 'API/hooks/useApiLazy';
import { useApiLazyAuth } from 'API/hooks/useApiLazyAuth';
import {
  GetNearbyBranchesRequest,
  GetNearbyBranchesResponse,
  GetStoreFinderRequest,
  GetStoreFinderResponse
} from 'API/types/branches.types';
import { generateQueryParam } from 'utils/generateQueryParam';

/**
 * Config
 */
const BASE_URL = 'branches';

// 🟦 GET /branches/getStoreFinderBranches - Get product stock and pricing with multiple product ids
export function useApiGetStoreFinderBranches(
  options: APIOptions<GetStoreFinderResponse> = {}
) {
  type Res = GetStoreFinderResponse;
  const props: UseAPILazyProps<Res> = {
    url: '',
    kind: 'get',
    options,
    header: {}
  };
  const api = useApiLazy<Res>(props);
  const call = async (params: GetStoreFinderRequest) => {
    const url = generateQueryParam(
      `${BASE_URL}/getStoreFinderBranches`,
      params
    );
    return await api.call({ ...props, url });
  };
  return { ...api, call };
}

// 🟦 GET /branches/getChangeBranch - Get nearby branches
export function useApiGetNearbyBranches(
  options: APIOptions<GetNearbyBranchesResponse> = {}
) {
  const props: UseAPILazyProps<GetNearbyBranchesResponse> = {
    url: '',
    kind: 'get',
    options,
    header: {}
  };
  const api = useApiLazyAuth<GetNearbyBranchesResponse>(props);
  const call = async (body: GetNearbyBranchesRequest) =>
    await api.call({ ...props, url: `${BASE_URL}/getChangeBranch`, body });
  return { ...api, call };
}
