import { createContext, useContext } from 'react';

import { WrapperProps } from '@reece/global-types';
import { Maybe, Category, useProductCategoriesQuery } from 'generated/graphql';
import { useDomainInfo } from 'hooks/useDomainInfo';

/**
 * Types
 */
export type Categories = Maybe<Maybe<Category>[]>;
export type CategoriesContextType = {
  categories?: Categories;
  categoriesLoading: boolean;
};

/**
 * Context
 */
export const CategoriesContext = createContext({
  categoriesLoading: false
} as CategoriesContextType);
export const useCategoriesContext = () => useContext(CategoriesContext);

/**
 * Provider
 */
function CategoriesProvider(props: WrapperProps) {
  /**
   * Data
   */
  const { engine } = useDomainInfo();
  const { data: categoriesData, loading: categoriesLoading } =
    useProductCategoriesQuery({ skip: !engine, variables: { engine } });

  /**
   * Render
   */
  return (
    <CategoriesContext.Provider
      value={{
        categories: categoriesData?.productCategories?.categories as Categories,
        categoriesLoading
      }}
    >
      {props.children}
    </CategoriesContext.Provider>
  );
}

export default CategoriesProvider;
