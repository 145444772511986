// istanbul ignore file
import { useTranslation } from 'react-i18next';

import { Link } from 'components';
import searchResultsIcon from 'images/search-results-icon.svg';

/**
 * Types
 */
type Props = {
  searchTerm: string;
};

/**
 * Component
 */
function SearchNoResults(props: Props) {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();

  /**
   * Render
   */
  return (
    <div
      className="flex flex-col flex-1 py-8 bg-common-white"
      data-testid="search-noresults-wrapper"
    >
      <div className="mx-auto max-w-7xl">
        <div className="box-border flex-row flex-wrap w-full m-0">
          <span className="text-xl font-bold pl-8">{`${props.searchTerm} `}</span>
          <span className="text-xl font-bold">
            {t('search.returnedZeroResults')}
          </span>
        </div>
        <div className="w-full mt-20 mb-24 box-border flex flex-wrap flex-col items-center justify-center">
          <img
            src={searchResultsIcon}
            alt="search icon"
            className="justify-self-center pb-12"
          />
          <div className="mx-4 max-w-[388px] h-[391px] items-center justify-center px-1">
            <p
              className="text-secondary-2-100 text-xl font-bold pb-1.5 pr-2.5"
              data-testid="zero-results-message"
            >
              {t('search.zeroResults')}
            </p>
            <div className="text-base">
              <ul className="list-disc ml-4">
                <li>{t('search.searchSuggestionsHint1')}</li>
                <li>{t('search.searchSuggestionsHint2')}</li>
                <li>{t('search.searchSuggestionsHint3')}</li>
                <li>
                  <span>{t('search.searchSuggestionsHint4')}</span>
                  <Link to="/location-search">{t('search.contactBranch')}</Link>
                  <span>{` ${t('search.forSpecialOrders')}`}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SearchNoResults;
