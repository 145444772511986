import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FeaturedItems } from 'pages/BrandPages/BigDog/lib/featuredItems';
import { Image, Link } from 'components';

/**
 * Component
 */
function BigDogFeaturedItems() {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();

  /**
   * Render
   */
  return (
    <section
      className="mt-[116px] md:mt-2.5"
      data-testid="bigdog-section-featured"
    >
      {/* Divider */}
      <Divider
        flexItem
        className="w-full !h-4 !bg-common-white !bg-gradient-to-r !from-etc-big-dog-orange !to-etc-big-dog-orange/10"
      />
      {/* Title */}
      <h1 className="h-[59px] mt-[61px] ml-[57px] text-common-black text-5xl leading-[1.5] font-extrabold font-gilroy md:h-[39px] md:ml-0 md:text-[32px] md:text-center">
        {t('bigDogMarketing.featuredItemsTitle')}
      </h1>
      {/* List */}
      <div className="flex flex-wrap justify-center gap-10 bg-common-white md:mt-6 md:flex-col md:items-center md:gap-2">
        {FeaturedItems.map((featuredItem, index) => (
          <Link to={featuredItem.url} key={`bigdog-item-${index}`}>
            <div className="h-[250px] flex items-center justify-center md:h-full">
              <Image alt={featuredItem.title} src={featuredItem.image} />
            </div>
            <h3 className="text-common-black text-2xl leading-[1.2] font-extrabold font-metropolis text-center md:text-lg">
              {featuredItem.title}
            </h3>
          </Link>
        ))}
      </div>
    </section>
  );
}

export default BigDogFeaturedItems;
