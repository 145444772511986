import { useState } from 'react';

import { MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { GetOrdersResponse, Order } from 'API/types/orders.types';
import { Button, DropdownButton, Pagination } from 'components';
import { MoreActions } from 'icons';
import OrderSummaryCard from 'pages/Orders/components/OrderSummaryCard';

/**
 * Types
 */
export type OrdersMobileRendererProps = {
  orders: Order[];
  getOrdersResponse?: GetOrdersResponse;
  loading?: boolean;
  page?: string;
  handlePageChange?: (page: string) => void;
  onExportClicked?: () => void;
};

/**
 * Component
 */
function OrdersMobileRenderer(props: OrdersMobileRendererProps) {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();

  /**
   * State
   */
  const [currentPage, setCurrentPage] = useState(parseInt(props?.page ?? '1'));

  /**
   * Callbacks
   */
  // 🟤 Cb - Menu item - Export
  const handleExport = (setOpen: (open: boolean) => void) => () => {
    props?.onExportClicked?.();
    setOpen(false);
  };
  // 🟤 Cb - Current page
  const handleCurrentPage = (page: number) => {
    setCurrentPage(page);
    props?.handlePageChange?.(page.toString());
    window.scrollTo(0, 0);
  };

  /**
   * Render
   */
  return (
    <>
      <div className="grid grid-cols-2 pb-2">
        <span className="flex items-center">
          {`${props.getOrdersResponse?.totalItems ?? 0} ${t('common.result', {
            count: props.getOrdersResponse?.totalItems ?? 0
          })}`}
        </span>
        <span className="flex items-end !justify-end">
          <DropdownButton
            testId="orders-more-actions"
            className="!px-2 !min-w-0 whitespace-nowrap"
            content={(_, setOpen) => (
              <MenuItem onClick={handleExport(setOpen)}>
                <Button
                  data-testid="orders-more-actions-export-button"
                  color="gray"
                  kind="text"
                  size="sm"
                >
                  {t('common.exportToExcel')}
                </Button>
              </MenuItem>
            )}
            transformOrigin={{ vertical: 'top', horizontal: 140 }}
            hideLastIcon
          >
            <MoreActions className="!w-6 !h-6" />
          </DropdownButton>
        </span>
      </div>
      <div className="grid grid-cols-1 gap-4">
        {props.orders?.length ? (
          props.orders?.map((data, key) => (
            <OrderSummaryCard content={data} key={data.orderNumber + key} />
          ))
        ) : (
          <h4
            className="text-secondary-2-100 text-base px-1 py-4"
            data-testid="no-orders-message"
          >
            {t('orders.noOrdersMessage')}
          </h4>
        )}
      </div>
      {Boolean(props.getOrdersResponse?.totalItems) && (
        <div className="ml-auto py-4 flex justify-center">
          <Pagination
            current={currentPage}
            ofText={t('common.of')}
            count={props.getOrdersResponse?.totalPages ?? 1}
            onChange={handleCurrentPage}
            onNext={handleCurrentPage}
            onPrev={handleCurrentPage}
            align="center"
            testId="pagination-nav"
          />
        </div>
      )}
    </>
  );
}
export default OrdersMobileRenderer;
