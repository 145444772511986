import { BuilderComponent } from '@builder.io/react';

import { isUndefined } from 'lodash-es';

import { Loader } from 'components';
import Home from 'Home';
import useBuilderIOAPI from 'pages/BuilderIO/lib/useBuilderIOAPI';

/**
 * Component
 */
function BuilderIOCategories() {
  const path = window.location.pathname.replaceAll('%20', '_');

  // 🟣 BuilderIO - page content
  const { content: body, loading: pageLoading } = useBuilderIOAPI(
    'page-category',
    path
  );

  if (pageLoading) {
    return <Loader data-testid="builderio-page-categories-loading" />;
  }

  if (isUndefined(body)) {
    return <Home />;
  }
  return (
    <div data-testid="builderio-page-category">
      <BuilderComponent model="page-category" content={body} />
    </div>
  );
}
export default BuilderIOCategories;
