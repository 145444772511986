import { ArrowBack } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import SelectAccountsForm from 'common/SelectAccounts/sub/SelectAccountsForm';
import { Button, Modal } from 'components';
import useScreenSize from 'hooks/useScreenSize';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';

/**
 * Types
 */
type Props = {
  required: boolean;
  open: boolean;
};

/**
 * Component
 */
function SelectAccounts(props: Props) {
  /**
   * Custom Hooks
   */
  const { push } = useHistory();
  const { isSmallScreen } = useScreenSize();
  const { t } = useTranslation();

  /**
   * Context
   */
  const { setAccountsModalOpen } = useSelectedAccountsContext();

  /**
   * Callbacks
   */
  const closeModal = () => setAccountsModalOpen(false);

  /**
   * Render
   */
  return (
    <Modal
      open={props.open}
      fullScreen={isSmallScreen}
      noClose={props.required}
      onClose={closeModal}
      headerContent={
        props.required ? (
          <Button
            iconStart={<ArrowBack />}
            kind="text"
            onClick={() => push('/logout')}
            data-testid="selectaccountmodal-logout-button"
          >
            {t('common.logOut')}
          </Button>
        ) : (
          <div data-testid="selectaccountmodal-header-empty" />
        )
      }
      testId="selectaccountmodal"
    >
      <div className="pb-4" data-testid="selectaccountmodal-wrapper">
        <SelectAccountsForm onContinue={closeModal} isLogin={props.required} />
      </div>
    </Modal>
  );
}

export default SelectAccounts;
