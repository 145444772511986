import { Fragment, useMemo, useState } from 'react';
import { kebabCase } from 'lodash';
import { omit } from 'lodash-es';

import {
  Box,
  Button,
  Card,
  Grid,
  Skeleton,
  Typography,
  useScreenSize
} from '@dialexa/reece-component-library';
import { useTranslation } from 'react-i18next';

import { Invoice, ProofOfDeliveryResponse } from 'generated/graphql';
import { checkStatus } from 'utils/statusMapping';
import { handleAddress } from './util';
import ProofOfDeliveryModal from 'Invoice/ProofOfDeliveryModal';

type Props = {
  loading: boolean;
  invoice?: Invoice;
  pod?: ProofOfDeliveryResponse;
  podLoading?: boolean;
};

function DeliverySummaryCard(props: Props) {
  /**
   * Custom hooks
   */
  const { isSmallScreen } = useScreenSize();
  const { t } = useTranslation();

  /**
   * State
   */
  const [proofOfDeliveryModalOpen, setProofOfDeliveryModalOpen] =
    useState(false);

  /**
   * Memo
   */
  const summaryValues = useMemo(summaryValuesMemo, [props, t]);

  /**
   * Callbacks
   */
  const handleProofOfDeliveryModalOpen = (open: boolean) => () =>
    setProofOfDeliveryModalOpen(open);

  return (
    <>
      <ProofOfDeliveryModal
        open={proofOfDeliveryModalOpen}
        onClose={handleProofOfDeliveryModalOpen(false)}
        invoice={props?.invoice}
        podLoading={props?.podLoading}
        pod={props?.pod}
        data-testid="pod-modal"
      />
      <Card sx={{ p: isSmallScreen ? 3 : 4, height: 1 }}>
        <Grid container spacing={4}>
          <Grid item md={7}>
            <Box pb={3}>
              <Typography color="primary" variant="h5">
                {t('common.deliverySummary')}
              </Typography>
            </Box>
          </Grid>
          {Boolean(props?.pod) && (
            <Grid item md={5}>
              <Box>
                <Button
                  data-testid="pod-button"
                  variant="secondary"
                  onClick={handleProofOfDeliveryModalOpen(true)}
                  fullWidth
                >
                  {t('invoice.proofOfDelivery')}
                </Button>
              </Box>
            </Grid>
          )}
          <Grid item md={8}>
            <Grid container spacing={4}>
              {summaryValues.map((v) => (
                <Fragment key={v.key}>
                  <Grid item xs={5}>
                    <Typography fontWeight={500} whiteSpace="nowrap">
                      {v.key}
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    {props.loading ? (
                      <Typography>
                        <Skeleton />
                      </Typography>
                    ) : (
                      <Typography
                        data-testid={`delivery-summary-${kebabCase(v.key)}`}
                      >
                        {v.value}
                      </Typography>
                    )}
                  </Grid>
                </Fragment>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  );

  function summaryValuesMemo() {
    const address = omit(props?.invoice?.address, '__typename');

    return [
      {
        key: t('invoice.shippedTo'),
        value: handleAddress(address)
      },
      {
        key: t('common.shipDate'),
        value: props?.invoice?.shipDate ?? '-'
      },
      {
        key: t('common.deliveryMethod'),
        value: props?.invoice?.deliveryMethod
          ? checkStatus(props.invoice.deliveryMethod)
            ? t(`common.${checkStatus(props.invoice.deliveryMethod)}`)
            : t('common.delivery')
          : '-'
      }
    ];
  }
}

export default DeliverySummaryCard;
