import theme from 'providers/libs/Theme/baseTheme';

const muiTabs = {
  root: {
    flex: '1 0 auto',
    minHeight: 'auto',
    marginBottom: theme.spacing(3)
  }
};

export default muiTabs;
