import { useMemo } from 'react';

import { kebabCase } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { Branch } from 'API/types/branches.types';
import { useBranchContext } from 'providers/BranchProvider';

/**
 * Hook
 */
export function useBranchCardData(branch?: Branch) {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();

  /**
   * Context
   */
  const { homeBranch, shippingBranch } = useBranchContext();

  /**
   * Memo
   */
  // 🔵 memo - branch card data abstraction
  return useMemo(() => {
    // base
    const address1 = branch?.address1 || `(${t('branch.noAddress')})`;
    const address2 = branch?.address2 ? `, ${branch?.address2}` : '';
    const city = branch?.city || `(${t('branch.noCity')})`;
    const state = branch?.state || `(${t('branch.noState')})`;
    const zip = branch?.zip ?? '';
    // for distance, BE returns either null distance, or complete string "X.X miles"
    // max miles is hardcoded to 400 in BE
    const miles = branch?.distance;
    // final
    const name = branch?.name || `(${t('branch.noName')})`;
    const address = address1 + address2;
    const cityStateZip = `${city}, ${state} ${zip}`;
    const hours = branch?.businessHours?.split(';') ?? [];
    const distance = miles ?? `${t('common.na')} ${t('common.miles')}`;
    const isHomeBranch = homeBranch?.branchId === branch?.branchId;
    const isShippingBranch = shippingBranch?.branchId === branch?.branchId;
    const testId = kebabCase(branch?.branchId ?? '');
    // output
    return {
      name,
      address,
      cityStateZip,
      hours,
      distance,
      isHomeBranch,
      isShippingBranch,
      testId
    };
  }, [
    branch?.address1,
    branch?.address2,
    branch?.branchId,
    branch?.businessHours,
    branch?.city,
    branch?.distance,
    branch?.name,
    branch?.state,
    branch?.zip,
    homeBranch?.branchId,
    shippingBranch?.branchId,
    t
  ]);
}
