import { useState } from 'react';

import { Checkbox, FormControlLabel, Hidden } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Button, Link, TextInput } from 'components';
import { useSubscribeToEmailsMutation } from 'generated/graphql';

/**
 * Config
 */
export const SUCCESS_RESPONSE = 'Subscribed to Email Succesfully!';

/**
 * Component
 */
function FooterConnect() {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();

  /**
   * States
   */
  const [confirmed, setConfirmed] = useState(false);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [warning, setWarning] = useState(false);

  /**
   * Data
   */
  // 🟣 Mutation - submit email to subscribe
  const [subscribeToEmailsMutation] = useSubscribeToEmailsMutation();

  /**
   * Callbacks
   */
  const handleSubmit = async () => {
    // Warning
    if (!confirmed) {
      setWarning(true);
      return;
    }
    setWarning(false);
    setLoading(true);
    // API
    const variables = { subscribeToEmailsInput: { subscriberEmail: email } };
    const { data } = await subscribeToEmailsMutation({ variables });
    // success
    setLoading(false);
    if (data?.subscribeToEmails === SUCCESS_RESPONSE) {
      setConfirmed(false);
      setSuccess(true);
      return;
    }
    // error
    console.error({ data });
  };

  /**
   * Render
   */
  return (
    <div className="mr-4 inline-block text-left md:mx-2">
      <h5 className="text-primary-1-100 text-xl leading-8 font-medium ">
        {t('footer.connect')}
      </h5>
      <div className="my-4">
        <div className="flex items-center gap-1">
          <TextInput
            value={email}
            placeholder={t('footer.enterEmail')}
            onChange={(e) => setEmail(e.currentTarget.value)}
            testId="footer-connect-text"
            inputClassName="[&:not(:hover)&:not(:focus)]:!border-primary-1-100"
            disabled={loading}
          />
          <Hidden mdDown>
            <Button
              kind="outline"
              className="!py-3"
              title={t('footer.subscribe')}
              onClick={handleSubmit}
              data-testid="footer-connect-submit-desktop"
              disabled={!email || loading}
            />
          </Hidden>
        </div>
        <div className="pt-6">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                className="!text-primary-1-100"
                data-testid="footer-connect-checkbox"
              />
            }
            className="!items-start"
            onChange={(_, value) => setConfirmed(value)}
            label={
              <span>
                {t('footer.connectAgree')}
                <Link
                  className="text-primary-3-100 underline"
                  to="/terms-of-access"
                >
                  {t('footer.termsAccess')}
                </Link>
                {t('footer.connectAnd')}
                <Link
                  className="text-primary-3-100 underline"
                  to="/privacy-policy"
                >
                  {t('footer.privacy')}
                </Link>
                .
              </span>
            }
            value={confirmed}
          />
        </div>
        {warning && (
          <p
            className="mt-2 ml-7 text-support-2-100 text-base md:ml-0"
            data-testid="footer-connect-warning"
          >
            {t('footer.connectWarning')}
          </p>
        )}
        {success && (
          <p
            className="mt-2 text-primary-1-100 text-base"
            data-testid="footer-connect-success"
          >
            {t('footer.connectSuccess')}
          </p>
        )}
        <Hidden mdUp>
          <Button
            kind="outline"
            fullWidth
            title={t('footer.subscribe')}
            onClick={handleSubmit}
            className="mt-4"
            data-testid="footer-connect-submit-mobile"
          />
        </Hidden>
      </div>
    </div>
  );
}

export default FooterConnect;
