import { useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { pick } from 'lodash';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { useAccountPageContext } from 'pages/Account/AccountPageProvider';

/**
 * Config
 */
const blankFormValue: EditAccountForm = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  phoneTypeId: '',
  email: ''
};
const phoneRegExp = /^\(\d{3}\) \d{3}-\d{4}$/;

/**
 * Types
 */
export type EditAccountForm = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  phoneTypeId: string;
  email: string;
};

/**
 * Hook
 */
function useEditAccountForm() {
  /**
   * Context
   */
  const { userInfo } = useAccountPageContext();

  /**
   * Custom hooks
   */
  const { t } = useTranslation();

  /**
   * Memo
   */
  const defaultValues = useMemo(
    () => pick(userInfo, Object.keys(blankFormValue)),
    [userInfo]
  );

  /**
   * Form
   */
  const form = useForm<EditAccountForm>({
    defaultValues,
    resolver: yupResolver(
      Yup.object({
        firstName: Yup.string().required(t('validation.firstNameRequired')),
        lastName: Yup.string().required(t('validation.lastNameRequired')),
        phoneNumber: Yup.string().matches(phoneRegExp, {
          message: t('validation.phoneNumberInvalid')
        }),
        email: Yup.string()
          .email(t('validation.emailInvalidVerbose'))
          .required(t('validation.emailRequired')),
        phoneTypeId: Yup.string().required()
      })
    ),
    mode: 'onBlur',
    reValidateMode: 'onSubmit'
  });

  /**
   * Output
   */
  const values = form.getValues();
  return { ...form, values, defaultValues };
}

export default useEditAccountForm;
