import theme from 'providers/libs/Theme/baseTheme';

const muiTextFields = {
  root: {
    padding: theme.spacing(1, 0),
    // handles label
    marginTop: theme.spacing(3)
  }
};

export default muiTextFields;
