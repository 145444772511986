import palette from 'providers/libs/Theme/palette';
import theme from 'providers/libs/Theme/baseTheme';

const muiInputLabel = {
  root: {
    color: palette.secondary02.main,
    lineHeight: theme.typography.pxToRem(24),
    marginBottom: theme.spacing(1)
  },
  asterisk: {
    color: palette.error.main
  },
  outlined: {
    '&.MuiInputLabel-marginDense': {
      transform: 'translate(8px, 13px)'
    },
    '&.MuiInputLabel-shrink': {
      transform: 'translate(0, -24px)'
    }
  }
};

export default muiInputLabel;
