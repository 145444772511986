import { Maybe } from 'generated/graphql';
import slugify from 'react-slugify';
import { priceStringToFloat } from 'utils/strings';

export const VALUE_OVER_10MIL = 9999999;

export function checkIfOver10Mil(subTotal: string) {
  return priceStringToFloat(subTotal) > VALUE_OVER_10MIL;
}

export function makeProductSlug(
  brand: Maybe<string> | undefined,
  category: Maybe<string> | undefined
) {
  const brandSlug = `${slugify(brand)}/`;
  const categorySlug = `${slugify(category)}/`;
  return `${brandSlug === '/' ? '' : brandSlug}${
    categorySlug === '/' ? '' : categorySlug
  }`;
}
