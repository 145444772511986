import { TextField } from '@mui/material';
import Card from 'components/Card';
import DayPicker from 'components/DayPicker';

/**
 * Config
 */
const display = {
  title: 'DayPicker'
};
// set dates
const today = new Date();
const fromDate = new Date();
const toDate = new Date();
fromDate.setDate(today.getDate() - 7);
toDate.setDate(today.getDate() + 7);
const range = [fromDate, toDate];

/**
 * Component
 */
function DayPickerPage() {
  return (
    <Card className="my-8 p-6">
      <h5 className="text-2xl font-bold text-secondary-2-100">
        {display.title}
      </h5>
      <hr className="my-4" />
      <div className="my-4 flex justify-center">
        <DayPicker selected={range} />
      </div>
      <hr className="my-4" />
      <div className="my-4 flex justify-center">
        <DayPicker
          selected={range}
          input={(props) => <TextField InputProps={{ ...props }} />}
        />
      </div>
    </Card>
  );
}

export default DayPickerPage;
