/**
 * Types
 */
export type Props = {
  fieldName: string;
  fieldValue: string;
  testId: string;
};

/**
 * Component
 */
function UserInfoText(props: Props) {
  /**
   * Render
   */
  return (
    <div className="w-full flex flex-row gap-4 justify-between px-10 py-5 md:px-6">
      <span className="whitespace-nowrap text-primary-3-100 text-base md:text-sm">
        {props.fieldName}
      </span>
      <span
        className="min-w-0 truncate text-primary-1-100 font-bold text-base text-right md:text-sm"
        data-testid={props.testId}
      >
        {props.fieldValue}
      </span>
    </div>
  );
}

export default UserInfoText;
