import { useTranslation } from 'react-i18next';

import { useDomainInfo } from 'hooks/useDomainInfo';
/**
 * Types
 */
export type HeaderItem = {
  name: string;
  validPaths: string[];
  path: string;
  visible: boolean;
  selected?: boolean;
};

/**
 * Hook
 */
function useHeaderItems() {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();
  const { isWaterworks } = useDomainInfo();

  /**
   * Output
   */
  const headerItems: HeaderItem[] = [
    {
      name: t('common.contracts'),
      validPaths: ['/contracts', '/contract'],
      path: '/contracts',
      visible: isWaterworks
    },
    {
      name: t('common.invoices'),
      validPaths: ['/invoices', '/invoice'],
      path: '/invoices',
      visible: true
    },
    {
      name: t('common.myOrders'),
      validPaths: ['orders', 'order'],
      path: '/orders',
      visible: true
    },
    {
      name: t('common.lists'),
      validPaths: ['lists'],
      path: '/lists',
      visible: !isWaterworks
    },
    {
      name: t('common.quotes'),
      validPaths: ['quotes', 'quote'],
      path: '/quotes',
      visible: !isWaterworks
    },
    {
      name: t('common.buyAgain'),
      validPaths: ['buyagain', 'buy-again', 'previously-purchased-products'],
      path: '/buyagain',
      visible: !isWaterworks
    },
    {
      name: t('common.approvals'),
      validPaths: ['purchase-approvals'],
      path: '/purchase-approvals',
      visible: !isWaterworks
    }
  ];
  return headerItems;
}
export default useHeaderItems;
