import CircularProgress from 'components/CircularProgress';
import Card from 'components/Card';

/**
 * Config
 */
const display = {
  title: 'CircularProgress'
};

/**
 * Component
 */
function CircularProgressPage() {
  return (
    <Card className="my-8 p-6">
      <h5 className="text-2xl font-bold text-secondary-2-100">
        {display.title}
      </h5>
      <hr className="my-4" />
      <div className="my-4 flex justify-center">
        <CircularProgress size={20} />
        <CircularProgress size={40} />
        <CircularProgress size={60} />
        <CircularProgress size={80} />
        <CircularProgress size={40} color="red" />
        <CircularProgress size={40} color="yellow" />
        <CircularProgress size={40} color="green" />
        <CircularProgress size={40} color="secondary" />
        <CircularProgress size={40} color="primary" />
        <CircularProgress size={40} color="gray" />
        <CircularProgress
          size={40}
          className="[&_.MuiCircularProgress-svg]:text-support-5-100"
        />
      </div>
    </Card>
  );
}

export default CircularProgressPage;
