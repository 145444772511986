import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react';

import { Transition } from '@headlessui/react';
import { WrapperProps } from '@reece/global-types';
import { noop, uniqueId } from 'lodash-es';

import ToastComponent, { ToastConfig, ToastType } from 'components/Toast';

/**
 * Config
 */
const DEFAULT_TOAST_TIMEOUT = 10000;
const MAX_TOASTS = 3;

/**
 * Types
 */
export type ToastContextType = {
  clear: () => void;
  /**
   * Creates a toast
   * @description Creates a new toast
   * @function toast(config)
   * @param {ToastConfig} config Object to be used for toast config
   * @param config.message `ReactNode` *(required)* - MAIN toast message
   * @param config.kind `'info' | 'warn' | 'error' | 'success'` [default: 'info'] - toast icon/color variants
   * @param config.timer `number` [default: DEFAULT_TOAST_TIMEOUT] - how long in ms the toast disappears (use 0 for no timeout)
   * @param config.size `'sm' | 'lg'` [default: 'sm'] - width of the toast
   * @param config.hasClose `boolean` [default: false] - Show close button
   * @param config.onClose `() => void` - function to be triggered when closing the toast (hasClose MUST be enabled)
   * @param config.button `ToastButton` - button on the right side of toast
   * @param config.button.display `ReactNode` *(required)*- button content
   * @param config.button.noClose `boolean` [default: false] - doesn't close the toast when clicked when true
   * @param config.button.action `() => void` - function to be triggered when toast button is clicked
   * @example toast({ message: 'hello world', kind: 'success' })
   */
  toast: (config: ToastConfig) => void;
  toasts: ToastType[];
};

/**
 * Context
 */
export const defaultToastContext: ToastContextType = {
  clear: noop,
  toast: noop,
  toasts: []
};
export const ToastContext = createContext(defaultToastContext);
export const useToastContext = () => useContext(ToastContext);

/**
 * Provider
 */
function ToastProvider({ children }: WrapperProps) {
  /**
   * States
   */
  const [toasts, setToasts] = useState<ToastType[]>([]);

  /**
   * Refs
   */
  const toastsRef = useRef(toasts);
  toastsRef.current = toasts;

  /**
   * Callbacks
   */
  // 🟤 Cb - Adjust toast visibility by id (used by transition)
  const hideById = (hideId: string, hide = true) => {
    const toasts = toastsRef.current;
    const affectedIndex = toasts.findIndex(({ id }) => id === hideId);
    const mutableToasts = [...toastsRef.current];
    affectedIndex > -1 && (mutableToasts[affectedIndex].hide = hide);
    setToasts(mutableToasts);
  };
  // 🟤 Cb - Delete toast by toast id
  const deleteById = (deleteId: string) => {
    const filtered = toastsRef.current.filter(({ id }) => id !== deleteId);
    setToasts(filtered);
  };
  // 🟤 Cb - Remove all toasts
  const clear = () => setToasts([]);
  // 🟤 Cb - Create new toast
  const toast = useCallback((config: ToastConfig) => {
    // Unique toast id for closeing
    const id = uniqueId('toast_');
    // Build new data model out of config
    const newToast: ToastType = { ...config, id, hide: true };
    // Putting a 1ms timeout to patch transition animation
    setTimeout(() => hideById(id, false), 1);
    // onClose also closes the toast itself
    if (config.hasClose || config.onClose) {
      newToast.onClose = () => {
        config.onClose?.();
        hideById(id);
      };
    }
    // Apply close toast to button if noClose is false
    if (config.button && !config.button?.noClose) {
      const action = () => {
        config.button?.action();
        hideById(id);
      };
      newToast.button = { ...config.button, action };
    }
    // Add toast to list
    const startPosition = Math.min(toast.length - MAX_TOASTS, 0);
    const oldToasts = [...toastsRef.current].slice(startPosition);
    setToasts([...oldToasts, newToast]);
    // Apply toast timer if declared
    const timer = newToast.timer ?? DEFAULT_TOAST_TIMEOUT;
    if (timer) {
      setTimeout(() => hideById(id), timer);
    }
  }, []);

  /**
   * Memo
   */
  const value = useMemo<ToastContextType>(
    () => ({ clear, toast, toasts }),
    [toast, toasts]
  );

  /**
   * Render
   */
  return (
    <ToastContext.Provider value={value}>
      {children}
      <div className="fixed top-0 left-[50%] translate-x-[-50%] flex flex-col items-center gap-2 py-2 z-[9999]">
        {toasts.map((item) => (
          <Transition
            appear
            unmount={false}
            show={!item.hide}
            as="div"
            enter="transition-opacity ease-linear duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            onAnimationEnd={() => item.hide && deleteById(item.id)}
            key={item.id}
          >
            <ToastComponent toast={item} />
          </Transition>
        ))}
      </div>
    </ToastContext.Provider>
  );
}

export default ToastProvider;
