import { APIOptions } from 'API/hooks/useApiBase';
import { UseAPILazyProps } from 'API/hooks/useApiLazy';
import { useApiLazyAuth, UseAPILazyAuthProps } from 'API/hooks/useApiLazyAuth';
import { useApiLazy } from 'API/hooks/useApiLazy';
import {
  SearchRequest,
  SearchResponse,
  SearchSuggestionRequest
} from 'API/types/search.types';

/**
 * Config
 */
const BASE_URL = 'search';

/**
 * Hooks
 */
// 🟩 POST /search - Main search API
export function useApiSearch(options: APIOptions<SearchResponse> = {}) {
  const props: UseAPILazyAuthProps<SearchResponse, SearchRequest> = {
    url: '',
    kind: 'post',
    options,
    optionalAuth: true,
    header: {}
  };
  const api = useApiLazyAuth<SearchResponse, SearchRequest>(props);
  const call = (body: SearchRequest) =>
    api.call({ ...props, url: BASE_URL, body });
  return { ...api, call };
}

// 🟩 POST /search/suggestions - Search suggestions
// 🔶 PLACEHOLDER - Update this when we get to search suggestions in header -Arthur G
export function useApiSearchSuggestions(
  options: APIOptions<SearchResponse> = {}
) {
  const props: UseAPILazyProps<SearchResponse, SearchSuggestionRequest> = {
    url: '',
    kind: 'post',
    options,
    header: {}
  };
  const api = useApiLazy<SearchResponse, SearchSuggestionRequest>(props);
  const call = (body: SearchSuggestionRequest) =>
    api.call({ ...props, url: `${BASE_URL}/suggestions`, body });
  return { ...api, call };
}
