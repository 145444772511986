import { alpha } from '@mui/material';

import palette from 'providers/libs/Theme/palette';
import theme from 'providers/libs/Theme/baseTheme';

const muiRadio = {
  root: {
    padding: theme.spacing(0.25),
    marginRight: theme.spacing(2)
  },
  colorPrimary: {
    color: alpha(palette.darkGray.main, 0.3),
    '&.Mui-checked': {
      color: palette.primary02.main
    }
  }
};

export default muiRadio;
