import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

import useDocumentTitle from 'hooks/useDocumentTitle';
import Header from 'pages/Header';
import ListsCreateListButton from 'pages/Lists/sub/ListsCreateListButton';
import ListMainArea from 'pages/Lists/sub/ListMainArea';
import ListsPageProvider from 'pages/Lists/provider/ListsPageProvider';
import ListsSelector from 'pages/Lists/sub/ListsSelector';

/**
 * Components
 */
function Lists() {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();
  useDocumentTitle(t('common.lists'));

  /**
   * Render
   */
  return (
    <ListsPageProvider>
      <Header pageTitle={t('common.myLists')}>
        <ListsCreateListButton />
      </Header>
      <div className="container bg-common-white mx-auto !px-0 !my-0">
        <Container
          className="h-full !flex flex-nowrap items-stretch !rounded-none md:!px-0"
          maxWidth="lg"
        >
          <ListsSelector />
          <div className="h-full border-secondary-3-100 border-r mr-4 md:hidden" />
          <ListMainArea />
        </Container>
      </div>
    </ListsPageProvider>
  );
}
export default Lists;
