import { Maybe } from 'generated/graphql';

/**
 * injectTestId - Quick function to set up special test-id to reduce test branches
 * @param body - main test id body
 * @param testId - test id that could be falsey
 * @param flipped - if true, return "{testId}-{body}"; if false, return "{body}-{testId}"
 * @returns string
 */
function injectTestId(
  body: string,
  testId?: Maybe<string | number>,
  flipped?: boolean
) {
  return testId ? (flipped ? `${testId}-${body}` : `${body}-${testId}`) : body;
}
export default injectTestId;
