import { useCallback, useEffect, useState } from 'react';

import { AxiosResponse } from 'axios';

import { APIBaseCall, useApiBase, UseAPIBaseProps } from 'API/hooks/useApiBase';
import { Maybe } from 'generated/graphql';

/**
 * Types
 */
export type UseAPIOnMountProps<Res, Req = object> = UseAPIBaseProps<
  Res,
  Req
> & {
  skip?: boolean;
  optionalAuth?: boolean;
};
export type UseAPIOnMountOutput<Res, Req = object> = {
  called: boolean;
  data?: Maybe<Res>;
  refetch: APIOnMountRefetch<Res, Req>;
  loading: boolean;
};
export type APIOnMountRefetchProps<Req = object> = Partial<
  Pick<UseAPIBaseProps<object, Req>, 'header' | 'body' | 'url'>
>;
export type APIOnMountRefetch<Response, Request = object> = (
  props?: APIOnMountRefetchProps<Request>
) => Promise<Maybe<AxiosResponse<Maybe<Response>>>>;

/**
 * Hook
 */
export function useApiOnMount<Response, Request = object>({
  skip,
  ...props
}: UseAPIOnMountProps<Response, Request>): UseAPIOnMountOutput<
  Response,
  Request
> {
  /**
   * API
   */
  const { called, call, ...rest } = useApiBase<Response, Request>(props);

  /**
   * State
   */
  const [data, setData] = useState<Maybe<Response>>();

  /**
   * Callback
   */
  // 🟤 Cb - Call
  const asyncCall: APIBaseCall<Response, Request> = useCallback(
    async (myProps) => {
      const res = await call(myProps);
      setData(res?.data);
      return res;
    },
    [call]
  );
  // 🟤 Cb - Refetch
  const refetch: APIOnMountRefetch<Response, Request> = (myProps) =>
    asyncCall({ ...props, ...myProps });

  /**
   * Effects
   */
  // 🟡 Effect - Automically calls on mount under the right conditions
  useEffect(() => {
    !called && !skip && asyncCall(props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip]);

  /**
   * Output
   */
  return { ...rest, called, data, refetch };
}
