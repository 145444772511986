import {
  Chat as ChatIcon,
  Circle as CircleIcon,
  LightbulbTwoTone as LightbulbTwoToneIcon
} from '@mui/icons-material';
import { IconButton } from '@mui/material';

import Alert from 'components/Alert';
import Card from 'components/Card';
import { componentShowMessage } from 'pages/Components/lib/util';

/**
 * Config
 */
const display = {
  title: 'Alert',
  default: 'Default Type',
  error: 'Error Type',
  info: 'Info Type',
  success: 'Success Type',
  warning: 'Warning Type',
  custom: 'Custom Type',
  debug: 'This is a custom Alert component'
};

/**
 * Component
 */
function AlertPage() {
  return (
    <Card className="my-8 p-6">
      <h5 className="text-2xl font-bold text-secondary-2-100">
        {display.title}
      </h5>
      <hr className="my-4" />
      <div className="my-4">
        <Alert icon={<CircleIcon />}>{display.default}</Alert>
      </div>
      <div className="my-4">
        <Alert severity="error">{display.error}</Alert>
      </div>
      <div className="my-4">
        <Alert severity="info">{display.info}</Alert>
      </div>
      <div className="my-4">
        <Alert severity="success">{display.success}</Alert>
      </div>
      <div className="my-4">
        <Alert severity="warning">{display.warning}</Alert>
      </div>
      <div className="my-4">
        <Alert
          icon={<LightbulbTwoToneIcon />}
          action={
            <IconButton
              color="inherit"
              size="small"
              onClick={componentShowMessage(display.debug)}
            >
              <ChatIcon />
            </IconButton>
          }
          className="!text-support-5-100 !bg-support-5-10 [&_.MuiAlert-icon]:!text-support-5-100"
        >
          {display.custom}
        </Alert>
      </div>
    </Card>
  );
}

export default AlertPage;
