import { subMonths } from 'date-fns';
import { Invoice, Maybe } from 'generated/graphql';
import { Row, TableInstance } from 'react-table';

import { Configuration } from 'utils/configuration';

/**
 * Types
 */
export type Age = typeof AGES[number];
export type InvoiceStatus = typeof invoiceStatus[number];
export type WaterworksInvoiceStatus = typeof waterworksInvoiceStatus[number];
export type InvoiceFilterOption = typeof invoiceFilterOptions[Age];

/**
 * Consts & Config
 */
export const invoiceStatus = ['Open', 'Closed', 'All'] as const;
export const waterworksInvoiceStatus = [
  'All',
  'Paid',
  'Unpaid',
  'Partial Paid'
] as const;
export const ordersStatus = [
  'All',
  'Delivered',
  'In-transit',
  'Order placed',
  'Picked up',
  'Ready for pickup'
] as const;
export const invoiceFilterOptions = {
  Total: 'All',
  Future: 'Future',
  Current: 'Current',
  '31-60': '31-60 days',
  '61-90': '61-90 days',
  '91-120': '91-120 days',
  Over120: '121+ days',
  TotalPastDue: 'All past due'
} as const;
export const AGES = Object.keys(
  invoiceFilterOptions
) as (keyof typeof invoiceFilterOptions)[];
export const ECLIPSE_BILLTRUST = 'http://morsco.billtrust.com/';
export const MINCRON_BILLTRUST = 'http://fortiline.billtrust.com/';
export const SSO_BILLTRUST = Configuration.billTrustURL || '';
export const MAX_ROW_COUNT = 15;
export const MAX_INVOICE_AGE = 360;
export const emptyDateRange = {
  from: undefined,
  to: undefined
};
export const defaultTimeRange = {
  from: subMonths(new Date(), 1),
  to: new Date()
};

/**
 * Functions
 */
export function sortAge(
  rowA: Row<Invoice>,
  rowB: Row<Invoice>,
  columnId: string
) {
  const a = AGES.indexOf(rowA.values[columnId]);
  const b = AGES.indexOf(rowB.values[columnId]);

  return a === b ? 0 : a > b ? 1 : -1;
}

type HandleDownloadClickedCbProps = {
  erpAccountId?: Maybe<string>;
  tableInstance: TableInstance<Invoice>;
  erpName: string;
  getInvoicesUrl: Function;
};
export function handleDownloadClickedCb({
  erpAccountId,
  getInvoicesUrl,
  erpName,
  tableInstance
}: HandleDownloadClickedCbProps) {
  const invoiceNumbers = tableInstance.selectedFlatRows.map((r) => {
    const row = r.original;
    return row.invoiceNumber;
  });

  getInvoicesUrl({
    variables: {
      accountId: erpAccountId ?? '',
      invoiceNumbers,
      erpName
    }
  });
}
