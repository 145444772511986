import palette from 'providers/libs/Theme/palette';
import theme from 'providers/libs/Theme/baseTheme';

const muiTableContainer = {
  root: {
    '.MuiCard-root &': {
      padding: theme.spacing(0, 2.5),
      '& tbody tr th': {
        color: palette.primary.main
      }
    }
  }
};

export default muiTableContainer;
