import { CheckIcon, DeleteIcon } from 'icons';

/**
 * Types
 */
type FlagCellProps = {
  value: boolean;
};

/**
 * Component
 */
function FlagCell(props: FlagCellProps) {
  if (props.value) {
    return <CheckIcon className="!text-success-100" height={16} width={16} />;
  }

  return <DeleteIcon className="!text-support-1-100" height={16} width={16} />;
}

export default FlagCell;
