export const wrapperStyles = {
  left: 'justify-start',
  center: 'justify-center',
  right: 'justify-end'
};
export const buttonStyle =
  'w-8 h-8 min-w-[auto] !p-0 disabled:bg-secondary-3-100 disabled:text-common-white';
export const inputStyle = [
  'h-8 !py-0 !p-[8.5px] !w-min rounded-none',
  'text-center font-medium !text-primary-1-100',
  'disabled:bg-common-white'
];
export const textStyle = [
  'h-8 flex justify-center items-center',
  'text-xl leading-6 tracking-[0.0075em] text-primary-1-100'
];
