import { cloneElement, ReactNode } from 'react';

import { Tooltip, TooltipProps } from '@mui/material';
import clsx from 'clsx';

import { HTMLDivProps, Modify } from '@reece/global-types';

/**
 * Styles
 */
const tooltipStyle = [
  '!py-2.5 !text-xs !bg-primary-3-100',
  'sm:!text-sm sm:!max-w-[400px] sm:!mb-10 sm:!px-5 sm:!py-4'
];

/**
 * Types
 */
type NewProps = {
  title: string;
  text: string;
  icon: JSX.Element;
  placement?: TooltipProps['placement'];
  children: ReactNode;
  activated?: boolean;
  testId?: string;
  disabled?: boolean;
  wrapperClassName?: string;
};
export type AdvancedToolTipProps = Modify<TooltipProps, NewProps>;

/**
 * Component
 */
function AdvancedToolTip(props: AdvancedToolTipProps) {
  /**
   * Props
   */
  const {
    activated,
    children,
    disabled,
    icon,
    placement,
    testId,
    text,
    title,
    wrapperClassName,
    ...rest
  } = props;

  /**
   * Render
   */
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          className: clsx(tooltipStyle),
          'data-testid': testId && `${testId}_tooltip`
        } as HTMLDivProps
      }}
      title={
        !disabled ? (
          <div
            className="flex justify-between items-center gap-3"
            data-testid={testId && `${testId}_popup`}
          >
            <div className="w-[10%]">
              {cloneElement(icon, {
                className: 'w-4 h-4 screen-md:w-auto screen-md:h-auto'
              })}
            </div>
            <div className="w-[90%]">
              <div>
                <span className="font-bold">{title}: </span>
                <span>{text}</span>
              </div>
            </div>
          </div>
        ) : (
          ''
        )
      }
      open={activated}
      placement={placement}
      data-testid={testId}
      {...rest}
    >
      <div className={wrapperClassName}>{children}</div>
    </Tooltip>
  );
}

export default AdvancedToolTip;
