import clsx from 'clsx';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from 'react-router-dom';

import { Modify } from '@reece/global-types';

// ⛔️ Do not use this for external links! Use <a> instead

/**
 * Styles
 */
const linkStyle = 'text-primary-2-100 cursor-pointer select-none';

/**
 * Types
 */
type NewProps = {
  testId?: string;
};
export type LinkProps = Modify<RouterLinkProps, NewProps>;

/**
 * Component
 */
function Link(props: LinkProps) {
  /**
   * Props
   */
  const { className, testId, ...rest } = props;

  /**
   * Render
   */
  return (
    <RouterLink
      {...rest}
      data-testid={testId}
      className={clsx(className, linkStyle)}
    >
      {props.children}
    </RouterLink>
  );
}

export default Link;
