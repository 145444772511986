import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { AlertColor, Snackbar } from '@mui/material';
import { noop, uniqueId } from 'lodash-es';

import { WrapperProps } from '@reece/global-types';
import Alert from 'components/Alert';
import useScreenSize from 'hooks/useScreenSize';

/**
 * Types
 */
type MessageOptions = {
  variant?: AlertColor;
};
type SnackbarQueueMessage = {
  id: string;
  message: string;
  timestamp: number;
  options?: MessageOptions;
};
export type SnackbarContextType = {
  queue: SnackbarQueueMessage[];
  pushAlert: (message: string, options?: MessageOptions) => void;
};

/**
 * Context
 */
export const defaultSnackbarContext: SnackbarContextType = {
  queue: [],
  pushAlert: noop
};
export const SnackbarContext = createContext<SnackbarContextType>(
  defaultSnackbarContext
);
export function useSnackbar() {
  return useContext(SnackbarContext);
}

/**
 * Provider
 */
function SnackbarProvider(props: WrapperProps) {
  /**
   * Custom hooks
   */
  const { isSmallScreen } = useScreenSize();

  /**
   * States
   */
  const [queue, setQueue] = useState<SnackbarQueueMessage[]>([]);

  /**
   * Memo
   */
  // 🔵 memo - list display orientation
  // Reverse if displaying on top
  const alerts = useMemo(
    () => (isSmallScreen ? queue.slice(0, 3) : queue.slice(0, 3).reverse()),
    [isSmallScreen, queue]
  );

  /**
   * Callbacks
   */
  // 🟤 cb - create new snackbar message
  const pushAlert = (message: string, options?: MessageOptions) => {
    const newItem = { id: uniqueId(), message, timestamp: Date.now(), options };
    setQueue([...queue, newItem]);
  };

  /**
   * Effects
   */
  // 🟡 effect - handle toast timing and list
  useEffect(() => {
    if (queue.length) {
      const timeout = setTimeout(() => {
        setQueue(
          queue.slice(1).map((a, i) => ({
            ...a,
            // reset the timer for anything that hasn't displayed yet
            // this will ensure all messages show for at least 5 secs
            timestamp: i > 1 ? Date.now() : a.timestamp
          }))
        );
      }, 5000 - (Date.now() - queue[0].timestamp));

      return () => clearTimeout(timeout);
    }
  }, [queue, setQueue]);

  /**
   * Render
   */
  return (
    <SnackbarContext.Provider value={{ queue, pushAlert }}>
      {props.children}
      <Snackbar
        open={Boolean(queue.length)}
        anchorOrigin={{
          horizontal: 'center',
          vertical: isSmallScreen ? 'bottom' : 'top'
        }}
      >
        {/* Implementing blank <div> to fix the MUI bug related to "scrollTop"  */}
        <div>
          {alerts.map((a) => (
            <div className="p-2" key={a.id}>
              <Alert
                severity={a?.options?.variant ?? 'success'}
                className="!shadow-md"
              >
                {a.message}
              </Alert>
            </div>
          ))}
        </div>
      </Snackbar>
    </SnackbarContext.Provider>
  );
}

export default SnackbarProvider;
