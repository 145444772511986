import { Skeleton } from '@mui/material';
import clsx from 'clsx';
import {
  baseStyle,
  baseColorStyles,
  containedColorStyles,
  containedStyle,
  svgStyle
} from 'components/InfoChip/styles';

/**
 * Styles
 */

/**
 * Types
 */
type InfoChipColors = 'primary' | 'green' | 'default';
export type InfoChipProps = {
  contained?: boolean;
  color?: InfoChipColors;
  text: string;
  icon: JSX.Element;
  loading?: boolean;
  loadingClassName?: string;
  onClick?: () => void;
  testId?: string;
};

/**
 * Component
 */
function InfoChip(props: InfoChipProps) {
  /**
   * Props
   */
  const {
    icon,
    text,
    contained,
    color = 'default',
    loading,
    loadingClassName,
    onClick,
    testId
  } = props;

  /**
   * Render
   */
  if (loading) {
    return (
      <Skeleton
        variant="rectangular"
        className={clsx(loadingClassName, 'w-full !h-6 rounded-full')}
        data-testid={`${testId}-loading`}
      />
    );
  }
  return (
    <div
      onClick={onClick}
      title={text}
      className={clsx(baseStyle, svgStyle, baseColorStyles[color], {
        [containedStyle]: contained,
        [containedColorStyles[color]]: contained
      })}
      data-testid={testId}
    >
      {icon}
      <span className="pl-1 whitespace-nowrap" data-testid={`${testId}-text`}>
        {text}
      </span>
    </div>
  );
}

export default InfoChip;
