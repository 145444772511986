import { useLocation } from 'react-router-dom';

import Header from 'common/Header';
import OldHeader from 'common/OldHeader';
import CartHeader from 'common/Header/CartHeader';
import { useAuthContext } from 'providers/AuthProvider';

/**
 * Component
 */
function HeaderSelector() {
  /**
   * Context
   */
  const { activeFeatures } = useAuthContext();
  const location = useLocation();

  // Cart Header
  if (location.pathname.includes('checkout')) {
    return <CartHeader />;
  }

  // General Header
  if (activeFeatures?.includes('NEW_HEADER')) {
    return <Header />;
  }
  return <OldHeader />;
}

export default HeaderSelector;
