import { ReactNode } from 'react';

import clsx from 'clsx';

import { WarningIcon, InfoIcon, Checkmark, CloseIcon } from 'icons';

/**
 * Types
 */
export type ToastKind = 'info' | 'success' | 'warn' | 'error';
export type ToastSize = 'sm' | 'lg';
export type ToastButton = {
  display: ReactNode;
  noClose?: boolean;
  action: () => void;
};
/** @type {ToastConfig} */
export type ToastConfig = {
  button?: ToastButton;
  kind?: ToastKind;
  timer?: number;
  message: ReactNode;
  hasClose?: boolean;
  size?: ToastSize;
  onClose?: () => void;
};
export type ToastType = ToastConfig & {
  id: string;
  hide: boolean;
};
export type ToastComponentProps = {
  toast: ToastType;
};
type ToastKindStyle = {
  icon: ReactNode;
  text: string;
  accent: string;
  bg: string;
};

/**
 * Kind
 */
const iconStyles = { width: 32, height: 32, className: 'shrink-0' };
export const styles: { [key in ToastKind]: ToastKindStyle } = {
  error: {
    icon: <WarningIcon {...iconStyles} />,
    text: 'text-support-1-100 [&_a]:!text-support-1-100',
    accent: 'text-support-1-100',
    bg: 'bg-error-10'
  },
  success: {
    icon: <Checkmark {...iconStyles} widths={28} height={28} />,
    text: 'text-primary-3-100 [&_a]:!text-success-100',
    accent: 'text-success-100',
    bg: 'bg-success-10'
  },
  warn: {
    icon: <WarningIcon {...iconStyles} />,
    text: 'text-primary-3-100 [&_a]:!text-support-2-100',
    accent: 'text-support-2-100',
    bg: 'bg-warning-20'
  },
  info: {
    icon: <InfoIcon {...iconStyles} />,
    text: 'text-primary-2-100 [&_a]:!text-primary-2-100',
    accent: 'text-primary-2-100',
    bg: 'bg-primary-2-10'
  }
};

/**
 * Component
 */
function Toast({ toast }: ToastComponentProps) {
  /**
   * Config
   */
  const { kind = 'info' } = toast;

  /**
   * Render
   */
  return (
    <div
      data-testid={`toast-${toast.id}`}
      className={clsx(
        'flex items-center gap-2 px-4 py-2 border-[1px] rounded drop-shadow-lg max-w-[calc(100vw-2rem)] select-none [&_a]:font-medium',
        styles[kind].text,
        styles[kind].bg,
        { 'w-[900px]': toast.size === 'lg' },
        { 'w-[480px]': toast.size !== 'lg' }
      )}
    >
      <span
        className={clsx(
          'w-8 h-8 flex items-center justify-center',
          styles[kind].accent
        )}
      >
        {styles[kind].icon}
      </span>
      <span className="flex-1">{toast.message}</span>
      {Boolean(toast.button) && (
        <>
          <button
            onClick={toast.button!.action}
            className={clsx('text-base font-medium', styles[kind].accent)}
            data-testid={`toast-${toast.id}-button`}
          >
            {toast.button!.display}
          </button>
        </>
      )}
      {Boolean(toast.hasClose) && (
        <span className="pl-2 flex justify-end items-center">
          <div
            className={clsx('h-6 mr-4 bg-transparent', styles[kind].accent, {
              'border-r-[1px]': toast.button
            })}
          />
          <button
            onClick={toast.onClose}
            data-testid={`toast-${toast.id}-close`}
          >
            <CloseIcon className={styles[kind].accent} />
          </button>
        </span>
      )}
    </div>
  );
}

export default Toast;
