import { Fragment, useMemo } from 'react';

import {
  Divider,
  Hidden,
  List,
  ListItem,
  ListItemText,
  Skeleton
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import SearchTopResult from 'common/OldHeader/sub/SearchTopResult';
import { SearchSuggestionResult } from 'generated/graphql';
import escapeRegex from 'utils/escapeRegex';

/**
 * Types
 */
export type SearchSuggestionsProps = {
  loading: boolean;
  searchTerm: string;
  onSuggestionClick: (suggestion: string, category?: string | null) => void;
  onResultClick: (id: string, productSlug: string) => void;
  results?: SearchSuggestionResult;
};

/**
 * Component
 */
function SearchSuggestions(props: SearchSuggestionsProps) {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();

  /**
   * Memo
   */
  // 🔵 Memo - suggestion value match RegExp
  const suggestionMatchRegexp = useMemo(
    () => new RegExp(`^${escapeRegex(props.searchTerm.toLowerCase())}`),
    [props.searchTerm]
  );
  // 🔵 Memo - top categories
  const topCategories = useMemo(
    () => props.results?.topCategories.slice(0, 2),
    [props.results?.topCategories]
  );
  // 🔵 Memo - top categories
  const renderSuggestions = useMemo(
    () =>
      props.results?.suggestions?.map((sug, index) => (
        <Fragment key={sug}>
          <ListItem
            className="cursor-pointer text-primary-3-100"
            onClick={() => props.onSuggestionClick(sug)}
            data-testid={`search-suggestion-item-${index}`}
          >
            <ListItemText disableTypography>
              <strong>{suggestionMatchRegexp.exec(sug)?.[0]}</strong>
              {sug.replace(suggestionMatchRegexp, '')}
            </ListItemText>
          </ListItem>
          {index === 0 &&
            topCategories?.map(({ value }) => (
              <ListItem
                className="pl-10 cursor-pointer text-primary-3-100"
                key={`${sug}_${value}-cat`}
                onClick={() => props.onSuggestionClick(sug, value)}
                data-testid={`search-suggestioncategory-item-${index}`}
              >
                <ListItemText disableTypography>
                  in{' '}
                  <span className="text-primary-2-100 font-semibold">
                    {value}
                  </span>
                </ListItemText>
              </ListItem>
            ))}
        </Fragment>
      )),
    [props, suggestionMatchRegexp, topCategories]
  );

  /**
   * Render
   */
  return (
    <div
      className="grid grid-cols-12"
      data-testid="header-searchsuggestions-wrapper"
    >
      {/* Suggestion */}
      <div className="col-span-5 flex md:col-span-12">
        <List dense className="w-full [&_.MuiListItem-dense]:py-1">
          {props.loading
            ? [...Array(10).keys()].map((x) => (
                <ListItem className="pr-12 [&:nth-child(even)]:pr-6" key={x}>
                  <ListItemText>
                    <Skeleton data-testid="search-suggestion-skeleton" />
                  </ListItemText>
                </ListItem>
              ))
            : renderSuggestions}
        </List>
      </div>
      {/* Top Results  */}
      <Hidden mdDown>
        <div className="col-span-7 flex">
          <Divider flexItem orientation="vertical" />
          <div className="px-4 w-full">
            <h5 className="pb-2 text-xl font-semibold">
              {t('search.topResults')}
            </h5>
            {props.loading
              ? [...Array(3).keys()].map((x) => (
                  <SearchTopResult
                    loading
                    key={x}
                    data-testid="search-topresult-skeleton"
                  />
                ))
              : props.results?.topProducts?.map((prod) => (
                  <SearchTopResult
                    product={prod}
                    key={prod.id}
                    onClick={props.onResultClick}
                  />
                ))}
          </div>
        </div>
      </Hidden>
    </div>
  );
}

export default SearchSuggestions;
