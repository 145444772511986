import {
  CircularProgress as MuiCircularProgress,
  CircularProgressProps as MuiCircularProgressProps
} from '@mui/material';

import { Modify } from '@reece/global-types';
import clsx from 'clsx';
import { colorStyles } from 'components/CircularProgress/styles';

/**
 * Props
 */
type NewProps = {
  color?: CircularProgressColor;
  noBack?: boolean;
  testId?: string;
};
export type CircularProgressColor = keyof typeof colorStyles;
export type CircularProgressProps = Modify<MuiCircularProgressProps, NewProps>;

/**
 * Component
 */
function CircularProgress(props: CircularProgressProps) {
  /**
   * Props
   */
  const {
    className,
    color = 'secondary',
    noBack,
    size = 80,
    sx,
    testId,
    thickness = 5,
    ...other
  } = props;

  /**
   * Render
   */
  return (
    <div className="relative flex" data-testid={testId}>
      <MuiCircularProgress
        variant="determinate"
        className={clsx(
          '[&_.MuiCircularProgress-svg]:text-secondary-3-100 [&_.MuiCircularProgress-svg]:w-full [&_.MuiCircularProgress-svg]:h-full',
          { 'opacity-0': noBack }
        )}
        size={size}
        thickness={thickness}
        value={100}
        data-testid={testId && `${testId}-back`}
        {...other}
      />
      <MuiCircularProgress
        variant="indeterminate"
        className={clsx(
          className,
          'absolute left-0 top-0 [&_.MuiCircularProgress-svg]:w-full [&_.MuiCircularProgress-svg]:h-full',
          colorStyles[color]
        )}
        size={size}
        thickness={thickness}
        data-testid={testId && `${testId}-front`}
        {...other}
      />
    </div>
  );
}

export default CircularProgress;
