import { Order, ProductPricing } from 'generated/graphql';
import { TFunction } from 'react-i18next';

import { AddToCartItem } from 'API/types/cart.types';
import { ToastConfig } from 'components/Toast';

export type AddedItemInfo = AddToCartItem;
type ProductPricingAvailability = ProductPricing & {};
type AddItemsToCartFn = (items: AddToCartItem[]) => void;
type AddItemsToCartProps = {
  order?: Order;
  toast: (config: ToastConfig) => void;
  t: TFunction<'translation', undefined>;
  addItemToCart: AddItemsToCartFn;
  pricingData: ProductPricingAvailability[];
};

export default function addItemsToCartCb({
  order,
  toast,
  t,
  addItemToCart,
  pricingData
}: AddItemsToCartProps) {
  if (order?.lineItems?.length) {
    const items = order.lineItems.reduce<AddedItemInfo[]>((prev, item) => {
      const [pricing] = pricingData.filter(
        (price) => price.productId === item.erpPartNumber
      );
      if (pricing?.sellPrice) {
        prev.push({
          productId: item.erpPartNumber ?? '',
          qty: item.orderQuantity ?? 0,
          minIncrementQty: 1
        });
      }
      return prev;
    }, []);
    const badItemCount = order.lineItems.length - items.length;
    if (badItemCount > 0) {
      toast({
        message: t('orders.badItemCount', {
          count: badItemCount,
          plural: badItemCount > 1 ? 's' : ''
        }),
        kind: 'error'
      });
    }

    addItemToCart(items);
  }
}
