import { useEffect, useRef, useState } from 'react';

import { Add, Remove } from '@mui/icons-material';
import clsx from 'clsx';

import {
  HTMLButtonProps,
  HTMLInputProps,
  Modify,
  OnInputChange,
  OnInputKeyboard
} from '@reece/global-types';
import InputBase from 'components/TextInput/InputBase';
import {
  buttonLeftBorder,
  buttonRightBorder,
  buttonSizeStyles,
  buttonStyle,
  buttonWrapperStyle,
  inputSizeStyles,
  inputStyle,
  wrapperFullWidth
} from 'components/NumberInput/styles';

/**
 * Config
 */
const invalidChars = ['-', '+', 'e'];
const regexNonInteger = /[^\d-]/g;

/**
 * Type
 */
type NewProps = {
  allowzero?: boolean | string;
  testId?: string;
  downbuttonprops?: HTMLButtonProps;
  fullWidth?: boolean;
  increment?: number;
  max?: number;
  min?: number;
  onBlur?: (newValue: number) => void;
  onClick?: (newValue: number) => void;
  size?: NumberInputSizes;
  sync?: boolean;
  upbuttonprops?: HTMLButtonProps;
  value?: number;
};
export type NumberInputSizes = 'lg' | 'md' | 'sm' | 'xs';
export type NumberInputProps = Modify<HTMLInputProps, NewProps>;

/**
 * Component
 */
function NumberInput(props: NumberInputProps) {
  /**
   * Props
   */
  const {
    onClick,
    onBlur,
    allowzero,
    disabled,
    fullWidth,
    max = Number.MAX_SAFE_INTEGER,
    size = 'md',
    value,
    increment,
    downbuttonprops,
    sync,
    testId,
    upbuttonprops,
    ...rest
  } = props;
  const defaultNumber = allowzero ? 0 : 1;
  const min = props.min || defaultNumber;
  const parentValue = value || increment || defaultNumber;

  /**
   * Refs
   */
  const inputEl = useRef<HTMLInputElement>(null);

  /**
   * States
   */
  const [number, setNumber] = useState<number>(parentValue);
  const [lastValue, setLastValue] = useState<number>(parentValue);
  const [focusValue, setFocusValue] = useState<string>();
  const [changed, setChanged] = useState<boolean>(false);

  /**
   * Callbacks
   */
  // 🟤 cb - click +/-
  const handleClick = (direction: 1 | -1) => {
    inputEl.current?.blur();
    const toChangeTo = number + direction * (increment || 1);
    onClick?.(toChangeTo);
    setValidValue(toChangeTo);
  };
  // 🟤 cb - textbox onFocus
  const handleFocus = () => setFocusValue(number.toString());
  // 🟤 cb - textbox onChange
  const handleChange = ({ target: { value } }: OnInputChange) => {
    const filteredValue = value.replace(regexNonInteger, '');
    setChanged(true);
    if (props.max === undefined) {
      setFocusValue(filteredValue);
      return;
    }
    const maxLength = max.toString().length;
    if (filteredValue.length <= maxLength) {
      setFocusValue(filteredValue);
    }
  };
  // 🟤 cb - textbox onBlur
  const handleBlur = () => {
    const getValue =
      parseInt(focusValue ?? '', 10) || lastValue || defaultNumber;
    const newValue = changed && !focusValue ? defaultNumber : getValue;
    onBlur?.(newValue);
    setValidValue(value || number);
    setChanged(false);
    setFocusValue(undefined);
  };
  // 🟤 cb - textbox press "Enter" to save
  const handleEnter = ({ key }: OnInputKeyboard) => {
    if (key !== 'Enter') {
      return;
    }
    inputEl.current?.blur();
    handleBlur();
  };

  /**
   * Effects
   */
  // 🟡 effect - sync value from props (parent) if sync is true
  // -- 🔶 NOTE: This is commented out since it is not doing anything and impossible to get test coverage on this part. If it's causing bugs, please do uncomment this. --
  // useEffect(() => {
  //   // istanbul ignore next
  //   if (sync && number !== parentValue) {
  //     setLastValue(number);
  //     setNumber(parentValue);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.sync, value]);
  // 🟡 effect - prevent `invalidChars` being inserted into the textbox
  useEffect(() => {
    inputEl.current?.addEventListener('keydown', preventInvalidKeys);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      inputEl.current?.removeEventListener('keydown', preventInvalidKeys);
    };
  }, []);
  // 🟡 - Apply last value
  // Can't useEffect because that will cause input caching issue and infinite loops
  if (value && value !== lastValue) {
    setLastValue(value);
    setNumber(value);
  }

  /**
   * Render
   */
  return (
    <div
      className={clsx('flex', { [wrapperFullWidth]: fullWidth })}
      data-testid={`${testId}-wrapper`}
    >
      {/* Minus button */}
      <div
        className={clsx(
          buttonWrapperStyle,
          buttonLeftBorder,
          buttonSizeStyles[size],
          { 'bg-primary-3-10': disabled }
        )}
      >
        <button
          {...downbuttonprops}
          disabled={disabled}
          onClick={() => handleClick(-1)}
          className={clsx(buttonStyle)}
          data-testid={`${testId}-down`}
        >
          <Remove />
        </button>
      </div>
      {/* Input */}
      <InputBase
        ref={inputEl}
        value={focusValue ?? number}
        onFocus={handleFocus}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={handleEnter}
        {...rest}
        disabled={disabled}
        testId={testId}
        className={clsx(inputStyle, inputSizeStyles[size], {
          'flex-1': fullWidth
        })}
      />
      {/* Plus button */}
      <div
        className={clsx(
          buttonWrapperStyle,
          buttonRightBorder,
          buttonSizeStyles[size],
          { 'bg-primary-3-10': disabled }
        )}
      >
        <button
          disabled={disabled}
          onClick={() => handleClick(1)}
          {...upbuttonprops}
          className={clsx(buttonStyle)}
          data-testid={`${testId}-up`}
        >
          <Add />
        </button>
      </div>
    </div>
  );

  /**
   * Util
   */
  // 🔣 util - filter and then setNumber
  function setValidValue(value: number) {
    value = value < min ? min : value;
    value = value > max ? max : value;
    setNumber(value);
  }
  // 🔣 util - used by `preventInvalidInputKeyEffect`
  function preventInvalidKeys(e: KeyboardEvent) {
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  }
}

export default NumberInput;
