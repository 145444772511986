export const baseButtonStyle =
  '[@media_(hover:_none)]:!bg-transparent bg-blend-multiply';
export const buttonStyles = {
  inherit: '!text-inherit',
  primary: '!text-primary-1-100',
  secondary: '!text-secondary-3-100',
  gray: '!text-secondary-2-100',
  lightBlue: '!text-primary-2-100',
  yellow: '!text-secondary-1-100',
  green: '!text-success-100',
  red: '!text-support-1-100'
};
export const disableStyles = '!text-secondary-3';
export const hasBackgroundStyle =
  '!bg-secondary-4-100 hover:!bg-secondary-3-60';
