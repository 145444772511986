import { ReactNode } from 'react';

import { AccordionSummary, AccordionDetails } from '@mui/material';
import clsx from 'clsx';

import Accordion from 'components/Accordion';
import { warningStyle } from 'components/AccordionWrapper/styles';
import { ArrowDropDownIcon } from 'icons';

/**
 * Styles
 */

/**
 * Type
 */
export type AccordionWrapperProps = {
  children: ReactNode;
  label: string;
  testId?: string;
  variant?: 'default' | 'warning';
};

/**
 * Component
 */
function AccordionWrapper(props: AccordionWrapperProps) {
  /**
   * Props
   */
  const { children, label, testId, variant = 'default' } = props;

  /**
   * Render
   */
  return (
    <Accordion
      type="product"
      className={clsx({ [warningStyle]: variant === 'warning' })}
      data-testid={testId}
    >
      <AccordionSummary
        data-testid={`${testId}-dropdown`}
        expandIcon={<ArrowDropDownIcon />}
      >
        {label}
      </AccordionSummary>
      <AccordionDetails sx={{ px: 0 }}>{children}</AccordionDetails>
    </Accordion>
  );
}

export default AccordionWrapper;
