import { createContext, useState, useContext, useEffect } from 'react';

import { noop, isUndefined } from 'lodash-es';
import { useHistory, useLocation } from 'react-router-dom';
import { WrapperProps } from '@reece/global-types';

import { useApiGetNearbyBranches } from 'API/branches.api';
import { PreferredTimeEnum } from 'API/types/cart.types';
import { ErrorState, ErrorTypes } from 'common/ErrorBoundary/ErrorComponent';
import {
  Branch,
  GetBranchQuery,
  Stock,
  useGetBranchLazyQuery,
  useUpdateWillCallBranchMutation
} from 'generated/graphql';
import { useGeolocation } from 'hooks/useGeolocation';
import { AuthContext } from 'providers/AuthProvider';
import { useCartContext } from 'providers/CartProvider';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';

/**
 * Config
 */
export const PAGE_SIZE = 5;
export const MAX_PAGES = 10;
export const VALID_ROUTES_WHEN_BRANCH_IS_MISSING = [
  '/account',
  '/customer-approval',
  '/error',
  '/support',
  '/user-management',
  '/location-search',
  '/credit-forms',
  '/job-form'
];

/**
 * Types
 */
export enum Divisions {
  ALL = 'all',
  PLUMBING = 'plumbing',
  HVAC = 'hvac',
  WATERWORKS = 'waterworks',
  BANDK = 'bandk'
}
export type BranchContextType = {
  homeBranch?: Branch;
  homeBranchLoading: boolean;
  homeBranchError: string;
  shippingBranch?: Branch;
  shippingBranchLoading: boolean;
  nearbyBranches?: Branch[];
  nearbyBranchesLoading: boolean;
  branchSelectOpen: boolean;
  division: string;
  productId?: string;
  stock?: Stock; // TODO: remove
  isLocationDistance?: boolean;
  setBranchSelectOpen: (p: boolean) => void;
  setStock: (s?: Stock) => void; // TODO: remove
  setShippingBranch: (branchId?: string) => void;
  setDivision: (p: Divisions) => void;
  setProductId: (p?: string) => void;
};
type BranchProviderProps = WrapperProps & {
  testAccessor?: jest.Mock;
  mocksBranchSelectOpen?: boolean;
};

/**
 * Context
 */
export const defaultBranchContext: BranchContextType = {
  homeBranchLoading: false,
  homeBranchError: '',
  shippingBranchLoading: false,
  nearbyBranchesLoading: false,
  branchSelectOpen: false,
  setBranchSelectOpen: noop,
  setStock: noop,
  setShippingBranch: noop,
  division: 'none',
  setDivision: noop,
  setProductId: noop
};
export const BranchContext =
  createContext<BranchContextType>(defaultBranchContext);
export const useBranchContext = () => useContext(BranchContext);

/**
 * Provider
 */
function BranchProvider(props: BranchProviderProps) {
  /**
   * Custom Hooks
   */
  const history = useHistory();
  const location = useLocation<ErrorState>();
  const isOnErrorPage = location.pathname === '/error';

  /**
   * State
   */
  const [branchSelectOpen, setBranchSelectOpen] = useState(
    Boolean(props.mocksBranchSelectOpen)
  );
  const [stock, setStock] = useState<Stock>();
  const [division, setDivision] = useState<Divisions>(Divisions.ALL);
  const [homeBranch, setHomeBranch] = useState<Branch>();
  const [isLocationDistance, setIsLocationDistance] = useState(false);
  const [shippingBranch, setShippingBranch] = useState<Branch>();
  const [productId, setProductId] = useState<string>();
  const [nearbyBranches, setNearbyBranches] = useState<Branch[]>([]);

  /**
   * Context
   */
  const {
    cart,
    cartLoading,
    updateWillCallBranchLoading,
    updateWillCallBranch,
    setCart,
    hydrateCartProducts,
    setSelectedBranch
  } = useCartContext();
  const { selectedAccounts, isMincron } = useSelectedAccountsContext();
  const { profile, authState } = useContext(AuthContext);

  const hasAccount = isMincron
    ? !selectedAccounts?.billToErpAccount
    : !selectedAccounts?.shipToErpAccount;

  /**
   * Data
   */
  // 🟣 Query - Get branch (by id)
  const [getBranch, { loading: getBranchLoading, error: getBranchError }] =
    useGetBranchLazyQuery({
      onCompleted: onBranchQueryCompleted,
      fetchPolicy: 'cache-and-network'
    });
  // 🟣 Geolocation
  const { position, positionLoading, positionError } = useGeolocation({
    skip: !branchSelectOpen && !getBranchError
  });
  const homeBranchLS = JSON.parse(localStorage.getItem('homeBranch') || '{}');
  const branchIDLS = localStorage.getItem('branchID') || '';
  // 🟣 API - Get Nearby Branches
  const { call: getNearbyBranches, loading } = useApiGetNearbyBranches({
    onCompleted: ({ data: { branches, homeBranch } }) => {
      // mapping this value from BE because FE depends on name "phone" in many places
      const res = branches.map((b) => ({ ...b, phone: b.phoneNumber }));
      setNearbyBranches(res);
      // mapping home as well for working with GQL, but we'll update this once we move all to BFF
      setHomeBranch({
        ...homeBranch,
        businessHours: homeBranch.hours,
        phone: homeBranch.phoneNumber
      });
      props.testAccessor?.('useApiGetNearbyBranches_done');
    }
  });

  // 🟣 Mutation - Update WillCall Branch
  const [updateBranch, { loading: updateBranchLoading }] =
    useUpdateWillCallBranchMutation({
      refetchQueries: ['SearchProduct', 'GetProduct']
    });

  /**
   * Callbacks
   */
  // 🟤 CB - Set Shipping Branch
  function setShippingBranchOld(branchId?: string) {
    if (
      branchId &&
      branchId !== shippingBranch?.branchId &&
      profile?.userId &&
      selectedAccounts.shipTo?.id &&
      updateWillCallBranch
    ) {
      updateBranch({
        variables: {
          cartId: cart?.id ?? '',
          branchId,
          userId: profile.userId,
          shipToAccountId: selectedAccounts.shipTo.id
        },
        onCompleted: (res) => {
          setCart({
            ...cart,
            shippingBranchId: branchId,
            willCall: {
              id: res.updateWillCallBranch.willCall.id,
              branchId: res.updateWillCallBranch.willCall.branchId ?? branchId,
              preferredDate:
                res.updateWillCallBranch.willCall.preferredDate ?? '',
              preferredTime:
                res.updateWillCallBranch.willCall.preferredTime ??
                PreferredTimeEnum.Morning,
              pickupInstructions:
                res.updateWillCallBranch.willCall.pickupInstructions ?? ''
            }
          });

          updateWillCallBranch(cart?.id ?? '');
          hydrateCartProducts();
        }
      });

      setBranchSelectOpen(false);
    }
  }

  /**
   * Effects
   */
  // 🟡 Effect - close branch on page change
  useEffect(() => {
    setBranchSelectOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  // 🟡 Effect - Get nearby branches
  useEffect(() => {
    if (!branchSelectOpen || hasAccount) {
      return;
    }
    getNearbyBranches({
      latitude: position?.coords?.latitude ?? homeBranchLS?.latitude,
      longitude: position?.coords?.longitude ?? homeBranchLS?.longitude,
      division
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchSelectOpen, hasAccount, division]);

  // 🟡 Effect - Get branch by shippingBranchId
  useEffect(() => {
    if (authState?.isAuthenticated && branchIDLS) {
      getBranch({ variables: { branchId: branchIDLS } });
    } else {
      isUndefined(selectedAccounts.shippingBranchId) &&
        setHomeBranch(undefined);
    }
  }, [
    authState?.isAuthenticated,
    selectedAccounts.shippingBranchId,
    getBranch,
    branchIDLS
  ]);

  // 🟡 Effect - Get Branch by cart shippingBranchId
  useEffect(() => {
    if (!isUndefined(homeBranch)) {
      // this is a temporary solution that addresses the mismatch
      // between BE and FE values for branch phone and hours
      const mappedBranch = {
        ...homeBranch,
        hours: homeBranch.businessHours,
        phone: homeBranch.actingBranchManagerPhone
      };
      localStorage.setItem('homeBranch', JSON.stringify(mappedBranch));
    }

    if (cart?.shippingBranchId) {
      getBranch({ variables: { branchId: cart?.shippingBranchId } });
    } else {
      setShippingBranch(homeBranch);
      setSelectedBranch(homeBranch as Branch);
    }
  }, [
    cart?.shippingBranchId,
    getBranch,
    homeBranch,
    selectedAccounts.shippingBranchId,
    setSelectedBranch
  ]);

  // 🟡 Effect - Get Branch by billToErpAccount
  useEffect(() => {
    if (branchIDLS) {
      getBranch({ variables: { branchId: branchIDLS } });
    }
  }, [
    selectedAccounts.billToErpAccount?.branchId,
    homeBranch?.branchId,
    getBranch,
    branchIDLS
  ]);

  // 🟡 Effect - adjust isLocationDistance
  useEffect(() => {
    if (navigator.geolocation && branchSelectOpen) {
      setIsLocationDistance(true);
    }
    if (positionError && branchSelectOpen) {
      setIsLocationDistance(false);
    }
  }, [setIsLocationDistance, positionError, branchSelectOpen]);

  // 🟡 Effect - Redirect Error to home
  useEffect(() => {
    if (
      homeBranch &&
      isOnErrorPage &&
      location.state?.errorType === ErrorTypes.BRANCH_ERROR
    ) {
      history.push('/');
    }
  }, [history, homeBranch, isOnErrorPage, location.state?.errorType]);

  // 🟡 Effect - Redirect to error page
  useEffect(() => {
    const matchLocation = VALID_ROUTES_WHEN_BRANCH_IS_MISSING.includes(
      location.pathname
    );
    if (
      !matchLocation &&
      !isOnErrorPage &&
      getBranchError &&
      !homeBranch &&
      authState?.isAuthenticated
    ) {
      history.push('/error', { errorType: ErrorTypes.BRANCH_ERROR });
    }
  }, [
    history,
    homeBranch,
    getBranchError,
    isOnErrorPage,
    location.pathname,
    authState?.isAuthenticated
  ]);

  /**
   * Render
   */
  return (
    <BranchContext.Provider
      value={{
        homeBranch,
        homeBranchLoading: getBranchLoading,
        homeBranchError: getBranchError?.message ?? '',
        shippingBranch,
        shippingBranchLoading:
          getBranchLoading ||
          cartLoading ||
          updateBranchLoading ||
          !!updateWillCallBranchLoading,
        nearbyBranches,
        nearbyBranchesLoading: getBranchLoading || loading || positionLoading,
        branchSelectOpen,
        division,
        isLocationDistance,
        productId,
        stock,
        setBranchSelectOpen,
        setStock,
        setShippingBranch: setShippingBranchOld,
        setDivision,
        setProductId
      }}
    >
      {props.children}
    </BranchContext.Provider>
  );

  /**
   * GQP onCompleted Def
   */
  // To be removed once we implement BFF calls
  function onBranchQueryCompleted({ branch }: GetBranchQuery) {
    if (!authState?.isAuthenticated && !branch.branchId) {
      return;
    }
    if (
      branch.branchId === selectedAccounts.shippingBranchId ||
      cart?.delivery?.shipTo !== selectedAccounts.shippingBranchId
    ) {
      localStorage.setItem('branchID', branch.branchId);
      setShippingBranch(branch as Branch);
      setSelectedBranch(branch as Branch);
      return;
    }
  }
}

export default BranchProvider;
