import { Lightbulb } from '@mui/icons-material';
import Card from 'components/Card';
import IconButton from 'components/IconButton';

/**
 * Config
 */
const display = {
  title: 'IconButton'
};

/**
 * Component
 */
function IconButtonPage() {
  return (
    <Card className="my-8 p-6">
      <h5 className="text-2xl font-bold text-secondary-2-100">
        {display.title}
      </h5>
      <hr className="my-4" />
      <div className="my-4 flex justify-center text-support-5-100">
        <IconButton color="primary">
          <Lightbulb />
        </IconButton>
        <IconButton color="lightBlue">
          <Lightbulb />
        </IconButton>
        <IconButton color="gray">
          <Lightbulb />
        </IconButton>
        <IconButton color="inherit">
          <Lightbulb />
        </IconButton>
        <IconButton color="secondary">
          <Lightbulb />
        </IconButton>
        <IconButton color="yellow">
          <Lightbulb />
        </IconButton>
        <IconButton color="green">
          <Lightbulb />
        </IconButton>
      </div>
    </Card>
  );
}

export default IconButtonPage;
