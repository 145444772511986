import { useMemo, useState } from 'react';

import { orderBy } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useFlexLayout, usePagination, useSortBy, useTable } from 'react-table';

import { useBranchColumnMemo } from 'pages/BranchManagement/lib/columns';
import EditModal from 'pages/BranchManagement/sub/EditModal';
import PermissionRequired, { Permission } from 'common/PermissionRequired';
import TablePageLayout from 'common/TablePageLayout';
import TableRenderer from 'common/TablePageLayout/TableRenderer';
import {
  BranchListItemFragment,
  useGetBranchesListQuery
} from 'generated/graphql';
import Search from 'pages/BranchManagement/sub/Search';

/**
 * Component
 */
function BranchManagement() {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();
  /**
   * State
   */
  const [editOpen, setEditOpen] = useState(false);
  const [selectedBranch, setSelectedBranch] =
    useState<BranchListItemFragment>();
  const [searchValue, setSearchValue] = useState('');

  /**
   * Data
   */
  const { data: branchesListData, loading: branchesListLoading } =
    useGetBranchesListQuery();

  /**
   * Callbacks
   */
  const filterBranches = (
    searchValue: string,
    branchs?: BranchListItemFragment[]
  ) =>
    branchs?.filter((branch) => {
      if (!searchValue) {
        return branch;
      }

      const query = searchValue.toLowerCase();
      const condition =
        branch.address1?.toLowerCase().includes(query) ||
        branch.city?.toLowerCase().includes(query) ||
        branch.state?.toLowerCase().includes(query) ||
        branch.name?.toLowerCase().includes(query) ||
        branch.zip?.includes(query) ||
        branch.branchId.includes(query);
      return condition && branch;
    });

  /**
   * Memos
   */
  // 🔵 Memo - ordered branch data
  const displayedData = useMemo(
    () =>
      orderBy(
        filterBranches(searchValue, branchesListData?.branches),
        ({ branchId }) => branchId
      ),
    [branchesListData?.branches, searchValue]
  );

  const columns = useBranchColumnMemo();

  /**
   * Table
   */
  const tableInstance = useTable(
    {
      data: displayedData,
      columns
    },
    useSortBy,
    useFlexLayout,
    usePagination
  );

  /**
   * Render
   */
  return (
    <>
      <EditModal
        open={editOpen}
        selectedBranch={selectedBranch}
        onClose={handleEditClose}
        testId="branch-edit-modal"
      />
      <PermissionRequired
        permissions={[Permission.MANAGE_BRANCHES]}
        redirectTo="/"
      >
        <TablePageLayout
          loading={branchesListLoading}
          pageTitle={t('branchManagement.storeFinderAdmin')}
          headerAction={
            <Search searchValue={searchValue} setSearchValue={setSearchValue} />
          }
          table={
            <TableRenderer
              resultsCount={tableInstance.rows.length}
              resultsCountText={t('branchManagement.branches')}
              noResultsMessage={t('common.noResultsFound')}
              tableInstance={tableInstance}
              primaryKey="branchId"
              testId="store-finder-table"
              onRowClick={handleRowClick}
            />
          }
        />
      </PermissionRequired>
    </>
  );

  /**
   * Callback Defs
   */
  function handleRowClick(data: BranchListItemFragment) {
    setSelectedBranch(data);
    setEditOpen(true);
  }

  function handleEditClose() {
    setEditOpen(false);
    setSelectedBranch(undefined);
  }
}

export default BranchManagement;
