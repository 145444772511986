import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance, Column } from 'react-table';

import FlagCell from 'pages/BranchManagement/sub/FlagCell';
import { BranchListItemFragment } from 'generated/graphql';
import { TruncatedCell } from 'utils/tableUtils';

/**
 * Hook
 */
export function useBranchColumnMemo() {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();
  /**
   * Memo
   */
  const branchColumn: Column<BranchListItemFragment>[] = useMemo(
    () => [
      {
        accessor: 'branchId',
        Header: `${t('branchManagement.erpId')}`,
        width: 75
      },
      {
        accessor: 'name',
        Header: `${t('branchManagement.branchName')}`,
        width: 300,
        Cell: TruncatedCell
      },
      {
        accessor: 'address1',
        Header: `${t('branchManagement.address')}`,
        Cell: ({ row }) =>
          `${row?.original?.address1}, ${row?.original?.city}, ${row?.original?.state} ${row?.original?.zip}`,
        width: 450
      },
      {
        accessor: 'isActive',
        Header: `${t('branchManagement.active')}`,
        Cell: setFlagCell
      },
      {
        accessor: 'isAvailableInStoreFinder',
        Header: `${t('branchManagement.inStoreFinder')}`,
        Cell: setFlagCell
      },
      {
        accessor: 'isPricingOnly',
        Header: `${t('branchManagement.pricingOnly')}`,
        Cell: setFlagCell
      },
      {
        accessor: 'isShoppable',
        Header: `${t('branchManagement.shoppable')}`,
        Cell: setFlagCell
      },
      {
        accessor: (data: BranchListItemFragment) =>
          [
            data.isBandK && 'Bath & Kitchen',
            data.isHvac && 'HVAC',
            data.isPlumbing && 'Plumbing',
            data.isWaterworks && 'Waterworks'
          ]
            .filter((i) => i)
            .join(', '),
        Header: `${t('branchManagement.division')}`,
        width: 350
      }
    ],
    [t]
  );
  /**
   * Output
   */
  return branchColumn;
}

export const setFlagCell = ({ value }: TableInstance) => (
  <FlagCell value={value} />
);
