import { Badge as MuiBadge, BadgeProps as MuiBadgeProps } from '@mui/material';
import clsx from 'clsx';

import { Modify } from '@reece/global-types';
import { themedStyles } from 'components/Badge/styles';

/**
 * Types
 */
type NewProps = {
  color?: BadgeColor;
  kind?: BadgeKind;
};
export type BadgeColor =
  | 'primary'
  | 'secondary'
  | 'gray'
  | 'green'
  | 'yellow'
  | 'red';
export type BadgeKind = 'fill' | 'strokeFill' | 'stroke';
export type BadgeProps = Modify<MuiBadgeProps, NewProps>;

/**
 * Component
 */
function Badge(props: BadgeProps) {
  /**
   * Props
   */
  const {
    children,
    className,
    color = 'secondary',
    kind = 'fill',
    ...other
  } = props;

  /**
   * Styles
   */
  const styling = themedStyles[kind][color];

  /**
   * Render
   */
  return (
    <MuiBadge className={clsx(className, styling)} {...other}>
      {children}
    </MuiBadge>
  );
}

export default Badge;
