import { Product, Scalars } from 'generated/graphql';
import { Maybe } from 'graphql/jsutils/Maybe';
import { Configuration } from 'utils/configuration';
import { CleanPhoneNumber } from 'utils/strings';
import { Utag, withUtag } from 'providers/UtagProvider';

declare global {
  interface Window {
    tealium: any;
  }
  const utag: Utag;
}

export interface PagePayload {
  account: string;
  job: string;
}

interface LoginEventType {
  billTo?: string | null;
  homeBranch?: string | null;
  authenticated?: boolean | null;
  userEmail?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  brandAssociation?: string | null;
  region?: string | null;
  customerType?: string | null;
  timestamp: string | null;
}

interface TrackUploadListActionType {
  billTo?: string | null;
  listName?: string | null;
  user?: string | null;
}

interface TrackUserRegistrationActionType {
  billTo?: string | null;
  homeBranch?: string | null;
  user?: string | null;
  timestamp?: string | null;
}

interface TrackProductViewActionType {
  authenticated: boolean | undefined;
  userEmail: string | null | undefined;
  searchTerm: string | null | undefined;
  product: Product | null | any;
  pageNumber: number | null;
  pageIndex: number | null;
  searchIndex: number | null;
  timestamp: string | null;
  brand: string | null;
}

interface TrackSearchActionType {
  authenticated: boolean | undefined;
  searchTerm?: string | null;
  userEmail?: string | null;
  timestamp?: string | null;
  homeBranch?: string | null;
  brandAssociation?: string | null;
  billTo?: string | null;
  customerType?: string | null;
}

interface TrackUserApprovalActionType {
  user?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
}

interface TrackSubmitOrderProductType {
  productName?: string | undefined;
  qty?: number | undefined;
  mfr?: string | undefined;
  erp?: Maybe<Scalars['String']>;
}

interface TrackPurchaseActionType {
  billTo: string | null | undefined;
  shipTo: string | null | undefined;
  orderNumber?: string | null;
  shippingBranch: string | undefined;
  products?: Array<TrackSubmitOrderProductType> | null;
  netTotal: string | undefined;
}

interface TrackUserDeletionActionType {
  billTo: string | null | undefined;
  user: string | null | undefined;
  deletedUser: string | null | undefined;
}

interface TrackListsPageViewActionType {
  billTo: string | null | undefined;
  userEmail: string | null | undefined;
}

interface TrackInvoicesPageViewActionType {
  billTo: string | null | undefined;
  userEmail: string | null | undefined;
}

interface TrackPreviouslyPurchasedPageViewActionType {
  billTo: string | null | undefined;
  userEmail: string | null | undefined;
}

interface TrackOrdersPageViewAcionType {
  billTo: string | null | undefined;
  userEmail: string | null | undefined;
}

interface TrackQuotesPageViewActionType {
  billTo: string | null | undefined;
  userEmail: string | null | undefined;
}

interface TrackPurchaseApprovalsPageViewActionType {
  billTo: string | null | undefined;
  userEmail: string | null | undefined;
}

interface TrackLocationSearchPageViewActionType {
  billTo: string | null | undefined;
  userEmail: string | null | undefined;
}

interface TrackCartPageViewActionType {
  billTo: string | null | undefined;
  userEmail: string | null | undefined;
}

interface TrackJobFormPageViewActionType {
  billTo: string | null | undefined;
  userEmail: string | null | undefined;
}

interface TrackCreditFormsPageViewActionType {
  billTo: string | null | undefined;
  userEmail: string | null | undefined;
}

interface TrackCheckoutPageViewActionType {
  billTo: string | null | undefined;
  userEmail: string | null | undefined;
}

interface TrackSearchPageOneConversionsActionType {
  billTo: string | null | undefined;
  searchTerm: string | null | undefined;
  conversionType: string | null | undefined;
  userEmail: string | null | undefined;
}

export const load = () => {
  //  loads Tealium with correct tealium env
  window.tealium.load(Configuration.tealiumEnv);
};

export const trackLoginAction = (properties: LoginEventType) => {
  withUtag().then((utag) =>
    utag.link({
      billTo: properties.billTo, //Required
      brandAssociation: properties.brandAssociation, //Required
      customerType: properties.customerType, //Required
      firstName: properties.firstName, //Required
      homeBranch: properties.homeBranch, //Required
      lastName: properties.lastName, //Required
      phoneNumber: CleanPhoneNumber(properties.phoneNumber), //Required
      region: properties.region, //Required
      tealium_event: 'user_login', //Required
      userEmail: properties.userEmail //Required
    })
  );
};

export const trackUserRegistrationAction = (
  properties: TrackUserRegistrationActionType
) => {
  withUtag().then((utag) =>
    utag.link({
      billTo: properties.billTo, //Required
      homeBranch: properties.homeBranch, //Required
      tealium_event: 'user_register', //Required
      userEmail: properties.user //Required
    })
  );
};

export const trackProductViewAction = (
  properties: TrackProductViewActionType
) => {
  withUtag().then((utag) =>
    utag.link({
      pageIndex: properties.pageIndex, //Required
      pageNumber: properties.pageNumber, //Suggested
      productCategories: properties.product?.categories, //Required
      productCmp: properties.product?.cmp, //Required
      productCustomerPartNumber: properties.product?.customerPartNumber, //Suggested
      productEnvironmentalOptions: properties.product?.environmentalOptions, //Suggested
      productErp: properties.product?.erp, //Required
      productFeaturesAndBenefits: properties.product?.featuresAndBenefits, //Required
      productId: properties.product?.id, //Required
      productImageUrlsLarge: properties.product?.imageUrls.large, //Suggested
      productImageUrlsMedium: properties.product?.imageUrls.medium, //Suggested
      productImageUrlsSmall: properties.product?.imageUrls.small, //Suggested
      productImageUrlsThumb: properties.product?.imageUrls.thumb, //Suggested
      productManufacturerName: properties.product?.manufacturerName, //Required
      productManufacturerNumber: properties.product?.manufacturerNumber, //Required
      productMinIncrementQty: properties.product?.minIncrementQty, //Required
      productName: properties.product?.name, //Required
      productPackageDimensionsHeight:
        properties.product?.packageDimensions?.height, //Suggested
      productPackageDimensionsLength:
        properties.product?.packageDimensions?.length, //Suggested
      productPackageDimensionsVolume:
        properties.product?.packageDimensions?.volume, //Suggested
      productPackageDimensionsVolumeUnitOfMeasure:
        properties.product?.packageDimensions?.volumeUnitOfMeasure, //Suggested
      productPackageDimensionsWeight:
        properties.product?.packageDimensions?.weight, //Suggested
      productPackageDimensionsWeightUnitOfMeasure:
        properties.product?.packageDimensions?.weightUnitOfMeasure, //Suggested
      productPackageDimensionsWidth:
        properties.product?.packageDimensions?.width, //Suggested
      productPartNumber: properties.product?.partNumber, //Required
      productPrice: properties.product?.price, //Required
      productProductOverview: properties.product?.productOverview, //Required
      productSeriesModelFigureNumber:
        properties.product?.seriesModelFigureNumber, //Suggested
      productStock: properties.product?.stock, //Suggested
      productTaxonomy: properties.product?.taxonomy, //Suggested
      productTechSpecificationsName:
        properties.product?.techSpecifications?.name, //Suggested
      productTechSpecificationsValue:
        properties.product?.techSpecifications?.value, //Suggested
      productTechnicalDocumentsName:
        properties.product?.technicalDocuments?.name, //Suggested
      productTechnicalDocumentsUrl: properties.product?.technicalDocuments?.url, //Suggested
      productUnspsc: properties.product?.unspsc, //Required
      productUpc: properties.product?.upc, //Required
      searchIndex: properties.searchIndex, //Suggested
      searchTerm: properties.searchTerm, //Suggested
      tealium_event: 'product_view', //Required
      userEmail: properties.userEmail //Required
    })
  );
};

export const trackSearchAction = (properties: TrackSearchActionType) => {
  withUtag().then((utag) =>
    utag.link({
      billTo: properties.billTo, //Required
      brandAssociation: properties.brandAssociation, //Required
      customerType: properties.customerType, //Required
      homeBranch: properties.homeBranch, //Required
      searchTerm: properties.searchTerm, //Required
      tealium_event: 'search', //Required
      userEmail: properties.userEmail //Required
    })
  );
};

export const trackUserApprovalAction = (
  properties: TrackUserApprovalActionType
) => {
  withUtag().then((utag) =>
    utag.link({
      approvedUserEmail: properties.user, //Required
      firstName: properties.firstName, //Required
      lastName: properties.lastName, //Required
      phoneNumber: properties.phoneNumber, //Required
      tealium_event: 'user_approval' //Required
    })
  );
};

export const trackUserDeletionAction = (
  properties: TrackUserDeletionActionType
) => {
  withUtag().then((utag) =>
    utag.link({
      billTo: properties.billTo, //Required
      deletedUser: properties.deletedUser, //Required
      tealium_event: 'user_deletion', //Required
      userEmail: properties.user //Suggested
    })
  );
};

export const trackPurchaseAction = (properties: TrackPurchaseActionType) => {
  withUtag().then((utag) =>
    utag.link({
      billTo: properties.billTo, //Required
      orderGrandTotal: properties.netTotal, //Required
      orderNumber: properties.orderNumber, //Required
      shipTo: properties.shipTo, //Required
      shippingBranch: properties.shippingBranch, //Required
      tealium_event: 'purchase' //Required
    })
  );
};

export const trackUploadListAction = (
  properties: TrackUploadListActionType
) => {
  withUtag().then((utag) =>
    utag.link({
      billTo: properties.billTo, //Required
      listName: properties.listName, //Required
      tealium_event: 'list_upload', //Required
      userEmail: properties.user //Required
    })
  );
};

export const trackListsPageViewAction = (
  properties: TrackListsPageViewActionType
) => {
  withUtag().then((utag) =>
    utag.link({
      billTo: properties.billTo,
      userEmail: properties.userEmail,
      tealium_event: 'lists_view'
    })
  );
};

export const trackInvoicesPageViewAction = (
  properties: TrackInvoicesPageViewActionType
) => {
  withUtag().then((utag) =>
    utag.link({
      billTo: properties.billTo,
      userEmail: properties.userEmail,
      tealium_event: 'invoices_view'
    })
  );
};

export const trackPreviouslyPurchasedPageViewAction = (
  properties: TrackPreviouslyPurchasedPageViewActionType
) => {
  withUtag().then((utag) =>
    utag.link({
      billTo: properties.billTo,
      userEmail: properties.userEmail,
      tealium_event: 'previouslyPurchased_view'
    })
  );
};

export const trackOrdersPageViewAction = (
  properties: TrackOrdersPageViewAcionType
) => {
  withUtag().then((utag) =>
    utag.link({
      billTo: properties.billTo,
      userEmail: properties.userEmail,
      tealium_event: 'orders_view'
    })
  );
};

export const trackQuotesPageViewAction = (
  properties: TrackQuotesPageViewActionType
) => {
  withUtag().then((utag) =>
    utag.link({
      billTo: properties.billTo,
      userEmail: properties.userEmail,
      tealium_event: 'quotes_view'
    })
  );
};

export const trackPurchaseApprovalsPageViewAction = (
  properties: TrackPurchaseApprovalsPageViewActionType
) => {
  withUtag().then((utag) =>
    utag.link({
      billTo: properties.billTo,
      userEmail: properties.userEmail,
      tealium_event: 'purchaseApprovals_view'
    })
  );
};

export const trackLocationSearchPageViewAction = (
  properties: TrackLocationSearchPageViewActionType
) => {
  withUtag().then((utag) =>
    utag.link({
      billTo: properties.billTo,
      userEmail: properties.userEmail,
      tealium_event: 'locationSearch_view'
    })
  );
};

export const trackJobFormPageViewAction = (
  properties: TrackJobFormPageViewActionType
) => {
  withUtag().then((utag) =>
    utag.link({
      billTo: properties.billTo,
      userEmail: properties.userEmail,
      tealium_event: 'jobForm_view'
    })
  );
};

export const trackCreditFormsPageViewAction = (
  properties: TrackCreditFormsPageViewActionType
) => {
  withUtag().then((utag) =>
    utag.link({
      billTo: properties.billTo,
      userEmail: properties.userEmail,
      tealium_event: 'creditForms_view'
    })
  );
};

export const trackRegisterPageViewAction = () => {
  withUtag().then((utag) =>
    utag.link({
      tealium_event: 'register_view'
    })
  );
};

export const trackCartPageViewAction = (
  properties: TrackCartPageViewActionType
) => {
  withUtag().then((utag) =>
    utag.link({
      billTo: properties.billTo,
      userEmail: properties.userEmail,
      tealium_event: 'cart_view'
    })
  );
};

export const trackCheckoutPageViewAction = (
  properties: TrackCheckoutPageViewActionType
) => {
  withUtag().then((utag) =>
    utag.link({
      billTo: properties.billTo,
      userEmail: properties.userEmail,
      tealium_event: 'checkout_view'
    })
  );
};

export const trackSearchPageOneConversionAction = (
  properties: TrackSearchPageOneConversionsActionType
) => {
  withUtag().then((utag) =>
    utag.link({
      billTo: properties.billTo,
      searchTerm: properties.searchTerm,
      conversionType: properties.conversionType,
      userEmail: properties.userEmail,
      tealium_event: 'search_conversion'
    })
  );
};
