import { useMemo } from 'react';
import {
  AccountQuery,
  ContractDetails,
  ErpAccount,
  ErpSystemEnum,
  useAccountQuery,
  useGetContractDetailsQuery
} from 'generated/graphql';
import { useDomainInfo } from 'hooks/useDomainInfo';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';
import { encryptData } from 'utils/encrypt';

export default function useContractDetailsData(contractNumber: string) {
  /**
   * Custom Hooks
   */
  const { brand } = useDomainInfo();
  /**
   * Data
   */

  /**
   * Context
   */
  const { selectedAccounts } = useSelectedAccountsContext();

  // 🔵 Memo - Encrypted ShipTo and BillTo
  // 🔶 Initialized here so it can be used on graphql query
  const encryptedBillTo = useMemo(
    () => encryptData(selectedAccounts.billTo?.id ?? ''),
    [selectedAccounts.billTo?.id]
  );
  const encryptedErpAccount = useMemo(
    () => encryptData(selectedAccounts?.billTo?.erpAccountId ?? ''),
    [selectedAccounts?.billTo?.erpAccountId]
  );

  /**
   * Data
   */
  const { data: contractDetailsQuery, loading: contratLoading } =
    useGetContractDetailsQuery({
      variables: {
        contractNumber,
        erpAccountId: encryptedErpAccount
      }
    });
  const { data: accountQuery, loading: accountLoading } = useAccountQuery({
    variables: {
      accountId: encryptedBillTo,
      brand
    }
  });

  /**
   * Memo/variables
   */
  const account = useMemo(() => accountMemo(accountQuery), [accountQuery]);
  const data = contractDetailsQuery?.contract as ContractDetails | undefined;
  const loading = contratLoading || accountLoading;

  // Return
  return { data, loading, account };
}

export function accountMemo(
  accountQuery: AccountQuery | undefined
): ErpAccount | undefined {
  const mincronAccounts = accountQuery?.account?.filter(
    (i) => i?.erpName === ErpSystemEnum.Mincron
  )[0];
  return mincronAccounts as ErpAccount | undefined;
}
