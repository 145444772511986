import { useTranslation } from 'react-i18next';

import { InfoIcon } from 'icons';

/**
 * Component
 */
function ItemUnavailable() {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();

  /**
   * Render
   */
  return (
    <span
      className="pt-2 flex items-center text-primary-2-100 text-caption"
      data-testid="product-unavailable"
    >
      <InfoIcon className="pr-2" />
      {t('orders.notAvailable')}
    </span>
  );
}

export default ItemUnavailable;
