import { Skeleton } from '@mui/material';
import clsx from 'clsx';
import repeatMap from 'utils/repeatMap';

export type TableProps = {
  headers: Array<{ id: string; label: string }>;
  rowClassName: string;
  rowCount: number;
  columnClassName: string;
};

function TableLoading(props: TableProps) {
  /**
   * Render
   */
  return (
    <table
      className="w-full text-sm text-left rtl:text-right border-stone-700 divide-y divide-gray-200"
      data-testid="orders-table"
    >
      <thead>
        <tr className="bg-secondary-4-30 border-secondary-4-100">
          {props.headers.map((column, key) => (
            <th
              scope="col"
              className={clsx(
                'px-6 py-4 dark-text-gray-90 text-primary-3-100 items-center justify-center align-middle font-medium text-base',
                {
                  'text-right': column.id === 'orderTotal'
                }
              )}
              key={key}
            >
              {column.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {repeatMap(props.rowCount, (rowIndex) => (
          <tr className={props.rowClassName} key={`tr-${rowIndex}`}>
            {repeatMap(props.headers.length, (columnIndex) => (
              <td
                className={props.columnClassName}
                key={`td-${rowIndex}-${columnIndex}`}
              >
                <Skeleton />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default TableLoading;
