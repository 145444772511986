import { useState } from 'react';

import Button from 'components/Button';
import Card from 'components/Card';
import Modal from 'components/Modal';

/**
 * Config
 */
const display = {
  title: 'Modal'
};
const loremIpsum =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Purus semper eget duis at tellus at urna condimentum mattis. Lacus vel facilisis volutpat est. Lacus sed viverra tellus in hac habitasse. Ultrices neque ornare aenean euismod elementum nisi quis eleifend. Condimentum vitae sapien pellentesque habitant morbi tristique. Vel fringilla est ullamcorper eget nulla. Tellus rutrum tellus pellentesque eu tincidunt tortor aliquam nulla. Egestas congue quisque egestas diam in arcu. Eu lobortis elementum nibh tellus. Mi in nulla posuere sollicitudin aliquam ultrices sagittis orci a. Integer feugiat scelerisque varius morbi enim. Eu turpis egestas pretium aenean pharetra. Mi tempus imperdiet nulla malesuada pellentesque elit eget gravida cum. Tortor id aliquet lectus proin nibh. Purus sit amet volutpat consequat. Mi ipsum faucibus vitae aliquet nec ullamcorper sit amet. Dictum sit amet justo donec enim diam vulputate ut pharetra.';

/**
 * Component
 */
function ModalPage() {
  // state
  const [isOpen0, setIsOpen0] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const isAnyOpen = isOpen0 || isOpen1;
  // render
  return (
    <Card className="my-8 p-6" data-testid="component-page_modal">
      <h5 className="text-2xl font-bold text-secondary-2-100">
        {display.title}
      </h5>
      <hr className="my-4" />
      <Modal
        open={isOpen0}
        onClose={() => setIsOpen0(false)}
        width="sm"
        headerContent={
          <h5 className="text-primary-1-100 text-3xl font-medium">
            TEST MODAL 0
          </h5>
        }
        testId="modal0"
      >
        <span className="text-base">{loremIpsum}</span>
      </Modal>
      <Modal
        open={isOpen1}
        onClose={() => setIsOpen1(false)}
        fullScreen
        headerContent={
          <h5 className="text-primary-1-100 text-2xl font-medium">
            TEST MODAL 1
          </h5>
        }
        testId="modal1"
      >
        <span className="text-base">{loremIpsum}</span>
      </Modal>
      <div className="my-4 flex justify-center gap-2">
        <Button
          onClick={() => setIsOpen0(true)}
          disabled={isAnyOpen}
          color="lightBlue"
          kind="text"
          data-testid="open0"
        >
          Standard Modal
        </Button>
        <Button
          onClick={() => setIsOpen1(true)}
          disabled={isAnyOpen}
          color="lightBlue"
          kind="text"
          data-testid="open1"
        >
          Full Screen Modal
        </Button>
      </div>
    </Card>
  );
}

export default ModalPage;
