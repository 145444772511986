export const inputStyle = [
  'px-4 py-[11px] w-full w-full rounded',
  'text-secondary-2-100 bg-common-white text-base',
  'transition-all',
  'border border-solid border-secondary-3-100 truncate',
  'outline-none focus:shadow-input',
  '[&::placeholder]:text-secondary-3-100',
  '[&:hover&:not(:disabled)]:border-primary-2-100',
  '[&:focus&:not(:disabled)]:border-primary-2-100',
  '[&:focus&:not(:disabled)]:shadow-primary-2-50'
];
export const inputDisabled = 'bg-primary-3-10';
export const inputError = 'border-support-1-100';
export const messageError = 'text-support-1-100';
