import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material';
import {
  FieldValues,
  UseControllerProps,
  useController
} from 'react-hook-form';

import { HTMLInputProps, Modify } from '@reece/global-types';
import Label from 'components/Label';

/**
 * Types
 */
type NewProps = {
  disabled?: boolean;
  label?: string;
  options: FormRadioOption[];
  required?: boolean;
  testId?: string;
};
export type FormRadioOption = { label: string; value: string };
export type FormRadioInputProps<TFields extends FieldValues> = Modify<
  UseControllerProps<TFields>,
  NewProps
>;
export type RadioOptionProps = {
  item: FormRadioOption;
  index: number;
  testId?: string;
};

/**
 * Compoonents
 */
function FormRadioInput<T extends FieldValues>(props: FormRadioInputProps<T>) {
  /**
   * Props
   */
  const { disabled, label, options, required, testId, ...rest } = props;

  /**
   * Custom Hooks
   */
  const {
    field: { ref, onChange, ...restField },
    fieldState: { error }
  } = useController({ ...rest });

  /**
   * Render
   */
  return (
    <div className="flex gow flex-col w-full">
      {/* Label */}
      <Label
        label={label}
        required={required}
        htmlFor={props.name}
        testId={testId}
      />
      {/* Radio */}
      <FormControl component="fieldset" error={Boolean(error)} ref={ref}>
        <RadioGroup
          row
          id={props.name}
          onChange={(_, value) => onChange(value)}
          {...restField}
          className="h-10"
          data-testid={testId}
        >
          {options.map((item, index) => (
            <RadioOption
              item={item}
              index={index}
              testId={testId}
              key={`${props.name}-${item.label}`}
            />
          ))}
        </RadioGroup>
      </FormControl>
      {/* Message */}
      {Boolean(error) && (
        <span
          className="px-4 pt-1 text-support-1-100 text-xs sm:pt-0"
          data-testid={`${testId}-error-text`}
        >
          {error!.message}
        </span>
      )}
    </div>
  );
}

export default FormRadioInput;

/**
 * Sub-Component
 */
function RadioOption(props: RadioOptionProps) {
  /**
   * Props
   */
  const { index, item, testId } = props;
  const radioTestId = `${testId}-radio-${index}`;
  const radioProps = { 'data-testid': radioTestId } as HTMLInputProps;
  /**
   * Render
   */
  return (
    <FormControlLabel
      className="!ml-0"
      value={item.value}
      label={item.label}
      control={<Radio className="!mr-3" inputProps={radioProps} />}
    />
  );
}
