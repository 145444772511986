import { ReactNode } from 'react';

import { Card, Container } from '@mui/material';
import clsx from 'clsx';
import { kebabCase } from 'lodash-es';

import Breadcrumbs, { BreadcrumbConfig } from 'common/Breadcrumbs';
import useScreenSize from 'hooks/useScreenSize';
import { embedding } from 'utils/embedPage';

/**
 * Types
 */
export type TablePageLayoutProps = {
  breadcrumbConfig?: BreadcrumbConfig[];
  customContent?: ReactNode;
  filters?: ReactNode;
  headerAction?: ReactNode;
  invoicesPage?: boolean;
  loading?: boolean;
  pageTitle: string;
  table?: ReactNode;
  flatCards?: boolean;
};

/**
 * Component
 */
function TablePageLayout(props: TablePageLayoutProps) {
  const embeddingPage = embedding();
  /**
   * Custom hooks
   */
  const { isSmallScreen } = useScreenSize();

  /**
   * Render
   */
  return (
    <>
      {!embeddingPage && (
        <Breadcrumbs
          pageTitle={props.pageTitle}
          config={props.breadcrumbConfig}
        />
      )}

      {/* Header Section */}
      <Container
        disableGutters={isSmallScreen && props?.flatCards}
        data-testid="table-root"
      >
        {/* Filters Section */}
        {!props.invoicesPage && !embeddingPage && (
          <Card
            square={isSmallScreen && props?.flatCards}
            raised={!isSmallScreen || !props?.flatCards}
            className="px-8 py-6 mb-6 md:px-6 md:py-4"
          >
            <div className="grid grid-cols-6 gap-4">
              <div className="col-span-5 flex content-center justify-start md:col-span-6 md:justify-center">
                <h5
                  className="text-xl font-medium"
                  data-testid={kebabCase(`${props?.pageTitle}-title`)}
                >
                  {props.pageTitle}
                </h5>
              </div>
              {Boolean(props.headerAction) && (
                <div className="col-span-1 flex justify-end md:col-span-6 md:justify-start">
                  {props.headerAction}
                </div>
              )}
            </div>
          </Card>
        )}
        {props.customContent}
        {/* Table Section */}
        <Card
          square={isSmallScreen && props?.flatCards}
          raised={!isSmallScreen || !props?.flatCards}
          className={clsx({
            'pb-4': props.loading,
            'mb-6': !isSmallScreen || !props?.flatCards
          })}
        >
          {Boolean(props.filters) && (
            <div className="p-8 md:p-6">{props.filters}</div>
          )}
          {props.table}
        </Card>
      </Container>
    </>
  );
}

export default TablePageLayout;
