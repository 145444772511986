import { CSSProperties, useEffect, useRef } from 'react';

import { WrapperProps } from '@reece/global-types';

/**
 * Types
 */
export type VirtualizedListItemProps = WrapperProps & {
  setHeight: (height?: number) => void;
  wrapperStyle: CSSProperties;
  testId?: string;
};

/**
 * Component
 */
function VirtualizedListItem(props: VirtualizedListItemProps) {
  /**
   * Refs
   */
  const itemRef = useRef<HTMLDivElement>(null);

  /**
   * Effects
   */
  // 🟡 Effect - adjust item size
  useEffect(() => {
    props.setHeight(itemRef.current?.getBoundingClientRect().height);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Render
   */
  return (
    <div style={props.wrapperStyle} data-testid={props.testId}>
      <div ref={itemRef}>{props.children}</div>
    </div>
  );
}

export default VirtualizedListItem;
