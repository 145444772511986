import { useMemo } from 'react';

import { TableCell, TableHead, TableRow } from '@mui/material';
import clsx from 'clsx';

import { TableRendererProps } from 'common/TablePageLayout/TableRenderer';
import {
  SortingAscIcon,
  SortingDescIcon,
  SortingContractsNoneIcon
} from 'icons';

/**
 * Component
 */
function TableRendererHead<TData extends object>(
  props: TableRendererProps<TData>
) {
  /**
   * Props
   */
  const { cellClassName, headerClassName, isContracts, tableInstance } = props;
  const { columns, headerGroups } = tableInstance;

  /**
   * Memo
   */
  const headerRowRender = useMemo(
    () =>
      headerGroups.map((headerGroup) => (
        <TableRow
          {...headerGroup.getHeaderGroupProps()}
          key={`tableheader-row-${headerGroup.id}`}
        >
          {headerGroup.headers.map((column, i) => (
            <TableCell
              {...column.getHeaderProps(column.getSortByToggleProps())}
              align={column.align}
            >
              <div
                className={clsx(
                  cellClassName,
                  'flex flex-nowrap items-center gap-0 md:gap-2',
                  {
                    'justify-start': column.align !== 'right',
                    'justify-end': column.align === 'right'
                  }
                )}
              >
                <div className="block min-w-0">{column.render('Header')}</div>
                {column.isSorted ? (
                  <div className="block pl-2">
                    {column.isSortedDesc ? (
                      <SortingAscIcon
                        data-testid={`${columns[i]?.id}-ascending`}
                      />
                    ) : (
                      <SortingDescIcon
                        data-testid={`${columns[i]?.id}-descending`}
                      />
                    )}
                  </div>
                ) : (
                  isContracts && (
                    <div
                      className="block pl-2"
                      data-testid={`${columns[i]?.id}-sorting-off`}
                    >
                      <SortingContractsNoneIcon />
                    </div>
                  )
                )}
              </div>
            </TableCell>
          ))}
        </TableRow>
      )),
    [cellClassName, columns, headerGroups, isContracts]
  );

  /**
   * Render
   */
  return (
    <TableHead className={headerClassName} data-testid="table-head-wrapper">
      {headerRowRender}
    </TableHead>
  );
}

export default TableRendererHead;
