import { useEffect, useMemo } from 'react';

import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CircularProgress } from 'components';
import useBuilderIOGetAllAPI from 'pages/BuilderIO/lib/useBuilderIOGetAllAPI';
import FeaturedSearchItem from 'pages/Search/sub/FeaturedSearch/FeaturedSearchItem';
import useSearchQueryParams from 'pages/Search/util/useSearchQueryParams';

/**
 * Types
 */
export type FSItem = {
  name: string;
  image?: string;
  search?: string;
  url?: string;
};

/**
 * Component
 */
function FeaturedSearch() {
  /**
   * Custom Hooks
   */
  const [params] = useSearchQueryParams();
  const { criteria = '', page = '1' } = params;
  const { t } = useTranslation();

  /**
   * API
   */
  // 🟣 BuilderIO - popular categories contents
  const searchMatch = {
    searchText: criteria.toLowerCase()
  };
  const {
    contents: popularCategories,
    loading: popularCategoriesLoading,
    refetch
  } = useBuilderIOGetAllAPI(
    'featured-search-item',
    { data: { searchMatch } },
    !criteria || page !== '1'
  );

  /**
   * Memo
   */
  const items = useMemo(() => {
    if (page !== '1' || !popularCategories?.length) {
      return [];
    }
    // Restructure the data type
    return popularCategories.map(
      ({ image, name, overrideSearchCriteria: s }) =>
        ({ image, name, search: s || undefined } as FSItem)
    );
  }, [popularCategories, page]);

  /**
   * Effects
   */
  // 🟡 Effect - Refetch popular categories on search term change
  useEffect(() => {
    if (page === '1') {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [criteria, page]);

  /**
   * Render
   */
  if (popularCategoriesLoading && page === '1') {
    return (
      <div
        className="flex justify-center p-2"
        data-testid="featured-search-loader"
      >
        <CircularProgress size={28} />
      </div>
    );
  }
  if (!items.length) {
    return null;
  }
  return (
    <div data-testid="featured-search-container">
      <p className="text-primary-3-100 text-xl font-bold">
        {t('search.featuredSearchTitle')}
      </p>
      <div className="box-border flex flex-wrap -ml-4 px-4 py-2 w-[calc(100%+48px)]">
        {items.map((item, i) => (
          <FeaturedSearchItem item={item} key={`featured-search-${i}`} />
        ))}
      </div>
      <Divider />
    </div>
  );
}

export default FeaturedSearch;
