import { useCallback, useState } from 'react';

import { Maybe } from 'yup/lib/types';

/**
 * Creates a stateful ref so that we can use it in effects
 * Code from Joseph Campuzano
 */
export const useCallbackRef = <T>(init?: T) => {
  const [stateRef, setStateRef] = useState<Maybe<T>>(init ?? null);
  const refCallback = useCallback((node: T) => {
    node && setStateRef(node);
  }, []);
  return [stateRef, refCallback] as const;
};
