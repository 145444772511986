import { useState } from 'react';

import { Container, Dialog, DialogContent, DialogTitle } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  Alert,
  Button,
  CircularProgress,
  IconButton,
  FormTextInput
} from 'components';
import { useUpdateUserPasswordMutation } from 'generated/graphql';
import useScreenSize from 'hooks/useScreenSize';
import { CloseIcon, InfoIcon } from 'icons';
import { useAccountPageContext } from 'pages/Account/AccountPageProvider';
import usePasswordChangeForm, {
  PasswordForm
} from 'pages/Account/lib/usePasswordChangeForm';
import { useSnackbar } from 'providers/SnackbarProvider';

/**
 * Config
 */
const passwordPlaceholder = '............';

/**
 * Types
 */
export type Props = {
  open: boolean;
  onClose: () => void;
};

/**
 * Component
 */
function UpdatePasswordDialog(props: Props) {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();
  const { isSmallScreen } = useScreenSize();
  const { pushAlert } = useSnackbar();
  const history = useHistory();

  /**
   * Context
   */
  const { userInfo } = useAccountPageContext();

  /**
   * States
   */
  const [message, setMessage] = useState('');

  /**
   * Data
   */
  const [updateUserPassword, { loading: updatePasswordLoading }] =
    useUpdateUserPasswordMutation();

  /**
   * Form
   */
  const { control, formState, handleSubmit } = usePasswordChangeForm();

  /**
   * Callbacks
   */
  // 🟤 Cb - Submit
  const onSubmit = (form: PasswordForm) => {
    const updateUserPasswordInput = {
      userId: userInfo.id,
      oldUserPassword: form.oldPassword,
      newUserPassword: form.newPassword
    };
    updateUserPassword({ variables: { updateUserPasswordInput } })
      .then(() => {
        pushAlert(t('user.informationSaved'), { variant: 'success' });
        history.push('/account');
        props.onClose();
      })
      .catch((error) => {
        console.error(error);
        const message = t('user.updatePasswordFailed');
        if (!isSmallScreen) {
          pushAlert(message, { variant: 'error' });
          return;
        }
        setMessage(message);
        setTimeout(() => setMessage(''), 5000);
      });
  };

  /**
   * Render
   */
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="sm"
      fullWidth
      fullScreen={isSmallScreen}
    >
      <DialogTitle>
        <IconButton
          onClick={props.onClose}
          size="large"
          className="!absolute top-7 right-2"
          data-testid="button-close"
        >
          <CloseIcon />
        </IconButton>
        <div className="mt-6 pl-6">
          <h5 className="text-xl text-primary-1-100">
            {t('user.changePassword')}
          </h5>
        </div>
      </DialogTitle>
      <DialogContent>
        {Boolean(message) && (
          <div
            className="absolute w-full px-2.5 left-0 bottom-2.5 z-[99999]"
            data-testid="updatepassword-alert"
          >
            <Alert severity="error">{message}</Alert>
          </div>
        )}
        <Container className="pt-4 pb-8" data-testid="container">
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="flex flex-col gap-4">
              <FormTextInput
                type="password"
                name="oldPassword"
                label={t('common.oldPassword')}
                placeholder={passwordPlaceholder}
                control={control}
                testId="old-password-input"
                required
              />
              <FormTextInput
                type="password"
                name="newPassword"
                label={t('common.newPassword')}
                placeholder={passwordPlaceholder}
                control={control}
                testId="new-password-input"
                required
              />
              <FormTextInput
                type="password"
                name="confirmPassword"
                label={t('common.confirmNewPassword')}
                placeholder={passwordPlaceholder}
                control={control}
                testId="confirm-password-input"
                required
              />
            </div>
            <span
              className={clsx('flex items-center pt-4 text-base', {
                'text-error-100':
                  formState.errors.oldPassword || formState.errors.newPassword
              })}
            >
              <span className="mr-2">
                <InfoIcon />
              </span>
              {t('register.passwordRequirements')}
            </span>
            <div className="pt-2 pl-8 text-secondary-2-100 text-sm">
              <p>{t('register.minimum')}</p>
              <p>{t('register.atLeastOneLowercase')}</p>
              <p>{t('register.uppercase')}</p>
              <p>{t('register.number')}</p>
            </div>
            <div className="pt-6">
              <div className="grid grid-cols-2 justify-center">
                <Button
                  onClick={props.onClose}
                  kind="text"
                  fullWidth
                  data-testid="button-cancel"
                >
                  {t('common.cancel')}
                </Button>
                <Button
                  type="submit"
                  fullWidth
                  data-testid="button-submit"
                  disabled={updatePasswordLoading}
                >
                  {updatePasswordLoading ? (
                    <CircularProgress size={20} testId="loading" />
                  ) : (
                    t('common.submitChanges')
                  )}
                </Button>
              </div>
            </div>
          </form>
        </Container>
      </DialogContent>
    </Dialog>
  );
}

export default UpdatePasswordDialog;
