import { Dispatch } from 'react';

import {
  Box,
  Drawer,
  IconButton,
  Typography,
  useScreenSize
} from '@dialexa/reece-component-library';
import { useTranslation } from 'react-i18next';

import { Maybe, Category } from 'generated/graphql';
import { CloseIcon } from 'icons';
import CategoriesMenu from './CategoriesMenu';

/**
 * Types
 */
export type Props = {
  loading: boolean;
  catfilters: Maybe<Category>[];
  category: string;
  filtersOpen: boolean;
  onShowHide: Dispatch<boolean>;
};

export default function CategoriesFiltersMenu(props: Props) {
  /**
   * Custom Hooks
   */
  const { isSmallScreen } = useScreenSize();
  const { t } = useTranslation();

  return isSmallScreen ? (
    <Drawer
      PaperProps={{ sx: { bottom: 0 } }}
      anchor="top"
      open={props.filtersOpen}
      onClose={() => props.onShowHide(false)}
    >
      <Box display="flex" flexDirection="column" minHeight={1}>
        <Box
          px={3}
          pt={5.5}
          pb={1.5}
          display="flex"
          alignItems="center"
          sx={{
            borderBottom: 1,
            borderBottomColor: 'lighterGray.main'
          }}
        >
          <Box flex="1" />
          <Typography
            variant="h5"
            component="span"
            color="primary"
            sx={{ fontWeight: 700 }}
          >
            {t('common.filter')}
          </Typography>
          <Box display="flex" flex="1" justifyContent="flex-end">
            <IconButton
              onClick={() => props.onShowHide(false)}
              size="large"
              sx={{ p: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box flex="1" overflow="auto">
          <CategoriesMenu
            filters={props.catfilters!}
            category={props.category}
          />
        </Box>
      </Box>
    </Drawer>
  ) : (
    <CategoriesMenu filters={props.catfilters!} category={props.category} />
  );
}
