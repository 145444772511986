import {
  createContext,
  useMemo,
  useEffect,
  useState,
  useCallback,
  useContext
} from 'react';

import {
  AuthState,
  OktaAuth,
  SigninWithCredentialsOptions,
  UserClaims
} from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { noop } from 'lodash-es';
import { useHistory, useLocation } from 'react-router-dom';

import { WrapperProps } from '@reece/global-types';
import { EmbeddedSessionResponse } from 'API/types/embedded.types';
import ApolloClient from 'client';
import {
  ApprovedUser,
  Feature,
  Maybe,
  useFeaturesQuery,
  useSetFeatureEnabledMutation,
  useUserQuery
} from 'generated/graphql';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { selectedAccountsVar } from 'providers/SelectedAccountsProvider';
import { catchError } from 'utils/errorhandling';
import { embedding } from 'utils/embedPage';

/**
 * Config
 */
const defaultProfile: Profile = {
  permissions: [] as string[],
  userId: '',
  isEmployee: false,
  isVerified: false
};

/**
 * Types
 */
export type Profile = {
  permissions: Array<string>;
  userId: string;
  isEmployee: boolean;
  isVerified: boolean;
};
export type UserContextType = {
  activeFeatures?: string[];
  authState: AuthState | null;
  ecommUser?: Maybe<ApprovedUser>;
  features?: Feature[];
  featuresLoading?: boolean;
  firstName?: string;
  hasFeature?: (feature: string) => boolean;
  handleLogout?: (redirectTo?: string) => void;
  isLoggingOut?: boolean;
  isUserLoaded?: boolean;
  lastName?: string;
  login?: (values: SigninWithCredentialsOptions) => Promise<void>;
  oktaAuth?: OktaAuth;
  profile?: Profile;
  setFeature?: (featureId: string, isEnabled: boolean) => void;
  user?: UserClaims;
  setEmbeddedSessionResponse?: (
    embeddedSessionResponse: EmbeddedSessionResponse
  ) => void;
};

/**
 * Context
 */
export const defaultAuthContext: UserContextType = {
  authState: null,
  isLoggingOut: false,
  isUserLoaded: false,
  setEmbeddedSessionResponse: noop
};
export const AuthContext = createContext(defaultAuthContext);
export const useAuthContext = () => useContext(AuthContext);

/**
 * Provider
 */
function AuthProvider(props: WrapperProps) {
  /**
   * Custom hooks
   */
  const history = useHistory();
  const location = useLocation();
  const { oktaAuth, authState } = useOktaAuth();
  const [userLogged, setUserLogged] = useLocalStorage('userLogged', false);

  /**
   * States
   */
  const [user, setUser] = useState<UserClaims>();
  const [isUserLoaded, setIsUserLoaded] = useState(false);
  const [profile, setProfile] = useState<Profile>(defaultProfile);
  const [features, setFeatures] = useState<Feature[]>([]);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [embeddedSessionResponse, setEmbeddedSessionResponse] =
    useState<EmbeddedSessionResponse>();

  /**
   * Data
   */
  // 🟣 Query - Features
  const { loading: featuresLoading } = useFeaturesQuery({
    onCompleted: (res) => res.features && setFeatures(res.features)
  });
  // 🟣 Query - User
  const { data: userData } = useUserQuery({
    skip: !profile?.userId,
    variables: { userId: profile?.userId },
    onCompleted: () => setIsUserLoaded(true)
  });
  // 🟣 Mutation - Set Feature Enabled
  const [setFeatureEnabledMutation] = useSetFeatureEnabledMutation();

  /**
   * Memos
   */
  // 🔵 Memo - first name
  const firstName = useMemo(() => user?.name?.split(' ')[0] ?? '', [user]);
  // 🔵 Memo - last name
  const lastName = useMemo(() => user?.name?.split(' ')[1] ?? '', [user]);
  // 🔵 Memo - active features
  const activeFeatures = useMemo(
    () => features.filter(({ isEnabled }) => isEnabled).map(({ name }) => name),
    [features]
  );

  /**
   * Effects
   */
  // 🟡 Effect - fetch Okta user
  useEffect(() => {
    if (!embedding() && authState?.isAuthenticated && !isLoggingOut) {
      oktaAuth
        .getUser()
        .then((userData) => setUser(userData))
        .catch(catchError);
    }
  }, [authState?.isAuthenticated, isLoggingOut, oktaAuth]);
  // 🟡 Effect - get profile from authState
  useEffect(() => {
    // Check if the user is authenticated or there's embeddedOktaToken in sessionStorage
    if (
      (authState?.isAuthenticated && !isLoggingOut) ||
      (sessionStorage.getItem('embeddedOktaToken') && embeddedSessionResponse)
    ) {
      try {
        // Extracting token and data
        const embeddedOktaToken = sessionStorage.getItem('embeddedOktaToken');
        const oktaTokenSessionStored = embeddedOktaToken
          ? JSON.parse(embeddedOktaToken)?.accessToken?.accessToken
          : '';
        // Determine the access token to use
        const accessToken =
          authState?.accessToken?.accessToken ?? oktaTokenSessionStored;

        // Decode jwt token
        const decoded = accessToken
          ? window.atob(accessToken.split('.')[1])
          : '';
        const data = JSON.parse(decoded);
        const userId = data.ecommUserId ?? '';

        // apply data
        setProfile({
          permissions: data.ecommPermissions ?? [],
          userId,
          isEmployee: data.isEmployee ?? false,
          isVerified: data.isVerified ?? false
        });

        // local storage handling
        const currentUserId = localStorage.getItem('currentUserId') || '';
        currentUserId !== userId && localStorage.removeItem('defaultAccounts');
        localStorage.setItem('currentUserId', userId);
        localStorage.setItem('logged', 'true');
        setUserLogged(true);
      } catch (e) {
        catchError(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    authState?.accessToken,
    authState?.isAuthenticated,
    isLoggingOut,
    oktaAuth,
    embeddedSessionResponse
  ]);
  // 🟡 Effect - end log out state at root
  useEffect(() => {
    if (location.pathname === '/' && isLoggingOut) {
      setIsLoggingOut(false);
    }
  }, [isLoggingOut, location.pathname]);
  // 🟡 Effect - Redirect to home with unverified employee account
  useEffect(() => {
    const isNonVerifiedEmployee = profile.isEmployee && !profile.isVerified;
    const isInvalidPath = location.pathname !== '/';
    if (isNonVerifiedEmployee && isInvalidPath) {
      history.replace('/');
    }
  }, [history, location.pathname, profile]);

  /**
   * Callbacks
   */
  // 🟤 Cb - login
  const login = useCallback(
    async (values: SigninWithCredentialsOptions) => {
      const transaction = await oktaAuth.signInWithCredentials(values);
      oktaAuth.signInWithRedirect({ sessionToken: transaction.sessionToken });
    },
    [oktaAuth]
  );
  // 🟤 Cb - log out
  const handleLogout = async (redirectTo: string = '/') => {
    if (!authState?.isAuthenticated || isLoggingOut) {
      return;
    }
    setIsLoggingOut(true);
    selectedAccountsVar({});
    localStorage.removeItem('selectedAccounts');
    localStorage.removeItem('homeBranchID');
    localStorage.removeItem('homeBranch');
    localStorage.removeItem('branchID');
    localStorage.setItem('itemsAddedList', JSON.stringify([]));
    localStorage.removeItem('firstLogin');
    localStorage.removeItem('currentUserId');
    localStorage.removeItem('logged');
    localStorage.removeItem('userLogged');
    userLogged && setUserLogged(false);
    sessionStorage.clear();
    await oktaAuth.signOut({
      postLogoutRedirectUri: `${window.location.origin}${redirectTo}`
    });
    await ApolloClient.clearStore();
  };
  // 🟤 Cb - set feature
  const setFeature = (featureId: string, isEnabled: boolean) => {
    setFeatureEnabledMutation({
      variables: { featureId, setFeatureEnabledInput: { isEnabled } },
      refetchQueries: ['features']
    });
  };
  // 🟤 Cb - check if feature exists
  const hasFeature = useCallback(
    (feature: string) => activeFeatures?.includes(feature),
    [activeFeatures]
  );

  /**
   * Render
   */
  return (
    <AuthContext.Provider
      value={{
        activeFeatures,
        authState,
        ecommUser: userData?.user,
        features,
        featuresLoading,
        firstName,
        hasFeature,
        handleLogout,
        isLoggingOut,
        isUserLoaded,
        lastName,
        login,
        oktaAuth,
        profile,
        setFeature,
        user,
        setEmbeddedSessionResponse
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
