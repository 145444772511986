import { LinkedOrders } from 'API/types/orders.types';
import { useTranslation } from 'react-i18next';

export type OrderDetailsRelatedProps = {
  content: LinkedOrders[];
};

function OrderDetailsRelated(props: OrderDetailsRelatedProps) {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();

  /**
   * Render
   */
  return (
    <>
      {Boolean(props.content.length) && (
        <div
          className="bg-common-white rounded-md shadow-surround pt-4 pb-8 h-max"
          data-testid="order-linked-orders"
        >
          <div className="px-6 py-3">
            <p
              className="text-left text-primary-1-100 font-bold text-xl"
              data-testid="order-linked-orders-label"
            >
              {t('common.linkedOrders')}
            </p>
            <hr className="h-px my-2 px-5 bg-gray-200"></hr>
          </div>
          <div className="text-base leading-6 px-6 flex flex-col pb-4 space-y-5">
            <table className="min-w-full text-left">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="text-left"
                    data-testid="linked-orders-date-column"
                  >
                    {t('common.orderDate')}
                  </th>
                  <th
                    scope="col"
                    className="text-center"
                    data-testid="linked-orders-number-column"
                  >
                    {t('invoice.orderNumber')}
                  </th>
                  <th
                    scope="col"
                    className="text-right"
                    data-testid="linked-orders-total-column"
                  >
                    {t('common.orderTotal')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.content.map((item, i) => (
                  <tr key={`ordernumber-${i}`}>
                    <td
                      className="text-left"
                      data-testid={`linked-order-${i}-order-date`}
                    >
                      {item.orderDate}
                    </td>
                    <td
                      className="text-center underline"
                      data-testid={`linked-order-${i}-order-number`}
                    >
                      <a
                        href={`/order/${item.orderNumber}`}
                        className="text-primary-2-100"
                        data-testid={`linked-order-${i}-order-number-link`}
                      >
                        {item.orderNumber}
                      </a>
                    </td>
                    <td
                      className="text-right"
                      data-testid={`linked-order-${i}-order-total`}
                    >
                      {item.orderTotal}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}
export default OrderDetailsRelated;
