import { useMemo } from 'react';

import { isUndefined } from 'lodash-es';
import { DateRange } from 'react-day-picker';
import { useTranslation } from 'react-i18next';

import { Button } from 'components';
import useScreenSize from 'hooks/useScreenSize';

/**
 * Types
 */
export type FilterActionButtonsProps = {
  applied?: boolean;
  dirty?: boolean;
  hideApplyOnMobile: boolean;
  invoiceStatus?: string;
  onReset: () => void;
  onViewResults: () => void;
  range?: DateRange;
  resultsCount?: number;
  warningMessage?: string;
};

/**
 * Component
 */
function FilterActionButtons(props: FilterActionButtonsProps) {
  /**
   * Custom hooks
   */
  const { isSmallScreen } = useScreenSize();
  const { t } = useTranslation();

  /**
   * Memo
   */
  const buttonDisabled = useMemo(
    () =>
      !props.dirty ||
      props.warningMessage === 'error' ||
      ((props.invoiceStatus === 'All' || props.invoiceStatus === 'Closed') &&
        (!props.range?.from || !props.range?.to)),
    [
      props.dirty,
      props.invoiceStatus,
      props.range?.from,
      props.range?.to,
      props.warningMessage
    ]
  );

  /**
   * Render
   */
  return (
    <>
      {(!isSmallScreen || (isSmallScreen && !props.hideApplyOnMobile)) && (
        <div className="mt-8 basis-auto flex-grow-0 flex-shrink-0 w-auto md:mt-0 md:basis-full md:w-full md:max-w-full">
          <Button
            data-testid="filter-action-apply-button"
            onClick={props.onViewResults}
            color={props.applied ? 'green' : 'primary'}
            disabled={buttonDisabled}
            fullWidth
            className="mb-3"
          >
            {props.applied
              ? t('invoices.filterApplied')
              : t('common.viewResults')}
          </Button>
        </div>
      )}
      {Boolean(props.dirty && props.onReset) && (
        <div className="mt-8 basis-auto flex-grow-0 flex-shrink-0 w-auto md:mt-0 md:basis-full md:w-full md:max-w-full">
          <Button
            data-testid="filter-action-reset-button"
            kind="outline"
            onClick={props.onReset}
            fullWidth
            className="mb-3 md:mb-0"
          >
            {t('common.reset')}
          </Button>
        </div>
      )}
      {!isUndefined(props.resultsCount) && (
        <div className="mt-8 mb-3 py-2 basis-auto flex-grow-0 flex-shrink-0 w-auto md:my-0 md:basis-full md:w-full md:max-w-full">
          <p className="text-sm font-medium" data-testid="results">
            {`${props.resultsCount} ${t('common.result', {
              count: props.resultsCount
            })}`}
          </p>
        </div>
      )}
    </>
  );
}

export default FilterActionButtons;
