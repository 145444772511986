import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { useLoadScript } from '@react-google-maps/api';
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url';
import { isUndefined } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { StoreFinderBranch } from 'API/types/branches.types';
import {
  Maybe,
  MileRadiusEnum,
  useGetHomeBranchQuery
} from 'generated/graphql';
import { useCallbackRef } from 'hooks/useCallbackRef';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { useGeolocation } from 'hooks/useGeolocation';
import { useAuthContext } from 'providers/AuthProvider';
import { Divisions } from 'providers/BranchProvider';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';
import { trackLocationSearchPageViewAction } from 'utils/analytics';
import { Configuration } from 'utils/configuration';
import { stateCode } from 'utils/states';
import useStoreFinderApi from 'pages/LocationSearch/lib/useStoreFinderApi';

/**
 * Config
 */
const loadScriptConfig = {
  googleMapsApiKey: Configuration.googleApiKey || 'no-key',
  libraries: ['places'] as Libraries
};

/**
 * Types
 */
export type Location = { lat: number; lng: number };
export type SelectedBranch = {
  branch?: StoreFinderBranch;
  latLng: Maybe<google.maps.LatLng>;
};
type Autocomplete = google.maps.places.Autocomplete;

/**
 * Hook
 */
function useLocationSearchLogics() {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();
  const { isLoaded: googleLoaded } = useLoadScript(loadScriptConfig);
  const { position, positionLoading } = useGeolocation({
    enableHighAccuracy: true
  });
  useDocumentTitle(t('common.findALocation'));

  /**
   * Refs
   */
  const [inputEl, inputElCallback] = useCallbackRef<HTMLInputElement>();
  const refResults = useRef<HTMLDivElement>(null);
  const mapInstance = useRef<Maybe<google.maps.Map>>(null);

  /**
   * Context
   */
  const { selectedAccounts } = useSelectedAccountsContext();
  const { profile, user } = useAuthContext();
  const billToAccountId = selectedAccounts.billToErpAccount?.erpAccountId ?? '';
  const userEmail = user?.email ?? '';

  /**
   * State
   */
  const [autocomplete, setAutocomplete] = useState<Autocomplete>();
  const [inputError, setInputError] = useState(false);
  const [place, setPlace] = useState<Location>();
  const [page, setPage] = useState(1);
  const [isMapView, setIsMapView] = useState(false);
  const [filter, setFilter] = useState<Divisions>(Divisions.ALL);
  const [selectedBranch, setSelectedBranch] = useState<SelectedBranch>();
  const [testOnMapLoad, setTestOnMapLoad] = useState(false);
  const [distance, setDistance] = useState<MileRadiusEnum>(
    MileRadiusEnum.Miles_25
  );

  /**
   * Data
   */
  const {
    branches,
    called: branchesCalled,
    loading: branchesLoading,
    latitude,
    longitude
  } = useStoreFinderApi({
    distance,
    filter,
    place,
    position,
    positionLoading,
    onCompleted: () => {
      setPage(1);
      trackLocationSearchPageViewAction({
        billTo: billToAccountId,
        userEmail
      });
    }
  });

  // 🟣 Query - Home Branch
  const { data: homeBranchQuery, loading: homeBranchLoading } =
    useGetHomeBranchQuery({
      variables: { shipToAccountId: selectedAccounts?.shipTo?.id ?? '' },
      skip: !position && positionLoading
    });

  /**
   * Memos
   */
  // 🔵 Memo - filter branch by division
  const filteredData = useMemo(
    () =>
      branches.reduce<StoreFinderBranch[]>((prev, b) => {
        if (!b.longitude || !b.latitude) {
          return prev;
        }
        const isAll = filter === Divisions.ALL;
        const isWaterworks = filter === Divisions.WATERWORKS && b.isWaterworks;
        const isHvac = filter === Divisions.HVAC && b.isHvac;
        const isPlumbing = filter === Divisions.PLUMBING && b.isPlumbing;
        const isBandK = filter === Divisions.BANDK && b.isBandK;
        if (isAll || isWaterworks || isHvac || isPlumbing || isBandK) {
          prev.push(b);
        }
        return prev;
      }, []),
    [filter, branches]
  );

  /**
   * Callbacks
   */
  // 🟤 Cb - Map load
  const onMapLoad = useCallback((mapInstanceVal: google.maps.Map) => {
    // 🔸 testOnMapLoad is used for unit test because ref is very flakey for that
    setTestOnMapLoad(true);
    mapInstance.current = mapInstanceVal;
  }, []);
  // 🟤 Cb - Autocomplete focus
  const handleAutocompleteFocus = () => {
    setInputError(false);
    inputEl && (inputEl.value = '');
  };
  // 🟤 Cb - Apply filter
  const handleApplyFilter = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value as Divisions);
    setSelectedBranch(undefined);
    setPage(1);
  };
  // 🟤 Cb - Distant change
  const handleDistanceChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDistance(e.target.value as MileRadiusEnum);
    setSelectedBranch(undefined);
  };
  // 🟤 Cb - Place changed
  const handlePlaceChanged = () => {
    if (!autocomplete || !inputEl) {
      return;
    }
    setSelectedBranch(undefined);
    const placeRes = autocomplete.getPlace();
    if (!isUndefined(placeRes?.place_id)) {
      setPlace({
        lat: placeRes.geometry?.location?.lat() ?? 0,
        lng: placeRes.geometry?.location?.lng() ?? 0
      });
      return;
    }
    /**
     * If the user pressed 'Enter' instead of clicking on a suggestion
     * then manually query the Places API with the user input
     */
    inputEl.blur();
    const predictionsRequest: google.maps.places.AutocompletionRequest = {
      input: inputEl.value,
      types: ['(regions)'],
      componentRestrictions: { country: ['us'] }
    };
    const autocompleteService = new google.maps.places.AutocompleteService();
    autocompleteService.getPlacePredictions(
      predictionsRequest,
      handlePredictions
    );
  };

  /**
   * Effects
   */
  // 🟡 Effect - set coord based on position or homeBranch
  useEffect(() => {
    if (position && inputEl) {
      inputEl.value = t('locationSearch.current');
      setPlace({
        lat: position.coords.latitude ?? 0,
        lng: position.coords.longitude ?? 0
      });
    } else if (inputEl && profile?.userId) {
      inputEl.value = t('locationSearch.homeBranchLocation');
      if (homeBranchQuery) {
        const { city, state } = homeBranchQuery.homeBranch;
        inputEl.value = `${city}, ${stateCode(state ?? '') ?? state}`;
      }
      setPlace({
        lat: homeBranchQuery?.homeBranch.latitude ?? 0,
        lng: homeBranchQuery?.homeBranch.longitude ?? 0
      });
    }
  }, [
    autocomplete,
    position,
    t,
    inputEl,
    homeBranchQuery?.homeBranch,
    profile?.userId,
    homeBranchQuery
  ]);
  // 🟡 Effect - Scroll to top when page changes
  useEffect(() => {
    page && refResults.current?.scrollIntoView();
    page && window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [page, refResults]);

  /**
   * Output
   */
  return {
    autocomplete,
    branchesCalled,
    branchesLoading,
    distance,
    filter,
    filteredData,
    branches,
    googleLoaded,
    handleApplyFilter,
    handleAutocompleteFocus,
    handleDistanceChange,
    handlePlace,
    handlePlaceChanged,
    handlePredictions,
    homeBranchLoading,
    homeBranchQuery,
    inputEl,
    inputElCallback,
    inputError,
    isMapView,
    latitude,
    longitude,
    mapInstance,
    onMapLoad,
    page,
    place,
    position,
    positionLoading,
    refResults,
    selectedBranch,
    setAutocomplete,
    setDistance,
    setFilter,
    setInputError,
    setIsMapView,
    setPage,
    setPlace,
    setSelectedBranch,
    testOnMapLoad
  };

  /**
   * Util
   */
  // 🔸 Splitting these off due to the complex nature of GMaps namespace for unit test
  // 🔠 Util - GMap Handle Predictions
  function handlePredictions(
    predictions: Maybe<google.maps.places.AutocompletePrediction[]>,
    status: google.maps.places.PlacesServiceStatus
  ) {
    if (!inputEl) {
      return;
    }
    if (!predictions || status !== google.maps.places.PlacesServiceStatus.OK) {
      setInputError(true);
      setPlace(undefined);
      return;
    }
    const prediction = predictions[0].description;
    inputEl.value = prediction;
    const placesRequest: google.maps.places.FindPlaceFromQueryRequest = {
      query: inputEl.value,
      fields: ['geometry']
    };

    const placesService = new google.maps.places.PlacesService(
      mapInstance.current ?? document.createElement('div')
    );

    placesService.findPlaceFromQuery(placesRequest, handlePlace);
  }
  // 🔠 Util - GMap Handle Place
  function handlePlace(
    places: Maybe<google.maps.places.PlaceResult[]>,
    status: google.maps.places.PlacesServiceStatus
  ) {
    if (!places || status !== google.maps.places.PlacesServiceStatus.OK) {
      console.error('Places Request', status);
      return;
    }
    setPlace({
      lat: places[0].geometry?.location?.lat() ?? 0,
      lng: places[0].geometry?.location?.lng() ?? 0
    });
  }
}
export default useLocationSearchLogics;
