import { useTranslation } from 'react-i18next';

import { Button, Highlighted } from 'components';

type AddressAutocompleteProps = {
  input: string;
  predictions: google.maps.places.AutocompletePrediction[];
  onSelect(prediction?: google.maps.places.AutocompletePrediction): void;
};

const MAX_PREDICTIONS = 3;

function AddressAutocomplete(props: AddressAutocompleteProps) {
  const { input = '', predictions, onSelect } = props;

  /**
   * Custom Hooks
   */
  const { t } = useTranslation();

  /**
   * Render
   */
  if (!input) {
    return null;
  }

  return (
    <div className="flex w-[calc(100%_-_16px)] py-4 rounded flex-col border border-etc-light-gray absolute bg-common-white z-10">
      {predictions.reduce<JSX.Element[]>((prev, prediction, index) => {
        if (index < MAX_PREDICTIONS) {
          prev.push(
            <Button
              type="button"
              kind="text"
              onClick={() => onSelect(prediction)}
              tabIndex={0}
              key={prediction.place_id}
              className="flex flex-col p-2 pl-4 !items-start text-base gap-y-2 select-none cursor-pointer text-secondary-2-100 hover:!bg-etc-light-blue focus:!bg-etc-light-blue focus:outline-transparent"
            >
              <Highlighted
                highlightClassName="text-primary-3-100 font-bold"
                data-testid={`checkout-address-autocomplete-${index}`}
                query={input}
                text={prediction.structured_formatting.main_text}
              />
              <span className="hover:!text-secondary-2-100">
                {prediction.structured_formatting.secondary_text}
              </span>
            </Button>
          );
        }
        return prev;
      }, [])}

      <Button
        color="lightBlue"
        type="button"
        kind="text"
        onClick={() => onSelect()}
        className="flex flex-col p-2 gap-y-2 select-none font-medium text-sm justify-center items-center"
      >
        {t('validation.enterMannually')}
      </Button>
    </div>
  );
}

export default AddressAutocomplete;
