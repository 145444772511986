import { useMemo } from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import img1 from 'images/posh/mission/mission1.jpg';
import img2 from 'images/posh/mission/mission2.jpg';
import img3 from 'images/posh/mission/mission3.jpg';
import img4 from 'images/posh/mission/mission4.jpg';
import img5 from 'images/posh/mission/mission5.jpg';

/**
 * Tyoes
 */
type MissionItem = {
  image: string;
  title: string;
  description: string;
};

/**
 * Component
 */
function PoshMission() {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();

  /**
   * Memo
   */
  const list: MissionItem[] = useMemo(
    () => [
      {
        image: img1,
        title: t('poshMarketing.mission1Title'),
        description: t('poshMarketing.mission1')
      },
      {
        image: img2,
        title: t('poshMarketing.mission2Title'),
        description: t('poshMarketing.mission2')
      },
      {
        image: img3,
        title: t('poshMarketing.mission3Title'),
        description: t('poshMarketing.mission3')
      },
      {
        image: img4,
        title: t('poshMarketing.mission4Title'),
        description: t('poshMarketing.mission4')
      },
      {
        image: img5,
        title: t('poshMarketing.mission5Title'),
        description: t('poshMarketing.mission5')
      }
    ],
    [t]
  );

  /**
   * Render
   */
  return (
    <section
      className="flex flex-col bg-common-white text-etc-posh-4139"
      data-testid="posh-section-mission"
    >
      {list.map((item, index) => (
        <div
          className={clsx('flex md:flex-col', {
            'flex-row-reverse': Boolean(index % 2)
          })}
          key={`posh-mission-${index}`}
        >
          <div
            className="w-1/2 h-[500px] bg-cover bg-center md:w-full md:h-[260px]"
            style={{ backgroundImage: `url(${item.image})` }}
          />
          <div className="w-1/2 px-24 py-24 flex flex-col justify-center md:w-full md:px-9 md:py-7">
            <p className="mb-10 text-[64px] leading-[1.2] font-bold md:text-[25px] md:text-center">
              {item.title}
            </p>
            <p className="text-[22px] leading-[1.2] font-medium tracking-[0.6] md:text-xl md:font-medium md:tracking-[1]">
              {item.description}
            </p>
          </div>
        </div>
      ))}
    </section>
  );
}

export default PoshMission;
