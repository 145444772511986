import { useEffect, useState } from 'react';

import { useSnackbar } from '@dialexa/reece-component-library';
import { ArrowBack } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Link, Route, Switch, useLocation, useHistory } from 'react-router-dom';

import useSearchParam from 'hooks/useSearchParam';
import Loader from 'components/Loader';
import {
  ErpSystemEnum,
  GetUserInviteQuery,
  useGetUserInviteLazyQuery
} from 'generated/graphql';
import Button from 'old-components/Button';
import Container from 'old-components/Container';
import Stepper from 'old-components/Stepper';
import accountRegistration from 'images/account-registration.png';
import StepOne from 'Register/StepOne';
import StepTwo from 'Register/StepTwo';
import StepThree from 'Register/StepThree';
import StepFour from 'Register/StepFour';
import StepFive from 'Register/StepFive';
import { testIds } from 'test-utils/testIds';
import { trackRegisterPageViewAction } from 'utils/analytics';
import 'Register/styles.scss';

/**
 * Types
 */
export interface InvitedUserType {
  accountNumber: string;
  email: string;
  firstName: string;
  lastName: string;
  fortilineCustomer?: boolean;
  invitedId: string;
}

/**
 * Constants
 */
const TEST_IDS = testIds.Register;

/**
 * Component
 */
function NewRegister() {
  /**
   * Custom Hooks
   */
  const { pathname } = useLocation();
  const inviteId = useSearchParam('inviteId');
  const { pushAlert } = useSnackbar();
  const { t } = useTranslation();
  const history = useHistory();

  /**
   * States
   */
  const [activeStep, setActiveStep] = useState(0);
  const [pageTitle, setPageTitle] = useState('');

  /**
   * Data
   */
  const [getUserInvite, { loading: inviteLoading }] = useGetUserInviteLazyQuery(
    {
      onCompleted: onUserInviteQueryCompleted,
      onError: () => {
        pushAlert(t('register.invalidInvite'), { variant: 'error' });
        history.replace({ pathname: '/register/step-1', search: '' });
      }
    }
  );

  /**
   * Effects
   */
  useEffect(
    pathnameEffect,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname, inviteId]
  );

  /**
   * Render
   */
  return inviteId && inviteLoading ? (
    <Loader />
  ) : (
    <div className="register" data-testid={TEST_IDS.page}>
      {/* Banner */}
      <div className="register__banner">
        <Container className="register__banner__container">
          <Link to="/" className="register__banner__button-container">
            <Button
              icon={<ArrowBack />}
              label={t('common.backToSite')}
              variant="text-link-dark"
            />
          </Link>
          <h1 className="register__banner__title">
            {t('common.registerForMaX')}
          </h1>
        </Container>
      </div>
      {/* Content */}
      <Container className="register__container">
        {/* Left half */}
        <Container className="register__content" maxWidth="sm">
          {/* Mobile back button */}
          <Link to="/" className="register__content__button-container">
            <Button
              icon={<ArrowBack />}
              label={t('common.backToSite')}
              variant="text-link-dark"
            />
          </Link>

          {/* Screen stepper */}
          <Stepper
            steps={[
              t('common.emailAddress'),
              t('register.companyInfo'),
              t('register.accountInfo'),
              t('register.contactInfo'),
              t('register.completeRegistration')
            ]}
            activeStep={activeStep}
          />
          {/* Screen title */}
          <h1 className="register__content__title">{pageTitle}</h1>
          {/* Screen subroutes */}
          <Switch>
            <Route exact path={`/register/step-1`}>
              <StepOne />
            </Route>
            <Route exact path={'/register/step-2'}>
              <StepTwo />
            </Route>
            <Route exact path={'/register/step-3'}>
              <StepThree />
            </Route>
            <Route exact path={'/register/step-4'}>
              <StepFour />
            </Route>
            <Route exact path={'/register/step-5'}>
              <StepFive />
            </Route>
          </Switch>
        </Container>
        {/* Right half */}
        <img
          className="register__image"
          src={accountRegistration}
          alt="reece"
        />
      </Container>
    </div>
  );
  /**
   * Effect Defs
   */
  function pathnameEffect() {
    const stepNames = [
      '/register/step-1',
      '/register/step-2',
      '/register/step-3',
      '/register/step-4',
      '/register/step-5'
    ];
    const stepTitles = [
      t('register.whatIsEmail'),
      t('register.companyInformation'),
      t('register.accountInformation'),
      t('register.contactInformation'),
      t('register.completeRegistration')
    ];
    if (inviteId) {
      getUserInvite({ variables: { inviteId } });
    }
    const stepIndex = stepNames.indexOf(pathname);
    if (stepIndex >= 0) {
      setActiveStep(stepIndex);
      setPageTitle(stepTitles[stepIndex]);
      if (stepIndex === 0) {
        trackRegisterPageViewAction();
      }
    } else if (!inviteId) {
      history.replace('/register/step-1');
    }
  }

  /**
   * Data Callbacks
   */
  function onUserInviteQueryCompleted({ userInvite }: GetUserInviteQuery) {
    if (userInvite?.completed) {
      pushAlert(t('register.invalidInvite'), { variant: 'error' });
      history.replace({ pathname: '/register/step-1', search: '' });
    } else {
      const invitedUserInfo: InvitedUserType = {
        email: userInvite?.email ?? '',
        firstName: userInvite?.firstName ?? '',
        lastName: userInvite?.lastName ?? '',
        accountNumber: userInvite?.erpAccountId ?? '',
        fortilineCustomer:
          userInvite?.erpSystemName === ErpSystemEnum.Mincron ?? false,
        invitedId: userInvite?.id ?? ''
      };
      history.replace({
        pathname: '/register/step-4',
        search: '',
        state: {
          email: userInvite?.email ?? '',
          invitedUserInfo: invitedUserInfo
        }
      });
    }
  }
}

export default NewRegister;
