import { Modify } from '@reece/global-types';
import { DetailedHTMLProps, LabelHTMLAttributes, ReactNode } from 'react';

/**
 * Types
 */
type HTMLLabelProps = DetailedHTMLProps<
  LabelHTMLAttributes<HTMLLabelElement>,
  HTMLLabelElement
>;
type NewProps = {
  label?: string | ReactNode;
  required?: Boolean;
  testId?: string;
};
export type LabelProps = Modify<HTMLLabelProps, NewProps>;

/**
 * Component
 */
function Label(props: LabelProps) {
  /**
   * Props
   */
  const { label, required, testId, ...rest } = props;

  /**
   * Render
   */
  if (!label) {
    return null;
  }
  return (
    <label
      className="block mb-2 w-max text-sm md:text-base"
      data-testid={`${testId}-label`}
      {...rest}
    >
      {label}
      {Boolean(required) && (
        <span
          className="text-support-1-100 pl-1"
          data-testid={`${testId}-required`}
        >
          *
        </span>
      )}
    </label>
  );
}

export default Label;
