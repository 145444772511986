export const baseStyle =
  'inline-flex items-center justify-center align-middle font-medium transition-all rounded-sm cursor-pointer';

export const sizeStyles = {
  sm: 'px-4 py-1 text-sm min-w-[64px] leading-[1.375rem] [&_svg]:w-[18px] [&_svg]:h-[18px]',
  md: 'px-6 py-2 text-base min-w-[64px] [&_svg]:w-5 [&_svg]:h-5',
  lg: 'px-8 py-4 text-base min-w-[64px] [&_svg]:w-6 [&_svg]:h-6'
};

export const disabledStyles = {
  fill: 'disabled:bg-secondary-3-60 disabled:text-primary-3-40 disabled:cursor-default',
  text: 'disabled:text-secondary-3-60 disabled:cursor-default',
  outline:
    'disabled:text-secondary-3-60 disabled:shadow-secondary-3-60 disabled:cursor-default'
};

export const baseOutlineStyle = 'shadow-[inset_0_0_0_1px_rgba(0,0,0,0)] ';
export const themedStyles = {
  fill: {
    primary: 'text-common-white bg-primary-1-100 hover:bg-primary-1-90',
    secondary: 'text-primary-1-100  bg-primary-2-10 hover:bg-primary-2-20',
    gray: 'text-common-white bg-primary-3-100 hover:bg-primary-3-90',
    lightBlue: 'text-common-white bg-primary-2-100 hover:bg-primary-2-90',
    yellow: 'text-common-black bg-secondary-1-60 hover:bg-secondary-1-90',
    green: 'text-common-white bg-success-100 hover:bg-success-90',
    red: 'text-common-white bg-support-1-100 hover:bg-support-1-90'
  },
  text: {
    primary: 'text-primary-1-100 bg-transparent hover:text-primary-1-80',
    secondary: 'text-primary-2-10 bg-transparent hover:text-primary-2-30',
    gray: 'text-primary-3-100 bg-transparent hover:text-primary-3-80',
    lightBlue: 'text-primary-2-100 bg-transparent hover:text-primary-2-80',
    yellow: 'text-secondary-1-100 bg-transparent hover:text-secondary-1-80',
    green: 'text-success-100 bg-transparent hover:text-success-80',
    red: 'text-support-1-100 bg-transparent hover:text-support-1-80'
  },
  outline: {
    primary:
      baseOutlineStyle +
      'text-primary-1-100 bg-transparent hover:text-primary-1-80 shadow-primary-1-100',
    secondary:
      baseOutlineStyle +
      'text-primary-2-10 bg-transparent hover:text-primary-2-30 shadow-primary-2-10',
    gray:
      baseOutlineStyle +
      'text-primary-3-100 bg-transparent hover:text-primary-3-80 shadow-primary-3-100',
    lightBlue:
      baseOutlineStyle +
      'text-primary-2-100 bg-transparent hover:text-primary-2-80 shadow-primary-2-100',
    yellow:
      baseOutlineStyle +
      'text-secondary-1-100 bg-transparent hover:text-secondary-1-80 shadow-secondary-1-100',
    green:
      baseOutlineStyle +
      'text-success-100 bg-transparent hover:text-success-80 shadow-success-100',
    red:
      baseOutlineStyle +
      'text-support-1-100 bg-transparent hover:text-error-80 shadow-support-1-100'
  }
};
