import { useState } from 'react';

import { CardHeader, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Button, Card, IconButton } from 'components';
import { EditIcon } from 'icons';
import { useAccountPageContext } from 'pages/Account/AccountPageProvider';
import UpdatePasswordDialog from 'pages/Account/sub/UpdatePasswordDialog';
import UserInfoText from 'pages/Account/sub/UserInfoText';

/**
 * Component
 */
function ViewAccount() {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();

  /**
   * Context
   */
  const { handleStartEditing, userInfo, isEmployee } = useAccountPageContext();

  /**
   * State
   */
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);

  /**
   * Callbacks
   */
  const handlePasswordDialogOpen = (open: boolean) => () =>
    setPasswordDialogOpen(open);

  /**
   * Render
   */
  return (
    <div data-testid="viewaccount-wrapper">
      <UpdatePasswordDialog
        open={passwordDialogOpen}
        onClose={handlePasswordDialogOpen(false)}
      />
      <Card className="md:rounded-none md:shadow-none">
        <div className="p-2">
          <CardHeader
            action={
              !isEmployee && (
                <IconButton
                  onClick={handleStartEditing}
                  size="large"
                  data-testid="editaccount-button"
                >
                  <EditIcon />
                </IconButton>
              )
            }
            title={t('common.accountInformation')}
            titleTypographyProps={{
              className: '!text-2xl font-medium md:!text-xl'
            }}
            data-testid="account-information-title"
          />
        </div>
        <Divider />
        <UserInfoText
          fieldName={` ${t('common.name')}: `}
          fieldValue={`${userInfo.firstName} ${userInfo.lastName}`}
          testId="name-field"
        />
        <div className="px-4">
          <Divider />
        </div>
        <UserInfoText
          fieldName={` ${t('common.phoneNumber')}:`}
          fieldValue={userInfo.phoneNumber}
          testId="phone-number-field"
        />
        <div className="px-4">
          <Divider />
        </div>
        <UserInfoText
          fieldName={` ${t('common.email')}:`}
          fieldValue={userInfo.email}
          testId="email-field"
        />
        <div className="px-4">
          <Divider />
        </div>
        <UserInfoText
          fieldName={` ${t('common.role')}: `}
          fieldValue={userInfo.role}
          testId="role-field"
        />
        <div className="pr-4 pl-10 pb-10 md:pl-6">
          <Button
            onClick={handlePasswordDialogOpen(true)}
            color="lightBlue"
            kind="text"
            className="!p-0"
            data-testid="change-password-button"
          >
            {t('user.changePassword')}
          </Button>
        </div>
      </Card>
    </div>
  );
}

export default ViewAccount;
