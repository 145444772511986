import {
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Skeleton
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Button, IconButton } from 'components';
import { Invoice, ProofOfDeliveryResponse } from 'generated/graphql';
import useScreenSize from 'hooks/useScreenSize';
import { CloseIcon } from 'icons';

/**
 * Types
 */
export type Props = {
  open: boolean;
  onClose: () => void;
  invoice?: Invoice;
  invoiceNumber?: string;
  orderNumber?: string;
  pod?: ProofOfDeliveryResponse;
  podLoading?: boolean;
};

/**
 * Component
 */
function ProofOfDeliveryModal(props: Props) {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();
  const { isSmallScreen } = useScreenSize();

  /**
   * Render
   */
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="xs"
      fullWidth
      fullScreen={isSmallScreen}
    >
      <DialogTitle>
        <IconButton
          onClick={props.onClose}
          size="large"
          className="!absolute top-2 right-2"
          data-testid="pod-close-icon"
        >
          <CloseIcon />
        </IconButton>
        <div className="mt-2" data-testid="pod-title">
          <h5 className="text-common-black">{t('invoice.proofOfDelivery')}</h5>
        </div>
      </DialogTitle>
      <DialogContent>
        <Container className="!px-0 pt-4 pb-4" data-testid="pod-container">
          <div className="flex flex-col gap-4">
            <div className="!py-2 bg-secondary-4-100">
              <span className="px-2.5 text-primary-1-100 font-medium">
                {t('common.orderInformation')}
              </span>
            </div>
            <div className="!py-2">
              <div className="grid grid-cols-2 gap-4">
                <div className="px-2.5 text-primary-3-100  font-medium col-span-1">
                  {t('invoice.orderNumber')}
                </div>
                {props.podLoading ? (
                  <Skeleton width={150} height={25} />
                ) : (
                  <div className="text-primary-3-100 col-span-1">
                    {props.pod?.invoiceNumber ?? props.orderNumber}
                  </div>
                )}
                <div className="px-2.5 text-primary-3-100  font-medium col-span-1">
                  {t('invoice.invoiceNumber')}
                </div>
                {props.podLoading ? (
                  <Skeleton width={150} height={25} />
                ) : (
                  <div className="text-primary-3-100 col-span-1">
                    {props.pod?.invoiceNumber ?? props.invoiceNumber}
                  </div>
                )}
              </div>
            </div>

            <div className="!py-2 bg-secondary-4-100">
              <span className="px-2.5 text-primary-1-100 font-medium">
                {t('contract.customerInformation')}
              </span>
            </div>
            <div className="!py-2">
              <div className="grid grid-cols-2 gap-4">
                <div className="px-2.5 text-primary-3-100  font-medium col-span-1">
                  {t('contract.customerName')}
                </div>
                {props.podLoading ? (
                  <Skeleton width={150} height={40} />
                ) : (
                  <div className="text-primary-3-100 col-span-1">
                    {props.pod?.custName}
                  </div>
                )}

                <div className="px-2.5 text-primary-3-100  font-medium col-span-1">
                  {t('invoice.deliveryAddress')}
                </div>
                {props.podLoading ? (
                  <Skeleton width={150} height={40} />
                ) : (
                  <div className="pr-12 text-primary-3-100 col-span-1">
                    <div>{props.pod?.locationAddress}</div>
                    <span>
                      {Boolean(props.pod?.locationCity) &&
                        `${props.pod?.locationCity}, ${props.pod?.locationState} ${props.pod?.locationPostalCode}`}
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="!py-2 bg-secondary-4-100">
              <span className="px-2.5 text-primary-1-100 font-medium">
                {t('invoice.recipientInformation')}
              </span>
            </div>
            <div className="!py-2">
              <div className="grid grid-cols-2 gap-4">
                <div className="px-2.5 text-primary-3-100  font-medium col-span-1">
                  {t('invoice.deliveryDateTime')}
                </div>
                {props.podLoading ? (
                  <Skeleton width={150} height={25} />
                ) : (
                  <div className="text-primary-3-100 col-span-1">
                    <span>{`${props.pod?.deliveryDate} at ${props.pod?.deliveryTime}`}</span>
                  </div>
                )}
                <div className="px-2.5 text-primary-3-100  font-medium col-span-1">
                  {t('invoice.recipientName')}
                </div>
                {props.podLoading ? (
                  <Skeleton width={150} height={25} />
                ) : (
                  <div className="text-primary-3-100 col-span-1">
                    {props.pod?.recipientName}
                  </div>
                )}
                <div className="px-2.5 text-primary-3-100  font-medium col-span-1">
                  {t('invoice.recipientSignature')}
                </div>
                {props.podLoading ? (
                  <Skeleton width={150} height={25} />
                ) : (
                  <div className="text-primary-3-100 col-span-1">
                    {Boolean(props.pod?.signatureImage) && (
                      <img
                        className="max-w-[110px] max-h-[32px]"
                        alt="signature"
                        src={`data:image/png;base64,${props.pod?.signatureImage}`}
                        data-testid="pod-signature"
                      />
                    )}
                  </div>
                )}
                <div className="px-2.5 text-primary-3-100  font-medium col-span-2">
                  {t('invoice.deliveryPhoto')}
                </div>
              </div>

              <div className="px-2.5 pt-4 grid grid-cols-4 gap-2">
                {Boolean(props.pod?.deliveryPhotos) &&
                  props.pod?.deliveryPhotos?.map((item, index) => (
                    <div className="col-span-1" key={`delivery-photo-${index}`}>
                      <img
                        className="max-w-[110px] max-h-[110px]"
                        alt="delivery"
                        src={`data:image/png;base64,${item}`}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="pt-4">
            <div className="grid grid-cols-1 justify-center">
              <Button
                onClick={props.onClose}
                type="submit"
                fullWidth
                data-testid="back-to-invoice-button"
                className="text-sm"
              >
                {Boolean(props.invoice || props.invoiceNumber)
                  ? t('invoice.backToInvoice')
                  : t('common.backToOrder')}
              </Button>
            </div>
          </div>
        </Container>
      </DialogContent>
    </Dialog>
  );
}
export default ProofOfDeliveryModal;
