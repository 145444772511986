import {
  Box,
  Grid,
  Hidden,
  Image,
  Link,
  Skeleton,
  Tooltip,
  Typography,
  useScreenSize
} from '@dialexa/reece-component-library';
import { useTranslation } from 'react-i18next';

import notfound from 'images/notfound.png';
import { InvoiceProduct } from 'generated/graphql';
import { format } from 'utils/currency';
import Dotdotdot from 'react-dotdotdot';
import { Link as RouterLink } from 'react-router-dom';
import { useDomainInfo } from 'hooks/useDomainInfo';
import ConditionalWrapper from 'old-components/ConditionalWrapper';
import slugify from 'react-slugify';
import InvoiceItemDataSection from './InvoiceItemDataSection';

type Props = {
  loading?: boolean;
  invoiceItem?: InvoiceProduct;
  isMincron?: boolean;
};

function InvoiceItem(props: Props) {
  /**
   * Props
   */
  const { loading, invoiceItem, isMincron } = props;

  /**
   * Custom Hooks
   */
  const { isSmallScreen } = useScreenSize();
  const { isWaterworks } = useDomainInfo();
  const { t } = useTranslation();

  /**
   * Misc
   */
  // URL
  const brandSlug = `${slugify(invoiceItem?.brand)}/`;
  const productSlug = `${brandSlug === '/' ? '' : brandSlug}`;

  /**
   * Output
   */
  return (
    <Grid
      container
      spacing={2}
      px={isSmallScreen ? 0 : 2}
      py={2}
      data-testid={`row_${invoiceItem?.id}`}
      sx={{ breakInside: 'avoid' }}
    >
      <Grid item>
        <Box
          height={80}
          width={80}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {loading ? (
            <Skeleton variant="rectangular" height={80} width={80} />
          ) : (
            <Image
              alt={invoiceItem?.name ?? 'Product Picture'}
              fallback={notfound}
              src={invoiceItem?.thumb ?? t('common.productPicture')}
              data-testid="product-image"
            />
          )}
        </Box>
      </Grid>
      <Grid item container direction="column" md={3} xs>
        <Typography variant="subtitle2" data-testid="mfr-name">
          {loading ? <Skeleton width={80} /> : invoiceItem?.brand ?? ''}
        </Typography>
        <Typography color="primary" component="div">
          {loading ? (
            <Skeleton />
          ) : (
            <Typography component="div" gutterBottom data-testid="product-name">
              <ConditionalWrapper
                wrapper={(children) => (
                  <Link
                    to={`/product/${productSlug}${invoiceItem?.id}`}
                    component={RouterLink}
                    data-testid="product-link"
                  >
                    {children}
                  </Link>
                )}
                condition={!isWaterworks && !isMincron}
              >
                <Dotdotdot clamp={3}>
                  <Tooltip title={invoiceItem?.name ?? ''}>
                    <Typography>{invoiceItem?.name}</Typography>
                  </Tooltip>
                </Dotdotdot>
              </ConditionalWrapper>
            </Typography>
          )}
        </Typography>
        {loading ? (
          <Typography variant="subtitle2">
            <Skeleton width={120} />
          </Typography>
        ) : (
          <Box>
            <Typography variant="subtitle2" data-testid="part-number">
              {invoiceItem?.partNumber
                ? `Part# ${invoiceItem?.partNumber}`
                : ''}
            </Typography>
            <Typography variant="subtitle2" data-testid="mfr-number">
              {invoiceItem?.mfr ? `MFR# ${invoiceItem?.mfr}` : ''}
            </Typography>
          </Box>
        )}
      </Grid>
      <Grid container item md>
        <Hidden mdUp>
          <Grid item xs={3}>
            <Typography color="primary" variant="subtitle2" align="center">
              {t('common.price')}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography color="primary" variant="subtitle2" align="center">
              {t('invoice.orderQuantity')}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography color="primary" variant="subtitle2" align="center">
              {t('invoice.shipQuantity')}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography color="primary" variant="subtitle2" align="center">
              {'B/O Qty'}
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Box
              display="flex"
              flexDirection="column"
              height={1}
              justifyContent={'flex-start'}
            >
              <Typography color="primary" variant="subtitle2" align="center">
                {t('invoice.orderTotal')}
              </Typography>
            </Box>
          </Grid>
        </Hidden>
        <InvoiceItemDataSection
          skeletonWidth={50}
          data={`${format(parseFloat(invoiceItem?.price ?? '0'))} ${
            invoiceItem?.pricingUom?.toLowerCase() || t('product.each')
          }`}
          isSmallScreen={isSmallScreen}
          loading={loading}
          dataId="order-price"
        />
        <InvoiceItemDataSection
          skeletonWidth={30}
          data={`${invoiceItem?.qty?.quantityOrdered ?? '-'}`}
          isSmallScreen={isSmallScreen}
          loading={loading}
          dataId="order-quantity"
        />
        <InvoiceItemDataSection
          skeletonWidth={30}
          data={`${invoiceItem?.qty?.quantityShipped ?? '-'}`}
          isSmallScreen={isSmallScreen}
          loading={loading}
          dataId="order-ship-quantity"
        />
        <InvoiceItemDataSection
          skeletonWidth={30}
          data={`${invoiceItem?.qty?.quantityBackOrdered ?? '-'}`}
          isSmallScreen={isSmallScreen}
          loading={loading}
          dataId="back-order-quantity"
        />
        <InvoiceItemDataSection
          skeletonWidth={50}
          data={`${format(
            (invoiceItem?.qty?.quantityOrdered ?? 0) *
              parseFloat(invoiceItem?.price ?? '0')
          )}`}
          isSmallScreen={isSmallScreen}
          loading={loading}
          dataId="order-price-total"
        />
      </Grid>
    </Grid>
  );
}

export default InvoiceItem;
