import { Dispatch, useEffect, useMemo, useState } from 'react';

import { Link, useScreenSize } from '@dialexa/reece-component-library';
import { Link as RouterLink } from 'react-router-dom';

import { OrderLineItem, ProductPricing } from 'generated/graphql';
import LineItemMobile from 'Invoice/LineItem.mobile';
import LineItemDesktop from 'Invoice/LineItem.desktop';
import slugify from 'react-slugify';
import { useCartContext } from 'providers/CartProvider';
import { useApiTrackEvent } from 'API/tealium.api';

export type OrderLineItemProps = {
  loading: boolean;
  lineItem?: OrderLineItem;
  isMincron?: boolean;
  uom?: string;
  listIds?: string[];
  pricingData?: ProductPricing;
};

export type SubOrderLineItemProps = {
  availableInList: string[];
  handleReorderButtonClick: () => void;
  isComment?: boolean;
  notAvailable: boolean;
  setAvailableInList: Dispatch<string[]>;
  urlWrapper: (args: JSX.Element) => JSX.Element;
} & OrderLineItemProps;

export default function LineItem(props: OrderLineItemProps) {
  /**
   * Props
   */
  const status = props?.lineItem?.status ?? '';
  const orderQuantity = props?.lineItem?.orderQuantity ?? 0;

  /**
   * Custom Hooks
   */
  const { isSmallScreen } = useScreenSize();

  /**
   * State
   */
  const [availableInList, setAvailableInList] = useState<string[]>([]);

  /**
   * Memos
   */
  const notAvailable = useMemo(notAvailableMemo, [status]);
  const isComment = useMemo(isCommentMemo, [status]); // Shows for comment or misc charge

  /**
   * Context
   */
  const { addItemToCart } = useCartContext();

  const brandSlug = `${slugify(props.lineItem?.manufacturerName)}/`;
  const productSlug = `${brandSlug === '/' ? '' : brandSlug}`;
  const { call: trackAddToCart } = useApiTrackEvent();

  /**
   * Effects
   */
  useEffect(handleAvailableInList, [props.listIds]);

  /**
   * Render
   */
  const subProps: SubOrderLineItemProps = {
    ...props,
    availableInList,
    handleReorderButtonClick,
    isComment,
    notAvailable,
    setAvailableInList,
    urlWrapper
  };
  if (isSmallScreen) {
    return <LineItemMobile {...subProps} />;
  }
  return <LineItemDesktop {...subProps} />;

  /**
   * Handles
   */
  function handleReorderButtonClick() {
    trackAddToCart({
      eventName: 'Order Details View',
      feature: 'Orders',
      eventData: {
        eventData: [
          { dataKey: 'withTasks', value: 'true' },
          {
            dataKey: 'taskAddToCart',
            value: 'Product ID: ' + props.lineItem?.productId
          }
        ]
      }
    });
    addItemToCart([
      {
        productId: props.lineItem?.productId ?? '',
        qty: orderQuantity,
        minIncrementQty: 0
      }
    ]);
  }

  function handleAvailableInList() {
    const listIdsAvailable = props.listIds ?? [];
    setAvailableInList(listIdsAvailable);
  }

  /**
   * Memo Defs
   */
  function notAvailableMemo() {
    const notAvailableStatList = [
      'NonStock',
      'Delete',
      'Purge',
      'NonPDW',
      'Discontinued'
    ];
    return notAvailableStatList.includes(status);
  }
  function isCommentMemo() {
    const commentStatList = ['Comment', 'MiscChrg'];
    return commentStatList.includes(status);
  }

  /**
   * Misc
   */
  function urlWrapper(children: JSX.Element) {
    return (
      <Link
        to={`/product/${productSlug}${props.lineItem?.productId ?? ''}`}
        component={RouterLink}
        data-testid="product-link"
      >
        {children}
      </Link>
    );
  }
}
