import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

import { Configuration } from 'utils/configuration';

/**
 * Hook
 */
function useAutocompleteLogic(autoCompleteInput: string) {
  const { placesService, placePredictions, getPlacePredictions } =
    usePlacesService({
      // istanbul ignore next
      apiKey: Configuration.googleApiKey || 'no-key',
      options: {
        input: autoCompleteInput || '',
        types: ['geocode'],
        componentRestrictions: { country: 'us' }
      }
    });

  return { placesService, placePredictions, getPlacePredictions };
}

export default useAutocompleteLogic;
