import { useEffect, useRef, useState } from 'react';

import { CheckCircle } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import clsx from 'clsx';
import { truncate } from 'lodash-es';
import { useDropzone } from 'react-dropzone';
import { Trans, useTranslation } from 'react-i18next';

import {
  OnButtonClick,
  OnFormSubmit,
  OnInputChange
} from '@reece/global-types';
import { useApiGetListDetails, useApiUploadListCSV } from 'API/lists.api';
import { ApiError } from 'API/types/common.types';
import { UploadListCSVResponse } from 'API/types/lists.types';
import { Button, Card, Loader } from 'components';
import { CloudUploadIcon, WarningIcon } from 'icons';
import baseI18nComponents from 'locales/baseComponents';
import { base64ToArrayBuffer, downloadFile } from 'utils/downloadFile';

/**
 * Config
 */
const VALID_TYPES = ['text/csv', 'application/vnd.ms-excel'];
const SCROLL_CONFIG: ScrollIntoViewOptions = {
  behavior: 'smooth',
  block: 'start',
  inline: 'nearest'
};

/**
 * Types
 */
enum PostUploadState {
  error,
  success
}
export type ListUploadDropzoneProps = {
  id?: string;
  back: () => void;
};

/**
 * Component
 */
function ListUploadDropzone(props: ListUploadDropzoneProps) {
  /**
   * Ref
   */
  const containerRef = useRef<HTMLDivElement>(null);

  /**
   * Custom hooks
   */
  const { t } = useTranslation();

  /**
   * State
   */
  const [acceptedFile, setAcceptedFile] = useState<File>();
  const [hasRejectedFile, setHasRejectedFile] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [postUploadState, setPostUploadState] = useState<PostUploadState>();
  const [uploadStat, setUploadStat] = useState<UploadListCSVResponse>();
  const [errorFile, setErrorFile] = useState<string>();

  /**
   * API
   */
  // 🟣 API - get list
  const {
    call: getListCall,
    loading: getListLoading,
    data: listDetailsData,
    called: getListCalled
  } = useApiGetListDetails();
  // 🟣 API - upload list
  const { call: uploadListCall, loading: uploadListLoading } =
    useApiUploadListCSV({});
  // consts
  const listName = listDetailsData?.listInfo?.name ?? '-';

  /**
   * Special Hook - Dropzone
   */
  const {
    getInputProps,
    getRootProps,
    inputRef: fileUploadRef,
    isDragAccept,
    isDragReject,
    open: openFileDialog
  } = useDropzone({
    multiple: false,
    noClick: true,
    onDropAccepted: (droppedFiles: File[]) => {
      setAcceptedFile(droppedFiles[0]);
      scrollToUploadContainer();
    },
    onDropRejected: () => setHasRejectedFile(true)
  });

  /**
   * Callbacks
   */
  // 🟤 Cb - Scrolling into upload section
  const scrollToUploadContainer = () =>
    containerRef.current?.scrollIntoView?.(SCROLL_CONFIG);
  // 🟤 Cb - Choose file
  const handleChooseFile = (event: OnInputChange) => {
    const [file] = event?.target?.files ?? [undefined];
    const acceptedFile = VALID_TYPES.includes(file?.type);
    setAcceptedFile(file);
    setHasRejectedFile(!acceptedFile);
    scrollToUploadContainer();
  };
  // 🟤 Cb - Remove file
  const handleRemoveFile = (e: OnButtonClick) => {
    e.preventDefault();
    // istanbul ignore next
    fileUploadRef?.current?.value && (fileUploadRef.current.value = '');
    setAcceptedFile(undefined);
    setPostUploadState(undefined);
    setErrorMessage(undefined);
    setHasRejectedFile(false);
  };
  // 🟤 Cb - Submit error
  const handleSubmitError = (data?: ApiError) => {
    setErrorMessage(data?.message);
    setPostUploadState(PostUploadState.error);
  };
  // 🟤 Cb - Form submit
  const handleFormSubmit = async (e: OnFormSubmit) => {
    e.preventDefault();
    // 🔸 acceptedFile is always truthy and hasRejectedFile is always falsey when this is called
    uploadListCall(props.id!, { file: acceptedFile! })
      .then((res) => {
        // 🟥 ERROR
        if (!res?.data?.listInfo || !res?.data?.products?.length) {
          handleSubmitError();
          return;
        }
        // 🟧 ERROR FILE
        res.data.file && setErrorFile(res.data.file);
        // 🟩 SUCCESS
        setUploadStat(res.data);
        setPostUploadState(PostUploadState.success);
      })
      .catch(handleSubmitError);
  };

  // 🟤 Cb - Download error csv file
  const downloadErrorCSV = () =>
    errorFile &&
    downloadFile(
      base64ToArrayBuffer(errorFile),
      `${listName}_errors`,
      'text/csv'
    );

  /**
   * Effects
   */
  // 🟡 Effect - initiate getList
  useEffect(() => {
    !getListCalled && props.id && getListCall(props.id);
  }, [getListCall, props.id, getListCalled]);

  /**
   * Render
   */
  return (
    <Card className="relative h-full p-6 flex flex-col border border-secondary-3-100 !shadow-none">
      {uploadListLoading && <Loader backdrop testId="list-upload-loading" />}
      {props.id && (
        <p className="w-full pb-4 flex">
          <span className="pr-1" data-testid="list-upload-name">
            {t('lists.uploadingTo')}
          </span>
          {getListLoading ? (
            <Skeleton className="flex-1" data-testid="get-list-loading" />
          ) : (
            <span className="font-bold flex-1 truncate">{listName}</span>
          )}
        </p>
      )}
      <div
        {...(!acceptedFile && getRootProps({}))}
        className={clsx(
          'w-full flex-1 p-4 bg-primary-2-100/5 rounded-xl border-2 border-dashed border-secondary-3-100 flex flex-col justify-center items-center',
          { '!bg-primary-2-100/25': !acceptedFile && isDragAccept },
          { '!bg-error-100/25': !acceptedFile && isDragReject }
        )}
        ref={containerRef}
        data-testid="dropzone-root"
      >
        {!acceptedFile ? (
          <>
            <CloudUploadIcon className="mb-6" />
            <p className="text-base font-medium">{t('lists.dragDrop')}</p>
            <p className="mb-6 text-base font-medium">{t('common.or')}</p>
            <Button onClick={openFileDialog} data-testid="choose-file-button">
              {t('common.chooseFile')}
            </Button>
            <input
              {...getInputProps()}
              id="choose-file-form"
              name="chooseFileForm"
              type="file"
              onChange={handleChooseFile}
              data-testid="choose-file-input"
            />
          </>
        ) : (
          <form
            className="flex flex-col items-center w-full px-2"
            data-testid="file-select-form"
            onSubmit={handleFormSubmit}
          >
            <div className="flex flex-col items-center">
              {/* Error */}
              {(hasRejectedFile ||
                postUploadState === PostUploadState.error) && (
                <div
                  className="py-4 flex text-support-1-100"
                  data-testid="upload-csv-error"
                >
                  <WarningIcon
                    className="mt-2 mr-2 w-8 h-8"
                    width={32}
                    height={32}
                  />
                  <span className="text-base flex-1">
                    <Trans
                      i18nKey={
                        hasRejectedFile
                          ? 'validation.csvRequired'
                          : 'lists.uploadError'
                      }
                      components={baseI18nComponents}
                    />
                    {errorMessage && (
                      <p
                        className="mt-2 break-all"
                        data-testid="upload-error-message"
                      >
                        {errorMessage}
                      </p>
                    )}
                  </span>
                </div>
              )}
              {/* Success */}
              {postUploadState === PostUploadState.success &&
                (uploadStat?.file ? (
                  <div
                    className="py-4 flex text-primary-3-100"
                    data-testid="upload-csv-warn"
                  >
                    <WarningIcon
                      className="mt-2 mr-2 w-8 h-8 text-secondary-1-100"
                      width={32}
                      height={32}
                    />
                    <span className="text-base flex-1">
                      <Trans
                        i18nKey="lists.uploadWarning"
                        values={{
                          success: uploadStat.successfulRowCount,
                          total: uploadStat.totalRowCount,
                          listName
                        }}
                        components={{
                          ...baseI18nComponents,
                          a: (
                            <Button
                              type="button"
                              kind="text"
                              color="lightBlue"
                              className="!min-w-0 !p-0 underline !text-base !inline !align-top"
                              data-testid="download-error-file-button"
                              onClick={downloadErrorCSV}
                            />
                          )
                        }}
                      />
                    </span>
                  </div>
                ) : (
                  <span
                    className="pt-4 pb-8 flex flex-col items-center text-success-100"
                    data-testid="upload-csv-success"
                  >
                    <CheckCircle className="mr-2 !w-12 !h-12" />
                    <span className="text-base font-medium">
                      {t('lists.uploadSuccess')}
                    </span>
                  </span>
                ))}
              {/* File name */}
              <div className="text-center mb-4">
                <span
                  className="text-base font-medium max-w-3/4"
                  data-testid="file-name-label"
                >{`${t('lists.fileSelected')}: ${truncate(
                  acceptedFile.name
                )}`}</span>
                <Button
                  type="button"
                  kind="text"
                  color="lightBlue"
                  className="!p-0 ml-2 underline !text-base !inline !align-top"
                  onClick={handleRemoveFile}
                  data-testid="remove-file-button"
                >
                  {t('common.remove')}
                </Button>
              </div>
              {!postUploadState && (
                <Button type="submit" disabled={hasRejectedFile}>
                  {t('common.submit')}
                </Button>
              )}
              {postUploadState === PostUploadState.success &&
                !uploadStat?.file && (
                  <Button
                    className="!px-6"
                    onClick={props.back}
                    color="green"
                    data-testid="upload-success-button"
                  >
                    {t('lists.viewList')}
                  </Button>
                )}
            </div>
          </form>
        )}
        {!acceptedFile && (
          <p className="mt-4 text-base font-medium text-secondary-2-100">
            {t('lists.fileTypes')}
          </p>
        )}
      </div>
    </Card>
  );
}

export default ListUploadDropzone;
