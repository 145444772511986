import featuredItem1 from 'images/bigDog/measuringTape.jpg';
import featuredItem2 from 'images/bigDog/pointShovel.jpg';
import featuredItem3 from 'images/bigDog/torpedoLevel.jpg';
import featuredItem4 from 'images/bigDog/toolPouch.jpg';
import featuredItem5 from 'images/bigDog/tieDownStrap.jpg';

import { links } from 'utils/links';

type BigDogFeaturedItems = {
  title: string;
  image: string;
  url: string;
};

const titles = [
  '26 ft Magnetic Tape Measure',
  'Round Point Shovel',
  'Torpedo Level',
  'Multi Purpose Tool Pouch',
  'Ratchet Tie Down Strap'
];
const images = [
  featuredItem1,
  featuredItem2,
  featuredItem3,
  featuredItem4,
  featuredItem5
];
const urls = links.bigDog.featuredItems;

export const FeaturedItems: BigDogFeaturedItems[] = titles.map(
  (item, index) => ({ title: item, image: images[index], url: urls[index] })
);
