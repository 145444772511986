import { Lightbulb } from '@mui/icons-material';
import Card from 'components/Card';
import InfoChip from 'components/InfoChip';

/**
 * Config
 */
const display = {
  title: 'InfoChip',
  chip: 'Test'
};

/**
 * Component
 */
function InfoChipPage() {
  return (
    <Card className="my-8 p-6">
      <h5 className="text-2xl font-bold text-secondary-2-100">
        {display.title}
      </h5>
      <hr className="my-4" />
      <div className="my-4 flex justify-center">
        <InfoChip color="default" text={display.chip} icon={<Lightbulb />} />
        <InfoChip color="primary" text={display.chip} icon={<Lightbulb />} />
        <InfoChip color="green" text={display.chip} icon={<Lightbulb />} />
      </div>
      <div className="my-4 flex justify-center">
        <InfoChip
          contained
          color="default"
          text={display.chip}
          icon={<Lightbulb />}
        />
        <InfoChip
          contained
          color="primary"
          text={display.chip}
          icon={<Lightbulb />}
        />
        <InfoChip
          contained
          color="green"
          text={display.chip}
          icon={<Lightbulb />}
        />
      </div>
    </Card>
  );
}

export default InfoChipPage;
