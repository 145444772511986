import { ReactNode } from 'react';

import {
  FieldValues,
  UseControllerProps,
  useController
} from 'react-hook-form';

import { Modify } from '@reece/global-types';
import SelectInput, { SelectOption } from 'components/SelectInput';

/**
 * Types
 */
type NewProps = {
  className?: string;
  disabled?: boolean;
  label?: string;
  listKey: string;
  message?: string;
  options: SelectOption[];
  placeholder?: string;
  renderValue?: (value: string) => ReactNode;
  required?: boolean;
  testId?: string;
};
export type FormSelectProps<TFields extends FieldValues> = Modify<
  UseControllerProps<TFields>,
  NewProps
>;

/**
 * Component
 */
function FormSelectInput<T extends FieldValues>(props: FormSelectProps<T>) {
  /**
   * Props
   */
  const {
    className,
    disabled,
    label,
    listKey,
    message,
    options,
    placeholder,
    renderValue,
    required,
    testId,
    ...rest
  } = props;

  /**
   * Custom Hooks
   */
  const {
    field,
    fieldState: { invalid, error }
  } = useController(rest);
  const onChange = (e: string) => field.onChange({ target: { value: e } });

  /**
   * Render
   */
  return (
    <SelectInput
      label={label}
      testId={testId}
      disabled={disabled}
      required={required}
      placeholder={placeholder}
      className={className}
      message={invalid ? error?.message : message}
      error={Boolean(error?.message)}
      listKey={listKey}
      options={options}
      fullWidth
      value={field.value}
      name={field.name}
      renderValue={renderValue}
      onBlur={field.onBlur}
      onChange={onChange}
    />
  );
}

export default FormSelectInput;
