import { useRef } from 'react';

/**
 * Hook
 * 🔸 Note - due to the difficulties of mocking useRef for unit testing, I purposely made this
 *     as an external module so I can mock it without breaking Jest render()
 */
export function useSidebarContentRef() {
  // Output
  return useRef<HTMLDivElement>(null);
}
