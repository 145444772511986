import { alpha } from '@mui/material/styles';

import palette from 'providers/libs/Theme/palette';

const muiTableRow = {
  root: {
    '&.MuiTableRow-hover:hover': {
      backgroundColor: alpha(palette.primary02.main, 0.1)
    }
  },
  hover: {}
};

export default muiTableRow;
