import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import UserItemButton from 'common/OldHeader/sub/UserItemButton';
import PermissionRequired, { Permission } from 'common/PermissionRequired';
import { Button, DropdownButton } from 'components';
import { AccountSettingsIcon } from 'icons';
import { useAuthContext } from 'providers/AuthProvider';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';

/**
 * Component
 */
function UserButton() {
  /**
   * Custom Hooks
   */
  const { push } = useHistory();
  const { t } = useTranslation();

  /**
   * Context
   */
  const { authState, firstName, profile } = useAuthContext();
  const { error: erpAccountError, isMincron } = useSelectedAccountsContext();
  const isAuthenticated = Boolean(authState?.isAuthenticated);
  const hasPermissions = Boolean(profile?.permissions?.length);

  /**
   * Render
   */
  return !isAuthenticated ? (
    <Button
      color="gray"
      onClick={() => push('/login')}
      iconStart={
        <AccountSettingsIcon className="text-primary-1-100" height={36} />
      }
      kind="text"
      size="sm"
      className="!py-2 !px-3 [&_>_svg]:w-[18px] [&_>_svg]:h-[18px]"
      data-testid="sign-in-header-button"
    >
      {t('common.signInRegister')}
    </Button>
  ) : (
    <DropdownButton
      className="!pl-3 !pr-4 !py-2 [&_>_svg]:w-[18px] [&_>_svg]:h-[18px]"
      iconStart={<AccountSettingsIcon color="primary" />}
      content={(_, setOpen) => (
        <div
          className="flex flex-col px-6 py-2"
          data-testid="header-userbutton-content"
        >
          <div className="flex items-center">
            <h5 className="block py-4 text-xl leading-6 font-bold">
              {t('common.hello', { name: firstName })}
            </h5>
          </div>
          <div className="flex flex-col items-start">
            <PermissionRequired permissions={[Permission.APPROVE_ALL_USERS]}>
              <UserItemButton
                label={t('common.customerApproval')}
                path="/customer-approval"
                setOpen={setOpen}
              />
            </PermissionRequired>
            <PermissionRequired permissions={[Permission.MANAGE_ROLES]}>
              <UserItemButton
                label={t('common.userManagement')}
                path="/user-management"
                setOpen={setOpen}
              />
            </PermissionRequired>
            {isAuthenticated && !erpAccountError && hasPermissions && (
              <UserItemButton
                label={t('common.accountInformation')}
                path="/account"
                setOpen={setOpen}
              />
            )}
            {!isMincron && (
              <PermissionRequired
                permissions={[Permission.MANAGE_PAYMENT_METHODS]}
              >
                <UserItemButton
                  label={t('common.paymentInformation')}
                  path="/payment-information"
                  setOpen={setOpen}
                />
              </PermissionRequired>
            )}
            <PermissionRequired permissions={[Permission.MANAGE_BRANCHES]}>
              <UserItemButton
                label={t('common.branchManagement')}
                path="/branch-management"
                setOpen={setOpen}
              />
            </PermissionRequired>
            {isAuthenticated && (
              <>
                <PermissionRequired permissions={[Permission.TOGGLE_FEATURES]}>
                  <UserItemButton
                    label={t('common.toggleFeatures')}
                    path="/features"
                    setOpen={setOpen}
                  />
                </PermissionRequired>
                <PermissionRequired permissions={[Permission.TOGGLE_FEATURES]}>
                  <UserItemButton
                    label={t('common.components')}
                    path="/components"
                    setOpen={setOpen}
                  />
                </PermissionRequired>
              </>
            )}
            <div className="mt-2.5">
              <UserItemButton
                isSignOut
                label={t('common.signOut')}
                path="/logout"
                setOpen={setOpen}
              />
            </div>
          </div>
        </div>
      )}
    >
      <p className="text-xs">{t('common.myAccount')}</p>
    </DropdownButton>
  );
}

export default UserButton;
