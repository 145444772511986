import { Skeleton } from '@mui/material';

/**
 * Types
 */
type Props = {
  testId?: string;
};

/**
 * Component
 */
function BranchCardLoading({ testId }: Props) {
  /**
   * Render
   */
  return (
    <div className="py-5" data-testid={`branchcard-loading-${testId}`}>
      <div className="grid grid-cols-3">
        {/* LEFT SIDE */}
        <div className="col-span-2">
          <div className="flex items-end">
            <Skeleton width={200} />
          </div>
          <Skeleton width={200} />
          <Skeleton width={200} />
          <Skeleton width={128} height={32} />
        </div>
        {/* RIGHT SIDE */}
        <div className="col-span-1 flex flex-col justify-end">
          <div className="mb-6">
            <Skeleton />
            <Skeleton />
          </div>
          <Skeleton variant="rectangular" height={36} />
        </div>
      </div>
    </div>
  );
}

export default BranchCardLoading;
