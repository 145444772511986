import { lazy } from 'react';

// lib
import { LoginCallback, SecureRoute } from '@okta/okta-react';
import Loader from 'old-components/Loader';
import { Location, Action } from 'history';
import { Redirect, Route, Switch } from 'react-router-dom';

// Provider/Context
import CheckoutProvider from 'Checkout/CheckoutProvider';
import CreditCardProvider from 'CreditCard/CreditCardProvider';
import { useAuthContext } from 'providers/AuthProvider';
import { useCartContext } from 'providers/CartProvider';

// Components
import Account from 'pages/Account';
import Appointments from 'pages/Appointments';
import MigrationSetupMessage from 'pages/MigrationSetupMessage';
import BranchManagement from 'pages/BranchManagement';
import BrandPage from 'pages/Brands';
import BigDogMarketing from 'pages/BrandPages/BigDog';
import PoshMarketing from 'pages/BrandPages/Posh';
import Cart from 'Cart';
import Checkout from 'Checkout';
import NavigationAlert from 'common/NavigationAlert';
import ErrorComponent from 'common/ErrorBoundary/ErrorComponent';
import Company from 'Company';
import ComponentPage from 'pages/Components';
import Contract from 'Contract';
import Contracts from 'Contracts';
import CreditCardCallback from 'CreditCard/CreditCardCallback';
import CreditForms from 'CreditForms';
import CustomerApproval from 'CustomerApproval';
import FeatureToggle from 'pages/FeatureToggle';
import ForgotPassword from 'ForgotPassword';
import CategoryPage from 'Categories';
import Invite from 'Invite';
import Invoice from 'Invoice';
import Invoices from 'Invoices';
import Legal from 'Legal';
import DoNotSellMyInfo from 'Legal/DoNotSellMyInfo';
import Lists from 'pages/Lists';
import ListUpload from 'pages/ListUpload';
import LocationSearch from 'pages/LocationSearch';
import Login from 'Login';
import Logout from 'pages/Logout';
import MaintenancePage from 'Invoices/Maintenance';
import ModifyAppointment from 'pages/Appointments/ModifyAppointment';
import NewOrders from 'pages/Orders';
import PaymentInformation from 'PaymentInformation';
import Portal from 'Portal';
import PreviouslyPurchasedProducts from 'PreviouslyPurchasedProducts';
import Product from 'pages/Product';
import PurchaseApprovals from 'PurchaseApprovals';
import ReviewPurchase from 'PurchaseApprovals/ReviewPurchase';
import Quote from 'Quote';
import Quotes from 'Quotes';
import RegisterNew from 'Register';
import RegisterOld from 'Register-old';
import VerifyEmail from 'Register-old/VerifyEmail';
import Search from 'pages/Search';
import PostLogin from 'pages/PostLogin';
import Support from 'pages/Support';
import User from 'User';
import UserManagement from 'UserManagement';
import WorksForYou from 'WorksForYou';

// builderio
import {
  BuilderIOAboutUs,
  BuilderIOBranches,
  BuilderIOBrands,
  BuilderIOEvents,
  BuilderIOHome,
  BuilderIORoot,
  BuilderIODivisions,
  BuilderIOPage,
  BuilderIOPartners
} from 'pages/BuilderIO';

// misc
import { useDomainInfo } from 'hooks/useDomainInfo';
import RegisterComplete from 'Register/RegisterComplete';
import Barcode from 'pages/Barcode';
import OrderDetails from 'pages/Orders/OrderDetails';
import Embedded from 'pages/Embedded';

/**
 * Lazy Loaded Components
 */
const JobForm = lazy(() => import('./JobForm'));

function Routes() {
  /**
   * Custom Hooks
   */
  const { isWaterworks } = useDomainInfo();

  /**
   * Contexts
   */
  const { activeFeatures, featuresLoading } = useAuthContext();
  const { clearContract } = useCartContext();
  const isNewRegistration =
    !activeFeatures?.length || activeFeatures.includes('NEW_REGISTRATION');
  const InvoiceMaintenance = activeFeatures?.includes('INVOICE_MAINTENANCE');
  const waterworksMaintenance = activeFeatures?.includes(
    'WATERWORKS_MAINTENANCE'
  );

  /**
   * Render (ROUTES)
   */
  return (
    <>
      <NavigationAlert when={showContractNavPrompt} onConfirm={clearContract} />
      <Switch>
        {/* Unsecure Routes */}
        <Route path="/error" exact component={ErrorComponent} />
        <Route
          exact
          path="/product/:brandName?/:category?/:id"
          component={Product}
        />
        {featuresLoading ? (
          <Route exact path="/register" component={Loader} />
        ) : !isNewRegistration ? (
          <Route exact path="/register" component={RegisterOld} />
        ) : (
          <Route exact path="/register/:step?" component={RegisterNew} />
        )}
        <Route exact path="/register/step-1" component={RegisterNew} />
        <Route exact path="/confirmation" component={RegisterComplete} />
        <Route exact path="/Appointment" component={Appointments} />
        <Route exact path="/Appointment/Manage" component={ModifyAppointment} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route path="/search" component={Search} />
        <Route path="/privacy-policy" component={Legal} />
        <Route path="/terms-of-access" component={Legal} />
        <Route path="/terms-of-sale" component={Legal} />
        <Route path="/do-not-sell-my-info" component={DoNotSellMyInfo} />
        <Route
          exact
          path="/login"
          component={
            waterworksMaintenance && isWaterworks ? MaintenancePage : Login
          }
        />
        <Route exact path="/logout" component={Logout} />
        <SecureRoute exact path="/user/:id" component={User} />
        <Route exact path="/location-search" component={LocationSearch} />
        <Route exact path="/support" component={Support} />
        <Route path="/login/callback" component={LoginCallback} />
        <Route path="/verify" component={VerifyEmail} />
        <Route path="/credit_callback" component={CreditCardCallback} />
        <Route path="/max-welcome" component={MigrationSetupMessage} />
        <Route exact path="/credit-forms" component={CreditForms} />
        <Route exact path="/company" component={Company} />
        <Route exact path="/works-for-you" component={WorksForYou} />
        <Route exact path="/brands/posh" component={PoshMarketing} />
        <Route exact path="/brands/bigdog" component={BigDogMarketing} />
        <Route exact path="/jobform" component={JobForm} />
        {/* BuilderIO Pages */}
        <Route exact path="/about" component={BuilderIOAboutUs} />
        <Route exact path="/partners" component={BuilderIOPartners} />
        <Route
          exact
          path="/tool-rental"
          key="908201"
          component={BuilderIOPage}
        />
        <Route exact path="/careers/us" component={BuilderIOPage} />
        <Route path="/builderio" component={BuilderIORoot} />
        <Route exact path="/brands" key="90201" component={BrandPage} />
        <Route path="/brands" key="09301" component={BuilderIOBrands} />
        <Route path="/events" key="34901" component={BuilderIOEvents} />
        <Route path="/category" key="3731" component={CategoryPage} />
        <Route path="/divisions" key="9875" component={BuilderIODivisions} />
        <Route path="/branch" key="98602" component={BuilderIOBranches} />
        <Route path="/banner" component={BuilderIORoot} />
        {waterworksMaintenance && isWaterworks ? (
          // Waterworks Maintenance redirect
          <Route path="/" key="98201" exact component={MaintenancePage} />
        ) : (
          <Route path="/" key="98202" exact component={BuilderIOHome} />
        )}
        <Route exact path="/embedded" component={Embedded} />
        {/* Secured Routes */}
        <SecureRoute exact path="/components" component={ComponentPage} />
        <SecureRoute exact path="/invite-user" component={Invite} />
        <SecureRoute exact path="/account" component={Account} />
        <SecureRoute exact path="/cart" component={Cart} />
        <SecureRoute exact path="/checkout/:step?">
          <CheckoutProvider>
            <CreditCardProvider>
              <Checkout />
            </CreditCardProvider>
          </CheckoutProvider>
        </SecureRoute>
        <SecureRoute
          exact
          path="/customer-approval"
          component={CustomerApproval}
        />
        <SecureRoute exact path="/contract/:id" component={Contract} />
        <SecureRoute exact path="/invoice/:id" component={Invoice} />
        <SecureRoute
          exact
          path="/invoices"
          component={
            (InvoiceMaintenance || waterworksMaintenance) && isWaterworks
              ? MaintenancePage
              : Invoices
          }
        />
        <SecureRoute exact path="/lists" component={Lists} />
        <SecureRoute exact path="/orders" component={NewOrders} />
        <SecureRoute exact path="/barcode/:id" component={Barcode} />
        <SecureRoute exact path="/lists/upload/:id?" component={ListUpload} />
        <SecureRoute exact path="/payment-information">
          <CreditCardProvider>
            <PaymentInformation />
          </CreditCardProvider>
        </SecureRoute>
        <SecureRoute exact path="/portal" component={Portal} />
        <SecureRoute exact path="/order/:id" component={OrderDetails} />
        <SecureRoute exact path="/contracts" component={Contracts} />
        <SecureRoute
          exact
          path="/buyagain"
          component={PreviouslyPurchasedProducts}
        />
        <SecureRoute
          exact
          path="/purchase-approvals"
          component={PurchaseApprovals}
        />
        <SecureRoute
          exact
          path="/purchase-approvals/:id"
          component={ReviewPurchase}
        />
        <SecureRoute exact path="/quote/:id" component={Quote} />
        <SecureRoute exact path="/quotes" component={Quotes} />
        <SecureRoute exact path="/job-form" component={JobForm} />
        <SecureRoute exact path="/user-management" component={UserManagement} />
        <SecureRoute path="/postlogin" component={PostLogin} />
        <SecureRoute exact path="/features" component={FeatureToggle} />
        <SecureRoute
          exact
          path="/branch-management"
          component={BranchManagement}
        />
        <Redirect to="/" />
      </Switch>
    </>
  );

  function showContractNavPrompt(
    pLocation: Location<any>,
    nLocation: Location<any> | undefined,
    action?: Action
  ): boolean {
    if (pLocation.pathname === nLocation?.pathname) return false;
    if (nLocation?.state?.ignoreNavAlertForPrev) return false;
    if (action === 'POP') return false;
    if (nLocation?.pathname?.includes('/checkout')) return false;
    return pLocation?.state?.canShowNavAlert;
  }
}

export default Routes;
