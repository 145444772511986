import { Fragment, useMemo } from 'react';

import { AccordionDetails, AccordionSummary, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Accordion, Link } from 'components';
import { Order, ProductPricing } from 'generated/graphql';
import { ChevronDownIcon } from 'icons';
import LineItem from 'Invoice/LineItem';

/**
 * Types
 */
type Props = {
  loading?: boolean;
  order?: Order;
  isMincron?: boolean;
  pricingData: ProductPricing[];
  fromOrders?: boolean;
};

/**
 * Component
 */
function ProductsCard(props: Props) {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();

  /**
   * Memos
   */
  const orderedPricing = useMemo(
    () =>
      props.order?.lineItems?.map((item) =>
        props.pricingData.find(
          (price) => price.productId === item?.erpPartNumber
        )
      ) ?? [],
    [props.order?.lineItems, props.pricingData]
  );

  /**
   * Render
   */
  return (
    <div className=" bg-common-white rounded-md shadow-surround p-2">
      <Accordion>
        <AccordionSummary
          expandIcon={<ChevronDownIcon className="text-primary-1-100" />}
          aria-controls="printGridWidth_15"
          id="panel1-header"
        >
          <span
            className="text-primary-1-100 text-xl font-bold"
            data-testid="items-ordered"
          >
            {props.loading ? (
              <Skeleton width={200} />
            ) : (
              `${t('common.products')} (${props?.order?.lineItems?.length})`
            )}
          </span>
        </AccordionSummary>
        <hr className="mx-2" />
        <AccordionDetails>
          {props.loading ? (
            <LineItem loading />
          ) : props?.order?.lineItems?.length ? (
            props.order.lineItems.map((p, idx) => (
              <Fragment key={p.productId ?? idx}>
                <LineItem
                  loading={false}
                  lineItem={p}
                  isMincron={props.isMincron}
                  uom={
                    p.uom?.toLowerCase() ??
                    orderedPricing[idx]?.orderUom.toLowerCase()
                  }
                  listIds={orderedPricing[idx]?.listIds ?? []}
                  pricingData={orderedPricing[idx]}
                />
                {idx + 1 < props!.order!.lineItems!.length && <hr />}
              </Fragment>
            ))
          ) : (
            <div className="px-6 py-16">
              <h4 className="text-secondary-3-100 text-2xl font-medium text-center">
                {t('orders.noItemsWaterworks')}
              </h4>
              <p className="text-secondary-3-100 text-lg font-medium text-center">
                {t('orders.noOrdersContactMessage')}
                <Link to="/support" className="underline">
                  {t('orders.noOrdersContactBranch')}
                </Link>
              </p>
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default ProductsCard;
