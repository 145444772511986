import { useRef, useState } from 'react';

import { VariableSizeList } from 'react-window';
import { sum } from 'lodash-es';

/**
 * Types
 */
export type UseVirtualizedListHeightProps = {
  defaultItemSize: number;
  maxHeight: number;
};
export type UseVirtualizedListHeightOutput = ReturnType<
  typeof useVirtualizedListHeight
>;

/**
 * Hook
 */
export function useVirtualizedListHeight(props: UseVirtualizedListHeightProps) {
  /**
   * Refs
   */
  const listRef = useRef<VariableSizeList<any>>(null);
  const sizeMapRef = useRef<Record<number, number>>({});

  /**
   * State
   */
  const [listHeight, setListHeight] = useState(0);

  /**
   * Callbacks
   */
  // 🟤 Cb - set height
  const handleSetHeight = (index: number) => (renderedHeight?: number) => {
    // Set the list to reset after the index of the last item is passed
    listRef.current?.resetAfterIndex(index);
    // Add the height of the rendered item to the sizeMap
    sizeMapRef.current = {
      ...sizeMapRef.current,
      [index]: renderedHeight ?? props.defaultItemSize
    };
    // Set the overall listHeight
    setListHeight((prev) =>
      prev === props.maxHeight
        ? prev
        : Math.min(sum(Object.values(sizeMapRef.current)), props.maxHeight)
    );
  };
  // 🟤 Cb - get height
  const getItemHeight = (index: number) =>
    sizeMapRef.current[index] ?? props.defaultItemSize;

  /**
   * Output
   */
  return { getItemHeight, handleSetHeight, listHeight, listRef };
}
