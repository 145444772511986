import palette from 'providers/libs/Theme/palette';

const muiInputBase = {
  root: {
    '&.Mui-error + * > .MuiFormHelperText-root:first-of-type': {
      color: palette.error.main
    },
    backgroundColor: palette.primary.contrastText
  },
  input: {
    height: '1.1876em'
  }
};

export default muiInputBase;
