// istanbul ignore file
/**
 * This configuration file loads the environment variables from the `src/environment/env.json` file.
 -----------------------------------------------------------------------------------------------------------------
**** THIS IS A TEMPORARY IMPLEMENTATION and there is a tech debt item to move all keys to an external vault.****
 -----------------------------------------------------------------------------------------------------------------
 * NOTE - The `src/environment/env.json` file IS DIFFERENT FROM THE `.env` file in the project root directory.
 * NOTE - For an environment variable to get registered in the app, it needs to be added to the Configuration object below.
 * NOTE - Test coverage is not needed for this
 * 1. In the root `.env` file, you need to set the REACT_APP_ENVIRONMENT variable to either "localhost", "development", "test", "uat", "production", or "production-backup".
 * 2. If your `src/environment/env.json` file does not exist, you need to create one based on the `env.example.json` file.
 * 3. Update the Configuration object (and PortalConfiguration interface if needed) to include any new/updated environment variables.
 */

// @ts-nocheck
import EnvironmentContext from 'environment/env.json';

if (!EnvironmentContext) {
  console.error(
    'Environment variables not found. Please check if the encrypted-env.json file exists.'
  );
  throw new Error('Environment variables are not defined.');
}

declare global {
  interface Window {
    __configuration__: any;
  }
}

window.__configuration__ = window.__configuration__ || {};
const ENVIRONMENT =
  window.__configuration__?.REACT_APP_ENVIRONMENT || 'localhost';

export type Environment =
  | 'development'
  | 'test'
  | 'uat'
  | 'production'
  | 'production-backup';

interface PortalConfiguration {
  apiUrl: string;
  appUrl: string;
  bffUrl: string;
  oktaUrl: string;
  oktaClientId: string;
  enableTranslationTest: boolean;
  segmentApiKey: string;
  googleApiKey: string;
  environment: Environment;
  showMaintenancePage: boolean;
  maxApiSecret: string;
  contentfulApiUrl?: string;
  contentfulApiKey?: string;
  contentfulPreviewApiKey?: string;
  contentfulPreviewEnable?: boolean;
  contentfulAboutUsId?: string;
  tealiumEnv: string;
  builderApiKey: string;
  billTrustURL: string;
  maxContentWidth?: number;
  maxBodyWidth?: number;
  creditFormEN: string;
  creditFormES: string;
  creditFormWWEN: string;
  creditFormWWES: string;
  creditFormProdEN: string;
  creditFormProdES: string;
  creditFormWWProdEN: string;
  creditFormWWProdES: string;
}

export const Configuration: PortalConfiguration = {
  apiUrl: EnvironmentContext[ENVIRONMENT].REACT_APP_API_URL!,
  bffUrl: EnvironmentContext[ENVIRONMENT].REACT_APP_BFF_URL!,
  appUrl: EnvironmentContext[ENVIRONMENT].REACT_APP_URL!,
  oktaUrl: EnvironmentContext[ENVIRONMENT].REACT_APP_OKTA_URL!,
  oktaClientId: EnvironmentContext[ENVIRONMENT].REACT_APP_OKTA_CLIENT_ID!,
  enableTranslationTest:
    EnvironmentContext[ENVIRONMENT].REACT_APP_ENABLE_TRANSLATION_TEST ===
    'true',
  segmentApiKey: EnvironmentContext[ENVIRONMENT].REACT_APP_SEGMENT_API_KEY!,
  googleApiKey: EnvironmentContext[ENVIRONMENT].REACT_APP_GOOGLE_API_KEY!,
  environment: EnvironmentContext[ENVIRONMENT]
    .REACT_APP_ENVIRONMENT as Environment,
  showMaintenancePage:
    EnvironmentContext[ENVIRONMENT].REACT_APP_SHOW_MAINTENANCE_PAGE === 'true',
  maxApiSecret: EnvironmentContext[ENVIRONMENT].REACT_APP_MAX_API_SECRET!,
  contentfulApiUrl:
    EnvironmentContext[ENVIRONMENT].REACT_APP_CONTENTFUL_API_URL,
  contentfulApiKey:
    EnvironmentContext[ENVIRONMENT].REACT_APP_CONTENTFUL_API_KEY,
  contentfulPreviewApiKey:
    EnvironmentContext[ENVIRONMENT].REACT_APP_CONTENTFUL_PREVIEW_API_KEY,
  contentfulPreviewEnable:
    EnvironmentContext[ENVIRONMENT].REACT_APP_CONTENTFUL_PREVIEW_ENABLE ===
    'true',
  contentfulAboutUsId:
    EnvironmentContext[ENVIRONMENT].REACT_APP_CONTENTFUL_ABOUTUS_ID,
  tealiumEnv: EnvironmentContext[ENVIRONMENT].REACT_APP_TEALIUM_ENVIRONMENT,
  builderApiKey: EnvironmentContext[ENVIRONMENT].REACT_APP_BUILDER_API_KEY,
  billTrustURL: EnvironmentContext[ENVIRONMENT].REACT_APP_BILL_TRUST_URL!,
  maxContentWidth:
    parseInt(
      EnvironmentContext[ENVIRONMENT].REACT_APP_AXIOS_MAX_CONTENT_LENGTH
    ) || undefined,
  maxBodyWidth: parseInt(
    EnvironmentContext[ENVIRONMENT].REACT_APP_AXIOS_MAX_BODY_LENGTH || undefined
  ),
  creditFormEN:
    EnvironmentContext[ENVIRONMENT].REACT_APP_CREDIT_FORM_URL_ENGLISH,
  creditFormES:
    EnvironmentContext[ENVIRONMENT].REACT_APP_CREDIT_FORM_URL_SPANISH,
  creditFormWWEN:
    EnvironmentContext[ENVIRONMENT].REACT_APP_CREDIT_FORM_URL_WW_ENGLISH,
  creditFormWWES:
    EnvironmentContext[ENVIRONMENT].REACT_APP_CREDIT_FORM_URL_WW_SPANISH,
  creditFormProdEN:
    EnvironmentContext[ENVIRONMENT].REACT_APP_CREDIT_FORM_PROD_URL_ENGLISH,
  creditFormProdES:
    EnvironmentContext[ENVIRONMENT].REACT_APP_CREDIT_FORM_PROD_URL_SPANISH,
  creditFormWWProdEN:
    EnvironmentContext[ENVIRONMENT].REACT_APP_CREDIT_FORM_PROD_URL_WW_ENGLISH,
  creditFormWWProdES:
    EnvironmentContext[ENVIRONMENT].REACT_APP_CREDIT_FORM_PROD_URL_WW_SPANISH
};

// Needs to be a function for test mocks
export const getEnvironment = () => ({
  isProd: Configuration.environment === 'production',
  isSandbox: Configuration.environment === 'production-backup',
  isDev: Configuration.environment === 'development',
  isTest: Configuration.environment === 'test',
  isUat: Configuration.environment === 'uat',
  isLocal: Configuration.environment === 'localhost'
});
