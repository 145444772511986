import {
  FieldValues,
  UseControllerProps,
  useController
} from 'react-hook-form';

import { Modify } from '@reece/global-types';
import TextInput, { TextInputProps } from 'components/TextInput';

/**
 * Types
 */
type NewProps = {
  className?: string;
  disabled?: boolean;
  label?: string;
  maxLength?: number;
  message?: string;
  placeholder?: string;
  required?: boolean;
  testId?: string;
  type?: TextInputProps['type'];
};
export type FormTextInputProps<TFields extends FieldValues> = Modify<
  UseControllerProps<TFields>,
  NewProps
>;

/**
 * Component
 */
function FormTextInput<T extends FieldValues>(props: FormTextInputProps<T>) {
  /**
   * Props
   */
  const {
    className,
    disabled,
    label,
    maxLength,
    message,
    placeholder,
    required,
    testId,
    type,
    ...rest
  } = props;

  /**
   * Custom Hooks
   */
  const {
    field,
    fieldState: { invalid, error }
  } = useController(rest);

  /**
   * Render
   */
  return (
    <TextInput
      label={label}
      testId={testId}
      disabled={disabled}
      required={required}
      placeholder={placeholder}
      className={className}
      type={type}
      message={invalid ? error?.message : message}
      error={Boolean(error?.message)}
      maxLength={maxLength}
      {...field}
    />
  );
}

export default FormTextInput;
