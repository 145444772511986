import {
  ReactNode,
  createContext,
  useState,
  useEffect,
  Dispatch,
  useContext
} from 'react';

import { noop, omit } from 'lodash-es';
import { useHistory, useLocation } from 'react-router-dom';

import {
  useApiUpdateDeliveryAddress,
  useApiGetCheckout,
  useApiRapidDeliveryCheck,
  useApiUpdateCreditCard,
  useApiUpdateIsRapid
} from 'API/checkout.api';
import {
  RapidDeliveryRequest,
  RapidDeliveryResponse
} from 'API/types/checkout.types';
import { useAuthContext } from 'providers/AuthProvider';
import {
  DeliveryData,
  DeliveryMethodOptionEnum,
  PaymentData,
  Step,
  WillCallData
} from 'Checkout/util/types';
import {
  AddressInput,
  Branch,
  CartInput,
  CreditCardInput,
  DeliveryInput,
  DeliveryMethodEnum,
  OrderPreviewResponse,
  OrderResponse,
  PreferredTimeEnum,
  WillCallInput
} from 'generated/graphql';
import { useCartContext, CartContractType } from 'providers/CartProvider';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';
import {
  defaultBranchData,
  defaultOrderData,
  defaultOrderPreviewData,
  defaultPaymentData,
  defaultRapidDelivery
} from './util';
import { trackCheckoutPageViewAction } from 'utils/analytics';
import { asyncNoop } from 'utils/etc';
import { Maybe } from 'yup/lib/types';
import {
  CartProps,
  DeliveryProps,
  ProductProps,
  WillCallProps
} from 'API/types/cart.types';

/**
 * Types
 */
export type CheckoutContextType = {
  deliveryData: DeliveryData;
  deliveryMethod: DeliveryMethodEnum;
  deliveryMethodOption: DeliveryMethodOptionEnum;
  deliveryMethodObject?: DeliveryProps | WillCallProps;
  disableContinue: boolean;
  getCheckout: (cartId: string) => Promise<Maybe<Partial<CartProps>>>;
  getCheckoutLoading: boolean;
  setDisableContinue: Dispatch<boolean>;
  orderData: OrderResponse;
  orderPreviewData: OrderPreviewResponse;
  paymentData: PaymentData;
  poNumberError: boolean;
  rapidDelivery: RapidDeliveryResponse;
  setRapidDelivery: Dispatch<RapidDeliveryResponse>;
  rapidDeliveryCheck: (
    cartId: string,
    req: RapidDeliveryRequest
  ) => Promise<Maybe<RapidDeliveryResponse>>;
  rapidDeliveryCheckLoading: boolean;
  setDeliveryData: Dispatch<DeliveryData>;
  setDeliveryMethodOption: Dispatch<DeliveryMethodOptionEnum>;
  setOrderData: Dispatch<OrderResponse>;
  setOrderPreviewData: Dispatch<OrderPreviewResponse>;
  setPaymentData: Dispatch<PaymentData>;
  setPoNumberError: Dispatch<boolean>;
  setShipToBranch: Dispatch<Branch>;
  setStep: Dispatch<Step>;
  setMnCronShoppingCartId: Dispatch<string>;
  setWillCallData: Dispatch<WillCallData>;
  shipToBranch: Branch;
  step: Step;
  willCallData: WillCallData;
  updateCart: (newPaymentData: PaymentData) => Promise<void>;
  updateDelivery: (deliveryData: DeliveryData) => Promise<void>;
  updateWillCall: (willCallData: WillCallData) => Promise<void>;
  tempCartItems: ProductProps[];
  setTempCartItems: Dispatch<ProductProps[]>;
  mnCronShoppingCartId: string;
  orderedCart?: CartProps;
  setOrderedCart: Dispatch<CartProps | undefined>;
  orderedContract?: CartContractType;
  setOrderedContract: Dispatch<CartContractType | undefined>;
  orderedLineNotes: Record<string, string>;
  setOrderedLineNotes: Dispatch<Record<string, string>>;
  isCreditSelectionChanging: boolean;
  setIsCreditSelectionChanging: Dispatch<boolean>;
  isWillCall: boolean;
  isDelivery: boolean;
  isDeliveryMethod: boolean;
  cartLoaded: boolean;
};

/**
 * Context
 */
export const initialCheckoutContextValues: CheckoutContextType = {
  deliveryData: {},
  deliveryMethod: DeliveryMethodEnum.Delivery,
  deliveryMethodOption: DeliveryMethodOptionEnum.Standard,
  disableContinue: false,
  getCheckout: asyncNoop,
  getCheckoutLoading: false,
  setDisableContinue: noop,
  orderData: defaultOrderData,
  orderPreviewData: defaultOrderPreviewData,
  paymentData: defaultPaymentData,
  poNumberError: true,
  rapidDelivery: defaultRapidDelivery,
  setRapidDelivery: () => {},
  shipToBranch: defaultBranchData,
  mnCronShoppingCartId: '',
  step: Step.INFO,
  setDeliveryData: () => {},
  setDeliveryMethodOption: () => {},
  setOrderData: () => {},
  setOrderPreviewData: () => {},
  setPaymentData: () => {},
  setPoNumberError: () => {},
  setShipToBranch: () => {},
  setStep: () => {},
  setWillCallData: () => {},
  setMnCronShoppingCartId: () => {},
  willCallData: {},
  deliveryMethodObject: undefined,
  updateCart: () => new Promise(() => {}),
  updateDelivery: () => new Promise(() => {}),
  rapidDeliveryCheck: asyncNoop,
  rapidDeliveryCheckLoading: false,
  updateWillCall: () => new Promise(() => {}),
  tempCartItems: [],
  setTempCartItems: () => {},
  setOrderedCart: () => {},
  setOrderedContract: () => {},
  orderedLineNotes: {},
  setOrderedLineNotes: () => {},
  isCreditSelectionChanging: false,
  setIsCreditSelectionChanging: () => {},
  isWillCall: false,
  isDelivery: false,
  isDeliveryMethod: false,
  cartLoaded: false
};

export const CheckoutContext = createContext(initialCheckoutContextValues);
/**
 * Provider
 */
function CheckoutProvider(props: { children: ReactNode }) {
  /**
   * Hooks
   */
  const history = useHistory();
  const location = useLocation();

  /**
   * Context
   */
  const {
    cart,
    isWillCall,
    updateCartDeliveryPreferences,
    updateDelivery: cartUpdateDelivery,
    updateWillCall: cartUpdateWillCall,
    contract
  } = useCartContext();
  const { isEclipse, selectedAccounts } = useSelectedAccountsContext();
  const billToAccountId = selectedAccounts.billToErpAccount?.erpAccountId ?? '';
  const { user } = useAuthContext();
  const userEmail = user?.email ?? '';

  /**
   * State
   */
  const [disableContinue, setDisableContinue] = useState(false);
  const [deliveryData, setDeliveryData] = useState<DeliveryData>({});
  const [deliveryMethod, setDeliveryMethod] = useState(
    DeliveryMethodEnum.Delivery
  );
  const [deliveryMethodOption, setDeliveryMethodOption] = useState(
    DeliveryMethodOptionEnum.Standard
  );
  const [mnCronShoppingCartId, setMnCronShoppingCartId] = useState('');
  const [orderData, setOrderData] = useState(defaultOrderData);
  const [orderPreviewData, setOrderPreviewData] =
    useState<OrderPreviewResponse>(defaultOrderPreviewData);
  const [paymentData, setPaymentData] = useState(defaultPaymentData);
  const [poNumberError, setPoNumberError] = useState(true);
  const [step, setStep] = useState(Step.INFO);
  const [shipToBranch, setShipToBranch] = useState(defaultBranchData);
  const [willCallData, setWillCallData] = useState<WillCallData>({});
  const [tempCartItems, setTempCartItems] = useState<ProductProps[]>([]);
  const [orderedCart, setOrderedCart] = useState<CartProps>();
  const [orderedContract, setOrderedContract] = useState<CartContractType>();
  const [orderedLineNotes, setOrderedLineNotes] = useState<
    Record<string, string>
  >({});
  const [isCreditSelectionChanging, setIsCreditSelectionChanging] =
    useState(false);
  const [rapidDelivery, setRapidDelivery] =
    useState<RapidDeliveryResponse>(defaultRapidDelivery);
  const [cartLoaded, setCartLoaded] = useState(false);

  /**
   * Consts
   */
  const cartDeliveryMethod = isWillCall
    ? ({
        id: cart?.willCall?.id ?? '',
        preferredTime: cart?.willCall?.preferredTime ?? '',
        branchId: cart?.willCall?.branchId ?? '',
        ...cart?.willCall
      } as WillCallProps)
    : ({
        id: cart?.delivery?.id ?? '',
        shouldShipFullOrder: false,
        address: cart?.delivery?.address,
        preferredDate: cart?.delivery?.preferredDate ?? '',
        preferredTime:
          cart?.delivery?.preferredTime ?? PreferredTimeEnum.Morning,
        deliveryInstructions: cart?.delivery?.deliveryInstructions,
        phoneNumber: cart?.delivery?.phoneNumber,
        isRapid: false,
        isScheduled: false,
        branchId: null
      } as DeliveryProps);
  const orderedCartDeliveryMethod =
    orderedCart?.deliveryMethod === DeliveryMethodEnum.Willcall
      ? {
          id: orderedCart?.willCall?.id ?? '',
          branchId: orderedCart?.willCall?.branchId ?? '',
          preferredDate: orderedCart?.willCall?.preferredDate ?? '',
          preferredTime:
            (orderedCart?.willCall?.preferredTime as PreferredTimeEnum) ??
            PreferredTimeEnum.Morning,
          pickupInstructions: orderedCart?.willCall?.pickupInstructions ?? ''
        }
      : {
          id: orderedCart?.delivery?.id ?? '',
          preferredTime:
            (orderedCart?.delivery?.preferredTime as PreferredTimeEnum) ??
            PreferredTimeEnum.Morning,
          ...orderedCart?.delivery
        };
  const deliveryMethodObject = cart
    ? {
        id: cartDeliveryMethod.id ?? '',
        branchId: cartDeliveryMethod?.branchId ?? null,
        preferredDate: cartDeliveryMethod.preferredDate,
        preferredTime: cartDeliveryMethod.preferredTime
      }
    : orderedCart
    ? orderedCartDeliveryMethod
    : undefined;

  const onBackButtonEvent = () => {
    switch (window.location.pathname) {
      case '/checkout':
        setStep(Step.INFO);
        break;
      case '/checkout/review':
        if (!contract) {
          history.push('/');
        } else {
          setStep(Step.REVIEW);
        }
        break;
      case '/checkout/complete':
        setStep(Step.CONFIRMATION);
        break;
    }
  };

  /**
   * API
   */
  // 🟣 API - get rapid delivery checks
  const { call: callRapidDeliveryCheck, loading: rapidDeliveryCheckLoading } =
    useApiRapidDeliveryCheck();
  // 🟣 API - get checkout info
  const {
    call: callgetCheckout,
    called: callGetCheckoutCalled,
    loading: getCheckoutLoading
  } = useApiGetCheckout();
  // 🟣 API - call Update Delivery Type
  const { call: callUpdateDeliveryType } = useApiUpdateIsRapid();
  // 🟣 API - call Update Credit Card
  const { call: callUpdateCreditCard } = useApiUpdateCreditCard();
  // 🟣 API - call Update Address
  const { call: updateDeliveryAddress } = useApiUpdateDeliveryAddress();

  /**
   * Effect
   */
  useEffect(() => {
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEclipse && !callGetCheckoutCalled && cart?.id) {
      callgetCheckout(cart?.id!).then((res) => {
        res?.data?.delivery?.isRapid &&
          setDeliveryMethodOption(DeliveryMethodOptionEnum.Rapid);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart?.id]);
  useEffect(() => {
    if (cart) {
      setDeliveryMethod(cart.deliveryMethod || DeliveryMethodEnum.Delivery);
      setDeliveryData({
        address: cart.delivery?.address,
        preferredDate: cart.delivery?.preferredDate,
        preferredTime: cart.delivery?.preferredTime,
        deliveryInstructions: cart.delivery?.deliveryInstructions,
        shouldShipFullOrder: cart.delivery?.shouldShipFullOrder
      });
      setWillCallData({
        preferredDate: cart.willCall?.preferredDate,
        preferredTime: cart.willCall?.preferredTime,
        pickupInstructions: cart.willCall?.pickupInstructions
      });
      setCartLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  // Add query params
  useEffect(() => {
    switch (step) {
      case Step.INFO:
        if (location.pathname !== '/checkout') {
          history.push('/checkout', { canShowCustomNavAlert: !!contract });
        }
        setTimeout(() => {
          trackCheckoutPageViewAction({
            billTo: billToAccountId,
            userEmail: userEmail
          });
        }, 2500);
        break;
      case Step.REVIEW:
        if (location.pathname !== '/checkout/review') {
          history.push('/checkout/review', {
            canShowCustomNavAlert: !!contract
          });
        }
        break;
      case Step.PAYMENT:
        if (location.pathname !== '/checkout/payment') {
          history.push('/checkout/payment', {
            canShowCustomNavAlert: !!contract
          });
        }
        break;
      case Step.CONFIRMATION:
        if (location.pathname !== '/checkout/complete') {
          history.push('/checkout/complete');
        }
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  /**
   * Callbacks
   */
  const rapidDeliveryCheck = async (
    cartId: string,
    req: RapidDeliveryRequest
  ) => {
    const res = (await callRapidDeliveryCheck(cartId, req))?.data;
    setRapidDelivery(res!);
    if (
      !res?.isRapidAllowed &&
      deliveryMethodOption === DeliveryMethodOptionEnum.Rapid
    ) {
      setDeliveryMethodOption(DeliveryMethodOptionEnum.Standard);
      callUpdateDeliveryType(cart?.id!, DeliveryMethodOptionEnum.Standard);
    }
    return res;
  };

  const getCheckout = async (cartId: string) => {
    const res = (await callgetCheckout(cartId))?.data;
    res?.delivery?.isRapid &&
      setDeliveryMethodOption(DeliveryMethodOptionEnum.Rapid);
    return res;
  };

  const updatePayment = async (newPaymentData: PaymentData) => {
    const { creditCard, ...paymentInput } = newPaymentData;

    const updatedCreditCard = {
      ...creditCard,
      expirationDate: omit(creditCard?.expirationDate, '__typename')
    } as CreditCardInput;

    const updateCartData: CartInput = {
      ...paymentInput,
      creditCard: creditCard ? omit(updatedCreditCard, '__typename') : null
    };
    if (updateCartData?.creditCard) {
      const resp = await callUpdateCreditCard(
        cart!.id!,
        updateCartData?.creditCard
      );
      if (resp?.data) {
        const data = {
          paymentMethodType: resp?.data?.paymentMethodType,
          poNumber: resp?.data?.poNumber,
          creditCard: resp?.data?.creditCard
        };
        setPaymentData(data);
      }
    }
  };

  const updateDelivery = async (deliveryData: DeliveryData) => {
    const { address, ...deliveryInput } = deliveryData;
    const addressInput = omit(address, ['__typename', 'id']) as AddressInput;
    if (isEclipse && !contract) {
      updateCartDeliveryPreferences?.(
        cart!.id!,
        cart!.delivery!.shouldShipFullOrder!
      );
      await updateDeliveryAddress(cart!.id!, addressInput);
    } else {
      const updateDeliveryData: DeliveryInput = {
        cartId: cart?.id!,
        shouldShipFullOrder: cart?.delivery?.shouldShipFullOrder!,
        address: addressInput,
        ...deliveryInput
      };
      await cartUpdateDelivery?.(updateDeliveryData);
      setDeliveryData(deliveryData);
    }
  };
  // Used for contract and quote only
  const updateWillCall = async (willCallData: WillCallData) => {
    const updateWillCallData: WillCallInput = {
      cartId: cart?.id!,
      ...willCallData
    };
    await cartUpdateWillCall?.(updateWillCallData);
    setWillCallData(willCallData);
  };

  const isDeliveryMethodCb = (
    deliveryMethodObject?: DeliveryProps | WillCallProps
  ): deliveryMethodObject is DeliveryProps =>
    Boolean((deliveryMethodObject as DeliveryProps)?.address);
  const isDeliveryMethod = isDeliveryMethodCb(deliveryMethodObject);

  /**
   * Render
   */
  return (
    <CheckoutContext.Provider
      value={{
        deliveryData,
        deliveryMethod,
        deliveryMethodOption,
        disableContinue,
        getCheckout,
        getCheckoutLoading,
        setDisableContinue,
        orderData,
        orderPreviewData,
        paymentData,
        poNumberError,
        rapidDelivery,
        setRapidDelivery,
        setDeliveryData,
        setDeliveryMethodOption,
        setOrderData,
        setOrderPreviewData,
        setPaymentData,
        setPoNumberError,
        setShipToBranch,
        setStep,
        setWillCallData,
        shipToBranch,
        step,
        willCallData,
        deliveryMethodObject,
        updateCart: updatePayment,
        updateDelivery,
        rapidDeliveryCheck,
        rapidDeliveryCheckLoading,
        updateWillCall,
        tempCartItems,
        setTempCartItems,
        mnCronShoppingCartId,
        setMnCronShoppingCartId,
        orderedCart,
        setOrderedCart,
        orderedContract,
        setOrderedContract,
        orderedLineNotes,
        setOrderedLineNotes,
        isCreditSelectionChanging,
        setIsCreditSelectionChanging,
        isDelivery: deliveryMethod === DeliveryMethodEnum.Delivery,
        isWillCall: deliveryMethod === DeliveryMethodEnum.Willcall,
        isDeliveryMethod,
        cartLoaded
      }}
    >
      {props.children}
    </CheckoutContext.Provider>
  );
}

export const useCheckoutContext = () => useContext(CheckoutContext);

export default CheckoutProvider;
