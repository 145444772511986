import palette from 'providers/libs/Theme/palette';

const muiListItemText = {
  multiline: {
    marginTop: 0,
    marginBottom: 0
  },
  primary: {
    color: palette.primary.main
  },
  secondary: {
    color: palette.secondary02.main
  }
};

export default muiListItemText;
