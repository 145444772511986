import { useContext, useMemo } from 'react';

import { Switch, Container } from '@mui/material';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';

import Breadcrumbs from 'common/Breadcrumbs';
import PermissionRequired, { Permission } from 'common/PermissionRequired';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { useToggleFeatures } from 'pages/FeatureToggle/lib/useToggleFeatures';
import { AuthContext } from 'providers/AuthProvider';

/**
 *
 * @returns Component
 */
function FeatureToggle() {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();
  const { setFeature } = useToggleFeatures();
  useDocumentTitle(t('common.features'));

  /**
   * Contest
   */
  const { features } = useContext(AuthContext);

  /**
   * Memo
   */
  const sortedFeatures = useMemo(
    () => orderBy(features ?? [], ['name']),
    [features]
  );

  /**
   * Render
   */
  return (
    <PermissionRequired permissions={[Permission.TOGGLE_FEATURES]}>
      <div data-testid="features-wrapper">
        <Breadcrumbs pageTitle={t('featureToggle.featureToggle')} />
        <Container>
          {sortedFeatures.map((feature) => (
            <div key={feature.id} className="flex flex-row">
              <h4 className="mt-1 text-2xl font-medium">{feature.name}</h4>
              <Switch
                checked={feature.isEnabled}
                onChange={setFeature(feature)}
                name="checkedA"
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
                role="feature-switch"
              />
            </div>
          ))}
        </Container>
      </div>
    </PermissionRequired>
  );
}

export default FeatureToggle;
