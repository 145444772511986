import { useMemo } from 'react';

import {
  Container,
  Breadcrumbs as MuiBreadcrumbs,
  Hidden
} from '@mui/material';
import * as H from 'history';
import { isUndefined, kebabCase } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Button } from 'components';
import useScreenSize from 'hooks/useScreenSize';
import { ChevronLeftIcon, ChevronRightIcon } from 'icons';

/**
 * Types
 */
export type BreadcrumbConfig = {
  text: string;
  to?: string | H.Location;
};
export type BreadcrumbsProps = {
  pageTitle: string;
  config?: BreadcrumbConfig[];
  className?: string; // MuiBreadcrumbs CSS override
  newMobileStyle?: boolean;
};

/**
 * Component
 */
function Breadcrumbs(props: BreadcrumbsProps) {
  /**
   * Props
   */
  const { config = [], pageTitle, newMobileStyle } = props;

  /**
   * Custom Hooks
   */
  const { isSmallScreen } = useScreenSize();
  const { t } = useTranslation();

  /**
   * Memos
   */
  // 🔵 memo - config with home
  const configWithHome = useMemo(
    () => [{ text: t('common.home'), to: '/' }, ...config],
    [config, t]
  );
  // 🔵 memo - filtered config
  const filteredConfig = useMemo(
    () => configWithHome.filter((c) => c.text !== '.'),
    [configWithHome]
  );
  // 🔵 memo - previous path
  const previousPath = useMemo(() => {
    const previousLinks = configWithHome.filter(({ to }) => !isUndefined(to));
    return previousLinks.length > 1
      ? previousLinks.slice(-1)[0]
      : configWithHome[0];
  }, [configWithHome]);

  /**
   * Render
   */
  return (
    <Container disableGutters={isSmallScreen} className="print:hidden">
      {/* Mobile */}
      {!newMobileStyle && (
        <Hidden mdUp>
          <div className="w-full py-2" data-testid="breadcrumb-mobile">
            <RouterLink
              to={previousPath.to!} // This is never undefined, previousPath memo filters it out
              data-testid="breadcrumb-mobile-link"
            >
              <Button
                iconStart={<ChevronLeftIcon />}
                kind="text"
                data-testid="breadcrumb-mobile-button"
              >
                {previousPath.text !== '.'
                  ? t('breadcrumbs.backTo', { text: previousPath.text })
                  : t('common.back')}
              </Button>
            </RouterLink>
          </div>
        </Hidden>
      )}
      {/* Desktop */}
      <Hidden mdDown={!newMobileStyle}>
        <MuiBreadcrumbs
          aria-label="breadcrumb"
          separator={<ChevronRightIcon />}
          className={props.className}
          data-testid="breadcrumb-desktop"
        >
          {filteredConfig.map((c) => (
            <RouterLink
              key={c.text}
              to={c.to ?? '#'}
              className="text-primary-1-100"
              data-testid={kebabCase(`breadcrumb-item-${c.text}`)}
            >
              {c.text}
            </RouterLink>
          ))}
          <span className="text-primary-3-100" data-testid="breadcrumb-title">
            {pageTitle}
          </span>
        </MuiBreadcrumbs>
      </Hidden>
    </Container>
  );
}

export default Breadcrumbs;
