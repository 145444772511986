import { WrapperProps } from '@reece/global-types';
import clsx from 'clsx';
import { useEffect, useState } from 'react';

/**
 * Types
 */
export type FadeProps = WrapperProps & {
  className?: string;
  in: boolean;
  stayMounted?: boolean;
  testId?: string;
};

/**
 * Component
 */
function Fade(props: FadeProps) {
  /**
   * States
   */
  const [visible, setVisible] = useState(false);

  /**
   * Effects
   */
  useEffect(() => {
    props.in && setVisible(true);
  }, [props.in]);

  /**
   * Render
   */
  if (!props.in && !visible && !props.stayMounted) {
    return null;
  }
  return (
    <div
      className={clsx('transition-opacity', props.className, {
        'animate-fade-in': props.in,
        'animate-fade-out': !props.in,
        hidden: !props.in && !visible
      })}
      onAnimationEnd={() => !props.in && setVisible(false)}
      data-testid={props.testId}
    >
      {props.children}
    </div>
  );
}
export default Fade;
