import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CloseIcon } from 'icons';
import { IconButton } from 'components';

/**
 * Types
 */
export type HoldAlertDialogProps = {
  open: boolean;
  onClose: () => void;
};

/**
 * Component
 */
function HoldAlertDialog(props: HoldAlertDialogProps) {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();

  /**
   * Render
   */
  return (
    <Dialog
      open={props.open}
      fullWidth
      maxWidth="sm"
      onClose={props.onClose}
      PaperProps={{ className: 'my-8 mx-4 w-[calc(100%-32px)]' }}
    >
      <DialogTitle
        className="flex justify-between items-center bg-primary-1-100 text-common-white"
        data-testid="holdalert-title"
      >
        <h5 className="text-xl font-medium">{t('common.warning')}</h5>
        <IconButton
          color="inherit"
          size="small"
          onClick={props.onClose}
          data-testid="hold-alert-dialog-close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <p className="px-6 py-4 text-secondary-2-100 text-base whitespace-pre md:px-0 md-py-2 md:whitespace-[initial]">
          {t('common.accountHoldOrdering')}
        </p>
      </DialogContent>
    </Dialog>
  );
}

export default HoldAlertDialog;
