import { useEffect, useMemo } from 'react';

import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@dialexa/reece-component-library';

import { SavePdfIcon } from 'icons';
import { useDomainInfo } from 'hooks/useDomainInfo';
import { useAuthContext } from 'providers/AuthProvider';
import { ErpSystemEnum } from 'generated/graphql';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';
import { trackCreditFormsPageViewAction } from 'utils/analytics';
import { Configuration } from 'utils/configuration';

interface CreditFormURLType {
  url: string;
  title: string;
  authorizationRequired?: boolean;
}

var fortilineFormURLS: CreditFormURLType[] = [];
var baseFormsURLS: CreditFormURLType[] = [];

function FormLinks() {
  const { isWaterworks } = useDomainInfo();
  const { selectedAccounts } = useSelectedAccountsContext();
  const { authState, hasFeature, user } = useAuthContext();
  const isBectranEnabled = hasFeature?.('BECTRAN_FORMS_TEST');
  const isBectranProdEnabled = hasFeature?.('BECTRAN_FORMS_PROD');

  const billToAccountId = selectedAccounts.billToErpAccount?.erpAccountId ?? '';
  const userEmail = user?.email ?? '';

  const urlFormEN = useMemo(() => {
    switch (true) {
      case isBectranProdEnabled && isWaterworks:
        return Configuration.creditFormWWProdEN;
      case isBectranEnabled && isWaterworks:
        return Configuration.creditFormWWEN;
      case isBectranProdEnabled:
        return Configuration.creditFormProdEN;
      case isBectranEnabled:
        return Configuration.creditFormEN;
      default:
        return '/files/credit-forms/MORSCO-Credit-Application-Wave-3-Rebrand-May-2024_Editable.pdf';
    }
  }, [isBectranProdEnabled, isBectranEnabled, isWaterworks]);

  const urlFormSP = useMemo(() => {
    switch (true) {
      case isBectranProdEnabled && isWaterworks:
        return Configuration.creditFormWWProdES;
      case isBectranEnabled && isWaterworks:
        return Configuration.creditFormES;
      case isBectranProdEnabled:
        return Configuration.creditFormProdES;
      case isBectranEnabled:
        return Configuration.creditFormES;
      default:
        return '/files/credit-forms/MORSCO-Credit-Application-Wave-3-Rebrand-May-2024_Editable.pdf';
    }
  }, [isBectranProdEnabled, isBectranEnabled, isWaterworks]);

  baseFormsURLS = [
    {
      url: urlFormEN,
      title: 'Customer Account Credit Application'
    },
    {
      url: urlFormSP,
      title: 'Solicitud de Crédito'
    }
  ];

  fortilineFormURLS = [
    ...baseFormsURLS,
    {
      url: '/files/credit-forms/EFT-One-Time-Form-WW-V2.pdf',
      title: 'Electronic Funds Transfer Authorization Form'
    }
  ];

  const commonFormURLS: CreditFormURLType[] = [
    ...baseFormsURLS,
    {
      url: '/files/credit-forms/INVOICE-AND-DELIVERY-TICKET-Terms-of-Sale_12-29-2014.pdf',
      title: 'Invoice & Delivery Ticket Terms of Sale'
    },
    {
      url: '/files/credit-forms/EFT-Customer-Authorization-Form-Morrison.pdf',
      title: 'Electronic Funds Transfer Authorization Form'
    },
    {
      url: '/files/credit-forms/Morsco_Tool_Rental_Agreement_Form_20211105.pdf',
      title: 'Equipment Rental Agreement',
      authorizationRequired: true
    }
  ];

  const isMincron = selectedAccounts.erpSystemName === ErpSystemEnum.Mincron;
  const creditFormURLS: CreditFormURLType[] =
    isMincron || isWaterworks ? fortilineFormURLS : commonFormURLS;

  useEffect(() => {
    // tealium page view tracking for credit forms has to be placed here and with a timeout to prevent utag error at render
    setTimeout(() => {
      trackCreditFormsPageViewAction({
        billTo: billToAccountId,
        userEmail: userEmail
      });
    }, 3000);
  });

  return (
    <Grid container>
      <Grid item xs={12} md={4}>
        <List>
          {creditFormURLS
            .filter(
              (url) => !url.authorizationRequired || authState?.isAuthenticated
            )
            .map((item, i, arr) => (
              <ListItem
                key={item.title}
                component="a"
                href={item.url}
                target="_blank"
                divider={i < arr.length - 1}
                disableGutters
              >
                <ListItemIcon>
                  <SavePdfIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Box component="span" color="primary02.main">
                      {item.title}
                    </Box>
                  }
                />
              </ListItem>
            ))}
        </List>
      </Grid>
    </Grid>
  );
}
export default FormLinks;
