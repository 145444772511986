import theme from 'providers/libs/Theme/baseTheme';

const muiListItem = {
  root: {
    minWidth: 'auto',
    marginLeft: theme.spacing(-3),
    marginRight: theme.spacing(1.5),
    '&.MuiListItemAvatar-alignItemsFlexStart': {
      marginTop: theme.spacing(0.5)
    }
  }
};

export default muiListItem;
