import { useState } from 'react';

import { APIBaseCall, useApiBase, UseAPIBaseProps } from 'API/hooks/useApiBase';
import { APICoreProps, HeaderProps } from 'API/lib/core';
import { Maybe } from 'generated/graphql';

/**
 * Types
 */
export type UseAPILazyProps<Response, Request = object> = Omit<
  UseAPIBaseProps<Response, Request>,
  'body'
>;
export type UseAPILazyOutput<Res, Req = object> = {
  called: boolean;
  data?: Maybe<Res>;
  call: APIBaseCall<Maybe<Res>, Req>;
  loading: boolean;
};

/**
 * Hook
 */
export function useApiLazy<Response, Request = object>(
  props: UseAPILazyProps<Response, Request>
): UseAPILazyOutput<Response, Request> {
  /**
   * API
   */
  const { call, ...rest } = useApiBase<Response, Request>(props);

  /**
   * State
   */
  const [data, setData] = useState<Maybe<Response>>();

  /**
   * Callback
   */
  const asyncCall = async (myProps?: Partial<APICoreProps<Request>>) => {
    const header: HeaderProps = {
      ...props.header,
      ...myProps?.header
    };
    const res = await call({ ...props, ...myProps, header });
    setData(res?.data);
    return res;
  };

  /**
   * Output
   */
  return { ...rest, data, call: asyncCall };
}
