import { useCallback } from 'react';

import {
  Input,
  MenuItem,
  Select as MuiSelect,
  SelectChangeEvent,
  SelectProps as MuiSelectProps
} from '@mui/material';
import clsx from 'clsx';

import { Modify } from '@reece/global-types';
import Label from 'components/Label';
import {
  inputStyles,
  memnuListProps,
  messageError,
  paperStyle
} from 'components/SelectInput/styles';

/**
 * Types
 */
export type SelectOption = { label: string; value: string };
type NewProps = {
  label?: string;
  listKey: string;
  message?: string;
  onChange?: (value: string) => void;
  options: SelectOption[];
  testId?: string;
};
export type SelectInputProps = Modify<MuiSelectProps<string>, NewProps>;

/**
 * Component
 */
function SelectInput(props: SelectInputProps) {
  /**
   * Props
   */
  const {
    children,
    error,
    id,
    label,
    listKey,
    message,
    name,
    onChange,
    options,
    placeholder,
    renderValue,
    required,
    testId,
    ...rest
  } = props;

  /**
   * Callbacks
   */
  // 🟤 Cb - Default render value
  const defaultRenderValue = useCallback(
    (selected: string) => {
      const selectedIndex = options.findIndex((i) => i.value === selected);
      return selectedIndex === -1
        ? placeholder ?? ''
        : options[selectedIndex].label;
    },
    [options, placeholder]
  );
  // 🟤 Cb - onChange
  const onChangeCb = (e: SelectChangeEvent<unknown>) =>
    onChange?.(e.target.value as string);

  /**
   * Render
   */
  return (
    <div className={clsx('flex flex-col', { 'w-full': props.fullWidth })}>
      {/* Label */}
      <Label
        label={label}
        htmlFor={id ?? name}
        required={required}
        testId={testId}
      />
      {/* Select */}
      <MuiSelect
        id={id}
        name={name}
        className={clsx('!pr-0 [&_svg]:!right-3', inputStyles)}
        input={<Input inputProps={{ 'data-testid': testId }} />}
        displayEmpty={placeholder !== undefined}
        renderValue={renderValue ?? defaultRenderValue}
        error={error}
        onChange={onChangeCb}
        MenuProps={{
          PaperProps: { className: paperStyle },
          MenuListProps: { className: memnuListProps },
          anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
          transformOrigin: { vertical: 'top', horizontal: 'left' },
          elevation: 0
        }}
        data-testid={`${testId}-select`}
        {...rest}
      >
        {options.map((item) => (
          <MenuItem
            value={item.value}
            key={`${listKey}-${item.value}`}
            children={item.label}
          />
        ))}
      </MuiSelect>
      {/* Message */}
      {Boolean(message) && (
        <div className="flex justify-between pt-1 px-4 pb-0 text-xs">
          {Boolean(message) && (
            <span
              className={clsx({ [messageError]: error })}
              data-testid={`${testId}-message`}
            >
              {message}
            </span>
          )}
        </div>
      )}
    </div>
  );
}

export default SelectInput;
