import palette from 'providers/libs/Theme/palette';
import theme from 'providers/libs/Theme/baseTheme';

const muiTableCell = {
  head: {
    fontSize: theme.typography.pxToRem(12.8),
    lineHeight: theme.typography.pxToRem(16.2),
    letterSpacing: 0,
    color: palette.primary.main,
    padding: theme.spacing(1.5, 2.5, 1)
  },
  body: {
    padding: theme.spacing(3, 2.5)
  }
};

export default muiTableCell;
