import { useTranslation } from 'react-i18next';

import { Button, Modal } from 'components';

/**
 * Types
 */

export type TimeoutModalProps = {
  open: boolean;
  onSubmit: () => void;
};

/**
 * Component
 */
function RapidDeliveryTimeoutModal(props: TimeoutModalProps) {
  const { open, onSubmit } = props;
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();

  /**
   * Render
   */
  return (
    <Modal
      width="sm"
      overridePageScroll
      noClose
      noRounded
      open={open}
      testId="rapid-delivery-not-available-dialog"
      headerContent={
        <h5 className="text-primary-3-100 text-xl font-medium px-2 pt-2">
          {t('cart.rapidDeliveryNotAvailable')}
        </h5>
      }
    >
      <div className="flex flex-col gap-4 px-2">
        <p
          data-testid="rapid-delivery-not-available-dialog-description"
          className="pb-8 text-base text-secondary-2-100"
        >
          {t('cart.rapidDeliveryTimeoutDescription')}
        </p>
        <Button
          key="rapid-delivery-not-available-dialog-submit"
          type="button"
          color="primary"
          size="lg"
          className="!w-fit self-end"
          onClick={onSubmit}
          data-testid="rapid-delivery-not-available-dialog-submit"
        >
          {t('cart.rapidDeliverySwitchSubmit')}
        </Button>
      </div>
    </Modal>
  );
}

export default RapidDeliveryTimeoutModal;
