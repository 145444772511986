import { Lightbulb } from '@mui/icons-material';
import Button from 'components/Button';
import Card from 'components/Card';
import DropdownButton from 'components/DropdownButton';

/**
 * Config
 */

const display = {
  title: 'DropdownButton',
  button: 'Click to Open',
  contentTitle: 'Dropdown Title',
  content1: 'TEST 1',
  content2: 'TEST 2',
  content3: 'TEST 3',
  close: 'Close'
};

/**
 * Component
 */
function DropdownButtonPage() {
  return (
    <Card className="my-8 p-6">
      <h5 className="text-2xl font-bold text-secondary-2-100">
        {display.title}
      </h5>
      <hr className="my-4" />
      <div className="my-4 flex justify-center">
        <DropdownButton
          content={(_, setOpen) => <DropdownContent setOpen={setOpen} />}
          children={display.button}
          iconStart={<Lightbulb color="primary" />}
        />
      </div>
    </Card>
  );
}

export default DropdownButtonPage;

/**
 * Sub-component
 */
// istanbul ignore next
function DropdownContent({ setOpen }: { setOpen: (o: boolean) => void }) {
  return (
    <div className="flex flex-col px-3 py-1">
      <h5 className="block py-2 font-bold">{display.contentTitle}</h5>
      <span className="block py-2">{display.content1}</span>
      <span className="block py-2">{display.content2}</span>
      <span className="block py-2">{display.content3}</span>
      <Button kind="text" onClick={() => setOpen(false)}>
        {display.close}
      </Button>
    </div>
  );
}
