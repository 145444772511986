import {
  ChangeEventHandler,
  ComponentPropsWithoutRef,
  ReactNode,
  forwardRef,
  useState
} from 'react';

import clsx from 'clsx';
import { size } from 'lodash-es';

import { CloseIcon, PasswordHide, PasswordShow } from 'icons';
import Button from 'components/Button';
import InputBase from 'components/TextInput/InputBase';
import Label from 'components/Label';

/**
 * Types
 */
// 🔸 onClear: the X button will only appear if this is used and value isn't empty
export type TextInputProps = ComponentPropsWithoutRef<'input'> & {
  className?: string;
  error?: boolean;
  iconEnd?: JSX.Element;
  iconEndClassName?: string;
  iconStart?: JSX.Element;
  iconStartClassName?: string;
  inputClassName?: string;
  label?: string | ReactNode;
  labelClassName?: string;
  message?: string;
  messageClassName?: string;
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
  status?: 'success' | 'neutral';
  testId?: string;
  onClear?: () => void;
};

/**
 * Component
 */
const TextInput = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  /**
   * Props
   */
  const {
    className,
    disabled,
    error,
    iconEnd,
    iconEndClassName,
    iconStart,
    iconStartClassName,
    inputClassName,
    label,
    labelClassName,
    maxLength,
    message,
    messageClassName,
    readOnly,
    testId,
    type,
    value,
    onClear,
    ...rest
  } = props;

  /**
   * State
   */
  const [showPassword, setShowPassword] = useState(false);
  const passwordType = showPassword ? 'text' : 'password';

  /**
   * Render
   */
  return (
    <div className={clsx('flex flex-col h-max w-full', className)}>
      {/* Label */}
      <Label
        label={label}
        required={rest.required}
        htmlFor={rest.id ?? rest.name}
        testId={testId}
      />
      {/* Input */}
      <div className="relative">
        <InputBase
          iconEnd={iconEnd}
          iconEndClassName={iconEndClassName}
          iconStart={iconStart}
          iconStartClassName={iconStartClassName}
          className={clsx(inputClassName, { 'pr-12': onClear })}
          disabled={disabled}
          error={error}
          readOnly={readOnly}
          type={type === 'password' ? passwordType : type}
          ref={ref}
          value={value}
          data-testid={testId}
          maxLength={maxLength}
          {...rest}
        />
        {Boolean(onClear && value) && (
          <Button
            className={clsx(
              'absolute right-2 top-1/2 !min-w-0 !p-2 transform -translate-y-1/2',
              { 'mr-10': props.iconEnd }
            )}
            color="gray"
            kind="text"
            size="sm"
            onClick={onClear}
            data-testid={`${testId}-clear-button`}
          >
            <CloseIcon />
          </Button>
        )}
        {type === 'password' && (
          <button
            type="button"
            className="absolute right-[16px] top-0 bottom-0"
            onClick={() => setShowPassword(!showPassword)}
            tabIndex={-1}
            data-testid={`${testId}-toggle-password`}
          >
            <span className="text-secondary-3-100">
              {showPassword ? <PasswordShow /> : <PasswordHide />}
            </span>
          </button>
        )}
        <div className="grid grid-cols-[90%_10%] items-center px-2 text-xs">
          {/* Message */}
          <div className="flex">
            {Boolean(message) && (
              <span
                className={clsx(messageClassName, {
                  'text-support-1-100': error
                })}
                data-testid={`${testId}-message`}
              >
                {message}
              </span>
            )}
          </div>
          {/* Count */}
          {maxLength && (
            <div
              className={clsx('flex justify-self-end', {
                'text-support-1-100': error
              })}
              data-testid={`${testId}-error`}
            >{`${size(`${value}`)}/${maxLength}`}</div>
          )}
        </div>
      </div>
    </div>
  );
});

export default TextInput;
