import { useCallback, useMemo, useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { ProductBasicInfo } from 'API/types/lists.types';
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormAutocompleteOption,
  Label,
  Modal
} from 'components';
import { AddIcon } from 'icons';
import useScreenSize from 'hooks/useScreenSize';
import baseI18nComponents from 'locales/baseComponents';
import { useListsPageContext } from 'pages/Lists/provider/ListsPageProvider';
import { MAX_LIST_ITEMS } from 'providers/libs/ListsProvider';
import { useToastContext } from 'providers/ToastProvider';

/**
 * Types
 */
export type ListCopyDialogProps = {
  open: boolean;
  onClose: () => void;
};

/**
 * Component
 */
function ListCopyDialog(props: ListCopyDialogProps) {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();
  const { isSmallScreen } = useScreenSize();

  /**
   * Context
   */
  const { toast } = useToastContext();
  const {
    callAddProductsToList,
    lists,
    listProducts,
    selectedLineItems,
    selectedList,
    setLists,
    setOpenCreateFormDialog,
    setProductsToAddToList
  } = useListsPageContext();

  /**
   * States
   */
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [error, setError] = useState<string>();

  /**
   * Memos
   */
  // 🔵 memo - list options
  const listOptions = useMemo(
    () =>
      lists.reduce<FormAutocompleteOption[]>((previous, current) => {
        current.id !== selectedList?.list.id &&
          previous.push({
            label: current.name,
            value: current.id,
            listSize: current.listLineItemsSize ?? 0
          });
        return previous;
      }, []),
    [lists, selectedList]
  );

  /**
   * Callbacks
   */
  // 🟤 Cb - Close
  const handleClose = () => {
    setSelectedValue('');
    setError(undefined);
    props.onClose();
  };
  // 🟤 Cb - Handle autocomplete input change
  const handleOnChange = (input: string) => {
    setError(undefined);
    if (!input) {
      setError('validation.nameRequired');
    } else if (checkIsListFull(input)) {
      setError('lists.listsHardLimitErrorFull');
    }
    setSelectedValue(input);
  };
  // 🟤 Cb - Copy products to selected list
  const handleSubmit = () => {
    const products = createProductsObject();
    if (!products.length) {
      return;
    }
    setLoading(true);
    callAddProductsToList({ listId: [selectedValue], products })
      .then((res) => {
        if (!res?.length) {
          return;
        }
        setLists(res);
        const listName = res.find(({ id }) => id === selectedValue)?.name;
        toast({
          message: t('lists.productCopied', {
            count: products.length,
            listName
          }),
          kind: 'success'
        });
      })
      .catch(() =>
        toast({ message: t('lists.productsNotCopied'), kind: 'error' })
      )
      .finally(() => {
        handleClose();
        setTimeout(
          // istanbul ignore next
          () => setLoading(false),
          200
        );
      });
  };
  // 🟤 Cb - Open create modal with products array
  const handleOpenCreateDialog = () => {
    handleClose();
    setOpenCreateFormDialog(true);
    setProductsToAddToList(createProductsObject());
  };
  // 🟤 Cb - Create product object from selected items
  const createProductsObject = () => {
    // process selected line items each to copy to list
    const products = Object.entries(selectedLineItems).reduce<
      ProductBasicInfo[]
    >((prev, [productId]) => {
      // process data
      const product = listProducts.find((item) => item.productId === productId);
      const quantity = product?.quantity || product?.minIncrementQty || 1;
      const newItem = { productId, quantity };
      return [...prev, newItem];
    }, []);
    // Create a map of productId to index in listProducts
    const indexMap = new Map(
      listProducts.map((item, index) => [item.productId, index])
    );
    // Sort products based on the index positions in listProducts
    return products.sort((a, b) => {
      const indexA = indexMap.get(a.productId) ?? -1;
      const indexB = indexMap.get(b.productId) ?? -1;
      return indexA - indexB;
    });
  };
  // 🟤 cb - selected list full state
  const checkIsListFull = useCallback(
    (myId?: string) => {
      const foundList = lists.find(({ id }) => id === myId);
      const lineItemCount = Object.keys(selectedLineItems).length;
      if (!foundList?.listLineItemsSize || !lineItemCount) {
        return false;
      }
      return foundList.listLineItemsSize + lineItemCount > MAX_LIST_ITEMS;
    },
    [lists, selectedLineItems]
  );

  /**
   * Render
   */
  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      width={isSmallScreen ? 'xs' : 'sm'}
      testId="list-copy-dialog"
      headerContent={
        <h5 className="text-primary-3-100 text-xl font-medium">
          {t(isSmallScreen ? 'lists.copyItem' : 'lists.copyProductToList')}
        </h5>
      }
    >
      {/* List to copy dropdown */}
      <div className="my-6 flex justify-center">
        <div className="flex flex-col grow">
          <Label label={t('lists.selectListToCopy')} />
          <Autocomplete
            options={listOptions}
            value={selectedValue}
            onChange={handleOnChange}
            maxDropdownHeight={288}
          />
          {/* Message */}
          {Boolean(error) && (
            <span
              className="px-4 pt-1 text-support-1-100 text-xs sm:pt-0"
              data-testid="select-list-id-error"
            >
              <Trans i18nKey={error} components={baseI18nComponents} />
            </span>
          )}
        </div>
      </div>
      {/* <>-------------------------<> SUBMIT BUTTONS <>-------------------------<> */}
      <div className="flex gap-x-8 gap-y-4 items-center transition-none !justify-end flex-col pb-4">
        <Button
          data-testid="list-copy-dialog-copy-button"
          key="btn-submit"
          fullWidth
          disabled={
            loading ||
            Boolean(error) ||
            !selectedValue ||
            !Object.keys(selectedLineItems).length
          }
          onClick={handleSubmit}
        >
          {loading ? (
            <CircularProgress size={24} color="secondary" />
          ) : (
            t('lists.copyItem')
          )}
        </Button>

        {/* OR Divider */}
        <div className="flex items-center w-full my-2">
          <div className="flex-grow border-t border-primary-3-20"></div>
          <span className="mx-2 text-secondary-3-100 uppercase">
            {t('common.or')}
          </span>
          <div className="flex-grow border-t border-primary-3-20"></div>
        </div>

        <Button
          data-testid="list-copy-dialog-create-button"
          type="button"
          onClick={handleOpenCreateDialog}
          kind="outline"
          fullWidth
          iconStart={<AddIcon />}
          disabled={loading || Object.keys(selectedLineItems).length === 0}
        >
          {t('lists.createNewList')}
        </Button>
      </div>
    </Modal>
  );
}

export default ListCopyDialog;
