import { useCallback, useMemo } from 'react';

import { Hidden } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

import { useAuthContext } from 'providers/AuthProvider';
import { Button, CircularProgress } from 'components';
import { CartIcon } from 'icons';
import { useCartContext } from 'providers/CartProvider';

/**
 * Config
 */
export const navAlertState = { canShowNavAlert: true };

/**
 * Component
 */
function NewCartButton() {
  /**
   * Custom hooks
   */
  const { pathname } = useLocation();
  const { push } = useHistory();
  const { t } = useTranslation();

  /**
   * Context
   */
  const { authState } = useAuthContext();
  const {
    cart,
    cartLoading,
    checkingOutWithQuote,
    contract,
    clearQuote,
    itemCount,
    itemLoading,
    refreshCart
  } = useCartContext();
  const isLoading = Boolean(cartLoading || itemLoading);

  /**
   * Memo
   */
  // 🔵 Memo - cart button content
  const cartButtonContent = useMemo(
    () => (
      <>
        <CartIcon height={32} width={32} className="text-primary-3-100" />
        {isLoading && (
          <Hidden mdDown>
            <div className="flex absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
              <CircularProgress size="2rem" noBack />
            </div>
          </Hidden>
        )}
      </>
    ),
    [isLoading]
  );

  /**
   * Callbacks
   */
  // 🟤 Cb - go to cart
  const goToCart = useCallback(() => {
    // consts
    const isAuth = authState?.isAuthenticated;
    // clear quote
    if (checkingOutWithQuote) {
      clearQuote();
    }
    // call to refresh pricing and availability data on cart when view cart
    if (cart?.products && !checkingOutWithQuote) {
      refreshCart?.();
    }
    // go to path
    push(isAuth ? '/cart' : '/login', isAuth && contract ? navAlertState : {});
  }, [
    authState?.isAuthenticated,
    cart?.products,
    checkingOutWithQuote,
    clearQuote,
    contract,
    push,
    refreshCart
  ]);

  /**
   * Render
   */
  if (pathname === '/select-accounts') {
    return null;
  }
  return (
    <div className="relative ml-6">
      {isLoading && (
        <div className="flex absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <CircularProgress
            size="2rem"
            noBack
            testId="go-to-cart-button-loading"
          />
        </div>
      )}

      <Button
        data-testid="go-to-cart-button-desktop"
        color="gray"
        iconStart={cartButtonContent}
        kind="text"
        onClick={goToCart}
        className="text-xl leading-[24px] font-medixl py-2 -mr-6"
      >
        {t('common.newCart', { count: itemCount })}
      </Button>
    </div>
  );
}

export default NewCartButton;
