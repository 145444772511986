import clsx from 'clsx';

import CircularProgress, {
  CircularProgressColor,
  CircularProgressProps
} from 'components/CircularProgress';
import { baseStyle, defaultStyle, pageStyle } from 'components/Loader/styles';

/**
 * Types
 */
export type LoaderProps = {
  backdrop?: boolean;
  className?: string;
  loaderColor?: CircularProgressColor;
  loaderSize?: CircularProgressProps['size'];
  size?: 'parent' | 'page' | 'flex';
  testId?: string;
};

/**
 * Component
 */
function Loader(props: LoaderProps) {
  /**
   * Props
   */
  const {
    backdrop,
    loaderColor = 'secondary',
    loaderSize,
    size = 'parent',
    testId
  } = props;

  /**
   * Render
   */
  return (
    <div
      className={clsx(baseStyle, props.className, {
        [pageStyle]: size === 'page',
        [defaultStyle]: size === 'parent',
        'bg-common-background-loader': backdrop
      })}
      data-testid={testId}
    >
      <CircularProgress color={loaderColor} size={loaderSize} />
    </div>
  );
}

export default Loader;
