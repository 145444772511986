import { useEffect, useState } from 'react';
import { BuilderComponent } from '@builder.io/react';
import { useTranslation } from 'react-i18next';

import { Loader } from 'components';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { useLocalStorage } from 'hooks/useLocalStorage';
import useBuilderIOAPI from 'pages/BuilderIO/lib/useBuilderIOAPI';
import { useDomainInfo } from 'hooks/useDomainInfo';
import { useApiGetBannerFlag } from 'API/users.api';

import '@builder.io/widgets';
/**
 * Component
 */
function BuilderIOHome() {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();
  useDocumentTitle(t('common.plumbingHVACWaterworksSupply'));
  const { isWaterworks } = useDomainInfo();
  const [showBanner, setShowBanner] = useState(false);

  /**
   * Context
   */
  const [userLogged] = useLocalStorage('userLogged', false);

  /**
   * Data
   */
  // 🟣 BuilderIO - page content
  const urlHome = (() => {
    if (!userLogged) return '/';
    if (isWaterworks) return '/home-page-fortiline-logged-in';
    return '/home-page-max';
  })();

  // BFF Get flag for banner
  const { call: getBannerFlag } = useApiGetBannerFlag(
    {
      onCompleted: ({ data }) => data && setShowBanner(true),
      onError: () => {
        console.error('something went wrong!');
        setShowBanner(false);
      }
    },
    { msgType: urlHome.substring(1, 50) }
  );

  const { content, loading } = useBuilderIOAPI('page', urlHome);

  const { content: contentBanner, loading: loadingBanner } = useBuilderIOAPI(
    'banner',
    `/home/banner/sign-in-announcement-modal${isWaterworks ? '-fortiline' : ''}`
  );

  useEffect(() => {
    getBannerFlag();
    // eslint-disable-next-line
  }, [userLogged]);

  /**
   * Render
   */
  if (loading || loadingBanner) {
    return <Loader data-testid="builderio-page-home-loading" />;
  }
  return (
    <div data-testid="builderio-page-home">
      {userLogged && contentBanner && showBanner && (
        <BuilderComponent model="page" content={contentBanner} />
      )}
      <BuilderComponent model="page" content={content} />
    </div>
  );
}

export default BuilderIOHome;
