import { createContext, useContext, useState } from 'react';

import { noop } from 'lodash-es';

import { WrapperProps } from '@reece/global-types';

/**
 * Types
 */
export type HeaderContextType = {
  searchOpen: boolean;
  setSearchOpen: (value: boolean) => void;
  searchPage: number;
  setSearchPage: (value: number) => void;
  trackedSearchTerm: string;
  setTrackedSearchTerm: (value: string) => void;
  pageIndex: number;
  setPageIndex: (value: number) => void;
  maintenance: boolean;
  setMaintenance: (value: boolean) => void;
};

/**
 * Context
 */
export const defaultHeaderContext: HeaderContextType = {
  searchOpen: false,
  setSearchOpen: noop,
  searchPage: 0,
  setSearchPage: noop,
  trackedSearchTerm: '',
  setTrackedSearchTerm: noop,
  pageIndex: 0,
  setPageIndex: noop,
  maintenance: false,
  setMaintenance: noop
};
export const HeaderContext = createContext(defaultHeaderContext);
export const useHeaderContext = () => useContext(HeaderContext);

/**
 * Provider
 */
function HeaderProvider(props: WrapperProps) {
  /**
   * State
   */
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchPage, setSearchPage] = useState(0);
  const [trackedSearchTerm, setTrackedSearchTerm] = useState('');
  const [pageIndex, setPageIndex] = useState(0);
  const [maintenance, setMaintenance] = useState(false);

  /**
   * Provider Render
   */
  return (
    <HeaderContext.Provider
      value={{
        searchOpen,
        setSearchOpen,
        searchPage,
        setSearchPage,
        trackedSearchTerm,
        setTrackedSearchTerm,
        pageIndex,
        setPageIndex,
        maintenance,
        setMaintenance
      }}
    >
      {props.children}
    </HeaderContext.Provider>
  );
}

export default HeaderProvider;
