export const baseStyles =
  '[&_.MuiChip-deleteIcon]:ml-[5px] [&_.MuiChip-deleteIcon]:mr-[-6px] [&_.MuiChip-deleteIcon]:!text-inherit';
export const baseOutlineStyle = 'shadow-[inset_0_0_0_1px_rgba(0,0,0,0)] ';
export const themedStyles = {
  fill: {
    primary: '!text-common-white !bg-primary-1-100',
    secondary: '!text-common-white !bg-primary-2-100',
    gray: '!text-common-white !bg-primary-3-100',
    green: '!text-common-white !bg-success-100',
    yellow: '!text-secondary-2-100 !bg-secondary-1-100',
    red: '!text-common-white !bg-error-100'
  },
  strokeFill: {
    primary:
      baseOutlineStyle +
      '!text-primary-1-100 !bg-primary-1-10 shadow-primary-1-100',
    secondary:
      baseOutlineStyle +
      '!text-primary-2-100 !bg-primary-2-10 shadow-primary-2-100',
    gray:
      baseOutlineStyle +
      '!text-primary-3-100 !bg-primary-3-10 shadow-primary-3-100',
    green:
      baseOutlineStyle + '!text-success-100 !bg-success-10 shadow-success-100',
    yellow:
      baseOutlineStyle +
      '!text-secondary-2-100 !bg-secondary-1-20 shadow-secondary-1-100',
    red: baseOutlineStyle + '!text-error-100 !bg-error-10 shadow-error-100'
  },
  stroke: {
    primary:
      baseOutlineStyle +
      '!text-primary-1-100 !bg-common-white shadow-primary-1-100',
    secondary:
      baseOutlineStyle +
      '!text-primary-2-100 !bg-common-white shadow-primary-2-100',
    gray:
      baseOutlineStyle +
      '!text-primary-3-100 !bg-common-white shadow-primary-3-100',
    green:
      baseOutlineStyle +
      '!text-success-100 !bg-common-white shadow-success-100',
    yellow:
      baseOutlineStyle +
      '!text-secondary-2-100 !bg-common-white shadow-secondary-1-100',
    red: baseOutlineStyle + '!text-error-100 !bg-common-white shadow-error-100'
  }
};
