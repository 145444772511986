import Button from 'components/Button';
import Card from 'components/Card';
import FeedbackButton from 'components/FeedbackButton';
import { CheckIcon, WarningIcon } from 'icons';
import { componentShowMessage } from 'pages/Components/lib/util';

/**
 * Config
 */
const display = {
  title: 'Button'
};

/**
 * Component
 */
function ButtonPage() {
  return (
    <Card className="my-8 p-6">
      <h5 className="text-2xl font-bold text-secondary-2-100">
        {display.title}
      </h5>
      <hr className="my-4" />
      <div className="my-4 ">
        <div className="flex flex-col items-center">
          <div>
            <Button size="sm" color="primary" children="test" />
            <Button size="sm" color="secondary" children="test" />
            <Button size="sm" color="gray" children="test" />
            <Button size="sm" color="lightBlue" children="test" />
            <Button size="sm" color="yellow" children="test" />
            <Button size="sm" color="green" children="test" />
            <Button size="sm" disabled children="test" />
            <Button
              size="sm"
              iconStart={<CheckIcon />}
              color="green"
              children="test"
            />
            <Button
              size="sm"
              iconEnd={<WarningIcon />}
              color="yellow"
              children="test"
            />
          </div>
          <div>
            <Button size="sm" kind="outline" color="primary" children="test" />
            <Button
              size="sm"
              kind="outline"
              color="secondary"
              children="test"
            />
            <Button size="sm" kind="outline" color="gray" children="test" />
            <Button
              size="sm"
              kind="outline"
              color="lightBlue"
              children="test"
            />
            <Button size="sm" kind="outline" color="yellow" children="test" />
            <Button size="sm" kind="outline" color="green" children="test" />
            <Button size="sm" kind="outline" children="test" disabled />
            <Button
              size="sm"
              kind="outline"
              iconStart={<CheckIcon />}
              color="green"
              children="test"
            />
            <Button
              size="sm"
              kind="outline"
              iconEnd={<WarningIcon />}
              color="yellow"
              children="test"
            />
          </div>
          <div>
            <Button size="sm" kind="text" color="primary" children="test" />
            <Button size="sm" kind="text" color="secondary" children="test" />
            <Button size="sm" kind="text" color="gray" children="test" />
            <Button size="sm" kind="text" color="lightBlue" children="test" />
            <Button size="sm" kind="text" color="yellow" children="test" />
            <Button size="sm" kind="text" color="green" children="test" />
            <Button size="sm" kind="text" children="test" disabled />
            <Button
              size="sm"
              kind="text"
              iconStart={<CheckIcon />}
              color="green"
              children="test"
            />
            <Button
              size="sm"
              kind="text"
              iconEnd={<WarningIcon />}
              color="yellow"
              children="test"
            />
          </div>
          <div>
            <Button size="md" children="test" />
            <Button size="md" kind="text" children="test" />
            <Button size="md" kind="outline" children="test" />
            <Button size="md" iconStart={<CheckIcon />} children="test" />
            <Button
              size="md"
              kind="outline"
              iconEnd={<WarningIcon />}
              children="test"
            />
          </div>
          <div>
            <Button size="lg" children="test" />
            <Button size="lg" kind="text" children="test" />
            <Button size="lg" kind="outline" children="test" />
            <Button size="lg" iconStart={<CheckIcon />} children="test" />
            <Button
              size="lg"
              kind="outline"
              iconEnd={<WarningIcon />}
              children="test"
            />
          </div>
          <div className="self-stretch">
            <Button size="sm" kind="outline" fullWidth children="test" />
            <Button
              size="md"
              iconStart={<CheckIcon />}
              iconEnd={<WarningIcon />}
              fullWidth
              children="test"
            />
            <Button size="md" color="green" fullWidth children="test" />
            <Button size="lg" color="yellow" fullWidth children="test" />
          </div>
          <div>
            <FeedbackButton
              value="test"
              valueDone="DONE"
              onClick={componentShowMessage('FeedbackButton click')}
            />
          </div>
        </div>
      </div>
    </Card>
  );
}

export default ButtonPage;
