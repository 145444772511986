import { APIOptions } from 'API/hooks/useApiBase';
import { UseAPILazyProps } from 'API/hooks/useApiLazy';
import { useApiLazyAuth } from 'API/hooks/useApiLazyAuth';
import { TealiumTrackRequest } from 'API/types/tealium.types';

/**
 * Config
 */
const BASE_URL = 'tealium';

/**
 * Hooks
 */
// 🟩 POST /tealium/trackLogin - Call BFF endpoint to track login event in tealium
export function useApiTrackEvent(options: APIOptions<unknown> = {}) {
  const props: UseAPILazyProps<void> = {
    url: '',
    kind: 'post',
    options,
    header: {}
  };
  const api = useApiLazyAuth<void>(props);
  const call = async (body: TealiumTrackRequest) =>
    await api.call({ ...props, url: `${BASE_URL}/track`, body });
  return { ...api, call };
}
