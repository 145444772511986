import { useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useApiGetProductInventoryAtBranches } from 'API/products.api';
import {
  Divisions,
  PAGE_SIZE,
  useBranchContext
} from 'providers/BranchProvider';

/**
 * Types
 */
export type UseSidebarLogicsType = ReturnType<typeof useSidebarLogics>;

/**
 * Hook
 */
export function useSidebarLogics() {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();
  /**
   * State
   */
  const [resultCount, setResultCount] = useState(PAGE_SIZE);

  /**
   * Refs
   */
  const firstElRef = useRef<HTMLDivElement>(null);
  const lastElRef = useRef<HTMLDivElement>(null);

  /**
   * Context
   */
  const {
    nearbyBranches,
    shippingBranch,
    division,
    productId,
    setBranchSelectOpen,
    setDivision,
    setProductId
  } = useBranchContext();

  /**
   * API
   */
  // 🟣  API on Mount - get product inventory at branches
  const {
    called: inventoryCalled,
    loading: inventoryLoading,
    data: productInventoryData,
    refetch: refetchInventory
  } = useApiGetProductInventoryAtBranches(productId!)({
    skip: !productId
  });

  const homeBranchLS = JSON.parse(localStorage.getItem('homeBranch') || '{}');
  /**
   * Memos
   */
  // 🔵 memo - Divisions
  const divisionOptions = useMemo(() => {
    const keys = Object.keys(Divisions) as Array<keyof typeof Divisions>;
    return keys.map((k) => ({
      label: t(`locationSearch.${Divisions[k]}`),
      value: Divisions[k] as string
    }));
  }, [t]);
  // 🔵 memo - Branch Results
  const results = useMemo(() => {
    const filteredByDivision = nearbyBranches?.filter((branch) => {
      switch (division) {
        case Divisions.WATERWORKS:
          return branch.isWaterworks;
        case Divisions.HVAC:
          return branch.isHvac;
        case Divisions.PLUMBING:
          return branch.isPlumbing;
        case Divisions.BANDK:
          return branch.isBandK;
        default:
          return true;
      }
    });
    const sorted = filteredByDivision?.sort((a, b) =>
      a.branchId === shippingBranch?.branchId
        ? -1
        : (a.distance ?? 0) - (b.distance ?? 0)
    );
    return sorted ?? [];
  }, [nearbyBranches, shippingBranch, division]);
  // 🔵 memo - Availability
  const availability = useMemo(() => {
    const orderedAvailability = new Map<string, number>();
    const inventory = productInventoryData?.inventory;
    if (!productId || !inventory) {
      return orderedAvailability;
    }

    // this sets the stock for Home branch
    const homeBranchAvailableOnHand =
      inventory.find((b) => b.branchId === homeBranchLS.branchId)
        ?.availableOnHand ?? 0;
    orderedAvailability.set(homeBranchLS.branchId, homeBranchAvailableOnHand);

    // this sets the stock for all nearby branches
    results.forEach(({ branchId }) => {
      const availableOnHand =
        inventory.find((b) => b.branchId === branchId)?.availableOnHand ?? 0;
      orderedAvailability.set(branchId, availableOnHand);
    });
    return orderedAvailability;
  }, [productInventoryData?.inventory, homeBranchLS, productId, results]);
  // 🔵 memo - filtered
  const filteredResults = useMemo(
    () =>
      results
        ?.filter((b) => b.branchId !== homeBranchLS?.branchId)
        ?.filter(({ branchId }) =>
          productId ? Boolean(availability.get(branchId)) : true
        ),
    [productId, homeBranchLS, results, availability]
  );
  // 🔵 memo - sliced
  const slicedResults = useMemo(
    () => filteredResults.slice(0, resultCount),
    [filteredResults, resultCount]
  );
  // 🔵 memo - see more
  const showSeeMore = useMemo(
    () =>
      productId
        ? resultCount < filteredResults.length
        : resultCount + 1 < results.length, // +1 for homeBranch
    [productId, filteredResults, results, resultCount]
  );

  /**
   * Callbacks
   */
  // 🟤 cb - see more
  const handleSeeMoreClicked = () => {
    setResultCount(resultCount + PAGE_SIZE);
    const option: ScrollIntoViewOptions = { block: 'end', behavior: 'smooth' };
    // applying timeout for the ref to be reassigned to the new element
    setTimeout(() => lastElRef.current?.scrollIntoView(option), 0);
  };
  // 🟤 cb - set division
  const handleApplyDivision = (division: string) =>
    setDivision(division as Divisions);
  // 🟤 cb - close
  const handleClose = () => {
    setBranchSelectOpen(false);
    setDivision(Divisions.ALL);
    setProductId(undefined);
  };

  /**
   * Output
   */
  return {
    availability,
    divisionOptions,
    firstElRef,
    handleApplyDivision,
    handleClose,
    handleSeeMoreClicked,
    lastElRef,
    inventoryCalled,
    inventoryLoading,
    refetchInventory,
    results,
    resultCount,
    showSeeMore,
    slicedResults
  };
}
