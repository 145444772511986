import { DataMap } from '@reece/global-types';
import { ContractDetails } from 'generated/graphql';
import { findProducts, validateProducts } from 'providers/libs/CartProvider';

// Gives total amount of contract when Releasing all
export function valueOfReleasingContract(
  contract?: ContractDetails,
  qtyMap?: DataMap<string>
) {
  const foundProducts = findProducts(contract, qtyMap);
  const validProducts = validateProducts(foundProducts);
  const subtotal = validProducts.reduce(
    (sum, { product, qty }) => sum + qty * (product?.netPrice ?? 0),
    0
  );
  return subtotal;
}

// Calculates Open Bal Qty  (leave value as is when Contract Qty - Released Qty is negative)
export function calculateOpenBalQty(
  contractQty: number | undefined | null,
  releasedQty: number | undefined | null
): string {
  let openBalQty = 'null';
  if (contractQty != null && releasedQty != null) {
    openBalQty = `${contractQty - releasedQty}`;
  }

  return openBalQty;
}
