import BuilderIO from 'pages/BuilderIO/Core';

/**
 * Component
 */
function BuilderIOEvents() {
  /**
   * Render
   */
  return (
    <div data-testid="builderio-page-events">
      <BuilderIO model="page-events" pathname={window.location.pathname} />
    </div>
  );
}
export default BuilderIOEvents;
