import { useTranslation } from 'react-i18next';
import { OnInputChange } from '@reece/global-types';

import { IconButton, TextInput } from 'components';
import { SearchIcon } from 'icons';

type SearchProps = {
  searchValue: string;
  setSearchValue: (value: string) => void;
};

function Search(props: SearchProps) {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();

  /**
   * Callbacks
   */
  // 🟤 Cb - handle search term change
  const handleOnChange = (e: OnInputChange) => {
    props.setSearchValue(e.currentTarget.value);
  };

  /**
   * Render
   */
  return (
    <div className="flex-1">
      <TextInput
        iconStart={
          <IconButton>
            <SearchIcon className="cursor-pointer" />
          </IconButton>
        }
        iconStartClassName=" text-primary-1-100"
        placeholder={t('common.search')}
        value={props.searchValue}
        onChange={handleOnChange}
        testId="list-search-items-input"
      />
    </div>
  );
}

export default Search;
