import { Lightbulb } from '@mui/icons-material';

import Card from 'components/Card';
import Chip, { ChipProps } from 'components/Chip';
import { componentShowMessage } from 'pages/Components/lib/util';

/**
 * Config
 */
const display = {
  title: 'Chip',
  text: 'Test',
  long: 'This is a very long chip',
  debug: 'Chip onClick test'
};

/**
 * Types
 */
type ChipDisplayProp = {
  a?: JSX.Element;
  n?: string;
  c?: ChipProps['color'];
  k?: ChipProps['kind'];
  click?: () => void;
  d?: () => void;
};

/**
 * Component
 */
function ChipPage() {
  /**
   * Render
   */
  return (
    <Card className="my-8 p-6">
      <h5 className="text-2xl font-bold text-secondary-2-100">
        {display.title}
      </h5>
      <hr className="my-4" />
      <div className="my-4 flex flex-col gap-8 justify-center">
        <div className="flex gap-8 justify-center">
          <ChipDisplay c="red" k="fill" />
          <ChipDisplay c="red" k="strokeFill" />
          <ChipDisplay c="red" k="stroke" />
        </div>
        <div className="flex gap-8 justify-center">
          <ChipDisplay c="yellow" k="fill" />
          <ChipDisplay c="yellow" k="strokeFill" />
          <ChipDisplay c="yellow" k="stroke" />
        </div>
        <div className="flex gap-8 justify-center">
          <ChipDisplay c="green" k="fill" />
          <ChipDisplay c="green" k="strokeFill" />
          <ChipDisplay c="green" k="stroke" />
        </div>
        <div className="flex gap-8 justify-center">
          <ChipDisplay c="primary" k="fill" />
          <ChipDisplay c="primary" k="strokeFill" />
          <ChipDisplay c="primary" k="stroke" />
        </div>
        <div className="flex gap-8 justify-center">
          <ChipDisplay c="secondary" k="fill" />
          <ChipDisplay c="secondary" k="strokeFill" />
          <ChipDisplay c="secondary" k="stroke" />
        </div>
        <div className="flex gap-8 justify-center">
          <ChipDisplay c="gray" k="fill" />
          <ChipDisplay c="gray" k="strokeFill" />
          <ChipDisplay c="gray" k="stroke" />
        </div>
        <div className="flex gap-8 justify-center">
          <ChipDisplay a={<Lightbulb />} />
          <ChipDisplay
            n={display.long}
            click={componentShowMessage(display.debug)}
          />
          <ChipDisplay d={componentShowMessage(display.debug)} />
        </div>
      </div>
    </Card>
  );
}

export default ChipPage;

/**
 * Sub-components
 */
function ChipDisplay(props: ChipDisplayProp) {
  return (
    <Chip
      avatar={props.a}
      onClick={props.click}
      onDelete={props.d}
      label={props.n ?? display.text}
      kind={props.k}
      color={props.c}
    />
  );
}
