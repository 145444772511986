import { forwardRef } from 'react';

import clsx from 'clsx';

/**
 * Styles
 */
const baseCardStyle =
  'm-auto w-full bg-common-white rounded-[10px] shadow-xl overflow-hidden';

/**
 * Types
 */
type CardProps = {
  className?: string;
  children?: React.ReactNode;
  testId?: string;
};

/**
 * Component
 */
const Card = forwardRef<HTMLDivElement, CardProps>((props, ref) => {
  /**
   * Props
   */
  const { className, children, testId } = props;

  /**
   * Render
   */
  return (
    <div
      className={clsx(className, baseCardStyle)}
      data-testid={testId}
      ref={ref}
    >
      {children}
    </div>
  );
});

export default Card;
