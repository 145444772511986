import { ReactNode } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Tooltip,
  Typography,
  alpha,
  useScreenSize,
  Alert,
  Skeleton
} from '@dialexa/reece-component-library';
import { OnButtonClick } from '@reece/global-types';
import { useTranslation } from 'react-i18next';

import { useAuthContext } from 'providers/AuthProvider';
import { useCartContext } from 'providers/CartProvider';
import { checkIfOver10Mil } from 'Cart/util';
import { useOrderSummary } from 'Cart/util/useOrderSummary';
import { useCheckoutContext } from 'Checkout/CheckoutProvider';
import ConditionalWrapper from 'old-components/ConditionalWrapper';
import { DeliveryMethodEnum, OrderPreviewResponse } from 'generated/graphql';
import { WarningIcon } from 'icons';
import { format } from 'utils/currency';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';
import { standardUserRoles } from 'User';
import { checkStatus } from 'utils/statusMapping';

export type OrderSummaryProps = {
  page: 'cart' | 'checkout' | 'confirmation' | 'approval';
  showTax?: boolean;
  showShippingAndHandlingAmt?: boolean;
  hideButton?: boolean;
  disableButton?: boolean;
  buttonText?: ReactNode;
  onButtonClick?: (e: OnButtonClick) => void;
  additionalCTAText?: ReactNode;
  onAdditionalCTAClick?: (e: OnButtonClick) => void;
  approveData?: OrderPreviewResponse;
  orderPreviewLoading?: boolean;
  approveOrderLoading?: { approve: boolean; reject: boolean };
};

export default function OrderSummary(props: OrderSummaryProps) {
  /**
   * Custom Hooks
   */
  const { isSmallScreen } = useScreenSize();
  const { t } = useTranslation();
  const { shippingAmount, handling, tax, orderTotal } = useOrderSummary({
    ...props
  });

  /**
   * Context
   */
  const { cart, cartLoading, subTotal, checkingOutWithQuote, contract } =
    useCartContext();
  const { orderedCart, orderData } = useCheckoutContext();
  const { profile, ecommUser, activeFeatures } = useAuthContext();
  const {
    isMincron,
    selectedAccounts: { shipToErpAccount, billToErpAccount }
  } = useSelectedAccountsContext();
  const isEmployee = profile?.isEmployee;
  const canEmployeeCheckout = Boolean(
    activeFeatures?.includes('ENABLE_EMPLOYEE_CHECKOUT')
  );
  const productCount = cart?.products?.length ?? 0;
  const isDeliveryMethod =
    cart?.deliveryMethod === DeliveryMethodEnum.Delivery ||
    checkStatus(orderData?.deliveryMethod ?? '') === 'delivery';
  const disableForStandardCashCustomer = Boolean(
    // istanbul ignore next
    standardUserRoles.includes(ecommUser?.role?.name ?? '') &&
      billToErpAccount?.alwaysCod &&
      isDeliveryMethod
  );
  const formatRequired =
    !!contract || checkingOutWithQuote || props.page === 'approval';

  /**
   * Render
   */
  return (
    <Box
      my={isSmallScreen ? 1 : undefined}
      position={isSmallScreen ? undefined : 'sticky'}
      top="0"
    >
      <Typography
        variant="h5"
        color="primary"
        py={3}
        px={isSmallScreen || props.page === 'approval' ? 0 : 3}
      >
        {t('cart.orderSummary')}
      </Typography>
      {Boolean(cart || orderedCart || (!cart && isMincron)) && (
        <Box
          p={3}
          pb={2}
          width={1}
          borderRadius="10px"
          bgcolor={(theme) => alpha(theme.palette.primary02.main, 0.05)}
          data-testid="ordersummary-component"
        >
          {props.orderPreviewLoading ? (
            <Box data-testid="ordersummary-skeleton">
              <Typography variant="body1">
                <Skeleton width={150} />
              </Typography>
              <Typography variant="body1">
                <Skeleton width={200} />
              </Typography>
              <Typography variant="body1">
                <Skeleton width={200} />
              </Typography>
              <Box py={2}>
                <Divider />
              </Box>
              <Typography variant="body1">
                <Skeleton width={100} />
              </Typography>
            </Box>
          ) : (
            <>
              <Box
                pb={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography color="primary" variant="body1" fontWeight={500}>
                  {t('cart.subtotal')}
                </Typography>
                <Typography
                  color="primary"
                  variant="body1"
                  data-testid="cart-subtotal"
                >
                  {props.approveData?.subTotal
                    ? formatRequired
                      ? format(parseFloat(props.approveData.subTotal))
                      : props.approveData.subTotal
                    : subTotal}
                </Typography>
              </Box>
              {props.showShippingAndHandlingAmt && isDeliveryMethod ? (
                <>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography color="primary" variant="caption">
                      {t('common.shipping')}
                    </Typography>
                    <Typography
                      color="primary"
                      variant="caption"
                      data-testid="shipping-amount"
                    >
                      {props.approveData?.shippingAmount
                        ? formatRequired
                          ? format(parseFloat(props.approveData.shippingAmount))
                          : props.approveData.shippingAmount
                        : shippingAmount}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography color="primary" variant="caption">
                      {t('common.handling')}
                    </Typography>
                    <Typography
                      color="primary"
                      variant="caption"
                      data-testid="handling-amount"
                    >
                      {props.approveData?.handling
                        ? formatRequired
                          ? format(parseFloat(props.approveData.handling))
                          : props.approveData.handling
                        : handling}
                    </Typography>
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography color="primary" variant="caption">
                      {t('cart.shippingHandling')}
                    </Typography>
                    <Typography
                      color="primary"
                      variant="caption"
                      data-testid="cart-shipping-handling"
                    >
                      {isDeliveryMethod || (!cart && isMincron)
                        ? props.page !== 'checkout'
                          ? t('cart.doubleDash')
                          : t('cart.shippingCalculatedNextStep')
                        : t('cart.free')}
                    </Typography>
                  </Box>
                  <Typography pb={1} color="primary" variant="caption">
                    {isDeliveryMethod &&
                      props.page !== 'checkout' &&
                      props.page !== 'confirmation' &&
                      t('cart.shippingDeterminedLater')}
                  </Typography>
                </>
              )}
              {props.showTax && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography color="primary" variant="caption">
                    {t('common.tax')}
                  </Typography>
                  <Typography
                    color="primary"
                    variant="caption"
                    data-testid="tax-amount"
                  >
                    {props.approveData?.tax
                      ? formatRequired
                        ? format(parseFloat(props.approveData.tax))
                        : props.approveData.tax
                      : tax}
                  </Typography>
                </Box>
              )}
              <Box component={Divider} mx={-1} my={2} />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography color="primary" variant="body1" fontWeight={500}>
                  {!props.showTax
                    ? t('cart.totalBeforeTax')
                    : t('common.total')}
                </Typography>
                <Typography
                  color="primary"
                  variant="body1"
                  data-testid="cart-total-before-tax"
                >
                  {props.approveData?.orderTotal
                    ? formatRequired
                      ? format(parseFloat(props.approveData.orderTotal))
                      : props.approveData.orderTotal
                    : orderTotal}
                </Typography>
              </Box>
            </>
          )}
          {!props.hideButton && (
            <Box pt={3} pb={2}>
              <ConditionalWrapper
                condition={Boolean(isEmployee) && !canEmployeeCheckout}
                wrapper={employeeTooltip}
              >
                <Button
                  style={{ width: '100%' }}
                  variant="primary"
                  data-testid="checkout-top-button"
                  disabled={
                    (isEmployee && !canEmployeeCheckout) ||
                    ((!productCount || cartLoading || !cart?.deliveryMethod) &&
                      props.page !== 'confirmation' &&
                      props.page !== 'approval' &&
                      !checkingOutWithQuote) ||
                    props.disableButton ||
                    shipToErpAccount?.creditHold ||
                    props.approveOrderLoading?.approve ||
                    checkIfOver10Mil(subTotal ?? '') ||
                    disableForStandardCashCustomer
                  }
                  onClick={props.onButtonClick}
                >
                  {cartLoading || props.approveOrderLoading?.approve ? (
                    <CircularProgress color="primary02.main" size={24} />
                  ) : (
                    props.buttonText
                  )}
                </Button>
              </ConditionalWrapper>
              {shipToErpAccount?.creditHold ? (
                <Alert
                  icon={<WarningIcon />}
                  sx={(theme) => ({
                    color: 'text.secondary',
                    bgcolor: 'inherit',
                    ...theme.typography.caption,
                    alignItems: 'center',
                    '& .MuiAlert-icon': {
                      color: 'secondary.main'
                    },
                    padding: 0
                  })}
                >
                  {t('common.accountHold')}
                </Alert>
              ) : null}
            </Box>
          )}
          {!!(props.additionalCTAText && props.onAdditionalCTAClick) && (
            <Button
              variant="text"
              color="primaryLight"
              fullWidth
              data-testid="additional-cta-button"
              onClick={props.onAdditionalCTAClick}
            >
              {cartLoading || props.approveOrderLoading?.reject ? (
                <CircularProgress color="primary02.main" size={24} />
              ) : (
                props.additionalCTAText
              )}
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
}

// TODO: Remove this and revert back to using EmployeeTooltip component when standard access cash customers can checkout delivery with credit card
// Temporary solution to adding variable tooltips for the disabled button
// const CustomTooltip = (
//   title: string = 'Checkout is currently disabled for employees.'
// ) => {
//   return (children: JSX.Element) => (
//     <Tooltip title={title} placement="top">
//       <span>{children}</span>
//     </Tooltip>
//   );
// };

export const employeeTooltip = (children: JSX.Element) => (
  <Tooltip
    title="Checkout is currently disabled for employees."
    placement="top"
  >
    <span>{children}</span>
  </Tooltip>
);
