import { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import {
  Button,
  CircularProgress,
  FormRadioInput,
  FormRadioOption
} from 'components';
import {
  BranchListItemFragment,
  useUpdateBranchMutation
} from 'generated/graphql';
import { useSnackbar } from 'providers/SnackbarProvider';

/**
 * Config
 */
const RADIO_OPTIONS: FormRadioOption[] = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' }
];

/**
 * Types
 */
type EditModalProps = {
  open: boolean;
  onClose: () => void;
  selectedBranch?: BranchListItemFragment;
  testId: string;
};

/**
 * Component
 */
function EditModal(props: EditModalProps) {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();
  const { pushAlert } = useSnackbar();

  /**
   * Data
   */
  const [updateBranch, { loading }] = useUpdateBranchMutation();

  /**
   * Form
   */
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, isDirty }
  } = useForm<BranchListItemFragment>({
    resolver: yupResolver(
      yup.object({
        isActive: yup.boolean().required(),
        isAvailableInStoreFinder: yup.boolean().required(),
        isPricingOnly: yup.boolean().required(),
        isShoppable: yup.boolean().required()
      })
    )
  });

  /**
   * Callbacks
   */
  const handleSubmitCallback = (data: BranchListItemFragment) => {
    updateBranch({
      variables: {
        input: {
          id: props.selectedBranch?.id ?? '',
          isActive: data.isActive,
          isAvailableInStoreFinder: data.isAvailableInStoreFinder,
          isPricingOnly: data.isPricingOnly,
          isShoppable: data.isShoppable
        }
      }
    })
      .then(() => {
        props.onClose();
        pushAlert(`${props.selectedBranch?.branchId} updated`, {
          variant: 'success'
        });
      })
      .catch(() => {
        props.onClose();
        pushAlert(t('branchManagement.somethingWentWrong'), {
          variant: 'error'
        });
      });
  };

  /**
   * Effects
   */
  // 🟡 effect - Sets default radio buttons based on selected branch
  useEffect(() => {
    if (props.selectedBranch) {
      reset({
        isActive: props.selectedBranch.isActive,
        isAvailableInStoreFinder: props.selectedBranch.isAvailableInStoreFinder,
        isShoppable: props.selectedBranch.isShoppable,
        isPricingOnly: props.selectedBranch.isPricingOnly
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedBranch]);

  /**
   * Render
   */
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth
      data-testid={props.testId}
    >
      <form onSubmit={handleSubmit(handleSubmitCallback)}>
        <DialogTitle>
          {`Update Branch ${props.selectedBranch?.branchId}: ${props.selectedBranch?.name}`}
        </DialogTitle>

        <DialogContent>
          <InputLabel>{t('branchManagement.isActive')}</InputLabel>
          <FormRadioInput
            control={control}
            name="isActive"
            options={RADIO_OPTIONS}
            disabled={loading}
            testId="editmodal-isactive"
          />
          <div className="space-y-5">
            <InputLabel>{t('branchManagement.isAvailable')}</InputLabel>
            <FormRadioInput
              control={control}
              name="isAvailableInStoreFinder"
              options={RADIO_OPTIONS}
              disabled={loading}
            />
          </div>
          <div className="space-y-5">
            <InputLabel>{t('branchManagement.isShoppable')}</InputLabel>
            <FormRadioInput
              control={control}
              name="isShoppable"
              options={RADIO_OPTIONS}
              disabled={loading}
            />
          </div>
          <div className="space-y-5">
            <InputLabel>{t('branchManagement.isPricingOnly')}</InputLabel>
            <FormRadioInput
              control={control}
              name="isPricingOnly"
              options={RADIO_OPTIONS}
              disabled={loading}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            kind="text"
            onClick={() => reset(props.selectedBranch)}
            disabled={!isDirty}
            data-testid="editmodal-resetbutton"
          >
            Reset
          </Button>
          <Button
            type="submit"
            disabled={!isValid || !isDirty || loading}
            className="w-[100px]"
            data-testid={'editmodal-submitbutton'}
          >
            {loading ? (
              <CircularProgress
                size={20}
                color="gray"
                testId="editmodal-circularprogress"
              />
            ) : (
              t('common.submit')
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default EditModal;
