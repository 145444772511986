import { useMemo } from 'react';

import { TableCell, TableRow } from '@mui/material';
import clsx from 'clsx';
import { Row } from 'react-table';

import { ungroupedTableCellStyle } from 'common/TablePageLayout/lib/styles';
import { uncommonId } from 'common/TablePageLayout/lib/uncommonId';
import { TableRendererProps } from 'common/TablePageLayout/TableRenderer';
import { TableMinusIcon, TablePlusIcon } from 'icons';

/**
 * Types
 */
export type TableBodyRowProp<TData extends object> = {
  row: Row<TData>;
  rowIndex: number;
} & TableRendererProps<TData>;

/**
 * Component
 */
function TableRendererBodyRow<TData extends object>(
  props: TableBodyRowProp<TData>
) {
  /**
   * Props
   */
  const {
    hasGroups,
    onRowClick,
    primaryKey,
    row: {
      cells,
      getRowProps,
      getToggleRowExpandedProps,
      isExpanded,
      isGrouped,
      original,
      subRows,
      values
    },
    rowIndex
  } = props;

  /**
   * Memo
   */
  const renderedTableCells = useMemo(
    () =>
      cells.map((cell, i) => (
        <TableCell
          {...cell.getCellProps()}
          align={cell.column.align}
          className={clsx({
            [ungroupedTableCellStyle]: !isGrouped && !!hasGroups,
            none: !!isExpanded && !!hasGroups,
            'cursor-pointer': Boolean(onRowClick)
          })}
          data-testid={`${uncommonId(cell.column.id)}-${rowIndex}`}
          key={`${rowIndex}-cell-${i}`}
        >
          {cell.isGrouped
            ? subRows.length > 1 && (
                <span {...getToggleRowExpandedProps()} className="pt-3">
                  {isExpanded ? (
                    <TableMinusIcon
                      className="text-primary-2-100 w-4 h-4"
                      data-testid={`${uncommonId(
                        cell.column.id
                      )}-${rowIndex}-minus`}
                    />
                  ) : (
                    <TablePlusIcon
                      className="text-primary-2-100 w-4 h-4"
                      data-testid={`${uncommonId(
                        cell.column.id
                      )}-${rowIndex}-plus`}
                    />
                  )}
                </span>
              )
            : cell.render(cell.isAggregated ? 'Aggregated' : 'Cell')}
        </TableCell>
      )),
    [
      cells,
      getToggleRowExpandedProps,
      hasGroups,
      isExpanded,
      isGrouped,
      onRowClick,
      rowIndex,
      subRows.length
    ]
  );

  /**
   * Render
   */
  return (
    <TableRow
      {...getRowProps()}
      onClick={() => onRowClick && onRowClick(original)}
      hover
      data-testid={`row_${values[primaryKey]}`}
    >
      {renderedTableCells}
    </TableRow>
  );
}

export default TableRendererBodyRow;
