import { CardHeader, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAccountPageContext } from 'pages/Account/AccountPageProvider';
import UserInfoText from 'pages/Account/sub/UserInfoText';
import { Card } from 'components';

/**
 * Component
 */
function ViewCompanyInfo() {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();

  /**
   * Context
   */
  const { companyInfo } = useAccountPageContext();

  /**
   * Render
   */
  return (
    <Card
      className="md:rounded-none md:shadow-none"
      testId="viewcompany-wrapper"
    >
      <div className="px-2 py-3">
        <CardHeader
          title={t('common.companyInformation')}
          titleTypographyProps={{
            className: '!text-2xl font-medium md:!text-xl'
          }}
          data-testid="company-information-title"
        />
      </div>
      <Divider />
      <UserInfoText
        fieldName={`${t('common.companyName')}:`}
        fieldValue={companyInfo.companyName!}
        testId="company-name-field"
      />
      <div className="px-4">
        <Divider />
      </div>
      <UserInfoText
        fieldName={`${t('common.streetAddress')}:`}
        fieldValue={`${companyInfo.street1} ${companyInfo.street2}${
          companyInfo.street2 ? ' ' : ''
        }${companyInfo.city}, ${companyInfo.state} ${companyInfo.zip}`}
        testId="company-address-field"
      />
    </Card>
  );
}

export default ViewCompanyInfo;
