import { useTranslation } from 'react-i18next';

import { Image } from 'components';
import { useProductPageContext } from 'pages/Product/ProductPageProvider';

/**
 * Component
 */
function ProductImage() {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();

  /**
   * Hooks
   */
  const { product } = useProductPageContext();

  /**
   * Render
   */
  return (
    <Image
      alt={product?.name ?? t('common.productPicture')}
      src={product?.imageUrls?.medium ?? ''}
      className="p-4"
      data-testid="pdp-image"
    />
  );
}
export default ProductImage;
