import { useCallback, useState, useRef } from 'react';

import {
  Box,
  Button,
  Grid,
  TextField,
  MenuItem,
  Select
} from '@dialexa/reece-component-library';
import { useFormik } from 'formik';
import { omit } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import AddresAutocomplete from 'Checkout/AddressAutocomplete';
import AddressConfirmModal from 'Checkout/AddressConfirmModal';
import { CustomAddressInput } from 'Checkout/util/types';
import { stateAbbreviations } from 'utils/states';
import updateZipcode from 'utils/updateZipCode';
import { useCheckoutContext } from 'Checkout/CheckoutProvider';
import { Delivery } from 'generated/graphql';
import { initialAddressValues } from 'Checkout/util';
import { MaskedInput } from 'components';
import useAutocompleteLogic from 'Checkout/lib/useAutocompleteLogic';

type Props = {
  onConfirm: (customAddress: CustomAddressInput) => void;
  onCancel: () => void;
};

export default function CustomAddress(props: Props) {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  /**
   * State
   */
  const [autoCompleteInput, setAutoCompleteInput] = useState('');
  const [confimModal, setConfirmModal] = useState(false);
  const [userValidated, setUserValidated] = useState(false);

  /**
   * Context
   */
  const { deliveryMethodObject, isDeliveryMethod } = useCheckoutContext();
  const delivery = isDeliveryMethod
    ? (deliveryMethodObject as Delivery)
    : undefined;
  const customAddress = delivery
    ? ({
        ...omit(delivery.address, ['__typename', 'id']),
        phoneNumber: delivery.phoneNumber
      } as CustomAddressInput)
    : undefined;

  const initialValues =
    delivery && delivery.address?.custom
      ? customAddress!
      : initialAddressValues;

  /**
   * Form
   */
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      if (userValidated) {
        setConfirmModal(true);
      } else {
        props.onConfirm(values);
      }
    },
    validationSchema: Yup.object({
      street1: Yup.string().required(t('validation.addressRequired')),
      city: Yup.string().required(t('validation.cityRequired')),
      state: Yup.string().required(t('validation.stateRequired')),
      zip: Yup.string().required(t('validation.zipRequired'))
    })
  });

  const { placesService, placePredictions, getPlacePredictions } =
    useAutocompleteLogic(autoCompleteInput);

  /**
   * Callbacks
   */
  // CB - find address component by type and return long or short name
  const findAddressComponent = useCallback(
    (
      address: google.maps.GeocoderAddressComponent[] = [],
      type: string,
      longNames?: boolean
    ) =>
      address.find((component) => component.types.includes(type))?.[
        longNames ? 'long_name' : 'short_name'
      ],
    []
  );

  // CB - process details into CustomAddressInput type and set form
  const onSelect = useCallback(
    (prediction?: google.maps.places.AutocompletePrediction) => {
      setAutoCompleteInput('');

      if (!prediction) {
        setUserValidated(true);
        return;
      }

      setUserValidated(false);

      placesService?.getDetails(
        {
          placeId: prediction.place_id
        },
        (placeDetails) => {
          if (!placeDetails) {
            return;
          }
          const street1 = `${
            findAddressComponent(
              placeDetails.address_components,
              'street_number'
            ) ?? ''
          } ${
            findAddressComponent(
              placeDetails.address_components,
              'route',
              true
            ) ?? ''
          }`;

          const city = `${
            findAddressComponent(placeDetails.address_components, 'locality') ??
            ''
          }`;
          const state = `${
            findAddressComponent(
              placeDetails.address_components,
              'administrative_area_level_1'
            ) ?? ''
          }`;
          const zip = `${
            findAddressComponent(
              placeDetails.address_components,
              'postal_code'
            ) ?? ''
          }`;

          // set form values
          formik.setFieldValue('street1', street1);
          formik.setFieldValue('city', city);
          formik.setFieldValue('state', state);
          formik.setFieldValue('zip', zip);
        }
      );
    },
    [findAddressComponent, formik, placesService]
  );

  // CB - handle choose address via keyboard
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      placePredictions.length && onSelect(placePredictions[0]);
      !placePredictions.length && onSelect();
    }
    if (e.key === 'ArrowDown' && placePredictions.length) {
      inputRef.current?.blur();
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit} noValidate>
        <Grid container spacing={2}>
          <div className="relative flex-1 pl-4 py-4">
            <Grid item xs={12}>
              <TextField
                id="company-name"
                name="companyName"
                label={t('common.companyCO')}
                placeholder={t('common.enterCompanyName')}
                value={formik.values.companyName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                inputProps={{
                  'data-testid': 'custom-address-company-name-input'
                }}
                helperText=" "
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                inputRef={inputRef}
                id="street1"
                name="street1"
                label={t('common.address')}
                placeholder={t('common.enterAddress')}
                value={formik.values.street1}
                onChange={(evt) => {
                  getPlacePredictions({ input: evt.target.value });
                  formik.handleChange(evt);
                  setAutoCompleteInput(evt.target.value);
                }}
                onBlur={formik.handleBlur}
                error={Boolean(formik.touched.street1 && formik.errors.street1)}
                helperText={
                  formik.touched.address && formik.errors.address
                    ? formik.errors.address
                    : ' '
                }
                inputProps={{
                  'data-testid': 'custom-address-address-input'
                }}
                onKeyPress={handleKeyPress}
                fullWidth
                required
                className="!pb-0"
                autoComplete="off"
              />
              <AddresAutocomplete
                input={autoCompleteInput}
                predictions={placePredictions}
                onSelect={onSelect}
              />
            </Grid>
          </div>
          <Grid item xs={12}>
            <TextField
              id="street2"
              name="street2"
              label={t('validation.address2')}
              placeholder={t('validation.address2Placeholder')}
              value={formik.values.street2}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              inputProps={{
                'data-testid': 'custom-address-street2-input'
              }}
              helperText=" "
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="city"
              name="city"
              label={t('common.city')}
              placeholder={t('common.enterCity')}
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(formik.touched.city && formik.errors.city)}
              helperText={
                formik.touched.city && formik.errors.city
                  ? formik.errors.city
                  : ' '
              }
              inputProps={{ 'data-testid': 'custom-address-city-input' }}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              id="state"
              name="state"
              label={t('common.state')}
              placeholder={t('common.selectState')}
              value={formik.values.state}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(formik.touched.state && formik.errors.state)}
              helperText={
                formik.touched.state && formik.errors.state
                  ? formik.errors.state
                  : ' '
              }
              inputProps={{ 'data-testid': 'custom-address-state-input' }}
              fullWidth
              required
            >
              {stateAbbreviations.map((state) => (
                <MenuItem key={state} value={state}>
                  {state}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="zip-code"
              name="zip"
              label={t('common.zip')}
              placeholder={t('common.enterZip')}
              value={formik.values.zip}
              onChange={updateZipcode(formik.handleChange)}
              onBlur={formik.handleBlur}
              error={Boolean(formik.touched.zip && formik.errors.zip)}
              helperText={
                formik.touched.zip && formik.errors.zip
                  ? formik.errors.zip
                  : ' '
              }
              inputProps={{ 'data-testid': 'custom-address-zip-input' }}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MaskedInput
              id="phone-number"
              name="phoneNumber"
              label={t('common.phoneNumber')}
              placeholder={t('common.enterPhone')}
              value={formik.values.phoneNumber ?? ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              mask="phone"
              testId="custom-address-phone-number-input"
            />
          </Grid>
        </Grid>
        <Box display="flex" alignItems="center" mt={2}>
          <Button type="submit" data-testid="confirm-address-button">
            {t('common.confirmAddress')}
          </Button>
          <Button
            type="button"
            variant="text"
            onClick={props.onCancel}
            sx={{ ml: 2 }}
            data-testid="cancel-address-button"
          >
            {t('common.cancel')}
          </Button>
        </Box>
      </form>
      <AddressConfirmModal
        open={confimModal}
        address={formik.values}
        onConfirm={(address) => {
          props.onConfirm(address);
          setConfirmModal(false);
        }}
        onCancel={() => setConfirmModal(false)}
      />
    </>
  );
}
