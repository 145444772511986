import { AccordionDetails, AccordionSummary } from '@mui/material';
import { kebabCase } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { Accordion, ConditionalWrapper } from 'components';
import useScreenSize from 'hooks/useScreenSize';
import { ArrowDropDownIcon } from 'icons';
import { useProductPageContext } from 'pages/Product/ProductPageProvider';

/**
 * Component
 */

function FeaturesAndBenefits() {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();
  const { isSmallScreen } = useScreenSize();

  /**
   * Context
   */
  const { product } = useProductPageContext();
  const features = product?.featuresAndBenefits?.split(';');

  /**
   * Render
   */
  if (!features) {
    return null;
  }
  return (
    <ConditionalWrapper
      condition={isSmallScreen}
      wrapper={(children) => (
        <Accordion type="product" className="text-primary-3-100">
          <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
            {t('product.featuresBenefits')}
          </AccordionSummary>
          <AccordionDetails sx={{ py: 2, px: 0 }}>{children}</AccordionDetails>
        </Accordion>
      )}
    >
      <ul className="list-disc ps-5" data-testid="pdp-feature-and-benefits">
        {features.map((feature, index) => (
          <p
            className="list-item max-w-[calc(100%*2/3)] my-1 text-base font-normal"
            data-testid={`feature-item-${kebabCase(feature)}`}
            key={kebabCase(`${feature}-${index}`)}
          >
            {feature}
          </p>
        ))}
      </ul>
    </ConditionalWrapper>
  );
}

export default FeaturesAndBenefits;
