import { StepLabelProps } from '@mui/material';

import StepIcon from 'components/StepIcon';

const MuiStepLabelProps: Partial<StepLabelProps> = {
  StepIconComponent: StepIcon,
  children: ' '
};

export default MuiStepLabelProps;
