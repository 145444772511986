import { forwardRef, FocusEventHandler, ReactElement } from 'react';

import clsx from 'clsx';

import { Modify } from '@reece/global-types';
import {
  inputDisabled,
  inputError,
  inputStyle,
  messageError
} from 'components/MaskedInput/styles';
import { dateMask, phoneMask, zipcodeMask } from 'utils/masks';
import Label from 'components/Label';
import BaseMaskedInput, {
  IMaskInputProps
} from 'components/MaskedInput/BaseMaskedInput';

/**
 * Config
 */
const maskMap = { date: dateMask, phone: phoneMask, zipcode: zipcodeMask };

/**
 * Types
 */
export type BaseMaskedInputProps = {
  label?: string;
  testId?: string;
  message?: string;
  messageClassName?: string;
  error?: boolean;
  value?: string;
  mask: keyof typeof maskMap;
  required?: boolean;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  id?: string;
  name?: string;
  maxLength?: number;
  pattern?: string;
  onChange?: (event: { target: { name: string; value: string } }) => void;
  onFocus?: FocusEventHandler<ReactElement>;
};
export type MaskedInputProps = Modify<IMaskInputProps, BaseMaskedInputProps>;

/**
 * Component
 */
const MaskedInput = forwardRef<HTMLInputElement, MaskedInputProps>(
  (props, ref) => {
    /**
     * Props
     */
    const {
      label,
      testId,
      message,
      error,
      value,
      mask,
      messageClassName,
      required,
      placeholder,
      className,
      disabled,
      id,
      name,
      maxLength,
      pattern,
      onChange,
      onFocus,
      ...rest
    } = props;

    /**
     * Render
     */
    return (
      <div className="text-secondary-2-100 text-base w-full">
        {/* Label */}
        <Label
          label={label}
          required={required}
          htmlFor={id ?? name}
          testId={testId}
        />
        {/* Input */}
        <BaseMaskedInput
          className={clsx(className, inputStyle, {
            [inputError]: error,
            [inputDisabled]: disabled
          })}
          // Due to leaky abstration of IMaskInput, type ignore has to be implemented
          // @ts-ignore
          inputRef={ref}
          // @ts-ignore
          {...maskMap[mask]}
          id={id ?? name}
          data-testid={testId}
          name={name}
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          maxLength={maxLength}
          pattern={pattern}
          onAccept={(v) =>
            onChange?.({
              target: { name: props.name ?? '', value: v as string }
            })
          }
          overwrite
          {...rest}
        />
        {/* Message */}
        {Boolean(message || maxLength) && (
          <div className="flex justify-between pt-1 px-4 pb-0 text-xs">
            {/* Message text */}
            {Boolean(message) && (
              <span
                className={clsx(messageClassName, { [messageError]: error })}
                data-testid={`${testId}-message`}
              >
                {message}
              </span>
            )}
            {/* Text length count */}
            {Boolean(maxLength) && (
              <span data-testid={`${testId}-count`}>
                {value?.length}/{maxLength!}
              </span>
            )}
          </div>
        )}
      </div>
    );
  }
);

export default MaskedInput;
