import { AccordionDetails, AccordionSummary } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { kebabCase } from 'lodash-es';

import { Accordion, ConditionalWrapper } from 'components';
import useScreenSize from 'hooks/useScreenSize';
import { ArrowDropDownIcon } from 'icons';
import { useProductPageContext } from 'pages/Product/ProductPageProvider';

/**
 * Component
 */
function ProductOverview() {
  /**
   * Custom Hooks
   */
  const { isSmallScreen } = useScreenSize();
  const { t } = useTranslation();

  /**
   * Context
   */
  const { product } = useProductPageContext();
  const overview = product?.productOverview?.split(';');

  /**
   * Render
   */
  if (!overview?.length) {
    return null;
  }
  return (
    <ConditionalWrapper
      condition={isSmallScreen}
      wrapper={(children) => (
        <Accordion type="product" className="text-primary-3-100">
          <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
            {t('product.featuresBenefits')}
          </AccordionSummary>
          <AccordionDetails sx={{ py: 2, px: 0 }}>{children}</AccordionDetails>
        </Accordion>
      )}
    >
      <ul className="list-disc ps-5" data-testid="pdp-overview">
        {overview.map((item, index) => (
          <p
            className="list-item max-w-[calc(100%*2/3)] my-1 text-base font-normal"
            key={kebabCase(`${item}-${index}`)}
          >
            {item}
          </p>
        ))}
      </ul>
    </ConditionalWrapper>
  );
}

export default ProductOverview;
