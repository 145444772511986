import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import 'react-app-polyfill/ie11';

import { ApolloProvider } from '@apollo/client';
import builder from '@builder.io/react';
import { ResizeObserver } from '@juggle/resize-observer';
import { BrowserRouter as Router } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';

import 'dotenv/config';
import 'i18n';
import 'index.css';

import App from 'App';
import client from 'client';
import ErrorBoundary from 'common/ErrorBoundary';
import ThemeProvider from 'providers/ThemeProvider';
import { load } from 'utils/analytics';
import { Configuration } from 'utils/configuration';

// Delete this when refactor is done, we will be phasing out '@dialexa/reece-component-library'
import { ThemeProvider as OldThemeProvider } from '@dialexa/reece-component-library';

/**
 * Function used only when embedding a screen into the Max Mobile app.
 */

smoothscroll.polyfill();

if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver;
}

// Initialize BuilderIO
const builderApiKey = Configuration.builderApiKey || '';
builder.init(builderApiKey);

load();

ReactDOM.render(
  <ApolloProvider client={client}>
    <ThemeProvider>
      <OldThemeProvider>
        <Router>
          <ErrorBoundary>
            <Suspense fallback={null}>
              <App />
            </Suspense>
          </ErrorBoundary>
        </Router>
      </OldThemeProvider>
    </ThemeProvider>
  </ApolloProvider>,
  document.getElementById('root')
);
