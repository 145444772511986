export const styles = {
  default:
    '!text-secondary-2-100 !bg-secondary-3-30 !border-secondary-2-100 [&_.MuiAlert-icon]:!text-secondary-2-100',
  error:
    '!text-support-1-100 !bg-error-10 !border-support-1-100 [&_.MuiAlert-icon]:!text-support-1-100',
  info: '!text-info-100 !bg-info-10 !border-info-100 [&_.MuiAlert-icon]:!text-info-100',
  success:
    '!text-success-100 !bg-success-10 !border-success-100 [&_.MuiAlert-icon]:!text-success-100',
  warning:
    '!text-support-1-50 !bg-warning-20 !border-support-1-50  [&_.MuiAlert-icon]:!text-support-1-50'
};
