import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import Breadcrumbs from 'common/Breadcrumbs';
import useScreenSize from 'hooks/useScreenSize';
import { useListsPageContext } from 'pages/Lists/provider/ListsPageProvider';

/**
 * Types
 */
type HeaderProps = {
  pageTitle: string;
  children?: React.ReactNode;
};

/**
 * Component
 */
export default function Header(props: HeaderProps) {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();
  const { isSmallScreen } = useScreenSize();

  /**
   * Context
   */
  const { lists } = useListsPageContext();

  /**
   *  Render
   */
  return (
    <div className="container lg flex flex-col gap-y-4 justify-between !mb-4 md:gap-y-2 md:!mb-8 !mt-[-1rem] h-[97px] md:!h-fit md:!px-2">
      <Breadcrumbs
        className="!pt-[31px] !pb-0 md:!pb-4"
        pageTitle={props.pageTitle}
        data-testid={`${props.pageTitle.toLowerCase()}-breadcrumbs`}
        newMobileStyle
      />
      <div className="container lg flex !my-0 justify-between md:flex-col md:items-start md:!px-0 flex-row !max-h-[30px] md:!max-h-[94px] items-end !pr-0 md:gap-y-6">
        <h1
          className={clsx(
            'text-primary-1-100 text-[25px] leading-[30px] pt-0 font-bold lg',
            {
              'md:text-primary-3-100': props.pageTitle !== t('common.orders')
            }
          )}
          data-testid={`${props.pageTitle.toLowerCase()}-breadcrumbs-text`}
        >
          {props.pageTitle === 'Lists' && isSmallScreen
            ? t('common.listsCount', { count: lists.length })
            : props.pageTitle}
        </h1>
        {props.children}
      </div>
    </div>
  );
}
