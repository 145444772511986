import { FooterItem } from 'common/Footer/lib/useFooterData';
import { Link } from 'components';
import { kebabCase } from 'lodash-es';
import { useHistory } from 'react-router-dom';

/**
 * Config
 */
const externalRegExp = /^(http|https):\/\//;
const telRegExp = /^(tel):/;

/**
 * Types
 */
export type FooterLinksProps = {
  item: FooterItem;
};

/**
 * Component
 */
function FooterLink(props: FooterLinksProps) {
  /**
   * Props
   */
  const isExternal =
    externalRegExp.test(props.item.to) || props.item.externalUrl;
  const isTel = telRegExp.test(props.item.to);
  const testId = `footer-link-${kebabCase(props.item.name)}`;

  /**
   * Custom Hooks
   */
  const history = useHistory();

  /**
   * Callbacks
   */
  const goToLocalUrl = () => {
    history.push(props.item.to);
    window.scrollTo({ top: 0 });
  };

  /**
   * Render
   */
  // external/phone url
  if (isExternal || isTel) {
    return (
      <a
        href={props.item.to}
        target={isExternal ? '_blank' : undefined}
        rel="noreferrer"
        className="text-primary-3-100 select-none"
        data-testid={testId}
      >
        {props.item.name}
      </a>
    );
  }
  // local url
  return (
    <Link
      to="#"
      onClick={goToLocalUrl}
      className="!text-primary-3-100"
      testId={testId}
    >
      {props.item.name}
    </Link>
  );
}

export default FooterLink;
