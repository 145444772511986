import { Container, Dialog, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAccountPageContext } from 'pages/Account/AccountPageProvider';
import { Button } from 'components';

/**
 * Types
 */
type Props = {
  open: boolean;
  onClose: (save: boolean) => void;
  email: string;
};

/**
 * Component
 */
function ConfirmEmailChangeDialog(props: Props) {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();

  /**
   * Context
   */
  const { userInfo } = useAccountPageContext();

  /**
   * Callbacks
   */
  // 🟤 Cb - Save
  const handleSave = () => props.onClose(true);
  // 🟤 Cb - Cancel
  const handleCancel = () => props.onClose(false);

  /**
   * Render
   */
  return (
    <Dialog open={props.open} onClose={handleCancel} maxWidth="sm" fullWidth>
      <DialogContent>
        <Container>
          <div className="pb-6 text-primary-1-100 text-center">
            <h4 className="pb-2 text-2xl font-medium">
              {t('user.changeEmailConfirmation')}
            </h4>
            <p className="text-base">
              {t('user.changeEmailExplanation', {
                oldEmail: userInfo.email,
                newEmail: props.email
              })}
            </p>
          </div>
          <div className="flex flex-row gap-4 justify-center md:flex-col">
            <div>
              <Button
                onClick={handleCancel}
                kind="outline"
                fullWidth
                data-testid="confirmemail-cancel-button"
              >
                {t('common.cancel')}
              </Button>
            </div>
            <div>
              <Button
                onClick={handleSave}
                fullWidth
                data-testid="confirmemail-yes-button"
              >
                {t('common.yesImSure')}
              </Button>
            </div>
          </div>
        </Container>
      </DialogContent>
    </Dialog>
  );
}

export default ConfirmEmailChangeDialog;
