import { BuilderComponent } from '@builder.io/react';

import { Loader } from 'components';
import useBuilderIOAPI from 'pages/BuilderIO/lib/useBuilderIOAPI';
import injectTestId from 'utils/injectTestId';

/**
 * Types
 */
export type BuilderIOOptionsProps = {
  modelType: string;
  data?: unknown;
  skip?: boolean;
  testId?: string;
};

/**
 * Component
 */
function BuilderIOCoreOptions(props: BuilderIOOptionsProps) {
  /**
   * Context
   */
  const { content, loading } = useBuilderIOAPI(
    'page',
    window.location.pathname,
    props.skip
  );

  /**
   * Render
   */
  if (loading || props.skip) {
    return <Loader data-testid={injectTestId('loading', props.testId, true)} />;
  }
  if (props.data) {
    return (
      <div data-testid={injectTestId('data', props.testId, true)}>
        <BuilderComponent
          model={props.modelType}
          content={content}
          data={props.data}
        />
      </div>
    );
  }
  return (
    <div data-testid={injectTestId('no-data', props.testId, true)}>
      <BuilderComponent model={props.modelType} content={content} />
    </div>
  );
}

export default BuilderIOCoreOptions;
