import { useMemo } from 'react';

import { Container, Hidden } from '@mui/material';
import clsx from 'clsx';
import { Dictionary } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router-dom';

import { Button, Link } from 'components';
import useDocumentTitle from 'hooks/useDocumentTitle';
import leak from 'images/leak.svg';
import leak_bg from 'images/leak_bg.svg';
import stacked_boxes from 'images/stacked_boxes.svg';

/**
 * Styles
 */
const imageStyle =
  'absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]';

/**
 * Types
 */
type Translation = {
  header: string;
  body: string;
  actionText: string;
  actionLink: string;
};
type TranslationMap = Dictionary<Translation>;
export enum ErrorTypes {
  NOT_FOUND = 'NOT_FOUND',
  BRANCH_ERROR = 'BRANCH_ERROR',
  OTHER = 'OTHER' // Unused in prod but added for test coverage
}
export type ErrorState = {
  errorType?: ErrorTypes;
};

/**
 * Component
 */
function ErrorComponent() {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();
  const { state } = useLocation<ErrorState>();
  useDocumentTitle(t('common.errorTitle'));
  const { errorType } = state;
  const isNotFound = errorType === ErrorTypes.NOT_FOUND;
  const isBranchError = errorType === ErrorTypes.BRANCH_ERROR;
  const validErrorType = isNotFound || isBranchError;

  /**
   * Memo
   */
  const translationMap = useMemo<TranslationMap>(
    () => ({
      [ErrorTypes.NOT_FOUND]: {
        header: t('product.notFound'),
        body: t('product.notFoundDescription'),
        actionText: t('common.backToHome'),
        actionLink: '/'
      },
      [ErrorTypes.BRANCH_ERROR]: {
        header: t('branch.notFound'),
        body: t('branch.notFoundDescription'),
        actionText: 'Contact Support',
        actionLink: '/support'
      },
      [ErrorTypes.OTHER]: {
        header: t('common.leak'),
        body: t('common.tryGoingBack'),
        actionText: t('common.backToHome'),
        actionLink: '/'
      }
    }),
    [t]
  );

  /**
   * Render
   */
  return errorType ? (
    <div
      className="py-20 min-h-[480px] bg-common-white flex flex-1 md:py-10 md:mx-6 md:min-h-min md:bg-transparent"
      data-testid="error-wrapper"
    >
      <Container maxWidth="md" className="!flex flex-1">
        <div className="grid grid-cols-12 grid-flow-row gap-12 md:grid-flow-col md:gap-0">
          <Hidden mdDown>
            <div className="col-span-6 relative">
              <img
                src={leak_bg}
                alt="leaky pipe background"
                className={imageStyle}
                data-testid="error-desktop-bg"
              />
              <img
                src={validErrorType ? stacked_boxes : leak}
                alt={validErrorType ? 'stacked boxes' : 'leaky pipe'}
                className={imageStyle}
                data-testid="error-desktop-image"
              />
            </div>
          </Hidden>
          <div className="col-span-5 flex flex-col justify-center items-center md:col-span-12">
            <Hidden mdUp>
              <img
                className={clsx('mb-10', {
                  'max-w-[170px] h-auto': validErrorType
                })}
                src={validErrorType ? stacked_boxes : leak}
                alt={validErrorType ? 'stacked boxes' : 'leaky pipe'}
                data-testid="error-mobile-image"
              />
            </Hidden>
            {!validErrorType && (
              <h1
                className="text-5xl text-primary-2-100 font-normal md:text-4xl"
                data-testid="error-title"
              >
                {t('common.ohNo')}
              </h1>
            )}
            <h3
              className="pt-1.5 text-3xl font-medium text-center md:text-xl"
              data-testid="error-subtitle"
            >
              {translationMap[errorType].header}
            </h3>
            <p
              className="py-8 text-xl font-normal text-center md:py-4 md:text-base"
              data-testid="error-message"
            >
              {translationMap[errorType].body}
            </p>
            <div
              className={clsx(
                'px-8 flex flex-row items-center gap-6 md:pt-1 md:gap-2',
                { 'md:flex-col-reverse': validErrorType }
              )}
            >
              <div
                className={clsx('min-w-[150px]', { 'md:w-1/2': isNotFound })}
              >
                <Link
                  to={translationMap[errorType].actionLink}
                  className="flex"
                >
                  <Button kind="fill" fullWidth data-testid="error-back-button">
                    {translationMap[errorType].actionText}
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  ) : (
    <Redirect to="/" />
  );
}

export default ErrorComponent;
