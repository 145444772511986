import { ReactNode } from 'react';

import clsx from 'clsx';

/**
 * Types
 */
export type TabPanelProps = {
  children: ReactNode;
  className?: string;
  id?: string;
  'aria-labelledby'?: string;
  index: number;
  value: number;
  testId?: string;
};

/**
 * Component
 */
function TabPanel(props: TabPanelProps) {
  /**
   * Props
   */
  const {
    'aria-labelledby': ariaLabelledby,
    children,
    className,
    id,
    index,
    testId,
    value
  } = props;

  /**
   * Render
   */
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={id || `tabpanel-${index}`}
      aria-labelledby={ariaLabelledby || `tab-${index}`}
      className={clsx(className, 'mb-14')}
      data-testid={testId}
    >
      {value === index && children}
    </div>
  );
}

export default TabPanel;
