import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useApiLazy } from 'API/hooks/useApiLazy';
import PermissionRequired, { Permission } from 'common/PermissionRequired';
import { Button } from 'components';
import AccordionPage from 'pages/Components/sub/Accordion';
import AdvancedToolTipPage from 'pages/Components/sub/AdvancedTooltip';
import AlertPage from 'pages/Components/sub/Alert';
import ButtonPage from 'pages/Components/sub/Button';
import BadgePage from 'pages/Components/sub/Badge';
import ChipPage from 'pages/Components/sub/Chip';
import CircularProgressPage from 'pages/Components/sub/CircularProgress';
import DayPickerPage from 'pages/Components/sub/DayPicker';
import DropdownButtonPage from 'pages/Components/sub/DropdownButton';
import FormInputsPage from 'pages/Components/sub/FormInputs';
import MaskedInputPage from 'pages/Components/sub/MaskedInput';
import IconButtonPage from 'pages/Components/sub/IconButton';
import ImagePage from 'pages/Components/sub/Image';
import InfoChipPage from 'pages/Components/sub/InfoChip';
import SelectInputPage from 'pages/Components/sub/SelectInput';
import TextInputPage from 'pages/Components/sub/TextInput';
import LinkPage from 'pages/Components/sub/Link';
import NumberInputPage from 'pages/Components/sub/NumberInput';
import PaginationPage from 'pages/Components/sub/Pagination';
import StepperPage from 'pages/Components/sub/Stepper';
import ModalPage from 'pages/Components/sub/Modal';
import CheckboxPage from 'pages/Components/sub/Checkbox';
import ToastPage from 'pages/Components/sub/Toast';

/**
 * Component
 */
function ComponentsPage() {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();

  /**
   * API
   */
  const api = useApiLazy({
    kind: 'get',
    options: {},
    header: {},
    url: 'actuator/health'
  });

  /**
   * Callbacks
   */
  const useApiLazyCall = async () => {
    const data = await api.call();
    console.info({ useApiLazyCall: data });
  };

  /**
   * Render
   */
  return (
    <PermissionRequired permissions={[Permission.TOGGLE_FEATURES]}>
      <Container className="my-6 pb-6" data-testid="component_container">
        <h5 className="my-4 text-4xl text-primary-1-100 font-medium sm:text-3xl sm:text-center">
          {t('common.components')}
        </h5>
        <AccordionPage />
        <AlertPage />
        <AdvancedToolTipPage />
        <BadgePage />
        <ButtonPage />
        <ChipPage />
        <CircularProgressPage />
        <DayPickerPage />
        <DropdownButtonPage />
        <FormInputsPage />
        <IconButtonPage />
        <ImagePage />
        <InfoChipPage />
        <LinkPage />
        <MaskedInputPage />
        <NumberInputPage />
        <PaginationPage />
        <SelectInputPage />
        <StepperPage />
        <TextInputPage />
        <ModalPage />
        <CheckboxPage />
        <h2 className="text-primary-1-100 font-medium text-4xl mb-4">
          API TEST
        </h2>
        <Button
          disabled={api.loading}
          onClick={useApiLazyCall}
          data-testid="bff-api-test-call"
        >
          Test useApiLazy
        </Button>
        <ToastPage />
      </Container>
    </PermissionRequired>
  );
}

export default ComponentsPage;
