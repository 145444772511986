import { CSSProperties } from 'react';

import { useTheme } from '@mui/material';
import { TransitionStatus } from 'react-transition-group';

/**
 * Types
 */
export type SearchDrawerTransitions = {
  [key in TransitionStatus]: CSSProperties;
};

/**
 * Hook
 */
function useSearchDrawerTransitions(): SearchDrawerTransitions {
  /**
   * Custom hooks
   */
  const { transitions } = useTheme();
  const { create, duration, easing } = transitions;
  const { enteringScreen: entering, leavingScreen: leaving } = duration;
  const { easeIn, easeOut } = easing;

  /**
   * Output
   */
  return {
    entering: {
      visibility: 'visible',
      transition: create(['height'], { duration: leaving, easing: easeIn })
    },
    entered: {
      visibility: 'visible',
      transition: create(['height'], { duration: leaving, easing: easeIn })
    },
    exiting: {
      height: 0,
      transition: create(['height'], { duration: entering, easing: easeOut }),
      visibility: 'visible'
    },
    exited: {
      height: 0,
      transition: create(['height'], { duration: entering, easing: easeOut }),
      visibility: 'hidden'
    },
    unmounted: { height: 0, visibility: 'hidden' }
  };
}

export default useSearchDrawerTransitions;
