import { useEffect, useMemo, useRef, useState } from 'react';

import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import clsx from 'clsx';
import { clamp } from 'lodash';

import { OnInputChange, OnInputKeyboard } from '@reece/global-types';
import Button from 'components/Button';
import {
  buttonStyle,
  inputStyle,
  textStyle,
  wrapperStyles
} from 'components/Pagination/styles';
import InputBase from 'components/TextInput/InputBase';

/**
 * Types
 */
export type PaginationProps = {
  align?: keyof typeof wrapperStyles;
  className?: string;
  count: number;
  current?: number;
  ofText?: string;
  onChange?: (page: number) => void;
  onNext?: (page: number) => void;
  onPrev?: (page: number) => void;
  testId?: string;
};

/**
 * Component
 */
function Pagination(props: PaginationProps) {
  /**
   * Props
   */
  const {
    align = 'left',
    className,
    count,
    current = 1,
    ofText = 'of',
    onChange,
    onNext,
    onPrev,
    testId
  } = props;
  const disabled = count === 1;

  /**
   * Refs
   */
  const inputRef = useRef<HTMLInputElement>(null);

  /**
   * States
   */
  const [inputValue, setInputValue] = useState<string | null>(null);
  const [page, setPage] = useState(1);

  /**
   * Memo
   */
  // 🔵 memo - Input value used for render
  const getInputValue = useMemo(() => {
    if (inputValue === null) {
      return page.toString();
    }
    if (isNaN(parseInt(inputValue))) {
      return '';
    }
    return inputValue;
  }, [inputValue, page]);

  /**
   * Callbacks
   */
  // 🟤 cb - input onBlur
  const handleBlur = () => {
    setInputValue(null);
  };
  // 🟤 cb - input onChange
  const handleChange = (event: OnInputChange) => {
    const tempInputValue = parseInt(event.currentTarget.value, 10);
    const limitedValue = Math.min(tempInputValue, count);
    setInputValue(isNaN(limitedValue) ? '' : limitedValue.toString());
  };
  // 🟤 cb - previous button onClick
  const handlePrev = () => {
    const validPage = setValidValue(page - 1);
    onPrev?.(validPage);
  };
  // 🟤 cb - next button onClick
  const handleNext = () => {
    const validPage = setValidValue(page + 1);
    onNext?.(validPage);
  };
  // 🟤 cb - input onKeyDown
  const handleKeyDown = (event: OnInputKeyboard) => {
    if (event.key !== 'Enter') {
      return;
    }
    setInputValue(null);
    inputRef?.current?.blur();
    if (event.currentTarget.value !== '') {
      const validPage = setValidValue(parseInt(event.currentTarget.value, 10));
      onChange?.(validPage);
    }
  };

  /**
   * Effects
   */
  // 🟡 effect - set parent value
  useEffect(() => {
    setValidValue(current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);
  // 🟡 effect - adjust input size based on length
  useEffect(() => {
    const length =
      inputValue !== null ? inputValue.length || 1 : page.toString().length;
    inputRef.current?.setAttribute('size', length.toString());
  }, [inputValue, page]);

  /**
   * Render
   */
  return (
    <div
      className={clsx(className, 'flex items-center', wrapperStyles[align])}
      data-testid={testId}
    >
      <Button
        disabled={disabled || page === 1}
        className={clsx('mr-4', buttonStyle)}
        onClick={handlePrev}
        data-testid={`${testId}-previous`}
      >
        <ChevronLeft />
      </Button>
      <InputBase
        ref={inputRef}
        type="text"
        value={getInputValue}
        className={clsx(inputStyle)}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        disabled={disabled}
        size={1}
        data-testid={`${testId}-input`}
      />
      <span className={clsx('px-2', textStyle)}>{ofText}</span>
      <span className={clsx(textStyle)} data-testid={`${testId}-total`}>
        {count}
      </span>
      <Button
        disabled={disabled || page === count}
        className={clsx('ml-4', buttonStyle)}
        onClick={handleNext}
        data-testid={`${testId}-next`}
      >
        <ChevronRight />
      </Button>
    </div>
  );

  /**
   * Util
   */
  // 🔣 util - filter and then setNumber
  function setValidValue(value: number) {
    const newValue = clamp(value, 1, count);
    setPage(newValue);
    return newValue;
  }
}

export default Pagination;
