import { useState, useContext, useEffect, createContext } from 'react';

import { noop } from 'lodash-es';

import { WrapperProps } from '@reece/global-types';

/**
 * Types
 */
declare global {
  interface Window {
    utag?: Utag;
  }
}
export type Utag = {
  link: (data: object) => void;
  view: (data: object) => void;
};

/**
 * Config
 */
export const MAX_REFRESH = 50;
export const TIMEOUT = 200;
export const errorMessage = 'Unable to load utag!';
export const defaultUtag: Utag = { link: noop, view: noop };

/**
 * Context
 */
const UtagContext = createContext<Utag>(defaultUtag);
export const useUtag = () => useContext(UtagContext);

/**
 * Provider
 */
function UtagProvider({ children }: WrapperProps) {
  /**
   * State
   */
  const [utag, setUtag] = useState(defaultUtag);

  /**
   * Effects
   */
  // 🟡 Effect - Poll for the utag object on the window over a period
  useEffect(() => {
    withUtag().then((newUtag) => setUtag(newUtag));
  }, []);

  /**
   * Render
   */
  return <UtagContext.Provider value={utag}>{children}</UtagContext.Provider>;
}
export default UtagProvider;

/**
 * Util
 */
export async function withUtag() {
  for (const i of new Array(MAX_REFRESH)) {
    if (window.utag) {
      return window.utag;
    }
    await new Promise((resolve) => setTimeout(() => resolve(i), TIMEOUT));
  }
  console.warn(errorMessage);
  return defaultUtag;
}
