import { useState } from 'react';

import Card from 'components/Card';
import SelectInput from 'components/SelectInput';

/**
 * Config
 */
const display = {
  title: 'Select Inputs',
  placeholder: 'Placeholder',
  label: 'Input',
  helper: 'Helper Text'
};
const options = [
  { label: 'Select', value: 'select' },
  { label: 'ABC', value: 'abc' },
  { label: '123', value: '123' }
];

/**
 * Component
 */
function SelectInputPage() {
  /**
   * States
   */
  const [value, setValue] = useState('');

  /**
   * Render
   */
  return (
    <Card className="my-8 p-6">
      <h5 className="text-2xl font-bold text-secondary-2-100">
        {display.title}
      </h5>
      <hr className="my-4" />
      <div className="my-4 flex justify-center">
        <SelectInput
          label="Input"
          value={value}
          required
          fullWidth
          message={display.helper}
          placeholder={display.placeholder}
          onChange={setValue}
          ref={null}
          listKey="select-main"
          options={options}
        />
      </div>
      <div className="my-4 flex justify-center">
        <SelectInput
          label="Input"
          value={value}
          error
          fullWidth
          message={display.helper}
          placeholder={display.placeholder}
          onChange={setValue}
          ref={null}
          listKey="select-error"
          options={options}
        />
      </div>
      <div className="my-4 flex justify-center">
        <SelectInput
          label="Input"
          value={value}
          disabled
          fullWidth
          message={display.helper}
          placeholder={display.placeholder}
          onChange={setValue}
          ref={null}
          listKey="select-error"
          options={options}
        />
      </div>
    </Card>
  );
}

export default SelectInputPage;
