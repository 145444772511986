import { useEffect, useState } from 'react';

import { Container, Divider, Hidden, Toolbar } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { useHeaderContext } from 'common/Header/HeaderProvider';
import {
  containerStyle,
  containerStyleSearchOpen,
  logoStyle,
  toolbarStyle,
  toolbarStyleSearchOpen
} from 'common/OldHeader/lib/styles';
import CartButton from 'common/OldHeader/sub/CartButton';
import DesktopSubheader from 'common/OldHeader/sub/DesktopSubheader';
import NewDesktopSubheader from 'common/OldHeader/sub/NewDesktopSubheader';
import NewListsSubheader from 'common/OldHeader/sub/NewListsSubheader';
import MobileDrawer from 'common/OldHeader/sub/MobileDrawer';
import OldBranchButton from 'common/OldHeader/sub/OldBranchButton';
import SearchBar from 'common/OldHeader/sub/SearchBar';
import { AppBar, Button, IconButton, Link } from 'components';
import { useDomainInfo } from 'hooks/useDomainInfo';
import { MenuIcon, SearchIcon, StarFilledIcon } from 'icons';
import logoSrc from 'images/logo.svg';
import { useAuthContext } from 'providers/AuthProvider';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';

/**
 * Component
 */
function OldHeader() {
  /**
   * Custom Hooks
   */
  const { isWaterworks, subdomain } = useDomainInfo();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  /**
   * State
   */
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [waterworksMaintenance, setWaterworksMaintenance] = useState(false);

  /**
   * Context
   */
  const { activeFeatures, authState, profile } = useAuthContext();
  const { searchOpen, setSearchOpen } = useHeaderContext();
  const { isMincron } = useSelectedAccountsContext();
  const newNavBar =
    activeFeatures?.includes('NEW_NAVBAR') &&
    !activeFeatures?.includes('NEW_HEADER');

  /**
   * Callbacks
   */
  // 🟤 Cb - Open drawer
  const handleDrawerOpen = () => setDrawerOpen(true);
  // 🟤 Cb - Open search
  const handleSearchOpen = () => setSearchOpen(true);
  // 🟤 Cb - My lists
  const handleListsClick = () =>
    push(authState?.isAuthenticated ? '/lists' : '/login');

  /**
   * Effect
   */
  // 🟡 effect - Overflow when search
  useEffect(() => {
    document.body.classList.toggle('overflow-hidden', searchOpen);
  }, [searchOpen]);
  // 🟡 effect - Waterworks maintenance trigger
  useEffect(() => {
    if (
      activeFeatures?.includes('WATERWORKS_MAINTENANCE') &&
      subdomain === 'fortiline'
    ) {
      setWaterworksMaintenance(true);
    }
  }, [activeFeatures, subdomain]);

  /**
   * Render
   */
  if (
    pathname.includes('/barcode') ||
    pathname.includes('/careers') ||
    pathname.includes('/banner/')
  ) {
    return null;
  }
  // Select accounts/Register page specific header
  if (pathname.includes('/select-accounts') || pathname.includes('/register')) {
    return (
      <div
        className="bg-transparent bottom-0 z-10 print:hidden"
        data-testid="header-wrapper-alt"
      >
        <AppBar color="inherit" position="static" elevation={0}>
          <Toolbar
            disableGutters
            className={clsx(toolbarStyle, {
              [toolbarStyleSearchOpen]: searchOpen
            })}
          >
            <Container
              maxWidth="lg"
              className={clsx(containerStyle, {
                [containerStyleSearchOpen]: searchOpen
              })}
            >
              <div className="leading-none flex md:mx-auto">
                <Link to="/" className="flex" data-testid="header-logo-link">
                  <img
                    alt="Reece"
                    data-testid="reecestandard-logo"
                    src={logoSrc}
                    className={logoStyle}
                  />
                  {subdomain !== 'reece' && (
                    <>
                      <div className="mx-3 my-2 md:my-0">
                        <Divider orientation="vertical" />
                      </div>
                      <img
                        alt={subdomain}
                        data-testid="subdomain-logo"
                        src={`/logos/${subdomain}.svg`}
                        className={logoStyle}
                      />
                    </>
                  )}
                </Link>
              </div>
            </Container>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
  // General Header
  return (
    <div
      className="bg-transparent bottom-0 z-10 print:hidden"
      data-testid="header-wrapper"
    >
      <div className={clsx('hidden md:block h-auto', { '!h-0': searchOpen })} />
      <MobileDrawer open={drawerOpen} setOpen={setDrawerOpen} />
      <div
        className={clsx('bg-transparent bottom-0 z-10', {
          'md:absolute md:top-0 md:w-full': searchOpen
        })}
      >
        <AppBar color="inherit" position="static" elevation={0}>
          <div className="bg-common-white">
            <Toolbar
              disableGutters
              className={clsx(toolbarStyle, {
                [toolbarStyleSearchOpen]: searchOpen
              })}
            >
              {!isWaterworks && !isMincron && (
                <Hidden mdUp>
                  <SearchBar />
                </Hidden>
              )}
              <Container
                maxWidth="lg"
                className={clsx(containerStyle, {
                  [containerStyleSearchOpen]: searchOpen
                })}
              >
                <div className="w-full grid grid-cols-3 gap-2 md:gap-0">
                  <div className="relative col-span-2 flex items-center flex-nowrap md:col-span-3 md:justify-between">
                    <Hidden mdUp>
                      <div className="flex flex-1 justify-start">
                        <IconButton
                          onClick={handleDrawerOpen}
                          size="large"
                          className="mr-auto"
                          data-testid="mobile-hamburger-menu"
                        >
                          <MenuIcon />
                        </IconButton>
                      </div>
                    </Hidden>
                    <div className="flex basis-1/4 flex-grow-0 flex-shrink leading-none">
                      <Link to="/" className="flex">
                        <img
                          alt="Reece"
                          data-testid="reecestandard-logo"
                          src={logoSrc}
                          className={logoStyle}
                        />
                      </Link>
                      {subdomain !== 'reece' && (
                        <>
                          <div className="mx-3 my-2 md:my-0">
                            <Divider orientation="vertical" />
                          </div>
                          <Link to="/" className="flex">
                            <img
                              alt={subdomain}
                              data-testid="subdomain-logo"
                              src={`/logos/${subdomain}.svg`}
                              className={logoStyle}
                            />
                          </Link>
                        </>
                      )}
                    </div>
                    {!isWaterworks && !isMincron && (
                      <Hidden mdDown>
                        <div className="ml-10 flex flex-1">
                          <SearchBar />
                        </div>
                      </Hidden>
                    )}
                    <Hidden mdUp>
                      <div className="flex flex-1 justify-end">
                        {!isWaterworks && !isMincron && (
                          <IconButton
                            onClick={handleSearchOpen}
                            size="large"
                            className="ml-auto"
                            data-testid="header-search-button"
                          >
                            <SearchIcon />
                          </IconButton>
                        )}
                        {!waterworksMaintenance && <CartButton />}
                      </div>
                    </Hidden>
                  </div>
                  <Hidden mdDown>
                    <div
                      className={clsx(
                        'col-span-1 flex items-center justify-end flex-nowrap',
                        { hidden: waterworksMaintenance }
                      )}
                    >
                      {pathname !== '/select-accounts' && (
                        <>
                          {!isWaterworks && !isMincron && (
                            <Button
                              kind="text"
                              color="gray"
                              iconStart={
                                <StarFilledIcon className="text-primary-1-100" />
                              }
                              onClick={handleListsClick}
                              data-testid="my-lists-button"
                              className="whitespace-nowrap !font-normal"
                            >
                              {t('common.myLists')}
                            </Button>
                          )}
                          <OldBranchButton data-testid="old-branch-button-desktop" />{' '}
                          <CartButton />
                        </>
                      )}
                    </div>
                  </Hidden>
                </div>
              </Container>
            </Toolbar>
            {Boolean(
              authState?.isAuthenticated &&
                (!profile?.isEmployee ||
                  (profile?.isEmployee && profile?.isVerified)) &&
                pathname !== '/select-accounts'
            ) && (
              <Hidden mdUp>
                <Divider />
                {!waterworksMaintenance && (
                  <Toolbar disableGutters>
                    <OldBranchButton data-testid="old-branch-button-mobile" />
                  </Toolbar>
                )}
              </Hidden>
            )}
          </div>
          {!waterworksMaintenance && (
            <Hidden mdDown>
              {newNavBar && authState?.isAuthenticated ? (
                <div>
                  <NewDesktopSubheader />
                  <NewListsSubheader />
                </div>
              ) : (
                <div>
                  <DesktopSubheader />
                </div>
              )}
            </Hidden>
          )}
        </AppBar>
      </div>
    </div>
  );
}

export default OldHeader;
