import { useCallback, useEffect, useMemo } from 'react';

import { useSnackbar } from '@dialexa/reece-component-library';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  InviteUserInput,
  useInviteUserMutation,
  useAccountErpIdLazyQuery,
  ErpSystemEnum
} from 'generated/graphql';
import { InviteUserFormInput } from 'Invite';
import { useDomainInfo } from 'hooks/useDomainInfo';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';
import { encryptData } from 'utils/encrypt';

export default function useInviteCallback() {
  /**
   * Custom hooks
   */
  const history = useHistory();
  const { pushAlert } = useSnackbar();
  const { t } = useTranslation();
  const { brand } = useDomainInfo();

  /**
   * Data
   */
  const [inviteUser, { loading: inviteUserLoading }] = useInviteUserMutation();
  const [getErpAccount, { data: erpAccountData }] = useAccountErpIdLazyQuery();

  /**
   * Context
   */
  const { selectedAccounts } = useSelectedAccountsContext();

  // 🔵 Memo - Encrypted ShipTo and BillTo
  const encryptedAccount = useMemo(
    () => encryptData(erpAccountData?.account[0]?.erpAccountId ?? ''),
    [erpAccountData?.account]
  );

  /**
   * Callbacks
   */
  const handleInviteUser = useCallback(
    handleInviteUserCb,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [erpAccountData, history, inviteUser, pushAlert, selectedAccounts, t]
  );

  /**
   * Effect
   */
  useEffect(getErpAccountEffect, [getErpAccount, selectedAccounts, brand]);

  /**
   * Callback def
   */
  async function handleInviteUserCb(invite: Omit<InviteUserFormInput, 'erp'>) {
    if (erpAccountData) {
      const inviteUserInput: InviteUserInput = {
        ...invite,
        billToAccountId: selectedAccounts?.billTo?.id ?? '',
        erpAccountId: encryptedAccount,
        erp: selectedAccounts?.erpSystemName ?? ErpSystemEnum.Eclipse
      };

      try {
        await inviteUser({ variables: { inviteUserInput } });
        pushAlert(t('user.userInviteSuccess'), { variant: 'success' });
        history.push('/user-management');
      } catch (error) {
        try {
          pushAlert(JSON.parse((error as any).message).error, {
            variant: 'error'
          });
        } catch {
          console.error('Could not parse error response');
          pushAlert(t('user.userInviteError'), { variant: 'error' });
        }
      }
    }
  }

  /**
   * Effect def
   */
  function getErpAccountEffect() {
    const accountId = encryptData(selectedAccounts?.billTo?.id ?? '');
    if (selectedAccounts?.billTo?.id) {
      getErpAccount({ variables: { accountId, brand } });
    }
  }

  /**
   * Output
   */
  return { inviteUserLoading, handleInviteUser };
}
