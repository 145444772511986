import { alpha } from '@mui/material/styles';

import palette from 'providers/libs/Theme/palette';
import theme from 'providers/libs/Theme/baseTheme';

const muiOutlinedInput = {
  root: {
    '&:not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline': {
      borderColor: palette.primary02.main
    },
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${palette.primary02.main}`,
        boxShadow: `0 0 4px 0 ${alpha(palette.primary02.main, 0.5)}`
      }
    }
  },
  input: {
    padding: theme.spacing(1.75, 2)
  },
  inputMarginDense: {
    paddingTop: theme.spacing(1.5),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(1)
  },
  notchedOutline: {
    borderColor: palette.lightGray.main,
    top: 0,
    '& legend': {
      display: 'none'
    }
  }
};

export default muiOutlinedInput;
