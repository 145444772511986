import { useCallback } from 'react';

import { EcommAccount, Maybe } from 'generated/graphql';
import { useDomainInfo } from 'hooks/useDomainInfo';
import { useTranslation } from 'react-i18next';

/**
 * Types
 */
export type SelectAccountOption = {
  label: {
    id: string;
    name: string;
    branchId: string;
    divisionEnum: string;
    address: string;
    accountNumber: string;
  };
  value: string;
};

/**
 * Hook
 */
function useFormatOptions() {
  // Custom hooks
  const { divisionEnum } = useDomainInfo();
  const { t } = useTranslation();

  // Callbacks
  const formatOptions = useCallback(
    (data: EcommAccount[]): SelectAccountOption[] =>
      data
        .filter((item) => item.divisionEnum === divisionEnum)
        .map((item) => ({
          label: {
            id: item.id ?? '',
            name: `${item.name ?? t('common.na')}`,
            branchId: item.branchId ?? '-',
            divisionEnum: item.divisionEnum ?? '',
            address: `${item.address ?? t('branch.noAddress')}`,
            accountNumber: `#${item.erpAccountId ?? '-'}`
          },
          value: item.id ?? ''
        })),
    [divisionEnum, t]
  );

  // Output
  return formatOptions;
}

export default useFormatOptions;

/**
 * Util
 */
export const formatAccountDisplay = (account?: Maybe<SelectAccountOption>) =>
  account ? `${account.label.accountNumber} - ${account.label.name}` : '';
