import { useMemo } from 'react';

import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import {
  ContactFormInput,
  useSendContactFormMutation,
  useUserQuery
} from 'generated/graphql';
import { useAuthContext } from 'providers/AuthProvider';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';
import { useSnackbar } from 'providers/SnackbarProvider';

/**
 * Config
 */
export const API_SUCCESS = 'Email sent successfully!';

/**
 * Hook
 */
function useSupportForm() {
  /**
   * Custom Hook
   */
  const { pushAlert } = useSnackbar();
  const { t } = useTranslation();

  /**
   * Context
   */
  const { profile } = useAuthContext();
  const { selectedAccounts } = useSelectedAccountsContext();

  /**
   * Data
   */
  // 🟣 Query - User
  const { data: userQuery, loading: userLoading } = useUserQuery({
    variables: { userId: profile?.userId }
  });
  // 🟣 Mutation - Send contact form
  const [contactFormQuery, { loading: sendLoading }] =
    useSendContactFormMutation();

  /**
   * Memo
   */
  const initialValues = useMemo(
    () => ({
      firstName: userQuery?.user?.firstName || '',
      lastName: userQuery?.user?.lastName || '',
      phoneNumber: userQuery?.user?.phoneNumber || '',
      email: userQuery?.user?.email || '',
      zip: (selectedAccounts.billToErpAccount?.zip || '').slice(0, 5),
      topic: '',
      message: ''
    }),
    [userQuery, selectedAccounts]
  );

  /**
   * Callbacks
   */
  const onSubmit = (contactFormInput: ContactFormInput) => {
    const submit = async () => {
      try {
        const res = await contactFormQuery({ variables: { contactFormInput } });
        if (res.data?.sendContactForm === API_SUCCESS) {
          formik.resetForm();
          pushAlert(t('common.messageSent'), { variant: 'success' });
          return;
        }
        pushAlert(t('common.messageFail'), { variant: 'error' });
      } catch (e) {
        pushAlert(t('common.messageFail'), { variant: 'error' });
        console.error(e);
      }
    };
    submit();
  };

  /**
   * Form
   */
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      firstName: Yup.string()
        .trim()
        .required(t('validation.firstNameRequired')),
      lastName: Yup.string().trim().required(t('validation.lastNameRequired')),
      phoneNumber: Yup.string().matches(/^\(\d{3}\)\s{1}\d{3}-\d{4}$/, {
        message: t('validation.phoneNumberInvalid')
      }),
      email: Yup.string()
        .email(t('validation.emailInvalid'))
        .required(t('validation.emailRequired')),
      zip: Yup.string()
        .matches(/^\d{5}$/, { message: t('validation.zipInvalid') })
        .required(t('validation.zipRequired')),
      topic: Yup.string().required(t('validation.topicRequired')),
      message: Yup.string().trim().required(t('validation.messageRequired'))
    }),
    onSubmit
  });

  /**
   * Output
   */
  return { formik, sendLoading, userLoading };
}

export default useSupportForm;
