import { gql, QueryHookOptions, useQuery } from '@apollo/client';

/**
 * Types
 */
export type MaintenanceBanner = {
  isOn: boolean;
  message: string;
  title: string;
};
export type MaintenanceBannerQuery = {
  maintenanceBannerCollection?: { items: MaintenanceBanner[] };
};

/**
 * Query
 */
export const maintenanceBannerQuery = gql`
  query {
    maintenanceBannerCollection {
      items {
        isOn
        message
        title
      }
    }
  }
`;

/**
 * Hook
 */
export const useMaintenanceBannerQuery = (
  options?: QueryHookOptions<MaintenanceBannerQuery, {}>
) =>
  useQuery<MaintenanceBannerQuery>(maintenanceBannerQuery, {
    ...options,
    context: { clientName: 'contentful' },
    variables: {}
  });
