import {
  ComponentPropsWithoutRef,
  CSSProperties,
  Dispatch,
  forwardRef
} from 'react';

import clsx from 'clsx';
import { size } from 'lodash-es';

import TextareaBase from 'components/Textarea/TextareaBase';
import Label from 'components/Label';

/**
 * Types
 */
export type TextareaProps = ComponentPropsWithoutRef<'textarea'> & {
  className?: string;
  error?: boolean;
  inputClassName?: string;
  inputStyles?: CSSProperties;
  label?: string;
  labelClassName?: string;
  message?: string;
  messageClassName?: string;
  setValue?: Dispatch<string>;
  status?: 'success' | 'neutral';
  testId?: string;
};

/**
 * Component
 */
const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => {
    /**
     * Props
     */
    const {
      className,
      disabled,
      error,
      inputClassName,
      inputStyles,
      label,
      labelClassName,
      maxLength,
      message,
      messageClassName,
      readOnly,
      testId,
      value,
      ...rest
    } = props;

    /**
     * Render
     */
    return (
      <div className={clsx('flex flex-col h-max w-full', className)}>
        {/* Label */}
        <Label
          label={label}
          required={rest.required}
          htmlFor={rest.id ?? rest.name}
          testId={testId}
        />
        {/* Input */}
        <div className="relative md:h-full flex flex-col">
          <TextareaBase
            className={inputClassName}
            style={inputStyles ?? undefined}
            disabled={disabled}
            error={error}
            readOnly={readOnly}
            ref={ref}
            value={value}
            data-testid={testId}
            {...rest}
          />
          <div className="grid grid-cols-[90%_10%] items-center px-2 text-xs">
            {/* Message */}
            <div className="flex">
              {Boolean(message) && (
                <span
                  className={clsx(messageClassName, {
                    'text-support-1-100': error
                  })}
                  data-testid={`${testId}-message`}
                >
                  {message}
                </span>
              )}
            </div>
            {/* Count */}
            {maxLength && (
              <div
                className={clsx('flex justify-self-end', {
                  'text-support-1-100': error
                })}
                data-testid={`${testId}-count`}
              >{`${size(`${value}`)}/${maxLength}`}</div>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default Textarea;
