import BuilderIOCoreOptions from 'pages/BuilderIO/CoreOptions';
import { useAuthContext } from 'providers/AuthProvider';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

/**
 * Component
 */
function BuilderIOPartners(props: RouteComponentProps) {
  /**
   * Contexts
   */
  const { oktaAuth } = useAuthContext();

  /**
   * States
   */
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  /**
   * Effects
   */
  useEffect(() => {
    setLoading(true);
    oktaAuth?.isAuthenticated().then((authRes) => {
      setIsLoggedIn(authRes);
      setLoading(false);
    });
  }, [oktaAuth]);

  /**
   * Render
   */
  return (
    <div data-testid="builderio-page-partners">
      <BuilderIOCoreOptions
        {...props}
        skip={loading}
        modelType="page"
        data={{ isLoggedIn }}
      />
    </div>
  );
}
export default BuilderIOPartners;
