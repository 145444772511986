import { findIndex, intersectionWith, xorWith } from 'lodash-es';
import { t } from 'i18next';
import { Maybe } from 'yup/lib/types';

import { ContractDetails, LineItem } from 'generated/graphql';
import {
  CartProps,
  DeleteItemResponse,
  ProductProps
} from 'API/types/cart.types';
import { format } from 'utils/currency';

export function findCartProductIndex(itemId: string, cart?: CartProps) {
  const clonedProducts = [...(cart?.products ?? [])];
  const index = findIndex(clonedProducts, (item) => item?.id === itemId);
  return { clonedProducts, index };
}

// Use for Release # of items of contract (NOT all)
export function pullContractProductFromMap(
  contract: ContractDetails,
  qtyMap: Record<string, string>,
  merge: boolean,
  cartLineItems?: Maybe<ProductProps>[]
) {
  if (!contract.contractProducts?.length) {
    return [];
  }

  const foundProducts = findProducts(contract, qtyMap);
  const validProducts = validateProducts(foundProducts);
  const output = convertMapOfProductFromContractToCart(validProducts);
  if (!merge || !cartLineItems) {
    return output;
  }

  return mergeLineItems(cartLineItems, output);
}

// Release all items from contract
export function pullAllContractProducts(contract: ContractDetails) {
  if (!contract.contractProducts?.length) {
    return [];
  }

  const foundProducts = findProducts(contract);
  const validProducts = validateProducts(foundProducts);
  const output = convertMapOfProductFromContractToCart(validProducts);

  return output;
}

// Convert type from ContractProduct to LineItem
export type FoundProduct = ReturnType<typeof findProducts>[0];
export function convertMapOfProductFromContractToCart(
  products: FoundProduct[]
) {
  return products.map(
    ({ product, qty }) =>
      ({
        customerPartNumber: product.partNumber ?? '',
        erpPartNumber: product.mfr ?? '',
        id: product.sequenceNumber ?? `${product.id ?? ''}-${product.index}`,
        pricePerUnit: product.netPrice ?? 0,
        product: {
          // Purposely added index to the object to prevent duplicate ID
          id: product.sequenceNumber ?? `${product.id ?? ''}-${product.index}`,
          categories: [],
          sellPrice: product.netPrice ?? '',
          imageUrls: {
            medium: product.thumb ?? '',
            small: product.thumb ?? '',
            thumb: product.thumb ?? '',
            large: product.thumb ?? ''
          },
          manufacturerName: product.brand ?? '',
          manufacturerNumber: product.mfr ?? '',
          name: product.name ?? '',
          partNumber: product.partNumber ?? '',
          minIncrementQty: 0
        },
        productPricingDTO: {
          productID: product.id ?? '',
          catalogId: '',
          branchAvailableQty: 0,
          totalAvailableQty: 0,
          qty,
          sellPrice: product.netPrice ?? '',
          price: product.netPrice ?? 0,
          orderUom: product.pricingUom?.toLowerCase() || t('product.each')
        },
        lineItemSubtotal: format(qty * (product.netPrice ?? 0)),
        quantity: qty,
        uom: product.pricingUom?.toLowerCase() || t('product.each'),
        listIds: []
      } as ProductProps)
  );
}

//  Compiles products for release into a single list (merges qtyMap with contractProducts if qtyMap is passed through)
export function findProducts(
  contract?: ContractDetails,
  qtyMap?: Record<string, string>
) {
  if (!contract?.contractProducts?.length) {
    console.error('Failure to find products in contract!');
    return [];
  }
  if (qtyMap) {
    return Object.entries(qtyMap).map(([sequence, value], index) => {
      const foundProduct = contract.contractProducts!.find(
        ({ sequenceNumber }) => sequenceNumber === sequence
      );
      const qty = parseInt(value);
      const product = { ...foundProduct, index }; // Add "index" to prevent duplicate ID
      return { product, qty };
    });
  }
  return contract.contractProducts.map((product, index) => {
    const { quantityReleasedToDate: released, quantityOrdered: contract } =
      product?.qty || {}; // Use for all items qty logic
    const qty = Math.max((contract ?? 0) - (released ?? 0), 0);
    const modifiedProduct = { ...product, index }; // Add "index" to prevent duplicate ID
    return { product: modifiedProduct, qty };
  });
}

export function validateProducts(products: FoundProduct[]) {
  return products.filter(
    ({ product, qty }) => product && !product.displayOnly && qty
  );
}

// Used for adding additional products released to cart
const filterOutNull = (data: Maybe<ProductProps | LineItem>[]) =>
  data.filter((item) => Boolean(item)) as ProductProps[];
const compareId = (a: Maybe<ProductProps>, b: Maybe<ProductProps>) =>
  a?.id === b?.id;

export function mergeLineItems(
  oldLI: Maybe<ProductProps>[],
  newLI: Maybe<ProductProps>[]
) {
  const oldCleaned = filterOutNull(oldLI);
  const newCleaned = filterOutNull(newLI);

  const alreadyExistingItems = intersectionWith(
    newCleaned,
    oldCleaned,
    compareId
  ) as ProductProps[];
  const xorLineItems = xorWith(oldCleaned, newCleaned, compareId);

  const intersectLineItems = alreadyExistingItems.map((item) => {
    const index = findIndex(oldCleaned, (old) => old?.id === item.id);
    // istanbul ignore next
    item.quantity = (oldCleaned[index]?.quantity ?? 0) + (item?.quantity ?? 0);

    return item;
  });

  return [...xorLineItems, ...intersectLineItems];
}

export function deleteCartItemProductMapping(
  prev: Partial<CartProps> | undefined,
  data: DeleteItemResponse
) {
  return data.products.map((product, i) => ({
    id: product.id,
    erpPartNumber: product.erpPartNumber,
    customerPartNumber: product.customerPartNumber,
    quantity: product.quantity,
    uom: product.uom,
    product: {
      id: product.product.id,
      partNumber: product.product.partNumber,
      name: product.product.name,
      manufacturerName: product.product.manufacturerName,
      manufacturerNumber: product.product.manufacturerNumber,
      imageUrls: product.product.imageUrls,
      categories: prev?.products?.[i].product.categories ?? [],
      minIncrementQty: product.product.minIncrementQty
    },
    listIds: product.listIds,
    qty: product.quantity,
    productPricingDTO: {
      productId: prev?.products?.[i]?.productPricingDTO?.productId ?? '',
      catalogId: prev?.products?.[i]?.productPricingDTO?.catalogId ?? '',
      branchAvailableQty:
        prev?.products?.[i]?.productPricingDTO?.branchAvailableQty ?? 0,
      totalAvailableQty: product.qtyAvailable,
      sellPrice: product.pricePerUnit,
      orderUom: product.uom,
      cmp: product.product.cmp,
      listIds: product.listIds
    },
    pricePerUnit: 0,
    lineItemId: product.id,
    lineItemSubtotal: product.lineItemSubtotal,
    productAvailable: product.productAvailable
  }));
}
