// istanbul ignore file
import { useMemo, useCallback } from 'react';

import { Collapse, Hidden, Skeleton } from '@mui/material';
import { xorWith } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { Aggregate, SearchRequest } from 'API/types/search.types';
import { Button, Chip } from 'components';
import { ProductAttribute } from 'generated/graphql';
import { CloseIcon } from 'icons';
import SearchFilterList from 'pages/Search/sub/SearchFilters/SearchFilterList';
import { getSelectedFiltersArray } from 'pages/Search/util/searchFiltersUtil';
import useSearchQueryParams from 'pages/Search/util/useSearchQueryParams';
import { useAuthContext } from 'providers/AuthProvider';
import { useBranchContext } from 'providers/BranchProvider';
import repeatMap from 'utils/repeatMap';

/**
 * Types
 */
type Props = {
  filters: Aggregate[];
  loading: boolean;
  onChange: (b?: Partial<SearchRequest>) => void;
  onClear: () => void;
};

/**
 * Component
 */
function SearchFiltersContent(props: Props) {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();
  const [params, setParams] = useSearchQueryParams();
  const { categories = [], filters = [] } = params;

  /**
   * Context
   */
  const { shippingBranch } = useBranchContext();
  const { profile } = useAuthContext();

  /**
   * Memos
   */
  // 🔵 Memo - filters
  const selectedFilters = useMemo(
    () => getSelectedFiltersArray(filters),
    [filters]
  );

  /**
   * Callbacks
   */
  // 🟤 Cb - toggle
  const handleToggle = useCallback(
    (changedFilter: ProductAttribute) => {
      const normalizedAttribute: ProductAttribute = {
        attributeType: (changedFilter.attributeType ?? '').replace(/s$/, ''),
        attributeValue: changedFilter.attributeValue
      };
      if (/category\d/.test(changedFilter.attributeType ?? '')) {
        const depth = parseInt(changedFilter.attributeType?.slice(-1) ?? '1');
        const newCategories = categories.slice(0, depth - 1);
        props.onChange({ categories: newCategories, page: 1 });
        setParams({ ...params, page: '1', categories: newCategories });
      } else if (normalizedAttribute.attributeType === 'category') {
        // handle category add
        if (normalizedAttribute.attributeValue) {
          const newCategories = [
            ...categories,
            normalizedAttribute.attributeValue
          ];
          props.onChange({ categories: newCategories, page: 1 });
          setParams({ ...params, page: '1', categories: newCategories });
        }
      } else {
        // Handle filter change
        const rawFilters = xorWith(
          selectedFilters,
          [normalizedAttribute],
          (a, b) =>
            a?.attributeType === b?.attributeType &&
            a?.attributeValue === b?.attributeValue
        );
        const filters = rawFilters.map(
          (f) => `${f.attributeType}|${f.attributeValue}`
        );
        props.onChange({ filters, page: 1 });
        setParams({ ...params, page: '1', filters });
      }
    },
    [setParams, params, categories, selectedFilters, props]
  );
  // 🟤 Cb - reset
  const handleReset = () => {
    props.onChange({ categories: [], filters: [], page: 1 });
    props.onClear();
  };
  // 🟤 Cb - display
  const handleAttributeDisplay = useCallback(
    (attributeType: string) =>
      props.filters.find(
        ({ filterDisplay }) => filterDisplay.filterKey === attributeType
      )?.filterDisplay?.filterName ?? attributeType,
    [props.filters]
  );

  /**
   * Components
   */
  const filterList = useMemo(
    () =>
      props.filters.reduce<JSX.Element[]>((prev, filterGroup, index) => {
        const subfilters = filterGroup.individualAggregates ?? [];
        if (
          !(
            filterGroup.filterDisplay.filterName === 'Category' &&
            categories.length === 3
          ) &&
          !(
            filterGroup.filterDisplay.filterName === 'In Stock' &&
            !profile?.userId
          ) &&
          !(
            filterGroup.filterDisplay.filterName !== 'In Stock' &&
            !subfilters?.length
          ) &&
          (filterGroup.sortOrder ?? 0) >= 0
        ) {
          prev.push(
            <SearchFilterList
              filter={filterGroup.filterDisplay}
              expanded={filterGroup.expanded}
              subfilters={subfilters}
              loading={props.loading}
              skeletonTotal={categories.length ? 1 : 3}
              selectedFilters={selectedFilters}
              handleToggle={handleToggle}
              key={filterGroup.filterDisplay?.filterKey}
              index={index}
            />
          );
        }
        return prev;
      }, []),
    [
      categories.length,
      handleToggle,
      profile?.userId,
      props.filters,
      props.loading,
      selectedFilters
    ]
  );

  /**
   * Render
   */
  return (
    <div className="flex flex-col w-full">
      <Hidden mdUp>
        <Collapse in={filters.length > 0}>
          <div className="pt-2 pb-4 overflow-hidden">
            <p className="text-primary-1-100 text-base font-medium py-2 px-8">
              {t('common.filters')}
            </p>
            <div className="flex overflow-x-auto overflow-y-hidden px-6">
              {selectedFilters.map((selectedFilter) => (
                <div key={selectedFilter.attributeValue} className="p-2">
                  <Chip
                    avatar={<CloseIcon />}
                    onClick={() => handleToggle(selectedFilter)}
                    label={`${handleAttributeDisplay(
                      selectedFilter.attributeType
                    )}: ${
                      selectedFilter.attributeType === 'in_stock_location'
                        ? shippingBranch?.name
                        : selectedFilter.attributeValue
                    }`}
                    data-testid={`selected-fiter-${selectedFilter.attributeValue}`}
                    kind="stroke"
                  />
                </div>
              ))}
              <div className="w-6 flex-[1_0_auto]" />
            </div>
          </div>
        </Collapse>
      </Hidden>
      <Hidden mdDown>
        <Collapse in={Boolean(selectedFilters.length)}>
          <div className="px-10 py-6 flex flex-col border-t border-secondary-3-100">
            <div className="flex justify-between items-center pb-2 text-primary-1-100 text-sm font-medium">
              {t('search.selectedItem', {
                count: selectedFilters.length + categories.length
              })}
              <Button
                kind="text"
                color="lightBlue"
                size="sm"
                className="!text-caption font-normal"
                onClick={handleReset}
              >
                {t('search.clearAll')}
              </Button>
            </div>
            {selectedFilters.map((selectedFilter) => (
              <div
                className="grid grid-cols-[24px_100%] items-center px-1 py-2 cursor-pointer"
                key={selectedFilter?.attributeValue}
                onClick={() => handleToggle(selectedFilter as ProductAttribute)}
                data-testid={`selected-fiter-${selectedFilter.attributeValue}`}
              >
                <CloseIcon className="mr-3 w-4 h-4 text-primary-1-100" />
                <span className="text-caption">
                  {`${handleAttributeDisplay(selectedFilter.attributeType)}: ${
                    selectedFilter.attributeType === 'in_stock_location'
                      ? shippingBranch?.name
                      : selectedFilter?.attributeValue
                  }`}
                </span>
              </div>
            ))}
          </div>
        </Collapse>
      </Hidden>
      {!props.filters &&
        repeatMap(2, (i) => (
          <Skeleton
            key={i}
            variant="rectangular"
            className="w-full mb-1 h-14"
          />
        ))}
      {filterList}
    </div>
  );
}
export default SearchFiltersContent;
