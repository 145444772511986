import Card from 'components/Card';
import CheckboxInput from 'components/CheckboxInput';

/**
 * Config
 */
const display = { title: 'Checkbox', item: 'Test item' };

/**
 * Component
 */
function CheckboxPage() {
  return (
    <Card className="my-8 p-6">
      <h5 className="text-2xl font-bold text-secondary-2-100">
        {display.title}
      </h5>
      <hr className="my-4" />
      <div className="my-4 ">
        <div className="flex flex-col items-center">
          <CheckboxInput />
          <CheckboxInput label={display.item} />
          <CheckboxInput label={display.item} disabled checked />
          <CheckboxInput label={display.item} disabled checked={false} />
          <CheckboxInput label={display.item} color="lightBlue" />
          <CheckboxInput label={display.item} color="gray" />
          <CheckboxInput label={display.item} color="yellow" />
          <CheckboxInput label={display.item} color="green" />
          <CheckboxInput label={display.item} color="red" />
        </div>
      </div>
    </Card>
  );
}

export default CheckboxPage;
