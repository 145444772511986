import { useTranslation } from 'react-i18next';

import { BrandCardProps } from 'pages/Brands/BrandCard';
import arcoImage from 'pages/Brands/images/arco-hero-image.png';
import universalRundleImage from 'pages/Brands/images/universalrundle-hero-image.png';
import poshImage from 'pages/Brands/images/posh-hero-image.jpg';
import bpressImage from 'pages/Brands/images/bpress-hero-image.jpg';
import bigDogImage from 'pages/Brands/images/bigdog-hero-image.jpg';
import kadenImage from 'pages/Brands/images/kaden-hero-image.png';
import zusterImage from 'pages/Brands/images/zuster-hero-image.jpg';

/**
 * Hook
 */
function useBrandList() {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();
  /**
   * Output
   */
  const output: BrandCardProps[] = [
    {
      thumbnail: arcoImage,
      title: t('brands.brandCardArco.title'),
      description: t('brands.brandCardArco.description'),
      url: '/brands/arco-brand',
      isContained: false,
      isCentered: false
    },
    {
      thumbnail: universalRundleImage,
      title: t('brands.brandCardUniversalRundle.title'),
      description: t('brands.brandCardUniversalRundle.description'),
      url: 'brands/universal-rundle',
      isContained: false,
      isCentered: true
    },
    {
      thumbnail: poshImage,
      title: t('brands.brandCardPosh.title'),
      description: t('brands.brandCardPosh.description'),
      url: 'brands/posh',
      isContained: false,
      isCentered: false
    },
    {
      thumbnail: bpressImage,
      title: t('brands.brandCardBpress.title'),
      description: t('brands.brandCardBpress.description'),
      url: 'https://reece.conexbanninger.com/',
      isContained: true,
      isCentered: true
    },
    {
      thumbnail: bigDogImage,
      title: t('brands.brandCardBigDog.title'),
      description: t('brands.brandCardBigDog.description'),
      url: 'brands/bigdog',
      isContained: false,
      isCentered: false
    },
    {
      thumbnail: kadenImage,
      title: t('brands.brandKaden.title'),
      description: t('brands.brandKaden.description'),
      url: 'https://www.kadenair.com/ ',
      isContained: false,
      isCentered: true,
      shouldOpenInNewTab: true
    },
    {
      thumbnail: zusterImage,
      title: t('brands.brandZuster.title'),
      description: t('brands.brandZuster.description'),
      url: 'https://reecebathandkitchen.com/collections/Zuster',
      isContained: false,
      isCentered: true,
      shouldOpenInNewTab: true
    }
  ];
  return output;
}
export default useBrandList;
