import { Trans } from 'react-i18next';

import { ToastConfig } from 'components/Toast';
import baseI18nComponents from 'locales/baseComponents';
import { SelectedListsMap } from 'providers/ListsProvider';

/**
 * Config
 */
export const MAX_LIST_ITEMS = 600;
export const MAX_LIST_API_ERROR_CODE = '422 UNPROCESSABLE_ENTITY';

/**
 * Types
 */
export type AddProductToListErrorProps = {
  lists: SelectedListsMap;
  openAddToListDrawer: () => void;
  resolve: (value: boolean | PromiseLike<boolean>) => void;
  test?: jest.Mock;
  toast: (config: ToastConfig) => void;
};

/**
 * Function
 */
export function addProductToListError(props: AddProductToListErrorProps) {
  // error type is any due the type assigned for .throw
  return (error: any) => {
    if (error.code === MAX_LIST_API_ERROR_CODE) {
      const maxedOutLists = Object.values(props.lists).reduce<string[]>(
        (prev, list) => {
          if ((list.listLineItemsSize ?? 0) >= MAX_LIST_ITEMS) {
            prev.push(list.name);
          }
          return prev;
        },
        []
      );
      const ExceededLists = (
        <ul>
          {maxedOutLists.map((list, idx) => (
            <li key={idx}>• {list}</li>
          ))}
        </ul>
      );
      props.toast({
        message: (
          <Trans
            i18nKey="lists.addToListError_limitExceeded"
            components={{ ...baseI18nComponents, ExceededLists }}
          />
        ),
        kind: 'error'
      });
      props.test?.('addProductToListError_422');
    } else {
      props.toast({
        message: <Trans i18nKey="lists.addToListError" />,
        kind: 'error'
      });
      props.test?.('addProductToListError_normal');
    }
    props.openAddToListDrawer();
    props.resolve(false);
  };
}
