import { Dialog, DialogContent } from '@mui/material';

import { Button, IconButton } from 'components';
import { CloseIcon } from 'icons';
import decodeHTMLEntities from 'utils/decodeHTMLEntities';

/**
 * Tyoes
 */
export type CommonAlertProps = {
  title: string;
  message: string;
  confirmBtnTitle?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  cancelBtnTitle?: string;
  open: boolean;
};

/**
 * Component
 */
function CommonAlert(props: CommonAlertProps) {
  /**
   * Render
   */
  return (
    <Dialog open={props.open} onClose={props.onCancel} maxWidth="sm" fullWidth>
      <div className="flex justify-between" data-testid="alert-title">
        <h5 className="mt-4 ml-6 text-xl text-primary-1-100 font-medium">
          {decodeHTMLEntities(props.title)}
        </h5>
        <IconButton onClick={props.onCancel} size="large">
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent data-testid="alert-content">
        <p className="pb-6 text-base text-primary-1-100">
          {decodeHTMLEntities(props.message)}
        </p>
        <div className="flex justify-end pt-4 pb-2 md:flex-col-reverse">
          <Button
            kind="text"
            data-testid="alert-cancel-button"
            onClick={props.onCancel}
            color="lightBlue"
            className="underline mr-4"
          >
            {props.cancelBtnTitle}
          </Button>
          {Boolean(props.confirmBtnTitle) && (
            <Button
              onClick={props.onConfirm}
              kind="fill"
              color="primary"
              data-testid="alert-confirm-button"
            >
              {props.confirmBtnTitle}
            </Button>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default CommonAlert;
