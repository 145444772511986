import Card from 'components/Card';
import NumberInput from 'components/NumberInput';

/**
 * Config
 */
const display = {
  title: 'NumberInput'
};

/**
 * Component
 */
function NumberInputPage() {
  return (
    <Card className="my-8 p-6">
      <h5 className="text-2xl font-bold text-secondary-2-100">
        {display.title}
      </h5>
      <hr className="my-4" />
      <div className="my-4 flex flex-col justify-cente gap-2">
        <NumberInput size="xs" />
        <NumberInput size="sm" />
        <NumberInput size="md" />
        <NumberInput size="lg" />
      </div>
      <div className="my-4 flex flex-col justify-center gap-2">
        <NumberInput fullWidth size="xs" />
        <NumberInput fullWidth size="sm" />
        <NumberInput fullWidth size="md" />
        <NumberInput fullWidth size="lg" />
        <NumberInput fullWidth size="lg" disabled />
      </div>
    </Card>
  );
}

export default NumberInputPage;
