import { Fragment, useMemo } from 'react';

import {
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Hidden,
  RadioGroup,
  TextField
} from '@mui/material';
import { Autocomplete, GoogleMap } from '@react-google-maps/api';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Button, Loader, Pagination } from 'components';
import { MileRadiusEnum } from 'generated/graphql';
import useDocumentTitle from 'hooks/useDocumentTitle';
import useScreenSize from 'hooks/useScreenSize';
import { GpsIcon } from 'icons';
import useLocationSearchLogics from 'pages/LocationSearch/lib/useLocationSearchLogics';
import useMapMarkers from 'pages/LocationSearch/lib/useMapMarkers';
import 'pages/LocationSearch/overrideStyles.css';
import LocationCard from 'pages/LocationSearch/sub/LocationCard';
import Popover from 'pages/LocationSearch/sub/Popover';
import LocationCardLoading from 'pages/LocationSearch/sub/LocationCardLoading';
import { Divisions } from 'providers/BranchProvider';
import { radio } from 'utils/inputTestId';

/**
 * Config
 */
export const PAGE_SIZE = 20;
const NA_CENTER = { LATITUDE: 39.5, LONGITUDE: -98.35 };

/**
 * Component
 */
function LocationSearch() {
  /**
   * Custom hooks
   */
  const { isSmallScreen } = useScreenSize();
  const { t } = useTranslation();
  useDocumentTitle(t('common.findALocation'));
  const {
    branches,
    branchesCalled,
    branchesLoading,
    distance,
    filter,
    filteredData,
    googleLoaded,
    handleApplyFilter,
    handleAutocompleteFocus,
    handleDistanceChange,
    handlePlaceChanged,
    homeBranchLoading,
    inputEl,
    inputElCallback,
    inputError,
    isMapView,
    latitude,
    longitude,
    onMapLoad,
    page,
    positionLoading,
    refResults,
    selectedBranch,
    setAutocomplete,
    setIsMapView,
    setPage,
    setPlace,
    setSelectedBranch
  } = useLocationSearchLogics();

  /**
   * Memo
   */
  // 🔵 memo - Location type options for select input
  const divisionOptions = useMemo(
    () =>
      Object.values(Divisions).map((f) => (
        <FormControlLabel
          value={f}
          key={`branch-filter-select-${f}`}
          data-testid={`${f}-radio-list-item`}
          control={radio('filter')}
          className="whitespace-nowrap"
          label={`${t(`locationSearch.${f}`)}`}
        />
      )),
    [t]
  );
  // 🔵 memo - Result list
  const resultList = useMemo(
    () =>
      filteredData
        .map((b, i, array) => (
          <Fragment key={`branch-result-card-filtered-${b.branchId}`}>
            <div className="py-6 pr-2">
              <LocationCard
                index={i + 1}
                branch={b}
                updateSelectedBranch={setSelectedBranch}
              />
            </div>
            {i % PAGE_SIZE < PAGE_SIZE - 1 && i < array.length - 1 && (
              <Divider />
            )}
          </Fragment>
        ))
        .slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE),
    [filteredData, page, setSelectedBranch]
  );
  // 🔵 memo - Google Map Markers
  const markerMemo = useMapMarkers({
    filteredData,
    googleLoaded,
    selectedBranch,
    setSelectedBranch
  });

  /**
   * Render
   */
  if (!googleLoaded) {
    return null;
  }
  return (
    <div className="bg-common-white">
      <Container data-testid="location-search-container">
        <div className="flex md:h-auto md:flex-col">
          {/* ===== Left side ===== */}
          <div className="max-w-[41.666667%] max-h-[75vh] basis-full px-2 overflow-y-scroll md:max-w-full">
            <div className="flex flex-col flex-nowrap gap-4 h-full pr-10 md:h-auto">
              {/* ----- Titles ----- */}
              <h1
                className="pt-8 text-primary-1-100 text-2xl font-medium"
                data-testid="branch-locations-title"
              >
                {t('locationSearch.header')}
              </h1>
              <p className="text-sm" data-testid="location-search-helpText">
                {t('locationSearch.helpText')}
              </p>
              {/* ----- Search ----- */}
              <Autocomplete
                onLoad={(test) => setAutocomplete(test)}
                onPlaceChanged={handlePlaceChanged}
                types={['postal_code', 'locality', 'sublocality']}
                fields={['geometry']}
                restrictions={{ country: ['us'] }}
              >
                <TextField
                  label={t('locationSearch.searchLabel')}
                  placeholder={t('locationSearch.searchPlaceholder')}
                  data-testid="location-search-input"
                  fullWidth
                  onFocus={handleAutocompleteFocus}
                  error={inputError}
                  helperText={inputError && t('locationSearch.zipCityInvalid')}
                  inputRef={inputElCallback}
                  InputProps={{
                    startAdornment: (
                      <div
                        className={clsx('pr-2', {
                          'text-etc-light-gray':
                            inputEl?.value !== t('locationSearch.current'),
                          'text-primary-2-100':
                            inputEl?.value === t('locationSearch.current')
                        })}
                      >
                        <GpsIcon />
                      </div>
                    ),
                    endAdornment: (
                      <Button
                        kind="text"
                        color="lightBlue"
                        onClick={() => {
                          setPlace(undefined);
                          setSelectedBranch(undefined);
                          inputEl && (inputEl.value = '');
                          inputEl?.focus();
                        }}
                        data-testid="clear-button"
                      >
                        {t('common.clear').toLocaleLowerCase()}
                      </Button>
                    )
                  }}
                />
              </Autocomplete>
              {/* ----- Location Types ----- */}
              <div className="grid grid-cols-3 gap-4">
                <div className="col-span-3 w-full pb-4 md:col-span-2">
                  <p
                    data-testid="location-types-label"
                    className="text-base pb-2"
                  >
                    {t('locationSearch.locationTypesLabel')}
                  </p>
                  <FormControl variant="outlined" className="!w-auto" fullWidth>
                    <RadioGroup
                      aria-label="location-types-label"
                      name="locationtypeslabel"
                      value={filter}
                      onChange={handleApplyFilter}
                      className="flex justify-start md:justify-around"
                      data-testid="location-types-label"
                    >
                      <div className="grid grid-cols-2 gap-x-4 gap-y-2 ml-2 md:grid-cols-1">
                        {divisionOptions}
                      </div>
                    </RadioGroup>
                  </FormControl>
                </div>
                <Hidden mdUp>
                  <div className="col-span-1 flex items-end">
                    <Button
                      fullWidth
                      kind="outline"
                      color="primary"
                      onClick={() => setIsMapView(!isMapView)}
                      className="mb-[18px] h-[45px]"
                      data-testid="map-button"
                    >
                      {isMapView
                        ? t('locationSearch.listView')
                        : t('locationSearch.mapView')}
                    </Button>
                  </div>
                </Hidden>
              </div>
              {/* ----- Distance ----- */}
              <div className="-mt-4">
                <p className="text-base pb-2">{t('locationSearch.distance')}</p>
                <FormControl component="fieldset" className="w-auto">
                  <RadioGroup
                    aria-label="distance-radius"
                    name="distanceRadius"
                    value={distance}
                    onChange={handleDistanceChange}
                    data-testid="distance-radius-options"
                    className="flex justify-start md:justify-around"
                  >
                    <div className="grid grid-cols-2 gap-x-4 gap-y-2 ml-2 pr-16 md:grid-cols-1">
                      <FormControlLabel
                        value={MileRadiusEnum.Miles_25}
                        control={radio('distance')}
                        label={`${t('locationSearch.25Miles')}`}
                      />
                      <FormControlLabel
                        value={MileRadiusEnum.Miles_50}
                        control={radio('distance')}
                        label={`${t('locationSearch.50Miles')}`}
                        data-testid="distance-radius-50miles"
                      />
                      <FormControlLabel
                        value={MileRadiusEnum.Miles_100}
                        control={radio('distance')}
                        label={`${t('locationSearch.100Miles')}`}
                      />
                      <FormControlLabel
                        value={MileRadiusEnum.Miles_200}
                        control={radio('distance')}
                        label={`${t('locationSearch.200Miles')}`}
                      />
                    </div>
                  </RadioGroup>
                </FormControl>
              </div>
              {/* ----- Results List ----- */}
              {branchesCalled && (!isSmallScreen || !isMapView) && (
                <>
                  <p className="text-base" data-testid="result-count">
                    {`${filteredData.length} `}
                    {t('common.result', { count: filteredData.length })}
                  </p>
                  <Divider />
                  <div data-testid="location-cards-section" ref={refResults}>
                    {
                      // Loading
                      branchesLoading ||
                      positionLoading ||
                      homeBranchLoading ? (
                        <LocationCardLoading />
                      ) : // List
                      filteredData.length ? (
                        resultList
                      ) : (
                        // No results
                        <h6
                          className="py-6 text-secondary-3-100 text-lg text-center font-medium"
                          data-testid="no-results-message"
                        >
                          {t('locationSearch.noResults')}
                        </h6>
                      )
                    }
                  </div>
                  {Boolean(page && filteredData.length) && (
                    <>
                      <Divider />
                      <div className="flex justify-between items-center pb-4">
                        <p
                          className="text-base"
                          data-testid="record-count-footer"
                        >
                          {`${filteredData.length} `}
                          {t('common.result', { count: filteredData.length })}
                        </p>
                        <Pagination
                          current={page}
                          count={Math.ceil(filteredData.length / PAGE_SIZE)}
                          ofText={t('common.of')}
                          onChange={setPage}
                          onNext={setPage}
                          onPrev={setPage}
                          testId="pagination"
                        />
                      </div>
                    </>
                  )}
                  <Hidden mdUp>
                    <div className="h-10" />
                  </Hidden>
                </>
              )}
            </div>
          </div>
          {/* Google Maps */}
          {(!isSmallScreen || isMapView) && (
            <div
              className="max-w-[58.333333%] basis-full md:max-w-full md:py-4"
              data-testid="gmaps-wrapper"
            >
              <GoogleMap
                options={{ streetViewControl: false, clickableIcons: false }}
                onLoad={onMapLoad}
                mapContainerClassName="w-full h-[75vh] md:h-[60vh]"
                center={{
                  lat: selectedBranch?.branch?.latitude
                    ? selectedBranch.branch.latitude
                    : latitude || NA_CENTER.LATITUDE,
                  lng: selectedBranch?.branch?.longitude
                    ? selectedBranch.branch.longitude
                    : longitude || NA_CENTER.LONGITUDE
                }}
                zoom={!branches.length ? 4 : 10}
              >
                {(branchesLoading || positionLoading || homeBranchLoading) && (
                  <Loader backdrop size="parent" />
                )}
                {Boolean(selectedBranch?.latLng) && (
                  <Popover
                    branch={selectedBranch!.branch}
                    position={selectedBranch!.latLng!}
                    onCloseClick={() => setSelectedBranch(undefined)}
                  />
                )}
                {markerMemo}
              </GoogleMap>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
}

export default LocationSearch;
