import { Fragment } from 'react';

import { Divider } from '@mui/material';

import { PAGE_SIZE } from 'pages/LocationSearch';
import LocationCard from 'pages/LocationSearch/sub/LocationCard';
import repeatMap from 'utils/repeatMap';

/**
 * Config
 */
export const LOADING_SIZE = 3;

/**
 * Component
 */
function LocationCardLoading() {
  /**
   * Render
   */
  return (
    <>
      {repeatMap(LOADING_SIZE, (i) => (
        <Fragment key={`branch-result-card-loading-${i}`}>
          <div className="py-6 pr-2">
            <LocationCard loading index={i + 1} />
          </div>
          {i % PAGE_SIZE < PAGE_SIZE - 1 && i < LOADING_SIZE - 1 && <Divider />}
        </Fragment>
      ))}
    </>
  );
}

export default LocationCardLoading;
