import { useState } from 'react';

import { Lightbulb } from '@mui/icons-material';

import AdvancedToolTip from 'components/AdvancedToolTip';
import Card from 'components/Card';

/**
 * Config
 */
const display = {
  title: 'AdvancedToolTip',
  hover: 'Hover to show tooltip',
  tooltipTitle: 'Test',
  tooltipText: 'This is a tooltip'
};

/**
 * Component
 */
function AdvancedToolTipPage() {
  /**
   * States
   */
  const [on, setOn] = useState(false);
  /**
   * Render
   */
  return (
    <Card className="my-8 p-6">
      <h5 className="text-2xl font-bold text-secondary-2-100">
        {display.title}
      </h5>
      <hr className="my-4" />
      <div className="my-4 flex justify-center">
        <AdvancedToolTip
          title={display.tooltipTitle}
          text={display.tooltipText}
          icon={<Lightbulb />}
          placement="bottom"
          activated={on}
          testId="advancedtooltip-test"
        >
          <span
            className="px-4 py-2 bg-secondary-2-100 text-common-white rounded-full"
            onMouseEnter={() => setOn(true)}
            onMouseLeave={() => setOn(false)}
            data-testid="component-page_tooltip_hover"
          >
            {display.hover}
          </span>
        </AdvancedToolTip>
      </div>
    </Card>
  );
}

export default AdvancedToolTipPage;
