import { useMemo } from 'react';

import { AccordionDetails, AccordionSummary } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ProductDetails } from 'API/types/products.types';
import { Accordion } from 'components';
import useScreenSize from 'hooks/useScreenSize';
import { ArrowDropDownIcon } from 'icons';
import { useProductPageContext } from 'pages/Product/ProductPageProvider';

/**
 * Types
 */
type ProductCodeData = {
  label: string;
  value: string;
  key: keyof ProductDetails;
};

/**
 * Component
 */
function ProductCodes() {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();
  const { isSmallScreen } = useScreenSize();

  /**
   * Context
   */
  const { product } = useProductPageContext();

  /**
   * Memo
   */
  // 🔵 memo - main data
  const data = useMemo(() => {
    const list: ProductCodeData[] = [];
    // id
    product?.productMSC &&
      list.push({
        label: t('product.itemNumber'),
        value: product.productMSC,
        key: 'productId'
      });
    // upc
    product?.upc &&
      list.push({ label: t('product.upc'), value: product.upc, key: 'upc' });
    // unspc
    product?.unspsc &&
      list.push({
        label: t('product.unspc'),
        value: product.unspsc,
        key: 'unspsc'
      });
    // series
    product?.seriesModelFigureNumber &&
      list.push({
        label: t('product.series'),
        value: product?.seriesModelFigureNumber,
        key: 'seriesModelFigureNumber'
      });
    return list;
  }, [product, t]);

  /**
   * Render
   */
  // 🟩 Empty
  if (!data.length) {
    return null;
  }
  // 🟩 Desktop
  if (!isSmallScreen) {
    return (
      <div
        className="pt-6 border-secondary-3-100 border-t"
        data-testid="pdp-codes-desktop"
      >
        <h5
          className="text-primary-1-100 text-xl font-medium"
          data-testid="additional-product-codes"
        >
          {t('product.productCodes')}
        </h5>
        <div className="flex gap-2">
          {data.map((item, i) => (
            <div
              key={`product-codes-${i}`}
              data-testid={`product-code-item-${item.key}`}
              className="basis-1/2 py-4 text-secondary-2-100"
            >
              <div className="flex flex-col justify-between w-full text-sm text-secondary-2-100">
                <b>{item.label}</b>
                <span>{item.value}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
  // 🟩 Mobile
  return (
    <Accordion type="product" data-testid="pdp-codes-mobile">
      <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
        {t('product.productCodes')}
      </AccordionSummary>
      <AccordionDetails>
        <ul className="w-full list-none px-6 py-2">
          {data.map((item, i) => (
            <li
              key={`product-codes-${i}`}
              data-testid={`product-code-item-${item.key}`}
              className={clsx(
                'flex justify-start items-center w-full py-4 border-secondary-3-100 hover:bg-secondary-4-100',
                { 'border-b': i < data.length - 1 }
              )}
            >
              <div className="flex justify-between w-full text-sm text-secondary-2-100">
                <span className="pr-2 font-medium">{item.label}</span>
                <span className="pl-2 font-normal">{item.value}</span>
              </div>
            </li>
          ))}
        </ul>
      </AccordionDetails>
    </Accordion>
  );
}

export default ProductCodes;
