import { ChangeEvent } from 'react';

import { OnInputChange } from '@reece/global-types';

export default function updateZipcode(
  updateForm: (e: ChangeEvent<any>) => void
) {
  return (e: OnInputChange) => {
    e.target.value = filterZipCode(e.target.value);
    updateForm(e);
  };
}

export const filterZipCode = (value: string) =>
  value.replace(/\D/g, '').slice(0, 5);
