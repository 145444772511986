import { useMemo } from 'react';

import { AccordionDetails, AccordionSummary } from '@mui/material';
import clsx from 'clsx';
import { chunk } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { Accordion } from 'components';
import useScreenSize from 'hooks/useScreenSize';
import { ArrowDropDownIcon } from 'icons';
import { useProductPageContext } from 'pages/Product/ProductPageProvider';

/**
 * Component
 */
function TechnicalSpecifications() {
  /**
   * Custom Hooks
   */
  const { isSmallScreen } = useScreenSize();
  const { t } = useTranslation();

  /**
   * Context
   */
  const { product } = useProductPageContext();
  /**
   * Memo
   */
  // 🔵 memo - data
  const data = useMemo(
    () => product?.techSpecifications ?? [],
    [product?.techSpecifications]
  );
  // 🔵 memo - desktop columns
  const [columnLeft, columnRight] = useMemo(
    () => chunk(data, Math.ceil(data.length / 2)),
    [data]
  );
  /**
   * Render
   */
  // 🟩 Empty
  if (!data.length) {
    return null;
  }
  // 🟩 Desktop
  if (!isSmallScreen) {
    return (
      <div
        className="flex flex-nowrap gap-20"
        data-testid="pdp-techspecs-desktop"
      >
        <div className="basis-1/2">
          <table className="w-full inline-table text-primary-3-100 text-base">
            <tbody>
              {columnLeft.map((item, i) => (
                <tr
                  className={clsx('align-middle', {
                    '[&_td]:border-b [&_td]:border-secondary-3-100':
                      i < columnLeft.length - 1
                  })}
                  key={item.name || `tech-specs-item-${i}`}
                >
                  <td className="pr-3 py-4 font-bold">{item.name}</td>
                  <td className="pl-3 py-4 ">{item?.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="basis-1/2">
          <table className="w-full inline-table text-primary-3-100 text-base">
            <tbody>
              {columnRight &&
                columnRight.map((item, i) => (
                  <tr
                    className={clsx('align-middle', {
                      '[&_td]:border-b [&_td]:border-secondary-3-100':
                        i < columnRight.length - 1
                    })}
                    key={item.name || `tech-specs-item-${i}`}
                  >
                    <td className="pr-3 py-4 font-bold">{item.name}</td>
                    <td className="pl-3 py-4 ">{item?.value}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
  // 🟩 Mobile
  return (
    <Accordion type="product" data-testid="pdp-techspecs-mobile">
      <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
        {t('product.techSpecs')}
      </AccordionSummary>
      <AccordionDetails>
        <ul className="w-full list-none px-6 py-2">
          {data.map((item, i) => (
            <li
              key={`product-tech-specs-${i}`}
              className={clsx(
                'flex justify-start items-center w-full py-4 border-secondary-3-100 hover:bg-secondary-4-100',
                { 'border-b': i < data.length - 1 }
              )}
            >
              <p className="text-sm text-primary-3-100">
                <b>{item.name} :</b>
                {item.value}
              </p>
            </li>
          ))}
        </ul>
      </AccordionDetails>
    </Accordion>
  );
}

export default TechnicalSpecifications;
