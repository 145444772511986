import { APIOptions } from 'API/hooks/useApiBase';
import { UseAPILazyProps } from 'API/hooks/useApiLazy';
import { useApiLazyAuth } from 'API/hooks/useApiLazyAuth';
import { makeApiOnMountAuth } from 'API/lib/makeApi';
import {
  AddProductsToListRequest,
  CreateListRequest,
  UpdateListRequest,
  CreateListsResponse,
  DeleteListResponse,
  DeleteProductListResponse,
  DownloadListCSVRequest,
  DownloadListPDFRequest,
  GetListDetailsResponse,
  GetListsResponse,
  UpdateListResponse,
  UploadListCSVRequest,
  UploadListCSVResponse,
  ProductsIds,
  ListsSortResponse,
  ListsSortRequest,
  ProductsListsResponse,
  RemoveProductsListsRequest,
  GetAllListNamesResponse,
  ListInfoRequest
} from 'API/types/lists.types';
import { generateQueryParam } from 'utils/generateQueryParam';
/**
 * Config
 */
const BASE_URL = 'lists';

/**
 * Hooks
 */
// 🟦 GET /lists - Get all lists
export const useApiGetLists = makeApiOnMountAuth<GetListsResponse>({
  url: BASE_URL,
  kind: 'get',
  header: {}
});

// 🟦 GET /lists - Get all lists names
export const useApiGetAllListNames =
  makeApiOnMountAuth<GetAllListNamesResponse>({
    url: `${BASE_URL}/names`,
    kind: 'get',
    header: {}
  });

// 🟦 GET /lists/{listId} - Get specific list
export function useApiGetListDetails(
  options: APIOptions<GetListDetailsResponse> = {}
) {
  const props: UseAPILazyProps<GetListDetailsResponse> = {
    url: '',
    kind: 'get',
    options,
    header: {}
  };
  const api = useApiLazyAuth<GetListDetailsResponse>(props);
  const call = async (listId: string) =>
    await api.call({ ...props, url: `${BASE_URL}/${listId}` });
  return { ...api, call };
}

// 🟦 GET /lists/pdf/{listId} - Export a list in pdf
export function useApiPrintList(options: APIOptions<BlobPart> = {}) {
  const props: UseAPILazyProps<BlobPart> = {
    url: '',
    kind: 'get',
    options: { ...options, responseType: 'blob' },
    header: {}
  };
  const api = useApiLazyAuth<BlobPart>(props);
  const call = async (listId: string, params: DownloadListPDFRequest) =>
    await api.call({
      ...props,
      url: generateQueryParam(`${BASE_URL}/pdf/${listId}`, params)
    });
  return { ...api, call };
}

// 🟦 GET /lists/list/{searchTerm} - Get lists by search term
export function useApiGetListsBySearchTerm(
  options: APIOptions<GetListsResponse> = {}
) {
  const props: UseAPILazyProps<GetListsResponse> = {
    url: '',
    kind: 'get',
    options,
    header: {}
  };
  const api = useApiLazyAuth<GetListsResponse>(props);
  const call = async (searchTerm: string) =>
    await api.call({ ...props, url: `${BASE_URL}/search/${searchTerm}` });
  return { ...api, call };
}

// 🟦 GET /lists/template - Get list template
export function useApiGetListsTemplate(options: APIOptions<string> = {}) {
  const props: UseAPILazyProps<string> = {
    url: `${BASE_URL}/template`,
    kind: 'get',
    options,
    header: {}
  };
  const api = useApiLazyAuth<string>(props);
  const call = async () => await api.call({ ...props });
  return { ...api, call };
}

// 🟩 POST /lists/pdf/barcode/{listId} - Export a list in pdf with barcodes
export function useApiPrintListBarcode(options: APIOptions<BlobPart> = {}) {
  const props: UseAPILazyProps<BlobPart> = {
    url: '',
    kind: 'post',
    options: { ...options, responseType: 'blob' },
    header: {}
  };
  const api = useApiLazyAuth<BlobPart>(props);
  const call = async (
    listId: string,
    params: DownloadListPDFRequest,
    body: ProductsIds
  ) =>
    await api.call({
      ...props,
      url: generateQueryParam(`${BASE_URL}/pdf/barcode/${listId}`, params),
      body
    });
  return { ...api, call };
}

// 🟩 POST /lists - Create a new list
export function useApiCreateList(
  options: APIOptions<CreateListsResponse> = {}
) {
  const props: UseAPILazyProps<CreateListsResponse> = {
    url: BASE_URL,
    kind: 'post',
    options,
    header: {}
  };
  const api = useApiLazyAuth<CreateListsResponse>(props);
  const call = async (body: CreateListRequest) =>
    await api.call({ ...props, body });
  return { ...api, call };
}

// 🟩 POST /lists/{listId} - Duplicate a list
export function useApiDuplicateList(
  options: APIOptions<UpdateListResponse> = {}
) {
  const props: UseAPILazyProps<UpdateListResponse> = {
    url: '',
    kind: 'post',
    options,
    header: {}
  };
  const api = useApiLazyAuth<UpdateListResponse>(props);
  const call = async (listId: string, body: UpdateListRequest) =>
    await api.call({ ...props, url: `${BASE_URL}/${listId}`, body });
  return { ...api, call };
}

// 🟩 POST /lists/csv/{listId} - Download list as CSV
export function useApiDownloadListCSV(options: APIOptions<BlobPart> = {}) {
  const props: UseAPILazyProps<BlobPart> = {
    url: '',
    kind: 'post',
    options,
    header: {}
  };
  const api = useApiLazyAuth<BlobPart>(props);
  const call = async (listId: string, params: DownloadListCSVRequest) => {
    const url = generateQueryParam(`${BASE_URL}/csv/${listId}`, params);
    return await api.call({ ...props, url });
  };

  return { ...api, call };
}

// 🟩 POST /lists/search - Get lists ids associated to an array of products
export function useApiGetAssociatedLists(
  options: APIOptions<ProductsListsResponse> = {}
) {
  const props: UseAPILazyProps<ProductsListsResponse> = {
    url: `${BASE_URL}/search`,
    kind: 'post',
    options,
    header: {}
  };
  const api = useApiLazyAuth<ProductsListsResponse>(props);
  const call = async (body: ProductsIds) => await api.call({ ...props, body });
  return { ...api, call };
}

// 🟩 POST /lists/upload - Upload CSV to a new list
export function useApiUploadNewListCSV(
  options: APIOptions<UploadListCSVResponse> = {}
) {
  const props: UseAPILazyProps<UploadListCSVResponse, UploadListCSVRequest> = {
    url: '',
    kind: 'post',
    options,
    header: {},
    upload: true
  };
  const api = useApiLazyAuth<UploadListCSVResponse, UploadListCSVRequest>(
    props
  );
  const call = async (params: ListInfoRequest, body: UploadListCSVRequest) =>
    await api.call({
      ...props,
      url: generateQueryParam(`${BASE_URL}/upload`, params),
      body
    });
  return { ...api, call };
}

// 🟧 PUT /lists/{listId} - Update a list
export function useApiUpdateList(options: APIOptions<UpdateListResponse> = {}) {
  const props: UseAPILazyProps<UpdateListResponse> = {
    url: '',
    kind: 'put',
    options,
    header: {}
  };
  const api = useApiLazyAuth<UpdateListResponse>(props);
  const call = async (listId: string, body: UpdateListRequest) =>
    await api.call({ ...props, url: `${BASE_URL}/${listId}`, body });
  return { ...api, call };
}

// 🟧 PUT /lists/ - Add products to list
export function useApiAddProductsToList(
  options: APIOptions<UpdateListResponse> = {}
) {
  const props: UseAPILazyProps<UpdateListResponse> = {
    url: BASE_URL,
    kind: 'put',
    options,
    header: {}
  };
  const api = useApiLazyAuth<UpdateListResponse>(props);
  const call = async (body: AddProductsToListRequest) =>
    await api.call({ ...props, body });
  return { ...api, call };
}

// 🟧 PUT /lists/upload/{listId} - Upload CSV to list
export function useApiUploadListCSV(
  options: APIOptions<UploadListCSVResponse> = {}
) {
  const props: UseAPILazyProps<UploadListCSVResponse, UploadListCSVRequest> = {
    url: '',
    kind: 'put',
    options,
    header: {},
    upload: true
  };
  const api = useApiLazyAuth<UploadListCSVResponse, UploadListCSVRequest>(
    props
  );
  const call = async (listId: string, body: UploadListCSVRequest) =>
    await api.call({ ...props, url: `${BASE_URL}/upload/${listId}`, body });
  return { ...api, call };
}

// 🟧 PUT /lists/sort - Sort lists (Drag-n-drop) (for list products, see useApiUpdateList)
export function useApiSortLists(options: APIOptions<ListsSortResponse> = {}) {
  const props: UseAPILazyProps<ListsSortResponse, ListsSortRequest> = {
    url: '',
    kind: 'put',
    options,
    header: {}
  };
  const api = useApiLazyAuth<ListsSortResponse, ListsSortRequest>(props);
  const call = async (body: ListsSortRequest) =>
    await api.call({ ...props, url: `${BASE_URL}/sort`, body });
  return { ...api, call };
}

// 🟥 DELETE /lists/{listId} - Delete a list
export function useApiDeleteList(options: APIOptions<DeleteListResponse> = {}) {
  const props: UseAPILazyProps<DeleteListResponse> = {
    url: '',
    kind: 'delete',
    options,
    header: {}
  };
  const api = useApiLazyAuth<DeleteListResponse>(props);
  const call = async (listId: string) =>
    await api.call({ ...props, url: `${BASE_URL}/${listId}` });
  return { ...api, call };
}

// 🟥 DELETE /lists/products/{listId} - Remove one or more products from list
export function useApiDeleteProductList(
  options: APIOptions<DeleteProductListResponse> = {}
) {
  const props: UseAPILazyProps<DeleteProductListResponse> = {
    url: '',
    kind: 'delete',
    options,
    header: {}
  };
  const api = useApiLazyAuth<DeleteProductListResponse>(props);
  const call = async (listId: string, body: ProductsIds) =>
    await api.call({ ...props, url: `${BASE_URL}/products/${listId}`, body });
  return { ...api, call };
}

// 🟥 DELETE /lists/products/ - Remove one or more products from an array of lists
export function useApiRemoveProductMultipleLists(
  options: APIOptions<GetListsResponse> = {}
) {
  const props: UseAPILazyProps<GetListsResponse> = {
    url: `${BASE_URL}/products`,
    kind: 'delete',
    options,
    header: {}
  };
  const api = useApiLazyAuth<GetListsResponse>(props);
  const call = async (body: RemoveProductsListsRequest) =>
    await api.call({ ...props, body });
  return { ...api, call };
}
