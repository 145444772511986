import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useProductPageContext } from 'pages/Product/ProductPageProvider';
import { useAuthContext } from 'providers/AuthProvider';
import { format } from 'utils/currency';

/**
 * Component
 */
function ProductPricing() {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();

  /**
   * Context
   */
  const { authState, hasFeature } = useAuthContext();
  const { product, pricing, pricingLoading } = useProductPageContext();

  /**
   * Consts
   */
  const uom = product?.uom ?? t('product.each');
  const price =
    pricing?.sellPrice === product?.sellPrice
      ? format(pricing?.sellPrice ?? 0)
      : product?.sellPrice ?? 0;

  /**
   * Render
   */
  // 🟩 logged in, CMP enabled
  // 🔶 We are currently skipping CMP for now
  // if (
  //   authState?.isAuthenticated &&
  //   product?.isSavings &&
  //   hasFeature?.('COMPETITIVE_MARKET_PRICE')
  // ) {
  //   // 🟢 no sell price
  //   if (!product.sellPrice) {
  //     return (
  //       <div data-testid="pdp-price-unavailable">
  //         <h4 className="text-secondary-3-100 text-2xl font-bold">
  //           {t('product.priceUnavailable')}
  //         </h4>
  //       </div>
  //     );
  //   }
  //   // 🟢 has sell price
  //   return (
  //     <div data-testid="pdp-price">
  //       {/* CMP */}
  //       <div className="flex items-center">
  //         <p className="text-secondary-2-100 text-base font-normal">
  //           {t('product.cmp')}
  //           {': '}
  //           <span className="font-bold line-through" data-testid="cmp-price">
  //             {product.cmp}{' '}
  //           </span>
  //         </p>
  //         <Tooltip
  //           enterTouchDelay={0}
  //           title={t('product.cmpTooltipText') as string}
  //           placement={isSmallScreen ? 'top' : 'right'}
  //         >
  //           <HelpIcon className="w-5 h-5 ml-2 text-primary-1-100" />
  //         </Tooltip>
  //       </div>
  //       {/* MAIN Price */}
  //       <span
  //         className="text-primary-1-100 text-4xl font-bold lowercase"
  //         data-testid="sell-price"
  //       >
  //         {product.sellPrice} <span className="text-3xl font-light">{uom}</span>
  //       </span>
  //       {/* You Save */}
  //       <p
  //         className="text-success-100 text-base font-normal"
  //         data-testid="price"
  //       >
  //         {t('common.youSave')}{' '}
  //         <b data-testid="saved-price">{product.savedPrice} </b>
  //         {`(${product.savedPercentage}%)`}
  //       </p>
  //     </div>
  //   );
  // }
  // 🟩 Logged in, competitive price (CMP) disabled
  if (authState?.isAuthenticated) {
    return (
      <span
        className="text-secondary-2-100 text-4xl font-bold lowercase"
        data-testid="pdp-sell-price"
      >
        {pricingLoading ? (
          <Skeleton className="!w-24 !h-10 !inline-block !scale-100" />
        ) : (
          price
        )}{' '}
        <span className="text-3xl font-light">{uom}</span>
      </span>
    );
  }
  // 🟩 Logged out, show price feature enabled
  if (hasFeature?.('SHOWS_PRICES')) {
    return (
      <span
        className="text-secondary-2-100 text-4xl font-bold lowercase"
        data-testid="pdp-cmp-price"
      >
        {product?.sellPrice ?? ''}{' '}
        <span className="text-3xl font-light">{uom}</span>
      </span>
    );
  }
  // 🟩 Logged out, show price feature disabled
  return null;
}
export default ProductPricing;
