import { useTranslation } from 'react-i18next';

import { Button, Link } from 'components';
import poshTitle from 'images/posh/poshTitle.png';
import landingBG from 'images/posh/poster.png';
import { buttonStyle } from 'pages/BrandPages/Posh/lib/styles';
import { links } from 'utils/links';

/**
 * Component
 */
function PoshHeader() {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();

  /**
   * Render
   */
  return (
    <section data-testid="posh-section-header">
      {/* Title */}
      <div className="h-[238px] flex justify-center items-center md:h-[84px]">
        <img
          src={poshTitle}
          alt="posh-title"
          className="w-[249px] h-[131px] md:w-[100px] md:h-[52px]"
        />
      </div>
      {/* Landing */}
      <div
        className="h-[500px] bg-cover bg-[center_68%] pl-[200px] md:h-[203px]"
        style={{ backgroundImage: `url(${landingBG})` }}
      />
      {/* Intro bar */}
      <div className="flex justify-center bg-etc-light-gray">
        <div className="max-w-[851px] flex flex-col justify-center items-center my-14 md:max-w-[480px] md:mx-6 md:my-8">
          <h2 className="mb-8 text-etc-posh-4139 text-[64px] text-center font-bold md:text-4xl md:leading-none">
            {t('poshMarketing.introTitle')}
          </h2>
          <p className="text-etc-posh-4139 text-[22px] font-bold text-center leading-[1.2] md:text-xl md:font-medium">
            {t('poshMarketing.introParagraph')}
          </p>
          <div className="mt-10">
            <Link to={links.posh.mainShop}>
              <Button kind="outline" color="gray" className={buttonStyle}>
                {t('poshMarketing.shopPosh')}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PoshHeader;
