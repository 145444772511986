import { useTranslation } from 'react-i18next';

import useDocumentTitle from 'hooks/useDocumentTitle';
import BuilderIO from 'pages/BuilderIO/Core';

/**
 * Aboutus Page
 */
function BuilderIOAboutUs() {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();
  useDocumentTitle(t('common.aboutTitle'));

  return (
    <div className="w-full bg-common-background" data-testid="about-us-wrapper">
      <BuilderIO model="page" pathname={window.location.pathname} />
    </div>
  );
}
export default BuilderIOAboutUs;
