import { StepIconProps as MuiStepIconProps } from '@mui/material';
import clsx from 'clsx';

import { Modify } from '@reece/global-types';

/**
 * Styles
 */
const baseStyle = 'text-common-white flex items-center h-6 z-[2]';
const activeCircle =
  'before:absolute before:top-1 before:bottom-1 before:left-1 before:right-1 before:rounded-full before:bg-primary-2-100/[.2]';

/**
 * Types
 */
type NewProps = {
  icon?: undefined;
  testId?: string;
};
type StepIconProps = Modify<MuiStepIconProps, NewProps>;

/**
 * Component
 */
function StepIcon(props: StepIconProps) {
  /**
   * Props
   */
  const { active, className, testId, completed } = props;

  /**
   * Render
   */
  return (
    <div
      className={clsx(className, baseStyle, { 'text-primary-2-100': active })}
      data-testid={testId}
    >
      <div
        className={clsx('w-5 h-5 rounded-full relative', {
          'bg-secondary-4-100': !completed && !active,
          'bg-primary-2-100': completed || active,
          [activeCircle]: active
        })}
      />
    </div>
  );
}

export default StepIcon;
