import { Container, Dialog, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Button } from 'components';

/**
 * Types
 */
type Props = {
  open: boolean;
  onClose: (save: boolean) => void;
};

/**
 * Component
 */
function ConfirmCancelDialog(props: Props) {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();

  /**
   * Callbacks
   */
  const handleSave = () => props.onClose(true);
  const handleCancel = () => props.onClose(false);

  /**
   * Render
   */
  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="sm" fullWidth>
      <DialogContent>
        <Container className="py-8">
          <h4 className="text-primary-1-100 text-2xl text-center font-medium pb-8">
            {t('common.unsavedChanges')}
          </h4>
          <div className="grid grid-cols-2 gap-4">
            <div className="md:col-span-2">
              <Button
                fullWidth
                onClick={handleCancel}
                kind="outline"
                data-testid="confirmcancel-cancel-button"
              >
                {t('common.withoutSaving')}
              </Button>
            </div>
            <div className="md:col-span-2">
              <Button
                fullWidth
                onClick={handleSave}
                data-testid="confirmcancel-save-button"
              >
                {t('common.save')}
              </Button>
            </div>
          </div>
        </Container>
      </DialogContent>
    </Dialog>
  );
}

export default ConfirmCancelDialog;
