import { useMemo } from 'react';

import { useDomainInfo } from 'hooks/useDomainInfo';
import { expressionsHomeGallery as ehg } from 'hooks/utils/useDomainInfo';

/**
 * Config
 */
export const oldSubs = ['devoreandjohnson', 'murraysupply', 'fwcaz'];

/**
 * Hook
 */
function useCompanyList() {
  /**
   * Custom Hooks
   */
  const { companyList } = useDomainInfo();

  /**
   * Memo
   */
  const output = useMemo(
    () =>
      companyList.filter(({ sub }) => {
        const filterEHG = sub === ehg.sub;
        const filterOld = oldSubs.includes(sub);
        return !(filterEHG || filterOld);
      }),
    [companyList]
  );

  /**
   * Output
   */
  return output;
}

export default useCompanyList;
