import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps
} from '@mui/material';

import { Modify } from '@reece/global-types';
import clsx from 'clsx';
import { ButtonColor } from 'components/Button';
import {
  baseButtonStyle,
  buttonStyles,
  disableStyles,
  hasBackgroundStyle
} from 'components/IconButton/styles';

/**
 * Types
 */
type NewProps = {
  color?: IconButtonColor;
  hasBackground?: boolean;
  testId?: string;
};
export type IconButtonColor = ButtonColor | 'inherit';
export type IconButtonProps = Modify<MuiIconButtonProps, NewProps>;

/**
 * Component
 */
function IconButton(props: IconButtonProps) {
  /**
   * props
   */
  const {
    children,
    className,
    color = 'primary',
    hasBackground,
    testId,
    ...other
  } = props;

  /**
   * Render
   */
  return (
    <MuiIconButton
      className={clsx(
        className,
        baseButtonStyle,
        { [buttonStyles[color]]: !other.disabled },
        { [disableStyles]: other.disabled },
        { [hasBackgroundStyle]: hasBackground }
      )}
      data-testid={testId}
      {...other}
    >
      {children}
    </MuiIconButton>
  );
}

export default IconButton;
