import { ComponentPropsWithoutRef, cloneElement, forwardRef } from 'react';

import clsx from 'clsx';

/**
 * Styles
 */
const inputStyle = [
  'px-4 py-[11px] w-full w-full rounded',
  'text-secondary-2-100 bg-common-white text-base',
  'transition-all truncate',
  'border border-solid border-secondary-3-100',
  'outline-none focus:shadow-input',
  '[&::placeholder]:text-secondary-3-100',
  '[&:hover&:not(:disabled)]:border-primary-2-100',
  '[&:focus&:not(:disabled)]:border-primary-2-100',
  '[&:focus&:not(:disabled)]:shadow-primary-2-50',
  'disabled:bg-primary-3-10',
  'read-only:bg-secondary-4-60'
];
const inputError = 'border-support-1-100';

/**
 * Types
 */
export type InputBaseProps = ComponentPropsWithoutRef<'input'> & {
  error?: boolean;
  iconEnd?: JSX.Element;
  iconEndClassName?: string;
  iconStart?: JSX.Element;
  iconStartClassName?: string;
  testId?: string;
};

/**
 * Component
 */
const InputBase = forwardRef<HTMLInputElement, InputBaseProps>((props, ref) => {
  /**
   * Props
   */
  const {
    error,
    className,
    testId,
    iconEnd,
    iconEndClassName,
    iconStart,
    iconStartClassName,
    ...rest
  } = props;

  /**
   * Render
   */
  return (
    <>
      {Boolean(iconStart) && (
        <span
          className={clsx(
            iconStartClassName,
            'w-10 h-full mx-1.5 inline-flex absolute top-1/2 -translate-y-1/2 flex justify-center items-center'
          )}
        >
          {iconStart &&
            cloneElement(iconStart, { 'data-testid': 'input-icon-start' })}
        </span>
      )}
      <input
        className={clsx(
          inputStyle,
          className,
          { 'pl-12': Boolean(iconStart) },
          { 'pr-12': Boolean(iconEnd) },
          { [inputError]: error }
        )}
        ref={ref}
        data-testid={testId}
        {...rest}
      />
      {Boolean(iconEnd) && (
        <span
          className={clsx(
            iconEndClassName,
            'w-10 h-full mx-1.5 inline-flex absolute right-0 flex justify-center items-center'
          )}
        >
          {iconEnd &&
            cloneElement(iconEnd, { 'data-testid': 'input-icon-end' })}
        </span>
      )}
    </>
  );
});

export default InputBase;
