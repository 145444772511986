import { useContext, useEffect, useRef, useState } from 'react';

import {
  Box,
  Card,
  ClickAwayListener,
  Grid,
  Grow,
  Link,
  Popper,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme
} from '@dialexa/reece-component-library';
import Button from 'components/Button';
import { Instance } from '@popperjs/core';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import useResizeObserver from 'use-resize-observer';

import {
  ListStyled,
  ListItemButtonStyled,
  ListItemTextStyled
} from 'Categories/CategoriesStyles';
import { CategoriesContext } from 'Categories/CategoriesProvider';
import Loader from 'old-components/Loader';
import { Category, Maybe } from 'generated/graphql';
import { ArrowDropDownIcon } from 'icons';
import { useAuthContext } from 'providers/AuthProvider';
import { makeSlug } from 'utils/makeSlug';

function CategoriesButtonWrapper() {
  /**
   * Custom Hooks
   */
  const location = useLocation();
  const theme = useTheme();
  const { t } = useTranslation();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const { activeFeatures } = useAuthContext();

  /**
   * Context
   */
  const { categories, categoriesLoading } = useContext(CategoriesContext);

  /**
   * State
   */
  const [open, setOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<Maybe<Category>>();

  /**
   * Refs
   */
  const containerEl = useRef<HTMLDivElement>(null);
  const popperRef = useRef<Instance>(null);

  const { ref: windowRef, width: windowWidth } =
    useResizeObserver<HTMLDivElement>({
      box: 'border-box',
      round: (n) => Math.round(n * 10) / 10
    });

  /**
   * Effects
   */
  useEffect(popperEffect, [categories, popperRef]);
  useEffect(locationChangeEffect, [location]);
  useEffect(defaultToFirstCategory, [categories, setSelectedCategory]);

  const isHomePageCategories =
    !activeFeatures?.length || activeFeatures.includes('HOMEPAGE_CATEGORIES');
  const ToolRental = activeFeatures?.includes('TOOL_RENTAL')
    ? t('common.toolRental')
    : '';
  return (
    <Box component="div" ref={containerEl} sx={{ flex: '1 0 auto' }}>
      <Box
        component="div"
        ref={windowRef}
        sx={{
          position: 'absolute',
          width: '100vw',
          height: 0,
          zIndex: -1
        }}
      />
      <Button
        color="gray"
        kind="text"
        onMouseEnter={() => setOpen(true)}
        iconEnd={
          <Box
            component={ArrowDropDownIcon}
            sx={{ transform: open ? 'scaleY(-1)' : undefined }}
          />
        }
        data-testid="browse-products-button"
      >
        <p className="!text-primary-1-100 font-bold text-sm">
          {t('common.browseProducts')}
        </p>
      </Button>

      <ClickAwayListener
        mouseEvent="onMouseDown"
        onClickAway={() => {
          setOpen(false);
        }}
      >
        <Popper
          style={{ zIndex: theme.zIndex.modal }}
          id={open ? 'categories-listing' : undefined}
          data-testid="categories-listing-popper"
          open={open}
          onMouseLeave={() => setOpen(false)}
          anchorEl={containerEl.current}
          placement="bottom-start"
          popperRef={popperRef}
          disablePortal={process.env.NODE_ENV === 'test'}
          modifiers={[
            {
              name: 'flip',
              options: {
                enabled: false
              }
            },
            {
              name: 'offset',
              options: {
                offset: [0, 8]
              }
            },
            {
              name: 'preventOverflow',
              options: {
                priority: ['left', 'right'],
                padding:
                  windowWidth && isLargeScreen
                    ? (windowWidth - theme.breakpoints.values.lg + 48) / 2
                    : theme.spacing(3)
              }
            }
          ]}
          transition
        >
          {({ TransitionProps }) => (
            <Grow style={{ transformOrigin: '0 0 0' }} {...TransitionProps}>
              <Card
                square
                elevation={0}
                data-testid="categories-listing"
                sx={(theme) => ({
                  border: 1,
                  borderColor: 'lighterGray.main',
                  minHeight: 200,
                  maxHeight: 600,
                  display: 'flex',
                  flexDirection: 'column',
                  p: 3,
                  [theme.breakpoints.between('md', 'lg')]: {
                    width: `calc(100vw - ${theme.spacing(6)})`
                  },
                  [theme.breakpoints.up('lg')]: {
                    width: `${
                      theme.breakpoints.values.lg - parseInt(theme.spacing(6))
                    }px`
                  }
                })}
              >
                {categoriesLoading ? (
                  <Loader />
                ) : (
                  <Box display="flex" overflow="hidden">
                    <Box component={ListStyled} sx={{ overflowY: 'auto' }}>
                      {!categories?.length
                        ? [...new Array(10)].map((_, i) => (
                            <Typography key={i} variant="h2">
                              <Skeleton width="calc(100vw / 5)" />
                            </Typography>
                          ))
                        : categories.map((category1, i) =>
                            !isHomePageCategories ? (
                              <ListItemButtonStyled
                                key={category1?.name}
                                dense
                                data-testid={`category1-${i}`}
                                selected={
                                  selectedCategory?.name === category1?.name
                                }
                                onMouseEnter={() =>
                                  category1 && setSelectedCategory(category1)
                                }
                              >
                                <ListItemTextStyled
                                  disableTypography
                                  primary={
                                    <Typography
                                      variant="caption"
                                      color="textPrimary"
                                      component="span"
                                    >
                                      {category1?.name}
                                    </Typography>
                                  }
                                />
                              </ListItemButtonStyled>
                            ) : (
                              <Link
                                variant="body2"
                                onClick={handleClose}
                                component={RouterLink}
                                key={i}
                                to={getLink(
                                  category1?.name ?? '',
                                  selectedCategory?.name ?? ''
                                )}
                              >
                                <ListItemButtonStyled
                                  key={category1?.name}
                                  dense
                                  data-testid={`category1-${i}`}
                                  selected={
                                    selectedCategory?.name === category1?.name
                                  }
                                  onMouseEnter={() =>
                                    category1 && setSelectedCategory(category1)
                                  }
                                >
                                  <ListItemTextStyled
                                    disableTypography
                                    primary={
                                      <Typography
                                        variant="caption"
                                        color="textPrimary"
                                        component="span"
                                      >
                                        {category1?.name}
                                      </Typography>
                                    }
                                  />
                                </ListItemButtonStyled>
                              </Link>
                            )
                          )}
                      <Link
                        variant="body2"
                        onClick={handleClose}
                        component={RouterLink}
                        to={{ pathname: '/tool-rental' }}
                      >
                        <ListItemButtonStyled
                          key="tool-rental-menu"
                          dense
                          data-testid="tool-rental-menu"
                          selected={
                            selectedCategory?.name === t('common.toolRental')
                          }
                          onMouseEnter={() => setSelectedCategory(null)}
                        >
                          <ListItemTextStyled
                            disableTypography
                            primary={
                              <Typography
                                variant="caption"
                                color="textPrimary"
                                component="span"
                              >
                                {ToolRental}
                              </Typography>
                            }
                          />
                        </ListItemButtonStyled>
                      </Link>
                    </Box>
                    {selectedCategory && selectedCategory.children ? (
                      <Box mx={8} my={2} width={1}>
                        <Box mb={5} display="flex" alignItems="center">
                          <Typography variant="h5">
                            {t('product.productCategories')}{' '}
                            <Box fontWeight={300} component="span">
                              ({selectedCategory?.children?.length ?? 0})
                            </Box>
                          </Typography>
                          <Link
                            variant="body2"
                            onClick={handleClose}
                            component={RouterLink}
                            to={{
                              pathname:
                                '/search/category' +
                                makeSlug([selectedCategory?.name ?? '']),
                              search: `?categories=${encodeURIComponent(
                                selectedCategory?.name ?? ''
                              ).trim()}`
                            }}
                            sx={{
                              color: 'primary02.main',
                              ml: 2
                            }}
                          >
                            {t('common.viewAll')}
                          </Link>
                        </Box>
                        <Grid
                          container
                          spacing={2}
                          sx={() => ({
                            height: 'calc(100% - 64px)',
                            overflow: 'hidden auto',
                            '&::-webkit-scrollbar': {
                              WebkitAppearance: 'none',
                              width: '5px',
                              borderRadius: '5px',
                              bgcolor: '#e4e4e4'
                            },
                            '&::-webkit-scrollbar-thumb': {
                              borderRadius: '5px',
                              bgcolor: 'primary02.main'
                            }
                          })}
                        >
                          {[
                            ...(selectedCategory?.children ??
                              ({} as Maybe<Category>))
                          ]
                            ?.sort(
                              // Sort by length of category2.children
                              (a, b) =>
                                (b?.children?.length ?? 0) -
                                (a?.children?.length ?? 0)
                            )
                            ?.map((category2, idx2) => (
                              <Grid
                                container
                                item
                                direction="column"
                                xs={3}
                                key={category2?.name}
                              >
                                <Link
                                  variant="body2"
                                  data-testid={`category2-${idx2}`}
                                  onClick={handleClose}
                                  component={RouterLink}
                                  sx={{ pb: 1, fontWeight: 500 }}
                                  to={{
                                    pathname:
                                      '/search/category' +
                                      makeSlug([
                                        selectedCategory.name,
                                        category2?.name
                                      ]),
                                    search: `&categories=${[
                                      selectedCategory.name,
                                      category2?.name
                                    ]
                                      .map((c) =>
                                        encodeURIComponent(c ?? '').trim()
                                      )
                                      .join('&categories=')}`
                                  }}
                                >
                                  {category2?.name}
                                </Link>
                                {category2?.children?.map((category3, idx3) => (
                                  <Link
                                    key={category3?.name}
                                    data-testid={`category3-${idx2}-${idx3}`}
                                    color="textPrimary"
                                    variant="caption"
                                    onClick={handleClose}
                                    component={RouterLink}
                                    sx={{ pb: 2 }}
                                    to={{
                                      pathname:
                                        '/search/category' +
                                        makeSlug([
                                          selectedCategory?.name,
                                          category2?.name,
                                          category3?.name
                                        ]),
                                      search: `&categories=${[
                                        selectedCategory?.name,
                                        category2?.name,
                                        category3?.name
                                      ]
                                        .map((c) =>
                                          encodeURIComponent(c ?? '').trim()
                                        )
                                        .join('&categories=')}`
                                    }}
                                  >
                                    {category3?.name}
                                  </Link>
                                ))}
                              </Grid>
                            ))}
                        </Grid>
                      </Box>
                    ) : null}
                  </Box>
                )}
              </Card>
            </Grow>
          )}
        </Popper>
      </ClickAwayListener>
    </Box>
  );

  /**
   * Effect Defs
   */
  function popperEffect() {
    if (categories && popperRef.current) {
      popperRef.current.update();
    }
  }

  function locationChangeEffect() {
    setOpen(false);
  }

  function defaultToFirstCategory() {
    setSelectedCategory(categories?.[0]);
  }

  /**
   * Event Handlers
   */
  function handleClose() {
    setOpen(false);
  }

  function getLink(categoryName: string, selectedCategory: string) {
    switch (categoryName) {
      case 'Water Heaters':
        return {
          pathname: '/category/water-heaters'
        };
      case t('common.heatingAndCooling'):
        return {
          pathname: '/category/heating-cooling'
        };
      default:
        return {
          pathname: '/search/category' + makeSlug([categoryName]),
          search: `?categories=${encodeURIComponent(
            selectedCategory ?? ''
          ).trim()}`
        };
    }
  }
}

export default CategoriesButtonWrapper;
