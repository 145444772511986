import { useCallback, useRef, useState } from 'react';

import { CheckCircle } from '@mui/icons-material';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { useDropzone } from 'react-dropzone';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Maybe } from 'yup/lib/types';

import {
  OnButtonClick,
  OnFormSubmit,
  OnInputChange
} from '@reece/global-types';
import { useApiGetAllListNames, useApiUploadNewListCSV } from 'API/lists.api';
import { ApiError } from 'API/types/common.types';
import { List, UploadListCSVResponse } from 'API/types/lists.types';
import { Button, Card, Loader, Textarea, TextInput } from 'components';
import { WarningIcon } from 'icons';
import baseI18nComponents from 'locales/baseComponents';
import { acceptedCharPattern } from 'pages/Lists/provider/hooks/useListActionsForm';
import { base64ToArrayBuffer, downloadFile } from 'utils/downloadFile';

/**
 * Config
 */
const VALID_TYPES = ['text/csv', 'application/vnd.ms-excel'];
const SCROLL_CONFIG: ScrollIntoViewOptions = {
  behavior: 'smooth',
  block: 'start',
  inline: 'nearest'
};

/**
 * Types
 */
enum PostUploadState {
  error,
  success
}
export type ListUploadDropzoneProps = {
  back: () => void;
  test?: string;
};

/**
 * Component
 */
function ListUploadDropzoneNew(props: ListUploadDropzoneProps) {
  /**
   * Ref
   */
  const containerRef = useRef<HTMLDivElement>(null);

  /**
   * Custom hooks
   */
  const { t } = useTranslation();
  const { push } = useHistory();

  /**
   * State
   */
  const [acceptedFile, setAcceptedFile] = useState<File>();
  const [hasRejectedFile, setHasRejectedFile] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [postUploadState, setPostUploadState] = useState<PostUploadState>();
  const [uploadStat, setUploadStat] = useState<UploadListCSVResponse>();
  const [errorFile, setErrorFile] = useState<string>();
  const [newList, setNewList] = useState<List>();

  /**
   * API
   */
  // 🟣 API - list names
  const { data: listNamesRes, loading: getListNamesLoading } =
    useApiGetAllListNames({});
  // 🟣 API - upload new list
  const { call: uploadListCall, loading: uploadListLoading } =
    useApiUploadNewListCSV({});
  const loading = getListNamesLoading || uploadListLoading;

  /**
   * Special Hook - Dropzone
   */
  const {
    getInputProps,
    getRootProps,
    inputRef: fileUploadRef,
    isDragAccept,
    isDragReject,
    open: openFileDialog
  } = useDropzone({
    multiple: false,
    noClick: true,
    onDropAccepted: (droppedFiles: File[]) => {
      setAcceptedFile(droppedFiles[0]);
      scrollToUploadContainer();
    },
    onDropRejected: () => setHasRejectedFile(true)
  });

  /**
   * Callbacks
   */
  // 🟤 Cb - Scrolling into upload section
  const scrollToUploadContainer = () =>
    containerRef.current?.scrollIntoView?.(SCROLL_CONFIG);
  // 🟤 Cb - Choose file
  const handleChooseFile = (event: OnInputChange) => {
    const [file] = event?.target?.files ?? [undefined];
    const acceptedFile = VALID_TYPES.includes(file?.type);
    setAcceptedFile(file);
    setHasRejectedFile(!acceptedFile);
    scrollToUploadContainer();
  };
  // 🟤 Cb - Remove file
  const handleRemoveFile = (e: OnButtonClick) => {
    e.preventDefault();
    // istanbul ignore next
    fileUploadRef?.current?.value && (fileUploadRef.current.value = '');
    setAcceptedFile(undefined);
    setPostUploadState(undefined);
    setErrorMessage(undefined);
    setHasRejectedFile(false);
  };
  // 🟤 Cb - Submit error
  const handleSubmitError = (data?: ApiError) => {
    setErrorMessage(data?.message);
    setPostUploadState(PostUploadState.error);
  };
  // 🟤 Cb - Download error csv file
  const downloadErrorCSV = () =>
    errorFile &&
    downloadFile(
      base64ToArrayBuffer(errorFile),
      `${newList?.name}_errors`,
      'text/csv'
    );
  // 🟤 Cb - Form submit
  const handleFormSubmit = (e: OnFormSubmit) => {
    e.preventDefault();
    // 🔸 acceptedFile is always truthy and hasRejectedFile is always falsey when this is called
    uploadListCall({ ...formik.values }, { file: acceptedFile! })
      .then((res) => {
        // 🟥 ERROR
        if (!res?.data?.listInfo || !res?.data?.products?.length) {
          handleSubmitError();
          return;
        }
        // 🟧 ERROR FILE
        res.data.file && setErrorFile(res.data.file);
        // 🟩 SUCCESS
        setUploadStat(res.data);
        setNewList(res.data.listInfo);
        setPostUploadState(PostUploadState.success);
      })
      .catch(handleSubmitError);
  };
  // 🟤 Cb - Validate list name to be unique
  const isNameUnique = useCallback(
    (input?: Maybe<string>) =>
      !listNamesRes?.getAllListNames?.includes(
        // istanbul ignore next
        input?.trim() ?? ''
      ),
    [listNamesRes?.getAllListNames]
  );

  /**
   * Form
   */
  const validationSchema = Yup.object({
    name: Yup.string()
      .required(t('validation.nameRequired'))
      .matches(acceptedCharPattern, {
        message: t('lists.listCharError', { type: 'name' })
      })
      .test('is-name-unique', `${t('lists.listExistsWarning')}`, isNameUnique),
    description: Yup.string().max(
      200,
      `${t('lists.listCountError', {
        type: 'description',
        count: 200
      })}`
    )
  });
  const formik = useFormik({
    initialValues: { name: '', description: '' },
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema,
    onSubmit: handleFormSubmit
  });

  /**
   * Render
   */
  return (
    <Card className="relative h-full p-6 flex flex-col border border-secondary-3-100 !shadow-none">
      {loading && <Loader backdrop testId="list-upload-loading" />}
      {/* Upload dropzone */}
      {!postUploadState && (
        <div
          {...(!acceptedFile && getRootProps({}))}
          className={clsx(
            'w-full p-4 bg-primary-2-100/5 rounded-xl border-2 border-dashed border-secondary-3-100 flex flex-col justify-center items-center md:mb-4',
            { '!bg-primary-2-100/25': !acceptedFile && isDragAccept },
            { '!bg-error-100/25': !acceptedFile && isDragReject }
          )}
          ref={containerRef}
          data-testid="dropzone-root"
        >
          {!acceptedFile ? (
            <>
              <p className="text-base font-medium">
                <Trans
                  i18nKey="lists.dragDropNew"
                  components={{
                    a: (
                      <Button
                        color="lightBlue"
                        kind="text"
                        className="!px-0 !py-0 !justify-normal"
                        onClick={openFileDialog}
                        data-testid="choose-file-button"
                      />
                    )
                  }}
                />
              </p>
              <p className="mt-2 text-base font-base text-secondary-2-100">
                {t('lists.fileTypes')}
              </p>
              <input
                {...getInputProps()}
                id="choose-file-form"
                name="chooseFileForm"
                type="file"
                onChange={handleChooseFile}
                data-testid="choose-file-input"
              />
            </>
          ) : (
            <>
              <p
                className="w-full text-base text-center font-medium truncate"
                data-testid="file-name-label"
              >{`${t('lists.fileSelected')}: ${acceptedFile.name}`}</p>
              <Button
                type="button"
                kind="text"
                color="lightBlue"
                className="!p-0 ml-2 underline !text-base !inline !align-top"
                onClick={handleRemoveFile}
                data-testid="remove-file-button"
              >
                {t('common.remove')}
              </Button>
            </>
          )}
        </div>
      )}
      {/* Error */}
      {(hasRejectedFile || postUploadState === PostUploadState.error) && (
        <div
          className="py-4 flex items-center text-support-1-100"
          data-testid="upload-csv-error"
        >
          <WarningIcon className="mt-2 mr-2 w-8 h-8" width={32} height={32} />
          <span className="text-base flex-1">
            <Trans
              i18nKey={
                hasRejectedFile ? 'validation.csvRequired' : 'lists.uploadError'
              }
              components={baseI18nComponents}
            />
            {errorMessage && (
              <p className="mt-2" data-testid="upload-error-message">
                {errorMessage}
              </p>
            )}
          </span>
        </div>
      )}
      {/* Success */}
      {postUploadState === PostUploadState.success &&
        (uploadStat?.file ? (
          <div
            className="py-4 flex flex-1 items-center text-primary-3-100"
            data-testid="upload-csv-warning"
          >
            <WarningIcon
              className="mt-2 mr-2 w-8 h-8 text-secondary-1-100"
              width={32}
              height={32}
            />
            <span className="text-base flex-1">
              <Trans
                i18nKey="lists.uploadWarning"
                values={{
                  success: uploadStat.successfulRowCount,
                  total: uploadStat.totalRowCount,
                  listName: newList?.name
                }}
                components={{
                  ...baseI18nComponents,
                  a: (
                    <Button
                      type="button"
                      kind="text"
                      color="lightBlue"
                      className="!min-w-0 !p-0 underline !text-base !inline !align-top"
                      data-testid="download-error-file-button"
                      onClick={downloadErrorCSV}
                    />
                  )
                }}
              />
            </span>
          </div>
        ) : (
          <span
            className="pt-4 pb-8 flex flex-1 flex-col items-center justify-center text-primary-3-100"
            data-testid="upload-csv-success"
          >
            <CheckCircle className="mr-2 !w-12 !h-12 text-success-100" />
            <span className="text-base font-medium">
              {t('lists.uploadSuccess')}
            </span>
          </span>
        ))}

      {!postUploadState && (
        <form
          className="flex flex-col flex-1 items-center w-full px-2"
          data-testid="file-select-form"
          onSubmit={handleFormSubmit}
        >
          <div className="flex-1 w-full">
            {/* <>-------------------------<> LIST NAME <>-------------------------<> */}
            <div className="flex py-4 flex-col w-full max-w-full md:py-0">
              <TextInput
                name="name"
                label={t('lists.enterListName')}
                testId="list-upload-name"
                required
                placeholder={t('lists.listNamePlaceholder')}
                maxLength={30}
                value={formik.values.name}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.name)}
                disabled={loading}
                onBlur={formik.handleBlur}
                message={formik.errors.name ?? ''}
              />
            </div>
            {/* <>-------------------------<> LIST DESCRIPTION <>-------------------------<> */}
            <div className="flex flex-col w-full max-w-full md:h-full overflow-y-hidden">
              <Textarea
                name="description"
                label={t('lists.enterListDesc')}
                testId="list-upload-description"
                inputClassName="whitespace-break-spaces md:h-auto overflow-scroll"
                className="md:h-full"
                rows={4}
                placeholder={t('lists.listDescPlaceholder')}
                maxLength={200}
                value={formik.values.description}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.description)}
                disabled={loading}
                onBlur={formik.handleBlur}
                message={formik.errors.description ?? ''}
              />
            </div>
          </div>
          <Button
            type="submit"
            disabled={!acceptedFile}
            color="lightBlue"
            data-testid="upload-submit-button"
          >
            {t('common.submit')}
          </Button>
        </form>
      )}
      {postUploadState === PostUploadState.success && (
        <div className="text-center">
          <Button
            onClick={() => push(`/lists?id=${newList?.id}`)}
            data-testid="upload-success-button"
          >
            {t('lists.viewList')}
          </Button>
        </div>
      )}
    </Card>
  );
}

export default ListUploadDropzoneNew;
