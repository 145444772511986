import { Lightbulb } from '@mui/icons-material';

import Card from 'components/Card';
import Badge, { BadgeColor, BadgeKind, BadgeProps } from 'components/Badge';

/**
 * Config
 */
const display = {
  title: 'Badge'
};

/**
 * Types
 */
type BadgeDisplayProp = {
  n?: number;
  c?: BadgeColor;
  k?: BadgeKind;
  v?: BadgeProps['variant'];
};

/**
 * Component
 */
function BadgePage() {
  /**
   * Render
   */
  return (
    <Card className="my-8 p-6">
      <h5 className="text-2xl font-bold text-secondary-2-100">
        {display.title}
      </h5>
      <hr className="my-4" />
      <div className="my-4 flex flex-col gap-8 justify-center">
        <div className="flex gap-8 justify-center">
          <BadgeDisplay c="red" v="standard" />
          <BadgeDisplay c="red" v="standard" k="strokeFill" />
          <BadgeDisplay c="red" v="standard" k="stroke" />
          <BadgeDisplay c="red" v="dot" />
          <BadgeDisplay c="red" v="dot" k="strokeFill" />
          <BadgeDisplay c="red" v="dot" k="stroke" />
        </div>
        <div className="flex gap-8 justify-center">
          <BadgeDisplay c="yellow" v="standard" />
          <BadgeDisplay c="yellow" v="standard" k="strokeFill" />
          <BadgeDisplay c="yellow" v="standard" k="stroke" />
          <BadgeDisplay c="yellow" v="dot" />
          <BadgeDisplay c="yellow" v="dot" k="strokeFill" />
          <BadgeDisplay c="yellow" v="dot" k="stroke" />
        </div>
        <div className="flex gap-8 justify-center">
          <BadgeDisplay c="green" v="standard" />
          <BadgeDisplay c="green" v="standard" k="strokeFill" />
          <BadgeDisplay c="green" v="standard" k="stroke" />
          <BadgeDisplay c="green" v="dot" />
          <BadgeDisplay c="green" v="dot" k="strokeFill" />
          <BadgeDisplay c="green" v="dot" k="stroke" />
        </div>
        <div className="flex gap-8 justify-center">
          <BadgeDisplay c="secondary" />
          <BadgeDisplay c="secondary" k="strokeFill" />
          <BadgeDisplay c="secondary" k="stroke" />
          <BadgeDisplay c="secondary" v="dot" />
          <BadgeDisplay c="secondary" v="dot" k="strokeFill" />
          <BadgeDisplay c="secondary" v="dot" k="stroke" />
        </div>
        <div className="flex gap-8 justify-center">
          <BadgeDisplay c="primary" v="standard" />
          <BadgeDisplay c="primary" v="standard" k="strokeFill" />
          <BadgeDisplay c="primary" v="standard" k="stroke" />
          <BadgeDisplay c="primary" v="dot" />
          <BadgeDisplay c="primary" v="dot" k="strokeFill" />
          <BadgeDisplay c="primary" v="dot" k="stroke" />
        </div>
        <div className="flex gap-8 justify-center">
          <BadgeDisplay c="gray" v="standard" />
          <BadgeDisplay c="gray" v="standard" k="strokeFill" />
          <BadgeDisplay c="gray" v="standard" k="stroke" />
          <BadgeDisplay c="gray" v="dot" />
          <BadgeDisplay c="gray" v="dot" k="strokeFill" />
          <BadgeDisplay c="gray" v="dot" k="stroke" />
        </div>
        <div className="flex gap-8 justify-center">
          <BadgeDisplay c="red" n={9} />
          <BadgeDisplay c="yellow" n={99} />
          <BadgeDisplay c="green" n={999} />
          <BadgeDisplay c="secondary" n={9999} />
          <BadgeDisplay c="primary" n={99999} />
          <BadgeDisplay c="gray" n={999999} />
        </div>
      </div>
    </Card>
  );
}

export default BadgePage;

/**
 * Sub-components
 */
function BadgeDisplay(props: BadgeDisplayProp) {
  return (
    <Badge
      badgeContent={props.n ?? 8}
      color={props.c}
      kind={props.k}
      variant={props.v}
      max={99999}
    >
      <Lightbulb />
    </Badge>
  );
}
