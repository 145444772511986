// istanbul ignore file
import { useCallback, useMemo } from 'react';

import { Drawer } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Aggregate, SearchRequest } from 'API/types/search.types';
import { Button, IconButton, Loader } from 'components';
import useScreenSize from 'hooks/useScreenSize';
import { CloseIcon } from 'icons';
import SearchFiltersContent from 'pages/Search/sub/SearchFilters/SearchFiltersContent';
import { getSelectedFiltersArray } from 'pages/Search/util/searchFiltersUtil';
import useSearchQueryParams from 'pages/Search/util/useSearchQueryParams';

/**
 * Types
 */
export type Props = {
  loading: boolean;
  count: number;
  filters: Aggregate[];
  filtersOpen: boolean;
  onChange: (b?: Partial<SearchRequest>) => void;
  onShowHide: (p: boolean) => void;
};

/**
 * Component
 */
function SearchFilters(props: Props) {
  /**
   * Custom Hooks
   */
  const { isSmallScreen } = useScreenSize();
  const { t } = useTranslation();
  const [params, setParams] = useSearchQueryParams();
  const { filters = [], criteria: searchTerm } = params;

  /**
   * Memos
   */
  const selectedFilters = useMemo(
    () => getSelectedFiltersArray(filters),
    [filters]
  );

  /**
   * Callbacks
   */
  const handleClear = useCallback(
    () => setParams({ criteria: searchTerm }),
    [searchTerm, setParams]
  );

  /**
   * Render
   */
  if (!isSmallScreen) {
    return (
      <SearchFiltersContent
        loading={props.loading}
        filters={props.filters}
        onChange={props.onChange}
        onClear={handleClear}
      />
    );
  }
  return (
    <Drawer
      PaperProps={{ className: 'bottom-0' }}
      anchor="top"
      open={props.filtersOpen}
      onClose={() => props.onShowHide(false)}
    >
      <div className="flex flex-col min-h-full">
        <div className="px-6 pt-11 pb-3 flex items-center border-b border-primary-3-10">
          <div className="flex-1" />
          <span className="text-xl text-primary-1-100 font-bold">
            {t('common.filter')}
          </span>
          <div className="flex-1 justify-end flex">
            <IconButton onClick={() => props.onShowHide(false)} size="small">
              <CloseIcon data-testId="close-icon" />
            </IconButton>
          </div>
        </div>
        <div className="flex-1 overflow-auto">
          <SearchFiltersContent
            loading={props.loading}
            filters={props.filters}
            onChange={props.onChange}
            onClear={handleClear}
          />
        </div>
        <div className="flex p-8 border-t border-secondary-4-100 shadow-surround">
          <Button
            data-testId="search-filter-button"
            onClick={() => props.onShowHide(false)}
            className="flex-1"
          >
            {`${t('search.showResults')} (`}
            {props.loading ? (
              <Loader size="flex" loaderSize="1em" className="my-0 mx-1 " />
            ) : (
              props.count
            )}
            {')'}
          </Button>
          {Boolean(selectedFilters.length) && (
            <Button kind="text" onClick={handleClear} className="ml-6">
              {t('common.clear')}
            </Button>
          )}
        </div>
      </div>
    </Drawer>
  );
}
export default SearchFilters;
