import {
  EmbeddedSessionResponse,
  UseEmbeddedSessionRequest
} from 'API/types/embedded.types';
import { ApiOnMountProps, makeApiOnMountAuth } from 'API/lib/makeApi';
import { useMemo } from 'react';
import { generateQueryParam } from 'utils/generateQueryParam';

/**
 * Config
 */
const BASE_URL = 'embedded';

/**
 * Hooks
 */
// 🟦 GET /use-session - Uses the embedded session
export function useApiUseEmbeddedSession(
  props: ApiOnMountProps<EmbeddedSessionResponse>,
  params: UseEmbeddedSessionRequest
) {
  const url = useMemo(() => generateQueryParam(BASE_URL, params), [params]);
  return makeApiOnMountAuth<EmbeddedSessionResponse>({
    url,
    kind: 'get',
    header: {}
  })(props);
}
