import { FormEventHandler } from 'react';

import { Hidden, TextField } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { searchTextFieldStyle } from 'common/Header/lib/styles';
import { Button } from 'components';
import { SearchIcon } from 'icons';

/**
 * Types
 */
export type SearchInputProps = {
  value: string;
  onChange: (value: string) => void;
  onSubmit: FormEventHandler<HTMLFormElement>;
  onFocus: () => void;
};

/**
 * Component
 */
function SearchInput(props: SearchInputProps) {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();

  /**
   * Render
   */
  return (
    <form className="grow shrink" id="product-search" onSubmit={props.onSubmit}>
      {/* Using MUI textfield instead due to some components doesn't work from `src/components` */}
      <TextField
        id="search-term"
        type="text"
        onFocus={props.onFocus}
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
        className={clsx(searchTextFieldStyle)}
        placeholder={t('common.searchPlaceholder')}
        inputProps={{ 'data-testid': 'search-bar-input', autoComplete: 'off' }}
        InputProps={{
          startAdornment: (
            <>
              <Button
                data-testid="search-input-submit"
                color="primary"
                type="submit"
                className="m-0 !p-0 !min-w-[auto] !bg-transparent !text-primary-2-100 [&_svg]:!w-6 [&_svg]:!h-6"
                disabled={!props.value}
              >
                <SearchIcon />
              </Button>
            </>
          ),
          endAdornment: (
            <Hidden mdDown>
              {Boolean(props.value) && (
                <Button
                  kind="text"
                  type="button"
                  color="lightBlue"
                  size="sm"
                  onClick={() => props.onChange('')}
                  data-testid="search-bar-clear"
                >
                  {t('common.clear').toLowerCase()}
                </Button>
              )}
            </Hidden>
          )
        }}
        fullWidth
      />
    </form>
  );
}

export default SearchInput;
