import { useTranslation } from 'react-i18next';

import leak from 'images/leak.svg';
import leak_bg from 'images/leak_bg.svg';

/**
 * Component
 */
function SidebarError() {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();

  /**
   * Render
   */
  return (
    <div
      className="px-8 pt-20 pb-8 flex flex-col items-center"
      data-testid="sidebar-error"
    >
      <div className="relative w-[171px] h-[195px]">
        <img
          className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-full"
          src={leak_bg}
          alt="leaky pipe"
        />
        <img
          className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-[60%]"
          src={leak}
          alt="leaky pipe"
        />
      </div>
      <h1 className="pt-6 pb-2 text-primary-2-100 text-5xl font-normal">
        {t('common.ohNo')}
      </h1>
      <h4 className="text-2xl font-medium">{t('common.leak')}</h4>
      <p className="py-4 text-base text-center">{t('branch.error')}</p>
    </div>
  );
}

export default SidebarError;
