import { useMemo, useRef, useState } from 'react';

import { Backdrop } from '@mui/material';
import clsx from 'clsx';
import { uniqBy } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';
import { FixedSizeList } from 'react-window';

import useSearchDrawerTransitions from 'common/Header/lib/useSearchDrawerTransitions';
import IconButton from 'components/IconButton';
import InputBase from 'components/TextInput/InputBase';
import { ArrowDropDownIcon, ArrowDropUpIcon } from 'icons';
import {
  SelectAccountOption,
  formatAccountDisplay
} from 'common/SelectAccounts/lib/useFormatOptions';
import AutoCompleteOption from 'common/SelectAccounts/sub/AutoCompleteOption';

/**
 * Config
 */
export const LIST_CUTOFF = 5;
const MAX_LIST_HEIGHT = 256;
const MAX_LIST_HEIGHT_ALT = 190;
const LIST_ITEM_HEIGHT = 73;

/**
 * Types
 */
type Props = {
  onChange: (val: string) => void;
  disabled?: boolean;
  value: string;
  options: SelectAccountOption[];
  testId?: string;
  isEmployee?: boolean;
  isShipTo?: boolean;
};

/**
 * Component
 */
function SelectAccountAutocomplete(props: Props) {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();
  const transitions = useSearchDrawerTransitions();

  /**
   * Refs
   */
  const inputRef = useRef<HTMLInputElement>(null);

  /**
   * States
   */
  const [open, setOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [search, setSearch] = useState('');

  /**
   * Memo
   */
  // 🔵 Memo - Selected value
  const selected = useMemo(
    () => props.options.find((opt) => opt.value === props.value) ?? null,
    [props.value, props.options]
  );
  // 🔵 Memo - Options
  const options = useMemo(() => {
    // unique
    let uniqueOptions = uniqBy(props.options, ({ label }) => label);
    // search
    if (search) {
      // sort search results depending on the type of the keyword input
      const sortedData = uniqueOptions.sort((a, b) => {
        const isSearchNumber = parseInt(search);
        const compareField = isSearchNumber ? 'accountNumber' : 'name';
        const secondaryCompareField = isSearchNumber ? 'branchId' : 'address';

        const primaryComparison = a.label[compareField].localeCompare(
          b.label[compareField]
        );
        const secondaryComparison = a.label[
          secondaryCompareField
        ].localeCompare(b.label[secondaryCompareField]);

        return primaryComparison || secondaryComparison;
      });

      uniqueOptions = sortedData.filter(
        (item) =>
          formatAccountDisplay(item)
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          item.label.address.toLowerCase().includes(search.toLowerCase()) ||
          item.label.branchId.toLowerCase().includes(search.toLowerCase()) ||
          item.label.id.toLowerCase().includes(search.toLowerCase())
      );
    }
    // showmore cutoff
    if (!showMore) {
      const getSelectedIndex = uniqueOptions.findIndex(
        (item) => item.value === selected?.value
      );
      if (getSelectedIndex !== -1 && getSelectedIndex >= LIST_CUTOFF) {
        const [item] = uniqueOptions.splice(getSelectedIndex, 1);
        uniqueOptions.splice(LIST_CUTOFF - 1, 0, item);
      }
      const cutoff = uniqueOptions.slice(0, LIST_CUTOFF);
      return cutoff;
    }
    return uniqueOptions;
  }, [props.options, search, selected?.value, showMore]);
  // max height
  const maxListHeight = useMemo(
    () =>
      Math.min(
        !props.isEmployee && props.isShipTo
          ? MAX_LIST_HEIGHT_ALT
          : MAX_LIST_HEIGHT,
        LIST_ITEM_HEIGHT * options.length
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options.length]
  );

  /**
   * Callbacks
   */
  // 🟤 Cb - Open
  const openSearch = () => {
    inputRef.current?.focus();
    setOpen(true);
  };
  // 🟤 Cb - Close
  const closeSearch = () => {
    inputRef.current?.blur();
    setOpen(false);
  };
  // 🟤 Cb - Toggle
  const toggleSearch = () => (open ? closeSearch() : openSearch());
  // 🟤 Cb - Select
  const onItemSelect = (item: SelectAccountOption) => () => {
    setSearch('');
    props.onChange(item.label.id);
    closeSearch();
  };

  /**
   * Render
   */
  return (
    <div data-testid={props.testId} className="relative">
      <div className={clsx('relative', { 'z-[100]': open })}>
        <InputBase
          ref={inputRef}
          value={open ? search : formatAccountDisplay(selected)}
          onChange={(e) => setSearch(e.target.value)}
          onFocus={openSearch}
          disabled={props.disabled}
          testId={`${props.testId}-input`}
          className="!pr-12 "
        />
      </div>
      <div className={clsx('absolute top-1 right-1', { 'z-[100]': open })}>
        <IconButton
          color="gray"
          onClick={toggleSearch}
          data-testid={`${props.testId}-button`}
          disabled={props.disabled}
        >
          {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </IconButton>
      </div>
      <Backdrop
        open={open}
        onClick={closeSearch}
        data-testid={`${props.testId}-backdrop`}
        className="fixed top-0 left-0 w-screen h-screen z-10 !bg-transparent"
      />
      <Transition in={open} timeout={150}>
        {(state) => (
          <div
            className="absolute inset-x-0 top-12 invisible bg-common-white overflow-hidden z-[1200] shadow"
            style={{
              ...transitions[state]
            }}
            data-testid={`${props.testId}-dropdown-wrapper`}
          >
            <FixedSizeList
              itemData={options}
              height={maxListHeight}
              width="100%"
              itemSize={LIST_ITEM_HEIGHT}
              itemCount={options.length}
              children={({ style, data, index: i }) => (
                <div
                  style={style}
                  data-testid={`${props.testId}-item-${i}`}
                  onClick={onItemSelect(data[i])}
                  className={clsx(
                    'select-none cursor-pointer pl-4 hover:bg-primary-2-100/20',
                    {
                      'bg-primary-2-100/10': data[i].value === selected?.value
                    }
                  )}
                >
                  <AutoCompleteOption
                    liElementProps={{ className: 'list-none' }}
                    option={data[i]}
                    isEmployee={props.isEmployee}
                    last={i >= options.length - 1}
                  />
                </div>
              )}
            />
            {!options.length && (
              <div
                className="py-2 text-secondary-3-100 text-base text-center"
                data-testid={`${props.testId}-no-results`}
              >
                {t('common.noResultsFound')}
              </div>
            )}
            {!showMore && options.length >= LIST_CUTOFF && (
              <div className="py-2 text-primary-2-100 text-base text-center underline">
                <span
                  className="cursor-pointer"
                  data-testid={`${props.testId}-show-more`}
                  onClick={() => setShowMore(true)}
                >
                  {t('common.seeAll')}
                </span>
              </div>
            )}
          </div>
        )}
      </Transition>
    </div>
  );
}

export default SelectAccountAutocomplete;
