import { useEffect } from 'react';

import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Breadcrumbs from 'common/Breadcrumbs';
import Card from 'components/Card';
import { loadLightningComponent } from 'pages/Appointments/lib/loadLightningComponent';

/**
 * Component
 */
function Appointments() {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();

  /**
   * Effects
   */
  // 🟡 effect - loads salesforce lightning component for scheduling an appointment
  useEffect(() => {
    loadLightningComponent('lexcontainer', 'Inbound_Custom_Guest_Appointment');
  }, []);

  /**
   * Render
   */
  return (
    <>
      <Breadcrumbs pageTitle={t('home.scheduleAnAppointment')} />
      <Container maxWidth="lg">
        <div className="pb-20">
          <h3
            className="mb-12 text-primary-1-100 text-left font-bold text-3.5xl"
            data-testid="appointments-title"
          >
            {t('home.scheduleAnAppointment')}
          </h3>

          {/* New Appointments */}
          <Card>
            <div className="bg-common-white pt-4 pb-8 mx-16 min-h-[340px] md:mx-0 md:min-h-[520px]">
              <div id="lexcontainer" />
            </div>
          </Card>
        </div>
      </Container>
    </>
  );
}

export default Appointments;
