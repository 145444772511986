import { setItem, getItem } from 'utils/localStorage';

/**
 * Config
 */
export const MAX_SEARCH_TERM_COUNT = 10;

/**
 * setLocalStorageSearchTerm
 * @param searchTerm sring
 * @returns void
 */
export function setLocalStorageSearchTerm(searchTerm: string) {
  const searchTermsLocalStorageRaw = getItem('searchTerms');
  try {
    // Parse
    const searchTerms: String[] = searchTermsLocalStorageRaw
      ? JSON.parse(searchTermsLocalStorageRaw)
      : [];
    // If it's empty, just fill it
    if (!searchTerms.length) {
      setItem('searchTerms', JSON.stringify([searchTerm]));
      return;
    }
    // If's already in the list, move it to the front
    if (searchTerms.includes(searchTerm)) {
      const newSearchTerms = searchTerms.filter((t) => t !== searchTerm);
      newSearchTerms.unshift(searchTerm);
      setItem('searchTerms', JSON.stringify(newSearchTerms));
      return;
    }
    // Add and limit search terms
    if (searchTerms.length >= MAX_SEARCH_TERM_COUNT) {
      searchTerms.pop();
    }
    searchTerms.unshift(searchTerm);
    setItem('searchTerms', JSON.stringify(searchTerms));
  } catch (e) {
    // Error failsafe
    console.error(e);
  }
}
