import { HTMLAttributes } from 'react';

import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  SelectAccountOption,
  formatAccountDisplay
} from 'common/SelectAccounts/lib/useFormatOptions';

/**
 * Types
 */
export type AutoCompleteOptionProps = {
  liElementProps: HTMLAttributes<HTMLLIElement>;
  option: SelectAccountOption;
  isEmployee?: boolean;
  last?: boolean;
};

/**
 * Component
 */
function AutoCompleteOption(props: AutoCompleteOptionProps) {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();

  /**
   * Render
   */
  return (
    <li {...props.liElementProps} key={props.option.label.id}>
      <div className="pr-6 py-3">
        <p className="text-base font-bold truncate md:text-caption">
          {props.isEmployee && (
            <span className="text-primary-1-100" data-testid="option-branchid">
              {t('common.branch')} {props.option.label.branchId}
              {' • '}
            </span>
          )}
          <span className="text-primary-3-100">
            {formatAccountDisplay(props.option)}
          </span>
        </p>
        <p className="text-secondary-2-70 text-base truncate md:text-caption">
          {props.option.label.address}
        </p>
      </div>
      {!props.last && <Divider className="!mr-4 bg-secondary-4-100" />}
    </li>
  );
}

export default AutoCompleteOption;
