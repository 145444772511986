import palette from 'providers/libs/Theme/palette';

const muiStepLabel = {
  label: {
    fontWeight: 500,
    color: palette.primary02.main,
    '.MuiStepper-backgroundGray &:not(.MuiStepLabel-active)': {
      color: palette.secondary02.main
    },
    '&.MuiStepLabel-active, .MuiStepper-highlightCompleted &.MuiStepLabel-completed':
      {
        fontWeight: 500,
        color: palette.primary02.main
      }
  }
};

export default muiStepLabel;
