import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { OrderInformation } from 'API/types/orders.types';
import { Button } from 'components';
import { OpenIcon } from 'icons';

export type OrderDetailsStatusProps = {
  content?: OrderInformation;
  orderNumber: string;
  podAvailable?: boolean;
  handleProofOfDelivery?: (orderNumber: string) => void;
};

function OrderDetailsStatus(props: OrderDetailsStatusProps) {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();

  /**
   * Render
   */
  return (
    <div
      className="bg-common-white rounded-md shadow-surround pt-4 pb-8"
      data-testid="order-summary"
    >
      <div className="px-6 py-3">
        <p
          className="text-left text-primary-1-100 font-bold text-xl"
          data-testid="order-number"
        >
          {props.orderNumber}
        </p>
        <span
          className={clsx(
            'block w-min text-sm font-semibold rounded gap-x-1.5 px-2 whitespace-nowrap',
            {
              '!text-secondary-2-100 bg-secondary-4-100':
                props.content?.orderStatus === 'Order placed' ||
                props.content?.orderStatus === 'INVOICED' ||
                props.content?.orderStatus === 'OPEN'
            },
            {
              '!text-secondary-2-100 bg-primary-2-5':
                props.content?.orderStatus === 'In-transit' ||
                props.content?.orderStatus === 'Ready for pick up'
            },
            {
              '!text-success-100 !bg-success-10':
                props.content?.orderStatus === 'SHIPPED' ||
                props.content?.orderStatus === 'Delivered' ||
                props.content?.orderStatus === 'Picked up'
            },
            {
              '!text-support-1-100 bg-support-1-10':
                props.content?.orderStatus === 'Canceled'
            }
          )}
          data-testid="order-status"
        >
          {props.podAvailable ? (
            <Button
              color="green"
              kind="text"
              size="sm"
              onClick={() => props.handleProofOfDelivery?.(props.orderNumber)}
              data-testid={`order-detail-${props.orderNumber}-pod-button`}
              className="!px-0 font-semibold"
            >
              {props.content?.orderStatus}
            </Button>
          ) : (
            props.content?.orderStatus
          )}
        </span>
        <hr className="my-2 px-5" />
      </div>
      <div className="grid gap-y-2 text-base leading-6 px-6">
        <p>
          <span className="font-bold block">{t('common.jobName')}:</span>
          <span className="block" data-testid="order-jobname">
            {props.content?.jobName}
          </span>
        </p>
        <p>
          <span className="font-bold block">{t('common.orderDate')}:</span>
          <span className="block" data-testid="order-date">
            {props.content?.orderDate}
          </span>
        </p>
        <p>
          <span className="font-bold block">{t('common.orderedBy')}:</span>
          <span className="block" data-testid="order-by">
            {props.content?.orderedBy}
          </span>
        </p>
        <p>
          <span className="font-bold block">{t('common.poNumber')}:</span>
          <span className="block" data-testid="order-ponumber">
            {props.content?.poNumber}
          </span>
        </p>
        {Boolean(props.content?.invoiceUrl?.length) && (
          <p>
            <span className="font-bold block">{t('common.invoice')}:</span>
            <a
              href={props.content?.invoiceUrl}
              target="_blank"
              rel="noreferrer"
              data-testid="order-invoicestatus"
            >
              {props.content?.invoiceStatus}{' '}
              <OpenIcon
                className="inline"
                data-testid="order-invoice-openicon"
              />
            </a>
          </p>
        )}
      </div>
    </div>
  );
}

export default OrderDetailsStatus;
