import { alpha } from '@mui/material/styles';

import palette from 'providers/libs/Theme/palette';
import theme from 'providers/libs/Theme/baseTheme';

const muiInput = {
  root: {
    borderRadius: '4px',
    overflow: 'hidden',
    backgroundColor: palette.common.white,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    letterSpacing: '0.005em',
    padding: theme.spacing(1.5, 2),
    transition: theme.transitions.create(['box-shadow']),
    boxShadow: `inset 0 0 0 1px ${palette.lightGray.main}`,
    '&:hover': {
      boxShadow: `inset 0 0 0 1px ${palette.primary02.main}`
    },
    '&.Mui-focused': {
      boxShadow: `inset 0 0 0 1px ${palette.primary02.main}, 0 0 4px 0 ${alpha(
        palette.primary02.main,
        0.5
      )}`
    },
    '&.Mui-error': {
      '&, &:hover': {
        boxShadow: `inset 0 0 0 1px ${palette.error.main}`
      },
      '&.Mui-focused': {
        boxShadow: `inset 0 0 0 1px ${palette.error.main}, 0 0 4px 0 ${alpha(
          palette.error.main,
          0.5
        )}`
      }
    },
    '&.Mui-disabled': {
      backgroundColor: palette.lighterGray.main,
      '&, &:hover': {
        boxShadow: `inset 0 0 0 1px ${palette.lightGray.main}`
      }
    }
  },
  input: {
    height: theme.spacing(3),
    padding: 0
  }
};

export default muiInput;
