import { useState } from 'react';

import { Collapse, Tooltip } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Maybe } from 'yup/lib/types';

import { Button } from 'components';
import { TruncateTextWithCentralEllipsis } from 'utils/strings';

/**
 * Types
 */
export type CustomerPartNumberProps = {
  partNumber?: Maybe<string[]>;
  stylingForPage: string;
};

/**
 * Component
 */
function CustomerPartNumber(props: CustomerPartNumberProps) {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();

  /**
   * State
   */
  const [viewAll, setViewAll] = useState(false);

  /**
   * Render
   */
  if (!props.partNumber?.length) {
    return null;
  }
  return (
    <div
      className={clsx('text-secondary-2-100', {
        'text-caption': props.stylingForPage !== 'PDP',
        'text-xl': props.stylingForPage === 'PDP'
      })}
    >
      <div
        data-testid="my-customer-part-number"
        className="flex flex-wrap gap-1 items-start"
      >
        <span className="max-w-full">
          {t('product.partNumber')}
          <Tooltip title={props.partNumber[0]} enterTouchDelay={0}>
            <span>
              {TruncateTextWithCentralEllipsis(props.partNumber[0], 5, 5)}
            </span>
          </Tooltip>
        </span>
        {props.partNumber.length > 1 && (
          <Button
            onClick={() => setViewAll(!viewAll)}
            color="lightBlue"
            size="sm"
            kind="text"
            data-testid="view-all"
            className={clsx(
              '!justify-self-end !min-w-[50px] !font-normal !text-left',
              {
                '!text-caption !p-0 ': props.stylingForPage !== 'PDP',
                '!text-lg !px-2 !py-0': props.stylingForPage === 'PDP'
              }
            )}
          >
            {viewAll ? t('common.hide') : t('common.viewAll')}
          </Button>
        )}{' '}
      </div>
      {props.partNumber.length > 1 && (
        <Collapse in={viewAll}>
          {props.partNumber.map((item, index) => (
            <Tooltip title={item} enterTouchDelay={0} key={`item-${index}`}>
              <p>
                {Boolean(index) && TruncateTextWithCentralEllipsis(item, 5, 5)}
              </p>
            </Tooltip>
          ))}
        </Collapse>
      )}
    </div>
  );
}
export default CustomerPartNumber;
