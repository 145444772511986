import { BuilderComponent } from '@builder.io/react';
import { useLocation } from 'react-router-dom';

import { Loader } from 'components';
import { useGetBranchQuery } from 'generated/graphql';
import Slider from 'pages/BuilderIO/components/Slider';
import useBuilderIOAPI from 'pages/BuilderIO/lib/useBuilderIOAPI';
import useBuilderIOGetAllAPI from 'pages/BuilderIO/lib/useBuilderIOGetAllAPI';

/**
 * Component
 */
function BuilderIOBranches() {
  /**
   * Custom hooks
   */
  const { pathname } = useLocation();
  const branchId = pathname.match(/[^/]+(?=\/$|$)/gm)?.[0] ?? '';

  /**
   * Data
   */
  // 🟣 query - branch info
  const { data: getBranchData, loading: branchLoading } = useGetBranchQuery({
    skip: !branchId,
    variables: { branchId }
  });
  // 🟣 BuilderIO - page content
  const { content, loading: pageLoading } = useBuilderIOAPI(
    'page-branches',
    pathname
  );
  // 🟣 BuilderIO - events contents
  const regionCode = getBranchData?.branch.regionCode || '';
  const scope = {
    $in: ['ALL', `B${branchId}`, regionCode]
  };
  const { contents: events, loading: eventsLoading } = useBuilderIOGetAllAPI(
    'events-data',
    { data: { scope } },
    !branchId || !regionCode
  );

  /**
   * Render
   */
  if (branchLoading || pageLoading || eventsLoading) {
    return <Loader data-testid="builderio-page-branches-loading" />;
  }
  return (
    <div
      className="w-full bg-common-white"
      data-testid="builderio-page-branches"
    >
      <BuilderComponent
        model="page-events"
        content={content}
        options={{ includeRefs: true, cachebust: true }}
      />
      <div className="my-16">
        <Slider
          dataEvents={events}
          testId="branches"
          keyName="builder-io-branches"
        />
      </div>
    </div>
  );
}
export default BuilderIOBranches;
