import { Container, Hidden } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Button } from 'components';
import useScreenSize from 'hooks/useScreenSize';
import { ChevronLeftIcon } from 'icons';
import ProductPage from 'pages/Product/ProductPage';
import ProductPageProvider from 'pages/Product/ProductPageProvider';
import ProductBreadcrumb from 'pages/Product/sub/ProductBreadcrumb';

/**
 * Component
 */
function ProductPageRoot() {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();
  const { isSmallScreen } = useScreenSize();
  const history = useHistory();

  /**
   * Render
   */
  return (
    <div className="h-full bg-common-white" data-testid="pdp-root">
      <ProductPageProvider>
        {/* Header */}
        <Container maxWidth="lg" disableGutters={isSmallScreen}>
          {/* desktop breadcrumb */}
          <Hidden mdDown>
            <ProductBreadcrumb />
          </Hidden>
          {/* mobile go back button */}
          <Hidden mdUp>
            <div className="w-full py-2">
              <Button
                onClick={history.goBack}
                iconStart={<ChevronLeftIcon />}
                kind="text"
                data-testid="go-back-button"
              >
                {t('common.back')}
              </Button>
            </div>
          </Hidden>
        </Container>
        {/* Main page */}
        <ProductPage />
      </ProductPageProvider>
    </div>
  );
}

export default ProductPageRoot;
