type StatusMapping = {
  [key: string]: 'delivery' | 'direct' | 'return' | 'willCall' | undefined;
};

const statusMapping: StatusMapping = {
  'OT OUR TRUCK': 'delivery',
  'OUR TRUCK': 'delivery',
  'PICK UP': 'willCall',
  WILLCALL: 'willCall',
  DELIVERY: 'delivery',
  DIRECT: 'direct',
  'WILL CALL': 'willCall',
  'DELIVERY SERVICE': 'delivery',
  'WILL CALL INSTAL': 'willCall',
  'OTA OUR TRUCK AM': 'willCall',
  'PICK UP NO RF': 'delivery',
  'PICKUP NO PRINT': 'willCall',
  'BEST WAY': 'delivery',
  'OT INSTALL': 'delivery',
  'PICK UP NO RF H': 'willCall',
  'PICK UP PLMB': 'willCall',
  'OTP OUR TRUCK PL': 'delivery',
  'ADVANTAGE WC': 'willCall',
  'LTL COM CAR': 'delivery',
  'UPS GROUND': 'delivery',
  'OT SHOW': 'delivery',
  'PICK UP HVAC': 'willCall',
  'WILL CALL PLMB': 'willCall',
  COURIER: 'delivery',
  'OUR TRUCK SHOWRM': 'delivery',
  'OUR TRUCK HVAC': 'delivery',
  ACCOUNTING: 'delivery',
  'JP INSTALL': 'delivery',
  'IN HOT WATER': 'delivery',
  'VENDOR FREIGHT': 'delivery',
  'OTP OUR TRUCK PM': 'delivery',
  SALESPERSON: 'delivery',
  'SEE SHIP INSTRUC': 'delivery',
  'ST INSTALL': 'delivery',
  'ZONE 7': 'delivery',
  'WILL CALL HVAC': 'willCall',
  'VENDOR DELIVERY': 'delivery',
  'ZONE 24': 'delivery',
  'ZONE 1': 'delivery',
  'ZONE 20': 'delivery',
  'ZONE 4': 'delivery',
  'ZONE 3': 'delivery',
  'ZONE 6': 'delivery',
  'ZONE 2': 'delivery',
  'UPS NEXT DAY': 'delivery',
  TRUCKLOAD: 'delivery',
  'ZONE 23': 'delivery',
  'UPS NEXT DAY AIR': 'delivery',
  'WILL CALL HOT': 'willCall',
  'PICKUP & DELIVER': 'delivery',
  'UPS COLLECT': 'delivery',
  'UPS 2ND DAY AIR': 'delivery',
  TRANSFER: 'delivery',
  'OTZ OUR TRUCK ND': 'delivery',
  'PICK UP NO RF W': 'willCall',
  'LTL HVAC': 'delivery',
  'OT HOT HOT': 'delivery',
  'WILL CALL SHOWRM': 'willCall',
  'ACCT - ACCOUNTING': 'delivery',
  'ON DEMAND DELIVR': 'delivery',
  'UPS 2ND DAY AM': 'delivery',
  'OT ERROR': 'delivery',
  'UPS 3DAY SELECT': 'delivery',
  BID: 'delivery',
  'IHW WILLCALL': 'willCall',
  USPS: 'delivery',
  'COMMON CARRIER': 'delivery',
  'UPS EARLY AM NDA': 'delivery',
  'UPS ERLY AM HVAC': 'delivery',
  POA: 'delivery',
  FEDEX: 'delivery',
  'UPS NEXT DAY SAT': 'delivery',
  'SALESPERSON HVAC': 'delivery',
  'UPS SATURDAY': 'delivery',
  'STOCKING TRUCK': 'delivery',
  BUS: 'delivery',
  'UPS NEXT DAY 3PM': 'delivery',
  'OUR TRUCK UTIL': 'delivery',
  'UPS 3 DAY HVAC': 'delivery',
  'UPS 2 DAY HVAC': 'delivery',
  'UPS GROUND HVAC': 'delivery',
  REDIST: 'delivery',
  'UPS NXT DAY HVAC': 'delivery',
  'TRANSFER PICKUP': 'delivery',
  'PICK UP UTIL': 'delivery',
  'QUICK PICK': 'delivery',
  'UPS ND SAT HVAC': 'delivery',
  'BEST WAY HVAC': 'delivery',
  'MARKET CREDIT': 'return',
  'RETURN SALESMAN': 'return',
  'RETURN WILL CALL': 'return',
  'OT APPLIANCE': 'return',
  'RETURN OUR TRUCK': 'return',
  Direct: 'delivery',
  'Our Truck': 'delivery',
  Pickup: 'willCall',
  Shipped: 'delivery',
  'RAPID DELIVERY': 'delivery',
  Delivery: 'delivery',
  'Will Call': 'willCall'
};

export const checkStatus = (status: string): string | undefined =>
  statusMapping[status] || statusMapping[status.replace(/_/g, ' ')];

export default statusMapping;
