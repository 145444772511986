import { useEffect, useMemo, useRef, useState } from 'react';

import { Card, ClickAwayListener, Grow, Popper, Zoom } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import useResizeObserver from 'use-resize-observer';

import { useHeaderContext } from 'common/Header/HeaderProvider';
import useSearchSubmit from 'common/Header/lib/useSearchSubmit';
import SearchDrawer from 'common/Header/sub/SearchDrawer';
import SearchInput from 'common/Header/sub/SearchInput';
import SearchSuggestions from 'common/Header/sub/SearchSuggestions';
import { Maybe, useSearchSuggestionQuery } from 'generated/graphql';
import useDebounce from 'hooks/useDebounce';
import { useDomainInfo } from 'hooks/useDomainInfo';
import useScreenSize from 'hooks/useScreenSize';
import { useQueryParams } from 'hooks/useSearchParam';
import { useSubdomainErpSystem } from 'hooks/useSubdomainErpSystem';
import { useAuthContext } from 'providers/AuthProvider';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';
import { SearchParams } from 'pages/Search/util/useSearchQueryParams';

/**
 * Config
 */
export const resizeObserverConfig: Parameters<typeof useResizeObserver>[0] = {
  box: 'border-box',
  round: (n: number) => Math.round(n * 10) / 10
};
const defaultQueryParam = {
  arrayKeys: ['categories', 'filters']
};

/**
 * Component
 */
function SearchBar() {
  /**
   * Custom Hooks
   */
  const { isSmallScreen } = useScreenSize();
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { currentSubdomain } = useSubdomainErpSystem();
  const { engine } = useDomainInfo();
  const [, setParams] = useQueryParams<SearchParams>(defaultQueryParam);

  /**
   * Context
   */
  const { profile } = useAuthContext();
  const { searchOpen, setSearchOpen, setTrackedSearchTerm, setSearchPage } =
    useHeaderContext();
  const { selectedAccounts } = useSelectedAccountsContext();

  /**
   * State
   */
  const [searchTerm, setSearchTerm] = useState('');
  const [searchedOnPage, setSearchedOnPage] = useState(pathname);
  const { value: debouncedSearchTerm, loading: debouncedSearchTermLoading } =
    useDebounce(searchTerm, 500);

  /**
   * Refs
   */
  const inputContainerRef = useRef<HTMLDivElement>(null);
  const { ref: headerRef, height: headerHeight } =
    useResizeObserver(resizeObserverConfig);

  /**
   * Data
   */
  const { data: searchSuggestionsData, loading: searchSuggestLoading } =
    useSearchSuggestionQuery({
      skip: !debouncedSearchTerm || debouncedSearchTermLoading,
      variables: {
        term: debouncedSearchTerm,
        userId: profile?.userId,
        shipToAccountId: selectedAccounts.shipTo?.id,
        erpSystem: currentSubdomain,
        state: selectedAccounts?.shipToErpAccount?.state?.toLowerCase(),
        engine
      }
    });

  /**
   * Memos
   */
  const headerHeightAdjusted = useMemo(
    () => (headerHeight ?? 0) + 1,
    [headerHeight]
  );

  /**
   * Callbacks
   */
  // 🟤 Cb - close search
  const closeSearch = () => setSearchOpen(false);
  // 🟤 Cb - search input change
  const handleChange = (value: string) => {
    setSearchTerm(value);
    setSearchedOnPage(pathname);
  };
  // 🟤 Cb - search input focus (desktop only)
  const handleFocus = () => !isSmallScreen && setSearchOpen(true);
  // 🟤 Cb - search input submit
  const handleSubmit = useSearchSubmit({ searchTerm, closeSearch });
  // 🟤 Cb - desktop click away listener event
  const handleClickAway = (e: MouseEvent | TouchEvent) => {
    e.stopPropagation();
    closeSearch();
  };
  // 🟤 Cb - search suggestion
  const handleSuggestionClick = (
    criteria: string,
    category?: Maybe<string>
  ) => {
    setParams({ criteria, categories: [category ?? ''] }, '/search');
    setSearchPage(1);
    setSearchTerm('');
    closeSearch();
  };
  // 🟤 Cb - search suggestion
  const handleResultClick = (id: string, productSlug: string) => {
    // Go to the product page
    history.push(`/product/${productSlug}${id}`);
    // Close the search box
    closeSearch();
    // Set the saved term
    setTrackedSearchTerm(searchTerm);
    // Reset the page
    setSearchPage(0);
    setSearchTerm('');
  };

  /**
   * Effects
   */
  // 🟡 Effect - reset search on page change
  useEffect(() => {
    const isSearchPage = pathname === '/search' && search.includes('criteria');
    const isSearchedOnPage = pathname === searchedOnPage;
    ((!isSearchPage && !isSearchedOnPage) || isSearchedOnPage) &&
      setSearchTerm('');
    /**
     * NOTICE:
     * The hook exhaustive deps is only pointed towards `pathname` is because
     * we only need to reset searchTerm only on page change. Not something like `searchedOnPage`.
     * Otherwise, it'll reset on the first key press after a page change
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  /**
   * Render
   */
  // Mobile
  if (isSmallScreen) {
    return (
      <div data-testid="header-searchbar-wrapper-mobile">
        {/* Search Input */}
        <Zoom in={searchOpen}>
          <div
            className="absolute inset-y-0 inset-x-3 flex justify-center items-center bg-common-white origin-[calc(100%-80px)_24px]"
            ref={headerRef}
          >
            <SearchInput
              value={searchTerm}
              onChange={handleChange}
              onSubmit={handleSubmit}
              onFocus={handleFocus}
            />
          </div>
        </Zoom>
        {/* Drawer */}
        <SearchDrawer
          open={searchOpen}
          headerHeight={headerHeightAdjusted}
          onBackClick={closeSearch}
        >
          {Boolean(searchTerm) && (
            <SearchSuggestions
              loading={searchSuggestLoading || debouncedSearchTermLoading}
              results={searchSuggestionsData?.searchSuggestion}
              searchTerm={debouncedSearchTerm}
              onSuggestionClick={handleSuggestionClick}
              onResultClick={handleResultClick}
            />
          )}
        </SearchDrawer>
      </div>
    );
  }
  // Desktop
  return (
    <div className="flex-1" data-testid="header-searchbar-wrapper-desktop">
      <ClickAwayListener onClickAway={handleClickAway}>
        <div ref={inputContainerRef}>
          {/* Search Input */}
          <SearchInput
            value={searchTerm}
            onChange={handleChange}
            onSubmit={handleSubmit}
            onFocus={handleFocus}
          />
          {/* Search Suggestions */}
          <Popper
            open={Boolean(searchTerm) && searchOpen}
            anchorEl={inputContainerRef.current}
            placement="bottom"
            modifiers={[
              { name: 'preventOverflow', enabled: false },
              { name: 'flip', enabled: false }
            ]}
            disablePortal={process.env.NODE_ENV === 'test'}
            transition
            className="z-[10001]"
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Card
                  data-testid="search-suggestions"
                  className="px-4 py-8"
                  sx={{ width: inputContainerRef?.current?.clientWidth }}
                >
                  <SearchSuggestions
                    loading={searchSuggestLoading || debouncedSearchTermLoading}
                    results={searchSuggestionsData?.searchSuggestion}
                    searchTerm={debouncedSearchTerm}
                    onSuggestionClick={handleSuggestionClick}
                    onResultClick={handleResultClick}
                  />
                </Card>
              </Grow>
            )}
          </Popper>
        </div>
      </ClickAwayListener>
    </div>
  );
}

export default SearchBar;
