import { DeliverySummary } from 'API/types/orders.types';
import { Button } from 'components';
import { ImageIcon } from 'icons';
import { useTranslation } from 'react-i18next';

export type OrderDetailsDeliveryProps = {
  content?: DeliverySummary;
  orderNumber?: string;
  podAvailable?: boolean;
  handleProofOfDelivery?: (orderNumber: string) => void;
};

function OrderDetailsDelivery(props: OrderDetailsDeliveryProps) {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();

  /**
   * Render
   */
  return (
    <div
      className=" bg-common-white rounded-md shadow-surround pt-4 pb-8"
      data-testid="order-delivery-summary"
    >
      <div className="px-6 py-3">
        <div className="grid grid-cols-7">
          <p
            className="col-span-4 text-left text-primary-1-100 font-bold text-xl"
            data-testid="order-delivery-summary-label"
          >
            {t('common.deliverySummary')}
          </p>
          {props.podAvailable && (
            <div className="col-span-3 flex justify-end">
              <Button
                color="gray"
                kind="outline"
                size="sm"
                onClick={() =>
                  props?.handleProofOfDelivery?.(props?.orderNumber!)
                }
                data-testid={`order-detail-summary-pod-button`}
                className="rounded gap-x-1 !px-0 !text-primary-1-100 border-primary-2-100 font-semibold"
              >
                <ImageIcon className="inline pl-1" />

                <div className="!pr-1">{t('invoice.proofOfDelivery')}</div>
              </Button>
            </div>
          )}
        </div>
        <hr className="h-px my-2 px-5 bg-gray-200"></hr>
      </div>
      <div className="grid gap-y-2 text-base leading-6 px-6">
        <p>
          <span
            className="font-bold block"
            data-testid="order-fulfillment-method-label"
          >
            {t('common.fulfillmentMethod')}:
          </span>
          <span className="block" data-testid="order-fulfillment-method">
            {props.content?.fulfillmentMethod}
          </span>
        </p>
        <p>
          <span
            className="font-bold block"
            data-testid="order-fulfilled-date-label"
          >
            {t('common.fulfilledDate')}:
          </span>
          <span className="block" data-testid="order-fulfilled-date">
            {props.content?.fulfilledDate}
          </span>
        </p>
        <p>
          <span className="font-bold block" data-testid="order-shippedto-label">
            {t('common.shippedTo')}:
          </span>
          <span className="block" data-testid="order-shippedto-street-1">
            {props.content?.orderAddress?.streetLineOne}
          </span>
          <span className="block" data-testid="order-shippedto-street-2">
            {props.content?.orderAddress?.streetLineTwo}
          </span>
          <span className="block" data-testid="order-shippedto-street-3">
            {props.content?.orderAddress?.streetLineThree}
          </span>
          <span className="block" data-testid="order-shippedto-csz">
            {props.content?.orderAddress?.city}{' '}
            {props.content?.orderAddress?.state}{' '}
            {props.content?.orderAddress?.postalCode}
          </span>
        </p>
        <p>
          <span
            className="font-bold block"
            data-testid="order-delivery-specialInstructions-label"
          >
            {t('common.specialInstructions2')}:
          </span>
          <span
            className="block"
            data-testid="order-delivery-specialInstructions"
          >
            {props.content?.specialInstructions}
          </span>
        </p>
      </div>
    </div>
  );
}
export default OrderDetailsDelivery;
