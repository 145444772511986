import { Location, Action } from 'history';
import NavigationPrompt from 'react-router-navigation-prompt';
import { useTranslation } from 'react-i18next';

import CommonAlert from 'common/Alerts';

/**
 * Types
 */
type WhenFn = (
  pl: Location,
  nl: Location | undefined,
  action?: Action
) => boolean;
export type NavigationAlertProps = {
  when: boolean | WhenFn;
  title?: string;
  message?: string;
  confirmBtnTitle?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  cancelBtnTitle?: string;
};

/**
 * Components
 */
function NavigationAlert(props: NavigationAlertProps) {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();

  /**
   * Render
   */
  return (
    <NavigationPrompt when={props.when} allowGoBack>
      {(navPrompt) => (
        <CommonAlert
          open
          title={t('common.navPromptTitle')}
          message={t('common.navPromptMsg')}
          confirmBtnTitle={t('common.navPromptConfirmBtn')}
          cancelBtnTitle={t(`common.cancel`)}
          {...props}
          onConfirm={() => {
            props.onConfirm?.();
            navPrompt.onConfirm();
          }}
          onCancel={() => {
            props.onCancel?.();
            navPrompt.onCancel();
          }}
        />
      )}
    </NavigationPrompt>
  );
}

export default NavigationAlert;
