import { Redirect, useHistory } from 'react-router-dom';

import PendingUser from 'Home/PendingUser';
import { useAuthContext } from 'providers/AuthProvider';
import VerifyEmail from 'Register-old/VerifyEmail';

/**
 * Component
 */
function PostLogin() {
  /**
   * Custom Hooks
   */
  const { location } = useHistory();

  /**
   * Context
   */
  const { profile } = useAuthContext();

  /**
   * Render
   */
  if (profile?.isEmployee && !profile?.isVerified) {
    return <VerifyEmail />;
  }
  if (
    !profile?.permissions?.length &&
    !profile?.isEmployee &&
    profile?.userId
  ) {
    return <PendingUser />;
  }
  return <Redirect to={(location.state || '/') as string} />;
}

export default PostLogin;
