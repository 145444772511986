import { NavigateNext, NavigateBefore } from '@mui/icons-material';
import ReactSlider, { ResponsiveObject } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

/**
 * Types
 */
export type EventBranch = {
  name: string;
  image: string;
  shortDescription: string;
  scope: string;
  url: string;
};
export type EventsSliderProps = {
  dataEvents: EventBranch[];
  testId?: string;
  keyName: string;
};

/**
 * Config
 */
const SliderResponsiveConfig: ResponsiveObject[] = [
  {
    breakpoint: 1024,
    settings: { slidesToShow: 3, slidesToScroll: 1, infinite: true, dots: true }
  },
  {
    breakpoint: 600,
    settings: { slidesToShow: 2, slidesToScroll: 2, initialSlide: 2 }
  },
  { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } }
];

/**
 * Component
 */
function Slider(props: EventsSliderProps) {
  /**
   * render
   */
  return (
    <div className="w-1/2 m-auto" data-testid={props.testId}>
      <ReactSlider
        dots
        infinite
        speed={50}
        slidesToShow={3}
        slidesToScroll={1}
        initialSlide={0}
        nextArrow={<NavigateNext color="primary" />}
        prevArrow={<NavigateBefore color="primary" />}
        responsive={SliderResponsiveConfig}
      >
        {props.dataEvents?.map((item, key) => (
          <div
            className="h-[150px] text-black"
            data-testid={`${props.testId}-item-${key}`}
            key={`${props.keyName}-${key}`}
          >
            <div className="h-20 flex justify-center items-center">
              <a href={item.url}>
                <img src={item.image} alt="" className="h-20 w-20"></img>
              </a>
            </div>
            <div className="flex flex-col justify-center items-center gap-1 p-4">
              <h2 className="text-base font-semibold">{item.name}</h2>
              <h2 className="text-xs font-light">{item.shortDescription}</h2>
            </div>
          </div>
        ))}
      </ReactSlider>
    </div>
  );
}
export default Slider;
