import { Tabs as MuiTabs, TabsProps as MuiTabsProps } from '@mui/material';
import clsx from 'clsx';

import { Modify } from '@reece/global-types';

/**
 * Styles
 */
const borderBottomStyle = 'border-b border-primary-3-10';
const indicatorStyle = '[&_.MuiTabs-indicator]:h-0.5';
const indicatorColors = {
  primary: '[&_.MuiTabs-indicator]:bg-primary-1-100',
  primaryLight: '[&_.MuiTabs-indicator]:bg-primary-1-100',
  secondary: '[&_.MuiTabs-indicator]:bg-secondary-1-100'
};

/**
 * Types
 */
type NewProps = {
  indicatorColor?: keyof typeof indicatorColors;
  borderBottom?: boolean;
};
export type TabsProps = Modify<MuiTabsProps, NewProps>;

/**
 * Component
 */
function Tabs(props: TabsProps) {
  /**
   * Props
   */
  const {
    children,
    indicatorColor = 'primary',
    borderBottom = true,
    onFocusVisible,
    sx,
    ...other
  } = props;

  /**
   * Render
   */
  return (
    <MuiTabs
      className={clsx(
        { [borderBottomStyle]: borderBottom },
        indicatorStyle,
        indicatorColors[indicatorColor]
      )}
      {...other}
    >
      {children}
    </MuiTabs>
  );
}

export default Tabs;
