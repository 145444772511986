// istanbul ignore file
import { useEffect, useState } from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Alert, Link } from 'components';
import { SignOutIcon } from 'icons';

/**
 * Component
 */
function SearchVisitorAlert() {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();

  /**
   * State
   */
  const [fixedAlert, setFixedAlert] = useState(false);

  /**
   * Callbacks
   */
  const handleScroll = () => {
    // istanbul ignore next
    const position = window.scrollY || window.pageYOffset;
    const [headerElement] = document.getElementsByTagName('header');
    const headerHeight = headerElement.getBoundingClientRect().height;
    setFixedAlert(position >= headerHeight);
  };

  /**
   * Effects
   */
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  /**
   * Render
   */
  return (
    <div
      className={clsx('flex justify-center', {
        'sticky top-0 z-[1]': fixedAlert
      })}
    >
      <Alert
        icon={<SignOutIcon width={30} height={30} />}
        severity="info"
        className="border"
      >
        <div className="flex my-2 gap-1 md:flex-wrap">
          <Link to="/login" className="text-base font-medium underline">
            {t('search.signIn')}
          </Link>
          <span className="text-base font-normal">
            {t('search.pricingInfo')}
          </span>
        </div>
      </Alert>
    </div>
  );
}

export default SearchVisitorAlert;
