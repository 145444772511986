import { useEffect, useRef, useState } from 'react';

import { Maybe } from 'yup/lib/types';

import { useApiGetStoreFinderBranches } from 'API/branches.api';
import { APIOnCompleted } from 'API/hooks/useApiBase';
import {
  GetStoreFinderRequest,
  GetStoreFinderResponse,
  StoreFinderBranch
} from 'API/types/branches.types';
import { MileRadiusEnum } from 'generated/graphql';
import { GeolocationResponse } from 'hooks/useGeolocation';
import { Divisions } from 'providers/BranchProvider';

/**
 * Config
 */
const LATITUDE = 32.8206376;
const LONGITUDE = -96.8139518;

/**
 * Types
 */
type Props = {
  distance: MileRadiusEnum;
  filter: Divisions;
  place?: Location;
  position: Maybe<GeolocationResponse>;
  positionLoading: boolean;
  onCompleted?: APIOnCompleted<GetStoreFinderResponse>;
};
export type Location = { lat: number; lng: number };

/**
 * Hook
 */
function useStoreFinderApi(props: Props) {
  /**
   * Ref
   */
  const requestRef = useRef<GetStoreFinderRequest>();

  /**
   * Custom hooks
   */
  const distance = props.distance.replace('MILES_', '');
  const locationType = props.filter;
  const latitude =
    props.place?.lat ?? props.position?.coords.latitude ?? LATITUDE;
  const longitude =
    props.place?.lng ?? props.position?.coords.longitude ?? LONGITUDE;
  // must be placed here to update
  useEffect(() => {
    requestRef.current = { latitude, longitude, distance, locationType };
  }, [
    distance,
    latitude,
    locationType,
    longitude,
    props.place,
    props.position?.coords
  ]);

  /**
   * States
   */
  const [branches, setBranches] = useState<StoreFinderBranch[]>([]);

  /**
   * API
   */
  const { call, called, loading } = useApiGetStoreFinderBranches({
    onCompleted: (res) => {
      props.onCompleted?.(res);
      if (
        distance === requestRef.current?.distance &&
        locationType === requestRef.current.locationType &&
        latitude === requestRef.current.latitude &&
        longitude === requestRef.current.longitude
      ) {
        setBranches(res.data);
      }
    }
  });

  /**
   * Effects
   */
  // 🟡 Effect - Call to fetch branches
  useEffect(() => {
    if (props.place && !props.positionLoading && (latitude || longitude)) {
      call({ latitude, longitude, distance, locationType });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.place?.lat,
    props.place?.lng,
    props.positionLoading,
    latitude,
    longitude,
    distance
  ]);

  /**
   * Output
   */
  return { branches, latitude, longitude, called, loading };
}
export default useStoreFinderApi;
