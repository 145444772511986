import theme from 'providers/libs/Theme/baseTheme';

const muiFormHelperText = {
  contained: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
};

export default muiFormHelperText;
