import { Fragment, useMemo } from 'react';

import { Divider } from '@mui/material';
import { kebabCase } from 'lodash-es';

import {
  MaintenanceBanner as MaintenanceBannerType,
  useMaintenanceBannerQuery
} from 'common/MaintenanceBanner/query';

/**
 * Config
 */
export const dummyMaintenanceBanner: MaintenanceBannerType = {
  isOn: false,
  message: '',
  title: ''
};

/**
 * Component
 */
function MaintenanceBanner() {
  /**
   * Data
   */
  const { data } = useMaintenanceBannerQuery();

  /**
   * Memo
   */
  const messages = useMemo(
    () =>
      data?.maintenanceBannerCollection?.items.filter(({ isOn }) => isOn) ?? [],
    [data?.maintenanceBannerCollection?.items]
  );
  const count = messages.length;

  /**
   * Render
   */
  if (!count) {
    return null;
  }
  return (
    <header className="bg-etc-dark-orange" data-testid="maintenance-wrapper">
      <div className="text-2xl leading-10 text-common-white text-center whitespace-pre-wrap mx-2 my-4 md:text-lg md:leading-6 md:my-2 md:mx-1">
        {messages.map((item, index) => (
          <Fragment key={kebabCase(`maintenance-msg-${item.title}`)}>
            <span data-testid={`maintenance-msg${index}`}>{item.message}</span>
            {index < count - 1 && <Divider className="!my-2 !mx-10" />}
          </Fragment>
        ))}
      </div>
    </header>
  );
}

export default MaintenanceBanner;
