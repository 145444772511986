/**
 * https://usehooks-typescript.com/react-hook/use-local-storage
 *
 *  // Usage
 *  export default function Component() {
 *    const [isDarkTheme, setDarkTheme] = useLocalStorage('darkTheme', true)
 *
 *    const toggleTheme = () => {
 *      setDarkTheme(!isDarkTheme)
 *    }
 *
 *    return (
 *      <button onClick={toggleTheme}>
 *        {`The current theme is ${isDarkTheme ? `dark` : `light`}`}
 *      </button>
 *    )
 *  }
 */
import { useEffect, useState } from 'react';

export function useLocalStorage<T>(
  key: string,
  initialValue: T
): [T, (value: T) => void] {
  // Get from local storage then
  // parse stored json or return initialValue
  const readValue = () => {
    // Prevent build error "window is undefined" but keep keep working
    // Reason why we're ignoring this is that it is impossible to mock window as undefined
    /* istanbul ignore next */
    if (typeof window === 'undefined') {
      /* istanbul ignore next */
      return initialValue;
    }

    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      /* istanbul ignore next */
      console.warn(`Error reading localStorage key “${key}”:`, error);
      /* istanbul ignore next */
      return initialValue;
    }
  };

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(readValue);

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T) => {
    // Prevent build error "window is undefined" but keeps working
    /* istanbul ignore next */
    if (typeof window == 'undefined') {
      /* istanbul ignore next */
      console.warn(
        `Tried setting localStorage key “${key}” even though environment is not a client`
      );
    }

    try {
      // Allow value to be a function so we have the same API as useState
      const newValue = value instanceof Function ? value(storedValue) : value;

      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(newValue));

      // Save state
      setStoredValue(newValue);

      // We dispatch a custom event so every useLocalStorage hook are notified
      window.dispatchEvent(new Event('local-storage'));
    } catch (error) {
      /* istanbul ignore next */
      console.warn(`Error setting localStorage key “${key}”:`, error);
    }
  };

  useEffect(() => {
    setStoredValue(readValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      setStoredValue(readValue());
    };

    // this only works for other documents, not the current one
    window.addEventListener('storage', handleStorageChange);

    // this is a custom event, triggered in writeValueToLocalStorage
    window.addEventListener('local-storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('local-storage', handleStorageChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [storedValue, setValue];
}
