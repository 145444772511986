import Card from 'components/Card';
import Image from 'components/Image';

/**
 * Config
 */
const display = {
  title: 'Image'
};

/**
 * Component
 */
function ImagePage() {
  return (
    <Card className="my-8 p-6">
      <h5 className="text-2xl font-bold text-secondary-2-100">
        {display.title}
      </h5>
      <hr className="my-4" />
      <div className="my-4 flex justify-center">
        <Image src="https://images.tradeservice.com/ProductImages/DIR100111/CODCOM_110.3_MED.jpg" />
      </div>
      <div className="my-4 flex justify-center">
        <Image src="[BROKEN]" />
      </div>
    </Card>
  );
}

export default ImagePage;
