import BuilderIO from 'pages/BuilderIO/Core';

/**
 * Component
 */
function BuilderIOPage() {
  /**
   * Render
   */
  return (
    <div data-testid="builderio-page">
      <BuilderIO model="page" pathname={window.location.pathname} />
    </div>
  );
}
export default BuilderIOPage;
