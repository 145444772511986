// istanbul ignore file
import { ListItem, ListItemIcon, ListItemText, Skeleton } from '@mui/material';
import clsx from 'clsx';
import { kebabCase } from 'lodash-es';

import {
  AggregateFilterDisplay,
  IndividualAggregate
} from 'API/types/search.types';
import { CheckboxInput } from 'components';
import { ProductAttribute } from 'generated/graphql';

/**
 * Types
 */
type SearchSubfilterProps = {
  checked: boolean;
  filter?: AggregateFilterDisplay;
  handleToggle: (changedFilter: ProductAttribute) => void;
  subfilter: IndividualAggregate;
  stockText: string;
  loading: boolean;
  filterDisabled?: boolean;
};

/**
 * Component
 */
function SearchSubfilter(props: SearchSubfilterProps) {
  /**
   * Callback
   */
  const listItemClickCb = () => {
    if (props.filterDisabled) {
      return;
    }
    props.handleToggle({
      attributeType: props.filter?.filterKey,
      attributeValue: props.subfilter.value || ''
    });
  };

  /**
   * Render
   */
  return (
    <ListItem
      key={props.subfilter.value}
      onClick={listItemClickCb}
      className={clsx('!px-0 !py-2 !hover:bg-transparent cursor-pointer', {
        '!cursor-not-allowed': props.filterDisabled
      })}
      data-testid={kebabCase(`filter-line-item-${props.subfilter.value}`)}
    >
      <ListItemIcon className="!min-w-[auto] !mr-2">
        <CheckboxInput
          checked={props.checked}
          color="secondary"
          disabled={props.filterDisabled}
          data-testid={kebabCase(`checkbox-${props.subfilter.value}`)}
        />
      </ListItemIcon>
      <ListItemText className="!my-0">
        {props.filter?.filterKey === 'in_stock_location' && props.loading ? (
          <Skeleton variant="rectangular" className="w-full" />
        ) : (
          <p
            className={clsx('text-caption', {
              'text-secondary-3-100': props.filterDisabled,
              'text-primary-3-100': !props.filterDisabled
            })}
          >
            {props.stockText || props.subfilter.value}
            <span className="text-primary-3-100 font-light ml-1">
              ({props.subfilter.count})
            </span>
          </p>
        )}
      </ListItemText>
    </ListItem>
  );
}
export default SearchSubfilter;
