import { useTranslation } from 'react-i18next';

import { Button, Image, Link } from 'components';
import { ReactComponent as PoshIcon } from 'images/posh/poshIcon.svg';
import brochureImg from 'images/posh/poshBrochure.jpg';
import {
  buttonStyle,
  buttonStyleWhite
} from 'pages/BrandPages/Posh/lib/styles';
import { links } from 'utils/links';

/**
 * Component
 */
function PoshFooter() {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();

  /**
   * Render
   */
  return (
    <section data-testid="posh-section-footer">
      {/* Built to last */}
      <div className="px-[208px] py-20 flex flex-col justify-center items-center bg-etc-posh-4139 text-common-white md:px-8 md:py-16">
        <PoshIcon className="text-common-white" />
        <h2 className="mx-16 my-8 text-common-white text-[40px] leading-[1.2] font-bold text-center md:mx-0 md:text-[30px]">
          {t('poshMarketing.footerText')}
        </h2>
        <Link to={links.posh.mainShop}>
          <Button kind="outline" className={buttonStyleWhite}>
            {t('poshMarketing.shopTheCollection')}
          </Button>
        </Link>
      </div>
      {/* Brochure */}
      <div className="px-8 py-20 flex flex-col justify-center items-center bg-etc-light-gray md:px-[26px] md:py-16">
        <Image
          src={brochureImg}
          alt="brochure"
          className="mb-8 shadow-[3px_5px_6px_0px_#00000080]"
        />
        <a href={links.posh.brochure} download>
          <Button kind="outline" className={buttonStyle}>
            {t('poshMarketing.downloadBrochure')}
          </Button>
        </a>
      </div>
    </section>
  );
}

export default PoshFooter;
