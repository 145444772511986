import { useState, useEffect } from 'react';

import { Builder, builder, useIsPreviewing } from '@builder.io/react';

import BuilderButton from 'pages/BuilderIO/components/Button';

/**
 * Hook
 */
function useBuilderIOAPI(model: string, url: string, skip?: boolean) {
  /**
   * States
   */
  // 🔸 Due to BuilderIO's library has poor Typescript support and there isn't anyway for us to fix it, we have to use `any`
  const isPreviewingInBuilder = useIsPreviewing();
  const [content, setContent] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  /**
   * Effects
   */
  // 🟡 Effect - register Builder components
  useEffect(() => {
    // Register the CustomButton component with Builder.io
    Builder.registerComponent(BuilderButton, { name: 'Button' });
  }, []);
  // 🟡 Effect - API call
  useEffect(() => {
    // Fetch content from Builder CMS
    !skip &&
      builder
        .get(model, { url })
        .promise()
        .then((page) => {
          setLoading(false);
          setContent(page);
        })
        .catch((error) => {
          setError(true);
          console.error('Error fetching content:', error);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip]);

  /**
   * Output
   */
  return { content, error, isPreviewingInBuilder, loading };
}

export default useBuilderIOAPI;
