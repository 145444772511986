import { useCallback, FormEvent } from 'react';

import { useHistory } from 'react-router-dom';

import { useHeaderContext } from 'common/Header/HeaderProvider';
import { setLocalStorageSearchTerm } from 'common/OldHeader/lib/setLocalStorageSearchTerm';
import { useDomainInfo } from 'hooks/useDomainInfo';
import { useAuthContext } from 'providers/AuthProvider';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';
import { trackSearchAction } from 'utils/analytics';
import { timestamp } from 'utils/dates';

/**
 * Types
 */
export type UseSearchSubmitProps = {
  searchTerm: string;
  closeSearch: () => void;
};

/**
 * Hook
 */
function useSearchSubmit(props: UseSearchSubmitProps) {
  /**
   * Custom Hooks
   */
  const { push } = useHistory();
  const { brand } = useDomainInfo();

  /**
   * Context
   */
  const { ecommUser, authState, user } = useAuthContext();
  const { setSearchPage } = useHeaderContext();
  const { selectedAccounts } = useSelectedAccountsContext();

  /**
   * Callback
   */
  return useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!props.searchTerm) {
        return;
      }
      const authenticated = authState?.isAuthenticated;
      trackSearchAction({
        authenticated: authenticated,
        searchTerm: props.searchTerm,
        timestamp,
        userEmail: authenticated ? user?.email : null,
        homeBranch: selectedAccounts?.billToErpAccount?.branchId ?? '',
        brandAssociation: brand,
        billTo: selectedAccounts?.billTo?.erpAccountId ?? '',
        customerType: ecommUser?.role?.name ?? ''
      });
      setLocalStorageSearchTerm(props.searchTerm);
      setSearchPage(1);
      props.closeSearch();
      // Blur the Search input on submit so the Search Suggestions will appear
      // on subsequent searches
      document.activeElement instanceof HTMLElement &&
        document.activeElement.blur();
      // The & Character causes problems when being used in a string in the url
      const urlSafeSearchTerm: string = encodeURIComponent(props.searchTerm);
      push({ pathname: '/search', search: `?criteria=${urlSafeSearchTerm}` });
    },
    [
      props,
      authState?.isAuthenticated,
      user?.email,
      selectedAccounts?.billToErpAccount?.branchId,
      selectedAccounts?.billTo?.erpAccountId,
      brand,
      ecommUser?.role?.name,
      setSearchPage,
      push
    ]
  );
}

export default useSearchSubmit;
