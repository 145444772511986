import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { displayCount } from 'pages/Orders/util/lib';
import { SelectInput, SelectOption } from 'components';
import { DownloadIcon, PrintIcon } from 'icons';

/**
 * Config
 */
const displayCountOptions: SelectOption[] = displayCount.map((value) => ({
  label: value,
  value
}));

/**
 * Types
 */
export type DisplayProps = {
  displayCount?: string;
  setDisplayCount?: (count: string) => void;
  onExportClicked: () => void;
  showPrint?: boolean;
  hasResults?: boolean;
  callPrintOrders?: () => void;
  printOrdersLoading?: boolean;
};

/**
 * Component
 */
function DisplayBlock(props: DisplayProps) {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();

  const handleExportClicked = () => {
    props.onExportClicked();
  };

  return (
    <>
      <div className="ml-auto inline-flex">
        <div className="w-20 md:w-full mb-[7px]">
          <SelectInput
            label={t('common.display')}
            value={props.displayCount}
            listKey="searchcard-orders-display-count"
            onChange={props.setDisplayCount}
            testId={`orders-display-count-filter`}
            options={displayCountOptions}
          />
        </div>
        <div className="pt-7 py-3 md:w-20 flex flex-nowrap">
          <button
            type="button"
            onClick={handleExportClicked}
            className={clsx(
              'ml-2 px-2 py-2 whitespace-nowrap text-primary-3-80 font-bold bg-common-white rounded border border-gray-200',
              {
                'me-2 !py-1': props.showPrint
              }
            )}
            hidden={!props?.hasResults}
            data-testid="orders-export"
          >
            <label className="inline-flex cursor-pointer">
              <DownloadIcon transform="scale(0.75)" />
              <span className="mt-1 ml-1">{t('common.exportToExcel')}</span>
            </label>
          </button>
          {props.showPrint && props.callPrintOrders && (
            <button
              type="button"
              className="px-2 py-2 text-primary-3-80 font-bold bg-white rounded border border-gray-200"
              data-testid="orders-print"
              onClick={props.callPrintOrders}
              disabled={props.printOrdersLoading}
            >
              <label className="inline-flex pointer-events-none">
                <PrintIcon transform="scale(0.7)" />
                <span className="mt-1 ml-1">{t('common.print')}</span>
              </label>
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default DisplayBlock;
