import clsx from 'clsx';

import { WrapperProps } from '@reece/global-types';
import { Alert } from 'components';

/**
 * Types
 */
export type OverlayProps = WrapperProps & {
  show?: boolean;
  infoMessages?: string[];
};

/**
 * Component
 */
function Overlay(props: OverlayProps) {
  /**
   * Props
   */
  const { show, infoMessages } = props;

  /**
   * Render
   */
  return (
    <div className="relative" data-testid="overlay-wrapper">
      <div
        className={clsx('absolute w-full h-full', {
          'bg-common-white opacity-60': show,
          'opacity-0': !show
        })}
      />
      {show && (
        <div
          className="absolute w-full h-full z-[999]"
          data-testid="overlay-show"
        >
          <div className="flex items-center justify-center">
            <div className="w-1/2 md:w-full">
              <Alert severity="info" variant="filled">
                {infoMessages?.map((m: string) => (
                  <p key={m}>{m} </p>
                ))}
              </Alert>
            </div>
          </div>
        </div>
      )}
      {props.children}
    </div>
  );
}

export default Overlay;
