import { ChangeEvent, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import {
  Button,
  CircularProgress,
  MaskedInput,
  SelectInput,
  SelectOption,
  Textarea,
  TextInput
} from 'components';
import useSupportForm from 'pages/Support/lib/useSupportForm';
import updateZipcode from 'utils/updateZipCode';

/**
 * Component
 */
function ContactFormComponent() {
  /**
   * Custom Hook
   */
  const { t } = useTranslation();
  const { formik, sendLoading, userLoading } = useSupportForm();
  const loading = sendLoading || userLoading;

  /**
   * Memo
   */
  const topicList: SelectOption[] = useMemo(
    () =>
      [
        t('support.topicCustomerAccount'),
        t('support.topicProduct'),
        t('support.topicFeedback'),
        t('support.topicOther')
      ].map((v) => ({ label: v, value: v })),
    [t]
  );

  /**
   * Render
   */
  return (
    <div className="flex justify-center">
      <div className="basis-2/3 px-10 my-10 border-secondary-3-100 border-l md:basis-full md:border-l-0">
        <h5 className="text-primary-1-100 text-xl font-medium">
          {t('support.contactTitle')}
        </h5>
        <form
          onSubmit={formik.handleSubmit}
          noValidate
          className="pt-10 md:pt-8"
        >
          <div className="flex flex-wrap gap-y-4 md:gap-y-2">
            {/* <>-------------------------<> FIRST NAME <>-------------------------<> */}
            <div className="basis-1/2 pr-4 md:basis-full md:pr-2">
              <TextInput
                id="contact-first-name"
                name="firstName"
                label={t('common.firstName')}
                placeholder={t('common.firstName')}
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(
                  formik.touched.firstName && formik.errors.firstName
                )}
                message={
                  formik.touched.firstName && formik.errors.firstName
                    ? formik.errors.firstName
                    : ' '
                }
                testId="first-name-input"
                required
                disabled={loading}
              />
            </div>
            {/* <>-------------------------<> LAST NAME <>-------------------------<> */}
            <div className="basis-1/2 md:basis-full">
              <TextInput
                id="contact-last-name"
                name="lastName"
                label={t('common.lastName')}
                placeholder={t('common.lastName')}
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(
                  formik.touched.lastName && formik.errors.lastName
                )}
                message={
                  formik.touched.lastName && formik.errors.lastName
                    ? formik.errors.lastName
                    : ' '
                }
                testId="last-name-input"
                required
                disabled={loading}
              />
            </div>
            {/* <>-------------------------<> PHONE NUMBER <>-------------------------<> */}
            <div className="basis-1/2 pr-4 md:basis-full md:pr-2">
              <MaskedInput
                id="phone-number"
                name="phoneNumber"
                label={t('common.phoneNumber')}
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                onBlurCapture={(e) => {
                  formik.handleBlur(e);
                  formik.setTouched({ phoneNumber: true });
                }}
                error={Boolean(
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                )}
                message={
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                    ? formik.errors.phoneNumber
                    : ' '
                }
                mask="phone"
                placeholder="(000) 000-0000"
                testId="phone-number-input"
                disabled={loading}
              />
            </div>
            {/* <>-------------------------<> ZIP CODE <>-------------------------<> */}
            <div className="basis-1/2 md:basis-full">
              <MaskedInput
                id="zip-code"
                name="zip"
                label={t('common.zip')}
                placeholder="00000"
                value={formik.values.zip}
                onChange={(e) =>
                  updateZipcode(formik.handleChange)(
                    e as unknown as ChangeEvent<any>
                  )
                }
                onBlurCapture={(e) => {
                  formik.handleBlur(e);
                  formik.setTouched({ zip: true });
                }}
                error={Boolean(formik.touched.zip && formik.errors.zip)}
                message={
                  formik.touched.zip && formik.errors.zip
                    ? formik.errors.zip
                    : ' '
                }
                testId="zip-code-input"
                required
                mask="zipcode"
                disabled={loading}
              />
            </div>
            {/* <>-------------------------<> EMAIL ADDRESS <>-------------------------<> */}
            <div className="basis-full">
              <TextInput
                id="email"
                name="email"
                label={t('common.emailAddress')}
                placeholder={t('common.enterEmailAddress')}
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(formik.touched.email && formik.errors.email)}
                message={
                  formik.touched.email && formik.errors.email
                    ? formik.errors.email
                    : ' '
                }
                testId="email-input"
                required
                disabled={loading}
              />
            </div>
            {/* <>-------------------------<> TOPIC <>-------------------------<> */}
            <div className="basis-full" data-testid="topicContainer">
              <SelectInput
                id="topic"
                name="topic"
                label={t('common.topic')}
                value={formik.values.topic}
                required
                fullWidth
                message={
                  formik.touched.topic && formik.errors.topic
                    ? formik.errors.topic
                    : ' '
                }
                error={Boolean(formik.touched.topic && formik.errors.topic)}
                placeholder={t('common.select')}
                onChange={(value) => formik.setFieldValue('topic', value)}
                onBlur={formik.handleBlur}
                ref={null}
                listKey="select-topic"
                options={topicList}
                testId="topic-input"
              />
            </div>
            {/* <>-------------------------<> THE MAIN MESSAGE BOX <>-------------------------<> */}
            <div className="basis-full">
              <Textarea
                id="message"
                name="message"
                label={t('support.message')}
                value={formik.values.message}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(formik.touched.message && formik.errors.message)}
                message={
                  formik.touched.message && formik.errors.message
                    ? formik.errors.message
                    : ' '
                }
                testId="message-input"
                required
                rows={3}
                disabled={loading}
              />
            </div>
            {/* <>-------------------------<> SUBMIT BUTTON <>-------------------------<> */}
            <div className="basis-full flex justify-center">
              <div className="w-1/4 mt-2 md:w-1/2">
                <Button
                  type="submit"
                  data-testid="send-button"
                  disabled={loading}
                  fullWidth
                  size="lg"
                >
                  {sendLoading ? (
                    <CircularProgress
                      color="secondary"
                      size={24}
                      testId="send-button-loading"
                    />
                  ) : (
                    t('common.send')
                  )}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ContactFormComponent;
