import theme from 'providers/libs/Theme/baseTheme';

const muiCardHeader = {
  root: {
    padding: theme.spacing(2, 3)
  },
  action: {
    marginTop: -4,
    marginRight: -8,
    marginBottom: -4
  }
};

export default muiCardHeader;
