import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GetOrdersResponse } from 'API/types/orders.types';
import { Button, Pagination } from 'components';
import CircularProgress from 'components/CircularProgress';
import {
  OpenIcon,
  SortingAscIcon,
  SortingContractsNoneIcon,
  SortingDescIcon
} from 'icons';
import { formatDate } from 'utils/dates';
import { startCase } from 'lodash-es';
import clsx from 'clsx';
import { Tooltip } from '@mui/material';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';
import { ErpSystemEnum } from 'generated/graphql';
import { Link, useLocation } from 'react-router-dom';

export type TableProps = {
  headers: Array<{ id: string; label: string }>;
  content: Object;
  getOrdersResponse?: GetOrdersResponse;
  loading?: boolean;
  page?: string;
  sortBy?: {
    id: string;
    direction: boolean;
  };
  handlePageChange?: (page: string) => void;
  handleProofOfDelivery?: (orderNumber: string) => void;
  handleSortChange?: (
    sortBy: { id: string; direction: boolean },
    currentPage: string
  ) => void;
};

export default function TableRenderer(props: TableProps) {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();
  const { search } = useLocation();

  /**
   * Context
   */
  const { selectedAccounts } = useSelectedAccountsContext();
  const erpName = selectedAccounts?.erpSystemName ?? ErpSystemEnum.Eclipse;
  const tableContent = Object.values(props.content);

  /**
   * State
   */
  const [currentPage, setCurrentPage] = useState(parseInt(props?.page ?? '1'));
  const [sortBy, setSortBy] = useState(props?.sortBy);

  return (
    <table
      className="w-full text-sm text-left rtl:text-right border-stone-700 divide-y divide-gray-200"
      data-testid="orders-table"
    >
      <thead>
        <tr className="bg-secondary-4-30 border-secondary-4-100">
          {props.headers.map((column, key) => {
            return (
              <th
                scope="col"
                className={clsx('py-2 dark-text-gray-90', {
                  'text-right': column.id === 'orderTotal'
                })}
                key={key}
                data-testid={`orders-column-${key}`}
              >
                <div>
                  <Button
                    color="gray"
                    kind="text"
                    onClick={() => handleSortChange(column.id)}
                    data-testid={`orders-sort-${key}`}
                    className="inline !px-5"
                  >
                    {column.label} {`  `}
                    {sortBy?.id === column.id ? (
                      sortBy?.direction ? (
                        <SortingAscIcon
                          transform="scale(0.7)"
                          className="inline"
                          data-testid={`orders-ascending-${key}`}
                        />
                      ) : (
                        <SortingDescIcon
                          transform="scale(0.7)"
                          className="inline"
                          data-testid={`orders-descending-${key}`}
                        />
                      )
                    ) : (
                      <SortingContractsNoneIcon
                        transform="scale(0.7)"
                        className="inline"
                        data-testid={`orders-sorting-off-${key}`}
                      />
                    )}
                  </Button>
                </div>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {tableContent?.length ? (
          tableContent.map((data, key) => {
            return (
              <tr
                className="bg-white border-b hover:bg-secondary-4-50"
                key={key}
                data-testid={`orders-row-${key}`}
              >
                <td
                  className="px-5 py-4"
                  data-testid={`orders-orderdate-${key}`}
                >
                  {formatDate(new Date(data.orderDate?.replace(/-/g, '/')))}
                </td>
                <td
                  className="px-6 py-4 text-primary-2-100 underline"
                  data-testid={`orders-ordernumber-${key}`}
                >
                  <Link
                    to={`/order/${data.orderNumber}`}
                    data-testid={`orders-ordernumber-link-${key}`}
                    onClick={() => localStorage.setItem('filters', search)}
                  >
                    {data.orderNumber}
                  </Link>
                </td>
                <td
                  className="px-6 py-4 max-w-[150px]"
                  data-testid={`orders-po-${key}`}
                >
                  {data.poNumber ? (
                    <Tooltip placement="bottom" title={data.poNumber}>
                      <div className="truncate">{data.poNumber}</div>
                    </Tooltip>
                  ) : (
                    `--`
                  )}
                </td>
                <td
                  className="px-5 py-4"
                  data-testid={`orders-fulfillment-${key}`}
                >
                  {data.fulfillment}
                </td>
                <td
                  className="px-6 py-3"
                  data-testid={`orders-orderstatus-${key}`}
                >
                  <div
                    className={clsx(
                      'py-1 rounded font-semibold w-fit text-center px-2',
                      {
                        '!text-success-100 !bg-success-10':
                          data.orderStatus === 'SHIPPED' ||
                          data.orderStatus === 'Delivered' ||
                          data.orderStatus === 'Picked up'
                      },
                      {
                        '!text-secondary-2-100 bg-secondary-4-100':
                          data.orderStatus === 'Order placed' ||
                          data.orderStatus === 'INVOICED' ||
                          data.orderStatus === 'OPEN'
                      },
                      {
                        '!text-secondary-2-100 bg-primary-2-5':
                          data.orderStatus === 'In-transit' ||
                          data.orderStatus === 'Ready for pick up'
                      },
                      {
                        '!text-support-1-100 bg-support-1-10':
                          data.orderStatus === 'Canceled'
                      }
                    )}
                  >
                    {data.podStatus ? (
                      <Button
                        color="gray"
                        kind="text"
                        size="sm"
                        onClick={() =>
                          props.handleProofOfDelivery?.(data.orderNumber)
                        }
                        data-testid={`orders-pod-button-${key}`}
                        className="!px-0 !py-0 !text-success-100 font-semibold"
                      >
                        <div>
                          {erpName === ErpSystemEnum.Mincron
                            ? startCase(
                                data.orderStatus
                                  ? data.orderStatus.toString().toLowerCase()
                                  : ''
                              )
                            : data.orderStatus ?? ''}
                        </div>
                      </Button>
                    ) : (
                      <div>
                        {erpName === ErpSystemEnum.Mincron
                          ? startCase(
                              data.orderStatus
                                ? data.orderStatus.toString().toLowerCase()
                                : ''
                            )
                          : data.orderStatus ?? ''}
                      </div>
                    )}
                  </div>
                </td>
                <td
                  className="px-6 py-4"
                  data-testid={`orders-fulfilled-date-${key}`}
                >
                  {Boolean(data.fulfilledDate) &&
                    formatDate(
                      new Date(data.fulfilledDate?.replace(/-/g, '/'))
                    )}
                </td>
                <td
                  className="pl-6 pr-8 py-4 text-right"
                  data-testid={`orders-total-${key}`}
                >
                  {' '}
                  {Boolean(data.orderTotal) && `$`}
                  {data.orderTotal}
                </td>
                <td
                  className="px-6 py-4"
                  data-testid={`orders-invoicestatus-${key}`}
                >
                  {data.invoiceStatus ? (
                    <div>
                      {`${data.invoiceStatus} `}{' '}
                      <a
                        href={data.invoiceUrl ?? ''}
                        target="_blank"
                        rel="noreferrer"
                        data-testid={`orders-invoicestatus-link-${key}`}
                      >
                        <OpenIcon className="inline" />
                      </a>
                    </div>
                  ) : (
                    '--'
                  )}
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={8}>
              <div className="px-6 py-16 flex justify-center text-center">
                {props.loading ? (
                  <CircularProgress
                    size={50}
                    color="secondary"
                    testId="tablerender-loading"
                  />
                ) : (
                  <h4
                    className="text-secondary-2-100 text-2xl font-bold"
                    data-testid="no-orders-message"
                  >
                    {t('orders.noOrdersMessage')}
                  </h4>
                )}
              </div>
            </td>
          </tr>
        )}
      </tbody>
      <tfoot>
        <tr>
          <td className="px-6 py-3" colSpan={6} data-testid="orders-count">
            {handleFooterDisplay()}
          </td>
          {Boolean(props.getOrdersResponse?.totalItems) && (
            <td className="py-3" colSpan={2}>
              <div className="ml-auto pl-7 flex-end justify-self-end">
                <Pagination
                  current={currentPage}
                  ofText={t('common.of')}
                  count={props.getOrdersResponse?.totalPages ?? 1}
                  onChange={handleCurrentPage}
                  onNext={handleCurrentPage}
                  onPrev={handleCurrentPage}
                  align="center"
                  testId="pagination-nav"
                />
              </div>
            </td>
          )}
        </tr>
      </tfoot>
    </table>
  );

  function handleCurrentPage(page: number) {
    setCurrentPage(page);
    props?.handlePageChange?.(page.toString());
  }

  function handleSortChange(id: string) {
    const direction = sortBy?.id === id ? !sortBy?.direction : true;
    const updatedSortBy = { id, direction };
    setSortBy(updatedSortBy);
    props?.handleSortChange?.(updatedSortBy, currentPage.toString());
  }

  function handleFooterDisplay() {
    const {
      totalItems = 0,
      resultsPerPage = 0,
      page = 1
    } = props.getOrdersResponse ?? {};

    const itemsOnCurrentPage =
      totalItems > 0
        ? Math.min(totalItems - (page - 1) * resultsPerPage, resultsPerPage)
        : 0;

    let displayString;

    if (totalItems === 0) {
      displayString = t('orders.zeroOrders');
    } else if (totalItems <= resultsPerPage) {
      displayString = `${totalItems} of ${totalItems}`;
    } else {
      displayString = `${itemsOnCurrentPage} of ${totalItems}`;
    }
    return `${displayString} ${
      totalItems === 1 ? t('common.order') : t('common.orders')
    }`;
  }
}
