const muiAvatar = {
  root: {
    width: 30,
    height: 30
  },
  rounded: {
    borderRadius: '2px'
  }
};

export default muiAvatar;
