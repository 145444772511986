/**
 * Use for getting parameter from URL
 * @param url string
 * @param paramName string
 * @returns string
 */
export function getQueryParam(url: string, paramName: string) {
  try {
    const parsedUrl = new URL(url);
    const params = new URLSearchParams(parsedUrl.search);
    const paramData = params.get(paramName);
    return paramData ? decodeURIComponent(paramData) : '';
  } catch (e) {
    return '';
  }
}
