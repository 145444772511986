import { alpha } from '@mui/material/styles';

import palette from 'providers/libs/Theme/palette';

const muiCheckbox = {
  root: {
    color: alpha(palette.darkGray.main, 0.3)
  },
  colorPrimary: {
    '&.Mui-checked': {
      color: palette.primary02.main
    }
  }
};

export default muiCheckbox;
