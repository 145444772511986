import { ChangeEvent, useState } from 'react';

import Card from 'components/Card';
import TextInput from 'components/TextInput';

/**
 * Config
 */
const display = {
  title: 'Text Inputs',
  placeholder: 'Placeholder',
  label: 'Input',
  helper: 'Helper Text'
};

/**
 * Component
 */
function TextInputPage() {
  /**
   * States
   */
  const [text, setText] = useState('');

  /**
   * Callback
   */
  const onChange = (e: ChangeEvent<HTMLInputElement>) =>
    setText(e.target.value);

  /**
   * Render
   */
  return (
    <Card className="my-8 p-6">
      <h5 className="text-2xl font-bold text-secondary-2-100">
        {display.title}
      </h5>
      <hr className="my-4" />
      <div className="my-4 flex justify-center">
        <TextInput
          value={text}
          onChange={onChange}
          label={display.label}
          placeholder={display.placeholder}
          message="Test message"
          data-testid="component-page_text"
        />
        <TextInput
          value={text}
          onChange={onChange}
          label={display.label}
          placeholder={display.placeholder}
        />
        <TextInput
          value={text}
          onChange={onChange}
          label={display.label}
          placeholder={display.placeholder}
          error
        />
      </div>
      <div className="my-4 flex justify-center">
        <TextInput
          value={text}
          onChange={onChange}
          label={display.label}
          type="password"
          placeholder={display.placeholder}
          error
          message="Test message"
        />
        <TextInput
          value={text}
          onChange={onChange}
          label={display.label}
          type="password"
          placeholder={display.placeholder}
          disabled
        />
        <TextInput
          value={text}
          onChange={onChange}
          label={display.label}
          placeholder={display.placeholder}
          readOnly
        />
      </div>
    </Card>
  );
}

export default TextInputPage;
