import { alpha } from '@mui/material/styles';

import palette from 'providers/libs/Theme/palette';

const muiCard = {
  root: {
    boxShadow: 'none',
    // Default elevation is 8
    '&.MuiPaper-elevation8': {
      boxShadow: `2px 2px 10px 0 ${alpha(palette.primary03.main, 0.15)}`
    },
    '&.MuiPaper-rounded': {
      borderRadius: '10px'
    }
  }
};

export default muiCard;
