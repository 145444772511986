import { Backdrop, duration } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';

import { WrapperProps } from '@reece/global-types';
import { ChevronLeftIcon } from 'icons';
import { Button } from 'components';
import useSearchDrawerTransitions from 'common/OldHeader/lib/useSearchDrawerTransitions';

/**
 * Types
 */
export type SearchDrawerProps = WrapperProps & {
  open: boolean;
  headerHeight: number;
  onBackClick: () => void;
};

/**
 * Component
 */
function SearchDrawer(props: SearchDrawerProps) {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();
  const searchDrawerTransitions = useSearchDrawerTransitions();

  /**
   * Render
   */
  return (
    <>
      <Backdrop
        open={props.open}
        transitionDuration={{
          enter: duration.leavingScreen,
          exit: duration.enteringScreen
        }}
        className="z-[-1]"
      />
      <Transition in={props.open} timeout={150}>
        {(state) => (
          <div
            className="fixed inset-x-0 bottom-0 flex invisible bg-common-white overflow-hidden z-[1200]"
            style={{
              height: `calc(100% - ${props.headerHeight}px)`,
              top: props.headerHeight,
              ...searchDrawerTransitions[state]
            }}
            data-testid="header-searchdrawer-wrapper"
          >
            <div className="flex flex-col flex-1 m-4">
              <Button
                kind="text"
                iconStart={<ChevronLeftIcon />}
                onClick={props.onBackClick}
                className="self-start mb-4 !p-4 [&_svg]:!w-6 [&_svg]:!h-6"
              >
                {t('common.back')}
              </Button>
              <div className="flex-1 overflow-x-hidden overflow-y-auto">
                {props.children}
              </div>
            </div>
          </div>
        )}
      </Transition>
    </>
  );
}

export default SearchDrawer;
