/**
 * Table
 */
export const tableStyles = [
  '[&_.MuiTableBody-root_.MuiTableRow-root:nth-child(even)]:bg-secondary-4-60',
  '[&_.MuiTableBody-root_.MuiTableRow-root:nth-child(even):hover]:bg-primary-2-100/10',
  '[&_.MuiTableBody-root_.MuiTableCell-root]:relative',
  '[&_.MuiTableBody-root_.MuiTableCell-root]:h-12',
  '[&_.MuiTableBody-root_.MuiTableCell-root]:px-5',
  '[&_.MuiTableBody-root_.MuiTableCell-root]:py-4',
  '[&_.MuiTableBody-root_.MuiTableCell-root]:border-0',
  '[&_.MuiCheckbox-root]:relative',
  '[&_.MuiCheckbox-root]:-top-1',
  '[&_.MuiCheckbox-root]:p-0'
];

/**
 * Table cell
 */
export const ungroupedTableCellStyle =
  '[&_.MuiTableCell-root]:text-caption [&_.MuiTableCell-root]:px-5 [&_.MuiTableCell-root]:py-2';
