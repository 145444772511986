// 🔸 NOTE: Due to a Tailwind bug where it is processing
// escape string '\\' as 2 of '\', `String.raw` has
// to be implemented to prevent that bug to occur.

export const wrapperStyling = [
  String.raw`[&_.rdp-head\_cell]:font-normal`,
  String.raw`[&_.rdp-head\_cell]:text-secondary-2-100'`,
  String.raw`[&_.rdp-caption\_label]:text-base`,
  String.raw`[&_.rdp-caption\_label]:pl-2.5`,
  String.raw`[&_.rdp-caption\_label]:font-medium`,
  '[&_.rdp-nav_.rdp-button]:transition',
  '[&_.rdp-nav_.rdp-button:hover:not([disabled])]:bg-primary-2-10',
  '[&_.rdp-nav_.rdp-button:active:not([disabled])]:border-2',
  '[&_.rdp-nav_.rdp-button:focus:not([disabled])]:border-2',
  '[&_.rdp-nav_.rdp-button:active:not([disabled])]:border-primary-2-100',
  '[&_.rdp-nav_.rdp-button:focus:not([disabled])]:border-primary-2-100',
  '[&_.MuiSvgIcon-root]:text-2xl',
  '[&_.MuiSvgIcon-root]:text-primary-100',
  '[&_.rdp-day]:transition',
  '[&_.rdp-day]:relative',
  '[&_.rdp-day]:z-0',
  '[&_.rdp-day]:h-10',
  '[&_.rdp-day]:text-sm',
  '[&_.rdp-day:focus]:border-0',
  String.raw`[&_.rdp-day:not(.rdp-day\_disabled)_.backdrop]:transition`,
  String.raw`[&_.rdp-day:not(.rdp-day\_disabled)_.backdrop]:border-solid`,
  String.raw`[&_.rdp-day:not(.rdp-day\_disabled)_.backdrop]:rounded-full`,
  String.raw`[&_.rdp-day:not(.rdp-day\_disabled)_.backdrop]:border-2`,
  String.raw`[&_.rdp-day:not(.rdp-day\_disabled)_.backdrop]:border-transparent`,
  String.raw`[&_.rdp-day:not(.rdp-day\_disabled)_.backdrop:hover]:border-primary-2-100`,
  '[&_.rdp-day_.backdrop]:z-5',
  '[&_.rdp-day_.backdrop]:absolute',
  '[&_.rdp-day_.backdrop]:w-6',
  '[&_.rdp-day_.backdrop]:h-6',
  '[&_.rdp-day_.backdrop]:box-content',
  '[&_.rdp-day_.backdrop]:leading-[10]',
  String.raw`[&_.rdp-day:not(.rdp-day\_disabled)_.backdrop]:cursor-pointer`,
  String.raw`[&_.rdp-day.rdp-day\_selected]:!bg-primary-2-10`,
  String.raw`[&_.rdp-day.rdp-day\_selected:not([disabled])]:!border-none`,
  String.raw`[&_.rdp-day.rdp-day\_selected_.backdrop]:text-common-background`,
  String.raw`[&_.rdp-day.rdp-day\_selected_.backdrop]:bg-primary-2-100`,
  String.raw`[&_.rdp-day.rdp-day\_selected_.backdrop]:border-2`,
  String.raw`[&_.rdp-day.rdp-day\_selected_.backdrop]:border-solid`,
  String.raw`[&_.rdp-day.rdp-day\_selected_.backdrop]:border-primary-2-100`,
  String.raw`[&_.rdp-day.rdp-day\_selected_.backdrop]:leading-6`,
  String.raw`[&_.rdp-day.rdp-day\_selected_.backdrop]:font-semibold`,
  String.raw`[&_.rdp-day.rdp-day\_range\_start]:!rounded-l-full`,
  String.raw`[&_.rdp-day.rdp-day\_range\_start]:pr-1`,
  String.raw`[&_.rdp-day.rdp-day\_range\_start]:w-[37px]`,
  String.raw`[&_.rdp-day.rdp-day\_range\_start]:right-[-4px]`,
  String.raw`[&_.rdp-day.rdp-day\_range\_start:hover]:bg-primary-2-10`,
  String.raw`[&_.rdp-day.rdp-day\_range\_end]:!rounded-r-full`,
  String.raw`[&_.rdp-day.rdp-day\_range\_end]:pl-1`,
  String.raw`[&_.rdp-day.rdp-day\_range\_end]:w-[37px]`,
  String.raw`[&_.rdp-day.rdp-day\_range\_end:focus]:border-0`,
  String.raw`[&_.rdp-day.rdp-day\_range\_end:hover]:bg-primary-2-10`,
  String.raw`[&_.rdp-day.rdp-day\_range\_middle]:!bg-primary-2-10`,
  String.raw`[&_.rdp-day.rdp-day\_outside]:rounded-none`,
  String.raw`[&_.rdp-day.rdp-day\_outside]:bg-secondary-4-60`,
  String.raw`[&_.rdp-day.rdp-day\_outside]:text-secondary-3-100`,
  String.raw`[&_.rdp-day.rdp-day\_outside.rdp-day\_range\_middle]:bg-primary-2-10`,
  String.raw`[&_.rdp-day.rdp-day\_outside.rdp-day\_range\_middle]:text-primary-3-100`,
  '[&_.rdp-day.rdp-button:hover]:bg-secondary-4-60'
];

export const popperStyling =
  'mt-4 bg-common-white rounded shadow-lg border border-solid border-secondary-4-100';
