const muiPaper = {
  root: {
    transition: 'none'
  },
  rounded: {
    borderRadius: '4px'
  }
};

export default muiPaper;
