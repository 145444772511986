import { Container, Toolbar, AppBar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import FooterLinks from 'common/Footer/FooterLinks';
import FooterConnect from 'common/Footer/FooterConnect';
import { Link } from 'components';
import logoSrc from 'images/logo.svg';

/** //
 * Config
 */
const year = new Date().getFullYear();

/**
 * Component
 */
function Footer() {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();
  const { pathname } = useLocation();

  /**
   * Memo
   */
  const isValidPathname = !pathname.includes('register');
  const isCheckout = pathname.includes('checkout');

  /**
   * Render
   */
  if (
    pathname.includes('/barcode') ||
    pathname.includes('/careers') ||
    pathname.includes('/banner/')
  ) {
    return null;
  }
  return (
    <AppBar
      color="inherit"
      position="relative"
      elevation={0}
      className="border border-primary-3-10 [&_.MuiToolbar-root]:min-h-auto [&_.MuiToolbar-root]:py-4 md:[&_.MuiToolbar-root]:py-6 print:hidden"
      data-testid="footer"
    >
      {!isCheckout && (
        <Toolbar disableGutters>
          {isValidPathname && (
            <Container maxWidth="lg" data-testid="footer-title-toolbar">
              <div className="mt-4 flex items-center gap-6">
                <Link to="/" className="flex">
                  <img
                    alt="Reece"
                    data-testid="reecestandard-logo"
                    src={logoSrc}
                    className="w-[104px] h-9"
                  />
                </Link>
                <h5 className="text-primary-1-100 text-xl font-medium">
                  {t('footer.motto')}
                </h5>
              </div>
            </Container>
          )}
        </Toolbar>
      )}
      {isValidPathname && !isCheckout && (
        <Toolbar disableGutters>
          <Container maxWidth="lg" data-testid="footer-links-toolbar">
            <div className="grid grid-cols-5 gap-2 mt-2">
              <div className="col-span-3 md:col-span-5">
                <FooterLinks />
              </div>
              <div className="col-span-2 md:col-span-5">
                <FooterConnect />
              </div>
            </div>
          </Container>
        </Toolbar>
      )}
      <Toolbar disableGutters className="border-t border-primary-3-10">
        <Container maxWidth="lg">
          <div className="flex flex-row justify-between items-center text-secondary-2-70 text-xs leading-[18px] md:flex-col md:justify-center">
            {isCheckout && (
              <div className="flex gap-x-1 [&_a]:!text-[#939393]">
                <Link to="/terms-of-access">{t('footer.termsAccess')}</Link>
                <span>|</span>
                <Link to="/privacy-policy">{t('footer.privacy')}</Link>
                <span>|</span>
                <Link to="/terms-of-sale">{t('footer.termsSale')}</Link>
              </div>
            )}
            <div className="flex-shrink-0 ml-auto md:ml-0">
              &copy; {t('common.copyright', { year })}
            </div>
          </div>
        </Container>
      </Toolbar>
    </AppBar>
  );
}

export default Footer;
