import forge from 'node-forge';

const publicKeyPem = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAp2HKT1TGCWvIFe72e3pH
G+wCX6ZHk3fPyow9zW0KCtarmX4hcOtWkIHLcl3uZFdn81jB3mx7Vr8qOn/N+NqK
LVkQl2Eb0Ey+F076SzLe18zuv22iPYqg85lfpn5ioLy4Xi/Tpd8NR1FDuHPNuMUw
0Ibk2qNmzOHiMi+y/t/j/um42du2LEJpSWbzUAaJuSP/Xc7ud4+PR76XbA9msx6s
LFDMPQ2wNI348uuYLfxRXRnKiQDdOcllFq3+5TT9NC02liG6OlWc0sYpsIHaKdjU
9eGc3xWIDVizhFqk/8/rWMXx3cjL9Ium9BQTc7rVQn0caH8g9sx3jt+vviDBotQb
MQIDAQAB
-----END PUBLIC KEY-----`;

export function encryptData(data: string): string {
  const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);
  const encrypted = publicKey.encrypt(data, 'RSA-OAEP', {
    md: forge.md.sha256.create(),
    mgf1: forge.mgf.mgf1.create(forge.md.sha1.create())
  });
  return forge.util.encode64(encrypted);
}
