import React from 'react';

import { Box, Typography } from '@dialexa/reece-component-library';
import { useTranslation } from 'react-i18next';

function TermsOfAccess() {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();

  return (
    <>
      <Box>
        <Typography variant="h4">{t('legal.termsOfAccess')}</Typography>
      </Box>
      <Box mt={4} mx={1}>
        <Typography variant="body1">
          <b>IMPORTANT NOTICE</b>
        </Typography>
      </Box>
      <Box mt={4} mx={1}>
        <Typography variant="body1">
          <b>
            USE OF THIS WEBSITE BY ANY PARTY ("YOU") CONSTITUTES YOUR ACCEPTANCE
            OF THE FOLLOWING TERMS.
          </b>
        </Typography>
      </Box>
      <Box mt={2} mx={1}>
        <Typography variant="body1">
          The terms constitute a binding agreement between you and MORSCO, Inc.
          d/b/a Reece, its parent, subsidiaries and affiliates, ("Company”).
          Read the terms carefully. If you do not accept these terms, please
          refrain from using this or any other Company website. The Company
          reserves the right to change its website and these terms at any time
          without prior notice.
        </Typography>
      </Box>
      <Box mt={4} mx={1}>
        <Typography variant="body1">
          <b>Content Use</b>
        </Typography>
      </Box>
      <Box mt={2} mx={1}>
        <Typography variant="body1">
          The documents, graphics, and other information ("Content") posted on
          any of the Company websites are owned by MORSCO, Inc., or are provided
          with permission of the owner.
        </Typography>
      </Box>
      <Box mt={2} mx={1}>
        <Typography variant="body1">
          The information provided on this website is for general informational
          and educational purposes for members of the general public, investors,
          customers, partners and potential partners. Certain sections of this
          website are intended for particular audiences, including Company,
          vendors, potential acquisitions, the media, customers, as well as
          members of the general public. Your access to and use of the
          information contained on the website is subject to this Terms of Use
          Agreement. By accessing and using this website, you accept, without
          limitation or qualification, this Terms of Use Agreement.
        </Typography>
      </Box>
      <Box mt={2} mx={1}>
        <Typography variant="body1">
          You may access the Content solely for your personal, noncommercial
          use. You may download or print a copy of portions of the Content,
          provided any copy retains all applicable copyright notices. All other
          rights, title, and interest to the Content are expressly reserved by
          the Company.
        </Typography>
      </Box>
      <Box mt={2} mx={1}>
        <Typography variant="body1">
          You will comply with all applicable laws in connection with the use of
          this website. You will not engage in conduct on or in connection with
          this website that is illegal, misleading, infringing, defamatory,
          obscene, offensive, or otherwise objectionable. You will not cause
          damage, embarrassment, or adverse publicity to the Company or its
          affiliates.
        </Typography>
      </Box>
      <Box mt={2} mx={1}>
        <Typography variant="body1">
          You will cooperate with all reasonable requests of the Company and
          will notify the Company promptly upon learning of any actual or
          suspected breach of these terms and conditions by you or unauthorized
          use or abuse of this website. No other permission is granted to you to
          print, copy, reproduce, distribute, transmit, upload, download, store,
          display in public, alter, or modify the Content. You shall indemnify
          and hold the Company harmless against any liability (including
          reasonable attorney fees) arising from or related to your failure to
          comply with these Terms of Use.
        </Typography>
      </Box>
      <Box mt={4} mx={1}>
        <Typography variant="body1">
          <b>Website and Registration Administration</b>
        </Typography>
      </Box>
      <Box mt={2} mx={1}>
        <Typography variant="body1">
          Per the "Use of Content" terms above, you may link to any page of this
          website as long as the link is without framing. However, in the event
          the Company deems your linking practices in relation to this website
          to be inappropriate, the Company may provide notice to you concerning
          removal or modification of the inappropriate link, and you agree to
          comply with any and all requirements of the Company relating thereto.
          Upon notification by Company as provided above, you may link to this
          website unless and until the Company gives notice that you must
          discontinue linking to this website.
        </Typography>
      </Box>
      <Box mt={2} mx={1}>
        <Typography variant="body1">
          The Company may, in its discretion modify, edit, translate, suspend,
          restrict access to, or terminate this website, these terms and
          conditions, the Content, or any link at any time without liability or
          prior notice. The Company may in its discretion terminate the browsing
          of, registration with, and use of this website by you at any time
          without liability or prior notice for any reason, including for any
          breach of these terms and conditions.
        </Typography>
      </Box>
      <Box mt={4} mx={1}>
        <Typography variant="body1">
          <b>Third-Party Website Links</b>
        </Typography>
      </Box>
      <Box mt={2} mx={1}>
        <Typography variant="body1">
          As a convenience to you, this website may contain content, links, and
          other information submitted by third parties over whom the Company has
          no control or responsibility. The Company has no obligation to
          monitor, control, or restrict the use of this website, or third-party
          websites accessible via links on this website. These other sites are
          not under the control of the Company, and you acknowledge that
          (whether or not such sites are affiliated in any way with the Company.
          The Company is not responsible for and makes no warranties or
          representations of any kind as to the accuracy, copyright compliance,
          legality, decency, or any other aspect of the content of such sites.
          The inclusion of such a link does not imply endorsement of any site by
          the Company or any association with its operators. Moreover, the
          Company has no obligation to verify any content submitted by you or to
          provide any registration or other information to you concerning any
          content.
        </Typography>
      </Box>
      <Box mt={4} mx={1}>
        <Typography variant="body1">
          <b>Registration and Passwords</b>
        </Typography>
      </Box>
      <Box mt={2} mx={1}>
        <Typography variant="body1">
          The Company may or may not at times require you to have a password and
          provide registration details to access this website or portions of
          this website. If the Company does require a password and registration
          details, it shall be a condition of use of this website that all the
          details you provide are correct, current, and complete. If the Company
          believes that the details are not correct, current, or complete, the
          Company will have the right to refuse you access to the website, or
          any of its resources, and to terminate or suspend your account, if
          any. You are responsible for maintaining the confidentiality of any
          password(s) you are given to access this website, and you are fully
          responsible for all activities that occur under your password(s). You
          agree to notify the Company immediately of any unauthorized use of
          your password(s). The Company reserves the absolute right not to issue
          a password to any person or entity.
        </Typography>
      </Box>
      <Box mt={4} mx={1}>
        <Typography variant="body1">
          <b>Disclaimer</b>
        </Typography>
      </Box>
      <Box mt={2} mx={1}>
        <Typography variant="body1">
          The website and its Content are provided free of charge and on an AS
          IS basis. The Company does not warrant that your use will be
          uninterrupted or error-free. This website may contain inaccuracies,
          out-of-date information, and typographical errors. The Company makes
          no warranties or representations of any kind as to the accuracy,
          currency, completeness, or reliability of any advice, opinion,
          statement, or other information displayed or distributed through this
          website. By use of this website, you acknowledge that any reliance on
          any such opinion, advice, statement, report, or information shall be
          at your sole risk. The Company reserves the right, in its sole
          discretion, to correct any errors or omissions in any portion of the
          website, and to make other changes to the website at any time without
          notice.
        </Typography>
      </Box>
      <Box mt={2} mx={1}>
        <Typography variant="body1">
          <b>
            YOU AGREE THAT ACCESS TO AND USE OF THIS WEBSITE AND THE CONTENT
            THEREOF IS AT YOUR OWN RISK. THE COMPANY PROVIDES NO WARRANTY,
            WHETHER EXPRESS OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
            NONINFRINGEMENT IN CONNECTION WITH THIS WEBSITE OR ITS CONTENT.
          </b>
        </Typography>
      </Box>
      <Box mt={2} mx={1}>
        <Typography variant="body1">
          <b>
            NEITHER THE COMPANY, NOR ANY PARTY INVOLVED IN CREATING, PRODUCING,
            OR DELIVERING THIS WEBSITE SHALL BE LIABLE FOR ANY DAMAGES,
            INCLUDING, WITHOUT LIMITATION, DIRECT, INCIDENTAL, CONSEQUENTIAL,
            INDIRECT, OR PUNITIVE DAMAGES ARISING OUT OF ACCESS TO, USE OF, OR
            INABILITY TO USE THIS WEBSITE, OR ANY ERRORS OR OMISSIONS IN THE
            CONTENT THEREOF. THIS LIMITATION INCLUDES DAMAGES TO, OR FOR ANY
            VIRUSES THAT INFECT, YOUR COMPUTER EQUIPMENT.
          </b>
        </Typography>
      </Box>
      <Box mt={4} mx={1}>
        <Typography variant="body1">
          <b>Limitation of Liability</b>
        </Typography>
      </Box>
      <Box mt={2} mx={1}>
        <Typography variant="body1">
          In no event shall the Company be liable to any party as a result of
          use of this website for any direct, indirect, special, incidental, or
          consequential damages (including, without limitation, damages for lost
          profits, lost data, or business interruption), whether based on
          warranty, contract, negligence, tort, or any other legal theory, even
          if the Company has been advised of the possibility of such damages.
        </Typography>
      </Box>
      <Box mt={4} mx={1}>
        <Typography variant="body1">
          <b>Indemnification</b>
        </Typography>
      </Box>
      <Box mt={2} mx={1}>
        <Typography variant="body1">
          You agree to indemnify, defend, and hold harmless the Company, its
          officers, directors, employees, agents, suppliers, and third-party
          partners from and against all losses, expenses, damages, and costs,
          including reasonable attorneys’ fees, resulting from any violation by
          you of these Terms of Use.
        </Typography>
      </Box>
      <Box mt={4} mx={1}>
        <Typography variant="body1">
          <b>Governing Law</b>
        </Typography>
      </Box>
      <Box mt={2} mx={1}>
        <Typography variant="body1">
          The Company maintains this site in Dallas, Texas (USA). The Company
          makes no representation that materials on this website are appropriate
          or available for use at locations outside of the United States, and
          access to this website from locations where its contents are illegal
          is prohibited. You may not use this website or export the materials on
          this website in violation of U.S. export laws and regulations. If you
          access the website from a location outside of the United States, you
          are responsible for compliance with all local laws.
        </Typography>
      </Box>
      <Box mt={2} mx={1}>
        <Typography variant="body1">
          These Terms of Use are governed by and construed in accordance with
          the laws of the State of Texas, without regards to Texas’ conflict of
          law principles. Any dispute relating to this Agreement shall be
          submitted to arbitration conducted in Dallas, Texas.
        </Typography>
      </Box>
      <Box mt={2} mx={1}>
        <Typography variant="body1">
          You are responsible for complying with the laws of the jurisdiction
          from which you are accessing this website, and you agree that you will
          not access or use the information on this site in violation of such
          laws.
        </Typography>
      </Box>
      <Box mt={4} mx={1}>
        <Typography variant="body1">
          <b>Intellectual Property</b>
        </Typography>
      </Box>
      <Box mt={2} mx={1}>
        <Typography variant="body1">
          The entire contents of this website are subject to copyright
          protection. The contents of this website may not be copied other than
          for noncommercial individual reference with all copyright or other
          proprietary notices retained, and thereafter may not be recopied,
          reproduced, or otherwise redistributed. Except as expressly provided
          above, you may not otherwise copy, display, download, distribute,
          modify, reproduce, republish, or retransmit any information, text, or
          documents contained in this website or any portion thereof in any
          electronic medium or in hard copy, or create any derivative work based
          on such images, text, or documents, without the express written
          consent of the Company. Nothing contained herein shall be construed as
          conferring by implication, estoppel, or otherwise any license or right
          under any patent or trademark of the Company or any third party.
        </Typography>
      </Box>
      <Box mt={2} mx={1}>
        <Typography variant="body1">
          The Company, its logo and other logos, taglines, and slogans on this
          website are service marks, trademarks, or registered trademarks of the
          Company. Other product or company names may be the trademarks or
          service marks of their respective owners.
        </Typography>
      </Box>
      <Box mt={2} mx={1}>
        <Typography variant="body1">
          Elements of this website are protected by trade dress and other laws,
          and this website and its appearance, including website code and
          scripts, may not be copied or imitated in any way without the express
          written consent of the Company.
        </Typography>
      </Box>
      <Box mt={4} mx={1}>
        <Typography variant="body1">
          <b>Forward-Looking Statements</b>
        </Typography>
      </Box>
      <Box mt={2} mx={1}>
        <Typography variant="body1">
          Some of the information on this website and the documents within it
          may contain forward-looking statements regarding future events or the
          future financial performance of the Company. Any forward-looking
          statement speaks only as of the date made. We undertake no obligation
          to update any forward-looking statements to reflect events or
          circumstances arising after the date on which they are made.
        </Typography>
      </Box>
      <Box mt={4} mx={1}>
        <Typography variant="body1">
          <b>Miscellaneous</b>
        </Typography>
      </Box>
      <Box mt={2} mx={1}>
        <Typography variant="body1">
          This Terms of Use Agreement constitutes the entire agreement between
          you and the Company with respect to the use of this website. If for
          any reason a court of competent jurisdiction finds any provision of
          this Terms of Use Agreement or portion thereof to be unenforceable,
          that provision shall be enforced to the maximum extent permissible so
          as to effect the intent of this Terms of Use Agreement, and the
          remainder of this Terms of Use Agreement shall continue in full force
          and effect.
        </Typography>
      </Box>
      <Box mt={2} mx={1}>
        <Typography variant="body1">
          MORSCO, Inc., “Reece”. All rights reserved.
        </Typography>
      </Box>
      <Box mt={2} mx={1}>
        <Typography variant="body1">
          Last updated September 24, 2021.
        </Typography>
      </Box>
    </>
  );
}
export default TermsOfAccess;
