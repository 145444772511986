import { APIOptions } from 'API/hooks/useApiBase';
import { UseAPILazyProps } from 'API/hooks/useApiLazy';
import { useApiLazyAuth } from 'API/hooks/useApiLazyAuth';
import { makeApiOnMountAuth } from 'API/lib/makeApi';
import {
  AddItemsToCartRequest,
  AddItemsToCartResponse,
  CartChangeResponse,
  CartProps,
  DeleteItemResponse,
  GetCartPricingAvailabilityResponse,
  GetCartResponse,
  RemoveAllCartItemsResponse,
  UpdateCartItemQtyRequest,
  UpdateCartItemQtyResponse,
  UpdateWillCallBranchRequest
} from 'API/types/cart.types';
import { generateQueryParam } from 'utils/generateQueryParam';

/**
 * Config
 */
const BASE_URL = 'cart';

/**
 * Hooks
 */
//  🟦 GET /cart/{cartId}/getCart - Get cart
export const useApiGetCart = makeApiOnMountAuth<GetCartResponse>({
  url: `${BASE_URL}/getCart`,
  kind: 'get',
  header: {}
});

//  🟦 GET /cart/{cartId}/getCartPricingAvailability - Get product pricing and availability on cart line items and return cart subtotal
export function useApiGetCartPricingAvailability(
  options: APIOptions<GetCartPricingAvailabilityResponse> = {}
) {
  const props: UseAPILazyProps<GetCartPricingAvailabilityResponse> = {
    url: '',
    kind: 'get',
    options,
    header: {}
  };
  const api = useApiLazyAuth<GetCartPricingAvailabilityResponse>(props);
  const call = (cartId: string) =>
    api.call({
      ...props,
      url: `${BASE_URL}/getCartPricingAvailability/${cartId}`
    });
  return { ...api, call };
}

// 🟩 PATCH /cart/{cartId}/updateWillCallBranch - Update will call branch on cart
export function useApiUpdateWillCallBranch(
  options: APIOptions<CartChangeResponse> = {}
) {
  const props: UseAPILazyProps<CartChangeResponse> = {
    url: '',
    kind: 'patch',
    options,
    header: {}
  };
  const api = useApiLazyAuth<UpdateWillCallBranchRequest>(props);
  const call = (cartId: string) =>
    api.call({
      ...props,
      url: `${BASE_URL}/updateWillCallBranch/${cartId}`
    });
  return { ...api, call };
}

// 🟩 PATCH /cart/{cartId}/updateCartItemQty - Update item quantity
export function useApiUpdateCartItemQty(
  options: APIOptions<UpdateCartItemQtyResponse> = {}
) {
  const props: UseAPILazyProps<UpdateCartItemQtyResponse> = {
    url: '',
    kind: 'patch',
    options,
    header: {}
  };
  const api = useApiLazyAuth<UpdateCartItemQtyResponse>(props);
  const call = (cartId: string, body: UpdateCartItemQtyRequest) =>
    api.call({
      ...props,
      body,
      url: `${BASE_URL}/updateCartItemQty/${cartId}`
    });
  return { ...api, call };
}

// 🟩 PATCH /cart/{cartId}/updateCartDeliveryPreferences - Update delivery preferences
export function useApiUpdateCartDeliveryPreferences(
  options: APIOptions<CartChangeResponse> = {}
) {
  const props: UseAPILazyProps<CartChangeResponse> = {
    url: '',
    kind: 'patch',
    options,
    header: {}
  };
  const api = useApiLazyAuth<CartChangeResponse>(props);
  const call = (cartId: string, shouldShipFullOrder: boolean) =>
    api.call({
      ...props,
      url: generateQueryParam(
        `${BASE_URL}/updateCartDeliveryPreferences/${cartId}`,
        { shouldShipFullOrder }
      )
    });
  return { ...api, call };
}

// 🟩 PATCH /cart/{cartId}/updateCartDeliveryMethod - Update delivery method on Cart
export function useApiUpdateCartDeliveryMethod(
  options: APIOptions<CartChangeResponse> = {}
) {
  const props: UseAPILazyProps<CartChangeResponse> = {
    url: '',
    kind: 'patch',
    options,
    header: {}
  };
  const api = useApiLazyAuth<CartChangeResponse>(props);
  const call = (cartId: string, deliveryMethod: CartProps['deliveryMethod']) =>
    api.call({
      ...props,
      url: generateQueryParam(
        `${BASE_URL}/updateCartDeliveryMethod/${cartId}`,
        { deliveryMethod }
      )
    });
  return { ...api, call };
}

// 🟩 PATCH /cart/{cartId}/addCartItem - Add items to the cart
export function useApiAddCartItem(
  options: APIOptions<AddItemsToCartResponse> = {}
) {
  const props: UseAPILazyProps<AddItemsToCartResponse> = {
    url: '',
    kind: 'patch',
    options,
    header: {}
  };
  const api = useApiLazyAuth<AddItemsToCartResponse>(props);
  const call = (body: AddItemsToCartRequest) =>
    api.call({
      ...props,
      body,
      url: `${BASE_URL}/addToCart`
    });
  return { ...api, call };
}

// 🟥 DELETE /cart/{cartId}/removeAllCartItems - Remove all line items from cart
export function useApiRemoveAllCartItems(
  options: APIOptions<RemoveAllCartItemsResponse> = {}
) {
  const props: UseAPILazyProps<RemoveAllCartItemsResponse> = {
    url: '',
    kind: 'delete',
    options,
    header: {}
  };
  const api = useApiLazyAuth<RemoveAllCartItemsResponse>(props);
  const call = (cartId: string) =>
    api.call({
      ...props,
      url: `${BASE_URL}/removeAllCartItems/${cartId}`
    });
  return { ...api, call };
}

// 🟥 DELETE /cart/{cartId}/deleteCartItem/{lineItemId} - Delete line item
export function useApiDeleteCartItem(
  options: APIOptions<DeleteItemResponse> = {}
) {
  const props: UseAPILazyProps<DeleteItemResponse> = {
    url: '',
    kind: 'delete',
    options,
    header: {}
  };
  const api = useApiLazyAuth<DeleteItemResponse>(props);
  const call = (cartId: string, lineItemId: string) =>
    api.call({
      ...props,
      url: `${BASE_URL}/deleteCartItem/${cartId}/${lineItemId}`
    });
  return { ...api, call };
}
