import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import Card from 'components/Card';
import FormAutocompleteInput from 'components/FormInputs/AutoComplete';
import FormMaskedInput from 'components/FormInputs/Mask';
import FormRadioInput from 'components/FormInputs/Radio';
import FormSelectInput from 'components/FormInputs/Select';
import FormTextInput from 'components/FormInputs/Text';
import Button from 'components/Button';
import { componentShowMessage } from 'pages/Components/lib/util';

/**
 * Config
 */
const display = {
  title: 'Form Inputs',
  submit: 'Submit'
};
const defaultValues: FormFields = {
  autocomplete: 'test',
  masked: '123',
  radio: '',
  select: '',
  text: 'Test'
};
const autocompleteOptions = [
  { label: 'Test', value: 'test' },
  { label: '123', value: '123' },
  { label: 'Auto Complete', value: 'autocomplete' },
  { label: 'Select Me', value: 'selectme' },
  { label: 'Test 2', value: 'test 2' }
];
const radioOptions = [
  { label: 'Letter', value: 'abc' },
  { label: 'Number', value: '123' },
  { label: 'Special', value: '.,?' }
];
const selectOptions = [
  { label: 'Select', value: 'select' },
  { label: 'ABC', value: 'abc' },
  { label: '123', value: '123' }
];
const resolver = yupResolver(
  yup
    .object({
      autocomplete: yup.string().required(),
      masked: yup.string().required(),
      radio: yup.string().required(),
      select: yup.string().required(),
      text: yup.string().required()
    })
    .required()
);

/**
 * Types
 */
type FormFields = {
  autocomplete: string;
  masked: string;
  radio: string;
  select: string;
  text: string;
};

/**
 * Component
 */
function FormInputsPage() {
  /**
   * Custom hook
   */
  const { control, handleSubmit } = useForm<FormFields>({
    defaultValues,
    resolver,
    mode: 'onTouched',
    reValidateMode: 'onSubmit'
  });

  /**
   * Render
   */
  return (
    <Card className="my-8 p-6">
      <h5 className="text-2xl font-bold text-secondary-2-100">
        {display.title}
      </h5>
      <hr className="my-4" />
      <form onSubmit={handleSubmit(componentShowMessage('Submit'))}>
        <div className="my-4 flex justify-center">
          <FormAutocompleteInput
            label="Autocomplete Input"
            control={control}
            options={autocompleteOptions}
            name="autocomplete"
            required
          />
        </div>
        <div className="my-4 flex justify-center">
          <FormMaskedInput
            label="Masked Input"
            control={control}
            name="masked"
            mask="phone"
            required
          />
        </div>
        <div className="my-4 flex justify-center">
          <FormRadioInput
            label="Radio Input"
            control={control}
            name="radio"
            required
            options={radioOptions}
          />
        </div>
        <div className="my-4 flex justify-center">
          <FormSelectInput
            label="Select Input"
            control={control}
            name="select"
            required
            placeholder="Select"
            listKey="select-form"
            options={selectOptions}
          />
        </div>
        <div className="my-4 flex justify-center">
          <FormTextInput
            label="Text Input"
            control={control}
            name="text"
            required
          />
        </div>
        <div className="my-4 flex justify-center">
          <Button type="submit">{display.submit}</Button>
        </div>
      </form>
    </Card>
  );
}

export default FormInputsPage;
