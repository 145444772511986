// https://github.com/gpbl/react-day-picker/blob/master/packages/react-day-picker/src/hooks/useInput/useInput.ts
import { ChangeEventHandler, useState } from 'react';

import {
  format as formatDate,
  parse,
  isValid,
  isBefore,
  isAfter
} from 'date-fns';
import { enUS } from 'date-fns/locale';
import {
  DayClickEventHandler,
  InputDayPickerProps,
  InputHTMLAttributes,
  MonthChangeEventHandler,
  UseInputOptions,
  UseInput
} from 'react-day-picker';

import { parseFromToProps } from 'components/DayPicker/lib/parseFromToProps';

/**
 * Config
 */
const DEFAULT_FORMAT = 'P';

/**
 * Types
 */
export type ControlledInputOptions = UseInputOptions & {
  onSelect?: (day: Date) => void;
};

/**
 * useDateInput hook - Return props for binding an input field to DayPicker.
 */
function useDateInput(options: ControlledInputOptions = {}): UseInput {
  /**
   * Props
   */
  const {
    locale = enUS,
    required,
    format = DEFAULT_FORMAT,
    defaultSelected,
    today = new Date()
  } = options;
  const { fromDate, toDate } = parseFromToProps(options);
  const defaultInputValue = defaultSelected
    ? formatDate(defaultSelected, format, { locale })
    : '';

  /**
   * States
   */
  const [month, setMonth] = useState(defaultSelected ?? today);
  const [selectedDay, setSelectedDay] = useState(defaultSelected);
  const [inputValue, setInputValue] = useState(defaultInputValue);

  /**
   * Callbacks
   */
  // 🟤 Cb - Shortcut to the DateFns functions
  const parseValue = (value: string) => parse(value, format, today, { locale });

  // 🟤 Cb - Reset to default or today
  const reset = () => {
    setSelectedDay(defaultSelected);
    setMonth(defaultSelected ?? today);
    setInputValue(defaultInputValue);
  };

  // 🟤 Cb - Apply selected date
  const setSelected = (date?: Date) => {
    setSelectedDay(date);
    setMonth(date ?? today);
    setInputValue(date ? formatDate(date, format, { locale }) : '');
  };

  // 🟤 Cb - handleDayClick
  const handleDayClick: DayClickEventHandler = (day, { selected }) => {
    if (!required && selected) {
      setSelectedDay(undefined);
      setInputValue('');
      return;
    }
    setSelectedDay(day);
    setInputValue(formatDate(day, format, { locale }));
    options.onSelect?.(day);
  };

  // 🟤 Cb - handleMonthChange
  const handleMonthChange: MonthChangeEventHandler = (month) => {
    setMonth(month);
  };

  // 🟤 Cb - handleChange
  // When changing the input field, save its value in state and check if the
  // string is a valid date. If it is a valid day, set it as selected and update
  // the calendar’s month.
  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setInputValue(e.target.value);
    const day = parseValue(e.target.value);
    const isBeforeFrom = fromDate ? isBefore(day, fromDate) : true;
    const isAfterTo = toDate ? isAfter(day, toDate) : true;
    if (!isValid(day) || isBeforeFrom || isAfterTo) {
      setSelectedDay(undefined);
      return;
    }
    setSelectedDay(day);
    setMonth(day);
    options?.onSelect?.(day);
  };

  // 🟤 Cb - handleBlur
  // Special case for _required_ fields: on blur, if the value of the input is not
  // a valid date, reset the calendar and the input value.
  const handleBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
    const day = parseValue(e.target.value);
    !isValid(day) && reset();
  };

  // 🟤 Cb - handleFocus
  // When focusing, make sure DayPicker visualizes the month of the date in the
  // input field.
  const handleFocus: React.FocusEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.value) {
      reset();
      return;
    }
    const day = parseValue(e.target.value);
    isValid(day) && setMonth(day);
  };

  /**
   * Objects
   */
  const dayPickerProps: InputDayPickerProps = {
    mode: 'custom',
    month,
    onDayClick: handleDayClick,
    onMonthChange: handleMonthChange,
    selected: selectedDay,
    locale,
    fromDate: options?.fromDate,
    toDate: options?.toDate,
    today
  };
  const inputProps: InputHTMLAttributes = {
    onBlur: handleBlur,
    onChange: handleChange,
    onFocus: handleFocus,
    value: inputValue
  };

  /**
   * Output
   */
  return { dayPickerProps, inputProps, reset, setSelected };
}

export default useDateInput;
