import { forwardRef } from 'react';

import clsx from 'clsx';

import { HTMLButtonProps, Modify } from '@reece/global-types';
import {
  baseStyle,
  disabledStyles,
  sizeStyles,
  themedStyles
} from 'components/Button/styles';

/**
 * Types
 */
type NewProps = {
  className?: string;
  color?: ButtonColor;
  disabled?: boolean;
  fullWidth?: boolean;
  iconEnd?: JSX.Element;
  iconEndClassName?: string;
  iconStart?: JSX.Element;
  iconStartClassName?: string;
  kind?: ButtonKind;
  size?: ButtonSize;
  title?: string;
};
export type ButtonColor =
  | 'primary'
  | 'secondary'
  | 'gray'
  | 'lightBlue'
  | 'yellow'
  | 'green'
  | 'red';
export type ButtonKind = 'fill' | 'outline' | 'text';
export type ButtonSize = 'sm' | 'md' | 'lg';
export type ButtonProps = Modify<HTMLButtonProps, NewProps>;

/**
 * Component
 */
const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  /**
   * props
   */
  const {
    className,
    color = 'primary',
    disabled,
    fullWidth,
    iconEnd,
    iconEndClassName,
    iconStart,
    iconStartClassName,
    kind = 'fill',
    onClick,
    size = 'md',
    title,
    ...rest
  } = props;

  /**
   * Render
   */
  return (
    <button
      className={clsx(
        className,
        baseStyle,
        themedStyles[kind][color],
        sizeStyles[size],
        disabledStyles[kind],
        { 'w-full': fullWidth }
      )}
      onClick={onClick}
      disabled={disabled}
      ref={ref}
      {...rest}
    >
      {Boolean(iconStart) && (
        <span className={clsx(iconStartClassName, 'ml-0 mr-1.5 inline-flex')}>
          {iconStart}
        </span>
      )}
      {title ?? props.children}
      {Boolean(iconEnd) && (
        <span className={clsx(iconEndClassName, 'ml-0 mr-0 inline-flex')}>
          {iconEnd}
        </span>
      )}
    </button>
  );
});

export default Button;
