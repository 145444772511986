/**
 * Use for getting parameter from URL
 * @param file BlobPart
 * @param fileName string
 * @param fileType string
 * @returns
 */
export function downloadFile(
  file: BlobPart,
  fileName: string,
  fileType: string
) {
  const blob = new Blob([file], { type: fileType });
  const url = window.URL.createObjectURL(blob);

  // Creating a link element
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();

  // Clean up and remove the link
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
}

export function base64ToArrayBuffer(data: string) {
  const binaryString = window.atob(data);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}
