import { useState, useMemo, useEffect } from 'react';

import { Modify } from '@reece/global-types';
import MinimumQuantityDialog from 'common/QtyInput/MinimumQuantityDialog';
import { NumberInput, NumberInputProps } from 'components';
import useDebouncedCallback from 'hooks/useDebouncedCallback';

/**
 * Config
 */
const defaultModal: QtyAdjustmentModal = {
  open: false,
  adjustedQty: 0,
  minQty: 1,
  productName: ''
};

/**
 * Types
 */
export type QtyInputProps = Modify<
  NumberInputProps,
  {
    index?: number;
    noDebounce?: boolean;
    onUpdate?: (value: number) => void;
    productName?: string;
  }
>;
export type QtyAdjustmentModal = {
  minQty: number;
  adjustedQty: number;
  open: boolean;
  productName: string;
};

/**
 * Component
 */
function QtyInput(props: QtyInputProps) {
  /**
   * Props
   */
  const {
    allowZero,
    value: parentValue,
    max: incomingMax,
    increment = 1,
    noDebounce,
    onUpdate,
    productName,
    sync,
    ...otherProps
  } = props;
  const min = allowZero ? 0 : increment;

  /**
   * State
   */
  const [isManualInput, setManualInput] = useState(false);
  const [modal, setModal] = useState(defaultModal);
  const [qty, setQty] = useState(parentValue || min);

  /**
   * Memo
   */
  // 🔵 Memo - max qty
  const max = useMemo(
    () => (incomingMax ? incomingMax - (incomingMax % increment) : undefined),
    [incomingMax, increment]
  );

  /**
   * Callbacks
   */
  // ⏲️ Debounced Cb - Update qty
  const updateQty = useDebouncedCallback(
    (newqty: number) => {
      const notAtZero = !allowZero && newqty;
      const isPositive = allowZero && newqty >= 0;
      const notAtLowest = notAtZero || isPositive;
      const canUpdate = newqty !== parentValue && notAtLowest;
      canUpdate && onUpdate?.(newqty);
    },
    300,
    [parentValue]
  );
  // 🟤 Cb - Qty update
  const handleQtyUpdate = (inputQty: number) => {
    const remainder = increment ? inputQty % increment : 0;
    // When there's remainder, it needs to be adjusted to be divisible by `increment`
    if (remainder) {
      const matchIncrement = inputQty - remainder + increment;
      const calculatedMax = max ? max - (max % increment) : undefined;
      const toAdjustTo = max
        ? Math.min(matchIncrement, calculatedMax!)
        : matchIncrement;
      // shows a modal to notify the end user
      setModal({
        open: true,
        adjustedQty: toAdjustTo,
        minQty: allowZero ? 0 : increment,
        productName: productName ?? ''
      });
      setQty(toAdjustTo);
      onUpdate?.(toAdjustTo);
      return;
    }
    // if no remainder, set the value
    const toAdjustTo = max ? Math.min(inputQty, max) : inputQty;
    setQty(toAdjustTo);
    onUpdate?.(toAdjustTo);
  };
  // 🟤 Cb - Input blur
  const handleInputBlur = (inputQty: number) => {
    if (isManualInput && inputQty !== qty) {
      setManualInput(false);
      handleQtyUpdate(inputQty);
    }
  };
  // 🟤 Cb - Input click
  const handleClickQtyChange = (inputQty: number) => {
    inputQty = max ? Math.min(inputQty, max) : inputQty;
    inputQty = Math.max(inputQty, min);
    setQty(inputQty);
    if (noDebounce) {
      onUpdate?.(inputQty);
    } else {
      updateQty(inputQty);
    }
    setManualInput(false);
  };
  // 🟤 Cb - close dialog
  const handleCloseDialog = () => setModal({ ...modal, open: false });

  /**
   * Effects
   */
  // 🟡 Effect - sync parent value
  useEffect(() => {
    if (!isManualInput && qty !== parentValue && sync) {
      setQty(parentValue);
    }
  }, [isManualInput, parentValue, qty, sync]);

  /**
   * Render
   */
  return (
    <>
      <NumberInput
        {...otherProps}
        value={qty}
        min={min}
        max={max}
        allowzero={allowZero}
        increment={props.increment}
        onClick={handleClickQtyChange}
        onFocus={() => setManualInput(true)}
        onBlur={handleInputBlur}
      />
      <MinimumQuantityDialog
        {...modal}
        onClose={handleCloseDialog}
        onConfirm={handleCloseDialog}
      />
    </>
  );
}

export default QtyInput;
