import { useEffect, useState } from 'react';

import { Container, Divider, Hidden, Toolbar } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import BranchButton from 'common/Branches/BranchButton';
import { useHeaderContext } from 'common/Header/HeaderProvider';
import {
  containerStyle,
  containerStyleSearchOpen,
  logoStyle,
  toolbarStyle,
  toolbarStyleSearchOpen
} from 'common/Header/lib/styles';
import CartButton from 'common/Header/sub/CartButton';
import DesktopSubheader from 'common/Header/sub/DesktopSubheader';
import MobileDrawer from 'common/Header/sub/MobileDrawer';
import SearchBar from 'common/Header/sub/SearchBar';
import { AppBar, Button, IconButton, Link } from 'components';
import { useDomainInfo } from 'hooks/useDomainInfo';
import { MaxIcon, MenuIcon, SearchIcon, StarFilledIcon } from 'icons';
import logoSrc from 'images/logo.svg';
import { useAuthContext } from 'providers/AuthProvider';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';
import CategoriesButtonWrapper from 'Categories/CategoriesButtonWrapper';
import UserButton from 'common/Header/sub/UserButton';
import DesktopSubheaderMenu from 'common/Header/sub/DesktopSubheaderMenu';

/**
 * Component
 */
function Header() {
  /**
   * Custom Hooks
   */
  const { isWaterworks, subdomain } = useDomainInfo();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  /**
   * State
   */
  const [drawerOpen, setDrawerOpen] = useState(false);

  /**
   * Context
   */
  const { activeFeatures, authState, profile } = useAuthContext();
  const { maintenance, searchOpen, setSearchOpen, setMaintenance } =
    useHeaderContext();
  const { isMincron } = useSelectedAccountsContext();

  /**
   * Callbacks
   */
  // 🟤 Cb - Open drawer
  const handleDrawerOpen = () => setDrawerOpen(true);
  // 🟤 Cb - Open search
  const handleSearchOpen = () => setSearchOpen(true);
  // 🟤 Cb - My lists
  const handleListsClick = () =>
    push(authState?.isAuthenticated ? '/lists' : '/login');

  /**
   * Effect
   */
  // 🟡 effect - Overflow when search
  useEffect(() => {
    document.body.classList.toggle('overflow-hidden', searchOpen);
  }, [searchOpen]);

  //
  // 🟡 Effect - Checks if in fortiline subdomain and waterworks maintenance toggle active
  useEffect(() => {
    activeFeatures?.includes('WATERWORKS_MAINTENANCE') &&
      subdomain === 'fortiline' &&
      setMaintenance(true);
  }, [activeFeatures, subdomain, setMaintenance]);

  /**
   * Render
   */
  if (
    pathname.includes('/barcode') ||
    pathname.includes('/careers') ||
    pathname.includes('/banner/')
  ) {
    return null;
  }

  // Select accounts/Register page specific header
  if (pathname.includes('/register')) {
    return (
      <div
        className="bg-transparent bottom-0 z-10 print:hidden"
        data-testid="header-wrapper-alt"
      >
        <AppBar color="inherit" position="static" elevation={0}>
          <Toolbar
            disableGutters
            className={clsx(toolbarStyle, {
              [toolbarStyleSearchOpen]: searchOpen
            })}
          >
            <Container
              maxWidth="lg"
              className={clsx(containerStyle, {
                [containerStyleSearchOpen]: searchOpen
              })}
            >
              <div className="leading-none	flex md:mx-auto">
                <Link to="/" className="flex" data-testid="header-logo-link">
                  <img
                    alt="Reece"
                    data-testid="reecestandard-logo"
                    src={logoSrc}
                    className={logoStyle}
                  />
                  {subdomain !== 'reece' && (
                    <>
                      <div className="mx-3 my-2 md:my-0">
                        <Divider orientation="vertical" />
                      </div>
                      <img
                        alt={subdomain}
                        data-testid="subdomain-logo"
                        src={`/logos/${subdomain}.svg`}
                        className={logoStyle}
                      />
                    </>
                  )}
                </Link>
              </div>
            </Container>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
  // General Header
  return (
    <div
      className="bg-transparent bottom-0 z-10 print:hidden"
      data-testid="header-wrapper"
    >
      <div className={clsx('hidden md:block h-auto', { '!h-0': searchOpen })} />
      <MobileDrawer open={drawerOpen} setOpen={setDrawerOpen} />
      <div
        className={clsx('bg-transparent bottom-0 z-10', {
          'md:absolute md:top-0 md:w-full': searchOpen
        })}
      >
        <AppBar color="inherit" position="static" elevation={0}>
          <div className="bg-common-white">
            <Toolbar
              disableGutters
              className={clsx(toolbarStyle, {
                [toolbarStyleSearchOpen]: searchOpen
              })}
            >
              {/* Search Bar */}
              {!isWaterworks && !isMincron && (
                <Hidden mdUp>
                  <SearchBar />
                </Hidden>
              )}

              <Container
                maxWidth="lg"
                className={clsx(containerStyle, {
                  [containerStyleSearchOpen]: searchOpen
                })}
              >
                <div className="w-full flex justify-between gap-2">
                  <div className="relative w-full flex items-center flex-nowrap">
                    <Hidden mdUp>
                      <div className="flex flex-1 justify-start">
                        <IconButton
                          onClick={handleDrawerOpen}
                          size="large"
                          className="mr-auto"
                          data-testid="mobile-hamburger-menu"
                        >
                          <MenuIcon />
                        </IconButton>
                      </div>
                    </Hidden>
                    <div className="flex basis-auto flex-grow-0 flex-shrink leading-none">
                      <Link to="/" className="flex">
                        <img
                          alt="Reece"
                          data-testid="reecestandard-logo"
                          src={logoSrc}
                          className={logoStyle}
                        />
                      </Link>
                      {subdomain !== 'reece' && (
                        <>
                          <div className="mx-3 my-2 md:my-0">
                            <Divider orientation="vertical" />
                          </div>
                          <Link to="/" className="flex">
                            <img
                              alt={subdomain}
                              data-testid="subdomain-logo"
                              src={`/logos/${subdomain}.svg`}
                              className={logoStyle}
                            />
                          </Link>
                        </>
                      )}
                    </div>
                    {/* Browse Product */}
                    {!isWaterworks && !isMincron && (
                      <Hidden mdDown>
                        <div className="flex basis-auto ml-5">
                          <CategoriesButtonWrapper />
                        </div>
                      </Hidden>
                    )}
                    {/* SearchBar*/}
                    {!isWaterworks && !isMincron && (
                      <Hidden mdDown>
                        <div className="ml-5 flex flex-1">
                          <SearchBar />
                        </div>
                      </Hidden>
                    )}
                    <Hidden mdUp>
                      <div className="flex flex-1 justify-end">
                        {!isWaterworks && !isMincron && (
                          <IconButton
                            onClick={handleSearchOpen}
                            size="large"
                            className="ml-auto"
                            data-testid="header-search-button"
                          >
                            <SearchIcon />
                          </IconButton>
                        )}
                        {/* Cart button */}
                        {!maintenance && <CartButton />}
                      </div>
                    </Hidden>
                  </div>
                  <Hidden mdDown>
                    {!maintenance && (
                      <div className="col-span-1 flex items-center justify-end flex-nowrap ml-3 gap-3">
                        {/* MAX */}
                        <DesktopSubheaderMenu item="max">
                          <MaxIcon className="!w-[45px] !h-[15px]" />
                        </DesktopSubheaderMenu>
                        {/*User */}
                        {!maintenance && <UserButton />}
                        {!isWaterworks && !isMincron && (
                          <Button
                            kind="text"
                            color="gray"
                            iconStart={
                              <StarFilledIcon className="text-primary-1-100" />
                            }
                            onClick={handleListsClick}
                            data-testid="my-lists-button"
                            className="whitespace-nowrap !font-bold !text-sm !px-1"
                          >
                            {t('common.lists')}
                          </Button>
                        )}
                        <CartButton />
                      </div>
                    )}
                  </Hidden>
                </div>
              </Container>
            </Toolbar>
            {Boolean(
              authState?.isAuthenticated &&
                (!profile?.isEmployee ||
                  (profile?.isEmployee && profile?.isVerified)) &&
                !maintenance
            ) && (
              <Hidden mdUp>
                <Divider />
                <Toolbar disableGutters>
                  <BranchButton data-testid="branch-button-mobile" />
                </Toolbar>
              </Hidden>
            )}
          </div>
          {!maintenance && (
            <Hidden mdDown>
              <DesktopSubheader />
            </Hidden>
          )}
        </AppBar>
      </div>
    </div>
  );
}

export default Header;
